import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
//import { useAlert } from 'react-alert'
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'

//import { GetApi } from '../../Common/ApiAxios'
import * as LogisticUtility from '../../Common/Utilties/LogisticUtility'
import FUNC from '../../Common/FunctionEnum'

import {BoardBaseFormRow, BoardBaseFormRowScroll} from '../Wrap/BoardBaseWrap'
import Search from './Search'
import SideSubMenu from '../SideSubMenu'
//import IndustryCarbonEmissionBoard from './Molcules/IndustryCarbonEmissionBoard'
import IndustryCarbonEmissionBoard2 from './Molcules/IndustryCarbonEmissionBoard2' 
import IndustryCarbonEmissionBoard3 from './Molcules/IndustryCarbonEmissionBoard3' 

//const DefaultUrl = '/api/logisticManagement'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 85vh;
  text-align: center;
  vertical-align: middle;
`

const CarbonEmissions = (props) => {
  const { loginUserInfo } = useSelector(state => state);
  const [viewFunction,setViewFunction] = useState(false);
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC);
  const subGrants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC_CARBONEMISSIONS);
  //const [searchWorkplaceId, setSearchWorkplaceId] = useState('000011'); // 検索欄の現場ID
  const [searchWorkplaceId, setSearchWorkplaceId] = useState('000000'); // 検索欄の現場ID
  const [searchDate, setSearchDate] = useState(LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD')); // 検索欄の日付

  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }

  // 機能メニューの閉じるイベント
  const onCloseFunction = (event) => {
    setViewFunction(false);
  }

  useEffect(() => {
    if (grants && subGrants)
      document.title = grants.functionMenuName + '【' + subGrants.functionMenuName + '】 | CoreBoard'
    else if (grants)
    document.title = grants.functionMenuName + ' | CoreBoard'
    else
      document.title = 'CoreBoard'
  }, [grants, subGrants])
    
  // 描画時に一度だけ実行
  useEffect(() => {
    //getIndustryInfo();
  }, [])

  return (
    <>
      <Search
        onClickFunction={onClickFunction}
        onCloseFunction={onCloseFunction}
        viewFunction={viewFunction}
        searchWorkplaceId={searchWorkplaceId}
        setSearchWorkplaceId={setSearchWorkplaceId}
        searchDate={searchDate}
        setSearchDate={setSearchDate}
      />
      <BoardBaseFormRow>
        <SideSubMenu pageNum={5}/>
        <BoardBaseFormRowScroll>
          <ContentWrap>
            <IndustryCarbonEmissionBoard2 grants={grants} searchWorkplaceId={searchWorkplaceId} searchDate={searchDate}/>
            <IndustryCarbonEmissionBoard3 grants={grants} searchWorkplaceId={searchWorkplaceId} searchDate={searchDate}/>
          </ContentWrap>
        </BoardBaseFormRowScroll>
      </BoardBaseFormRow>
    </>
  )
}

export default CarbonEmissions

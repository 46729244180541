import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'

const MediaWrap = styled.div`
  width:1740px;
  display: flex; /* 横並び */
  flex-wrap: wrap;
  margin: 4px;
  box-shadow: 0 0 3px 2px ${props => props.selected ? 'rgba(0,128,0,0.8)' : 'rgba(0,0,0,0.4)'};

  color: #000;
  font-size: 12px;
`
const MediaItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 40px;
  border-right: 2px dashed #fff;
`
const MediaRow = styled.div`
  display: flex; /* 縦並び */
  flex-direction: row;
  height: 40px;

  align-items: center;
`
const TextWrap = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
` 

// 項目名
function getTitle(data) {
  let text = '';

  if (data.processId === '') {
    return text
  }
  
  {
    text = <>{data.processName}<br/>({data.processId})</>
  }

  return text;
}

function getItem(data,before) {
  if(data==null || before==null) return;

  let text = LogisticUtility.getCommaText(data.toFixed(2));
  let ratio = 0.00;
  
  if(data !== 0 && before !== 0){
    ratio = data * 100 / before;
  }  
  let value = ratio.toFixed(2); 

  return <>{text}<br/>({value}%)</>
}

const MediaDataCarbonEmissions = (props) => {
  const {
    data,before
  } = props

  // 初期値
  useEffect(() => {
  })

  //if (!data) return null;
  //console.log(data);
  return (
    <MediaWrap>
      <MediaItem>
        <TextWrap style={{width:'185px'}}>
          <div>{getTitle(data)}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'160px'}}>
          <MediaRow>
            <div style={{width:'80px'}}>{getItem(data.processStaff,before.processStaff)}</div>
            <div style={{width:'80px'}}>{getItem(data.processTime,before.processTime)}</div>
          </MediaRow>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'240px'}}>
          <MediaRow>
            <div style={{width:'80px'}}>{getItem(data.monthProcessStaff,before.monthProcessStaff)}</div>
            <div style={{width:'80px'}}>{getItem(data.monthProcessTime,before.monthProcessTime)}</div>
            <div style={{width:'80px'}}>{getItem(data.aveWage,before.aveWage)}</div>
          </MediaRow>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'160px'}}>
          <MediaRow>
            <div style={{width:'80px'}}>{getItem(data.sunProcessStaff,before.sunProcessStaff)}</div>
            <div style={{width:'80px'}}>{getItem(data.sunProcessTime,before.sunProcessTime)}</div>
          </MediaRow>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'160px'}}>
          <MediaRow>
            <div style={{width:'80px'}}>{getItem(data.monProcessStaff,before.monProcessStaff)}</div>
            <div style={{width:'80px'}}>{getItem(data.monProcessTime,before.monProcessTime)}</div>
          </MediaRow>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'160px'}}>
          <MediaRow>
            <div style={{width:'80px'}}>{getItem(data.tueProcessStaff,before.tueProcessStaff)}</div>
            <div style={{width:'80px'}}>{getItem(data.tueProcessTime,before.tueProcessTime)}</div>
          </MediaRow>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'160px'}}>
          <MediaRow>
            <div style={{width:'80px'}}>{getItem(data.wedProcessStaff,before.wedProcessStaff)}</div>
            <div style={{width:'80px'}}>{getItem(data.wedProcessTime,before.wedProcessTime)}</div>
          </MediaRow>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'160px'}}>
          <MediaRow>
            <div style={{width:'80px'}}>{getItem(data.thuProcessStaff,before.thuProcessStaff)}</div>
            <div style={{width:'80px'}}>{getItem(data.thuProcessTime,before.thuProcessTime)}</div>
          </MediaRow>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'160px'}}>
          <MediaRow>
            <div style={{width:'80px'}}>{getItem(data.friProcessStaff,before.friProcessStaff)}</div>
            <div style={{width:'80px'}}>{getItem(data.friProcessTime,before.friProcessTime)}</div>
          </MediaRow>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'160px'}}>
          <MediaRow>
            <div style={{width:'80px'}}>{getItem(data.satProcessStaff,before.satProcessStaff)}</div>
            <div style={{width:'80px'}}>{getItem(data.satProcessTime,before.satProcessTime)}</div>
          </MediaRow>
        </TextWrap>
      </MediaItem>
    </MediaWrap>
  )
}

MediaDataCarbonEmissions.propTypes = {
  grants: PropTypes.object, // 権限関連
  data: PropTypes.object, // CO2排出情報
  totalEmissions: PropTypes.number, // CO2排出量の合計値
}

export default MediaDataCarbonEmissions

import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'

const MediaWrap = styled.div`
  width:1650px;
  display: flex; /* 横並び */
  flex-wrap: wrap;
  margin: 4px;
  box-shadow: 0 0 3px 2px ${props => props.selected ? 'rgba(0,128,0,0.8)' : 'rgba(0,0,0,0.4)'};

  color: #000;
  font-size: 16px;
`
const MediaItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 80px;
  border-right: 2px dashed #fff;
`
const MediaRow = styled.div`
  display: flex; /* 縦並び */
  flex-direction: row;
  height: 40px;

  align-items: center;
`
const TextWrap = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
` 

// 日付確認
function getDate(date,week) {
  var tmpDate = new Date(date);
  var dayOfWeek  = tmpDate.getDay();

  tmpDate.setDate(tmpDate.getDate() - dayOfWeek + week);
  var text = LogisticUtility.getStringFromDate(tmpDate, 'YYYY-MM-DD');

  return text;
}
// 日付確認
function getDataMin(dataList,date,week) {
  if(dataList==null || !dataList) return;
  var dateValue = getDate(date,week);

  var dataRow = null
  dataList.map((item)=>{
    if(item.timeZone == dateValue){
      dataRow = item;
    }
  })
  if(dataRow == null) return 0;

  return Number(dataRow.progressWorkTime).toFixed(2);
}
// 日付確認
function getDataHour(dataList,date,week) {
  if(dataList==null || !dataList) return;

  var dateValue = getDate(date,week);

  var dataRow = null
  dataList.map((item)=>{
    if(item.timeZone == dateValue){
      dataRow = item;
    }
  })
  if(dataRow == null) return 0;

  return Number(dataRow.planWorkTime).toFixed(2);
}

const MediaDataCarbonEmissions = (props) => {
  const {
    title,data,targetYMD,
  } = props

  // 初期値
  useEffect(() => {
  })

  //if (!data) return null;
  //console.log(data);
  return (
    <MediaWrap>
      <MediaItem>
        <TextWrap style={{width:'185px',fontWeight:'bold'}}>
          <div>{title}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'100px'}}>
          <MediaRow>
            <div style={{width:'100px'}}>{'分'}</div>
          </MediaRow>
          <MediaRow>
            <div style={{width:'100px'}}>{'時間'}</div>
          </MediaRow>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'190px'}}>
          <MediaRow>
            <div style={{width:'190px',textAlign: 'right',marginRight:'1rem',fontSize:'24px'}}>{getDataMin(data,targetYMD,1)}</div>
          </MediaRow>
          <MediaRow>
            <div style={{width:'190px',textAlign: 'right',marginRight:'1rem',fontSize:'24px'}}>{getDataHour(data,targetYMD,1)}</div>
          </MediaRow>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'190px'}}>
          <MediaRow>
            <div style={{width:'190px',textAlign: 'right',marginRight:'1rem',fontSize:'24px'}}>{getDataMin(data,targetYMD,2)}</div>
          </MediaRow>
          <MediaRow>
            <div style={{width:'190px',textAlign: 'right',marginRight:'1rem',fontSize:'24px'}}>{getDataHour(data,targetYMD,2)}</div>
          </MediaRow>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'190px'}}>
          <MediaRow>
            <div style={{width:'190px',textAlign: 'right',marginRight:'1rem',fontSize:'24px'}}>{getDataMin(data,targetYMD,3)}</div>
          </MediaRow>
          <MediaRow>
            <div style={{width:'190px',textAlign: 'right',marginRight:'1rem',fontSize:'24px'}}>{getDataHour(data,targetYMD,3)}</div>
          </MediaRow>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'190px'}}>
          <MediaRow>
            <div style={{width:'190px',textAlign: 'right',marginRight:'1rem',fontSize:'24px'}}>{getDataMin(data,targetYMD,4)}</div>
          </MediaRow>
          <MediaRow>
            <div style={{width:'190px',textAlign: 'right',marginRight:'1rem',fontSize:'24px'}}>{getDataHour(data,targetYMD,4)}</div>
          </MediaRow>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'190px'}}>
          <MediaRow>
            <div style={{width:'190px',textAlign: 'right',marginRight:'1rem',fontSize:'24px'}}>{getDataMin(data,targetYMD,5)}</div>
          </MediaRow>
          <MediaRow>
            <div style={{width:'190px',textAlign: 'right',marginRight:'1rem',fontSize:'24px'}}>{getDataHour(data,targetYMD,5)}</div>
          </MediaRow>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'190px'}}>
          <MediaRow>
            <div style={{width:'190px',textAlign: 'right',marginRight:'1rem',fontSize:'24px'}}>{getDataMin(data,targetYMD,6)}</div>
          </MediaRow>
          <MediaRow>
            <div style={{width:'190px',textAlign: 'right',marginRight:'1rem',fontSize:'24px'}}>{getDataHour(data,targetYMD,6)}</div>
          </MediaRow>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'190px'}}>
          <MediaRow>
            <div style={{width:'190px',textAlign: 'right',marginRight:'1rem',fontSize:'24px'}}>{getDataMin(data,targetYMD,7)}</div>
          </MediaRow>
          <MediaRow>
            <div style={{width:'190px',textAlign: 'right',marginRight:'1rem',fontSize:'24px'}}>{getDataHour(data,targetYMD,7)}</div>
          </MediaRow>
        </TextWrap>
      </MediaItem>
    </MediaWrap>
  )
}

MediaDataCarbonEmissions.propTypes = {
  grants: PropTypes.object, // 権限関連
  data: PropTypes.object, // CO2排出情報
  totalEmissions: PropTypes.number, // CO2排出量の合計値
}

export default MediaDataCarbonEmissions

import React, {useEffect} from 'react'
import { Switch, Route } from 'react-router-dom'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
//import PropTypes from 'prop-types'
//import Tooltip from '@material-ui/core/Tooltip'

import FUNC from '../Common/FunctionEnum'

import CurrentMonthProgress from './CurrentMonthProgress/CurrentMonthProgress'
import PreviousMonthResults from './PreviousMonthResults/PreviousMonthResults'
import Summary from './Summary/Summary'
import HealthCare from './HealthCare/HealthCare'
import Help from './Help/Help'

const Container = styled.div`
  .AppTruck {
    flex-direction: column;
    background-repeat: no-repeat;
    background-color: #FFF;
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }
`

const Truck = () => {
  const { loginUserInfo } = useSelector(state => state);
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.TRUCK);

  useEffect(() => {
    //console.log(loginUserInfo.menuList);
    if (grants)
      document.title = grants.functionMenuName + ' | Core Board';
    else
      document.title = 'Core Board';
  }, [grants])

  return (
    <Container>
      <div className='AppTruck' id='AppTruck'>
        <div className='mainTruck'>
          <Switch>
            <Route path='/truck/currentMonthProgress' component={CurrentMonthProgress} />
            <Route path='/truck/previousMonthResults' component={PreviousMonthResults} />
            <Route path='/truck/summary' component={Summary} />
            <Route path='/truck/healthCare' component={HealthCare} />
            <Route path='/truck/help' component={Help} />
            <Route exact component={CurrentMonthProgress} />
          </Switch>
        </div>
      </div>
    </Container>
  )
}

export default Truck

import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
//import { useAlert } from 'react-alert'
import { useDispatch } from 'react-redux'
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { GetApi } from '../../../Common/ApiAxios'
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'
import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'
import {ListUl, ListLi} from '../../Wrap/BoardBaseWrap'

import MediaHeaderCarbonEmissions from '../Atoms/MediaHeaderCarbonEmissions'
import MediaDataCarbonEmissions from '../Atoms/MediaDataCarbonEmissions'
//import MediaHeaderActivityEmissions from '../Atoms/MediaHeaderActivityEmissions'
//import MediaDataActivityEmissions from '../Atoms/MediaDataActivityEmissions'

const DefaultUrl = '/api/logisticManagement'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  text-align: left;
  vertical-align: middle;
`

const ContentFlex = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  text-align: center;
`

const GraphWrap = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 100%;
  height: 350px;
  text-align: center;
`
/*
const COLORS = [
    '#FF0000',
    '#800000',
    '#FFFF00',
    '#808000',
    '#00FF00',
    '#008000',
    '#00FFFF',
    '#0000FF',
    '#000080',
    '#FF00FF',
    '#800080',
    '#FF0000',
    '#AA0000',
    '#880000',
    '#440000',
    '#220000',
];
const COLORS = [
  '#9dceff',
  '#9bdeff',
  '#6fb7ff',
  '#5bcaff',
  '#51a8ff',
  '#28b9ff',
  '#1e8eff',
  '#00acff',
  '#0074e8',
  '#0099e3',
  '#005ebb',
  '#007ab5',
  '#00509f',
  '#005f8c',
  '#00264d',
  '#003046',
];

*/
const COLORS = [
  '#6e6eff',
  '#6fb7ff',
  '#5bcaff',
  '#4646ff',
  '#51a8ff',
  '#28b9ff',
  '#1e8eff',
  '#00acff',
  '#0074e8',
  '#0099e3',
  '#005ebb',
  '#007ab5',
  '#00509f',
  '#005f8c',
  '#003a75',
  '#004364',
];

/*
// 当月内訳のLegendのカスタム
function renderLegendText(value) {
  return <span style={{ color:"#000" }}>{value}</span>;
};
*/
const IndustryCarbonEmissionBoard3 = (props) => {
  const {
    grants,
    industryData,
    searchWorkplaceId,
    searchDate,
  } = props
  const dispatch = useDispatch();
  const [totalEmissions, setTotalEmissions] = useState(0);
  const [list, setList] = useState([]); // Scope3内訳のリスト
  const [list2, setList2] = useState([]);
  const [data3, setData3] = useState([]); // カテゴリ別比率
  const [data4, setData4] = useState([]);

  // 月単位のCO2排出量のカテゴリの集計の取得
  const apiGetTotalCategoryEmissionsMonthly = async (isLoadSplinner) => {
    //console.log('call apiGetTotalCategoryEmissionsMonthly');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true))

    const apiUrl = DefaultUrl + '/getTotalCategoryEmissionsMonthly';
    const param = {
      workplaceId: searchWorkplaceId,
      targetYMD: LogisticUtility.getStringFromDate(new Date(searchDate), 'YYYY-MM-DD'),
      industryId: '00005',
    }
    //console.log(param);
    const result = await GetApi(apiUrl, param);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('日単位のCO2排出量のカテゴリの集計取得失敗');
      setTotalEmissions(0);
      setList([]);
    } else {
      //console.log(result);
      var total = 0;
      result.data.forEach(function(elem, index) {
        total += elem.emissions;
      });

      var list = result.data.filter((elem) => { return elem.scopeNum === '00003'; });
      //console.log(list);
      var scope3List = new Array();
      list.map(item => {
        var items = scope3List.filter((elem) => { return elem.categoryId === item.categoryId; });
        if (items.length === 0) {
          scope3List.push(
            {
              scopeNum:item.scopeNum,
              categoryId:item.categoryId,
              categoryName:item.categoryName,
              emissions:item.emissions,
            }
          )
        } else {
          items[0].emissions += item.emissions;
        }
      });
      //console.log(total);

      //var index = 1
      setData3(scope3List.map(item => {
        //console.log(item);
        return {
          //name: 'カテゴリ' + index++,
          name: item.categoryName,
          value: Number(LogisticUtility.getCommaText((item.emissions * 100.0 / total).toFixed(1))),
        }
      }));

      setTotalEmissions(total);
      setList(scope3List);
    }
  }

  // 日単位のCO2排出量のアクティビティの集計の取得
  const apiGetTotalActivityEmissionsDaily = async (isLoadSplinner) => {
    //console.log('call apiGetTotalActivityEmissionsDaily');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getTotalActivityEmissionsDaily';
    const param = {
      workplaceId: searchWorkplaceId,
      targetYMD: LogisticUtility.getStringFromDate(new Date(searchDate), 'YYYY-MM-DD'),
      industryId: '00005',
      categoryId: '00003',
    }
    //console.log(param);
    const result = await GetApi(apiUrl, param);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('日単位のCO2排出量のアクティビティの集計取得失敗');
      setList2([]);
    } else {
      //console.log(result.data);
      setList2(result.data);
      var total = 0;
      result.data.map(item => {
        total += item.emissions
      });

      setData4(result.data.map(item => {
        return {
          name: item.activityName,
          value1: LogisticUtility.checkRate(item.emissions * 100 / total),
          value2: 0,
        }
      }));
    }
  }

  useEffect(() => {
    apiGetTotalCategoryEmissionsMonthly();
    apiGetTotalActivityEmissionsDaily();
  }, [searchWorkplaceId, searchDate])

  // 描画時に一度だけ実行
  useEffect(() => {
    apiGetTotalCategoryEmissionsMonthly();
    apiGetTotalActivityEmissionsDaily();
  }, [])

  return (
    <ContentWrap>
      <GraphWrap>
        <div style={{width:'860px', marginLeft:'0.75rem', textAlign:'left'}}>
          <span style={{marginLeft:'0.25rem', fontWeight:'bold', fontSize:'20px'}}>Scope3 内訳</span>
          <MediaHeaderCarbonEmissions />
          <ListUl width={'100%'} height={'288px'}>
          {list.map(item => (
            <ListLi key={'sCarbonEmissions_' + item.scopeNum + '_' + item.categoryId} style={{cursor: 'default'}}>
              <MediaDataCarbonEmissions grants={grants} data={item} totalEmissions={totalEmissions} />
            </ListLi>
          ))}
          </ListUl>
        </div>
        <div style={{width:'80px'}}></div>
        <div style={{width:'860px', margin:'auto'}}>
          <div style={{width:'800px', height:'290px'}}>
          <span style={{fontWeight:'bold'}}>Scope3 カテゴリ別 比率</span>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={data3}
                cx="40%"
                cy="50%"
                outerRadius={130}
                fill="#8884d8"
                nameKey="name"
                dataKey="value"
                style={{ fontSize: '10px' }}
              >
              {data3.map((entry, index) => (
                  <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                  />
              ))}
              </Pie>
              <Tooltip />
              <Legend layout="vertical" verticalAlign="top" align="right" />
            </PieChart>
          </ResponsiveContainer>
          </div>
        </div>
      </GraphWrap>
      <div style={{height:'50px'}}></div>
    </ContentWrap>
  )
}

IndustryCarbonEmissionBoard3.propTypes = {
  grants: PropTypes.object, // 権限関連
  industryData: PropTypes.object, // 業種情報
  searchWorkplaceId: PropTypes.string, // 検索欄で選択した現場ID
  searchDate: PropTypes.string, // 検索欄で選択した日付
}

export default IndustryCarbonEmissionBoard3

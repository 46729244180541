import React, { useState, useEffect, useRef } from 'react'
//import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'

//import CardTotal from '../Atoms/CardTotal'
import CardTotalVAS from '../Atoms/CardTotalVAS'

const DefaultUrl = '/api/logisticManagement'


const Flex = styled.div`
  display: flex; /* 横並び */
  flex-direction: row;
  width: 100%;
  text-align: center;
  vertical-align:middle;
  margin-top: 10px;
  margin-bottom: 5px;
`
const ContentRow = styled.div`
  display: flex; /* 横並び */
  flex-direction: column;
`
const Content = styled.div`
  height: 100px;
  margin-left: auto;
  margin-right: auto;
`
// ヘッダー部のカード
const ProgressHeader = (props) => {
  const {
    progressList
  } = props

  //if (!grants || !totalDatas) return null
  return (
    <Flex>
      <Content>
        <ContentRow>         
          <CardTotalVAS type={0} progressList={progressList}/>      
        </ContentRow>
      </Content>
      <Content>
        <ContentRow>         
          <CardTotalVAS type={1} progressList={progressList}/>      
        </ContentRow>
      </Content>
    </Flex>
  )
}

ProgressHeader.propTypes = {
  progressList: PropTypes.array //実績リスト
}
  
export default ProgressHeader


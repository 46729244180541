import React, { useEffect,useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {PieChart,Pie,Cell } from "recharts"

const BodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  overflow: hidden;
  vertical-align: middle;
  width: 300px;
  height: 130px;
  box-shadow: 2px 2px 6px rgba(0,0,0,.4);
  background-color: ${props => props.contentColor?props.contentColor:'#fff'};
`

const Process = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  text-align:center;
  vertical-align:middle;
  padding: 0.5vw;
  hidden;
`

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  text-align: center;
  vertical-align: middle;
  padding: 0.4rem;
  opacity: ${props => props.contentOpacity?1:0};
`

const ProcessTitle = styled.div`
  font-weight: bold;
  color: #000;
  text-align: center;
  font-size: 1.3rem;
`
const ProgressMainContentRow = styled.div`
  color: #000;
  text-align: center;
  font-size: 2.0rem;
`

const ProgressContentRow = styled.div`
  color: #000;
  text-align: center;
  font-size: 1.0rem;
`

const ProgressSubContentRow = styled.div`
  font-weight: bold;
  color: #000;
  text-align: center;
  font-size: 1rem;
`

const ProgressSubBody= styled.div`
  display: flex; /* 縦並び */
  flex-direction: row;

  text-align: center;
  vertical-align: middle;
  padding:0.3rem;
  width: 300px;
  height: 100px;
`
const ProgressSubContent = styled.div`
  display: flex; /* 横並び */
  flex-direction: column;
  vertical-align: middle;
  width: 100px;
`

const data = [
    { name: 'A', value: 80, color: '#4E79A6' },
    { name: 'B', value: 80, color: 'lightgray' },
  ];
  const cx = 85;
  const cy = 85;
  const iR = 50;
  const oR = 70;

const StoreGraph = (props) => {
  const {
    entry,opacity
  } = props

  const [data,setData] = useState([
  ]);
  useEffect(() => {
    // if(entry.planStoreQuantity==0){
    //   setData(
    //     [
    //       { name: '実績', value: Number(0), color: '#4E79A6' },
    //       { name: '残り', value: Number(1), color: 'lightgray' },])
    // }else{
    //   var remain = Number(entry.planStoreQuantity)-Number(entry.progressStoreQuantity)
    //   remain = remain>0?remain:0
    //   setData(
    //     [
    //       { name: '実績', value: Number(entry.progressStoreQuantity), color: '#4E79A6' },
    //       { name: '残り', value: remain, color: 'lightgray' },])
    // }

  }, [entry])

  const makeContent=(progress,plan)=>{
    if(progress && plan) return <><span style={{fontWeight: 'bold'}}>{Number(progress).toLocaleString()}</span> <span style={{fontSize:'90%'}}>/</span> <span style={{fontSize:'70%'}}>{Number(plan).toLocaleString()}</span></>
    if(progress==0 || plan==0) return <><span style={{fontWeight: 'bold'}}>{Number(progress).toLocaleString()}</span> <span style={{fontSize:'90%'}}>/</span> <span style={{fontSize:'70%'}}>{Number(plan).toLocaleString()}</span></>
    return <></>
  }
  const makeMainContent=(progress,plan)=>{
    if(progress && plan){
      var color = progress==plan?'blue':'red'
      return <><span style={{fontWeight: 'bold',color:color}}>{Number(progress).toLocaleString()}</span> <span style={{fontSize:'90%',color:color}}>/</span> <span style={{fontSize:'70%',color:color}}>{Number(plan).toLocaleString()}</span></>
    } 
    if(progress==0 || plan==0){
      var color = progress==plan?'blue':'red'
      return <><span style={{fontWeight: 'bold',color:color}}>{Number(progress).toLocaleString()}</span> <span style={{fontSize:'90%',color:color}}>/</span> <span style={{fontSize:'70%',color:color}}>{Number(plan).toLocaleString()}</span></>
    } 
    return <></>
  }

  const setOpacityAndClor=()=>{
    if(!entry.progressEndTimeDiff) return true
    let tokens = entry.progressEndTimeDiff.split(':')
    let sec = 0

    if (tokens[0]) sec += (Number(tokens[0]) - 9)
    if(Number(tokens[0])<12){
      if (tokens[1] && Number(tokens[1])) sec += Number(tokens[1])/60
    }else if(Number(tokens[0])>12){
      sec -= 1
      if (tokens[1] && Number(tokens[1])) sec += Number(tokens[1])/60
    }

    return Number(entry.progressStoreQuantityRate) < sec/8 * 100
  }

  return (
    <ContentWrap contentOpacity={true}>
        <BodyContent contentColor={entry.completionFlag==0?'#fff':'gray'}>
            <Process>
                <div><ProcessTitle>{entry.areaName}</ProcessTitle></div>
            </Process>
            {/* 進捗詳細 */}
            <ProgressMainContentRow>{makeMainContent(entry.completionQuantity,entry.allQuantity)}</ProgressMainContentRow>
            <div style={{height:'15px'}}/>
            <ProgressSubBody>
              <ProgressSubContent>
                <ProgressSubContentRow>不足入庫待</ProgressSubContentRow>
                <ProgressContentRow>{entry.notInStockQuantity}</ProgressContentRow>
              </ProgressSubContent>
              <ProgressSubContent>
                <ProgressSubContentRow>ケース出荷</ProgressSubContentRow>
                <ProgressContentRow>{makeContent(entry.completionCaseShipping,entry.allCaseShipping)}</ProgressContentRow>
              </ProgressSubContent>
              <ProgressSubContent>
                <ProgressSubContentRow>カゴ車在庫</ProgressSubContentRow>
                <ProgressContentRow>{makeContent(entry.completionKagoShipping,entry.allKagoShipping)}</ProgressContentRow>
              </ProgressSubContent>
            </ProgressSubBody>
        </BodyContent>
    </ContentWrap>
  )
}
StoreGraph.propTypes = {
}

export default StoreGraph

import React, { useEffect } from 'react'
import styled from 'styled-components'
//import PropTypes from 'prop-types'

const MediaWrap = styled.div`
  width:1800px;
  display: flex; /* 横並び */
  flex-wrap: wrap;
  margin: 4px;
  box-shadow: 0 0 3px 2px 'rgba(0,0,0,0.4)';
  background-color: #649CDF;
  color: #fff;
  font-size: 14px;
  font-weight: bold;

  text-align: center;
  align-items: center;
`
const MediaItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 40px;
  border-right: 2px dashed #fff;
`

const ProgressTitle = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
` 

const MediaHeaderItemEmissions = (props) => {
  const {
  } = props

  // 初期値
  useEffect(() => {
  })

  return (
    <MediaWrap>
      <MediaItem>
        <ProgressTitle style={{width:'150px'}}>店舗コード</ProgressTitle>
      </MediaItem>
      <MediaItem>
        <ProgressTitle style={{width:'200px'}}>店舗名</ProgressTitle>
      </MediaItem>
      <MediaItem>
        <ProgressTitle style={{width:'100px'}}>総アイテム数</ProgressTitle>
      </MediaItem>
      <MediaItem>
        <ProgressTitle style={{width:'100px'}}>総数量</ProgressTitle>
      </MediaItem>
      <MediaItem>
        <ProgressTitle style={{width:'100px'}}>カゴ車台数</ProgressTitle>
      </MediaItem>
      <MediaItem>
        <ProgressTitle style={{width:'100px'}}>サンテナ数</ProgressTitle>
      </MediaItem>
      <MediaItem>
        <ProgressTitle style={{width:'150px'}}>カゴ車ID</ProgressTitle>
      </MediaItem>
      <MediaItem>
        <ProgressTitle style={{width:'150px'}}>アイテムコード</ProgressTitle>
      </MediaItem>
      <MediaItem>
        <ProgressTitle style={{width:'300px'}}>アイテム名</ProgressTitle>
      </MediaItem>
      <MediaItem>
        <ProgressTitle style={{width:'100px'}}>アイテム実績</ProgressTitle>
      </MediaItem>
      <MediaItem>
        <ProgressTitle style={{width:'50px'}}></ProgressTitle>
      </MediaItem>
    </MediaWrap>
  )
}

MediaHeaderItemEmissions.propTypes = {
}

export default MediaHeaderItemEmissions

import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'


import { GetApi } from '../../../Common/ApiAxios'
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'
import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'
//import ErrorHandling from '../../Common/ErrorHandling'

import MediaDataItemEmissions from './Atoms/MediaDataItemEmissions'
import MediaHeaderItemEmissions from './Atoms/MediaHeaderItemEmissions'
import MediaSumHeaderItemEmissions from './Atoms/MediaSumHeaderItemEmissions'
import ProgressGraphBoard from './Atoms/ProgressGraphBoard'

const DefaultUrl = '/api/logisticManagement'

const BodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  vertical-align: middle;
  height: 520px;
`
const ContentWrap = styled.div`
  display: flex; /* 横並び */
  flex-direction: column;
  vertical-align: middle;
  width: 100%;
  height: 520px;
  padding:0.5rem;
`
export const ListUl = styled.ul`
  width: ${props => props.width ? props.width : '100%'};
  height: ${props => props.height ? props.height : '400px'};
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    border:none;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(112, 112, 112);
    border-radius: 10px;
  }
`

export const ListLi = styled.li`
  width: ${props => props.width ? props.width : 'calc(100%-18px)'};
  display: flex; /* 横並び */
  flex-direction: row;
  text-indent: 10px;
  background: ${props => props.selected ? '#048b63' : '#fff'};
  color: ${props => props.selected ? '#fff' : '#333'};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  .listId{
    margin-right:1vw;
  }
  :nth-child(even){
    background: ${props => props.selected ? '#048b63' : '#eee'};
    color: ${props => props.selected ? '#fff' : '#333'};
  }
  :hover {
    background: #ccc;
    color: #333;
  }
`

//const data = [{name:'米惣リテール'},{name:'米飯BIG中松愛'},{name:'米飯BIG北・信'},{name:'惣菜BIG北・信・中松愛'},{name:'MV静岡'},{name:'MV東海（総量出荷）'},]
const ItemPerformance = (props) => {
  const {
    searchDate,progressList,onClick
  } = props

  return (
    <ContentWrap>
      <BodyContent>
            <div style={{width:'1810px', marginLeft:'20px', marginRight:'20px', textAlign:'left'}}>
            <MediaHeaderItemEmissions />
            <MediaSumHeaderItemEmissions progressList={progressList}/>
            <ListUl width={'100%'} height={'555px'}>
            {progressList.map((item,index) => (
              <ListLi key={item.vasShippingItemInfo.ownerCd + '_' + index} style={{cursor: 'default'}}>
                <MediaDataItemEmissions data={item.vasShippingItemInfo} searchDate={searchDate} onClick={onClick}/>
                <ProgressGraphBoard data={item.vasShippingInfo} planItem={item.vasShippingItemInfo.totalPlanItem}/>
              </ListLi>
            ))}
            </ListUl>
            </div>
      </BodyContent>
    </ContentWrap>
  )
}
ItemPerformance.propTypes = {
  progressList: PropTypes.array, //種まき進捗データリスト
  searchDate: PropTypes.string, // 検索日時
}


export default ItemPerformance

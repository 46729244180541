import React, { useEffect } from 'react'
import styled from 'styled-components'
//import PropTypes from 'prop-types'

const MediaWrap = styled.div`
  width:1798px;
  display: flex; /* 横並び */
  flex-wrap: wrap;
  margin: 4px;
  box-shadow: 0 0 3px 2px 'rgba(0,0,0,0.4)';
  background-color: #f2b3b3;
  color: #fff;
  font-size: 14px;
  font-weight: bold;

  text-align: center;
  align-items: center;
`
const MediaItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 40px;
  border-right: 2px dashed #fff;
`

const MediaTitle = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
  text-align:right;
` 
  /**
   * 秒数を時間(HHmm)の文字列で返す
   * @param {int} seconds 秒
   * @return {string} 時間(HHmm)の文字列
   */
  function secToTime(seconds) {
    if(!seconds) return '-';

    let hour = seconds / 3600 | 0
    let minutes = seconds % 3600 / 60 | 0
    
    return ('00' + hour.toString()).slice(-2) + ':' + ('00' + minutes.toString()).slice(-2)
  }
  function diff48Time(baseDt, dt) {

    if (!baseDt || !dt)
      return '-'
  
    let diff = new Date(dt).getTime() - new Date(baseDt).getTime()
  
    //HH部分取得
    let diffHour = diff / (1000 * 60 * 60);
  
    //MM部分取得
    //let diffMinute = (diffHour - Math.floor(diffHour)) * 60;
    let diffMinute = ((diff / 1000) - Math.floor(diffHour)*60*60)/60;
    //SS部分取得
    //let diffSecond = (diffMinute - Math.floor(diffMinute)) * 60;
  
    let time = ('00' + Math.floor(diffHour)).slice(-2) + ':' + ('00' + Math.floor(diffMinute)).slice(-2)
  
    return time
  }

const MediaHeaderDaily = (props) => {
  const {
    personalList
  } = props  
  // 初期値
  useEffect(() => {
  })
  //集計  
  const makeSummary=(type)=>{
    var sum = 0

    switch(type){
      case 0:
        personalList.map((data,index)=>{
          sum += parseInt(data.resultItemNum)
        })
        sum = sum.toLocaleString()
        break;
      case 1:
        personalList.map((data,index)=>{
          sum += parseInt(data.resultNum)
        })
        sum = sum.toLocaleString()
        break; 
      case 2:
        personalList.map((data,index)=>{
          sum += parseInt(data.avgNum)
        })
        sum = sum / personalList.length
        if(isNaN(sum)) sum = 0
        sum = sum.toFixed(0).toLocaleString()
        break;
      case 3:
        personalList.map((data,index)=>{
          sum += parseInt(data.workTimeSec)
        })
        if(isNaN(sum)) sum = 0
        sum = secToTime(sum)
        break;
      case 4:
        personalList.map((data,index)=>{
          sum += parseInt(data.vasWorkTimeSec)
        })
        if(isNaN(sum)) sum = 0
        sum = secToTime(sum)
        break;
      case 5:
        personalList.map((data,index)=>{
          sum += parseInt(data.avgNum)
        })
        if(isNaN(sum)) sum = 0
        if(personalList.length>0) sum = sum/personalList.length
        sum = sum.toFixed(0).toLocaleString()
        break;
      case 6:
        sum = '-'
        var min = 0
        if(personalList.length > 0) {
          min = new Date(personalList[0].targetDt)
          min = min.setDate(min.getDate()+1)
        }
        personalList.map((data,index)=>{
          if(min > new Date(data.workStartAt)) {
            min = new Date(data.workStartAt)
            sum = diff48Time(data.targetDt,data.workStartAt)
          }
        })
        break;
      case 7:
        sum = '-'
        var max = 0
        if(personalList.length > 0) max = new Date(personalList[0].targetDt)
        personalList.map((data,index)=>{
          if(max < new Date(data.workEndAt)) {
            max = new Date(data.workEndAt)
            sum = diff48Time(data.targetDt,data.workEndAt)
          }
        })
        break;
      default:
        break;
    }

    return sum
  }
  return (
    <MediaWrap>
      <MediaItem>
        <MediaTitle style={{width:'150px',textAlign:'center'}}>集計</MediaTitle>
      </MediaItem>
      <MediaItem>
        <MediaTitle style={{width:'300px',textAlign:'center'}}>{personalList.length}</MediaTitle>
      </MediaItem>
      <MediaItem>
        <MediaTitle style={{width:'160px'}}><div style={{marginRight:'2rem'}}>{makeSummary(0)}</div></MediaTitle>
      </MediaItem>
      <MediaItem>
        <MediaTitle style={{width:'160px'}}><div style={{marginRight:'2rem'}}>{makeSummary(1)}</div></MediaTitle>
      </MediaItem>
      <MediaItem>
        <MediaTitle style={{width:'160px'}}><div style={{marginRight:'2rem'}}>{makeSummary(5)}</div></MediaTitle>
      </MediaItem>
      <MediaItem>
        <MediaTitle style={{width:'150px',textAlign:'center'}}>{makeSummary(6)}</MediaTitle>
      </MediaItem>
      <MediaItem>
        <MediaTitle style={{width:'150px',textAlign:'center'}}>{makeSummary(7)}</MediaTitle>
      </MediaItem>
      <MediaItem>
        <MediaTitle style={{width:'150px',textAlign:'center'}}>{makeSummary(3)}</MediaTitle>
      </MediaItem>
      <MediaItem>
        <MediaTitle style={{width:'150px',textAlign:'center'}}>{makeSummary(4)}</MediaTitle>
      </MediaItem>
    </MediaWrap>
  )
}

MediaHeaderDaily.propTypes = {
}

export default MediaHeaderDaily

import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import { convertPxToRem, measurePxText } from '../../../Common/Utilties/StringUtility'
const MediaWrap = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  text-align: center;
  align-items: center;
  color: #000;
  font-size:  1.0vw;
  text-align: center;
  align-items: center;
  width: 1774px;
  margin: 4px;
  box-shadow: 0 0 3px 2px ${props => props.selected ? 'rgba(0,128,0,0.8)' : 'rgba(0,0,0,0.4)'};
`
const MediaItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 33px;
  border-right: ${props => props.borderNone ? '': '1px solid #000'};
`

const TextWrap = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
` 

const MaterialHandlingItem = (props) => {
  const {
    searchDate,
    data,type,dialog
  } = props

  // 初期値
  useEffect(() => {
  })
  const createElementName = (name,num) => {
    if (name) {
      let text = name
      let textPx = measurePxText(text,props.dialog ? '1.2vw;': '0.8vw;')
      let textRem = convertPxToRem(textPx)
      let checkRem = num
      if (textRem < checkRem)
      {
        return <>{name}</>
      }
  
      let editedText = text
      while (textRem > checkRem) {
        editedText = editedText.slice(0, -1)
        textPx = measurePxText(editedText + '...',props.dialog ? '1.2vw;': '0.8vw;')
        textRem = convertPxToRem(textPx)
        console.log(textRem,checkRem,editedText)
      }
  
      editedText = editedText + '...'
  
      return <Tooltip title={text}><div>{editedText}</div></Tooltip>
    }

    return <></>
  }

  //if (!data) return null;
  //console.log(data);
  return (
    <MediaWrap>
      <MediaItem>
        <TextWrap style={{width:'80px', textAlign:'center'}}>
          <div>{data.index}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'100px', textAlign:'center'}}>
          <div>{data.truckId}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'250px', textAlign:'center'}}>
          <div>{createElementName(data.companyName,13)}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'250px', textAlign:'center'}}>
          <div>{createElementName(data.driverName,13)}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'80px', textAlign:'center'}}>
          <div>{data.automobileRank}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'80px', textAlign:'center'}}>
          <div>{data.temperatureArea}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'80px', textAlign:'center'}}>
          <div>{data.work}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'180px', textAlign:'center'}}>
          <div>{data.workTime}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'250px', textAlign:'center'}}>
          <div>{createElementName(data.workplaceName,13)}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'80px', textAlign:'center'}}>
          <div>{data.cago}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'80px', textAlign:'center'}}>
          <div>{data.cart}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'80px', textAlign:'center'}}>
          <div>{data.coldCago}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'80px', textAlign:'center'}}>
          <div>{data.dolly}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem borderNone={true}>
        <TextWrap style={{width:'80px', textAlign:'center'}}>
          <div>{data.trauckTemperature}</div>
        </TextWrap>
      </MediaItem>
    </MediaWrap>
  )
}

MaterialHandlingItem.propTypes = {
}

export default MaterialHandlingItem

import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
//import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { GetApi } from '../../../Common/ApiAxios'
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'
import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'

import CardTotal from '../Atoms/CardTotal'
import ProcessPieGraph from './ProcessPieGraph'

const DefaultUrl = '/api/logisticManagement'

const Flex = styled.div`
  display: flex; /* 横並び */
  flex-direction: row;
  width: 100%;
  height: 410px;
  text-align: center;
  vertical-align: middle;
  margin-top: 10px;
  margin-bottom: 5px;
`
const ContentRow = styled.div`
  display: flex; /* 横並び */
  flex-direction: column;
`
const Content = styled.div`
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  height: 410px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px;
  ::-webkit-scrollbar-corner {
    display: none;
  }
`

// ヘッダー部のカード
const ProgressHeader = (props) => {
  const {
    weekly
  } = props
  const dispatch = useDispatch();
  //const alert = useAlert();
  const intervalRef = useRef(null);
  const [quantityPlan,setQuantityPlan] = useState(null);
  //const [totalProgressInfo,seTtotalProgressInfo] = useState({progress:0.0});
  //const [plaEndInfo, setPlanEndInfo] = useState({planEndAt:LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD') + " 00:00:00"});

  const defaltValue = {agreementStaffAvgWage:0.0,aveWage:0.0,costProgressValue:0.0}
  const [monthlyData,setMonthlyData] = useState([defaltValue,defaltValue]);
  const [daylyData,setDaylyData] = useState([defaltValue,defaltValue]);

  const [processList,setProcessList] = useState([]);
  const [processMonthList,setProcessMonthList] = useState([]);


  // 物量計画の週情報の取得
  const apiGetQuantityWeekly = async () => {

    const apiUrl = DefaultUrl + '/getQuantityWeeklySummaryCard';
    const param = {
      workplaceId: '000011',
      targetYMD: LogisticUtility.getStringFromDate(new Date(weekly[0].dateValue), 'YYYY-MM-DD'),
    }

    const result = await GetApi(apiUrl, param);

    //dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('物量計画の週情報取得失敗');
      setDaylyData(null);
    } else {
      //console.log(result);
      console.log(result.data)

      if(result.data.length==2){
        result.data[0].workTimeRate = 0
        if(result.data[0].workTime && result.data[1].workTime ){
          result.data[0].workTimeRate = result.data[0].workTime/result.data[1].workTime * 100
        }
        result.data[0].productivityRate = 0
        if(result.data[0].productivity && result.data[1].productivity ){
          result.data[0].productivityRate = result.data[0].productivity/result.data[1].productivity * 100
        }
        setDaylyData(result.data[0]);
      }
    }
  }

  // 描画時に一度だけ実行
  useEffect(() => {
    apiGetProcessPieGraph()
    apiGetQuantityWeekly()
    intervalRef.current = setInterval(() => {
      apiGetProcessPieGraph()
      apiGetQuantityWeekly()
  }, 1000 * 60); // 60秒間隔で変更
    return () => {
      onClearInerval();
    };
  }, [weekly])
    

  // 周期初期のクリア
  const onClearInerval = async () => {
    if (intervalRef.current !== null)
      clearInterval(intervalRef.current);
    
    intervalRef.current = null;
  }
  // 進捗設定情報の取得
  const apiGetProcessPieGraph = async (isLoadSplinner) => {
    //console.log('call getActivityProgressManagement');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getWeeklyPieGraph';
   
    const data = {
      workplaceId: '000011',
      targetYMD: LogisticUtility.getStringFromDate(new Date(weekly[0].dateValue), 'YYYY-MM-DD'),
      //targetYMD:'2023-02-24'
    }
    const result = await GetApi(apiUrl, data);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      //alert.error('条件の該当データがありません(進捗設定情報の取得)');
    } else {
      console.log(result.data);
      setProcessList(result.data[0]);
      setProcessMonthList(result.data[1]);
    }
  }

  if (!processList || !daylyData) return null
  return (
    <Flex>
      <Content>
        <ContentRow>
          <CardTotal totalData={daylyData} type={4} />
          <CardTotal totalData={daylyData} type={5} />
          <CardTotal totalData={monthlyData} type={6} />  
          <CardTotal totalData={monthlyData} type={7} />    
        </ContentRow>
      </Content>
      <ProcessPieGraph processList={processList} title={'工程比率'}/>
      <Content>
        <ContentRow>         
          <CardTotal totalData={monthlyData} type={8} />  
          <CardTotal totalData={monthlyData} type={9} />    
        </ContentRow>
      </Content>
      <ProcessPieGraph processList={[]} title={'事故比率'}/>
    </Flex>
  )
}

ProgressHeader.propTypes = {
  grants: PropTypes.object, // 権限関連
  totalDatas: PropTypes.object, // 集計情報
  wageType: PropTypes.number, // 平均時給タイプ　0:契約から算出,1:労務費から算出
}
  
export default ProgressHeader


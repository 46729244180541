import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import { useSelector } from 'react-redux'
//import FUNC from '../../Common/FunctionEynum'
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'

//import { GetApi } from '../../Common/ApiAxios'
//import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import * as LogisticUtility from '../../Common/Utilties/LogisticUtility'
//import ErrorHandling from '../../Common/ErrorHandling'

import OperationHeader from './Molcules/OperationHeader'
import OperationList from './Molcules/OperationList'
import OperationMaterialHandling from './Molcules/OperationMaterialHandling'
import OperationTypeTab from './Molcules/OperationTypeTab'
import OperationMaterialHandlingPrediction from './Molcules/OperationMaterialHandlingPrediction'
const DefaultUrl = '/api/logisticManagement'

const BodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: row;

  vertical-align: middle;
  padding:0.5rem;
`
const TitleContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: row;
  text-align: center;
  vertical-align: middle;
  width: 100%;
`
const ContentColumn = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;

  vertical-align: middle;
  padding:0.5rem;
`

const ProgressContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;

  vertical-align: middle;
  width: ${props => props.width ? props.width : '500px'};
  height: ${props => props.height ? props.height : '170px'};
  background-color: whitesmoke;
`

const ContentWrap = styled.div`
  display: flex; /* 横並び */
  flex-direction: column;
  vertical-align: middle;

  width: 100%;
  height: 100%;
`

const Title = styled.div`
  display: flex; /* 横並び */
  flex-direction: row;
  font-weight: bold;
  color: #000;
  text-align: left;
  font-size: 2rem;
  margin: 5px;
`

const ProgressTitle = styled.div`
  font-weight: bold;
  color: #000;
  text-align: center;
  font-size: 1.8rem;
  width 100%;
  padding:0.5rem;
  margin-top:0.7rem;

`
const ProgressImageTitle = styled.div`
  font-weight: bold;
  color: #000;
  text-align: center;
  font-size: 1.8rem;
  width 100%;
  padding:0.5rem;
  margin-top:0.7rem;
  margin-left:24px;
`

const ProgressContentRow = styled.div`
  font-weight: bold;
  color: #000;
  text-align: center;
  font-size: 2rem;
`
const ProgressSubContentRow1 = styled.div`
  font-weight: bold;
  color: #000;
  text-align: center;
  font-size: 1.5rem;
`
const ProgressSubContentRow = styled.div`
  font-weight: bold;
  color: #000;
  text-align: center;
  font-size: 1rem;
`

const ProgressSubBody= styled.div`
  display: flex; /* 縦並び */
  flex-direction: row;

  text-align: center;
  vertical-align: middle;
  padding:0.3rem;
  width: 500px;
  height: 100px;
`
const ProgressSubBody1= styled.div`
  display: flex; /* 縦並び */
  flex-direction: row;

  text-align: center;
  vertical-align: middle;
  padding:0rem;
  width: 500px;
  height: 100px;
`
const ProgressSubContent = styled.div`
  display: flex; /* 横並び */
  flex-direction: column;
  vertical-align: middle;
  width: 250px;
  padding:0.5rem;
`

const ProgressStaffRow = styled.div`
  font-weight: bold;
  color: #000;
  text-align: center;
  font-size: 2rem;
  padding:0.4rem;
`

const ProgressPerformance = (props) => {
  const {
    headerType,
    matehan,
    result
  } = props

  // 描画時に一度だけ実行
  useEffect(() => {
  }, [])


  return (
    <>
    <ContentWrap>
      {/* <Title>
        {makeTitle()}
        <OperationTypeTab type={1}/>
      </Title> */}
      <OperationHeader quantity={result}/>
      {/*2023/12/25 K.Harada DEL アラートリストを削除*/}
      {headerType &&
        <OperationList quantity={1000}/>
      }
      {/*2023/12/25 K.Harada ADD type追加 0:マテハン出入状況,1:マテハン在庫数,2:翌日出荷予定マテハン数*/}
      <OperationMaterialHandling quantity={matehan} type={0}/>
      {!headerType &&
      <>
        <OperationMaterialHandlingPrediction quantity={1000} type={2}/>
        <OperationMaterialHandling quantity={1000} type={1}/>
      </>
      }
    </ContentWrap>
    </>
  )
}
ProgressPerformance.propTypes = {
  
}

export default ProgressPerformance

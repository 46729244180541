import React, { useState } from 'react'
//import { useSelector, useDispatch } from 'react-redux'
//import { useAlert } from 'react-alert'
//import FUNC from '../../Common/FunctionEynum'
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {XAxis, YAxis, Tooltip, Legend, Line, Bar,ComposedChart } from "recharts"
import ToolTips from '@material-ui/core/Tooltip'

//import ErrorHandling from '../../Common/ErrorHandling'
import {onExportCsvFile} from '../../../Common/Utilties/ExportUtility'
import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'

import IconDownloadImg from '../../../../image/download-icon.png'
import IconSettingImg from '../../../../image/setting-icon.png'

import AveWageVisibleDialog from './AveWageVisibleDialog'

const FlexWrap = styled.div`
  display: flex;
  flex-direction: column;
`
const ContentWrap = styled.div`
  display: flex;
  flex-direction: row;

  justify-content:flex-end;
`
const Header = '日付,現場ID,現場名,平均時給,地域最低時給,相場最低時給,相場最高時給,相場下限平均時給,相場上限平均時給,スタッフ数'

const AveWageGraph = (props) => {
  const {
    processList,type,wageType
  } = props
  //const { loginUserInfo } = useSelector(state => state);

  //const dispatch = useDispatch();
  //const alert = useAlert();

  const [open,setOpen] = useState(false)
  const visibleDefalt = 
    {
      localAvgWage: false,
      localMinWage: false,
      marketMinWage: false,
      marketMaxWage: false,
      marketLowerAvgWage: false,
      marketUpperAvgWage: false,
    }
  const [visibleList,setVisibleList] = useState(visibleDefalt)
  const onOpen=()=>{
    setOpen(true)
  }
  const onClose=()=>{
    setOpen(false)
  }

  const onExport=()=>{
    let result = Header + '\r\n'
    processList.map((workInfo)=>{
      var lineResult =
          workInfo.dateValue + ','
          + workInfo.workplaceId + ','
          + workInfo.workplaceName + ','
          + (wageType?workInfo.aveWage:workInfo.agreementStaffAvgWage) + ','
          + workInfo.localMinWage + ','
          + workInfo.marketMinWage + ','
          + workInfo.marketMaxWage + ','
          + workInfo.marketLowerAvgWage + ','
          + workInfo.marketUpperAvgWage + ','
          + workInfo.staffNum + ','
      lineResult = lineResult.replaceAll('null','')
      result += lineResult
      
      result += '\r\n'
    })
    let fileName = LogisticUtility.getStringFromDate(new Date(), 'YYYYMMDD')+"平均時給（"+(wageType?"月別":"日別")+"）"
    onExportCsvFile(result,fileName)
  }
  return (
    <FlexWrap>
      <ContentWrap>
        <ToolTips title={'ダウンロード'}>
        <div onClick={()=>onExport()} style={{cursor:'pointer'}}>
          <img alt='ダウンロード' src={IconDownloadImg} width="25px" height="25px"/>
        </div>
        </ToolTips>
        <ToolTips title={'表示項目選択'}>
        <div onClick={()=>onOpen()} style={{cursor:'pointer'}}>
          <img alt='表示項目選択' src={IconSettingImg} width="25px" height="25px" style={{marginLeft: '3px',marginRight: '40px'}}/>
        </div>
        </ToolTips>
      </ContentWrap>
      <ContentWrap>
      <ComposedChart 
            width={1690}
            height={370}
            data={processList}
            margin={{top: 10, right: 0, left: 0, bottom: 5}}>
              <XAxis xAxisId={1} dataKey="dateValue"/>
              <YAxis yAxisId={1}  domain={[0, dataMax => (dataMax+(500 - dataMax%500))]} tickCount={6}/>
              <YAxis yAxisId={2}  domain={[0, dataMax => (dataMax+(5 - dataMax%5))]} tickCount={6} orientation="right"/>
              <Tooltip formatter={(value) => new Intl.NumberFormat('ja-JP', {maximumFractionDigits: 0}).format(value.toFixed(0))} />
              <Bar type="bar" xAxisId={1} yAxisId={2} strokeWidth={2} dataKey="staffNum" name="スタッフ数" fill="rgba(254,97,132,0.8)" />          
              {!wageType &&
                <Line type="plainline" xAxisId={1} yAxisId={1} strokeWidth={2} dataKey="agreementStaffAvgWage" name="平均時給" stroke="rgba(54,164,235,0.8)" />
              }
              {wageType &&
                <Line type="plainline" xAxisId={1} yAxisId={1} strokeWidth={2} dataKey="aveWage" name="平均時給" stroke="rgba(54,164,235,0.8)" />  
              }
              {visibleList.localMinWage &&
                <Line type="plainline" xAxisId={1} yAxisId={1} strokeWidth={2} dataKey="localMinWage" name="地域最低時給" stroke="rgba(180,205,127,0.8)" />  
              }
              {visibleList.localAvgWage && !wageType &&
                <Line type="plainline" xAxisId={1} yAxisId={1} strokeWidth={2} dataKey="localAgreementStaffAvgWage" name={type?'当年平均時給':'当月平均時給'} stroke="rgba(233,163,38,0.8)" />
              }
              {visibleList.localAvgWage && wageType &&
                <Line type="plainline" xAxisId={1} yAxisId={1} strokeWidth={2} dataKey="localAvgWage" name={type?'当年平均時給':'当月平均時給'} stroke="rgba(233,163,38,0.8)" />
              }
              {visibleList.marketMinWage &&
                <Line type="plainline" xAxisId={1} yAxisId={1} strokeWidth={2} dataKey="marketMinWage" name="相場最低時給" stroke="rgba(0,106,108,0.8)" />
              }
              {visibleList.marketMaxWage &&
                <Line type="plainline" xAxisId={1} yAxisId={1} strokeWidth={2} dataKey="marketMaxWage" name="相場最高時給" stroke="rgba(122,188,255,0.8)" />  
              }
              {visibleList.marketLowerAvgWage &&
                <Line type="plainline" xAxisId={1} yAxisId={1} strokeWidth={2} dataKey="marketLowerAvgWage" name="相場下限平均時給" stroke="rgba(0,148,122,0.8)" />
              }
              {visibleList.marketUpperAvgWage &&
                <Line type="plainline" xAxisId={1} yAxisId={1} strokeWidth={2} dataKey="marketUpperAvgWage" name="相場上限平均時給" stroke="rgba(0,255,255,0.8)" />  
              }
              <Legend/>
      </ComposedChart >
      </ContentWrap>
      <AveWageVisibleDialog type={type} open={open} onCloseFunc={onClose} visibleList={visibleList}setVisibleList={setVisibleList}/>
    </FlexWrap>
  )
}

AveWageGraph.propTypes = {
  processList: PropTypes.array, // 工程情報
  type: PropTypes.number, // 0：日単位(上グラフ),1:月単位(下グラフ)
  wageType: PropTypes.number, // 平均時給タイプ　0:契約から算出,1:労務費から算出
}

export default AveWageGraph

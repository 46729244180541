import React, { useEffect,useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {PieChart,Pie,Cell } from "recharts"
import { useSelector } from 'react-redux'

import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'

//import ResultDialog from './ResultPatternDialog'
//import ResultStoreDialog from './ResultStoresPatternDialog'
import PlanDateDialog from './PlanDateDialog'
import EditIcon from '../../../../image/edit-icon.png'

const BodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  overflow: hidden;
  vertical-align: middle;
  margin-left:10px;
  width: 280px;
  height: 260px;
  box-shadow: 2px 2px 6px rgba(0,0,0,.4);
  background-color: ${props => props.contentColor?'salmon':'#fff'};
`

const Process = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: ${props => props.width ? props.width : '100%'};
  text-align:center;
  vertical-align:middle;
  padding: 1vw;
`

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  text-align: center;
  vertical-align: middle;
  padding: 0.35rem;
  opacity: ${props => props.contentOpacity?1:0};
`

const ContentBlock = styled.div`
  display: flex; /* 横並び */
  flex-direction: row;
  text-align: center;
  vertical-align: middle;
  padding: 0.5rem;
`
const Content = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  text-align: center;
  vertical-align: middle;
`

const ProcessTitle = styled.div`
  font-weight: bold;
  color: #000;
  text-align: center;
  font-size: 1.2rem;
`
const ProgressContentRow = styled.div`
  color: #000;
  text-align: center;
  font-size: 1.4rem;
`
const ProgressContentRow1 = styled.div`
  color: #000;
  text-align: center;
  font-size: 2rem;
`
const ProgressContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: row;

  vertical-align: middle;
  width: ${props => props.width ? props.width : '500px'};
  height: ${props => props.height ? props.height : '170px'};
  background-color: whitesmoke;

  margin-top: ${props => props.top ? props.top : '55px'};
  margin-left: ${props => props.left ? props.left : '20px'};
  margin-right: ${props => props.right ? props.right : '0px'};
`
const TitleContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: row;
  text-align: center;
  vertical-align: middle;
  width: ${props => props.width ? props.width : '100%'};
`
const ContentRow =  styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: ${props => props.width ? props.width : '100%'};
  vertical-align: middle;
  padding:0.3rem;
`

const Title = styled.div`
  font-weight: bold;
  color: #000;
  text-align: left;
  font-size: 2rem;
`

const ProgressTitle = styled.div`
cursor: pointer;
font-weight: bold;
color: blue;
text-decoration:underline;
text-align: center;
font-size: 1.8rem;
width 100%;
padding:0.5rem;
margin-top:0.7rem;

:hover{
  color: mediumblue;
}
`
const ProgressTitle2 = styled.div`
font-weight: bold;
color: #000;

text-align: center;
font-size: 1.8rem;
width 100%;
`
const ProgressContentItemRow = styled.div`
  color: #000;
  text-align: center;
  font-size: 2.2rem;
`

const ProgressSubContentRow1 = styled.div`
  font-weight: bold;
  color: #000;
  text-align: center;
  font-size: 1.8rem;
  margin-top: 1rem;
`
const ProgressSubContentRow = styled.div`
  font-weight: bold;
  color: #000;
  text-align: center;
  font-size: 1.5rem;
`

const ProgressSubBody= styled.div`
  display: flex; /* 縦並び */
  flex-direction: row;

  text-align: center;
  vertical-align: middle;
  padding:0.3rem;
  width: 500px;
  height: 100px;
`
const ProgressSubBody1= styled.div`
  display: flex; /* 縦並び */
  flex-direction: row;

  text-align: center;
  vertical-align: middle;
  padding:0rem;
  width: 500px;
  height: 100px;
`
const ProgressSubContent = styled.div`
  display: flex; /* 横並び */
  flex-direction: column;
  vertical-align: middle;
  width: 250px;
  padding:0.5rem;
`

const ProgressSubBody2= styled.div`
  display: flex; /* 縦並び */
  flex-direction: row;

  text-align: center;
  vertical-align: middle;
  padding:0rem;
  width: 330px;
  height: 100px;
`
const ProgressSubContent1 = styled.div`
display: flex; /* 横並び */
flex-direction: column;
vertical-align: middle;
width: 165px;
padding:0.5rem;
`
const ProgressTitle1 = styled.div`
  font-weight: bold;
  color: #000;
  text-align: center;
  font-size: 1.8rem;
  width 100%;
  padding:0.5rem;
`

const cx = 115;
const cy = 115;
const iR = 70;
const oR = 90;

const PatternGraph = (props) => {
  const {
    entry,opacity,searchDate,progressList,onChange,patternList
  } = props
  const { loginUserInfo } = useSelector(state => state);
  const isExternalUser = LogisticUtility.checkExternalUser(loginUserInfo.grantNameId);
  const [data,setData] = useState([]);
  const [openDialog,setOpenDialog] = useState(false);
  const [openStoreDialog,setOpenStoreDialog] = useState(false);
  const history = useHistory();

  useEffect(() => {
    //console.log(entry)

    if(entry.name == '米飯　BIG北・信'){
      patternList.map((tmp)=>{
        if(tmp.blockName == 'ニ' && tmp.patternCd == 'SOUZAI'){
          entry.totalItem = Number(entry.totalItem)+Number(tmp.totalItem)
          entry.totalPlanItem = Number(entry.totalPlanItem)+Number(tmp.totalPlanItem)
          entry.totalItem2 = tmp.totalItem2
          entry.totalPlanItem2 = tmp.totalPlanItem2
          entry.totalItemCountSouzai =  tmp.totalItemCountSouzai
          entry.totalPlanItemCountSouzai = tmp.totalPlanItemCountSouzai
          entry.totalShippingSouzai = tmp.totalShippingSouzai
          entry.totalPlanShippingSouzai = tmp.totalPlanShippingSouzai
        }
      })
    }
    if(entry.name == '米飯　BIG中松愛'){
      patternList.map((tmp)=>{
        if(tmp.blockName == 'イチ' && tmp.patternCd == 'SOUZAI'){
          entry.totalItem = Number(entry.totalItem)+Number(tmp.totalItem)
          entry.totalPlanItem = Number(entry.totalPlanItem)+Number(tmp.totalPlanItem)
          entry.totalItem2 = tmp.totalItem2
          entry.totalPlanItem2 = tmp.totalPlanItem2
          entry.totalItemCountSouzai =  tmp.totalItemCountSouzai
          entry.totalPlanItemCountSouzai = tmp.totalPlanItemCountSouzai
          entry.totalShippingSouzai = tmp.totalShippingSouzai
          entry.totalPlanShippingSouzai = tmp.totalPlanShippingSouzai
        }
      })

    }
    setData(
    [
      { name: '実績', value: Number(entry.totalItem), color: '#40C1C0' },
      { name: '残り', value: Number(entry.totalPlanItem-entry.totalItem), color: 'lightgray' },])
  }, [entry])

    /**
   * 勤務時間(HH:mm)を秒数で返す
   * @param {string} time 勤務時間(HH:mm)
   * @return {int} 秒数
   */
   function timeToSec(time) {
    if(!time)
      return 0
    let tokens = time.split(':')
    let sec = 0

    if (tokens[0]) sec += Number(tokens[0]) * 3600
    if (tokens[1]) sec += Number(tokens[1]) * 60

    return sec
  }

  const makeTitle=()=>{
         
    //進捗工数
    var totalTime = progressList.operationHour
    //稼働スタッフ
    var staffNum = progressList.planVasStaffNum
    staffNum = entry.ownerCd=='1'&&  entry.patternCd=='RETAIL'?staffNum-3:1
  
    //予定生産性
    var productivity = progressList.totalPlanItem/progressList.planVasWorkTime
    //残課題
    var reminng = progressList.totalPlanItem - progressList.totalItem
    //残り必要工数
    var diffEnd = reminng/(productivity*staffNum)*60
    diffEnd = Math.round(diffEnd)

    //現在時刻
    var now = new Date()
    var nowDate = now.getFullYear() + '-' + ('00'+Number(now.getMonth()+1)).slice(-2)+ '-' + ('00'+now.getDate()).slice(-2)
    if(!progressList  || !progressList.operationHour)return '【終了予定】'+'hh:mm'

    //種まき終了予定時間
    var planStartTime =  timeToSec(entry.startTime)

    var endPlanSecond= entry.totalPlanItem/productivity/staffNum + planStartTime/3600
    var endPlanHour = Math.floor(endPlanSecond)
    var endPlanMin = Math.floor(endPlanSecond*60%60)


    //終了予定時間
    var endTime = ('00'+endPlanHour).slice(-2) + ':' + ('00'+endPlanMin).slice(-2)

    if(timeToSec(endTime)>timeToSec(entry.endTime)) endTime = entry.endTime
    return '【終了予定】'+endTime
  }

  const makeContent=(progress,plan)=>{
    if(progress && plan) return <><span style={{fontWeight: 'bold'}}>{Number(progress).toLocaleString()}</span> <span style={{fontSize:'90%'}}>/</span> <span style={{fontSize:'50%'}}>{Number(plan).toLocaleString()}</span></>
    if(progress==0 && plan) return <><span style={{fontWeight: 'bold'}}>{Number(progress).toLocaleString()}</span> <span style={{fontSize:'90%'}}>/</span> <span style={{fontSize:'50%'}}>{Number(plan).toLocaleString()}</span></>
    return <><span style={{fontWeight: 'bold'}}>{Number(0).toLocaleString()}</span> <span style={{fontSize:'90%'}}>/</span> <span style={{fontSize:'50%'}}>{Number(0).toLocaleString()}</span></>
  }
  const makeContentProductivity=(progress,plan)=>{
    var colorCode = entry.shootProductivityDiffFlag==2?'#009EEC':'black'

    if(progress && plan) return <><span style={{fontWeight: 'bold',color:colorCode}}>{Number(progress).toLocaleString()}</span> <span style={{fontSize:'90%'}}>/</span> <span style={{fontSize:'50%'}}>{Number(plan).toLocaleString()}</span></>
    if(progress==0 && plan==0) return <><span style={{fontWeight: 'bold',color:colorCode}}>{Number(progress).toLocaleString()}</span> <span style={{fontSize:'90%'}}>/</span> <span style={{fontSize:'50%'}}>{Number(plan).toLocaleString()}</span></>
    return <></>
  }

  const setOpacityAndClor=()=>{
    if(!entry) return false
    if(entry.totalItem>=entry.totalPlanItem) return false

    //稼働スタッフ
    var staffNum = progressList.planVasStaffNum
    staffNum = entry.ownerCd=='1'&&  entry.patternCd=='RETAIL'?staffNum-3:1
    //予定生産性
    var productivity = progressList.totalPlanItem/(progressList.planVasWorkTime)
    //現在時刻
    var now = new Date()
    var nowStartTime = new Date(now.getFullYear(),now.getMonth(),now.getDate(),0,0,0) 

    var nowDate = now.getFullYear() + '-' + ('00'+Number(now.getMonth()+1)).slice(-2)+ '-' + ('00'+now.getDate()).slice(-2)
    if(nowDate != searchDate) return false;

    var diff = now.getTime() - nowStartTime.getTime() - timeToSec(entry.startTime)*1000
    var diffHour = diff/3600/1000

    return entry.totalItem<=diffHour*productivity*staffNum
  }

  const makePasentage=(progress,plan)=>{
    if(!progress || !plan || plan==0) return '0.00'
    return (progress / plan *100).toFixed(2)
  }
  const makeName=(progress)=>{
    if(!progress) return '';
    if(progress == '米飯　BIG北・信') return '米惣 北陸・信州';
    if(progress == '米飯　BIG中松愛') return '米惣 中松愛';
    return progress
  }
  const vasItem =()=> {
    var state = {type:1,ownerCd:entry.ownerCd,searchDate:searchDate,pattrnName:entry.name}
    history.push({state:state,pathname:'/logistic/vasItem'});
  }
  const vasStore =()=> {
    var state = {type:1,ownerCd:entry.ownerCd,searchDate:searchDate,pattrnName:entry.name}
    history.push({state:state,pathname:'/logistic/vasStore'});
  }
  const onClickImage=()=>{
    setOpenDialog(true)
  }

    /**
   * カゴ車進捗画面に遷移
   * @param
   */
    const vasCargo =()=> {
      var state = 
        {
          ownerCd:entry.ownerCd,//荷主番号
          searchDate:searchDate,//検索日付
          batchNo:entry.batchNo,//バッチ番号
          baseId:2,//拠点ID:尾西センター
          workplaceId:'000006',//現場ID:尾西センター
          ownerName:entry.ownerName,//荷主名
          pattrnName:makeName(entry.name),//パターン名
          patternCd:entry.patternCd//パターンコード
        }
  
      history.push({state:state,pathname:'/logistic/vascargo'});
    }
    /**
     * カゴ車台数
     * @param {String} progress カゴ車台数
     * @returns カゴ車台数表示用
     */
    const vasCargoNum =(progress)=> {
      console.log(progress)
      if(!progress) return '0'
      return Number(progress).toFixed(0)
    }
  return (
  <>
  <ContentBlock>
    <ContentWrap contentOpacity={(setOpacityAndClor())?opacity:true}>
        <BodyContent contentColor={setOpacityAndClor()}>
            <Process>
                <div><ProcessTitle>{makeName(entry.name)}</ProcessTitle></div>
                <PieChart width={280} height={133}>
                <Pie
                dataKey="value"
                startAngle={180}
                endAngle={0}
                data={data}
                cx={cx}
                cy={cy}
                innerRadius={iR}
                outerRadius={oR}
                fill="#8884d8"
                stroke="none"
                >
                {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
                </Pie>
                {/* グラフタイトル */}
                <text x={cx} y={cy} dx={8}  fontSize={'24px'} textAnchor="middle" fill={'black'} fontWeight={'bold'}>{makePasentage(entry.totalItem,entry.totalPlanItem)+'%'}</text>
                <text x={cx-oR+15} y={cy} dy={18} textAnchor="middle" fill={'black'}>{'0'}</text>
                <text x={cx+oR-5} y={cy} dy={18} textAnchor="middle" fill={'black'}>{'100'}</text>
                </PieChart>
            </Process>
            <>
              <div>{'作業物量進捗'}</div>
              <ProgressContentRow>{makeContent(entry.totalItem,entry.totalPlanItem)}</ProgressContentRow>
            </>
        </BodyContent>
      </ContentWrap>
      <ProgressContent height={'260px'} top={'6px'} left={'30px'}>
          <ContentRow>
            {!isExternalUser &&
            <div style={{ height:'16px', marginLeft:'auto', marginRight:'10px', marginTop:'10px', cursor: 'pointer'}}>
                  <img alt='開く' className='open' src={EditIcon} title={'ダイアログを開く'} width="16px"  onClick={onClickImage}/>
            </div>
            }
            <TitleContent>
              <ProgressTitle2>種まき作業実績</ProgressTitle2>
            </TitleContent>
            <ProgressSubContentRow1>{makeTitle()}</ProgressSubContentRow1>
            <ProgressSubContentRow1>{makePasentage(entry.totalItem,entry.totalPlanItem)}%</ProgressSubContentRow1>
            <ProgressContentItemRow>{makeContent(entry.totalItem,entry.totalPlanItem)}</ProgressContentItemRow>
            <ProgressSubBody1>
              <ProgressSubContent>
                <ProgressSubContentRow>米飯</ProgressSubContentRow>
                <ProgressContentItemRow>{makeContent(entry.totalItem1,entry.totalPlanItem1)}</ProgressContentItemRow>
              </ProgressSubContent>
              <ProgressSubContent>
                <ProgressSubContentRow>惣菜</ProgressSubContentRow>
                <ProgressContentItemRow>{makeContent(entry.totalItem2,entry.totalPlanItem2)}</ProgressContentItemRow>
              </ProgressSubContent>
            </ProgressSubBody1>
          </ContentRow>
      </ProgressContent>
      <ProgressContent width={'1000px'} height={'260px'} top={'6px'}>
        <ContentRow>
            <TitleContent>
              <ProgressTitle onClick={()=>vasStore(true)}>店舗別</ProgressTitle>
            </TitleContent>
            <ProgressSubBody2>
              <ProgressSubContent1>
                <ProgressTitle1>米飯</ProgressTitle1>
                <ProgressContentRow1 style={{fontWeight:'bold'}}>{makePasentage(entry.totalShipping,entry.totalPlanShipping)}%</ProgressContentRow1>
                <div style={{height:'1.8rem'}}/>
                <ProgressContentRow1>{makeContent(entry.totalShipping,entry.totalPlanShipping)}</ProgressContentRow1>
              </ProgressSubContent1>
              <ProgressSubContent1>
                <ProgressTitle1>惣菜</ProgressTitle1>
                <ProgressContentRow1 style={{fontWeight:'bold'}}>{makePasentage(entry.totalShippingSouzai,entry.totalPlanShippingSouzai)}%</ProgressContentRow1>
                <div style={{height:'1.8rem'}}/>
                <ProgressContentRow1>{makeContent(entry.totalShippingSouzai,entry.totalPlanShippingSouzai)}</ProgressContentRow1>
              </ProgressSubContent1>
            </ProgressSubBody2>
        </ContentRow>
        <ContentRow>
            <TitleContent>
              <ProgressTitle onClick={()=>vasItem(true)}>アイテム別</ProgressTitle>
            </TitleContent>
            <ProgressSubBody2>
              <ProgressSubContent1>
                <ProgressTitle1>米飯</ProgressTitle1>
                <ProgressContentRow1 style={{fontWeight:'bold'}}>{makePasentage(entry.totalItemCount,entry.totalPlanItemCount)}%</ProgressContentRow1>
                <div style={{height:'1.8rem'}}/>
                <ProgressContentRow1>{makeContent(entry.totalItemCount,entry.totalPlanItemCount)}</ProgressContentRow1>
              </ProgressSubContent1>
              <ProgressSubContent1>
                <ProgressTitle1>惣菜</ProgressTitle1>
                <ProgressContentRow1 style={{fontWeight:'bold'}}>{makePasentage(entry.totalItemCountSouzai,entry.totalPlanItemCountSouzai)}%</ProgressContentRow1>
                <div style={{height:'1.8rem'}}/>
                <ProgressContentRow1>{makeContent(entry.totalItemCountSouzai,entry.totalPlanItemCountSouzai)}</ProgressContentRow1>
              </ProgressSubContent1>
            </ProgressSubBody2>
        </ContentRow>
        <ContentRow>
            {/*2024/03/25 K.Harada ADD カゴ車台数を表示するように変更 */}
            <TitleContent>
              {/* <ProgressTitle2 style={{padding:'0.5rem',marginTop:'0.7rem'}}>カゴ車</ProgressTitle2> */}
              <ProgressTitle onClick={()=>vasCargo(true)}>カゴ車</ProgressTitle>
            </TitleContent>
            <div style={{height:'0.3rem'}}/>
            <ProgressContentRow1 style={{fontWeight:'bold', fontSize: '1.8rem'}}>{vasCargoNum(entry.cargoNum)}台</ProgressContentRow1>
            <div style={{height:'0.8rem'}}/>
            <ProgressContentRow1 style={{fontWeight:'bold',fontSize: '1.8rem'}}>（前日比 {makePasentage(entry.cargoNum,entry.cargoBeforeNum)}％）</ProgressContentRow1>
            {/* <div style={{height:'0.3rem'}}/>
            <ProgressContentRow1 style={{fontWeight:'bold', fontSize: '1.8rem'}}>{'0'}台</ProgressContentRow1>
            <div style={{height:'0.8rem'}}/>
            <ProgressContentRow1 style={{fontWeight:'bold',fontSize: '1.8rem'}}>（前日比 000.00%）</ProgressContentRow1> */}
        </ContentRow>
      </ProgressContent>
    </ContentBlock>
    <PlanDateDialog
        modalIsOpen={openDialog}
        setIsOpen={setOpenDialog}
        workplaceId={'000006'}
        searchDate={searchDate}
        ownerCd={entry.ownerCd}
        patternCd={entry.patternCd}
        onChange={onChange}
        endTime={entry.endTime}
        startTime={entry.startTime}
        baseId={'2'}
      />
      {/* <ResultStoreDialog
        modalIsOpen={openStoreDialog}
        setIsOpen={setOpenStoreDialog}
        workplaceId={'000006'}
        searchDate={searchDate}
        ownerCd={entry.ownerCd}
        patternName={entry.name}
      /> */}
    </>
  )
}
PatternGraph.propTypes = {
  entry: PropTypes.object, // パターン別種まき進捗データ
  opacity: PropTypes.bool, // グラフ点滅フラグ
  searchDate: PropTypes.string, // 検索日時
  progressList: PropTypes.object, // 全体種まき進捗データ
  onChange: PropTypes.func, // 種まき開始時間変更時処理
  patternList: PropTypes.array, // パターン別種まき進捗データ一覧
}

export default PatternGraph
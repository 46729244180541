import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
//import { useAlert } from 'react-alert'
import { useDispatch } from 'react-redux'
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { GetApi } from '../../../Common/ApiAxios'
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'
import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'

//import DateCalendar from '../../../Common/Atoms/Calendar'
import CloseIcon from '../../../../image/close_icon.png'

const DefaultUrl = '/api/logisticManagement'

/* 縦並 */
const Wrap = styled.div`
  display:flex;
  flex-flow: column;
`

/* 検索項目 */
const SearchItem = styled.div`
  display: flex; /* 横並び */
  flex-flow: row nowrap;
  text-align: left;
  vertical-align: middle;
`

const IndustryWrap = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: left;
  vertical-align: middle;
`

// 当月内訳のLegendのカスタム
function renderLegendText(value) {
  return <span style={{ color:"#000" }}>{value}</span>;
};
const InputYearlyGoalModal  = (props) => {
  const {
    grants,
    modalIsOpen,
    setIsOpen,
    workplaceId,
    searchDate,
  } = props
  const dispatch = useDispatch();
  const [scopeMonthRate, setScopeMonthRate] = useState([]);
  
  // 月単位のCO2排出量のカテゴリの集計の取得
  const apiGetTotalCategoryEmissionsMonthly = async (isLoadSplinner) => {
    //console.log('call apiGetTotalCategoryEmissionsMonthly');
    //console.log(searchDate)
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getTotalCategoryEmissionsMonthly';
    const param = {
      workplaceId: workplaceId,
      targetYMD: LogisticUtility.getStringFromDate(new Date(searchDate), 'YYYY-MM-DD'),
      industryId: '00005',
    }
    //console.log(param);
    const result = await GetApi(apiUrl, param);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
    } else {
      console.log(result.data);


      // 日単位の内訳（比率）算出
      {
        var now = new Date(searchDate);
        var year = now.getFullYear();
        var month = now.getMonth();
        //var month = now.getMonth() -1;
        var day = 1;
        //var day = 16;
        var targetDt = new Date(year, month, day);
        //var endDt = new Date(now.getFullYear(), now.getMonth(), 15);
        var list = new Array();
        //var total1 = 0;
        //var total2 = 0;
        //var total3 = 0;
        //console.log(endDt);
        while (targetDt.getMonth() === now.getMonth()) {
        //while (targetDt <= endDt) {
          var targetYMD = LogisticUtility.getStringFromDate(targetDt, 'YYYY-MM-DD');
          var list1 = result.data.filter((elem) => { return elem.scopeNum === '00001' && elem.targetYMD === targetYMD});
          var list2 = result.data.filter((elem) => { return elem.scopeNum === '00002' && elem.targetYMD === targetYMD});
          var list3 = result.data.filter((elem) => { return elem.scopeNum === '00003' && elem.targetYMD === targetYMD});
          var total1 = 0;
          var total2 = 0;
          var total3 = 0;

          list1.map(item => {
            total1 += item.emissions;
          });
          list2.map(item => {
            total2 += item.emissions;
          });
          list3.map(item => {
            total3 += item.emissions;
          });
  
          list.push({ day:LogisticUtility.getStringFromDate(targetDt, 'MM/DD'), Scope1: LogisticUtility.getCommaText(total1.toFixed(2)), Scope2: LogisticUtility.getCommaText(total2.toFixed(2)), Scope3: LogisticUtility.getCommaText(total3.toFixed(2)), 前年比: '0.00'  });

          targetDt = new Date(targetDt.getFullYear(), targetDt.getMonth(), targetDt.getDate() + 1);
          //console.log(targetDt);
        }

        setScopeMonthRate(list);
        //console.log(list);
      }
    }
  }

  // モーダル
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      //opacity: 0.8,
      padding: '10px',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    },
    overlay: {
      //background: 'rgba(0, 0, 0, 0.7)',
      zIndex: 10
    }
  }

  //ダイアログ開いた時の処理
  function afterOpenModal () {
    apiGetTotalCategoryEmissionsMonthly();
  }

  function closeModal () {
    setIsOpen(false);
  }

  useEffect(() => {
  }, [modalIsOpen])

  // 描画時に一度だけ実行
  useEffect(() => {
  }, [])

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel='Create Zone Modal'
      ariaHideApp={false}
    >
     <IndustryWrap>
        <div style={{width:'1800px', height:'410px', textAlign:'left'}}>
        <div style={{ height:'20px', marginLeft:'auto', marginRight:'10px', marginTop:'10px', cursor: 'pointer'}}>
            <img alt='閉じる' className='close' src={CloseIcon} title={'ダイアログを閉じる'} width="12px" height="12px" onClick={closeModal} />
        </div>
        <span style={{marginLeft:'0.25rem', fontWeight:'bold', fontSize:'20px'}}>Scope 当月推移</span>
        <ResponsiveContainer width="100%" height="85%">
          <BarChart
            data={scopeMonthRate}
            margin={{
              top: 30,
              right: 30,
              left: 10,
              bottom: 5,
            }}
          >
            <XAxis
              dataKey="day"
              tick={{fontSize: 14}}
              tickCount={31}
            />
            <YAxis 
              label={{ value: "(ton)", position: "insideLeft", angle: 0, dx: 20, dy: -50, fontSize: 10}}
              dx={-10}
              tick={{fontSize: 14}}
            />
            <YAxis orientation="right" yAxisId="right" />
            <Tooltip />
            <Legend formatter={renderLegendText}/>
            <Bar dataKey="Scope1" stackId="a" barSize={15} fill="#BCB0D8" />
            <Bar dataKey="Scope2" stackId="a" barSize={15} fill="#F9CC9D" />
            <Bar dataKey="Scope3" stackId="a" barSize={15} fill="#FFBD4C" />
            <Bar dataKey="前年比" stackId="right" barSize={15} fill="#0000FF" />
          </BarChart>
        </ResponsiveContainer>
      </div>
      </IndustryWrap>
    </Modal>
  )
}

InputYearlyGoalModal.propTypes = {
  grants: PropTypes.object, // 権限関連
  modalIsOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  workplaceId: PropTypes.string,
  targetYMD: PropTypes.string, // 
  yearlyGoal: PropTypes.object, // 年間目標値
  setYearlyGoal: PropTypes.func, // 年間目標値のセッター
}

export default InputYearlyGoalModal

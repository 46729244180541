import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
//import FUNC from '../../Common/FunctionEynum'
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'

import { GetApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import * as LogisticUtility from '../../Common/Utilties/LogisticUtility'
//import ErrorHandling from '../../Common/ErrorHandling'

import ProgressGraphBoard from './Atoms/ProgressGraphBoard'
import CardCamera from './Atoms/CardCamera'

//import { number } from 'yargs'
//import EditIcon from '../../../image/edit-icon.png'

//import PlanEditDialog from './Atoms/PlanEditDialog'
const DefaultUrl = '/api/logisticManagement'

const BodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: row;

  vertical-align: middle;
  padding:0.5rem;
`

const ContentColumn = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;

  vertical-align: middle;
  padding:0.5rem;
`

const ProgressContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;

  vertical-align: middle;
  width: ${props => props.width ? props.width : '500px'};
  height: ${props => props.height ? props.height : '135px'};//2024/02/21 170pxから変更
  background-color: whitesmoke;
`
//当日以外のカメラの空白用
const ProgressNullContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;

  vertical-align: middle;
  width: ${props => props.width ? props.width : '500px'};
  height: ${props => props.height ? props.height : '170px'};
`
const ProgressCameraImg = styled.img`
  display: flex; /* 縦並び */
  flex-direction: column;

  vertical-align: middle;
  width: ${props => props.width ? props.width : '500px'};
  height: ${props => props.height ? props.height : '170px'};
  background-color: whitesmoke;
`

const ContentWrap = styled.div`
  display: flex; /* 横並び */
  flex-direction: column;
  vertical-align: middle;

  width: 100%;
  height: 600px;
`
const TitleContent = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
`

const Title = styled.div`
  font-weight: bold;
  color: #000;
  text-align: left;
  font-size: 1.8rem; //2024/02/21 MOD 2rem;
`

const Alert = styled.div`
  font-weight: bold;
  color: red;
  margin-left: auto;
  margin-right: 2rem;
  font-size: 1.8rem; //2024/02/21 MOD 2rem;
`

const ProgressTitle = styled.div`
  font-weight: bold;
  color: #000;
  text-align: center;
  font-size: 1.5rem; //2024/02/21 MOD 1.8rem;
  padding:0.5rem;
  //margin-top:0.7rem;//2024/02/21 DEL
`
const ProgressImageTitle = styled.div`
  font-weight: bold;
  color: #000;
  text-align: center;
  font-size: 1.5rem; //2024/02/21 MOD 1.8rem;
  width 100%;
  padding:0.5rem;
  //margin-top:0.7rem;//2024/02/21 DEL
  //margin-left:24px;//2024/02/21 DEL
`

const ProgressContentRow = styled.div`
  font-weight: bold;
  color: #000;
  text-align: center;
  font-size: 2rem;
`
const ProgressSubContentRow = styled.div`
  font-weight: bold;
  color: #000;
  text-align: center;
  font-size: 1rem;
`

const ProgressSubBody= styled.div`
  display: flex; /* 縦並び */
  flex-direction: row;

  text-align: center;
  vertical-align: middle;
  padding:0.3rem;
  width: 500px;
  height: 100px;
`
const ProgressSubContent = styled.div`
  display: flex; /* 横並び */
  flex-direction: column;
  vertical-align: middle;
  width: 250px;
  padding:0.5rem;
`

const ProgressStaffRow = styled.div`
  font-weight: bold;
  color: #000;
  text-align: center;
  font-size: 2rem;
  padding:0.4rem;
`

const ProgressPerformance = (props) => {
  const {
    searchDate
  } = props
  //const { loginUserInfo } = useSelector(state => state);

  const dispatch = useDispatch();
  const alert = useAlert();
  const [processList,setProcessList] = useState([]);
  const [processHour,setProcessHour] = useState([]);
  const [processBefore,setProcessBefore] = useState([]);
  const [progressGraph,setProgressGraph] = useState([]);
  const [workplaceAlert, setWorkplaceAlert] = useState(null);
  //2024/01/04 K.Harada ADD アラートの履歴を格納用変数 
  const [workplaceAlertHistory, setWorkplaceAlertHistory] = useState(null);
  const intervalRef1 = useRef(null);
  const intervalRef2 = useRef(null);
  const intervalRef3 = useRef(null);
  const intervalRef4 = useRef(null);
  const [opacity,setOpacity]=useState(true);
  const [cameraMode,setCameraMode]=useState(0);
  const [cameraImg,setCameraImg] = useState(null);
  /*2023/12/12 ADD 時間帯別人数進捗、残工数取得用追加*/
  const [progressNum,setProgressNum] = useState([]);
  // 描画時に一度だけ実行
  useEffect(() => {
  }, [processList])

  // 現場アラート情報の取得
  const apiGetWorkplaceAlert = async (isLoadSplinner) => {
    console.log('call apiGetWorkplaceAlert');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getWorkplaceAlert';
    
    const data = {
      workplaceId: '000011',
    }
    const result = await GetApi(apiUrl, data);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
        //alert.error('条件の該当データがありません(現場アラート情報の取得)');
    } else {
      console.log(result.data);
      setWorkplaceAlert(result.data);
    }
  }    

  //2024/01/04 K.Harada ADD アラートの履歴を取得するように変更 start
  // 現場アラート(履歴)情報の取得
  const apiGetWorkplaceAlertHistory = async (isLoadSplinner) => {
    console.log('call apiGetWorkplaceAlertHistory');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getWorkplaceAlertHistory';
    
    const data = {
      workplaceId: '000011',
      targetYMD: searchDate
    }
    const result = await GetApi(apiUrl, data);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
        //alert.error('条件の該当データがありません(現場アラート情報の取得)');
    } else {
      console.log(result.data);
      setWorkplaceAlertHistory(result.data);
    }
  }    
  //2024/01/04 K.Harada ADD アラートの履歴を取得するように変更 end

  // 進捗設定情報の取得
  const apiGetSoterReal = async (isLoadSplinner) => {
    //console.log('call apiGetSoterReal');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getSoterReal';
    
    const data = {
      workplaceId: '000011',
      targetYMD: searchDate,//2023/12/27 K.Harada ADD 日付指定をするように変更 //LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD'),
      startAt:'07:00',
      endAt:'22:00'
    }
    const result = await GetApi(apiUrl, data);

    const apiUrl2 = DefaultUrl + '/getSoterGraph';

    const resultGraph = await GetApi(apiUrl2, data);

    /*2023/12/12 ADD 時間帯別人数進捗、残工数取得用追加*/
    const apiUrl3 = DefaultUrl + '/getActivityProgressManagement';

    const resultProgress = await GetApi(apiUrl3, data);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result.errorDetail || resultGraph.errorDetail || resultProgress.errorDetail) {
        //alert.error('条件の該当データがありません(進捗設定情報の取得)');
        setProcessList([]);
        setProcessHour([]);
        setProgressGraph([]);
        setProgressNum([]);
    } else {
      //console.log(result.data);
      setProcessList(result.data[0]?result.data[0]:[]);
      if(result.data.length>1){
        setProcessHour(result.data[1]);
      }else{
        setProcessHour([])
      }
      if(result.data.length>2){
        setProcessBefore(result.data[2]);
      }else{
        setProcessBefore([]);
      }
      var planSoterQuantity = !result.data[0] || isNaN(Number(result.data[0].planSoterQuantity))?0:Number(result.data[0].planSoterQuantity)
      var before = {progressQuantity:0,progressOverflow:0,progressReject:0,beforeProgressQuantity:0} 
      //console.log(result.data);
      resultGraph.data.map((item)=>{
        item.planQuantity = (planSoterQuantity/49).toFixed(0)
        /*2023/12/12 K.Harada ADD 休憩中の予定物量をゼロにする,休憩中の予定物量は休憩前の時間に追加 start*/
        if(item.timeZone == '12:15'||item.timeZone == '12:30'||item.timeZone == '12:45'||item.timeZone == '15:15'||item.timeZone > '18:00'){
          item.planQuantity = 0
        }else{
          if(item.timeZone<'12:15'){
            item.planQuantity =  ((planSoterQuantity/49)+ (planSoterQuantity/(49*7))).toFixed(0)
          }else if(item.timeZone<'15:15'){
            item.planQuantity =  ((planSoterQuantity/49)+ (planSoterQuantity/(49*9))).toFixed(0)
          }
        }
        /*2023/12/12 K.Harada ADD 一週間前の進捗データを追加 end*/
        item.progressQuantity = (item.progressQuantity>before.progressQuantity)?item.progressQuantity - before.progressQuantity:0
        item.progressReject = (item.progressReject>before.progressReject)?item.progressReject - before.progressReject:0
        item.progressOverflow = (item.progressOverflow>before.progressOverflow)?item.progressOverflow - before.progressOverflow:0
        /*2023/12/12 K.Harada ADD 一週間前の進捗データを追加 end*/
        item.beforeProgressQuantity = (item.beforeProgressQuantity>before.beforeProgressQuantity)?item.beforeProgressQuantity - before.beforeProgressQuantity:0

        before.progressQuantity = item.progressQuantity + before.progressQuantity
        before.progressReject = item.progressReject + before.progressReject
        before.progressOverflow = item.progressOverflow + before.progressOverflow
        /*2023/12/12 K.Harada ADD 一週間前の進捗データを追加 end*/
        before.beforeProgressQuantity = item.beforeProgressQuantity + before.beforeProgressQuantity
        /*2023/12/12 K.Harada ADD 一週間前の進捗データを追加 end*/
        }
      )
      setProgressGraph(resultGraph.data);

      /*2023/12/12 ADD 時間帯別人数進捗、残工数取得用追加*/
      setProgressNum(resultProgress.data)
    }
  }    

  // カメラ画像情報の取得
  const apiGetCameraReal = async (isLoadSplinner) => {
    //console.log('call apiGetCameraReal');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getCameraImg';
    
    const data = {
      workplaceId: '000011',
      targetYMD: searchDate//2023/12/27 K.Harada ADD 日付指定をするように変更//LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD'),
    }
    const result = await GetApi(apiUrl, data);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
        //alert.error('条件の該当データがありません(カメラ画像の取得)');
    } else {
        setCameraImg(result.data)     
    }
  }

  // 周期初期のクリア
  const onClearInerval = async () => {
    if (intervalRef1.current !== null)
      clearInterval(intervalRef1.current);
    
      intervalRef1.current = null;

    if (intervalRef2.current !== null)
      clearInterval(intervalRef2.current);
      
      intervalRef2.current = null;

    if (intervalRef3.current !== null)
      clearInterval(intervalRef3.current);
      
      intervalRef3.current = null;

    if (intervalRef4.current !== null)
      clearInterval(intervalRef4.current);
      
      intervalRef4.current = null;
  }

  // 描画時に一度だけ実行
  useEffect(() => {
    //2023/12/27 K.Harada ADD 当日以外をクローズに変更 start
    var now = new Date()
    var nowDate = now.getFullYear() + '-' + ('00'+Number(now.getMonth()+1)).slice(-2)+ '-' + ('00'+now.getDate()).slice(-2)
    //2023/12/27 K.Harada ADD 当日以外をクローズに変更 end

    /*2023/12/12 K.Harada MOD データ取得の時間が長くなったのでロード画面を追加（最初の一回のみ） end*/
    apiGetSoterReal(true);
    apiGetCameraReal();
    apiGetWorkplaceAlert();
    apiGetWorkplaceAlertHistory();//2024/01/04 K.Harada ADD アラートの履歴を取得するように変更
    intervalRef1.current = setInterval(() => {
      apiGetSoterReal();
      apiGetWorkplaceAlert();
      apiGetWorkplaceAlertHistory();//2024/01/04 K.Harada ADD アラートの履歴を取得するように変更
    }, 1000 * 60 * 5); // 5分間隔で変更
    
    intervalRef2.current = setInterval(() => {
      setOpacity((opacity) => !opacity)
    }, 1000 * 2); // 2秒間隔で変更
    
    intervalRef3.current = setInterval(() => {
      // 「出勤状況」⇒カメラ画像（ソータカーブ）⇒カメラ画像（ソータ直線）⇒カメラ画像（バース）
      setCameraMode((cameraMode) =>{ 
        //2023/12/27 K.Harada ADD 当日以外をクローズに変更 start
        if(nowDate != searchDate) return 0;
        //2023/12/27 K.Harada ADD 当日以外をクローズに変更 end
        switch(cameraMode) {
        case 0: // 出勤状況
          return 1;
        case 1: // カメラ画像（ソータカーブ）
          return 2;
        case 2: // カメラ画像（ソータ直線）
          return 3;
        case 3: // カメラ画像（バース）
          return 0;
        default:
          return 0;
      }
    })
    }, 1000 * 10); // 5秒間隔で変更
    
    intervalRef4.current = setInterval(() => {
      // 「出勤状況」⇒カメラ画像（ソータカーブ）⇒カメラ画像（ソータ直線）⇒カメラ画像（バース）
      apiGetCameraReal();
    }, 1000 * 1); // 1秒間隔で変更

    return () => {
      onClearInerval();
    };
  }, [searchDate])

  const makeStaffNum=(num)=>{
    if(!num) return '000'
    if(num<0) return num='000'
    return ('000'+num).slice(-3);
  }
  const makeTitle=()=>{
      //2023/12/28 K.Harada ADD 当日以外をクローズに変更 start
      var now = new Date()
      var nowDate = now.getFullYear() + '-' + ('00'+Number(now.getMonth()+1)).slice(-2)+ '-' + ('00'+now.getDate()).slice(-2)
      if(nowDate != searchDate) return  <>【作業終了予定時刻】<span>予定作業は終了しました。</span></>;
      //2023/12/28 K.Harada ADD 当日以外をクローズに変更 end
      var endTime = processList.progressEndTime
      var endDiffTime = processList.progressEndTimeDiff
      if(endTime==null || endDiffTime==null) return <>【作業終了予定時刻】 <span style={{fontSize:'2.1rem'}}>{'--:--'}</span></>
      if(!endTime || !endDiffTime) return <>【作業終了予定時刻】 <span style={{fontSize:'2.1rem'}}>{'18:00'}</span></>
      endDiffTime = endDiffTime.split(':')
      var diffTime = ''

      if(endDiffTime[0]!='00'){
        diffTime += endDiffTime[0]+'時間'
      }
      if(endDiffTime[1]!='00'){
        diffTime += endDiffTime[1]+'分'
      }
      if (Number(processList.progressAllQuantity) >= Number(processList.planAllQuantity)) {
        return <>【作業終了予定時刻】<span>予定作業は終了しました。</span></>
      }
      if(processList.endTimeDiffFlag==2){
        return <>【作業終了予定時刻】 <span style={{fontSize:'2.1rem'}}>{endTime}</span>【お知らせ】 <span>予定より{diffTime}早く終了する見込みです。</span></>
      }
      else if(processList.endTimeDiffFlag==1){
        return <>【作業終了予定時刻】 <span style={{fontSize:'2.1rem'}}>{endTime}</span>【お知らせ】 <span>予定通りの終了時刻です。</span></>
      }
      else{
        return <>【作業終了予定時刻】 <span style={{fontSize:'2.1rem'}}>{endTime}</span>【お知らせ】 <span style={{color:'#EE2439',opacity:opacity?1:0}}>予定より{diffTime}遅れています。</span></>
      }
  }

  const makeAlert=()=>{
    if (workplaceAlert === undefined || workplaceAlert === null)
      return <></>;
    //2023/12/27 K.Harada ADD 当日以外をクローズに変更 start
    var now = new Date()
    var nowDate = now.getFullYear() + '-' + ('00'+Number(now.getMonth()+1)).slice(-2)+ '-' + ('00'+now.getDate()).slice(-2)
    //2024/01/04 K.Harada ADD 当日以外を履歴データに変更 start
    //if(nowDate != searchDate) return <></>;
    if(nowDate != searchDate){
      var restText = '';
      var processText = '';
      if (workplaceAlertHistory === undefined || workplaceAlertHistory === null)
        return <></>;
      console.log(workplaceAlertHistory)
      if (workplaceAlertHistory.unRegistRest > 0) {
        restText = '休憩未打刻者有（' + workplaceAlertHistory.unRegistRest + '名）';
      }
      if (workplaceAlertHistory.unRegistProcess > 0) {
        processText = '工程未登録者有（' + workplaceAlertHistory.unRegistProcessCnt + '名）';
      }
    
      return <span>{restText + '　' + processText}</span>;
    }
    //2024/01/04 K.Harada ADD 当日以外を履歴データに変更 end
    //2023/12/27 K.Harada ADD 当日以外をクローズに変更 end
    var restText = '';
    var processText = '';
    if (workplaceAlert.unRegistRest > 0) {
      restText = '休憩未打刻者有';
    }
    if (workplaceAlert.unRegistProcess > 0) {
      processText = '工程未登録者有（' + workplaceAlert.unRegistProcessCnt + '名）';
    }

    return <span style={{opacity:opacity?1:0}}>{restText + '　' + processText}</span>;
  }

  const makeContent=(progress,plan)=>{
    //11/24 null値の判定がかけていたところがあったので修正
    if(progress=='null') progress=null
    if(plan=='null') plan=null
    if(progress==null && plan==null) return <>{Number(0).toLocaleString()} <span style={{fontSize:'90%'}}>/</span> <span style={{fontSize:'70%'}}>{Number(0).toLocaleString()}</span></>
    if(progress==null) return <>{Number(0).toLocaleString()} <span style={{fontSize:'90%'}}>/</span> <span style={{fontSize:'70%'}}>{Number(plan).toLocaleString()}</span></>
    if(progress && plan) return <>{Number(progress).toLocaleString()} <span style={{fontSize:'90%'}}>/</span> <span style={{fontSize:'70%'}}>{Number(plan).toLocaleString()}</span></>
    return <></>
  }
  
  const makeGraphData=(plan,progress)=>{

    if(!plan || !progress){
      return;
    } 
    var planInput = isNaN(Number(processList.planInput))?0:Number(processList.planInput)
    var progressInput = isNaN(Number(processList.progressInput))?0:Number(processList.progressInput)
    var unInput = planInput-progressInput;
    if (unInput < 0)
      unInput = 0;
    var data = [
      { name: '未投入数', value: unInput, color: 'firebrick' },
      { name: '投入実績数', value: progressInput, color: 'steelblue' },
    ];
    
    return JSON.parse(JSON.stringify(data));
  }
  const makeHourInput=()=>{
    //2023/12/27 K.Harada ADD 当日以外をクローズに変更 start
    var now = new Date()
    var nowDate = now.getFullYear() + '-' + ('00'+Number(now.getMonth()+1)).slice(-2)+ '-' + ('00'+now.getDate()).slice(-2)
    if(nowDate != searchDate) return <>-</>;
    //2023/12/27 K.Harada ADD 当日以外をクローズに変更 end

    if(!processList.progressInput || !processHour.progressInput) return<>0</>;
    var inputResult = Number(processList.progressInput)-Number(processHour.progressInput)
    return inputResult.toLocaleString();
  }
  const makeDelayRate=()=>{

    if(!processList.progressInput || !processHour.progressInput || processList.progressInput==null || processHour.progressInput==null) return<>0.00%</>;
  
    var inputResult = Number(processList.progressInput)-Number(processHour.progressInput)
    var planHour = processList.planInput/Number(processList.workingTime)
    var delayRate = inputResult/planHour*100
    var colorCode = '#009EEC'
    if(delayRate<100){
      colorCode='#EE2439'
    }
    //2023/12/27 K.Harada ADD 当日以外をクローズに変更 start
    var now = new Date()
    var nowDate = now.getFullYear() + '-' + ('00'+Number(now.getMonth()+1)).slice(-2)+ '-' + ('00'+now.getDate()).slice(-2)
    if(nowDate != searchDate) colorCode = ''
    //2023/12/27 K.Harada ADD 当日以外をクローズに変更 end

    if(isNaN(delayRate)){
      return<>0.00%</>;
    }
    return <span style={{color:colorCode}}>{delayRate.toFixed(2)}%</span>;
  }

  const makeHourAverageInput=()=>{
    if(!processList.averageInput  || processList.averageInput==null || processList.averageInput=='null') return<>0.00</>

    return Number(Number(processList.averageInput).toFixed(2)).toLocaleString();
  }
  const makeWeekRate=()=>{
    if(!processList.averageInput || !processHour.averageInput || processList.averageInput==null || processHour.averageInput==null || processList.averageInput=='null' || processHour.averageInput=='null') return<>0.00%</>;
    var inputResult = Number(processList.averageInput)
    var planHour = processHour.averageInput

    var rate = inputResult/planHour*100
    var colorCode = '#009EEC'
    if(rate<100){
      colorCode='#EE2439'
    }
    if(isNaN(rate)){
      return<>0.00%</>;
    }
    return <span style={{color:colorCode}}>{rate.toFixed(2)}%</span>;
  }

  //2024/01/04 K.Harada ADD 必要工数のデータを表示するように追加
  const makeProcessCnt=(quantity,productivity)=>{
    if(!quantity && !productivity) return<>-</>;
    var quantityNum = Number(quantity)
    var productivityNum = Number(productivity)

    var result = quantityNum/productivityNum

    if(isNaN(result) || !productivityNum != 0){
      return<>0.00</>;
    }
    return <span>{result.toFixed(2)} h</span>;
  }
  //2024/01/04 K.Harada ADD 必要工数過不足のデータを表示するように変更
  const makeProcessShortageCnt=(quantity,productivity,planWorkTime,employeePlanWorkTime)=>{
    if(!quantity && !productivity) return<>-</>;
    var quantityNum = Number(quantity)
    var productivityNum = Number(productivity)
    var time = productivityNum? quantityNum/productivityNum:0
    var result =  time - Number(planWorkTime) - Number(employeePlanWorkTime)

    if(isNaN(result)){
      return<>0.00</>;
    }
    //2024/01/22 K.Harada MOD シフト工数が不足しているとき赤色に変更
    /*
    if(result<0){
      result = 0
    }
    return <span>{result.toFixed(2)} h</span>;
    */
    var colorCode = 'red'
    if(result<0){
      //result = 0
      result = -result
      colorCode='black'
    }
    //2024/01/22 K.Harada MOD 桁数を合わせる(3桁、少数点以下2桁)
    //var tmp = result.toFixed(2).padStart(7,'\u00A0')
    var tmp = makePadBlank(result.toFixed(2),6)
    return <><span style={{color:colorCode}}>{tmp} </span>h</>;
    //2024/01/04 K.HaradaK.Harada MOD シフト工数が不足しているとき赤色に変更
  }
  //2024/01/04 K.Harada ADD 検索日時が当日か確認するフラグ作成
  const makeTodayFlag=()=>{
    var now = new Date()
    var nowDate = now.getFullYear() + '-' + ('00'+Number(now.getMonth()+1)).slice(-2)+ '-' + ('00'+now.getDate()).slice(-2)
    return nowDate == searchDate
  }
  const makePadBlank=(text,max)=>{
    for(var i=text.length;i<max;i++){
      text = '\u00A0\u00A0'+text
    }
    return text
  }
    //2024/01/04 K.Harada ADD 必要工数過不足のデータの長さを取得する
    const makeProcessShortageCntLen=(quantity,productivity,planWorkTime,employeePlanWorkTime)=>{
      if(!quantity && !productivity) return<>-</>;
      var quantityNum = Number(quantity)
      var productivityNum = Number(productivity)
      var time = productivityNum? quantityNum/productivityNum:0
      var result =  time - Number(planWorkTime) - Number(employeePlanWorkTime)

      //基本長は6
      if(isNaN(result)){
        return 6;
      }
      if(result.length > 6) return result.length
      return 6;
    }
  return (
    <>
    <ContentWrap>
      <TitleContent><Title>{makeTitle()}</Title><Alert>{makeAlert()}</Alert></TitleContent>
      <BodyContent>
        <ContentColumn>
          <ProgressContent width={'485px'}>{/*2024/01/04 K.Harada MOD サイズ変更 500px => 485px */}
            <TitleContent>
              <ProgressImageTitle>作業物量進捗</ProgressImageTitle>
            </TitleContent>
            <ProgressContentRow>{makeContent(processList.progressAllQuantity,processList.planAllQuantity)}</ProgressContentRow>
            <ProgressSubBody>
              <ProgressSubContent>
                <ProgressSubContentRow>ソーター</ProgressSubContentRow>
                <ProgressContentRow>{makeContent(processList.progressSoterQuantity,processList.planSoterQuantity)}</ProgressContentRow>
              </ProgressSubContent>
              <ProgressSubContent>
                <ProgressSubContentRow>非ソーター</ProgressSubContentRow>
                <ProgressContentRow>{makeContent(processList.progressNotsoterQuantity,processList.planNotsoterQuantity)}</ProgressContentRow>
              </ProgressSubContent>
            </ProgressSubBody>
          </ProgressContent>
          <div style={{height:'0.5rem'}}/>{/* 2024/01/04 K.Harada MOD サイズ変更 1rem => 0.5rem  */}
          <ProgressContent width={'485px'}>{/*2024/01/04 K.Harada MOD サイズ変更 500px => 485px */}
            <ProgressTitle>シュート投入数</ProgressTitle>
            <ProgressContentRow>{makeContent(processList.progressInput,processList.planInput)}</ProgressContentRow>
            <ProgressSubBody>
              <ProgressSubContent>
                <ProgressSubContentRow>RJ検出数</ProgressSubContentRow>
                <ProgressContentRow>{(processList.progressReject!=null && processList.progressReject)?processList.progressReject:0} 個</ProgressContentRow>
              </ProgressSubContent>
              <ProgressSubContent>
                <ProgressSubContentRow>オーバーフロー数</ProgressSubContentRow>
                <ProgressContentRow>{(processList.progressOverflow!=null && processList.progressOverflow)?processList.progressOverflow:0} 個</ProgressContentRow>
              </ProgressSubContent>
            </ProgressSubBody>
          </ProgressContent>
        </ContentColumn>
        <ContentColumn>
          <ProgressContent width={'385px'}>{/*2024/01/04 K.Harada MOD サイズ変更 400px => 385px */}
            <ProgressTitle>生産性</ProgressTitle>
            <div style={{height:'0.3rem'}}/>
            <ProgressContentRow>{makeContent(processList.progressAllProductivity,processList.standardAllProductivity)}</ProgressContentRow>
            <div style={{height:'0.8rem'}}/>
            <div style={{fontSize:'2rem',fontWeight:'bold'}}>
              (<span style={{color:processList.allProductivityDiffFlag==3?'#EE2439':processList.allProductivityDiffFlag==2?'#009EEC':'#535353'}}>
                {isNaN(Number(processList.allProductivityDiffRate)) || isNaN(Number(processList.progressAllProductivity/processList.standardAllProductivity)) ?'0.00':Number(processList.allProductivityDiffRate).toFixed(2)}%</span>)
            </div>
          </ProgressContent>
          <div style={{height:'0.5rem'}}/>{/* 2024/01/04 K.Harada MOD サイズ変更 1rem => 0.5rem  */}
          <ProgressContent width={'385px'}>{/*2024/01/04 K.Harada MOD サイズ変更 400px => 385px */}
            <ProgressTitle>1.0h 平均投入数</ProgressTitle>
            <div style={{height:'0.3rem'}}/>
            <ProgressContentRow>{makeHourAverageInput()}</ProgressContentRow>
            <div style={{height:'0.8rem'}}/>
            <ProgressContentRow>（前同曜日比：{makeWeekRate()}）</ProgressContentRow>           
          </ProgressContent>
        </ContentColumn>
        <ContentColumn>
          <ProgressContent width={'350px'}>{/*2024/01/04 K.Harada MOD サイズ変更 400px => 350px */}
            <ProgressTitle>工数進捗（h）</ProgressTitle>
            <div style={{height:'0.3rem'}}/>
            <ProgressContentRow>{makeContent(processList.progressWorkTime,processList.planWorkTime)}</ProgressContentRow>
            <div style={{height:'0.8rem'}}/>
            <ProgressContentRow>（{processList.workTimeDiffRate}%）</ProgressContentRow>
          </ProgressContent>
          <div style={{height:'0.5rem'}}/>{/* 2024/01/04 K.Harada MOD サイズ変更 1rem => 0.5rem  */}
          <ProgressContent width={'350px'}>{/*2024/01/04 K.Harada MOD サイズ変更 400px => 350px */}
            <ProgressTitle>直近1.0h投入数</ProgressTitle>
            <div style={{height:'0.3rem'}}/>
            <ProgressContentRow>{makeHourInput()}</ProgressContentRow>
            <div style={{height:'0.8rem'}}/>
            <ProgressContentRow>（遅延率：{makeDelayRate()}）</ProgressContentRow>
          </ProgressContent>
        </ContentColumn>
        <ContentColumn>
          <ProgressContent width={'calc(290px - 0.5rem)'}>
            <ProgressTitle>必要工数<br/>（目標生産性）</ProgressTitle>
            <div style={{height:'1rem'}}/>
            <ProgressContentRow>{makeProcessCnt(processList.planAllQuantity,processList.standardAllProductivity)}</ProgressContentRow>
          </ProgressContent>
          <div style={{height:'0.5rem'}}/>{/* 2024/01/04 K.Harada MOD サイズ変更 1rem => 0.5rem  */}
          <ProgressContent width={'calc(290px - 0.5rem)'}>
            <ProgressTitle>必要工数過不足<br/>（目標生産性）</ProgressTitle>
            <ProgressContentRow>
              <span style={{fontSize:'60%'}}>シフト工数：</span>
              {makeProcessShortageCnt(processList.planAllQuantity,processList.standardAllProductivity,processList.planWorkTime,processList.employeePlanWorkTime)}
            </ProgressContentRow>
            <div style={{height:'0.3rem'}}/>
            <ProgressContentRow>
              <span style={{fontSize:'60%'}}>　社員工数：</span>
              {processList.employeePlanWorkTime?makePadBlank(Number(processList.employeePlanWorkTime).toFixed(2),makeProcessShortageCntLen(processList.planAllQuantity,processList.progressAllProductivity,processList.planWorkTime,processList.employeePlanWorkTime))+' h':'-'}
            </ProgressContentRow>
          </ProgressContent>
          {/*2024/01/04 K.Harada MOD カメラと出勤状況をグラフ下に移動 */}
          {/* {cameraMode==0 &&
            <ProgressContent height={'calc(1rem + 340px)'}>
              <ProgressTitle>出勤状況</ProgressTitle>
              <ProgressStaffRow>未就業・・・{makeStaffNum(processList.unemployedCnt)}人</ProgressStaffRow>
              <ProgressStaffRow>就業中・・・{makeStaffNum(processList.attendanceWorkCnt)}人</ProgressStaffRow>
              <ProgressStaffRow>休憩中・・・{makeStaffNum(processList.restCnt)}人</ProgressStaffRow>
              <ProgressStaffRow>退勤済・・・{makeStaffNum(processList.leavingWorkCnt)}人</ProgressStaffRow>
              <div style={{height:'1rem'}}/>
              <ProgressStaffRow>有給・・・・{makeStaffNum(processList.paidCnt)}人</ProgressStaffRow>
              <ProgressStaffRow>欠勤・・・・{makeStaffNum(processList.absenteeismCnt)}人</ProgressStaffRow>
            </ProgressContent>
          }
          {cameraMode!=0 && <ProgressCameraImg height={'calc(1rem + 340px)'} src={cameraImg[cameraMode-1].cameraImg}/>} */}
        </ContentColumn>
        <ContentColumn>
          <ProgressContent width={'calc(290px - 0.5rem)'}>
            <ProgressTitle>必要工数<br/>（進捗生産性）</ProgressTitle>
            <div style={{height:'1rem'}}/>
            <ProgressContentRow>{makeProcessCnt(processList.planAllQuantity,processList.progressAllProductivity)}</ProgressContentRow>
          </ProgressContent>
          <div style={{height:'0.5rem'}}/>{/* 2024/01/04 K.Harada MOD サイズ変更 1rem => 0.5rem  */}
          <ProgressContent width={'calc(290px - 0.5rem)'}>
            <ProgressTitle>必要工数過不足<br/>（進捗生産性）</ProgressTitle>
            <ProgressContentRow>
              <span style={{fontSize:'60%'}}>シフト工数：</span>
              {makeProcessShortageCnt(processList.planAllQuantity,processList.progressAllProductivity,processList.planWorkTime,processList.employeePlanWorkTime)}
            </ProgressContentRow>
            <div style={{height:'0.3rem'}}/>
            <ProgressContentRow>
              <span style={{fontSize:'60%'}}>　社員工数：</span>
              {processList.employeePlanWorkTime?makePadBlank(Number(processList.employeePlanWorkTime).toFixed(2),makeProcessShortageCntLen(processList.planAllQuantity,processList.progressAllProductivity,processList.planWorkTime,processList.employeePlanWorkTime))+' h':'-'}
            </ProgressContentRow>
          </ProgressContent>
        </ContentColumn>
      </BodyContent>
    </ContentWrap>
    {processList.length!=0 &&
      <ProgressGraphBoard 
        data={makeGraphData(processList.planInput,processList.progressInput)} 
        percent={(processList.progressInput/processList.planInput*100).toFixed(1)} 
        setProcessList={setProcessList} 
        progressList={progressGraph} 
        progressNum={progressNum}
        searchDate={searchDate}
       />
    }
    {/*2024/01/04 K.Harada MOD カメラと出勤状況をグラフ下に移動 */}
    {processList.length!=0 &&
      <BodyContent>
        <BodyContent>
          {makeTodayFlag() &&
          <>
            {/*2024/01/17 Y.Irie MOD カメラ拡大表示機能の実装
            <ProgressCameraImg height={'calc(1rem + 340px)'} width={'464px'} src={cameraImg[0].cameraImg}/>
            <ProgressCameraImg height={'calc(1rem + 340px)'} width={'464px'} src={cameraImg[1].cameraImg}/>
            <ProgressCameraImg height={'calc(1rem + 340px)'} width={'464px'} src={cameraImg[2].cameraImg}/>
            */}
            <CardCamera height={'calc(1rem + 340px)'} width={'464px'} img={cameraImg[0].cameraImg}/>
            <CardCamera height={'calc(1rem + 340px)'} width={'464px'} img={cameraImg[1].cameraImg}/>
            <CardCamera height={'calc(1rem + 340px)'} width={'464px'} img={cameraImg[2].cameraImg}/>
          </>
          }
          {!makeTodayFlag() &&
          <>
            <ProgressNullContent height={'calc(1rem + 340px)'} width={'464px'} src={cameraImg[0].cameraImg}/>
            <ProgressNullContent height={'calc(1rem + 340px)'} width={'464px'} src={cameraImg[1].cameraImg}/>
            <ProgressNullContent height={'calc(1rem + 340px)'} width={'464px'} src={cameraImg[2].cameraImg}/>
          </>
          }
          <ProgressContent height={'calc(1rem + 340px)'} width={'464px'} >
            <ProgressTitle>出勤状況</ProgressTitle>
            <ProgressStaffRow>未就業・・・{makeStaffNum(processList.unemployedCnt)}人</ProgressStaffRow>
            <ProgressStaffRow>就業中・・・{makeStaffNum(processList.attendanceWorkCnt)}人</ProgressStaffRow>
            <ProgressStaffRow>休憩中・・・{makeStaffNum(processList.restCnt)}人</ProgressStaffRow>
            <ProgressStaffRow>退勤済・・・{makeStaffNum(processList.leavingWorkCnt)}人</ProgressStaffRow>
            <div style={{height:'1rem'}}/>
            <ProgressStaffRow>有給・・・・{makeStaffNum(processList.paidCnt)}人</ProgressStaffRow>
            <ProgressStaffRow>欠勤・・・・{makeStaffNum(processList.absenteeismCnt)}人</ProgressStaffRow>
          </ProgressContent>
        </BodyContent>
      </BodyContent>
    }
    {/*2024/02/26 K.Harada ADD 4~7枠目のカメラ追加 */}
    {processList.length!=0 && cameraImg && cameraImg.length>=4 &&
    <BodyContent>
        <BodyContent>
          {makeTodayFlag() &&
          <>
            {cameraImg[3] && <CardCamera height={'calc(1rem + 340px)'} width={'464px'} img={cameraImg[3].cameraImg}/>}
            {cameraImg[4] && <CardCamera height={'calc(1rem + 340px)'} width={'464px'} img={cameraImg[4].cameraImg}/>}
            {cameraImg[5] && <CardCamera height={'calc(1rem + 340px)'} width={'464px'} img={cameraImg[5].cameraImg}/>}
            {cameraImg[6] && <CardCamera height={'calc(1rem + 340px)'} width={'464px'} img={cameraImg[6].cameraImg}/>}
          </>
          }
        </BodyContent>
      </BodyContent>
    }
    </>
  )
}
ProgressPerformance.propTypes = {
}

export default ProgressPerformance

import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import styled from 'styled-components'

import { GetApi } from '../../../Common/ApiAxios'
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'
import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'

import {XAxis, YAxis, Tooltip, Legend, Line,PieChart,Pie,Cell, Bar,ComposedChart,Area } from "recharts"
import IconSettingImg from '../../../../image/update-icon.png'

import Tooltips from '@material-ui/core/Tooltip'

const DefaultUrl = '/api/logisticManagement'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 85vh;
  text-align: center;
  vertical-align: middle;
`

const ContentBody = styled.div`
  display: grid;
  grid-gap: 30px 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row: 1 / 2;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
`

const BodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  overflow: hidden;
  vertical-align: middle;
  width: 400px;
  height: 100%;
`

const GraphContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
`

const ProcessRow = styled.div`
  display: inline-block;
  color: #535353;
  margin-top: 2px;
  border-bottom: 1px solid #D9D5C9;
  width: 210px;
`

const ProcessName = styled.span`
  display: inline-block;
  font-weight: bold;
  color: #000;
  text-align: right;
  font-size: 1.2rem;
  width: 120px;
`

const ProcessSubItem = styled.span`
  display: inline-block;
  color: #535353;
  text-align: left;
`

const ProcessTitle = styled.div`
  font-weight: bold;
  color: #000;
  text-align: center;
  font-size: 1.2rem;
`

const ProcessTitleRow = styled.div`
  display: flex; /* 縦並び */
  flex-direction: row;
`
const Process = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
 
  background-color: #fff;
  text-align:center;
  vertical-align:middle;
  margin-top: 5px;
  margin-bottom: 5px;
`
const ProgressBodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  overflow: hidden;
  vertical-align: middle;
  width: 300px;
  height: 90%;
  box-shadow: 2px 2px 6px rgba(0,0,0,.4);
  margin-left: 60px;
`
const cx = 200;
const cy = 90;//140;2024/02/27 MOD

const innerRadius = 70;
const outerRadius = 90;//130;2024/02/27 MOD

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, value, fill,index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos((-midAngle) * RADIAN);
  const y = cy + radius * Math.sin((-midAngle) * RADIAN);
  if(fill == 'transparent' || value == 0){
    return (<></>);  
  }
  return (
    <text x={x} y={y} dx={fill=='firebrick'?24:-24} fill={"black"} fontSize={'16px'} fontWeight={'bold'} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${Number((value).toFixed(0)).toLocaleString()}`}
    </text>
  );
};

// 当月内訳のLegendのカスタム
function renderLegendText(value,pyload) {
  if(pyload.color == 'transparent'){
    return (<></>);  
  }
  return <>{value}</>;
};

const ProgressGraphBoard = (props) => {
  //const { loginUserInfo } = useSelector(state => state);
  const {
    data,percent,serachData,planItem,planItem2
  } = props
  const dispatch = useDispatch();
  const alert = useAlert();
  const [processList,setProcessList] = useState([]);
  const [prgressGraph,setPrgressGraph] = useState();
  const [maxQuantity, setMaxQuantity] = useState(0);

  const [batchNo, setBatchNo] = useState(1);
  const [ticks,setTicks] = useState(
    ['00:00','01:00','02:00','03:00','04:00','05:00','06:00',
     '07:00','08:00','09:00','10:00','11:00','12:00',
     '13:00','14:00','15:00','16:00','17:00','18:00',
     '19:00','20:00','21:00','22:00','23:00']
  )
  const [ticks1,setTicks1] = useState(
    ['00:00','01:00','02:00','03:00','04:00','05:00','06:00',
     '07:00','08:00','09:00','10:00','11:00','12:00',
     '13:00','14:00','15:00','16:00','17:00','18:00',
     '19:00','20:00','21:00','22:00','23:00']
  )
  //const [batchNo, setBatchNo] = useState(1);
  /*2024/02/27 ADD 時間帯別人数進捗、残工数取得用追加*/
  const [progressNum,setProgressNum] = useState([]);
  
  // 進捗設定情報の取得
  //2023/01/04 K.Harada ADD   引数に便指定を追加
  const apiGetVasShippingGraph= async (isLoadSplinner,batchNo,startAt) => {
    //console.log('call apiGetVasShippingGraph');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    //2023/02/13 K.Harada MOD テーブルを集計から見るように変更
    //const apiUrl = DefaultUrl + '/getVasShippingGraphTime';
    const apiUrl = DefaultUrl + '/getVasShippingGraphJson';
    // 当日
    var date = new Date(serachData);
    const data1 = {
      baseId: 2,
      workplaceId: '000006',
      startTime: startAt,
      targetDate: LogisticUtility.getStringFromDate(date, 'YYYY/MM/DD'),
      batchNo: batchNo //2023/01/04 K.Harada ADD 便指定を追加
    }
    const result1 = await GetApi(apiUrl, data1);
    /*
    // 先週の同曜日
    date.setDate(date.getDate() - 7);
    const data2 = {
      baseId: 2,
      workplaceId: '000006',
      startTime: startAt,
      targetDate: LogisticUtility.getStringFromDate(date, 'YYYY/MM/DD'),
      batchNo: batchNo //2023/01/04 K.Harada ADD 便指定を追加
    }
    const result2 = await GetApi(apiUrl, data2);
    */
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    setProcessList([]);
    setMaxQuantity(0);
    //if (result1.errorDetail || result2.errorDetail) {
    if (result1.errorDetail) {
      //alert.error('条件の該当データがありません(グラフ進捗設定情報の取得)');
    } else {
      var vasStartTime = Number(10)
      var vasEndTime = 24
      if(batchNo==2){
        vasStartTime = 23
        vasEndTime = Number(26)
      }
      var timeCount = (vasEndTime - vasStartTime)*4

      var tickList = []
      var planTotal = 0
      if(planItem && batchNo==1){
        //2023/01/04 K.Harada MOD 予定物量を作業開始時間・終了時刻に合わせる start
        planTotal = Math.round(planItem/timeCount)
        //2024/01/04 K.Harada MOD 予定物量を作業開始時間・終了時刻に合わせる end
      }
      //2023/01/04 K.Harada ADD 2便用の予定物量の処理を追加 start
      if(planItem2 && batchNo==2){
        planTotal = Math.round(planItem2/timeCount)
      }
      //2024/01/04 K.Harada MOD 2便用の予定物量の処理を追加 end
      {
        var startDt = new Date(1900,1,1,0,0,0);
        var targetDt = new Date(1900,1,1,8,0,0);
        var endDt = new Date(1900,1,2,8,0,0);
        var list = new Array();
        var maxValue = planTotal;

        // 当日
        while (targetDt < endDt) {
        //while (targetDt <= endDt) {
          //var targetYMD = LogisticUtility.getStringFromDate(targetDt, 'hh:mm');
          var diffTime = targetDt.getTime() - startDt.getTime();
          var diffHour = Math.floor(diffTime / (1000 * 60 * 60)).toString();
          var targetYMD = LogisticUtility.getStringFromDate(targetDt,diffHour+':mm');
          var list1 = result1.data.filter((elem) => { return elem.timeZone === targetYMD});

          var total1 = 0;
          var total2 = 0;
          var total3 = 0;
          //2024/02/13 K.Harada ADD 先週の同曜日を移動 start
          var total21 = 0;
          var total22 = 0;
          var total23 = 0;
          //2024/02/13 K.Harada ADD 先週の同曜日を移動　end
          list1.map(item => {
            total1 += Number(item.totalItem);
            total2 += Number(item.totalItemCount);
            total3 += Number(item.attendanceWorkCnt);
            //2023/02/13 K.Harada ADD 先週の同曜日を移動 start
            total21 += Number(item.totalPlanItem);
            total22 += Number(item.totalPlanItemCount);
            total23 += Number(item.planWorkTime);
            //2023/02/13 K.Harada ADD 先週の同曜日を移動　end
          });
          if (maxValue < total1)
            maxValue = total1;
          //2023/01/04 K.Harada ADD 予定時刻を作業開始時間・終了時刻に合わせるためのフラグ作成 start
          //var tmp = LogisticUtility.getStringFromDate(targetDt, 'hh');
          //tmp = Number(tmp)
          var tmp = diffHour>=vasStartTime && vasEndTime>diffHour
          //console.log(diffHour,tmp)
          //2024/01/04 K.Harada ADD 予定時刻を作業開始時間・終了時刻に合わせるためのフラグ作成 end
          //2023/01/04 K.Harada ADD 先週の同曜日を移動 start
          //2024/02/13 K.Harada DEL 先週の同曜日をクローズ start
          /*
          var list2 = result2.data.filter((elem) => { return elem.timeZone === targetYMD});
          var total21 = 0;
          var total22 = 0;
          var total23 = 0;
          list2.map(item => {
            total21 += Number(item.totalItem);
            total22 += Number(item.totalItemCount);
            total23 += Number(item.attendanceWorkCnt);
          });*/
          //2024/02/13 K.Harada DEL 先週の同曜日をクローズ end          
          if (maxValue < total21)
            maxValue = total21;
          //2023/01/04 K.Harada ADD 先週の同曜日を移動　end
          list.push(
            { 
              timeZone:targetYMD
              //2024/01/04 K.Harada MOD 予定時刻を作業開始時間・終了時刻に合わせる start
              , planQuantity:tmp?planTotal:0 //planTotal 
              //2024/01/04 K.Harada ADD 予定時刻を作業開始時間・終了時刻に合わせる end
              , progressQuantity:total1.toFixed(2)
              , totalItemCount:total2
              , attendanceWorkCnt:total3
              , beforeProgressQuantity:total21.toFixed(2)
              , beforeTotalItemCount:total22
              , beforeAttendanceWorkCnt:total23
            });
          //targetDt = new Date(1900,1,1,targetDt.getHours(),targetDt.getMinutes()+15,0);
          var tickFlag = LogisticUtility.getStringFromDate(targetDt, 'mm');
          if(tickFlag=='00')
            tickList.push(targetYMD)
          targetDt.setMinutes(targetDt.getMinutes() + 15);
        }
        setTicks(tickList)
        // 先週の同曜日
        //targetDt = new Date(1900,1,1,9,0,0);

        // while (targetDt < endDt) {
        //   var targetYMD = LogisticUtility.getStringFromDate(targetDt, 'hh:mm');
        //   var list1 = result2.data.filter((elem) => { return elem.timeZone === targetYMD});

        //   var total1 = 0;
        //   var total2 = 0;
        //   var total3 = 0;
        //   list1.map(item => {
        //     total1 += Number(item.totalItem);
        //     total2 += Number(item.totalItemCount);
        //     total3 += Number(item.attendanceWorkCnt);
        //   });
        //   if (maxValue < total1)
        //     maxValue = total1;

        //   var item = list.find((elem) => { return elem.timeZone === targetYMD});
        //   item.beforeProgressQuantity=total1.toFixed(2);
        //   item.beforeTotalItemCount=total2;
        //   item.beforeAttendanceWorkCnt=total3;

        //   targetDt = new Date(1900,1,1,targetDt.getHours(),targetDt.getMinutes()+15,0);
        // }

        //console.log(maxValue);
        //console.log(Math.ceil(maxValue / 100) * 100 + 100);
        setProcessList(list);
        setMaxQuantity(Math.ceil(maxValue / 100) * 100 + 100);
      }
    }
  }

  /* 
  * 時間帯別人数進捗、残工数取得
  */
  const apiGetProgressReal = async (isLoadSplinner) => {
    //console.log('call apiGetSoterReal');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));
    
    const data = {
      workplaceId: '000006',
      targetYMD: serachData,
      startAt:'08:00',
      endAt:'32:00'
    }

    /*時間帯別人数進捗、残工数取得用*/
    const apiUrl = DefaultUrl + '/getActivityProgressManagement';
    const resultProgress = await GetApi(apiUrl, data);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (resultProgress.errorDetail) {
        //alert.error('条件の該当データがありません(進捗設定情報の取得)');
    } else {
      var tmp = resultProgress.data.slice(0,resultProgress.data.length-1)
      var tickList = []
      tmp.map((value)=>{
        if(value.timeZone.slice(-2)=='00'){
          tickList.push(value.timeZone)
        }
      })
      setTicks1(tickList)
      setProgressNum(tmp)
      makeRemaining(resultProgress.data,fullScreen)//残工数のボディを作成
    }
  }    

  // 描画時に一度だけ実行
  useEffect(() => {
    if(!data) return;

    //種まき実績数
    setPrgressGraph(data.map((dataRow)=>{
      return dataRow
    }))
    var tmp = ('00'+(Number(8))).slice(-2)
    apiGetProgressReal();//時間帯別人数進捗,残工数
    apiGetVasShippingGraph(true,batchNo,tmp);//時間帯別推移

  }, [percent])
  
  const [fullScreen,setFullScreen] = useState(window.innerHeight>1000);

  function handleResize(event){
    setFullScreen(window.innerHeight>1000)
    makeRemaining(progressNum,window.innerHeight>1000)
  }
    //スクリーン変更イベント
    useEffect(() => {
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [progressNum])
  
    const [remainingWorkTimeList,setRemainingWorkTimeList] = useState([]);
    /* 残工数作成　list:時間帯ごとの残工数、fullScreen: true:全画面,false:通常*/
    const makeRemaining = async (list,fullScreen) => {
      if(!list) return
      var remainingList = []
      var date = new Date();
      var hour = date.getHours();
      
      //if(date > new Date(searchDate)) return
      var nowDate = date.getFullYear() + '-' + ('00'+Number(date.getMonth()+1)).slice(-2)+ '-' + ('00'+date.getDate()).slice(-2)
      if(nowDate != serachData) {
        setRemainingWorkTimeList(remainingList)
        return
      }
  
      list.map((process)=>{
        if(process.timeZone.slice(-2) ==='00' && process.timeZone.slice(-5,-3) >= hour){
          remainingList.push(
            <ProcessRow>
              <ProcessSubItem>{process.timeZone}</ProcessSubItem>
              <ProcessName>{process.remainingWorkTime.toFixed(2)}</ProcessName>
              <ProcessSubItem>h</ProcessSubItem>
            </ProcessRow>
          )
        }
      })
      setRemainingWorkTimeList(remainingList.slice(0,fullScreen?12:6))
    }
  
  return (
    <>
      <ContentWrap>
        <ContentBody>
        <GraphContent>
          <ProcessTitleRow>
          <div style={{margin:'0 0 0 auto', justifyContent: 'center'}}>
          <ProcessTitle>
            時間帯別推移
          </ProcessTitle>
          </div>
          <Tooltips title={(batchNo==2?1:2)+'便に切替'}>
            <img alt='切替' src={IconSettingImg} 
                 width="20px" height="20px" 
                 style={{margin:'0 0 0 auto', justifyContent: 'flex-end',textAlign: 'right', cursor: 'pointer'}}
                 onClick={()=>{
                  setBatchNo((batchNo)=>{return batchNo==2?1:2})
                  var tmp = ('00'+(Number(8))).slice(-2)
                  apiGetVasShippingGraph(true,batchNo==2?1:2,tmp);
                }}
            />            
          </Tooltips>
          </ProcessTitleRow>
          <ComposedChart 
              width={1350}
              height={210}
              data={processList}
              margin={{top: 0, right: 0, left: 0, bottom: 5}}>
                <XAxis xAxisId={1} dataKey="timeZone" ticks={ticks}/>
                <YAxis yAxisId={1} domain={[0, maxQuantity]}  tickCount={9} label={{ value: "物量",fontSize:'24px', angle: -90, dx: -20 }} />
                <YAxis yAxisId={2} domain={[0, 100]} hide={true}/>
                <Tooltip />
                <Line type="plainline" xAxisId={1} yAxisId={1} strokeWidth={2} dataKey="planQuantity" name="予定物量" stroke="#3F9C34" />
                <Line type="plainline" xAxisId={1} yAxisId={1} strokeWidth={2} dataKey="beforeProgressQuantity" name="前同曜日実績物量" stroke="#ee82ee" />      
                <Line type="plainline" xAxisId={1} yAxisId={1} strokeWidth={2} dataKey="progressQuantity" name="実績物量" stroke="#295D8C" />  
                <Bar type="bar" xAxisId={1} yAxisId={2} strokeWidth={2} dataKey="totalItemCount" name="種まき完了アイテム数" fill="#EC7A08" />      
                <Bar type="bar" xAxisId={1} yAxisId={2} strokeWidth={2} dataKey="attendanceWorkCnt" name="種まき作業者数" fill="#CC0001" />  
                <Legend/>
          </ComposedChart >
        </GraphContent>
        {prgressGraph&&
        <BodyContent>
          <ProcessTitle>種まき実績数</ProcessTitle>
          <PieChart width={400} height={210}>
              <Pie
                dataKey="value"
                data={prgressGraph}
                cx={cx}
                cy={cy}
                startAngle={90}
                endAngle={450}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                stroke="none"
                labelLine={false}
                label={renderCustomizedLabel}
                paddingAngle={5}
                >
                {prgressGraph.map((entry, index) => (
                    <Cell key={`cell2-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Legend verticalAlign='bottom' align="center" formatter={renderLegendText}/>
              <text x={cx} y={cy} dx={8} dy={16} fontSize={'36px'} textAnchor="middle" fill={'black'} fontWeight={'bold'}>{percent?percent+'%':'0.0%'}</text>
          </PieChart>
        </BodyContent>
        }
        </ContentBody>
        {/*時間帯別人数進捗、残工数追加*/}
        <div style={{marginTop:'5px'}}></div>
        {prgressGraph&&
          <ContentBody>
          <GraphContent>
            <ProcessTitle>時間帯別就業人数</ProcessTitle>
            <ComposedChart 
              width={1350}
              height={fullScreen?320:160}
              data={progressNum}
              margin={{top: 0, right: 0, left: 0, bottom: 5}}>
                <XAxis xAxisId={1} dataKey="timeZone"  ticks={ticks1}/>
                <YAxis yAxisId={1} domain={[0, 80]} tickCount={16} label={{ value: "人数",fontSize:'24px', angle: -90, dx: -20 }}/>
                <Tooltip />
                <Line xAxisId={1} yAxisId={1} strokeWidth={2} type="plainline" name="予定就業人数" dataKey="planStaffCnt" stroke="#3F9C34" />
                <Area xAxisId={1} yAxisId={1} type="step" name="実績就業人数" dataKey="staffCnt" stroke="#CC0001" fill="#CC0001" />
                <Legend/>
            </ComposedChart >
          </GraphContent>
          <ProgressBodyContent>
            <Process>
              <div>
                <ProcessTitle>残工数</ProcessTitle>
                {remainingWorkTimeList}
              </div>
            </Process>
          </ProgressBodyContent>
          </ContentBody>
        }
      </ContentWrap>
    </>
  )
}

export default ProgressGraphBoard

import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import styled from 'styled-components'
///import moment from 'moment'
//import { useAlert } from 'react-alert'
import { useDispatch } from 'react-redux'

import { PostApi } from '../../../Common/ApiAxios'
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'
import NormalButton from '../../../Common/Atoms/NormalButton'
import Button from '../../../Common/Atoms/NormalButton'

import CloseIcon from '../../../../image/close_icon.png'

/* 縦並 */
const Wrap = styled.div`
  display:flex;
  flex-flow: column;
`

const ColumnFlex = styled.div`
  display: flex; /* 横並び */
  flex-flow: row nowrap;
  text-align: left;
  align-items: center;
  marign: 2rem;
  height: 40px;
`

const ColumnName = styled.div`
  width: 10rem;
  margin-left: 1rem;
  text-align: left;
  align-items: center;
`

const KPIInfoSettingModal = (props) => {
  const {
    //grants,
    itemInfos,
    modalIsOpen,
    setIsOpen,
    onChange,
  } = props
  const dispatch = useDispatch();
  //const alert = useAlert();
  const [items,setItems] = useState([]);

  // モーダル
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      //opacity: 0.8,
      padding: '10px',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    },
    overlay: {
      //background: 'rgba(0, 0, 0, 0.7)',
      zIndex: 10
    }
  }

  //ダイアログ開いた時の処理
  function afterOpenModal () {
    setItems(itemInfos.map(item => {
      return {
        itemId: item.itemId,
        showFlag: item.showFlag,
        sort: item.sort,
      }
    }));
  }

  function closeModal () {
    setIsOpen(false);
  }

  // 表示切替ボタンクリックイベント
  const onClickView = (e , id) => {
    //console.log('call onClickView');
    e.persist();
    var item = items.find((element) => element.itemId === id);
    if (item) {
        item.showFlag = !item.showFlag;
        e.target.innerText = (item.showFlag?'表示':'非表示');
    }
  }

  // 更新ボタンクリックイベント
  const onClickUpdate = () => {
    //console.log('call onClickUpdate');

    apiUpdateNotice(true);
  }

  // 画面表示項目の更新
  const apiUpdateNotice = async (isLoadSplinner) => {
    //console.log('cal apiDeleteNotice');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = '/api/updateScreenShowItemInfo';
    const data = {
      url: '/top',
      itemNum: 0,
      itemName: 'TOP画面下部KPI項目',
      showItemJson: JSON.stringify(items),
    }
    const result = await PostApi(apiUrl, data);

    if (isLoadSplinner)
        dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      //alert.error('画面表示項目の更新に失敗しました');
    } else {
      onChange();
      setIsOpen(false);
    }
  }

  //if (!grants) return null;
  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel='Setting KPI Setting Modal'
      ariaHideApp={false}
    >
      <div style={{ width: '400px', height: '400px'}}>
        <Wrap>
          <div style={{ height:'20px', marginLeft:'auto', marginRight:'10px', marginTop:'10px', cursor: 'pointer'}}>
            <img alt='閉じる' className='close' src={CloseIcon} title={'ダイアログを閉じる'} width="12px" onClick={closeModal} />
          </div>
          {items.length > 0 &&
          <Wrap>
            <ColumnFlex>
              <ColumnName>センター運営人数</ColumnName>
              <Button
                height='36px' width='90px' padding='0'
                name={items[0].showFlag?'表示':'非表示'}
                type='button'
                margin='0 12px 0 12px'
                onClick={(e)=>onClickView(e, 0)}
              />                                         
            </ColumnFlex>
            <ColumnFlex>
              <ColumnName>労務コスト</ColumnName>
              <Button
                height='36px' width='90px' padding='0'
                name={items[1].showFlag?'表示':'非表示'}
                type='button'
                margin='0 12px 0 12px'
                onClick={(e)=>onClickView(e, 1)}
              />                                         
            </ColumnFlex>
            <ColumnFlex>
              <ColumnName>採用コスト</ColumnName>
              <Button
                height='36px' width='90px' padding='0'
                name={items[2].showFlag?'表示':'非表示'}
                type='button'
                margin='0 12px 0 12px'
                onClick={(e)=>onClickView(e, 2)}
              />                                         
            </ColumnFlex>
            <ColumnFlex>
              <ColumnName>平均時給</ColumnName>
              <Button
                height='36px' width='90px' padding='0'
                name={items[3].showFlag?'表示':'非表示'}
                type='button'
                margin='0 12px 0 12px'
                onClick={(e)=>onClickView(e, 3)}
              />                                         
            </ColumnFlex>
            <ColumnFlex>
              <ColumnName>平均勤続期間</ColumnName>
              <Button
                height='36px' width='90px' padding='0'
                name={items[4].showFlag?'表示':'非表示'}
                type='button'
                margin='0 12px 0 12px'
                onClick={(e)=>onClickView(e, 4)}
              />                                         
            </ColumnFlex>
            {/* <ColumnFlex>
              <ColumnName>物量予測値差異率</ColumnName>
              <Button
                height='36px' width='90px' padding='0'
                name={items[5].showFlag?'表示':'非表示'}
                type='button'
                margin='0 12px 0 12px'
                onClick={(e)=>onClickView(e, 5)}
              />                                         
            </ColumnFlex> */}
            <ColumnFlex>
              <ColumnName>事故発生件数</ColumnName>
              <Button
                height='36px' width='90px' padding='0'
                name={items[6].showFlag?'表示':'非表示'}
                type='button'
                margin='0 12px 0 12px'
                onClick={(e)=>onClickView(e, 6)}
              />                                         
            </ColumnFlex>
            <ColumnFlex>
              <ColumnName>事故弁済額</ColumnName>
              <Button
                height='36px' width='90px' padding='0'
                name={items[7].showFlag?'表示':'非表示'}
                type='button'
                margin='0 12px 0 12px'
                onClick={(e)=>onClickView(e, 7)}
              />                                         
            </ColumnFlex>
          </Wrap>
          }
          <div style={{marginLeft:'auto', marginRight:'10px', marginTop:'20px', cursor: 'pointer'}}>
            <NormalButton name='更新' onClick={onClickUpdate} />
          </div>
        </Wrap>
      </div>
    </Modal>
  )
}

KPIInfoSettingModal.propTypes = {
  grants: PropTypes.object, // 権限関連
  itemInfos: PropTypes.array,
  modalIsOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  onChange: PropTypes.func,
}

export default KPIInfoSettingModal

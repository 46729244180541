import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

export const InputCss = css`
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.05em;
  background: #fff;
  height: 2rem;
  border-radius: 5px;
  border: none;
  line-height: 1rem;
  text-indent: 1rem;
  box-shadow: inset 0 3px 5px 0 rgba(0,0,0,0.35);
  color: #333;
  &:focus{
    background: #fff;
    border: 2px solid #0036ff;
    outline: none;
  }
  ::placeholder {
    color: #ccc;
  }
`

const InputWrap = styled.input.attrs(props => ({
  // 初期値
  margin: props.margin || '0 0.25rem',
  width: props.width || '95px',
  display: props.display || 'block',
  textAligen: props.textAlign || 'right'
}))`
  margin: ${props => props.margin};
  width: ${props => props.width};
  min-width: ${props => props.width};
  display: ${props => props.display};
  font-size: 12px;
  letter-spacing: 0.05em;
  background: ${props => props.disabled ? '#eee' : '#fff'};
  height: 2rem;
  border-radius: 5px;
  border: none;
  line-height: 1rem;
  text-indent: 0.5rem;
  box-shadow: inset 0 3px 5px 0 rgba(0,0,0,0.35);
  color: #333;
  position: relative;
  text-align: ${props => props.textAlign};

  padding-right: ${props => props.textAlign=='right'?'0.5rem':'0'};
  
  &:focus {
    background: #fff;
    outline: none;
    border: 2px solid #0036ff;
    box-sizing: border-box;
    text-indent: 0.25rem;
  }
  ::placeholder {
    color: #ccc;
    padding-right 0;
    text-align: left;
  }
  ${props => props.addStyle}
`
const Input = ({
  disabled,
  display,
  margin,
  width,
  name,
  type,
  inputRef,
  placeholder,
  onKeyDown,
  onBlur,
  maxLength,
  addStyle,
  textAlign,
  ...props
}) => (
  <InputWrap
    disabled={disabled}
    margin={margin}
    width={width}
    type={type}
    display={display}
    name={name}
    ref={inputRef}
    onKeyDown={onKeyDown}
    onBlur={onBlur}
    placeholder={placeholder}
    maxLength={maxLength}
    addStyle={addStyle}
    textAlign={textAlign}
    {...props}
  />
)

Input.propTypes = {
  disabled: PropTypes.bool,
  margin: PropTypes.string,
  width: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  display: PropTypes.string,
  inputRef: PropTypes.any,
  onKeyDown: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  maxLength: PropTypes.string,
  addStyle: PropTypes.string,
}

export default Input

import React, { useEffect } from 'react'
//import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import * as HealthCareUtilty from '../../../Common/Utilties/HealthCareUtilty'

const CardWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 200px;
  background-color: #1155cc;
  border-radius: 10px;
  border: none;

  color: ${props => props.type === 0 ? '#000' :'#fff'};
`

const HeaderContent = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 100%;
  height: 40%;
  text-align:center;
  vertical-align:middle;
`

const Title = styled.span`
  width: 100%;

  font-size: 2.5vw;
  font-weight: normal;
  color: #fff;

  margin:auto;
`

const BodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 60%;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
`

// 通常項目
const NormalItem = styled.span`
  font-size: 4.0vw;
  font-weight: normal;
`

// 小項項目
const SmallItem = styled.span`
  font-size: 2.0vw;
  font-weight: normal;
`

function getTitle(type) {
  let title = '';
  switch(type) {
    case 0:
      title = 'CO2排出量';
      break;
    case 1:
      title = '実車率';
      break;
    case 2:
      title = '稼働率';
      break;
    default:
      title = '';
      break;
  }

  return title;
}

function getValueText(type, value) {
  var text = '';
  if (value === null)
    return text;

  switch(type) {
    case 0:
      text = HealthCareUtilty.getCommaText(Math.round(value * 10) / 10);
      break;
    case 1:
      text = HealthCareUtilty.getCommaText(Math.round(value * 10) / 10);
      break;
    case 2:
      text = HealthCareUtilty.getCommaText(Math.round(value * 10) / 10);
      break;
    default:
      text = '';
      break;
  }
  
  if (text.length > 0 && !text.includes('.'))
    text += '.0';

  return text;
}

function getUnit(type) {
  let text = '';
  switch(type) {
    case 0:
      text = 't-CO2';
      break;
    case 1:
      text = '';
      break;
    case 2:
      text = '';
      break;
    default:
      text = '';
      break;
  }

  return text;
}

// 上部1段目のカード
const CardTotal = (props) => {
  const {
    grants,
    value,
    type,
  } = props
  //const alert = useAlert();
  //const interval1Ref = useRef(null);

  // 描画時に一度だけ実行
  useEffect(() => {
  }, [])

  //if (!grants || !value) return null
  return (
    <CardWrap>
      <HeaderContent>
          <Title>{getTitle(type)}</Title>
      </HeaderContent>
      <BodyContent>
        <>
          <NormalItem>{getValueText(type, value)}</NormalItem>
          <div style={{margin: '0 0 0 auto'}}><SmallItem style={{marginRight:'1rem'}}>{getUnit(type)}</SmallItem></div>
        </>
      </BodyContent>
    </CardWrap>
  )
}

CardTotal.propTypes = {
  grants: PropTypes.object, // 権限関連
  value: PropTypes.object, // 集計値
  type: PropTypes.number, // 0:CO2排出量 1:実車率 2:稼働率
}
  
export default CardTotal

import React, { useEffect } from 'react'
import styled from 'styled-components'
//import PropTypes from 'prop-types'

const MediaWrap = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: calc(100%-28px); /* 100% - margin-left - margin-right */
  margin: 4px;
  box-shadow: 0 0 3px 2px 'rgba(0,0,0,0.4)';
  background-color: #649CDF;
  color: #fff;
  font-size: 16px;
  font-weight: bold;

  text-align: center;
  align-items: center;
`
const MediaItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 40px;
  border-right: 2px dashed #fff;
`

const ItemText = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
` 

const DescriptionHeader = (props) => {
  const {
  } = props

  // 初期値
  useEffect(() => {
  })

  return (
    <MediaWrap>
      <MediaItem>
        <ItemText style={{width:'50px'}}></ItemText>
      </MediaItem>
      <MediaItem>
        <ItemText style={{width:'200px'}}>項目</ItemText>
      </MediaItem>
      <MediaItem>
        <ItemText style={{width:'500px'}}>算出方法</ItemText>
      </MediaItem>
      <MediaItem>
        <ItemText style={{width:'400px'}}>データ元</ItemText>
      </MediaItem>
      <MediaItem>
        <ItemText style={{width:'300px'}}>項目</ItemText>
      </MediaItem>
    </MediaWrap>
  )
}

DescriptionHeader.propTypes = {
}

export default DescriptionHeader

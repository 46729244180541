import React,{useState,useRef} from 'react'
//import styled from 'styled-components'
import { useHistory } from "react-router-dom"
import { useSelector, useDispatch  } from 'react-redux'
import PropTypes from 'prop-types'

import Tooltip from '@material-ui/core/Tooltip'

import FUNC from '../../Common/FunctionEnum'
import { PostApi } from '../../Common/ApiAxios'

import SearchWrap ,{ FunctionContent, FunctionContentRow, FunctionIcon, FunctionName} from '../Wrap/BoardSerachWrap'

import IconCollapseImg from '../../../image/Collapse.png'
import IconFunctionImg from '../../../image/three-reader-vertical-icon.png'
import IconTopImg from '../../../image/menu-icon3.png'
import IconCloseImg from '../../../image/close_icon.png'

import Select from '../../Common/Atoms/Select'

import SearchCalendar from '../../Common/Atoms/Calendar'

const Search = (props) => {
  const {
    addStyle,
    onClickFunction,
    onCloseFunction,
    viewSubSearch,
    viewFunction,
    searchDateRef,
    onChange
  } = props
  const history = useHistory();
  const dispatch = useDispatch();
  const loginUserInfo = useSelector(state => state.loginUserInfo);
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC);
  const subGrants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC_DASHBOARD);
  
  const changeCalendar = (date)=>{
    onChange(date)
  }

  const workplaceRef = useRef();
  const [workplaceList, setWorkplaceList] = useState([
    {id:'000284', name:'昭和冷蔵 厚木物流センター', url:'/logistic/syouwaPerformance'}
    , {id:'000001', name:'AFS南関東センター', url:'/logistic/vasKantouPerformance'}
    , {id:'000256', name:'AFSランドポート習志野センター', url:'/logistic/narashinoPerformance'}
    , {id:'000006', name:'AFS尾西センター', url:'/logistic/vasPerformance'}
    , {id:'000011', name:'福通 神戸カインズセンター', url:'/logistic/sorterPerformance'}
  ]);
  const onChangeWorkplace = (e) => {
    //console.log(workplaceRef.current.value);

    let id = workplaceRef.current.value;
    let workplace = workplaceList.find(element => element.id === id);
    if (workplace) {
      history.push(workplace.url);
    }
  }

  async function topPage () {
    history.push('/top');
  }

  if (!grants && !subGrants) return null;
  return (
    <SearchWrap addStyle={addStyle} viewSubSearch={viewSubSearch} viewFunction={viewFunction}>
      <h3>{grants.functionMenuName}</h3>
      <img alt='階層' src={IconCollapseImg} width="24px" height="24px" style={{marginTop:'4px'}}/>
      <div className='subTitleItem' style={{fontSize:'16px',margin:'8px 0 0 12px'}}>{'実績照会'}</div>
      <Select selectRef={workplaceRef} onChange={onChangeWorkplace} width={'15rem'}>
            {workplaceList.map(item => (
              <option key={'work_'+item.id} value={item.id} selected={item.id=='000256'}>{item.name}</option>
            ))}
      </Select>
      <div style={{fontSize:'16px',margin:'8px 0 0 0px'}}>{'対象日付'}</div>
      <SearchCalendar changeCalendar={changeCalendar} dataValue={searchDateRef.current} isViewIcon/>
      <ul className='mainSearch'>
        <li>
        </li>
        <li className='subSearch'>
        </li>
      </ul>
      <div></div>
      <Tooltip title={'機能メニュー'}>
        <img alt='機能メニュー' src={IconFunctionImg} width="24px" height="24px" style={{marginTop: '4px',marginLeft: 'auto', cursor: 'pointer'}} onClick={onClickFunction} />
      </Tooltip>
      <li className='mainFunction'>
        <FunctionContent onMouseLeave={onCloseFunction}>
          <FunctionContentRow onClick={topPage} style={{marginTop:'12px',marginBottom:'12px'}}>
            <FunctionIcon alt='トップページ' src={IconTopImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>トップページ</span></FunctionName>
          </FunctionContentRow>
        </FunctionContent>
      </li>
    </SearchWrap>
  )
}

Search.propTypes = {
  addStyle: PropTypes.string, // css用追加用
  onClickFunction: PropTypes.func,
  onCloseFunction: PropTypes.func,
  viewSubSearch: PropTypes.bool, // サブ検索項目を表示するか
  viewFunction: PropTypes.bool, // サブ機能を表示するか
}

export default Search

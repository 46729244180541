import React, { useState, useEffect } from 'react'
//import { useSelector, useDispatch } from 'react-redux'
//import { useAlert } from 'react-alert'
//import FUNC from '../../Common/FunctionEnum'
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'

import { GetApi } from '../../Common/ApiAxios'
import * as LogisticUtility from '../../Common/Utilties/LogisticUtility'

import OperationHeader from './Molcules/OperationHeader'
import PlanWeekly from './Molcules/PlanWeekly'
import OperationWeekly from './Molcules/OperationWeekly'

const DefaultUrl = '/api/logisticManagement'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  float: flex;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
`

const ContentBody = styled.div`
  width: 90%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
`

const OperationBoard = (props) => {
  const {
    weekly,onClick
  } = props

  useEffect(() => {
  }, [])

  if (!weekly) return <></>;
  return (
    <>
      <ContentWrap>
        <OperationHeader dates={weekly} />
        <ContentBody>
          <PlanWeekly dates={weekly} onClick={onClick}/>
          <OperationWeekly dates={weekly} />
        </ContentBody>
      </ContentWrap>
    </>
  )
}

export default OperationBoard

import React, { useEffect } from 'react'
//import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const CardWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
`

const HeaderContent = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  text-align:center;
  vertical-align:middle;
  margin: auto;
`

const Title = styled.span`
  width: 100%;
  font-size: ${props => props.fontSize ? props.fontSize : '2.2vw'};
`
const ScreenButton = styled.button`
  display: flex;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.05em;
  text-align: center;
  vertical-align: middle;
  color: ${props => props.activeState ? '#fff' : '#000'};
  background-color: ${props => props.activeState ? '#649CDF' : '#ccc'};
  width: 100px;
  line-height: 30px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);
`
function getTitle(type) {
  var title = '';
  switch(type) {
    case 0:
      title = '日別サマリ';
      break;
    case 1:
      title = '月別サマリ';
      break;
    default:
      title = '';
      break;
  }

  return title;
}



// ヘッダー部のカード
const CardTotal = (props) => {
  const {
    grants,
    totalData,
    type,
    summaryType,
    onClick
  } = props
  //const alert = useAlert();
  //const interval1Ref = useRef(null);

  // 描画時に一度だけ実行
  useEffect(() => {
  }, [])

  //if (!grants || !totalData) return null
  return (
    <div>
      <ScreenButton type="button" onClick={()=>onClick(type)}>
        <span style={{marginLeft:'1rem'}}>{getTitle(type)}</span>
      </ScreenButton>
    </div>
  )
}

CardTotal.propTypes = {
  grants: PropTypes.object, // 権限関連
  totalData: PropTypes.object, // 集計情報
  type: PropTypes.number, // 0:全体進捗率 1:終了予定時刻 2:全体稼働率 3:事故発生件数 4:投資可能額
}
  
export default CardTotal

import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import styled from 'styled-components'
import StoreGraph from '../Atoms/StoreGraph'
import SearchCalendar from '../../../Common/Atoms/Calendar'
import { GetApi } from '../../../Common/ApiAxios'
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'
import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'
//import ErrorHandling from '../../Common/ErrorHandling'



const ContentName = styled.div`
  font-weight: bold;
  color: #000;
  font-size: 1.0rem;
  width: 16%;
`
const BodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: row;
  vertical-align: middle;
  height: 250px;
`
const ContentWrap = styled.div`
  display: flex; /* 横並び */
  flex-direction: column;
  vertical-align: middle;
  width: 100%;
  height: 720px;
  padding:0.5rem;
`

const Title = styled.div`
  font-weight: bold;
  color: #000;
  text-align: left;
  font-size: 1.8rem;
`

const TitleWrap = styled.div`
  display: flex; /* 横並び */
  flex-direction: row;
  vertical-align: left;
  width: 100%;
  padding:0.5rem;
`

const TableWrap = styled.div`
  display: flex; /* 横並び */
  flex-direction: row;
  vertical-align: middle;
  text-align: center;
  width: 100%;
`

const StorePerformance = (props) => {
  const {
    searchDate,summray,shippingList
  } = props

  //データ数
  const dataLength = 6;

  const makeBodyContent=(dataList)=>{
    var BodyContents = []
    for(var i=0;i<dataList.length/dataLength;i++){
      var tmpProcessList = dataList.slice(dataLength*i,dataLength*(i+1))
      BodyContents.push(
        <BodyContent>
        {tmpProcessList && tmpProcessList.map((entry, index) => 
          {  
              return (
                <StoreGraph entry={entry} key={'store_'+index}/>
              )
            }     
          )
        }
        </BodyContent>
      )
    }
    return BodyContents
  }
  return (
    <ContentWrap>
      <TitleWrap>
        <Title>エリア別 出荷進捗状況</Title>
      </TitleWrap>
      <>
      {summray && 
        <>
        <TableWrap>
          <ContentName>終了予定時刻</ContentName>
          <ContentName>幹線進捗</ContentName>
          <ContentName>店舗進捗</ContentName>
          <ContentName>数量進捗</ContentName>
          <ContentName>不足入庫待</ContentName>
          <ContentName>ケース出荷</ContentName>
          <ContentName>カゴ車在庫</ContentName>
        </TableWrap>
        <hr/>
        <TableWrap>
          <ContentName>{summray.scheduleEndTime}</ContentName>
          <ContentName>{summray.completionAreaCount+'/'+summray.allAreaCount}</ContentName>
          <ContentName>{summray.completionStoreCount+'/'+summray.allStoreCount}</ContentName>
          <ContentName>{summray.completionQuantity+'/'+summray.allQuantity}</ContentName>
          <ContentName>{summray.notInStockQuantity}</ContentName>
          <ContentName>{summray.completionCaseShipping+'/'+summray.allCaseShipping}</ContentName>
          <ContentName>{summray.completionKagoShipping+'/'+summray.allKagoShipping}</ContentName>
        </TableWrap>
        <hr/>
        </>
      }
      </>
      {shippingList && makeBodyContent(shippingList)}
    </ContentWrap>
  )
}
StorePerformance.propTypes = {
}

export default StorePerformance

import React, { useState, useEffect } from 'react'
//import { useDispatch } from 'react-redux'
//import { useAlert } from 'react-alert'
import styled from 'styled-components'

//import { GetApi } from '../../../Common/ApiAxios'
//import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'
//import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'
//import ErrorHandling from '../../Common/ErrorHandling'

import MediaHeaderRange from './Atoms/MediaHeaderRange'
import MediaDataRange from './Atoms/MediaDataRange'
import MediaHeaderRangeSummary from './Atoms/MediaHeaderRangeSummary'

const BodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  vertical-align: middle;
  height: 520px;
`

const ContentWrap = styled.div`
  display: flex; /* 横並び */
  flex-direction: column;
  vertical-align: middle;
  width: 100%;
  height: 520px;
  padding:0.5rem;
`

export const ListUl = styled.ul`
  width: ${props => props.width ? props.width : '100%'};
  height: ${props => props.height ? props.height : '400px'};
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    border:none;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(112, 112, 112);
    border-radius: 10px;
  }
`

export const ListLi = styled.li`
  width: ${props => props.width ? props.width : 'calc(100%-18px)'};
  display: flex; /* 横並び */
  flex-direction: row;
  text-indent: 10px;
  background: ${props => props.selected ? '#048b63' : '#fff'};
  color: ${props => props.selected ? '#fff' : '#333'};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  .listId{
    margin-right:1vw;
  }
  :nth-child(even){
    background: ${props => props.selected ? '#048b63' : '#eee'};
    color: ${props => props.selected ? '#fff' : '#333'};
  }
  :hover {
    background: #ccc;
    color: #333;
  }
`


const PerformanceRange = (props) => {
  const {
    dateRangeList
    , personalList
  } = props
  const [dateIndex, setDateIndex] = useState(0);

  // 日戻るクリックイベント
  const onClickBack = () => {
    //console.log('call onClickBack');
    
    if (dateIndex - 1 >= 0)
      setDateIndex(dateIndex - 1);
  }

  // 日次へクリックイベント
  const onClickNext = () => {
    //console.log('call onClickNext');
    if (dateIndex + 1 < personalList.length-8)
      setDateIndex(dateIndex + 1);
  }

  useEffect(() => {
    setDateIndex(0);
  }, [dateRangeList]);

  // 初期値
  useEffect(() => {
  });

  return (
    <ContentWrap>
      <BodyContent>
        <div style={{width:'1817px', marginLeft:'20px', marginRight:'20px', textAlign:'left'}}>
        <MediaHeaderRange dateRangeList={dateRangeList} dateIndex={dateIndex} onClickBack={onClickBack} onClickNext={onClickNext}/>
        <MediaHeaderRangeSummary dateRangeList={dateRangeList} dateIndex={dateIndex} personalList={personalList}/>
        <ListUl width={personalList.length >= 9 ? '1817px' : '1800px'} height={'580px'}>
        {personalList.map((item,index) => (
          <ListLi key={item.staffId + '_' + index} style={{cursor: 'default'}}>
          <MediaDataRange dateRangeList={dateRangeList} dateIndex={dateIndex} data={item}/>
          </ListLi>
        ))}
        </ListUl>
        </div>
      </BodyContent>
    </ContentWrap>
  )
}

PerformanceRange.propTypes = {
}

export default PerformanceRange

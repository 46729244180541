import React, { useState, useEffect, useRef } from 'react'
//import { useSelector, useDispatch } from 'react-redux'
//import { useAlert } from 'react-alert'
//import FUNC from '../../Common/FunctionEnum'
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import ToolTips from '@material-ui/core/Tooltip'

import { GetApi } from '../../Common/ApiAxios'
import * as LogisticUtility from '../../Common/Utilties/LogisticUtility'
import {onExportCsvFile} from '../../Common/Utilties/ExportUtility'

import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import { useDispatch } from 'react-redux'

import IconDownloadImg from '../../../image/download-icon.png'

import ProcessHeader from './Molcules/ProcessHeader'
import ProcessDetailHeader from './Molcules/ProcessDetailHeader'
import MediaDataProcess from './Atoms/MediaDataProcess'
import MediaDataDetailProcess from './Atoms/MediaDataDetailProcess'

import ProcessDetailShootHeader from './Molcules/ProcessDetailShootHeader'
import MediaDataDetailShootProcess from './Atoms/MediaDataDetailShootProcess'
import IconChangeImg from '../../../image/page-type-change-icon.png'

const DefaultUrl = '/api/logisticManagement'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  float: flex;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  margin-top:20px;
`

const ContentBody = styled.div`
  width: 90%;
  heigt: 100px;
  margin-left: auto;
  margin-right: auto;
`
export const ListUl = styled.ul`
  width: ${props => props.width ? props.width : '100%'};
  height: ${props => props.height ? props.height : '400px'};
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    border:none;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(112, 112, 112);
    border-radius: 10px;
  }
`

export const ListLi = styled.li`
  width: ${props => props.width ? props.width : 'calc(100%-18px)'};
  text-indent: 10px;
  background: ${props => props.selected ? '#048b63' : '#fff'};
  color: ${props => props.selected ? '#fff' : '#333'};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  .listId{
    margin-right:1vw;
  }
  :nth-child(even){
    background: ${props => props.selected ? '#048b63' : '#eee'};
    color: ${props => props.selected ? '#fff' : '#333'};
  }
  :hover {
    background: #ccc;
    color: #333;
  }
`

const ContentRowWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: row;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
`
const ContentRowBody = styled.div`
`

const Header = '集計月,現場ID,現場名,工程ID,工程名,人数,作業時間（秒）,作業時間（時）'
const OperationBoard = (props) => {
  //const { loginUserInfo } = useSelector(state => state);

  const dispatch = useDispatch();
  //const alert = useAlert();
  const [processList,setProcessList] = useState(null);
  const [boforeProcessList,setBeforeProcessList] = useState(null);

  const [targetYMD,setTargetYMD] = useState(LogisticUtility.getStringFromDate(new Date(), 'YYYY/MM/DD'));
  const [targetYM,setTargetYM] = useState(LogisticUtility.getStringFromDate(new Date(), 'YYYY年MM月度'));
  const intervalRef = useRef(null);

  const [beforeWeekProcessList,setBeforeWeekProcessList] = useState(null);
  const [sumProcessTime,setSumProcessTime] = useState(0);
  const [soterData,setSoterData] = useState(null);
  const [shootData,setShootData] = useState(null)

  const [type,setType] = useState(0);

  // 工程情報の取得
  const apiGetProcessInfo = async (isLoadSplinner) => {
    //console.log('call apiGetProcessInfo');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));
    const apiUrl = DefaultUrl + '/getProcessList';
    const param = {
      //targetYMD: LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD'),
      workplaceId:'000011',
      targetYMD: LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD'),
    }
    //console.log(param);
    const result = await GetApi(apiUrl, param);

    //dispatch(SetLoadSpinner(false));  
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('週情報取得失敗');
      setProcessList([]);
      setBeforeProcessList([]);
    } else {
      //console.log(result);
      setProcessList(result.data[1]);
      setBeforeProcessList(result.data[0]);
      setTargetYMD(LogisticUtility.getStringFromDate(new Date(), 'YYYY/MM/DD'));
      setTargetYM(LogisticUtility.getStringFromDate(new Date(), 'YYYY年MM月度'));

      //月の総工程時間取得
      var time = 0
      if(result.data[1])
        result.data[1].map((data)=>{
          time += data.monthProcessTime
        })
      setSumProcessTime(time)
    }
  }

  // 工程情報(先週)の取得
  const apiGetBeforeWeekProcessInfo = async () => {
    //console.log('call apiGetProcessInfo');

    const apiUrl = DefaultUrl + '/getProcessList';
    var now = new Date()
    now.setDate(now.getDate() - 7)
    const param = {
      //targetYMD: LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD'),
      workplaceId:'000011',
      targetYMD: LogisticUtility.getStringFromDate(now, 'YYYY-MM-DD'),
    }
    //console.log(param);
    const result = await GetApi(apiUrl, param);

    //dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('週情報取得失敗');
      setBeforeWeekProcessList([]);
    } else {
      //console.log(result);
      setBeforeWeekProcessList(result.data[1]);
    }
  }

    // ソーター進捗設定情報の取得
    const apiGetSoterReal = async (isLoadSplinner) => {
      //console.log('call apiGetSoterReal');
      // if (isLoadSplinner)
      //   dispatch(SetLoadSpinner(true));
  
      // const apiUrl = DefaultUrl + '/getSoterReal';
      
      // const data = {
      //   workplaceId: '000011',
      //   targetYMD: LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD'),
      // }
      // const result = await GetApi(apiUrl, data);

      // if (isLoadSplinner)
      //   dispatch(SetLoadSpinner(false));
  
      // if (result.errorDetail) {
      //     //alert.error('条件の該当データがありません(進捗設定情報の取得)');
      //     setSoterData([])
      // } else {
      //   setSoterData(result.data[0]);
      // }
    }    
    // 進捗設定情報の取得
    const apiGetShootReal = async (isLoadSplinner) => {
      //console.log('call getActivityProgressManagement');
      if (isLoadSplinner)
        dispatch(SetLoadSpinner(true));
  
      const apiUrl = DefaultUrl + '/getChuteList';
     
      const data = {
        workplaceId: '000011',
        targetYMD: LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD'),
      }
      const result = await GetApi(apiUrl, data);
  
      if (isLoadSplinner)
        dispatch(SetLoadSpinner(false));
  
      if (result.errorDetail) {
         //alert.error('条件の該当データがありません(進捗設定情報の取得)');
      } else {
        console.log(result.data);
        setShootData(result.data);
      }
    }
  // 周期初期のクリア
  const onClearInerval = async () => {
    if (intervalRef.current !== null)
      clearInterval(intervalRef.current);
    
    intervalRef.current = null;
  }

  // 描画時に一度だけ実行
  useEffect(() => {
    apiGetProcessInfo(true);
    apiGetBeforeWeekProcessInfo();
    apiGetSoterReal();
    apiGetShootReal();
    intervalRef.current = setInterval(() => {
      apiGetProcessInfo();
      apiGetBeforeWeekProcessInfo();
      apiGetSoterReal();
      apiGetShootReal();
    }, 1000 * 60); // 60秒間隔で変更

    return () => {
      onClearInerval();
    };
  }, [])

  const onExport = async ()=>{
    const apiUrl = DefaultUrl + '/getProcessExport';
    const param = {
      workplaceId:'000011',
      //targetYMD: '2023-02-24'
      targetYMD: LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD'),
    }
    //console.log(param);
    const result = await GetApi(apiUrl, param);

    //dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
    } else {
      let exportResult = Header + '\r\n';

      for (const workInfo of result.data) {
        var lineResult =
            workInfo.dateValue + ','
            + workInfo.workplaceId + ','
            + workInfo.workplaceName + ','
            + workInfo.processId + ','
            + workInfo.processName + ','
            + workInfo.processStaff + ','
            + workInfo.processTime + ','
            + workInfo.monthProcessTime + ',';
        lineResult = lineResult.replaceAll('null','');
        exportResult += lineResult;
        
        exportResult += '\r\n';
      }

      let fileName = LogisticUtility.getStringFromDate(new Date(), 'YYYYMMDD')+"_工程実績データ(工程別月単位)";
      onExportCsvFile(exportResult,fileName);
    }
  }

  if (processList==null || boforeProcessList==null) return <></>;
  return (
    <ContentWrap>
      <ContentBody>
      <div div style={{display:'flex',justifyContent:'flex-end', cursor:'pointer'}}>
        <ToolTips title={'ダウンロード'}><div  onClick={()=>onExport()}><img alt='ダウンロード' src={IconDownloadImg} width="25px" height="25px"/></div></ToolTips>
        <ToolTips title={'表示切替'}><div onClick={()=>{setType(!type)}}><img alt='表示切替' src={IconChangeImg} width="25px" height="25px" /></div></ToolTips>
      </div>
      </ContentBody>
      <ContentBody>
        {!type &&
        <>
        <ProcessHeader targetYMD={targetYMD} targetYM={targetYM} />
        <ListUl width={'1750px'} height={'288px'}>
        {processList.map((item,index) => (
          <ListLi key={item.processId} style={{cursor: 'default'}} width={'1740px'} >
            <MediaDataProcess data={item} before={boforeProcessList[index]} index={index}/>
          </ListLi>
        ))}
        </ListUl>
        </>
        }
      </ContentBody>
      {type &&
      <>
        <ContentRowWrap>
          <ContentRowBody>
            <ProcessDetailHeader targetYMD={targetYMD} targetYM={targetYM} />
            <ListUl width={'690px'} height={'288px'}>
            {processList.map((item,index) => (
              <>
              {item.processClassId != 16 &&
                <ListLi key={item.processId+'_detail'} style={{cursor: 'default'}} width={'680px'} >
                  <MediaDataDetailProcess 
                    data={item} 
                    sumProcessTime={sumProcessTime} 
                    before={beforeWeekProcessList?beforeWeekProcessList[index]:[]} 
                    index={index}
                  />
                </ListLi>
              }
              </>
            ))}
            </ListUl>
          </ContentRowBody>
          <ContentRowBody>
            <ProcessDetailShootHeader targetYMD={targetYMD} targetYM={targetYM} />
            <ListUl width={'1057px'} height={'288px'}>
            {processList.map((item,index) => (
              <>
              {item.processClassId == 16 &&
              <ListLi key={item.processId+'_shoot'} style={{cursor: 'default'}} width={'1047px'} >
                <MediaDataDetailShootProcess 
                  data={item} 
                  sumProcessTime={sumProcessTime} 
                  before={beforeWeekProcessList?beforeWeekProcessList[index]:[]} 
                  index={index}
                  shootData={shootData}
                />
              </ListLi>
              }
              </>
            ))}
            </ListUl>
          </ContentRowBody>
        </ContentRowWrap>
        </>
        }
    </ContentWrap>
  )
}

export default OperationBoard

import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'

const MediaWrap = styled.div`
  width:1700px;
  display: flex; /* 横並び */
  flex-wrap: wrap;
  margin: 4px;
  box-shadow: 0 0 3px 2px ${props => props.selected ? 'rgba(0,128,0,0.8)' : 'rgba(0,0,0,0.4)'};

  color: #000;
  font-size: 16px;
`
const MediaItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: ${props => props.height ? props.height : '40px'};
  border-right: 2px dashed #fff;
`
const MediaRow = styled.div`
  display: flex; /* 縦並び */
  flex-direction: row;
  height: 40px;

  align-items: center;
`
const TextWrap = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
` 



// 日付確認
function getDate(date,week) {
  var tmpDate = new Date(date)
  var dayOfWeek  = tmpDate.getDay()

  tmpDate.setDate(tmpDate.getDate() - dayOfWeek + week)
  var text = LogisticUtility.getStringFromDate(tmpDate, 'YYYY-MM-DD')

  return text;
}
// 日付確認
function getData(dataList,date,week,dataName) {
  if(dataList==null || !dataList) return;
  var dateValue = getDate(date,week)

  var dataRow = null
  dataList.map((item)=>{
    if(item.timeZone == dateValue){
      dataRow = item
    }
  })
  if(dataRow == null || dataRow[dataName] == null) return 0

  return Number(Number(dataRow[dataName]).toFixed(2)).toLocaleString();
}

// 日付確認
function getSummary(dataList,dataName,title) {
  if(title=='一人当り平均操作時間') return '-';
  if(dataList==null || !dataList) return;

  var sum = 0 
  dataList.map((item)=>{
    sum += Number(item[dataName])
  })
  if(isNaN(sum)) return 0
  var ave = Number(sum)/dataList.length
  if(isNaN(ave)) ave = 0
  ave = Number(Number(ave).toFixed(title=='VAS操作時間'?2:0)).toLocaleString()
  return Number(Number(sum).toFixed(2)).toLocaleString() + '（'+ave+'）';
}


const MediaDataSummaryHeader = (props) => {
  const {
    title,data,unit,dataName,targetYMD,
  } = props

  // 初期値
  useEffect(() => {
  })

  return (
    <MediaWrap>
      <MediaItem>
        <TextWrap style={{width:'230px',fontWeight:'bold'}}>
          <div>{title}（{unit}）</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'250px'}}>
          <MediaRow>
            <div style={{width:'250px',textAlign: 'right',marginRight:'1rem',fontSize:'24px'}}>{getSummary(data,dataName,title)}</div>
          </MediaRow>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'170px'}}>
          <MediaRow>
            <div style={{width:'170px',textAlign: 'right',marginRight:'1rem',fontSize:'24px'}}>{getData(data,targetYMD,1,dataName)}</div>
          </MediaRow>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'170px'}}>
          <MediaRow>
            <div style={{width:'170px',textAlign: 'right',marginRight:'1rem',fontSize:'24px'}}>{getData(data,targetYMD,2,dataName)}</div>
          </MediaRow>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'170px'}}>
          <MediaRow>
            <div style={{width:'170px',textAlign: 'right',marginRight:'1rem',fontSize:'24px'}}>{getData(data,targetYMD,3,dataName)}</div>
          </MediaRow>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'170px'}}>
          <MediaRow>
            <div style={{width:'170px',textAlign: 'right',marginRight:'1rem',fontSize:'24px'}}>{getData(data,targetYMD,4,dataName)}</div>
          </MediaRow>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'170px'}}>
          <MediaRow>
            <div style={{width:'170px',textAlign: 'right',marginRight:'1rem',fontSize:'24px'}}>{getData(data,targetYMD,5,dataName)}</div>
          </MediaRow>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'170px'}}>
          <MediaRow>
            <div style={{width:'170px',textAlign: 'right',marginRight:'1rem',fontSize:'24px'}}>{getData(data,targetYMD,6,dataName)}</div>
          </MediaRow>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'170px'}}>
          <MediaRow>
            <div style={{width:'170px',textAlign: 'right',marginRight:'1rem',fontSize:'24px'}}>{getData(data,targetYMD,7,dataName)}</div>
          </MediaRow>
        </TextWrap>
      </MediaItem>
    </MediaWrap>
  )
}

MediaDataSummaryHeader.propTypes = {
  grants: PropTypes.object, // 権限関連
  data: PropTypes.object, // CO2排出情報
  totalEmissions: PropTypes.number, // CO2排出量の合計値
}

export default MediaDataSummaryHeader

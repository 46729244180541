import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'

const MediaWrap = styled.div`
  width:1800px;
  display: flex; /* 横並び */
  flex-wrap: wrap;
  margin: 4px;
  box-shadow: 0 0 3px 2px ${props => props.selected ? 'rgba(0,128,0,0.8)' : 'rgba(0,0,0,0.4)'};

  color: #000;
  font-size: 16px;

  text-align: center;
  align-items: center;
`

const MediaItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 75px;
  border-right: 2px dashed #fff;
`

const TextWrap = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
` 

const AVGTextWrap = styled.span`
  font-size: 12px;
` 

/**
 * 秒数を時間(HHmm)の文字列で返す
 * @param {int} seconds 秒
 * @return {string} 時間(HHmm)の文字列
 */
function secToTime(seconds) {
  if(!seconds) return '-';

  let hour = seconds / 3600 | 0
  let minutes = seconds % 3600 / 60 | 0
  
  return ('00' + hour.toString()).slice(-2) + ':' + ('00' + minutes.toString()).slice(-2)
}

function diff48Time(baseDt, dt) {

  if (!baseDt || !dt)
    return '-'

  let diff = new Date(dt).getTime() - new Date(baseDt).getTime()

  //HH部分取得
  let diffHour = diff / (1000 * 60 * 60);

  //MM部分取得
  //let diffMinute = (diffHour - Math.floor(diffHour)) * 60;
  let diffMinute = ((diff / 1000) - Math.floor(diffHour)*60*60)/60;
  //SS部分取得
  //let diffSecond = (diffMinute - Math.floor(diffMinute)) * 60;

  let time = ('00' + Math.floor(diffHour)).slice(-2) + ':' + ('00' + Math.floor(diffMinute)).slice(-2)

  return time
}
function getData(data, dateList, index) {
  if (data === undefined || data === null || dateList === undefined || dateList === null)
    return null;
  else if (dateList.length <= index)
    return null;

  var date = dateList[index].dateValue;
  var p = data.personals.find((p) => { return p.targetDt == date});
  
  return p;
}

const MediaDataRange = (props) => {
  const {
    dateRangeList
    , dateIndex
    , data
  } = props
  const [info0, setInfo0] = useState(null);
  const [info1, setInfo1] = useState(null);
  const [info2, setInfo2] = useState(null);
  const [info3, setInfo3] = useState(null);
  const [info4, setInfo4] = useState(null);
  const [info5, setInfo5] = useState(null);
  const [info6, setInfo6] = useState(null);

  useEffect(() => {
    setInfo0(getData(data, dateRangeList, dateIndex + 0));
    setInfo1(getData(data, dateRangeList, dateIndex + 1));
    setInfo2(getData(data, dateRangeList, dateIndex + 2));
    setInfo3(getData(data, dateRangeList, dateIndex + 3));
    setInfo4(getData(data, dateRangeList, dateIndex + 4));
    setInfo5(getData(data, dateRangeList, dateIndex + 5));
    setInfo6(getData(data, dateRangeList, dateIndex + 6));
  }, [dateRangeList, dateIndex]);

  // 初期値
  useEffect(() => {
  })

  if (!data) return null;
  return (
    <MediaWrap>
      <MediaItem>
        <TextWrap style={{width:'120px', textAlign:'left'}}>
          <div style={{marginLeft:'1rem'}}>{data.staffId}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'200px', textAlign:'left'}}>
          <div style={{marginLeft:'1rem', fontWeight:'bold'}}>{data.staffName}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'140px', textAlign:'center'}}>
          <div>作業時間</div>
        </TextWrap>
        <TextWrap style={{width:'140px', textAlign:'center'}}>
          <div>種まき数（1h）</div>
        </TextWrap>
      </MediaItem>
      <MediaItem style={{backgroundColor: '#F2B3B3'}}>
        <TextWrap style={{width:'160px', textAlign:'center'}}>
          <div>{secToTime(data.totalWorkTimeSec)}
            <AVGTextWrap>（{secToTime(data.avgWorkTimeSec)}）</AVGTextWrap>
          </div>
        </TextWrap>
        <TextWrap style={{width:'160px', textAlign:'center'}}>
          <div>{LogisticUtility.getCommaText(data.toTalResultNum)} 
            <AVGTextWrap>（ {LogisticUtility.getCommaText(data.avgResultNum.toFixed(0))}）</AVGTextWrap>
          </div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'160px', textAlign:'center'}}>
          {info0 && <div>{secToTime(info0.workTimeSec)}</div>}
        </TextWrap>
        <TextWrap style={{width:'160px',textAlign:'right'}}>
          {info0 && 
          <>
          <div style={{marginRight:'0.75rem'}}>{LogisticUtility.getCommaText(info0.resultNum)} 
            <AVGTextWrap>（ {LogisticUtility.getCommaText(info0.avgNum)}）</AVGTextWrap>
          </div>
          </>
          }
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'160px', textAlign:'center'}}>
          {info1 && <div>{secToTime(info1.workTimeSec)}</div>}
        </TextWrap>
        <TextWrap style={{width:'160px',textAlign:'right'}}>
          {info1 && 
          <>
          <div style={{marginRight:'0.75rem'}}>{LogisticUtility.getCommaText(info1.resultNum)} 
            <AVGTextWrap>（ {LogisticUtility.getCommaText(info1.avgNum)}）</AVGTextWrap>
          </div>
          </>
          }
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'160px', textAlign:'center'}}>
          {info2 && <div>{secToTime(info2.workTimeSec)}</div>}
        </TextWrap>
        <TextWrap style={{width:'160px',textAlign:'right'}}>
          {info2 && 
          <>
          <div style={{marginRight:'0.75rem'}}>{LogisticUtility.getCommaText(info2.resultNum)} 
            <AVGTextWrap>（ {LogisticUtility.getCommaText(info2.avgNum)}）</AVGTextWrap>
          </div>
          </>
          }
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'160px', textAlign:'center'}}>
          {info3 && <div>{secToTime(info3.workTimeSec)}</div>}
        </TextWrap>
        <TextWrap style={{width:'160px',textAlign:'right'}}>
          {info3 && 
          <>
          <div style={{marginRight:'0.75rem'}}>{LogisticUtility.getCommaText(info3.resultNum)} 
            <AVGTextWrap>（ {LogisticUtility.getCommaText(info3.avgNum)}）</AVGTextWrap>
          </div>
          </>
          }
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'160px', textAlign:'center'}}>
          {info4 && <div>{secToTime(info4.workTimeSec)}</div>}
        </TextWrap>
        <TextWrap style={{width:'160px',textAlign:'right'}}>
          {info4 && 
          <>
          <div style={{marginRight:'0.75rem'}}>{LogisticUtility.getCommaText(info4.resultNum)} 
            <AVGTextWrap>（ {LogisticUtility.getCommaText(info4.avgNum)}）</AVGTextWrap>
          </div>
          </>
          }
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'160px', textAlign:'center'}}>
          {info5 && <div>{secToTime(info5.workTimeSec)}</div>}
        </TextWrap>
        <TextWrap style={{width:'160px',textAlign:'right'}}>
          {info5 && 
          <>
          <div style={{marginRight:'0.75rem'}}>{LogisticUtility.getCommaText(info5.resultNum)} 
            <AVGTextWrap>（ {LogisticUtility.getCommaText(info5.avgNum)}）</AVGTextWrap>
          </div>
          </>
          }
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'160px', textAlign:'center'}}>
          {info6 && <div>{secToTime(info6.workTimeSec)}</div>}
        </TextWrap>
        <TextWrap style={{width:'160px',textAlign:'right'}}>
          {info6 && 
          <>
          <div style={{marginRight:'0.75rem'}}>{LogisticUtility.getCommaText(info6.resultNum)} 
            <AVGTextWrap>（ {LogisticUtility.getCommaText(info6.avgNum)}）</AVGTextWrap>
          </div>
          </>
          }
        </TextWrap>
      </MediaItem>
    </MediaWrap>
  )
}

MediaDataRange.propTypes = {
  grants: PropTypes.object, // 権限関連
  data: PropTypes.object, // VAS個人実績
}

export default MediaDataRange

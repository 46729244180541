import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { GetApi } from '../../../Common/ApiAxios'
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'
import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'

import CardTotal from '../Atoms/CardTotal'

const DefaultUrl = '/api/logisticManagement'

const Flex = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 100%;
  height: 140px;
  text-align: center;
  vertical-align: middle;
  margin-top: 10px;
  margin-bottom: 5px;
`

const Content = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  width: 90%;
  heigt: 100px;
  margin-left: auto;
  margin-right: auto;

  ::-webkit-scrollbar-corner {
    display: none;
  }
`

// ヘッダー部のカード
const ProgressHeader = (props) => {
  const {
    grants,
    totalDatas,
  } = props
  const dispatch = useDispatch();
  const alert = useAlert();
  const intervalRef = useRef(null);
  const [quantityPlan,setQuantityPlan] = useState(null);
  const [totalProgressInfo,seTtotalProgressInfo] = useState({progress:0.0});
  const [plaEndInfo, setPlanEndInfo] = useState({planEndAt:LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD') + " 00:00:00"});

  // 進捗情報の取得
  const apiGetActivityProgressManagement = async (isLoadSplinner) => {
    //console.log('call getActivityProgressManagement');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getActivityProgressManagement';
   
    const data = {
      workplaceId: '000011',
      targetYMD: LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD'),
    }
    const result = await GetApi(apiUrl, data);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
       alert.error('条件の該当データがありません(進捗情報の取得)');
    } else {
      var planQuantity = 0.0;
      var progressQuantity = 0.0;
      var timeZone = '00:00';
      result.data.map((item)=>{
        planQuantity += item.planQuantity;
        progressQuantity += item.progressQuantity;
        if (item.remainingWorkTime > 0) {
          timeZone = item.timeZone;
        }
      });

      var totalProgress = 0.0;
      if (planQuantity > 0)
        totalProgress = progressQuantity * 100 / planQuantity;

      var endAt = LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD' + " " + timeZone + ":00");
      seTtotalProgressInfo({progress:totalProgress});
      setPlanEndInfo({planEndAt:endAt});
    }
  }

  // 物量計画情報の取得
  const apiGetQuantityPlanDaily = async (isLoadSplinner) => {
    //console.log('call apiGetQuantityPlanDaily')
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true))

    const apiUrl = DefaultUrl + '/getQuantityPlanDaily';
    const param = {
      workplaceId: '000011',
      targetYMD: LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD'),
    }

    const result = await GetApi(apiUrl, param);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('物量計画情報取得失敗');
      setQuantityPlan(null);
    } else {
      //console.log(result);
      setQuantityPlan(result.data);
    }
  }

  // 周期初期のクリア
  const onClearInerval = async () => {
    if (intervalRef.current !== null)
      clearInterval(intervalRef.current);
    
    intervalRef.current = null;
  }

  // 描画時に一度だけ実行
  useEffect(() => {
    apiGetQuantityPlanDaily();
    apiGetActivityProgressManagement();

    intervalRef.current = setInterval(() => {
      apiGetActivityProgressManagement();
      apiGetQuantityPlanDaily();
    }, 1000 * 30); // 30秒間隔で変更
    
    return () => {
      onClearInerval();
    };
  }, [])

  //if (!grants || !totalDatas) return null
  return (
    <Flex>
      <Content>
        <CardTotal totalData={totalProgressInfo} type={0}/>
        <CardTotal totalData={plaEndInfo} type={1}/>
        <CardTotal totalData={quantityPlan} type={2}/>
        <CardTotal totalData={quantityPlan} type={3}/>
        <CardTotal totalData={quantityPlan} type={4}/>
      </Content>
    </Flex>
  )
}

ProgressHeader.propTypes = {
  grants: PropTypes.object, // 権限関連
  totalDatas: PropTypes.object, // 集計情報
}
  
export default ProgressHeader


import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import * as LogisticUtility from '../../../../Common/Utilties/LogisticUtility'

const MediaWrap = styled.div`
  width:860px;
  display: flex; /* 横並び */
  flex-wrap: wrap;
  margin: 4px;
  box-shadow: 0 0 3px 2px ${props => props.selected ? 'rgba(0,128,0,0.8)' : 'rgba(0,0,0,0.4)'};

  color: #000;
  font-size: 16px;

  background-color:  ${props => props.colorFlag ? 'gray' : ''};
  text-align: center;
  align-items: center;
`
const MediaItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 85px;
  border-right: 2px dashed #fff;
`

const TextWrap = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
` 
const ItemRow = styled.div`
  display: flex; /* 縦並び */
  flex-direction: row;
  text-align: center;
  align-items: center;
`
const ItemColumn = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 75px;
`
/*
// 項目名
function getTitle(data) {
  let text = '';

  return text;
}
*/

/**
 * 秒数を時間(HHmm)の文字列で返す
 * @param {int} seconds 秒
 * @return {string} 時間(HHmm)の文字列
 */
function secToTime(seconds) {

  if(!seconds) return '-'
  var hour = seconds / 3600 | 0
  var minutes = seconds % 3600 / 60 | 0

  return ('00' + hour.toString()).slice(-2) + ':' + ('00' + minutes.toString()).slice(-2)
}
// CO2排出の割合
function getRatio(emissions, totalEmissions) {

  if (emissions == 0)
    return '0.00';
  
  let ratio = emissions * 100 / totalEmissions;
  let value = ratio.toFixed(2); 

  return LogisticUtility.getCommaText(value);
}
function diff48Time(baseDt, dt) {

  if (!baseDt || !dt)
    return '-'

  let diff = new Date(dt).getTime() - new Date(baseDt).getTime()

  //HH部分取得
  let diffHour = diff / (1000 * 60 * 60);

  //MM部分取得
  //let diffMinute = (diffHour - Math.floor(diffHour)) * 60;
  let diffMinute = ((diff / 1000) - Math.floor(diffHour)*60*60)/60;
  //SS部分取得
  //let diffSecond = (diffMinute - Math.floor(diffMinute)) * 60;

  let time = ('00' + Math.floor(diffHour)).slice(-2) + ':' + ('00' + Math.floor(diffMinute)).slice(-2)

  return time
}
const MediaDataItemEmissions = (props) => {
  const {
    searchDate,
    data,onClick
  } = props

  // 初期値
  useEffect(() => {
  })

  //if (!data) return null;
  //console.log(data);
  return (
    <MediaWrap colorFlag={data.totalItemCount == '1.0000'}>
      <MediaItem onClick={()=>onClick(data.cd,data.name)}>
        <TextWrap style={{width:'150px', textAlign:'left',color:'blue',textDecoration: 'underline',cursor: 'pointer'}}>
          <div style={{marginLeft:'1rem'}}>{data.cd}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'200px', textAlign:'left'}}>
          <div style={{marginLeft:'1rem', fontWeight:'bold'}}>{data.name}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'80px', textAlign:'center'}}>
          <div>{data.typeName}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'80px', textAlign:'right'}}>
          <div style={{marginRight:'1rem'}}>{LogisticUtility.getCommaText(parseInt(data.totalPlanItem))}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'80px', textAlign:'right'}}>
          <div style={{marginRight:'1rem'}}>{LogisticUtility.getCommaText(parseInt(data.totalItem))}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'150px', textAlign:'center'}}>
          <ItemRow>
            <ItemColumn>
                <div>{diff48Time(searchDate,data.startTime)}</div>
                <div >{diff48Time(searchDate,data.endTime)}</div>
            </ItemColumn>
            <ItemColumn>
                <div>（{secToTime(data.progressWorkTime)}）</div>
            </ItemColumn>
          </ItemRow>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'100px', textAlign:'right'}}>
          <div style={{marginRight:'1rem'}}>{getRatio(data.totalItem,data.totalPlanItem)}</div>
        </TextWrap>
      </MediaItem>
    </MediaWrap>
  )
}

MediaDataItemEmissions.propTypes = {
  data: PropTypes.object, // 種まき情報
  searchDate: PropTypes.string, // 検索日時
}

export default MediaDataItemEmissions

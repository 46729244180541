import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
//import { useAlert } from 'react-alert'
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'

import { GetApi } from '../../Common/ApiAxios'
import * as LogisticUtility from '../../Common/Utilties/LogisticUtility'
import FUNC from '../../Common/FunctionEnum'

import {BoardBaseFormRow, BoardBaseFormRowScroll} from '../Wrap/BoardBaseWrap'
import Search from './Search'
import SideSubMenu from '../SideSubMenu'

import OperationBoard from './OperationBoard'
import ProgressGraphBoard from './ProgressGraphBoard'
import ProgressBoard from './ProgressBoard'
const DefaultUrl = '/api/logisticManagement'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 85vh;
  text-align: center;
  vertical-align: middle;
`

const CainzWeekly = (props) => {
  const { loginUserInfo } = useSelector(state => state);
  const [viewFunction,setViewFunction] = useState(false);
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC);
  const subGrants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC_CARBONEMISSIONS);

  const [weekly,setWeekly] = useState();
  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }

  // 機能メニューの閉じるイベント
  const onCloseFunction = (event) => {
    setViewFunction(false);
  }

  useEffect(() => {
    if (grants && subGrants)
      document.title = grants.functionMenuName + '【' + subGrants.functionMenuName + '】 | CoreBoard'
    else if (grants)
    document.title = grants.functionMenuName + ' | CoreBoard'
    else
      document.title = 'CoreBoard'
  }, [grants, subGrants])
    
  // 描画時に一度だけ実行
  useEffect(() => {
    apiGetWeekDateInfo(LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD'));
  }, [])
  // 週情報の取得
  const apiGetWeekDateInfo = async (targetYMD) => {
    //console.log('call getWeekDateInfo')
    //dispatch(SetLoadSpinner(true))

    const apiUrl = DefaultUrl + '/getWeekDateInfo';
    const param = {
      targetYMD: targetYMD,
    }
    console.log(param);
    const result = await GetApi(apiUrl, param);

    //dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('週情報取得失敗');
      setWeekly([]);
    } else {
      console.log(result);
      setWeekly(result.data);
    }
  }

  const onClick=(add)=>{
    var targetDt = new Date(weekly[0].dateValue)
    targetDt.setDate(targetDt.getDate()+add)
    apiGetWeekDateInfo(LogisticUtility.getStringFromDate(new Date(targetDt), 'YYYY-MM-DD'));
  }

  if (!weekly) return <></>;
  return (
    <>
      <Search
        onClickFunction={onClickFunction}
        onCloseFunction={onCloseFunction}
        viewFunction={viewFunction}
      />
      <BoardBaseFormRow>
        <SideSubMenu height={'84vh'}/>
        <BoardBaseFormRowScroll>
          <ContentWrap>
            <OperationBoard weekly={weekly} onClick={(add)=>onClick(add)}/>
            <ProgressBoard weekly={weekly}/>
            <ProgressGraphBoard weekly={weekly}/>
          </ContentWrap>
        </BoardBaseFormRowScroll>
      </BoardBaseFormRow>
    </>
  )
}

export default CainzWeekly

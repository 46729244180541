import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'

const MediaWrap = styled.div`
  width:1800px;
  display: flex; /* 横並び */
  flex-wrap: wrap;
  margin: 4px;
  box-shadow: 0 0 3px 2px ${props => props.selected ? 'rgba(0,128,0,0.8)' : 'rgba(0,0,0,0.4)'};

  color: #000;
  font-size: 16px;

  text-align: center;
  align-items: center;
`

const MediaItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 40px;
  border-right: 2px dashed #fff;
`

const TextWrap = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
` 

/**
 * 秒数を時間(HHmm)の文字列で返す
 * @param {int} seconds 秒
 * @return {string} 時間(HHmm)の文字列
 */
function secToTime(seconds) {
  if(!seconds) return '-';

  let hour = seconds / 3600 | 0
  let minutes = seconds % 3600 / 60 | 0
  
  return ('00' + hour.toString()).slice(-2) + ':' + ('00' + minutes.toString()).slice(-2)
}

function diff48Time(baseDt, dt) {

  if (!baseDt || !dt)
    return '-'

  let diff = new Date(dt).getTime() - new Date(baseDt).getTime()

  //HH部分取得
  let diffHour = diff / (1000 * 60 * 60);

  //MM部分取得
  //let diffMinute = (diffHour - Math.floor(diffHour)) * 60;
  let diffMinute = ((diff / 1000) - Math.floor(diffHour)*60*60)/60;
  //SS部分取得
  //let diffSecond = (diffMinute - Math.floor(diffMinute)) * 60;

  let time = ('00' + Math.floor(diffHour)).slice(-2) + ':' + ('00' + Math.floor(diffMinute)).slice(-2)

  return time
}

const MediaDataDaily = (props) => {
  const {
    grants,
    searchDate,
    data,
  } = props

  // 初期値
  useEffect(() => {
  })

  if (!data) return null;
  //console.log(data);
  return (
    <MediaWrap>
      <MediaItem>
        <TextWrap style={{width:'150px', textAlign:'left'}}>
          <div style={{marginLeft:'1rem'}}>{data.staffId}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'300px', textAlign:'left'}}>
          <div style={{marginLeft:'1rem', fontWeight:'bold'}}>{data.staffName}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem style={{backgroundColor: '#FCE5CD'}}>
        <TextWrap style={{width:'160px', textAlign:'right'}}>
          <div style={{marginRight:'2rem'}}>{LogisticUtility.getCommaText(data.resultItemNum)}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem style={{backgroundColor: '#FCE5CD'}}>
        <TextWrap style={{width:'160px', textAlign:'right'}}>
          <div style={{marginRight:'2rem'}}>{LogisticUtility.getCommaText(data.resultNum)}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem style={{backgroundColor: '#FCE5CD'}}>
        <TextWrap style={{width:'160px', textAlign:'right'}}>
          <div style={{marginRight:'2rem'}}>{LogisticUtility.getCommaText(data.avgNum)}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'150px', textAlign:'center'}}>
          <div>{diff48Time(data.targetDt,data.workStartAt)}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'150px', textAlign:'center'}}>
          <div>{diff48Time(data.targetDt,data.workEndAt)}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'150px', textAlign:'center'}}>
          <div>{secToTime(data.workTimeSec)}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'150px', textAlign:'center'}}>
          <div>{secToTime(data.vasWorkTimeSec)}</div>
        </TextWrap>
      </MediaItem>
    </MediaWrap>
  )
}

MediaDataDaily.propTypes = {
  grants: PropTypes.object, // 権限関連
  data: PropTypes.object, // VAS個人実績
}

export default MediaDataDaily

import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
//import { useAlert } from 'react-alert'
import { useDispatch } from 'react-redux'
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { GetApi } from '../../../Common/ApiAxios'
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'
import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'
//import BoardBaseWrap, {BoardBaseFormRow, ListUl, ListLi} from '../../Wrap/BoardBaseWrap'

import Scop1Img from '../../../../image/scop1-icon.png'
import Scop2Img from '../../../../image/scop2-icon.png'
import Scop3UpperImg from '../../../../image/scop3-1-icon.png'
import Scop3UnderImg from '../../../../image/scop3-2-icon.png'
import EditIcon from '../../../../image/edit-icon.png'

//import MediaHeaderCarbonEmissions from '../Atoms/MediaHeaderCarbonEmissions'
//import MediaDataCarbonEmissions from '../Atoms/MediaDataCarbonEmissions'
import InputYearlyGoalModal from './InputYearlyGoalModal'

const DefaultUrl = '/api/logisticManagement'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  text-align: left;
  vertical-align: middle;
`

const ContentFlex = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  text-align: center;
`

const ColumnContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 30vw;
  margin-left: 1rem;
  margin-top: 0.25rem;
  margin-right: 1rem;
  margin-bottom: 0.25rem;
  text-align: center;
`

const IndustryWrap = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: left;
  vertical-align: middle;
`

const GraphTitleWrap = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 600px;
  font-size: 1.5vw;
  font-weight: bold;
  margin-top: auto;
  margin-bottom: auto;
`
const ActionImg = styled.img`
  width: 16px;
  height: 16px;
  display:none;
  ${GraphTitleWrap}:hover & {
    display:block;
  }

  cursor: pointer;
`

const TitleWrap = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  background-color: #649CDF;
  color: #fff;
`

const TitleText = styled.div`
  margin: auto;
  font-size: 1.2vw;
  font-weight: bold;
`

const ScopeWrap = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 100%;
  text-align: center;
`

const ScopeNameWrap = styled.div`
  height: 48px;
  font-size: 1.2vw;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
`

const ScopeValueWrap = styled.div`
  display: flex; /* 横並び */
  width: 100%;
  height: 30px;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
`

const ScopeRatio = styled.span`
  font-size: 0.8vw;
`

const ScopeIcon = styled.img`
  display: none;
  width: 28px;
  height: 28px;
  margin-left: 0.5rem;
  margin-top: 5px;
`

// 当月内訳のLegendのカスタム
function renderLegendText(value) {
  return <span style={{ color:"#000" }}>{value}</span>;
};

const IndustryCarbonEmissionBoard2 = (props) => {
  const {
    grants,
    industryData,
    searchWorkplaceId,
    searchDate,
  } = props
  const dispatch = useDispatch();
  const [totalEmissions, setTotalEmissions] = useState(0);
  const [realYeartotalEmissions, setRealYeartotalEmissions] = useState(0);
  const [yearTotalEmissions, setYearTotalEmissions] = useState([{name: 'CO2排出量累計',value:0,}]); // CO2排出累計
  const [scopeRate, setScopeRate] = useState([{name: 'Scope別比率',Scope1: 0,Scope2: 0,Scope3: 0,}]); // スコープ別比率
  const [scopeRateDetail, setScopeRateDetial] = useState([{name:'Scop1', value:0.0, rate:0.0}, {name:'Scop2', value:0.0, rate:0.0}, {name:'Scop3上流', value:0.0, rate:0.0}, {name:'Scop3下流', value:0.0, rate:0.0}]); // スコープ別比率
  const [scopeMonthRate, setScopeMonthRate] = useState([]);
  const [yearlyGoal, setYearlyGoal] = useState(0.0);
  const [openDialog1, setOpenDialog1] = useState(false);

  // 年間のCO2排出量目標値の取得
  const apiGetYearGoalEmissions = async (isLoadSplinner) => {
    //console.log('call apiGetYearGoalEmissions');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true))

    const apiUrl = DefaultUrl + '/getYearGoalEmissions';

    var now = new Date(searchDate);
    var year = now.getFullYear();
    var month = now.getMonth();
    if (month < 3) {
      year -= 1;
    }
    const param = {
      workplaceId: searchWorkplaceId,
      targetYMD: LogisticUtility.getStringFromDate(new Date(year, 3, 1), 'YYYY-MM-DD'),
    }
    //console.log(param);
    const result = await GetApi(apiUrl, param);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('年間のCO2排出量目標値取得失敗');
      setYearlyGoal(0.0);
    } else {
      //console.log(result);
      if (result.data === '') {
        setYearlyGoal(0.0);
      } else {
        setYearlyGoal(result.data.emissions);
      }
    }
  }

  // 年間のCO2排出量
  const apiGetTotalCategoryEmissionsYear = async (isLoadSplinner) => {
    //console.log('call apiGetTotalCategoryEmissionsMonthly');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getTotalCategoryEmissionsYear';

    var now = new Date(searchDate);
    var year = now.getFullYear();
    var month = now.getMonth();
    if (month < 3) {
      year -= 1;
    }
    const param = {
      workplaceId: searchWorkplaceId,
      targetYMD: LogisticUtility.getStringFromDate(new Date(year, 3, 1), 'YYYY-MM-DD'),
      industryId: '00005',
    }
    //console.log(param);
    const result = await GetApi(apiUrl, param);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('日単位のCO2排出量のカテゴリの集計取得失敗');
      setTotalEmissions(0);
    } else {
      //console.log(result.data);
      var total = 0;
      result.data.forEach(function(elem, index) {
        total += elem.emissions;
      });
      
      var data = [
        {
          name: 'CO2排出量累計',
          value: total.toFixed(4),
        },
      ];
      setYearTotalEmissions(data);
      setRealYeartotalEmissions(total);
    }
  }

  // 月単位のCO2排出量のカテゴリの集計の取得
  const apiGetTotalCategoryEmissionsMonthly = async (isLoadSplinner) => {
    //console.log('call apiGetTotalCategoryEmissionsMonthly');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getTotalCategoryEmissionsMonthly';
    const param = {
      workplaceId: searchWorkplaceId,
      targetYMD: LogisticUtility.getStringFromDate(new Date(searchDate), 'YYYY-MM-DD'),
      industryId: '00005',
    }
    //console.log(param);
    const result = await GetApi(apiUrl, param);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('日単位のCO2排出量のカテゴリの集計取得失敗');
      setTotalEmissions(0);
    } else {
      console.log(result.data);
      {
        var total1 = 0;
        var total2 = 0;
        var total3Upper = 0;
        var total3Under = 0;
        var total = 0;
        result.data.forEach(function(elem, index) {
          if (elem.scopeNum === '00001') {
            total1 += elem.emissions;
          }
          else if (elem.scopeNum === '00002') {
            total2 += elem.emissions;
          }
          else if (elem.scopeNum === '00003') {
            switch(elem.categoryId) {
              case '00001':
                total3Upper += elem.emissions;
                break;
              case '00002':
                total3Upper += elem.emissions;
                break;
              case '00003':
                total3Upper += elem.emissions;
                break;
              case '00004':
                total3Upper += elem.emissions;
                break;
              case '00005':
                total3Upper += elem.emissions;
                break;
              case '00006':
                total3Upper += elem.emissions;
                break;
              case '00007':
                total3Upper += elem.emissions;
                break;
              case '00008':
                total3Upper += elem.emissions;
                break;
              case '00009':
                total3Under += elem.emissions;
                break;
              case '00010':
                total3Under += elem.emissions;
                break;
              case '00011':
                total3Under += elem.emissions;
                break;
              case '00012':
                total3Under += elem.emissions;
                break;
              case '00013':
                total3Under += elem.emissions;
                break;
              case '00014':
                total3Under += elem.emissions;
                break;
              case '00015':
                total3Under += elem.emissions;
                break;
              case '00016':
                total3Under += elem.emissions;
                break;
            }
          }
          total += elem.emissions;
        });
        
        var data = [
          {
            name: 'Scope別比率',
            Scope1: 0.0,
            Scope2: 0.0,
            Scope3: 0.0,
          },
        ];
        if (total > 0) {
          data = [
            {
              name: 'Scope別比率',
              Scope1: Math.floor(total1 * 100 / total),
              Scope2: Math.floor(total2 * 100 / total),
              Scope3: 100 - Math.floor(total1 * 100 / total) - Math.floor(total2 * 100 / total),
            },
          ];
        }
  
        var dataDetail = [
          {name:'Scop1', value:total1, rate:LogisticUtility.checkRate(total1 * 100 / total)}
          , {name:'Scop2', value:total2, rate:LogisticUtility.checkRate(total2 * 100 / total)}
          , {name:'Scop3上流', value:total3Upper, rate:LogisticUtility.checkRate(total3Upper * 100 / total)}
          , {name:'Scop3下流', value:total3Under, rate:LogisticUtility.checkRate(100 - (total1+total2+total3Upper) * 100 / total)}
        ];
        setScopeRate(data);
        setScopeRateDetial(dataDetail);
        setTotalEmissions(total);
      }

      // 日単位の内訳（比率）算出
      {
        var now = new Date(searchDate);
        var year = now.getFullYear();
        var month = now.getMonth();
        //var month = now.getMonth() -1;
        var day = 1;
        //var day = 16;
        var targetDt = new Date(year, month, day);
        //var endDt = new Date(now.getFullYear(), now.getMonth(), 15);
        var list = new Array();
        //var total1 = 0;
        //var total2 = 0;
        //var total3 = 0;
        //console.log(endDt);
        while (targetDt.getMonth() === now.getMonth()) {
        //while (targetDt <= endDt) {
          var targetYMD = LogisticUtility.getStringFromDate(targetDt, 'YYYY-MM-DD');
          var list1 = result.data.filter((elem) => { return elem.scopeNum === '00001' && elem.targetYMD === targetYMD});
          var list2 = result.data.filter((elem) => { return elem.scopeNum === '00002' && elem.targetYMD === targetYMD});
          var list3 = result.data.filter((elem) => { return elem.scopeNum === '00003' && elem.targetYMD === targetYMD});
          var total1 = 0;
          var total2 = 0;
          var total3 = 0;

          list1.map(item => {
            total1 += item.emissions;
          });
          list2.map(item => {
            total2 += item.emissions;
          });
          list3.map(item => {
            total3 += item.emissions;
          });
  
          list.push({ day:LogisticUtility.getStringFromDate(targetDt, 'MM/DD'), Scope1: LogisticUtility.getCommaText(total1.toFixed(2)), Scope2: LogisticUtility.getCommaText(total2.toFixed(2)), Scope3: LogisticUtility.getCommaText(total3.toFixed(2)), 前年比: '0.00'  });

          targetDt = new Date(targetDt.getFullYear(), targetDt.getMonth(), targetDt.getDate() + 1);
          //console.log(targetDt);
        }

        setScopeMonthRate(list);
      }
    }
  }

  // 年間目標クリックイベント
  const onClickEdit = () => {
    console.log('onClickEdit');
    setOpenDialog1(true);
  }

  useEffect(() => {
    apiGetYearGoalEmissions();
    apiGetTotalCategoryEmissionsYear();
    apiGetTotalCategoryEmissionsMonthly();
  }, [searchWorkplaceId, searchDate])

  // 描画時に一度だけ実行
  useEffect(() => {
    apiGetYearGoalEmissions();
    apiGetTotalCategoryEmissionsYear();
    apiGetTotalCategoryEmissionsMonthly();
  }, [])

  return (
    <ContentWrap>
      <IndustryWrap>
        <GraphTitleWrap>
          年間 CO2排出量目標値 {LogisticUtility.getCommaText(yearlyGoal.toFixed(4))}（ton）
          <ActionImg style={{marginTop:'0px'}} src={EditIcon} onClick={onClickEdit} />
        </GraphTitleWrap>
        <GraphTitleWrap>
          年間 CO2排出量累計値 {LogisticUtility.getCommaText(realYeartotalEmissions.toFixed(4))}（ton）
        </GraphTitleWrap>
        <GraphTitleWrap>
          当月 CO2排出量 {LogisticUtility.getCommaText(totalEmissions.toFixed(4))}（ton）
        </GraphTitleWrap>
        {/*
        <div style={{width:'1200px', height:'55px'}}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              layout="vertical"
              data={yearTotalEmissions}
              margin={{
                top: 5,
                right: 30,
                left: 60,
                bottom: 5,
              }}
            >
            <XAxis type="number" domain={[0, 100]} />
            <YAxis dataKey="name" type="category" />
            <Tooltip />
            <Bar label dataKey="value" stackId="a" fill="#9FC5E8" />
            </BarChart>
          </ResponsiveContainer>
        </div>
         */}
      </IndustryWrap>{/* 
      <IndustryWrap><GraphTitleWrap>当月 CO2排出量 {LogisticUtility.getCommaText(totalEmissions.toFixed(4))}（ton）</GraphTitleWrap>
        
        <div style={{width:'1200px', height:'55px'}}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              layout="vertical"
              data={scopeRate}
              margin={{
                top: 5,
                right: 30,
                left: 60,
                bottom: 5,
              }}
            >
            <XAxis type="number" domain={[0, 100]} />
            <YAxis dataKey="name" type="category" />
            <Tooltip />
            <Bar label dataKey="Scope1" stackId="a" fill="#D9D2E9" />
            <Bar label dataKey="Scope2" stackId="a" fill="#FCE5CD" />
            <Bar label dataKey="Scope3" stackId="a" fill="#FFF2CC" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </IndustryWrap>
        */}
      <ContentFlex>
        <ColumnContentWrap>
          <TitleWrap><TitleText>上流</TitleText></TitleWrap>
          <ScopeWrap>
            <div style={{width:'100%', textAlign:'center', backgroundColor:'#FFBD4C', marginTop:'5px'}}>
              <ScopeNameWrap style={{color:"#25B837"}}>Scope３<ScopeIcon src={Scop3UpperImg} /></ScopeNameWrap>
              <div style={{width: '100%', display:'flex', flexDirection:'column', marginBottom:'0.5rem'}}>
                <ScopeValueWrap><div style={{margin:'auto'}}>{LogisticUtility.getCommaText(scopeRateDetail[2].value.toFixed(4))}<ScopeRatio>（{LogisticUtility.getCommaText(scopeRateDetail[2].rate.toFixed(1))}％）</ScopeRatio></div></ScopeValueWrap>
              </div>
            </div>
          </ScopeWrap>
        </ColumnContentWrap>
        <ColumnContentWrap>
          <TitleWrap><TitleText>自社</TitleText></TitleWrap>
          <ScopeWrap>
          <div style={{width:'49.5%', textAlign:'center', backgroundColor:'#BCB0D8', marginTop:'5px', marginRight:'0.5%'}}>
            <ScopeNameWrap style={{color:"#006BF6"}}>Scope１<ScopeIcon src={Scop1Img} /></ScopeNameWrap>
            <div style={{width: '100%', display:'flex', flexDirection:'column', marginBottom:'0.5rem'}}>
              <ScopeValueWrap><div style={{margin:'auto'}}>{LogisticUtility.getCommaText(scopeRateDetail[0].value.toFixed(4))}<ScopeRatio>（{LogisticUtility.getCommaText(scopeRateDetail[0].rate.toFixed(1))}％）</ScopeRatio></div></ScopeValueWrap>
            </div>
          </div>
          <div style={{width:'49.5%', textAlign:'center', backgroundColor:'#F9CC9D', marginTop:'5px', marginLeft:'0.5%'}}>
            <ScopeNameWrap style={{color:"#FF00FF"}}>Scope２<ScopeIcon src={Scop2Img} /></ScopeNameWrap>
            <div style={{width: '100%', display:'flex', flexDirection:'column', marginBottom:'0.5rem'}}>
              <ScopeValueWrap><div style={{margin:'auto'}}>{LogisticUtility.getCommaText(scopeRateDetail[1].value.toFixed(4))}<ScopeRatio>（{LogisticUtility.getCommaText(scopeRateDetail[1].rate.toFixed(1))}％）</ScopeRatio></div></ScopeValueWrap>
            </div>
          </div>
          </ScopeWrap>
        </ColumnContentWrap>
        <ColumnContentWrap>
          <TitleWrap><TitleText>下流</TitleText></TitleWrap>
          <ScopeWrap>
            <div style={{width:'100%', textAlign:'center', backgroundColor:'#FFBD4C', marginTop:'5px'}}>
              <ScopeNameWrap style={{color:"#25B837"}}>Scope３<ScopeIcon src={Scop3UnderImg} /></ScopeNameWrap>
              <div style={{width: '100%', display:'flex', flexDirection:'column', marginBottom:'0.5rem'}}>
                <ScopeValueWrap><div style={{margin:'auto'}}>{LogisticUtility.getCommaText(scopeRateDetail[3].value.toFixed(4))}<ScopeRatio>（{LogisticUtility.getCommaText(scopeRateDetail[3].rate.toFixed(1))}％）</ScopeRatio></div></ScopeValueWrap>
              </div>
            </div>
          </ScopeWrap>
        </ColumnContentWrap>
      </ContentFlex>
      <IndustryWrap>
        <div style={{width:'1800px', height:'390px', textAlign:'left'}}>
        <span style={{marginLeft:'0.25rem', fontWeight:'bold', fontSize:'20px'}}>Scope 当月推移</span>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={scopeMonthRate}
            margin={{
              top: 30,
              right: 30,
              left: 10,
              bottom: 5,
            }}
          >
            <XAxis
              dataKey="day"
              tick={{fontSize: 14}}
              tickCount={31}
            />
            <YAxis 
              label={{ value: "(ton)", position: "insideLeft", angle: 0, dx: 20, dy: -50, fontSize: 10}}
              dx={-10}
              tick={{fontSize: 14}}
            />
            <YAxis orientation="right" yAxisId="right" />
            <Tooltip />
            <Legend formatter={renderLegendText}/>
            <Bar dataKey="Scope1" stackId="a" barSize={15} fill="#BCB0D8" />
            <Bar dataKey="Scope2" stackId="a" barSize={15} fill="#F9CC9D" />
            <Bar dataKey="Scope3" stackId="a" barSize={15} fill="#FFBD4C" />
            <Bar dataKey="前年比" stackId="right" barSize={15} fill="#0000FF" />
          </BarChart>
        </ResponsiveContainer>
      </div>
      </IndustryWrap>
      
      <InputYearlyGoalModal
        grants={grants}
        modalIsOpen={openDialog1}
        setIsOpen={setOpenDialog1}
        workplaceId={searchWorkplaceId}
        targetYMD={searchDate}
        yearlyGoal={yearlyGoal}
        setYearlyGoal={setYearlyGoal}
      />
    </ContentWrap>
  )
}

IndustryCarbonEmissionBoard2.propTypes = {
  grants: PropTypes.object, // 権限関連
  industryData: PropTypes.object, // 業種情報
  searchWorkplaceId: PropTypes.string, // 検索欄で選択した現場ID
  searchDate: PropTypes.string, // 検索欄で選択した日付
}

export default IndustryCarbonEmissionBoard2

import React, {useEffect} from 'react'
import { Switch, Route } from 'react-router-dom'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
//import PropTypes from 'prop-types'
//import Tooltip from '@material-ui/core/Tooltip'

import FUNC from '../Common/FunctionEnum'

import Home from './Home/Home'
import Summary from './Summary/Summary'
import Detail from './Detail/Detail'
import TruckDetail from './TruckDetail/Detail'
import LoadingRate from './LoadingRateDetail/Detail'
//配送実績一覧（インナーフレームでデータポータルのサイト表示）
import IframeTruckDetail from './IframeTruckDetail/Detail'
//配送実績一覧（インナーフレームでデータポータルのサイト表示）
import IframeStoreDetail from './IframeStoreDetail/Detail'
//配送実績一覧（インナーフレームでデータポータルのサイト表示）
import Infomation from './Infomation/InfomationBody'
const Container = styled.div`
  .AppTruck {
    flex-direction: column;
    background-repeat: no-repeat;
    background-color: #FFF;
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }
`

const AFSTruck = () => {
  const { loginUserInfo } = useSelector(state => state);
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.TRUCK);

  useEffect(() => {
    //console.log(loginUserInfo.menuList);
    if (grants)
      document.title = grants.functionMenuName + ' | Core Board';
    else
      document.title = 'Core Board';
  }, [grants])

  return (
    <Container>
      <div className='AppAFSTruck' id='AppAFSTruck'>
        <div className='mainAFSTruck'>
          <Switch>
            <Route path='/afstruck/home' component={Home} />
            <Route path='/afstruck/summary' component={Summary} />
            <Route path='/afstruck/detail' component={Detail} />
            <Route path='/afstruck/truck' component={TruckDetail} />
            <Route path='/afstruck/loadingrate' component={LoadingRate} />
            <Route path='/afstruck/truckDetail' component={IframeTruckDetail} />
            <Route path='/afstruck/storeDetail' component={IframeStoreDetail} />
            <Route path='/afstruck/information' component={Infomation} />
            {/* <Route path='/afstruck/map' component={Summary} /> */}
            <Route exact component={IframeTruckDetail} />
          </Switch>
        </div>
      </div>
    </Container>
  )
}

export default AFSTruck

import React,{useEffect} from 'react'
import PropTypes from 'prop-types'

import {BoardBaseFormRow} from '../../../Wrap/BoardBaseWrap'
import PalDashboardGraph from './PalDashboardGraph'
import PalDashboardGraphLegend from './PalDashboardGraphLegend'

const GraphBody = ({ dateDataList, targetDt,staffWorkInfoList,limitList, selectedIndex, selectFunction, updatedShiftFunc, defaultUrl,onClickCargo,onClickLabor }) => {
  // 初期化メソッド
  useEffect(() => {
    //console.log(dateDataList)

  // 初回描画時のみ実行
  // eslint-disable-next-line
  }, [])
//visible={staffWorkInfoList.length ? true : false}

  return (
    <BoardBaseFormRow>
        {dateDataList.length !== 0 && dateDataList.map((post)=>{
          return (
            <div style={{display: 'wrap'}}>
            <div style={{display: 'flex'}}>
              <PalDashboardGraph title={post.title+'荷役管理'} data={post.cargoWorkDataList} keys={post.keys} onClick={onClickCargo}></PalDashboardGraph>
              <PalDashboardGraph title={post.title+'労務管理'} data={post.laborDataList} keys={post.keys} onClick={onClickLabor}></PalDashboardGraph>
            </div>
            <div style={{display: 'flex'}}>
              <PalDashboardGraphLegend keys={post.keys}></PalDashboardGraphLegend>
            </div>
            <div style={{display: 'flex'}}>
              {/* <PalDashboardGraph title={post.title+'荷役管理'} data={post.cargoWorkDataList} keys={post.keys}></PalDashboardGraph>
              <PalDashboardGraph title={post.title+'労務管理'} data={post.laborDataList} keys={post.keys}></PalDashboardGraph> */}
            </div>
            </div>
          )
        })}
    </BoardBaseFormRow>
  )
}

GraphBody.propTypes = {
  dateDataList: PropTypes.array,
  staffWorkInfoList: PropTypes.array,
  limitList: PropTypes.array,
  selectedIndex: PropTypes.any,
  selectFunction: PropTypes.func,
  updatedShiftFunc: PropTypes.func,
  onSalariedSearch: PropTypes.func,
  defaultUrl: PropTypes.string,
  targetDt: PropTypes.any,
  onClickCargo: PropTypes.func,
  onClickLabor: PropTypes.func,
}

export default GraphBody

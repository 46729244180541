import React, { useState, useEffect , useRef} from 'react'
import { useSelector } from 'react-redux'
//import { useAlert } from 'react-alert'
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'

//import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import { GetApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'

import FUNC from '../../Common/FunctionEnum'

import {BoardBaseFormRow, BoardBaseFormRowScroll} from '../Wrap/BoardBaseWrap'

import Tooltip from '@material-ui/core/Tooltip'
import * as LogisticUtility from '../../Common/Utilties/LogisticUtility'
import IconChangeImg from '../../../image/page-type-change-icon.png'
import Search from './Search'
import SideSubMenu from '../SideSubMenu'
import OperationBoard from './OperationBoard'
import ProgressBoard from './ProgressBoard'

const DefaultUrl = '/api/logisticManagement'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  overflow-y: none;
  overflow-x: none;
`

const RealTimeProgrss = (props) => {
  const { loginUserInfo } = useSelector(state => state);
  const [viewFunction,setViewFunction] = useState(false);
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC);
  const subGrants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC_DASHBOARD);

  const history = useLocation(); 
  const hisState = history.state

  const [searchDate,setSearchDate] = useState(hisState.searchDate);
  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }

  // 機能メニューの閉じるイベント
  const onCloseFunction = (event) => {
    setViewFunction(false);
  }
  useEffect(() => {
    if (grants && subGrants)
      document.title = grants.functionMenuName + '【' + subGrants.functionMenuName + '】 | CoreBoard'
    else if (grants)
      document.title = grants.functionMenuName + ' | CoreBoard'
    else
      document.title = 'CoreBoard'
  }, [grants, subGrants])
    
  const dispatch = useDispatch();
  //const alert = useAlert();
  const intervalRef = useRef(null);
  //const [quantityPlan,setQuantityPlan] = useState(null);
  //const [totalProgressInfo,seTtotalProgressInfo] = useState({progress:0.0});
  //const [plaEndInfo, setPlanEndInfo] = useState({planEndAt:LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD') + " 00:00:00"});

  //const defaltValue = {agreementStaffAvgWage:0.0,aveWage:0.0,costProgressValue:0.0}
  const [beforeData,setBeforeData] = useState([]);
  const [daylyData,setDaylyData] = useState([]);

  const [progressList,setProgressList] = useState([]);
  //const [progressMonthList,setProgressMonthList] = useState([]);

  /*2023/01/29 K.Harada ADD 現場IDを表示するように変更 */
  const workplaceId = history.state&&history.state.workplaceId?history.state.workplaceId:'000006'
  const baseId = history.state&&history.state.baseId?history.state.baseId:2
  
  // 周期初期のクリア
  const onClearInerval = async () => {
    if (intervalRef.current !== null)
      clearInterval(intervalRef.current);
    
    intervalRef.current = null;
  }

  // 描画時に一度だけ実行
  useEffect(() => {
    apiGetGraph(false,searchDate);
    apiGetProductivityToday(searchDate);
    intervalRef.current = setInterval(() => {
      apiGetGraph(false,searchDate);
      apiGetProductivityToday(searchDate);
  }, 1000 * 60 *5); // 60秒間隔で変更
    return () => {
      onClearInerval();
    };
  }, [])

  // 進捗設定情報の取得
  const apiGetGraph = async (isLoadSplinner,date) => {
    //console.log('call apiGetGraph');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getVasProductivity';
   
    const data = {
      baseId:baseId,
      targetDate: LogisticUtility.getStringFromDate(new Date(date), 'YYYY/MM/01')
    }
    const result = await GetApi(apiUrl, data);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
    } else {

      var targetDt = new Date(date);
      targetDt = new Date(targetDt.getFullYear(),targetDt.getMonth(),1,0,0,0);

      var endDt = new Date(targetDt.getFullYear(),targetDt.getMonth()+1,1,0,0,0);

      var list = new Array();

      while (targetDt < endDt) {
        var targetYMD = LogisticUtility.getStringFromDate(targetDt, 'YYYY/MM/DD');
        var list1 = result.data.filter((elem) => { return elem.timeZone === targetYMD});

        var total1 = 0;
        var total2 = 0;
        list1.map(item => {
          total1 += Number(item.totalItem);
          total2 += Number(item.progressWorkTime)
        });

        var productivity = 0;
        if(total2 != 0 && total1!= NaN && total2 != NaN){
          productivity = total1/total2
        }
        list.push({  dataValue: targetYMD,productivity:productivity,totalItem:total1 });

        targetDt = new Date(targetDt.getFullYear(),targetDt.getMonth(),targetDt.getDate()+1,0,0,0);
      }
      setProgressList(list);
    }
  }

  // 平均時給情報の取得
  const apiGetProductivityToday = async (date) => {
    //console.log('call apiGetProductivityToday');

    const apiUrl = DefaultUrl + '/getVasProductivityToday';
    const param = {
      baseId: baseId,
      targetDate: LogisticUtility.getStringFromDate(new Date(date), 'YYYY/MM/DD')
    }

    const result = await GetApi(apiUrl, param);

    if (result.errorDetail) {
    } else {
      console.log(result)
      var targetDt = new Date(date);
      var beforeDt = new Date(targetDt.getFullYear(),targetDt.getMonth(),targetDt.getDate()-7,0,0,0);

      var targetYMD = LogisticUtility.getStringFromDate(targetDt, 'YYYY/MM/DD');
      var beforeYMD = LogisticUtility.getStringFromDate(beforeDt, 'YYYY/MM/DD');
      var list1 = result.data.filter((elem) => { return elem.timeZone === targetYMD});
      var list2 = result.data.filter((elem) => { return elem.timeZone === beforeYMD});
      if(list1.length != 0){
        setDaylyData(list1)
      }else{
        setDaylyData([]);
      }
      if(list2.length != 0){
        setBeforeData(list2);
      }else{
        setBeforeData([]);
      }
      //
      //
    }
  }

  const [wageType,setWageType]=useState(0)
  const onClickChangeWageType = () => {
    setWageType(wageType?0:1);
  }

  const onSetTargetYMD=(date)=>{
    setSearchDate(date)

    apiGetGraph(false,date);
    apiGetProductivityToday(date);
  }
  return (
    <>
      <Search
        onClickFunction={onClickFunction}
        onCloseFunction={onCloseFunction}
        viewFunction={viewFunction}
        searchDateRef={searchDate}
        workplaceId={workplaceId}
        baseId={baseId}
      />
      <BoardBaseFormRow>
        <SideSubMenu height={'84vh'} planExisit={true}/>
        <BoardBaseFormRowScroll>
          <ContentWrap>
            <ProgressBoard 
              wageType={wageType} 
              type={1} 
              targetYMD={searchDate}
              daylyData={daylyData} 
              beforeData={beforeData} 
              progressList={progressList}
            />
            <OperationBoard 
              targetYMD={searchDate} 
              setTargetYMD={(date)=>onSetTargetYMD(date)} 
              workplaceId={workplaceId} 
              baseId={baseId}
            />
          </ContentWrap>
        </BoardBaseFormRowScroll>
      </BoardBaseFormRow>
    </>
  )
}

export default RealTimeProgrss

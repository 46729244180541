import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const CardWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: ${props => props.cardWidth ? props.cardWidth : '400px'};
  height: ${props => props.cardHeight ? props.cardHeight : '400px'};
  background-color: #fff;
`

const CardHeader = styled.div`
  display: none; /* 横並び */
  flex-wrap: wrap;
  width: 100%;
  height: 32px;
  background: #dcdcdc;
  box-sizing: border-box;
  text-align:center;
  vertical-align:middle;
`

const CardContent = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
`

const CameraView = styled.img`
  margin: auto;
  cursor: pointer;
`

// 拡大表示
const ExtendedImg = styled.div`
  background: rgba(0,0,0,.7);
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, .8);
  display: ${props => props.active ? 'block' : 'none'};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  z-index: 100000;
  overflow: none;

  .wrap {
    display: flex; /* 縦並び */
    flex-flow: column;
    width: 100%;
    height: 100%;

    .header {
      display: none;
      width: 100%;
      height: 10%;
    }

    .contens {
      width: 100%;
      /* height: 90%; */
      height: 100%;
      position: relative;

      /* 中央表示 */
      .outer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
      }
    }
  }

  /* 閉じるアイコン（右上） */
  .icon-close {
    background: #000;
    color: #fff;
    font-size: 30px;
    padding: 0 10px;
    position: absolute;
    right: 0;
    cursor: pointer;
  }
`

const CardCamera = (props) => {
  const {
    title,
    img,
    height,
    width,
  } = props
  const [active, setActive] = useState(false);

  // 拡大ボタンク(画像)リックイベント
  const onExpansion = () => {
    setActive(true);
  }

  // 閉じるボタン(画像)クリックイベント
  const onClose = () => {
    setActive(false);
  }
  return (
    <CardWrap cardWidth={width} cardHeight={height}>
      <CardHeader>{title}</CardHeader>
      <CardContent>
        <CameraView src={img} onClick={onExpansion}/>
        <ExtendedImg active={active}>
          <div className='wrap'>
            <div className='header'>
              <span className='icon-close' onClick={onClose}>×</span>
            </div>
            <div className='contens'>
              <img className='outer' src={img} style={{height:'93%'}} onClick={onClose}/>
            </div>
          </div>
        </ExtendedImg>
      </CardContent>
    </CardWrap>
  )
}

CardCamera.propTypes = {
    title: PropTypes.string, // タイトル
    img: PropTypes.object, // 画像データ
    height: PropTypes.string, // 画像の高さ
    width: PropTypes.string, // 画像の横幅
}

export default CardCamera

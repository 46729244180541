import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const MediaWrap = styled.div`
  width:1800px;
  display: flex; /* 横並び */
  flex-wrap: wrap;
  margin: 4px;
  box-shadow: 0 0 3px 2px 'rgba(0,0,0,0.4)';
  background-color: #f2b3b3;
  color: #fff;
  font-size: 14px;
  font-weight: bold;

  text-align: center;
  align-items: center;
`
const MediaItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 25px;
  border-right: 2px dashed #fff;
`

const ProgressTitle = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
` 

const MediaHeader = (props) => {
  const {
    progressList
  } = props

  // 初期値
  useEffect(() => {
  })

  //集計  
  // 0:予定物量、1:実績物量、2:進捗率
  const sumItem=(type)=>{
    var sum = 0

    switch(type){
      case 0:
        progressList.map((item,index)=>{
          var data = item.vasShippingItemInfo
          sum += parseInt(data.totalPlanShippingSouzai)
          sum += parseInt(data.totalPlanShipping)
        })
        sum = sum.toLocaleString()
        break;
      case 1:
        progressList.map((item,index)=>{
          var data = item.vasShippingItemInfo
          sum += parseInt(data.totalShippingSouzai)
          sum += parseInt(data.totalShipping)
        })
        sum = sum.toLocaleString()
        break; 
      case 2:
        var sumResult = 0
        var sumPlan = 0
        progressList.map((item,index)=>{
          var data = item.vasShippingItemInfo
          sumResult += parseInt(data.totalShippingSouzai)
          sumResult += parseInt(data.totalShipping)
          sumPlan += parseInt(data.totalPlanShippingSouzai)
          sumPlan += parseInt(data.totalPlanShipping)
        })

        sum = sumResult/sumPlan * 100
        if(isNaN(sum)) sum = 0
        sum = sum.toFixed(2)
        break;
      default:
        break;
    }

    return sum
  }
  return (
    <MediaWrap>
      <MediaItem>
        <ProgressTitle style={{width:'150px'}}>集計</ProgressTitle>
      </MediaItem>
      <MediaItem>
        <ProgressTitle style={{width:'300px'}}>{progressList.length}</ProgressTitle>
      </MediaItem>
      <MediaItem>
        <ProgressTitle style={{width:'80px'}}>-</ProgressTitle>
      </MediaItem>
      <MediaItem>
        <ProgressTitle style={{width:'80px'}}>{sumItem(0)}</ProgressTitle>
      </MediaItem>
      <MediaItem>
        <ProgressTitle style={{width:'80px'}}>{sumItem(1)}</ProgressTitle>
      </MediaItem>
      <MediaItem>
        <ProgressTitle style={{width:'150px'}}>{sumItem(2)}</ProgressTitle>
      </MediaItem>
    </MediaWrap>
  )
}

MediaHeader.propTypes = {
  progressList: PropTypes.array, // データリスト
}

export default MediaHeader

import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import styled from 'styled-components'

import { GetApi } from '../../../Common/ApiAxios'
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'
import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'

import {XAxis, YAxis, Tooltip, Legend, Line,PieChart,Pie,Cell, Bar,ComposedChart,Area } from "recharts"
import IconSettingImg from '../../../../image/update-icon.png'

import Tooltips from '@material-ui/core/Tooltip'

const DefaultUrl = '/api/logisticManagement'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  text-align: center;
  vertical-align: middle;
`

const ContentBody = styled.div`
  display: grid;
  grid-gap: 30px 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row: 1 / 2;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`

const BodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  overflow: hidden;
  vertical-align: middle;
  width: 400px;
  height: 100%;
`

const GraphContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
`

const COLORS = [
  '#6e6eff',
  '#6fb7ff',
  '#5bcaff',
  '#4646ff',
  '#51a8ff',
  '#28b9ff',
  '#1e8eff',
  '#00acff',
  '#0074e8',
  '#0099e3',
  '#005ebb',
  '#007ab5',
  '#00509f',
  '#005f8c',
  '#003a75',
  '#004364',
];
const ProgressGraphBoard = (props) => {
  //const { loginUserInfo } = useSelector(state => state);
  const {
    progressList,barGraph
  } = props

  // 描画時に一度だけ実行
  useEffect(() => {
    console.log(progressList,barGraph)
  }, [progressList,barGraph])

  return (
    <>
      <ContentWrap>
        <ContentBody>
        <GraphContent>
          <ComposedChart 
              width={1450}
              height={200}
              data={barGraph}
              margin={{top: 0, right: 0, left: 0, bottom: 5}}>
                <XAxis xAxisId={1} dataKey="deliveryDate" />
                <YAxis yAxisId={1}/>
                {barGraph && barGraph.length && barGraph[0].vasCargoGraphData.map((entry, index) => (
                  <Bar 
                    type="bar" 
                    xAxisId={1} 
                    yAxisId={1} 
                    strokeWidth={2} 
                    dataKey={'vasCargoGraphData['+index+'].cargoCount'} 
                    name={entry.ownerName} 
                    fill={COLORS[index % COLORS.length]} 
                    stackId="a"
                  />
                ))}
                <Tooltip />
                <Legend/>
          </ComposedChart >
        </GraphContent>
        {progressList&&
        <BodyContent>
          <PieChart 
          width={400}
          height={200}>
          <Pie
              data={progressList}
              cx="50%"
              cy="50%"
              labelLine={false}
              startAngle={450}
              endAngle={90}
              outerRadius={100}
              fill="#8884d8"
              dataKey="cargoCount"
              nameKey="ownerName"
          >
            {progressList.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
          </Pie>
          <Tooltip/>
          <Legend layout="vertical" verticalAlign="top" align="right" width={170}   wrapperStyle={{fontSize:10}}/>
        </PieChart >
        </BodyContent>
        }
        </ContentBody>
      </ContentWrap>
    </>
  )
}

export default ProgressGraphBoard

import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { GetApi } from '../../../Common/ApiAxios'
import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'

const DefaultUrl = '/api/logisticManagement'

const Wrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  float: flex;
  width: 100%;
  text-align: center;
  vertical-align: middle;
`

const Flex = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 100%;
  text-align: center;
  vertical-align: middle;
`

const Content = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  width: calc(100% - 210px);
  height: 100%;
  text-align: center;
  vertical-align: middle;

  margin-left: auto;
  margin-right: auto;

  ::-webkit-scrollbar-corner {
    display: none;
  }
`

const Title = styled.div`
  width: 200px;
  height: 50px;
  margin: 5px;

  font-size: 20px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
`

const MoneyPanel= styled.div`
  width: 100%;
  height: 50px;
  background-color: #cfe2f3;
  border-radius: 10px;
  border: none;
  margin: 5px;

  font-size: 16px;
`

const NamePanel= styled.div`
  width: 200px;
  height: 35px;
  background-color: #cfe2f3;
  border-radius: 10px;
  border: none;
  margin: 5px;

  font-size: 16px;
`

const ValuePanel = styled.div`
  width: 100%;
  height: 35px;
  background-color: #cfe2f3;
  border-radius: 10px;
  border: none;
  margin: 5px;

  font-size: 16px;
  vertical-align: middle;
`

const MoneyPanelContent = styled.div`
  margin-top: 15px;
`

const PanelContent = styled.div`
  margin-top: 10px;
`

function getTotalStaffNun(operation) {
  if (operation === undefined || operation === null) {
    return '0名／0名';
  }
  return operation.totalResultStaffNum + '名／' + operation.totalPlanStaffNum + '名';
}

function getStaffNum(setting, operation) {
  if (setting === undefined || setting === null) {
    return '0名／0名';
  }
  if (operation === undefined || operation === null) {
    return '0名／0名';
  }

  for (const process of operation.processes) {
    if (process.processId === setting.processId) {
      //console.log(process);
      return process.resultStaffNum + '名／' + process.planStaffNum + '名';
    }
  }
  
  return '0名／0名';
}

function getTotalWorkCost(operation) {
  if (operation === undefined || operation === null) {
    return '-,--- 　円';
  }

  return LogisticUtility.getCommaText(operation.totalWorkCost / 1000) + ' 千円';
}

function getTotalHourTime(operation) {
  if (operation === undefined || operation === null) {
    return '00.00';
  }

  return operation.totalHourTime.toFixed(2);
}

const OperationWeekly = (props) => {
  const {
    grants,
    dates,
  } = props
  const alert = useAlert();
  //const interval1Ref = useRef(null);
  const [setting,setSetting] = useState([]);
  const [operationStatus0,setOperationStatus0] = useState(null);
  const [operationStatus1,setOperationStatus1] = useState(null);
  const [operationStatus2,setOperationStatus2] = useState(null);
  const [operationStatus3,setOperationStatus3] = useState(null);
  const [operationStatus4,setOperationStatus4] = useState(null);
  const [operationStatus5,setOperationStatus5] = useState(null);
  const [operationStatus6,setOperationStatus6] = useState(null);

  // 稼働状況設定情報の取得
  const apiGetOperationStatusSetting = async () => {
    //console.log('call apiGetOperationStatusSetting')
    //dispatch(SetLoadSpinner(true))

    const apiUrl = DefaultUrl + '/getOperationStatusSetting';
    const param = {
      workplaceId: '000011',
    }

    const result = await GetApi(apiUrl, param);

    //dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('稼働状況設定取得失敗');
      setSetting([]);
    } else {
      //console.log(result);
      setSetting(result.data);
    }
  }
  
  // 稼働状況情報の取得
  const apiGetOperationStatusInfo = async (targetYMD,num) => {
    //console.log('call apiGetProcessProgress')
    //dispatch(SetLoadSpinner(true))

    const apiUrl = DefaultUrl + '/getOperationStatusInfo';
    const param = {
      workplaceId: '000011',
      targetYMD: targetYMD,
    }

    const result = await GetApi(apiUrl, param);

    //dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('稼働状況情報取得失敗');
      if (num === 0)
        setOperationStatus0(null);
      else if (num === 1)
        setOperationStatus1(null);
      else  if (num === 2)
        setOperationStatus2(null);
      else  if (num === 3)
        setOperationStatus3(null);
      else if (num === 4)
        setOperationStatus4(null);
      else if (num === 5)
        setOperationStatus5(null);
      else if (num === 6)
        setOperationStatus6(null);
    } else {
      //console.log(result);
      if (num === 0)
        setOperationStatus0(result.data);
      else if (num === 1)
        setOperationStatus1(result.data);
        else  if (num === 2)
        setOperationStatus2(result.data);
      else  if (num === 3)
        setOperationStatus3(result.data);
      else if (num === 4)
        setOperationStatus4(result.data);
      else if (num === 5)
        setOperationStatus5(result.data);
      else if (num === 6)
        setOperationStatus6(result.data);
    }
  }

  // 描画時に一度だけ実行
  useEffect(() => {
    apiGetOperationStatusSetting();

    let index = 0;
    for (const date of dates) {
      apiGetOperationStatusInfo(date.dateValue, index);
      index++;
    }
  }, [])

  //if (!grants || !totalDatas) return null
  return (
    <Wrap>
      <Flex>
          <Title>稼働状況<br/>Weekly</Title>
          <Content>
              <MoneyPanel style={{color:'#fff', backgroundColor:'#ff9900'}}><MoneyPanelContent>{getTotalWorkCost(operationStatus0)}</MoneyPanelContent></MoneyPanel>
              <MoneyPanel style={{color:'#fff', backgroundColor:'#ff9900'}}><MoneyPanelContent>{getTotalWorkCost(operationStatus1)}</MoneyPanelContent></MoneyPanel>
              <MoneyPanel style={{color:'#fff', backgroundColor:'#ff9900'}}><MoneyPanelContent>{getTotalWorkCost(operationStatus2)}</MoneyPanelContent></MoneyPanel>
              <MoneyPanel style={{color:'#fff', backgroundColor:'#ff9900'}}><MoneyPanelContent>{getTotalWorkCost(operationStatus3)}</MoneyPanelContent></MoneyPanel>
              <MoneyPanel style={{color:'#fff', backgroundColor:'#ff9900'}}><MoneyPanelContent>{getTotalWorkCost(operationStatus4)}</MoneyPanelContent></MoneyPanel>
              <MoneyPanel style={{color:'#fff', backgroundColor:'#ff9900'}}><MoneyPanelContent>{getTotalWorkCost(operationStatus5)}</MoneyPanelContent></MoneyPanel>
              <MoneyPanel style={{color:'#fff', backgroundColor:'#ff9900'}}><MoneyPanelContent>{getTotalWorkCost(operationStatus6)}</MoneyPanelContent></MoneyPanel>
          </Content>
      </Flex>
      <Flex>
          <NamePanel style={{color:'#f00', backgroundColor:'#fce5cd', fontWeight:'bold'}}><PanelContent>Total</PanelContent></NamePanel>
          <Content>
              <ValuePanel style={{color:'#f00', backgroundColor:'#fce5cd', fontWeight:'bold'}}><PanelContent>{getTotalStaffNun(operationStatus0)}</PanelContent></ValuePanel>
              <ValuePanel style={{color:'#f00', backgroundColor:'#fce5cd', fontWeight:'bold'}}><PanelContent>{getTotalStaffNun(operationStatus1)}</PanelContent></ValuePanel>
              <ValuePanel style={{color:'#f00', backgroundColor:'#fce5cd', fontWeight:'bold'}}><PanelContent>{getTotalStaffNun(operationStatus2)}</PanelContent></ValuePanel>
              <ValuePanel style={{color:'#f00', backgroundColor:'#fce5cd', fontWeight:'bold'}}><PanelContent>{getTotalStaffNun(operationStatus3)}</PanelContent></ValuePanel>
              <ValuePanel style={{color:'#f00', backgroundColor:'#fce5cd', fontWeight:'bold'}}><PanelContent>{getTotalStaffNun(operationStatus4)}</PanelContent></ValuePanel>
              <ValuePanel style={{color:'#f00', backgroundColor:'#fce5cd', fontWeight:'bold'}}><PanelContent>{getTotalStaffNun(operationStatus5)}</PanelContent></ValuePanel>
              <ValuePanel style={{color:'#f00', backgroundColor:'#fce5cd', fontWeight:'bold'}}><PanelContent>{getTotalStaffNun(operationStatus6)}</PanelContent></ValuePanel>
          </Content>
      </Flex>
      {setting.map(item => (
      <Flex>
        <NamePanel><PanelContent>{item.processName}</PanelContent></NamePanel>
        <Content>
            <ValuePanel><PanelContent>{getStaffNum(item, operationStatus0)}</PanelContent></ValuePanel>
            <ValuePanel><PanelContent>{getStaffNum(item, operationStatus1)}</PanelContent></ValuePanel>
            <ValuePanel><PanelContent>{getStaffNum(item, operationStatus2)}</PanelContent></ValuePanel>
            <ValuePanel><PanelContent>{getStaffNum(item, operationStatus3)}</PanelContent></ValuePanel>
            <ValuePanel><PanelContent>{getStaffNum(item, operationStatus4)}</PanelContent></ValuePanel>
            <ValuePanel><PanelContent>{getStaffNum(item, operationStatus5)}</PanelContent></ValuePanel>
            <ValuePanel><PanelContent>{getStaffNum(item, operationStatus6)}</PanelContent></ValuePanel>
        </Content>
      </Flex>
      ))
      }
      <Flex>
          <NamePanel style={{backgroundColor:'#b6d7a8', fontWeight:'bold'}}><PanelContent>実績工数</PanelContent></NamePanel>
          <Content>
              <ValuePanel style={{backgroundColor:'#b6d7a8', fontWeight:'bold'}}><PanelContent>{getTotalHourTime(operationStatus0)}</PanelContent></ValuePanel>
              <ValuePanel style={{backgroundColor:'#b6d7a8', fontWeight:'bold'}}><PanelContent>{getTotalHourTime(operationStatus1)}</PanelContent></ValuePanel>
              <ValuePanel style={{backgroundColor:'#b6d7a8', fontWeight:'bold'}}><PanelContent>{getTotalHourTime(operationStatus2)}</PanelContent></ValuePanel>
              <ValuePanel style={{backgroundColor:'#b6d7a8', fontWeight:'bold'}}><PanelContent>{getTotalHourTime(operationStatus3)}</PanelContent></ValuePanel>
              <ValuePanel style={{backgroundColor:'#b6d7a8', fontWeight:'bold'}}><PanelContent>{getTotalHourTime(operationStatus4)}</PanelContent></ValuePanel>
              <ValuePanel style={{backgroundColor:'#b6d7a8', fontWeight:'bold'}}><PanelContent>{getTotalHourTime(operationStatus5)}</PanelContent></ValuePanel>
              <ValuePanel style={{backgroundColor:'#b6d7a8', fontWeight:'bold'}}><PanelContent>{getTotalHourTime(operationStatus6)}</PanelContent></ValuePanel>
          </Content>
      </Flex>
    </Wrap>
  )
}

OperationWeekly.propTypes = {
  grants: PropTypes.object, // 権限関連
  dates: PropTypes.array, // 日付情報(1週分)
}

export default OperationWeekly

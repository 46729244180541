import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import styled from 'styled-components'
//import { useAlert } from 'react-alert'
import { useDispatch } from 'react-redux'

//import Select from '../../../Common/Atoms/Select'
import Input from '../../../Common/Atoms/Input'
import NormalButton from '../../../Common/Atoms/NormalButton'

import { PostApi } from '../../../Common/ApiAxios'
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'
import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'

//import DateCalendar from '../../../Common/Atoms/Calendar'
import CloseIcon from '../../../../image/close_icon.png'

const DefaultUrl = '/api/logisticManagement'

/* 縦並 */
const Wrap = styled.div`
  display:flex;
  flex-flow: column;
`

/* 検索項目 */
const SearchItem = styled.div`
  display: flex; /* 横並び */
  flex-flow: row nowrap;
  text-align: left;
  vertical-align: middle;
`

const InputYearlyGoalModal  = (props) => {
  const {
    grants,
    modalIsOpen,
    setIsOpen,
    workplaceId,
    targetYMD,
    yearlyGoal,
    setYearlyGoal,
  } = props
  const dispatch = useDispatch();
  //const alert = useAlert();
  const goalRef = useRef();

  // 年間のCO2排出量目標値の更新
  const apiPostYearGoalEmissions = async (isLoadSplinner) => {
    //console.log('call apiPostYearGoalEmissions');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/updateYearGoalEmissions';

    var now = new Date(targetYMD);
    var year = now.getFullYear();
    var month = now.getMonth();
    if (month < 3) {
      year -= 1;
    }
    const param = {
      workplaceId: workplaceId,
      targetYMD: LogisticUtility.getStringFromDate(new Date(year, 3, 1), 'YYYY-MM-DD'),
      emissions: goalRef.current.value
    }
    //console.log(param);
    const result = await PostApi(apiUrl, param);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('年間のCO2排出量目標値の更新失敗');
    } else {
      //console.log(result);
      setYearlyGoal(goalRef.current.value);

      closeModal();
    }
  }

  // モーダル
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      //opacity: 0.8,
      padding: '10px',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    },
    overlay: {
      //background: 'rgba(0, 0, 0, 0.7)',
      zIndex: 10
    }
  }

  //ダイアログ開いた時の処理
  function afterOpenModal () {
    goalRef.current.value = yearlyGoal;
  }

  function closeModal () {
    setIsOpen(false);
  }

  // 反映ボタンクリックイベント
  const onClickRefer = () => {
    apiPostYearGoalEmissions(true);
  }

  useEffect(() => {
  }, [modalIsOpen])

  // 描画時に一度だけ実行
  useEffect(() => {
  }, [])

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel='Create Zone Modal'
      ariaHideApp={false}
    >
      <div style={{ width:'300px', height:'150px', marginLeft:'1rem' }}>
        <Wrap>
          <div style={{ height:'20px', marginLeft:'auto', marginRight:'10px', marginTop:'10px', cursor: 'pointer'}}>
            <img alt='閉じる' className='close' src={CloseIcon} title={'ダイアログを閉じる'} width="12px" height="12px" onClick={closeModal} />
          </div>
          <SearchItem style={{marginTop:'1rem'}}>
            <label style={{marginTop:'auto', marginRight:'1rem', marginBottom:'auto'}}>年間目標値</label>
            <Input type='text' name='yearlyGoal' width='8rem' style={{marginLeft:'1rem', textAlign:'right', paddingRight: '1rem'}} inputRef={goalRef} />
          </SearchItem>
          <div style={{width:'100%', marginTop:'1rem', marginRight:'1rem', textAlign:'right'}}>
            <div style={{width:'150px', marginLeft:'auto', marginRight:'0px'}}>
              <NormalButton type='button' onClick={onClickRefer} width='100px' name='反映' />
            </div>
          </div>
        </Wrap>
      </div>
    </Modal>
  )
}

InputYearlyGoalModal.propTypes = {
  grants: PropTypes.object, // 権限関連
  modalIsOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  workplaceId: PropTypes.string,
  targetYMD: PropTypes.string, // 
  yearlyGoal: PropTypes.object, // 年間目標値
  setYearlyGoal: PropTypes.func, // 年間目標値のセッター
}

export default InputYearlyGoalModal

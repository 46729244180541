import React, { useState, useEffect } from 'react'
//import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { GetApi } from '../../../Common/ApiAxios'

import TypeTab from '../Atoms/TypeTab'

const DefaultUrl = '/api/logisticManagement'

const Flex = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 10%;
  height: 45x;
  text-align: center;
  vertical-align: middle;
  margin-bottom: 5px;
`

const Content = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
  height: ${props => props.height ? props.height : '200px'};
  margin-left: auto;
  margin-right: auto;

  ::-webkit-scrollbar-corner {
    display: none;
  }
`

// ヘッダー部のカード
const OperationHeader = (props) => {
  const {
    grants,
    dates,
    type,
    onClick
  } = props
  
  // 描画時に一度だけ実行
  useEffect(() => {
  }, [])

  if (type == null) return null
  return (
    <Flex>
      <Content height={'30px'}>
        <TypeTab type={0} summaryType={type} onClick={onClick}/>
        <TypeTab type={1} summaryType={type} onClick={onClick}/>
        {/*2023/12/25 K.Harada DEL 当月進捗を削除*/}
        {/* <TypeTab type={2} summaryType={type} onClick={onClick}/> */}
      </Content>
    </Flex>
  )
}

OperationHeader.propTypes = {
  grants: PropTypes.object, // 権限関連
  dates: PropTypes.array, // 日付情報(1週分)
}
  
export default OperationHeader


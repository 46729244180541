import React, { useState, useEffect } from 'react'
//import { useAlert } from 'react-alert'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'

import { GetApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import * as MoneyUtility from '../../Common/Utilties/MoneyUtility'
import * as LogisticUtility from '../../Common/Utilties/LogisticUtility'
import SettingIcon from '../../../image/setting-icon.png'
import DownIcon from '../../../image/down-red-icon.png'
import UpIcon from '../../../image/up-green-icon.png'
import KPIInfoSettingModal from '../Molcules/KPIInfoSettingModal/KPIInfoSettingModal'

const CardWrap = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: calc(100%-20px);
  border-radius: 10px;
  border: none;
  margin: 10px;
  text-align: left;
`

const KPIContent = styled.div`
  display: grid;
  width: 1780px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 90px; //70px;
  gap: 5px;
  margin: 10px;
  text-align: center;
`

const ItemWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
`

const TitelWrap = styled.div`
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`

const ValueWrap  = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 100%;
  text-align: right;

  font-size: 28px;
  font-weight: bold;
`

const Value = styled.span`
  width: 60%;
  margin: 0;
`

const Unit = styled.span`
  font-size: 18px;
  margin-left: 1rem;
  margin-top: 0.75rem;
`

const Icon = styled.img`
  width: 18px;
  height: 18px;
  margin-left: auto;
  margin-right: 0.5rem;
  margin-top: 0.5rem;

  cursor: pointer;

  display:none;
  ${CardWrap}:hover & {
    display:block;
  }
`
const MoMWrap = styled.div`
  font-size: 16px;
  text-align: center;
  margin-bottom: 5px;
`
const IconUpDown = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 6px;
  margin-top: 0.25rem;
`
//センター運営人数
function getStaffNum(summary) {
  if (!summary || !summary.staffNum)
    return '---';

  return summary.staffNum;
}
//センター運営人数(前月比)
function getStaffMoM(summary,before) {
  var staff = getStaffNum(summary)
  var beforeStaff = getStaffNum(before)
  if(staff=='---' || beforeStaff=='---') return '0人'
  var diff = staff - beforeStaff;
  if (diff == 0) return <span>{LogisticUtility.getCommaText(Math.abs(diff))} 人</span>;
  return <span style={{color:diff>0?'green':'red'}}>▲{LogisticUtility.getCommaText(Math.abs(diff))} 人</span>;
}
//センター運営人数(アイコン)
function getStaffIcon(summary,before) {
  var staff = getStaffNum(summary)
  var beforeStaff = getStaffNum(before)
  if(staff=='---' || beforeStaff=='---') return;
  var diff = Number(staff) - Number(beforeStaff);
  if (diff == 0) return;
  return <IconUpDown src={diff>0?UpIcon:DownIcon} />
}

//労務コスト
function getLaborCost(summary) {
  if(!summary || !summary.laborCost)
    return '0';

  return MoneyUtility.formatMoney((summary.laborCost/1000).toFixed(0));
}
//労務コスト(前月比)
function getLaborCostMoM(summary,before) {
  if (!summary || !summary.laborCost || !before || !before.laborCost) 
    return <span>{'0.00'} ％</span>;

  var cost = summary.laborCost
  var beforeCost = before.laborCost
  var raito = cost/beforeCost*100
  return <span> {raito.toFixed(2)} ％</span>;
}
//労務コスト(アイコン)
function getLaborCostIcon(summary,before) {
  if (!summary || !summary.laborCost || !before || !before.laborCost) 
    return;

  var cost = summary.laborCost
  var beforeCost = before.laborCost
  var diff = cost - beforeCost;
  if (diff == 0) return;
  return <IconUpDown src={diff>0?UpIcon:DownIcon} />
}

//採用コスト
function getRecruitmentCost(summary) {
  if(!summary || !summary.recruitmentCost)
    return '0';

  return MoneyUtility.formatMoney(summary.recruitmentCost.toFixed(0));
}

//採用コスト(前月比)
function getRecruitmentCostMoM(summary,before) {
  if (!summary || !summary.recruitmentCost || !before || !before.recruitmentCost) 
    return <span>{'0.00'} ％</span>;

  var cost = summary.recruitmentCost
  var beforeCost = before.recruitmentCost
  var raito = cost/beforeCost*100
  return <span> {raito.toFixed(2)} ％</span>;
}

//採用コスト(アイコン)
function getRecruitmentCostIcon(summary,before) {
  if (!summary || !summary.recruitmentCost || !before || !before.recruitmentCost) 
    return ;

  var cost = summary.recruitmentCost
  var beforeCost = before.recruitmentCost
  var diff = cost -beforeCost;
  if (diff == 0) return;
  return <IconUpDown src={diff>0?UpIcon:DownIcon} />
}

//平均時給
function getAvgWage(summary) {
  if(!summary || !summary.avgWage)
    return '0';

  return MoneyUtility.formatMoney(summary.avgWage.toFixed(0));
}

//平均時給(前月比)
function getAvgWageMoM(summary,before) {
  if (!summary || !summary.avgWage || !before || !before.avgWage) 
    return <span>{'0.00'} ％</span>;

  var cost = summary.avgWage
  var beforeCost = before.avgWage
  var raito = cost/beforeCost*100;
  return <span> {raito.toFixed(2)} ％</span>;
}

//平均時給(アイコン)
function getAvgWageIcon(summary,before) {
  if (!summary || !before) 
    return;

  var cost = summary.avgWage?summary.avgWage:0;
  var beforeCost = before.avgWage?before.avgWage:0;
  var diff = cost-beforeCost;
  if (diff == 0) return;
  return <IconUpDown src={diff>0?UpIcon:DownIcon} />
}

//平均勤続期間
function getAvgLengthOfService(summary) {
  if(!summary || !summary.avgLengthOfService)
    return '0';

  return summary.avgLengthOfService.toFixed(2);
}

//平均勤続期間(前月比)
function getAvgLengthOfServiceMoM(summary,before) {
  if (!summary || !summary.avgLengthOfService || !before || !before.avgLengthOfService) 
    return <span>{'0.00'} ％</span>;

  var cost = summary.avgLengthOfService;
  var beforeCost = before.avgLengthOfService;
  var raito = cost/beforeCost*100;
  return <span> {raito.toFixed(2)} ％</span>;
}

//平均勤続期間(アイコン)
function getAvgLengthOfServiceIcon(summary,before) {
  var num = getAvgLengthOfService(summary)
  var beforeNum = getAvgLengthOfService(before)
  if(num=='---') num = 0;
  if(beforeNum=='---') beforeNum = 0;
  var diff = Number(num) - Number(beforeNum);
  if (diff == 0) return;

  return <IconUpDown src={diff>0?UpIcon:DownIcon} />
}

//事故発生件数
function getAccidentNum(summary) {
  if (!summary || (!summary.accidentNum && summary.accidentNum==0))
    return '0';

  return summary.accidentNum;
}

//事故発生件数(前月比)
function getAccidentNumMoM(summary,before) {
  var num = getAccidentNum(summary)
  var beforeNum = getAccidentNum(before)
  if(num=='---') num = 0;
  if(beforeNum=='---') beforeNum = 0;
  
  var diff = Number(num) - Number(beforeNum);
  if(diff == 0) return <span>{LogisticUtility.getCommaText(Math.abs(diff))} 件</span>;
  return <span style={{color:diff>0?'green':'red'}}>▲{LogisticUtility.getCommaText(Math.abs(diff))} 件</span>;
}

//事故発生件数(アイコン)
function getAccidentNumIcon(summary,before) {
  var num = getAccidentNum(summary)
  var beforeNum = getAccidentNum(before)
  if(num=='---') num = 0
  if(beforeNum=='---') beforeNum = 0
  var diff = Number(num) - Number(beforeNum);
  if (diff == 0) return;

  return <IconUpDown src={diff>0?UpIcon:DownIcon} />
}

//事故弁済額
function getAccidentPaymentAmount(summary) {
  if (!summary || !summary.accidentPaymentAmount)
    return '0';

  return summary.accidentPaymentAmount;
}

//事故弁済額(前月比)
function getAccidentPaymentAmountMoM(summary,before) {
  if (!summary || !summary.accidentPaymentAmount || !before || !before.accidentPaymentAmount) 
    return <span>{'0.00'} ％</span>;
  var cost = summary.accidentPaymentAmount
  var beforeCost = before.accidentPaymentAmount
  var raito = cost/beforeCost
  return <span style={{color:raito>=1?'green':'red'}}>▲{LogisticUtility.getCommaText(Math.abs(raito*100).toFixed(2))} ％</span>;
}

//事故弁済額(アイコン)
function getAccidentPaymentAmountIcon(summary,before) {
  if (!summary || !summary.accidentPaymentAmount || !before || !before.accidentPaymentAmount) 
    return;
  
  var cost = summary.accidentPaymentAmount
  var beforeCost = before.accidentPaymentAmount
  var diff = cost - beforeCost;
  if (diff == 0) return;

  return <IconUpDown src={diff>0?UpIcon:DownIcon} />
}

function makeItem(item, summary,before) {
  switch(item.itemId) {
    case 0:
      if (item.showFlag === true) 
        return <ItemWrap>
           <TitelWrap>センター運営人数</TitelWrap>
           <MoMWrap>（前月比 {getStaffMoM(summary,before)})</MoMWrap>
           <ValueWrap>
              <Value>{LogisticUtility.getCommaText(getStaffNum(summary))}</Value><Unit>人</Unit>
              <>{getStaffIcon(summary,before)}</>
            </ValueWrap>
          </ItemWrap>
      break;
    case 1:
      if (item.showFlag === true)
        return <ItemWrap>
            <TitelWrap>労務コスト</TitelWrap>
            <MoMWrap>（前月比 {getLaborCostMoM(summary,before)})</MoMWrap>
            <ValueWrap>
              <Value>{getLaborCost(summary)}</Value><Unit>千円</Unit>
              <>{getLaborCostIcon(summary,before)}</>
            </ValueWrap>
          </ItemWrap>
      break;
    case 2:
      if (item.showFlag === true)
        return <ItemWrap>
            <TitelWrap>採用コスト</TitelWrap>
            <MoMWrap>（前月比 {getRecruitmentCostMoM(summary,before)})</MoMWrap>
            <ValueWrap>
              <Value>{getRecruitmentCost(summary)}</Value><Unit>円</Unit>
              <>{getRecruitmentCostIcon(summary,before)}</>
            </ValueWrap>
          </ItemWrap>
      break;
    case 3:
      if (item.showFlag === true)
        return <ItemWrap>
            <TitelWrap>平均時給</TitelWrap>
            <MoMWrap>（前月比 {getAvgWageMoM(summary,before)})</MoMWrap>
            <ValueWrap>
              <Value>{getAvgWage(summary)}</Value><Unit>円</Unit>
              <>{getAvgWageIcon(summary,before)}</>
            </ValueWrap>
          </ItemWrap>
      break;
    case 4:
      if (item.showFlag === true)
        return <ItemWrap>
            <TitelWrap>平均勤続期間</TitelWrap>
            <MoMWrap>（前月比 {getAvgLengthOfServiceMoM(summary,before)})</MoMWrap>
            <ValueWrap>
              <Value>{getAvgLengthOfService(summary)}</Value><Unit>月</Unit>
              <>{getAvgLengthOfServiceIcon(summary,before)}</>
            </ValueWrap>
          </ItemWrap>
      break;
    // case 5:
    //   if (item.showFlag === true)
    //     return <ItemWrap>
    //         <TitelWrap>物量予測値差異率</TitelWrap>
    //         <MoMWrap>（前月比 {'--.-'} ％)</MoMWrap>
    //         <ValueWrap><Value>--.-</Value><Unit>%</Unit></ValueWrap>
    //       </ItemWrap>
    //   break;
    case 6:
      if (item.showFlag === true)
        return <ItemWrap>
            <TitelWrap>事故発生件数</TitelWrap>
            <MoMWrap>（前月比 {getAccidentNumMoM(summary,before)})</MoMWrap>
            <ValueWrap>
              <Value>{LogisticUtility.getCommaText(getAccidentNum(summary))}</Value><Unit>件</Unit>
              <>{getAccidentNumIcon(summary,before)}</>
            </ValueWrap>
          </ItemWrap>
      break;
    case 7:
      if (item.showFlag === true)
        return <ItemWrap>
            <TitelWrap>事故弁済額</TitelWrap>
            <MoMWrap>（前月比 {getAccidentPaymentAmountMoM(summary,before)})</MoMWrap>
            <ValueWrap>
              <Value>{LogisticUtility.getCommaText(getAccidentPaymentAmount(summary))}</Value><Unit>円</Unit>
              <>{getAccidentPaymentAmountIcon(summary,before)}</>
            </ValueWrap>
          </ItemWrap>
      break;
    default:
      break;
  }
  return <></>
}

const orgItemInfos = [
  {itemId:0, showFlag:true, sort:0}
 ,{itemId:1, showFlag:true, sort:1}
 ,{itemId:2, showFlag:true, sort:2}
 ,{itemId:3, showFlag:true, sort:3}
 ,{itemId:4, showFlag:true, sort:4}
 ,{itemId:5, showFlag:false, sort:5}
 ,{itemId:6, showFlag:true, sort:6}
 ,{itemId:7, showFlag:true, sort:7}
];

// 下部のKPIカード
const KPIInfoCard = (props) => {
  const {
//    grants,
    summary,
    before
  } = props
  const dispatch = useDispatch();
  const { loginUserInfo } = useSelector(state => state);
  const [itemInfos,setItemInfos] = useState(null);
  const [openDialog,setOpenDialog] = useState(false);

  const apiGetScreenShowItemInfo = async (isLoadSplinner) => {
    //console.log('call apiGetScreenShowItemInfo');

    if (!loginUserInfo.staffId)
      return;

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = '/api/getScreenShowItemInfo';
    
    const data = {
      staffId: loginUserInfo.staffId,
      url: '/top',
      itemNum: 0
    }
    const result = await GetApi(apiUrl, data);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      //初期値はデータなし
      setItemInfos(orgItemInfos);
    } else {
      setItemInfos(JSON.parse(result.data.showItemJson));
    }
  }

  // 編集クリックイベント
  const onClickEdit = () => {
    //console.log('onClickEdit');
    setOpenDialog(true);
  }

  // 設定の変更イベント
  const onChange = () => {
    apiGetScreenShowItemInfo();
  }

  // ログイン情報が変更された場合
  useEffect(() => {
    apiGetScreenShowItemInfo();
  }, [loginUserInfo])

  // 描画時に一度だけ実行
  useEffect(() => {
    apiGetScreenShowItemInfo();
  }, [])

  if (!itemInfos) return null
  return (
    <CardWrap>
      <KPIContent>
        {itemInfos.map( item =>
          makeItem(item, summary, before)
        )}
      </KPIContent>
      <Tooltip title={'設定'}>
        <Icon alt={'設定'} src={SettingIcon} onClick={onClickEdit} />
      </Tooltip>
      <KPIInfoSettingModal
        itemInfos={itemInfos}
        modalIsOpen={openDialog}
        setIsOpen={setOpenDialog}
        onChange={onChange}
      />
    </CardWrap>
  )
}

KPIInfoCard.propTypes = {
//  grants: PropTypes.object, // 権限関連
    summary: PropTypes.object,
    before: PropTypes.object,
}

export default KPIInfoCard

import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Tooltip from '@material-ui/core/Tooltip'

import { DeleteApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import * as HealthCareUtilty from '../../Common/Utilties/HealthCareUtilty'

import InfoIcon from '../../../image/infomation-icon.png'
import ImportantIcon from '../../../image/important-icon.png'
import WarningIcon from '../../../image/warning-icon.png'
import EditIcon from '../../../image/edit-icon.png'
import DeleteIcon from '../../../image/delete-icon2.png'

import NoticeModal from '../Molcules/NoticeModal/NoticeModal'

const MediaWrap = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: calc(100% - 8px); /* 100% - margin-left - margin-right */
  height: 32px;
  margin: 4px;
  box-shadow: 0 0 3px 2px ${props => props.selected ? 'rgba(0,128,0,0.8)' : 'rgba(0,0,0,0.4)'};

  font-size: 14px;
  color: #000;
`

const MediaItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  text-align: left;
  align-items: center;
  margin-top:  auto;
  margin-bottom: auto;
`

const InfotAt = styled.span`
  width: 80px;
  color: #000000;
  margin-left: 0.25rem;
`

const InfoTagWrap = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  text-align: left;
  align-items: center;
`

const InfoTag = styled.span`
  margin-left: 0.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const InfoTitle= styled.span`
  max-width: 5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const InfoText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const ActionImg = styled.img`
  width: 16px;
  display:none;
  ${MediaWrap}:hover & {
    display:block;
  }
`

function getInfoAt(noticeData) {
  if (noticeData === undefined || noticeData === null)
    return <></>;

  return <span>{HealthCareUtilty.getStringFromDate(noticeData.noticeAt, 'YYYY.MM.DD')}</span>
}

function getInfoTypeIcon(noticeData) {
  if (noticeData === undefined || noticeData === null)
    return <></>;

  switch(noticeData.noticeType) {
    case 0:
      return <Tooltip title='お知らせ'><img style={{width:'20px'}} src={InfoIcon} alt="お知らせ" /></Tooltip>
    case 1:
      return <Tooltip title='重要'><img style={{width:'20px'}} src={ImportantIcon} alt="重要" /></Tooltip>
    case 2:
      return <Tooltip title='警告'><img style={{width:'20px'}} src={WarningIcon} alt="警告" /></Tooltip>
    default:
      return <></>;
  }
}

function getInfoTypeText(noticeData) {
  if (noticeData === undefined || noticeData === null)
    return <></>;
  if (noticeData.title === undefined  || noticeData.title === null || noticeData.title === '')
    return <></>;

  /*
  switch(noticeData.noticeType) {
    case 0:
      return <span>【お知らせ】</span>
    case 1:
      return <span>【重要】</span>
    case 2:
      return <span>【警告】</span>
  }

  return <></>;
  */
  return <>{noticeData.title}</>;
}

const MediaNotice = (props) => {
  const {
    //grants,
    noticeData,
    selected,
    onChangeNotice,
  } = props
  const dispatch = useDispatch();
  const [openDialog,setOpenDialog] = useState(false);

  // お知らせの削除
  const apiDeleteNotice = async (isLoadSplinner) => {
    //console.log('cal apiDeleteNotice');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = '/api/noticeManagement' + `/notice/${noticeData.noticeId}`;

    const result = await DeleteApi(apiUrl, null);

    if (isLoadSplinner)
        dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      //alert.error('お知らせの削除に失敗しました');
    } else {

      onChangeNotice();
    }
  }

  // お知らせ編集クリックイベント
  const onClickEdit = () => {
    //console.log('onClickEdit');
    setOpenDialog(true);
  }

  // お知らせ削除クリックイベント
  const onClickDelete = () => {
    //console.log('onClickDelete');
    apiDeleteNotice(true);
  }

  // 初期値
  useEffect(() => {
  })

  if (!noticeData) return null;
  return (
    <MediaWrap selected={selected}>
    <MediaItem>
      <InfotAt>{getInfoAt(noticeData)}</InfotAt>
    </MediaItem>
      <MediaItem>
        <InfoTagWrap>
          <InfoTag>{getInfoTypeIcon(noticeData)}</InfoTag>
          <span style={{width:'6rem'}}>【<InfoTitle>{getInfoTypeText(noticeData)}</InfoTitle>】</span>
        </InfoTagWrap>
      </MediaItem>
      <MediaItem>
        <InfoText>{noticeData.text}</InfoText>
      </MediaItem>
      <MediaItem style={{marginLeft:'auto', marginRight:'auto'}}>
        <div style={{width:'100px'}}></div>
      </MediaItem>
      <MediaItem style={{textAlign:'right', marginRight:'1rem'}}>
        <Tooltip title='編集'>
          <ActionImg src={EditIcon} onClick={onClickEdit} />
        </Tooltip>
      </MediaItem>
      <MediaItem style={{textAlign:'right', marginRight:'1rem'}}>
        <Tooltip title='削除'>
          <ActionImg src={DeleteIcon} onClick={onClickDelete}/>
        </Tooltip>
      </MediaItem>
      
      <NoticeModal
        noticeData={noticeData}
        type={1}
        modalIsOpen={openDialog}
        setIsOpen={setOpenDialog}
        onChangeNotice={onChangeNotice}
      />
    </MediaWrap>
  )
}

MediaNotice.propTypes = {
  //grants: PropTypes.object, // 権限関連
  noticeData: PropTypes.object, // お知らせ情報
  selected: PropTypes.bool, // 選択状態
  onChangeNotice: PropTypes.func, // お知らせ情報の変更
}

export default MediaNotice

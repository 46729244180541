import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const MediaWrap = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: calc(100%-18px); /* 100% - margin-left - margin-right */
  margin: 4px;
  box-shadow: 0 0 3px 2px ${props => props.selected ? 'rgba(0,128,0,0.8)' : 'rgba(0,0,0,0.4)'};

  color: #000;
  font-size: 16px;
  font-weight: bold;

  text-align: center;
  align-items: center;
`
const MediaItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 60px;
  border-right: 2px dashed #fff;
`

const ItemText = styled.div`
  width: 150px;
  margin: auto;
  text-align: left;

` 

function getItemName(no) {
  let text = '';
  switch(no) {
    case 1:
      text = '売上金額';
      break;
    case 2:
      text = '実車率';
      break;
    case 3:
      text = '稼働率';
      break;
    case 4:
      text = '走行距離稼働時間';
      break;
    case 5:
      text = '作業別作業時間';
      break;
    default:
      text = '';
      break;
  }

  return text;
}

function getItemCalc(no) {
  let text = '';
  switch(no) {
    case 1:
      text = '売上額の合計';
      break;
    case 2:
      text = '実車率(%) =積載時の走行距離%総走行距離×100';
      break;
    case 3:
      text = '稼働率(%) =トラックの稼働時間%年間総時間×100';
      break;
    case 4:
      text = '実車走行距離+空車走行距離の合計稼働時間の合計';
      break;
    case 5:
      text = '作業時間の合計';
      break;
    default:
      text = '';
      break;
  }

  return text;
}

function getItemSrc(no) {
  let text = '';
  switch(no) {
    case 1:
      text = '運送実績';
      break;
    case 2:
      text = 'デジタコ 日報集計データ';
      break;
    case 3:
      text = 'デジタコ 日報集計データ';
      break;
    case 4:
      text = 'デジタコ 日報集計データ';
      break;
    case 5:
      text = 'デジタコ 作業実績周期データ';
      break;
    default:
      text = '';
      break;
  }

  return text;
}

function getItemEtc(no) {
  let text = <></>;
  switch(no) {
    case 1:
      text = <>{'案件（荷主・商品・車両）'}<br/>{'売上'}</>;
      break;
    case 2:
      text = <>{'実車走行距離'}<br/>{'空車走行距離'}</>;
      break;
    case 3:
      text = <>{'稼働時間'}</>;
      break;
    case 4:
      text = <>{'実車走行距離'}<br/>{'空車走行距離'}<br/>{'稼働時間'}</>;
      break;
    case 5:
      text = <>{'作業名'}<br/>{'作業名'}<br/>{'作業時間（到着時刻-出発時刻）'}</>;
      break;
  }

  return text;
}
    
const DescriptionData = (props) => {
const {
grants,
no,
} = props

// 初期値
useEffect(() => {
})

if (!no) return null;
return (
<MediaWrap>
    <MediaItem>
    <ItemText style={{width:'50px', textAlign:'center'}}>{no}</ItemText>
    </MediaItem>
    <MediaItem>
    <ItemText style={{width:'200px'}}><div style={{marginLeft:'1rem'}}>{getItemName(no)}</div></ItemText>
    </MediaItem>
    <MediaItem>
    <ItemText style={{width:'500px'}}><div style={{marginLeft:'1rem'}}>{getItemCalc(no)}</div></ItemText>
    </MediaItem>
    <MediaItem>
    <ItemText style={{width:'400px'}}><div style={{marginLeft:'1rem'}}>{getItemSrc(no)}</div></ItemText>
      </MediaItem>
      <MediaItem>
        <ItemText style={{width:'300px'}}><div style={{marginLeft:'1rem'}}>{getItemEtc(no)}</div></ItemText>
      </MediaItem>
    </MediaWrap>
  )
}

DescriptionData.propTypes = {
  grants: PropTypes.object, // 権限関連
  no: PropTypes.object, // 番号
}

export default DescriptionData

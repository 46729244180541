import React, { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory,useLocation } from 'react-router-dom'

//import { AiFillPlusCircle, AiFillMinusCircle } from 'react-icons/ai'
import { useAlert } from 'react-alert'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '../../Common/Atoms/NormalButton'
//import { PostApi } from '../../Common/ApiAxios'

//import Select from '../../Common/Atoms/SelectAddValue'
//import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
//import { GetApi } from '../../Common/ApiAxios'
import Input from '../../Common/Atoms/Input'

import SearchWrap,{ FunctionContent, FunctionContentRow, FunctionIcon, FunctionName} from '../Wrap/SearchWrap'


import IconFunctionImg from '../../../image/three-reader-vertical-icon.png'
import IconTopImg from '../../../image/menu-icon3.png'
//import IconCloseImg from '../../../image/close_icon.png'
import IconReturnImg from '../../../image/return-icon.png'

const Search = (props) => {
  const dispatch = useDispatch()
  const companyRef = useRef()
  const [renderFlg, setRenderFlg] = useState(false)
  const [plusButton, setPlusButton] = useState(false)
  const {
    title, showButton, onClick, onKeyDown, staffIdRef, staffNameRef, firstSearch, grants,
    showCalendar,
    inSubCalendar, inSubChildren,
    addStyle, children, relationStatus,
    onClickFunction,
    onCloseFunction,viewFunction
  } = props
  const alert = useAlert()
  const loginUserInfo = useSelector(state => state.loginUserInfo)
  const history = useHistory();
  const location = useLocation(); 
  const hisState = location.state

  // 初期値
  useEffect(() => {
  // 必要なデータが更新された場合のみ実行
  // eslint-disable-next-line
  }, [loginUserInfo, dispatch])

  // レンダー終了判定

  // 初期検索
  useEffect(() => {
    if (renderFlg && firstSearch) {
      onClick()
    }
  }, [renderFlg, firstSearch, onClick])

  useEffect(() => {

  // 必要なデータが更新された場合のみ実行
  // eslint-disable-next-line
  }, [loginUserInfo, relationStatus])
  async function topPage () {
    history.push('/top');
  }

  async function back () {
    var tmp = hisState
    //console.log(hisState)
    if(tmp && tmp.pathname)
      history.push({state:tmp,pathname:tmp.pathname });
    else
      history.goBack()
  }

  const viewSubSearch = false
  return (
    <SearchWrap addStyle={addStyle} plusButton={plusButton} viewSubSearch={viewSubSearch} viewFunction={viewFunction}>
      <h3>{title}</h3>
      <ul className='mainSearch'>
        <li>
          <label>ID</label>
          <Input type='text' name='staffId' inputRef={staffIdRef} onKeyDown={onKeyDown} disabled={grants.readGrantDivision === 3}
           width='12rem'
           />
        </li>
        <li>
          <label>名称</label>
          <Input type='text' name='staffName' inputRef={staffNameRef} onKeyDown={onKeyDown} disabled={grants.readGrantDivision === 3}
           width='24rem'
           />
        </li>
      </ul>
      {showButton && <Button margin='0 0 0 16px' name='検索' padding='0px' onClick={onClick} />}
      <div></div>
      <Tooltip title={'機能メニュー'}>
        <img alt='機能メニュー' src={IconFunctionImg} width="24px" height="24px" style={{marginTop: '4px',marginLeft: 'auto', cursor: 'pointer'}} onClick={onClickFunction} />
      </Tooltip>
      <li className='mainFunction'>
        <FunctionContent onMouseLeave={onCloseFunction}>
          <FunctionContentRow onClick={topPage} style={{marginTop:'12px'}}>
            <FunctionIcon alt='トップページ' src={IconTopImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>トップページ</span></FunctionName>
          </FunctionContentRow>
          <hr></hr>
          <FunctionContentRow onClick={back} style={{marginTop:'12px',marginBottom:'12px'}}>
            <FunctionIcon alt='戻る' src={IconReturnImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>戻る</span></FunctionName>
          </FunctionContentRow>
        </FunctionContent>
      </li>
    </SearchWrap>
  )
}

Search.propTypes = {
  title: PropTypes.string, // タイトル
  showButton: PropTypes.bool, // 検索ボタン表示
  onClick: PropTypes.func, // 検索処理
  onKeyDown: PropTypes.func, // キーダウン処理
  staffIdRef: PropTypes.object, // 検索項目関連
  staffNameRef: PropTypes.object, // 検索項目関連
  firstSearch: PropTypes.bool, // 最初に検索処理をするか
  grants: PropTypes.object, // 権限関連
  inSubChildren: PropTypes.bool, // その他メニューをサブメニューに表示 メインには表示しない
  addStyle: PropTypes.string, // css用追加用
  children: PropTypes.any, // 子要素
}

export default Search

import React, { useEffect,useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {PieChart,Pie,Cell } from "recharts"

const BodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  overflow: hidden;
  vertical-align: middle;
  width: 221px;
  height: 230px;
  box-shadow: 2px 2px 6px rgba(0,0,0,.4);
  background-color: ${props => props.contentColor?props.contentColor:'#fff'};
`

const Process = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  text-align:center;
  vertical-align:middle;
  padding: 1vw;
  hidden;
`

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  text-align: center;
  vertical-align: middle;
  padding: 0.4rem;
  opacity: ${props => props.contentOpacity?1:0};
`

const ProcessTitle = styled.div`
  font-weight: bold;
  color: #000;
  text-align: center;
  font-size: 1.5rem;
`

const ProgressContentRow = styled.div`
  color: #000;
  text-align: center;
  font-size: 1.5rem;
`


const data = [
    { name: 'A', value: 80, color: '#4E79A6' },
    { name: 'B', value: 80, color: 'lightgray' },
  ];
  const cx = 85;
  const cy = 85;
  const iR = 50;
  const oR = 70;

const StoreGraph = (props) => {
  const {
    entry,opacity,searchDate
  } = props

  const [data,setData] = useState([
  ]);
  useEffect(() => {
    if(entry.planStoreQuantity==0){
      setData(
        [
          { name: '実績', value: Number(0), color: '#4E79A6' },
          { name: '残り', value: Number(1), color: 'lightgray' },])
    }else{
      var remain = Number(entry.planStoreQuantity)-Number(entry.progressStoreQuantity)
      remain = remain>0?remain:0
      setData(
        [
          { name: '実績', value: Number(entry.progressStoreQuantity), color: '#4E79A6' },
          { name: '残り', value: remain, color: 'lightgray' },])
    }

  }, [entry])

  const makeContent=(progress,plan)=>{
    if(progress && plan) return <><span style={{fontWeight: 'bold'}}>{Number(progress).toLocaleString()}</span> <span style={{fontSize:'90%'}}>/</span> <span style={{fontSize:'70%'}}>{Number(plan).toLocaleString()}</span></>
    return <></>
  }
  
  const setOpacityAndClor=()=>{
    //2023/12/27 K.Harada ADD 当日以外をクローズに変更 start
    if(searchDate) return false;
    //2023/12/27 K.Harada ADD 当日以外をクローズに変更 end
    if(!entry.progressEndTimeDiff) return true
    let tokens = entry.progressEndTimeDiff.split(':')
    let sec = 0

    if (tokens[0]) sec += (Number(tokens[0]) - 9)
    if(Number(tokens[0])<12){
      if (tokens[1] && Number(tokens[1])) sec += Number(tokens[1])/60
    }else if(Number(tokens[0])>12){
      sec -= 1
      if (tokens[1] && Number(tokens[1])) sec += Number(tokens[1])/60
    }

    return Number(entry.progressStoreQuantityRate) < sec/8 * 100
  }

  return (
    <ContentWrap contentOpacity={(entry.planStoreQuantity!=0 && Number(entry.progressStoreQuantityRate<100) && setOpacityAndClor())?opacity:true}>
        <BodyContent contentColor={entry.planStoreQuantity==0?'gray':setOpacityAndClor() && Number(entry.progressStoreQuantityRate<100)?'salmon':'#fff'}>
            <Process>
                <div><ProcessTitle>{entry.storeName}</ProcessTitle></div>
                <PieChart width={221} height={103}>
                {entry.planStoreQuantity!=0 &&
                <>
                <Pie
                dataKey="value"
                startAngle={180}
                endAngle={0}
                data={data}
                cx={cx}
                cy={cy}
                innerRadius={iR}
                outerRadius={oR}
                fill="#8884d8"
                stroke="none"
                >
                {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
                </Pie>
                <text x={cx} y={cy} dx={8}  fontSize={'18px'} textAnchor="middle" fill={'black'} fontWeight={'bold'}>{entry.progressStoreQuantityRate+'%'}</text>
                <text x={cx-oR+15} y={cy} dy={18} textAnchor="middle" fill={'black'}>{'0'}</text>
                <text x={cx+oR-5} y={cy} dy={18} textAnchor="middle" fill={'black'}>{'100'}</text>
                </>
                }
                </PieChart>
            </Process>
            {/* 進捗詳細 */}
            {entry.planStoreQuantity!=0 &&
            <>
            <div>{'作業物量進捗'}</div>
            <ProgressContentRow>{makeContent(entry.progressStoreQuantity,entry.planStoreQuantity)}</ProgressContentRow>
            </>
            }
        </BodyContent>
    </ContentWrap>
  )
}
StoreGraph.propTypes = {
}

export default StoreGraph

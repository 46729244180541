import React, { useState } from 'react'
//import { useSelector, useDispatch } from 'react-redux'
//import { useAlert } from 'react-alert'
//import FUNC from '../../Common/FunctionEynum'
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {XAxis, YAxis, Tooltip, Legend, Line, Bar,ComposedChart } from "recharts"

const FlexWrap = styled.div`
  display: flex;
  flex-direction: column;
`
const ContentWrap = styled.div`
  display: flex;
  flex-direction: row;

  justify-content:flex-end;
`

const AveWageGraph = (props) => {
  const {
    processList,type,wageType
  } = props

  return (
    <FlexWrap>
      <ContentWrap>
      <ComposedChart 
            width={1080}
            height={200}
            data={processList}
            margin={{top: 10, right: 0, left: 0, bottom: 5}}>
              <XAxis xAxisId={1} dataKey="dataValue"/>
              <YAxis yAxisId={1}  domain={[0, dataMax => (dataMax+(100 - dataMax%100))]} tickCount={6}/>
              <Tooltip formatter={(value) => new Intl.NumberFormat('ja-JP', {maximumFractionDigits: 0}).format(value.toFixed(0))} />  
              <Bar type="bar" xAxisId={1} yAxisId={1} strokeWidth={2} dataKey="productivity" name="生産性" fill="rgba(254,97,132,0.8)" />       
              <Legend/>
      </ComposedChart >
      </ContentWrap>
    </FlexWrap>
  )
}

AveWageGraph.propTypes = {
  processList: PropTypes.array, // 工程情報
  type: PropTypes.number, // 0：日単位(上グラフ),1:月単位(下グラフ)
  wageType: PropTypes.number, // 平均時給タイプ　0:契約から算出,1:労務費から算出
}

export default AveWageGraph

import React, { useEffect } from 'react'
//import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'

//import { GetApi } from '../../../Common/ApiAxios'
import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'

const CardWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${props => props.bColor === 3 ? '#f00' : '#1155cc'};
  border-radius: 10px;
  border: none;

  color: #fff;
`

const HeaderContent = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 100%;
  height: 40%;
  text-align:center;
  vertical-align:middle;

  margin:0.25rem;
`

const Title = styled.span`
  width: 100%;
  font-size: 2.0vw;
  font-weight: bold;
  margin:auto;
`

const BodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 60%;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;

  margin:0.25rem;
`

// 現在値
const PresentValue = styled.span`
  font-size: 1.7vw;
  font-weight: bold;
`

// 前週比
const YoYChange = styled.span`
  font-size: 1.25vw;
  font-weight: bold;
`

function getTitle(type) {
  var title = '';
  switch(type) {
    case 0:
      title = '平均物量';
      break;
    case 1:
      title = '平均時給';
      break;
    case 2:
      title = '平均稼働率';
      break;
    case 3:
      title = '事故発生件数';
      break;
    default:
      title = '';
      break;
  }

  return title;
}

function getValue(totalData, type) {
  var title = '';
  switch(type) {
    case 0:
      if (totalData !== undefined && totalData !== null) {
        title = LogisticUtility.getCommaText(Math.floor(totalData.avgQuantity));
      }
      break;
    case 1:
      title = '000.00' + " 円";
      break;
    case 2:
      title = '000.00' + " %";
      break;
    case 3:
      title = '000' + " 件";
      break;
    default:
      title = '';
      break;
  }

  return title;
}

function getRate(totalData, type) {
  var title = '000.00';

  switch(type) {
    case 0:
      if (totalData !== undefined && totalData !== null) {
        if (totalData.beforeAvgQuantity === 0) {
          title = '--.--';
        } else {
          title = LogisticUtility.getCommaText((totalData.avgQuantity * 100 / totalData.beforeAvgQuantity).toFixed(2));
        }
      }
      break;
    case 1:
      break;
    case 2:
      break;
    case 3:
      break; 
    default:
      break;   
  }

  return title;
}

// ヘッダー部のカード
const CardTotal2 = (props) => {
  const {
    grants,
    totalData,
    type,
  } = props
  //const alert = useAlert();
  //const interval1Ref = useRef(null);

  // 描画時に一度だけ実行
  useEffect(() => {
  }, [])

  //if (!grants || !totalData) return null
  return (
    <CardWrap bColor={type}>
      <HeaderContent>
          <Title>{getTitle(type)}</Title>
      </HeaderContent>
      <BodyContent>
          <PresentValue>{getValue(totalData, type)}</PresentValue>
          <YoYChange>(前週比：{getRate(totalData, type)} %)</YoYChange>
      </BodyContent>
    </CardWrap>
  )
}

CardTotal2.propTypes = {
  grants: PropTypes.object, // 権限関連
  totalData: PropTypes.object, // 集計情報
  type: PropTypes.number, // 0:物量 1:時給 2:稼働率 3:事故発生
}

export default CardTotal2

import React, { useState, useEffect,useRef } from 'react'
import { useSelector } from 'react-redux'
//import { useAlert } from 'react-alert'
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'
//import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import * as LogisticUtility from '../../Common/Utilties/LogisticUtility'//2023/12/27 K.Harada ADD date=>string変換用
import { useDispatch } from 'react-redux'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import { SetWorkplaceId } from '../../Common/Slice/SettingSlice'
import FUNC from '../../Common/FunctionEnum'

import {BoardBaseFormRow, BoardBaseFormRowScroll} from '../Wrap/BoardBaseWrap'

//import Tooltip from '@material-ui/core/Tooltip'

//import IconChangeImg from '../../../image/page-type-change-icon.png'
import Search from './Search'
//import SideSubMenu from '../SideSubMenu'
import ProgressPerformance from './ProgressPerformance'
import ShootPerformance from './ShootPerformance'
import StorePerformance from './StorePerformance'

import SideSubMenu from '../SideSubMenu'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  overflow-y: none;
  overflow-x: none;
`

const SorterPerformance = (props) => {
  const { loginUserInfo } = useSelector(state => state);
  const [viewFunction,setViewFunction] = useState(false);
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC);
  const subGrants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC_DASHBOARD);
  const searchDateRef = useRef() //2023/12/27 K.Harada ADD 日付用変数追加
  const { SettingInfo } = useSelector(state => state)

  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }

  // 機能メニューの閉じるイベント
  const onCloseFunction = (event) => {
    setViewFunction(false);
  }

  useEffect(() => {
    if (grants && subGrants)
      document.title = grants.functionMenuName + '【' + subGrants.functionMenuName + '】 | CoreBoard'
    else if (grants)
      document.title = grants.functionMenuName + ' | CoreBoard'
    else
      document.title = 'CoreBoard'
  }, [grants, subGrants])
  
  const intervalRef = useRef(null);
  const [windowType,setWindowType]=useState(0)
    // 周期初期のクリア
    const onClearInerval = async () => {
      if (intervalRef.current !== null)
        clearInterval(intervalRef.current);
      
      intervalRef.current = null;
    }
  
    // 描画時に一度だけ実行
    useEffect(() => {
      dispatch(SetWorkplaceId('000011'));
      intervalRef.current = setInterval(() => {
        setWindowType((windowType)=>{
            switch(windowType) {
              case 0: // 全体進捗
                return 1;
              case 1: // シュート進捗
                return 2;
              case 2: // 店舗進捗
                return 0;
            }
          }
        )
      }, 1000 * 30); // 30秒間隔で変更
    
      return () => {
        onClearInerval();
      };
    }, [])
    // 描画時に一度だけ実行
    useEffect(() => {
      intervalRef.current = setInterval(() => {
        var time = LogisticUtility.getStringFromDate(new Date(), 'hh:mm')
        console.log(SettingInfo.changeTime)
        if(SettingInfo.timeChangeFlag && time==SettingInfo.changeTime){
          searchDateRef.current = LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD')
        }
       }, 1000 * 30); // 30秒間隔で変更
      return () => {
        onClearInerval();
      };
    }, [SettingInfo.timeChangeFlag,SettingInfo.changeTime])
    //2023/12/27 K.Harada ADD 日付初期値挿入用
    const dispatch = useDispatch();
    const onChange=(date)=>{
      searchDateRef.current = date
      dispatch(SetLoadSpinner(true));
    }
    if (!searchDateRef.current) {
        searchDateRef.current = LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD') 
    }
  return (
    <>
      <Search
        onClickFunction={onClickFunction}
        onCloseFunction={onCloseFunction}
        viewFunction={viewFunction}
        searchDateRef={searchDateRef}
        onChange={(date)=>onChange(date)}
      />
      <BoardBaseFormRow>
        <SideSubMenu height={'84vh'} searchDate={searchDateRef.current}/>
        <BoardBaseFormRowScroll>
          <ContentWrap>
            {(SettingInfo.changeFlag) && windowType==0 && <ProgressPerformance searchDate={searchDateRef.current}/>}
            {(SettingInfo.changeFlag) && windowType==1 && <ShootPerformance searchDate={searchDateRef.current}/>}
            {(SettingInfo.changeFlag) && windowType==2 && <StorePerformance searchDate={searchDateRef.current}/>}
            {(!SettingInfo.changeFlag) &&
            <>
              <ProgressPerformance searchDate={searchDateRef.current}/>
              <ShootPerformance searchDate={searchDateRef.current}/>
              <StorePerformance searchDate={searchDateRef.current}/>
            </>
            }
          </ContentWrap>
        </BoardBaseFormRowScroll>
      </BoardBaseFormRow>
    </>
  )
}

export default SorterPerformance

import React, { useState } from 'react'
import { useHistory } from "react-router-dom"
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import Tooltip from '@material-ui/core/Tooltip'

import FUNC from '../../Common/FunctionEnum'
import { PostApi } from '../../Common/ApiAxios'
import * as LogisticUtility from '../../Common/Utilties/LogisticUtility'
//import Select from '../../Common/Atoms/Select'

import IconSearchImg from '../../../image/search-icon.png'
import IconCollapseImg from '../../../image/Collapse.png'
import IconFunctionImg from '../../../image/three-reader-vertical-icon.png'
import IconTopImg from '../../../image/menu-icon3.png'
import IconCloseImg from '../../../image/close_icon.png'

import SearchWrap ,{ FunctionContent, FunctionContentRow, FunctionIcon, FunctionName} from '../Wrap/BoardSerachWrap'

import SearchOptionModal from './Molcules/SearchOptionModal'

const Search = (props) => {
  const {
    addStyle,
    onClickFunction,
    onCloseFunction,
    viewSubSearch,
    viewFunction,
    searchWorkplaceId,
    setSearchWorkplaceId,
    searchDate,
    setSearchDate,
  } = props
  const history = useHistory();
  const loginUserInfo = useSelector(state => state.loginUserInfo);
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC);
  const subGrants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC_CARBONEMISSIONS);
  //ダイアログ表示用
  const [openDialog1, setOpenDialog1] = useState(false);
  const [workplaceName, setWorkplaceName] = useState('');
  // 検索ボタンクリックイベント
  const onClickSearchBtn=()=>{
    setOpenDialog1(true);
  }

  // TOP画面遷移クリックイベント
  async function topPage () {
    history.push('/top');
  }

  const changeCalendar = (dateStr) => {
    //console.log(dateStr);
    if (!dateStr) {
      //dispatch(SetWorkDate(null))
      //{setSearchDate && setSearchDate(null)}
      if (setSearchDate && setSearchDate(null))
        return null;
    }
    if (searchDate !== dateStr) {
      //dispatch(setSearchDate(dateStr))
      {setSearchDate && setSearchDate(dateStr)}
    }

    // ダイアログを閉じる
    //setOpenDialog1(false);
  }
  
  if (!grants && !subGrants) return null;
  return (
    <SearchWrap addStyle={addStyle} viewSubSearch={viewSubSearch} viewFunction={viewFunction}>
      <h3>{grants.functionMenuName}</h3>
      <img alt='階層' src={IconCollapseImg} width="24px" height="24px" style={{marginTop:'4px'}}/>
      <div className='subTitleItem' style={{fontSize:'16px',margin:'8px 0 0 12px'}}>{subGrants.functionMenuName}</div>
      <ul className='mainSearch'>
        <li>
          {workplaceName}
        </li>
        <li>
          {LogisticUtility.getStringFromDate(new Date(searchDate), 'YYYY年MM月')}
        </li>
        <li>
          <Tooltip title={'検索オプション'}>
            <img alt='検索オプション' src={IconSearchImg} width="24px" height="24px" style={{ marginTop: '4px',cursor: 'pointer' }} onClick={onClickSearchBtn}  />
          </Tooltip>
        </li>
        <li className='subSearch'>
        </li>
      </ul>
      <div></div>
      <Tooltip title={'機能メニュー'}>
        <img alt='機能メニュー' src={IconFunctionImg} width="24px" height="24px" style={{marginTop: '4px',marginLeft: 'auto', cursor: 'pointer'}} onClick={onClickFunction} />
      </Tooltip>
      <li className='mainFunction'>
        <FunctionContent onMouseLeave={onCloseFunction}>
          <FunctionContentRow onClick={topPage} style={{marginTop:'12px',marginBottom:'12px'}}>
            <FunctionIcon alt='トップページ' src={IconTopImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>トップページ</span></FunctionName>
          </FunctionContentRow>
        </FunctionContent>
      </li>
      
      <SearchOptionModal
        grants={grants}
        modalIsOpen={openDialog1}
        setIsOpen={setOpenDialog1}
        changeCalendar={changeCalendar}
        searchWorkplaceId={searchWorkplaceId}
        setSearchWorkplaceId={setSearchWorkplaceId}
        setSearchWorkplaceName={setWorkplaceName}
        searchDate={searchDate}
      />
    </SearchWrap>
  )
}

Search.propTypes = {
  addStyle: PropTypes.string, // css用追加用
  onClickFunction: PropTypes.func,
  onCloseFunction: PropTypes.func,
  viewSubSearch: PropTypes.bool, // サブ検索項目を表示するか
  viewFunction: PropTypes.bool, // サブ機能を表示するか
  searchWorkplaceId: PropTypes.object,
  setSearchWorkplaceId: PropTypes.func,
  searchDate: PropTypes.string,
  setSearchDate: PropTypes.func,
}

export default Search

import React, { useState, useEffect } from 'react'
//import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { GetApi } from '../../../Common/ApiAxios'

import PocTableList from '../Atoms/PocTableList'

const Flex = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 100%;
  text-align: center;
  vertical-align: middle;
`

const Content = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  width: 95%;
  height: ${props => props.height ? props.height : '200px'};
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
  ::-webkit-scrollbar-corner {
    display: none;
  }
`

// ヘッダー部のカード
const OperationHeader = (props) => {
  const {
    grants,
    dates,
    quantity
  } = props
  
  // 描画時に一度だけ実行
  useEffect(() => {
  }, [])

  if (!quantity || quantity == null) return null
  return (
    <Flex>
      <Content height={'700px'}>
        <PocTableList type={0} totalData={[
          {index:1,truckId:7957,companyName:'PAL運送事業部',driverName:'大阪 一郎',automobileRank:'4トン'
          ,temperatureArea:'チルド',work:'荷降ろし',workTime:'2023/11/27 06:10:01',
          workplaceName:'AFS南関東センター',cago:17,cart:2,coldCago:0,dolly:0,trauckTemperature:'2'},
          {index:2,truckId:7960,companyName:'PAL運送事業部',driverName:'東京 次郎',automobileRank:'4トン'
          ,temperatureArea:'チルド',work:'荷降ろし',workTime:'2023/11/27 04:10:01',
          workplaceName:'ランドポート習志野',cago:17,cart:2,coldCago:0,dolly:0,trauckTemperature:'2'},
          {index:3,truckId:7959,companyName:'東京運送',driverName:'福岡 三郎',automobileRank:'4トン'
          ,temperatureArea:'ドライ',work:'回収',workTime:'2023/11/27 03:10:01',
          workplaceName:'AFS南関東センター',cago:17,cart:2,coldCago:0,dolly:0,trauckTemperature:'2'},
          {index:4,truckId:7959,companyName:'東京運送',driverName:'福岡 三郎',automobileRank:'4トン'
          ,temperatureArea:'ドライ',work:'回収',workTime:'2023/11/27 00:00:01',
          workplaceName:'神奈川運送南関東高瀬センター',cago:17,cart:2,coldCago:0,dolly:0,trauckTemperature:'2'},
          {index:5,truckId:7950,companyName:'神奈川運送南関東高瀬センター',driverName:'福岡 三郎',automobileRank:'4トン'
          ,temperatureArea:'ドライ',work:'回収',workTime:'2023/11/27 10:09:01',
          workplaceName:'AFS南関東センター',cago:17,cart:2,coldCago:0,dolly:0,trauckTemperature:'2'},
          {index:6,truckId:'0000',companyName:'運送事業部',driverName:'ドライバー',automobileRank:'4トン'
          ,temperatureArea:'ドライ',work:'回収',workTime:'2023/11/27 00:00:00',
          workplaceName:'運送会社',cago:0,cart:0,coldCago:0,dolly:0,trauckTemperature:'0'},
          {index:7,truckId:'0000',companyName:'運送事業部',driverName:'ドライバー',automobileRank:'4トン'
          ,temperatureArea:'ドライ',work:'回収',workTime:'2023/11/27 00:00:00',
          workplaceName:'運送会社',cago:0,cart:0,coldCago:0,dolly:0,trauckTemperature:'0'},
          {index:8,truckId:'0000',companyName:'運送事業部',driverName:'ドライバー',automobileRank:'4トン'
          ,temperatureArea:'ドライ',work:'回収',workTime:'2023/11/27 00:00:00',
          workplaceName:'運送会社',cago:0,cart:0,coldCago:0,dolly:0,trauckTemperature:'0'},
          {index:9,truckId:'0000',companyName:'運送事業部',driverName:'ドライバー',automobileRank:'4トン'
          ,temperatureArea:'ドライ',work:'回収',workTime:'2023/11/27 00:00:00',
          workplaceName:'運送会社',cago:0,cart:0,coldCago:0,dolly:0,trauckTemperature:'0'},
          {index:10,truckId:'0000',companyName:'運送事業部',driverName:'ドライバー',automobileRank:'4トン'
          ,temperatureArea:'ドライ',work:'回収',workTime:'2023/11/27 00:00:00',
          workplaceName:'運送会社',cago:0,cart:0,coldCago:0,dolly:0,trauckTemperature:'0'},
          {index:11,truckId:'0000',companyName:'運送事業部',driverName:'ドライバー',automobileRank:'4トン'
          ,temperatureArea:'ドライ',work:'回収',workTime:'2023/11/27 00:00:00',
          workplaceName:'運送会社',cago:0,cart:0,coldCago:0,dolly:0,trauckTemperature:'0'},
          {index:12,truckId:'0000',companyName:'運送事業部',driverName:'ドライバー',automobileRank:'4トン'
          ,temperatureArea:'ドライ',work:'回収',workTime:'2023/11/27 00:00:00',
          workplaceName:'運送会社',cago:0,cart:0,coldCago:0,dolly:0,trauckTemperature:'0'},
          {index:13,truckId:'0000',companyName:'運送事業部',driverName:'ドライバー',automobileRank:'4トン'
          ,temperatureArea:'ドライ',work:'回収',workTime:'2023/11/27 00:00:00',
          workplaceName:'運送会社',cago:0,cart:0,coldCago:0,dolly:0,trauckTemperature:'0'},
          {index:14,truckId:'0000',companyName:'運送事業部',driverName:'ドライバー',automobileRank:'4トン'
          ,temperatureArea:'ドライ',work:'回収',workTime:'2023/11/27 00:00:00',
          workplaceName:'運送会社',cago:0,cart:0,coldCago:0,dolly:0,trauckTemperature:'0'},
          {index:16,truckId:'0000',companyName:'運送事業部',driverName:'ドライバー',automobileRank:'4トン'
          ,temperatureArea:'ドライ',work:'回収',workTime:'2023/11/27 00:00:00',
          workplaceName:'運送会社',cago:0,cart:0,coldCago:0,dolly:0,trauckTemperature:'0'},
          {index:17,truckId:'0000',companyName:'運送事業部',driverName:'ドライバー',automobileRank:'4トン'
          ,temperatureArea:'ドライ',work:'回収',workTime:'2023/11/27 00:00:00',
          workplaceName:'運送会社',cago:0,cart:0,coldCago:0,dolly:0,trauckTemperature:'0'},
          {index:18,truckId:'0000',companyName:'運送事業部',driverName:'ドライバー',automobileRank:'4トン'
          ,temperatureArea:'ドライ',work:'回収',workTime:'2023/11/27 00:00:00',
          workplaceName:'運送会社',cago:0,cart:0,coldCago:0,dolly:0,trauckTemperature:'0'},
          {index:19,truckId:'0000',companyName:'運送事業部',driverName:'ドライバー',automobileRank:'4トン'
          ,temperatureArea:'ドライ',work:'回収',workTime:'2023/11/27 00:00:00',
          workplaceName:'運送会社',cago:0,cart:0,coldCago:0,dolly:0,trauckTemperature:'0'},
        ]}/>
      </Content>
    </Flex>
  )
}

OperationHeader.propTypes = {
  grants: PropTypes.object, // 権限関連
  dates: PropTypes.array, // 日付情報(1週分)
}
  
export default OperationHeader


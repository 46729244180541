import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import styled from 'styled-components'
import StoreGraph from './Atoms/StoreGraph'

import { GetApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import * as LogisticUtility from '../../Common/Utilties/LogisticUtility'
//import ErrorHandling from '../../Common/ErrorHandling'

const DefaultUrl = '/api/logisticManagement'

const BodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: row;
  vertical-align: middle;
  height: 250px;
`
const ContentWrap = styled.div`
  display: flex; /* 横並び */
  flex-direction: column;
  vertical-align: middle;
  width: 100%;
  height: 520px;
  padding:0.5rem;
`

const Title = styled.div`
  font-weight: bold;
  color: #000;
  text-align: left;
  font-size: 1.8rem;
  padding:0.5rem;
`

const data = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16]
const StorePerformance = (props) => {
  const {
    searchDate
  } = props

    //const { loginUserInfo } = useSelector(state => state);

    const dispatch = useDispatch();
    const alert = useAlert();
    const [processList,setProcessList] = useState([]);
    const intervalRef = useRef(null);
    
    const[opacity,setOpacity]=useState(true)

    // 進捗設定情報の取得
    const apiGetSoterReal = async (isLoadSplinner) => {
      //console.log('call getActivityProgressManagement');
      if (isLoadSplinner)
        dispatch(SetLoadSpinner(true));
  
      const apiUrl = DefaultUrl + '/getStoreReal';
     
      const data = {
        workplaceId: '000011',
        targetYMD: searchDate//2023/12/27 K.Harada MOD 日付指定をするように変更//LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD'),
      }
      const result = await GetApi(apiUrl, data);
  
      if (isLoadSplinner)
        dispatch(SetLoadSpinner(false));
  
      if (result.errorDetail) {
         //alert.error('条件の該当データがありません(進捗設定情報の取得)');
      } else {
        //console.log(result.data);
        setProcessList(result.data);
      }
    }

    // 周期初期のクリア
    const onClearInerval = async () => {
      if (intervalRef.current !== null)
        clearInterval(intervalRef.current);
      
      intervalRef.current = null;
    }
  
    // 描画時に一度だけ実行
    useEffect(() => {
      apiGetSoterReal();
  
      intervalRef.current = setInterval(() => {
        apiGetSoterReal();
      }, 1000 * 60 * 5); // 5分間隔で変更
      
      intervalRef.current = setInterval(() => {
        setOpacity((opacity) => !opacity)
      }, 1000 * 2); // 2秒間隔で変更

      return () => {
        onClearInerval();
      };
    }, [searchDate])
  //データ数
  const dataLength = 16;
  
  const makeSearchDate=()=>{
    //2023/12/27 K.Harada ADD 当日以外をクローズに変更 start
    var now = new Date()
    var nowDate = now.getFullYear() + '-' + ('00'+Number(now.getMonth()+1)).slice(-2)+ '-' + ('00'+now.getDate()).slice(-2)
    return nowDate != searchDate
    //2023/12/27 K.Harada ADD 当日以外をクローズに変更 end
  }
  return (
    <ContentWrap>
      <Title>店舗別作業進捗</Title>
      <BodyContent>
        {processList && processList.map((entry, index) => 
          {  
            if(index<dataLength/2){
              return (
                <StoreGraph entry={entry} key={'store_'+index} opacity={opacity} searchDate={makeSearchDate()}/>
              )
            }     
          })
        }
      </BodyContent>
      <BodyContent>
        {processList && processList.map((entry, index) => 
          {  
            if(index>=dataLength/2 && index<dataLength){
              return (
                <StoreGraph entry={entry} key={'store_'+index} opacity={opacity} searchDate={makeSearchDate()}/>
              )
            }     
          })
        }
      </BodyContent>
      <BodyContent>
        {processList && processList.map((entry, index) => 
          {  
            if(index>=dataLength){
              return (
                <StoreGraph entry={entry} key={'store_'+index} opacity={opacity} searchDate={makeSearchDate()}/>
              )
            }     
          })
        }
      </BodyContent>
    </ContentWrap>
  )
}
StorePerformance.propTypes = {
}

export default StorePerformance

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
//import { useAlert } from 'react-alert'
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'
//import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import { BarChart, Bar, PieChart, Pie, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import FUNC from '../../Common/FunctionEnum'
import { GetApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import * as HealthCareUtilty from '../../Common/Utilties/HealthCareUtilty'
//import Button from '../../Common/Atoms/NormalButton'

import BoardBaseWrap,{BoardBaseFormRow} from '../Wrap/BoardBaseWrap'

import Search from './Search'
import SideSubMenu from '../SideSubMenu'
import CardTotal from './Atoms/CardTotal'
import CardTotal2 from './Atoms/CardTotal2'

const DefaultUrl = '/api/truckManagement'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 85vh;
  text-align: center;
  vertical-align: middle;
`

const ContentFlex = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  text-align: center;
`

const ContentHeader1 = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  width: 90%;
  margin-left: auto;
  margin-right: auto;

  ::-webkit-scrollbar-corner {
    display: none;
  }
`

const ContentHeader2 = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;

  ::-webkit-scrollbar-corner {
    display: none;
  }
`

const ContentGraph = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;

  ::-webkit-scrollbar-corner {
    display: none;
  }
`
const Graph =  styled.div`
  width: 100%;
  height: 300px;
  margin: auto;
`

const COLORS = [
  '#0072F0',
  '#00B6CB',
  '#F10096',
  '#FFA800',
  '#F66D00',
];

function getMileageText(mileage) {
  var text = '';
  if (mileage === null)
    return text;

  text = HealthCareUtilty.getCommaText(Math.round(mileage / 10) / 10);

  if (text.length > 0 && !text.includes('.'))
    text += '.0';
    
  return text;
}

function getRateText(rate) {
  var text = '';
  if (rate === null)
    return text;

  text = HealthCareUtilty.getCommaText(Math.round(rate * 10) / 10);

  if (text.length > 0 && !text.includes('.'))
    text += '.0';
  
  return text;
}

const Summary = (props) => {
  const dispatch = useDispatch()
  const { loginUserInfo } = useSelector(state => state);
  const [viewFunction,setViewFunction] = useState(false);
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.TRUCK);
  const subGrants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.TRUCK_SUMMARY);

  const [reportList, setRportList] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  const [mileageData, setMileageData] = useState([{name:'割合',occupiedMileage:0,freeMileage:0}]);

  // 1段目
  const [emissions, setEmissions] = useState(null);
  const [actualVehicleRate, setActualVehicleRate] = useState(null);
  const [utilizationRate, setUtilizationRate] = useState(null);
  // 2段目
  const [totalMileage, setTotalMileage] = useState(null);
  const [occupiedMileage, setOccupiedMileage] = useState(null);
  const [freeMileage, setFreeMileage] = useState(null);
  const [operatingTime, setOperatingTime] = useState(null);

  const CustomPieOccupiedTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{background:"#fff", border:'1px solid #c0c0c0'}}>
          <div style={{margin:'10px', textAlign:'left'}}>
            <p className="label">{`実車走行距離(km)`}</p>
          </div>
          <div style={{margin:'10px', textAlign:'left'}}>
            <p className="label">{` ${payload[0].name} : ${getMileageText(payload[0].value)}
             (${getRateText(payload[0].value * 100 / occupiedMileage)} %)`}</p>
          </div>
        </div>
      );
    }
  
    return null;
  };

  const CustomPieFreeTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{background:"#fff", border:'1px solid #c0c0c0'}}>
          <div style={{margin:'10px', textAlign:'left'}}>
            <p className="label">{`空車走行距離(km)`}</p>
          </div>
          <div style={{margin:'10px', textAlign:'left'}}>
            <p className="label">{` ${payload[0].name} : ${getMileageText(payload[0].value)}
             (${getRateText(payload[0].value * 100 / freeMileage)} %)`}</p>
          </div>
        </div>
      );
    }
  
    return null;
  };

  const CustomBaroltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      //console.log(payload);
      return (
        <div style={{background:"#fff", border:'1px solid #c0c0c0'}}>
          <div style={{margin:'10px', textAlign:'left'}}>
            <p className="label">{`${payload[0].name}(10m単位) ${HealthCareUtilty.getCommaText(occupiedMileage)} (${getRateText(payload[0].value)} %)`}</p>
            <p className="label">{`${payload[1].name}(10m単位) ${HealthCareUtilty.getCommaText(freeMileage)} (${getRateText(payload[1].value)} %)`}</p>
          </div>
        </div>
      );
    }
  
    return null;
  };

  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }
  // 機能メニューボタンを閉じるイベント
  const onCloseFunction = (event) => {
    setViewFunction(false);
  }

  // 別サイトに移動クリックイベント
  const onClikcJumpURL = () => {
    window.open("https://datastudio.google.com/u/0/reporting/b40e4b1b-0ede-42f9-a1f5-e4958a3d0a42/page/FrawC?s=ryzR4ttsqfo", "_blank");
  }

  // 対象の運送進捗（期間）情報の取得
  const apiGetSummarySpanReportAggregateInfo = async () => {
    console.log('call apiGetSummarySpanReportAggregateInfo');
    dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getSummarySpanReportAggregateInfo';
    const data = {
      //startYM: HealthCareUtilty.getStringFromDate(new Data(), 'YYYY-MM-DD'),
      //endYM: HealthCareUtilty.getStringFromDate(new Data(), 'YYYY-MM-DD'),
      startYM: '',
      endYM: '',
    }
    const result = await GetApi(apiUrl, data);

    dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      //alert.error('条件の該当データがありません(対象の運送進捗（月単位）情報の取得)');
    } else {
      //console.log(result.data);

      //var dateYMs = [];
      var vehicleList = [];
      var vehicleNos = [];
      var sumOccupiedMileage = 0;
      var sumfreeMileage = 0;
      var sumOperatingTime = 0;
      var numberOfDays = 0;
      var totalMonthTime = 0;
      var sumFuelAmount = 0;
      result.data.forEach(element => {
        sumOccupiedMileage += element.occupiedMileage;
        sumfreeMileage += element.freeMileage;
        sumOperatingTime += element.operatingTime;
        sumFuelAmount += (element.fuelAmount1 + element.fuelAmount2 + element.fuelAmount3);

        /*
        var date = HealthCareUtilty.getStringFromDate(new Date(element.operationDt), 'YYYY-MM');
        if (!dateYMs.includes(date)) {
          dateYMs.push(date);
          numberOfDays += element.numberOfDays;
        }
        */

        if (!vehicleNos.includes(element.vehicleNo)) {
          vehicleNos.push(element.vehicleNo);
        }

        var item = vehicleList.find(elem => elem.vehicleNo === element.vehicleNo);
        if (item === undefined) {
          vehicleList.push(
            {
              vehicleNo:element.vehicleNo,
              vehicleName:element.vehicleName,
              occupiedMileage:element.occupiedMileage,
              freeMileage:element.freeMileage,
              totalMileage:element.occupiedMileage + element.freeMileage,
              operatingTime:element.operatingTime,
              fuelAmount1:element.fuelAmount1,
              fuelAmount2:element.fuelAmount2,
              fuelAmount3:element.fuelAmount3,
            }
          );
        } else {
          item.occupiedMileage+=element.occupiedMileage;
          item.freeMileage+=element.freeMileage;
          item.totalMileage+=element.occupiedMileage + element.freeMileage;
          item.operatingTime+=element.operatingTime;
          item.fuelAmount1+=element.fuelAmount1;
          item.fuelAmount2+=element.fuelAmount2;
          item.fuelAmount3+=element.fuelAmount3;
        }
      });

      vehicleList.sort(function (a, b) {
        return a.vehicleNo - b.vehicleNo;
      });
      vehicleList.forEach(element => {
        element.totalMileage = Number((element.totalMileage / 10).toFixed(1));
        element.operatingTime = Number((element.operatingTime / 3600).toFixed(1));
      });

      var termDay = (new Date(result.data[0].operationDt) - new Date(result.data[result.data.length-1].operationDt)) / 86400000;
      numberOfDays = termDay;
      totalMonthTime = numberOfDays * 24 * 3600 * vehicleNos.length;

      // 1段目
      setEmissions(sumFuelAmount / 1000 * 37.7 * 0.0187 * 44 / 12);
      if (sumOccupiedMileage + sumfreeMileage === 0) {
        setActualVehicleRate(null);
      } else {
        setActualVehicleRate(sumOccupiedMileage / (sumOccupiedMileage + sumfreeMileage));
      }

      if (totalMonthTime === 0) {
        setUtilizationRate(null);
      } else {
        // 稼働率の算出?
        setUtilizationRate(sumOperatingTime / (1416*60*60*5) * 100);
      }
      
      // 2段目
      setTotalMileage(sumOccupiedMileage+sumfreeMileage);
      setOccupiedMileage(sumOccupiedMileage);
      setFreeMileage(sumfreeMileage);
      setOperatingTime(sumOperatingTime);
      setRportList(result.data);
      setVehicleList(vehicleList);

      var list = [];
      var rate = (Math.floor(sumOccupiedMileage * 1000 / (sumOccupiedMileage+sumfreeMileage)) / 10);
      list.push(
        {
          name:'割合',
          occupiedMileage: rate,
          freeMileage:100-rate
        }
      )
      setMileageData(list);
    }
  }

  useEffect(() => {
    if (grants && subGrants)
      document.title = grants.functionMenuName + '【' + subGrants.functionMenuName + '】 | CoreBoard'
    else if (grants)
    document.title = grants.functionMenuName + ' | CoreBoard'
    else
      document.title = 'CoreBoard'
  }, [grants, subGrants])
    
  // 描画時に一度だけ実行
  useEffect(() => {
    apiGetSummarySpanReportAggregateInfo();
  }, [])

  return (
    <BoardBaseWrap>
      <Search
        onClickFunction={onClickFunction}
        onCloseFunction={onCloseFunction}
        viewFunction={viewFunction}
      />
      <BoardBaseFormRow>
        <SideSubMenu/>
        <ContentWrap>
          <ContentHeader1>
            <CardTotal type={0} value={emissions}></CardTotal>
            <CardTotal type={1} value={actualVehicleRate}></CardTotal>
            <CardTotal type={2} value={utilizationRate}></CardTotal>
          </ContentHeader1>
          <ContentHeader2>
            <CardTotal2 type={0} value={totalMileage}></CardTotal2>
            <CardTotal2 type={1} value={occupiedMileage}></CardTotal2>
            <CardTotal2 type={2} value={freeMileage}></CardTotal2>
            <CardTotal2 type={3} value={operatingTime}></CardTotal2>
          </ContentHeader2>
          <ContentGraph>
            <Graph>
              <ContentFlex>
                <div style={{width:'400px', height:'250px'}}>
                  <span>実車走行距離</span>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={vehicleList}
                        cx="50%"
                        cy="50%"
                        fill="#8884d8"
                        paddingAngle={5}
                        nameKey="vehicleNo"
                        dataKey="occupiedMileage"
                      >
                      {vehicleList.map((entry, index) => (
                          <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                          />
                      ))}
                      </Pie>
                      <Tooltip content={ <CustomPieOccupiedTooltip/>} />
                      <Legend layout="vertical" verticalAlign="top" align="right" />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
                <div style={{width:'400px', height:'250px'}}>
                  <span>空車走行距離</span>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={vehicleList}
                        cx="50%"
                        cy="50%"
                        fill="#8884d8"
                        paddingAngle={5}
                        nameKey="vehicleNo"
                        dataKey="freeMileage"
                      >
                      {vehicleList.map((entry, index) => (
                          <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                          />
                      ))}
                      </Pie>
                      <Tooltip content={ <CustomPieFreeTooltip/>} />
                      <Legend layout="vertical" verticalAlign="top" align="right" />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </ContentFlex>
              <div style={{width:'100%', height:'80px'}}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={750}
                    height={60}
                    layout="vertical"
                    data={mileageData}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                  <XAxis type="number"/>
                  <YAxis dataKey="name" type="category" />
                  <Tooltip content={ <CustomBaroltip/>}/>
                  <Legend />
                  <Bar name="実車走行距離" dataKey="occupiedMileage" stackId="a" fill="#F08080" />
                  <Bar name="空車走行距離" dataKey="freeMileage" stackId="a" fill="#87CEEB" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Graph>
            <Graph>
              <div style={{width:'100%', height:'330px'}}>
                <span>端末別作業状況</span>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={vehicleList}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="vehicleNo" />
                    <YAxis yAxisId="left" orientation="left" />
                    <YAxis yAxisId="right" orientation="right" />
                    <Tooltip />
                    <Legend />
                    <Bar yAxisId="right" name="走行距離(km)" dataKey="totalMileage" fill="#0072F0" />
                    <Bar yAxisId="left" name="稼働時間(時間)" dataKey="operatingTime" fill="#00B6CB" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Graph>
          </ContentGraph>
          {/*<Button type='button' width='4rem' margin='auto' name='表示' padding='0px' onClick={onClikcJumpURL} />*/}
        </ContentWrap>
      </BoardBaseFormRow>
    </BoardBaseWrap>
  )
}

export default Summary

import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const MediaWrap = styled.div`
  width:1800px;
  display: flex; /* 横並び */
  flex-wrap: wrap;
  margin: 4px;
  box-shadow: 0 0 3px 2px 'rgba(0,0,0,0.4)';
  background-color: #f2b3b3;
  color: #fff;
  font-size: 14px;
  font-weight: bold;

  text-align: center;
  align-items: center;
`
const MediaItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 25px;
  border-right: 2px dashed #fff;
`

const ProgressTitle = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
` 

const MediaSumHeaderItemEmissions = (props) => {
  const {
    progressList
  } = props
  /**
   * 秒数を時間(HH:mm)の文字列で返す
   * @param {int} seconds 秒
   * @return {string} 時間(HHmm)の文字列
   */
  function secToTime(seconds) {


    if(!seconds) return '-'
    var hour = seconds / 3600 | 0
    var minutes = seconds % 3600 / 60 | 0

    return ('00' + hour.toString()).slice(-2) + ':' + ('00' + minutes.toString()).slice(-2)
  }
  //アイテム数集計  
  // 0:予定、1:実績
  const sumItem=(type)=>{
    var sum = 0

    switch(type){
      case 0:
        progressList.map((item,index)=>{
          var data = item.vasShippingItemInfo
          sum += parseInt(data.totalPlanItem)
        })
        sum = sum.toLocaleString()
        break;
      case 1:
        progressList.map((item,index)=>{
          var data = item.vasShippingItemInfo
          sum += parseInt(data.totalItem)
        })
        sum = sum.toLocaleString()
        break;
      case 2:
        progressList.map((item,index)=>{
          var data = item.vasShippingItemInfo
          var tmp = parseInt(data.timeZone)
          if(!isNaN(tmp))sum += tmp
        })
        sum = secToTime(sum)
        break;     
      case 3:
        var sumResult = 0
        var sumPlan = 0
        progressList.map((item,index)=>{
          var data = item.vasShippingItemInfo
          sumPlan += parseInt(data.totalPlanItem)
          sumResult += parseInt(data.totalItem)
        })

        sum = sumResult/sumPlan * 100
        if(isNaN(sum)) sum = 0
        sum = sum.toFixed(2)
        break; 
      default:
        break;
    }

    return sum
  }
  // 初期値
  useEffect(() => {
  })

  return (
    <MediaWrap>
      <MediaItem>
        <ProgressTitle style={{width:'150px'}}>集計</ProgressTitle>
      </MediaItem>
      <MediaItem>
        <ProgressTitle style={{width:'200px'}}>{progressList.length}</ProgressTitle>
      </MediaItem>
      <MediaItem>
        <ProgressTitle style={{width:'80px'}}>-</ProgressTitle>
      </MediaItem>
      <MediaItem>
        <ProgressTitle style={{width:'80px'}}>{sumItem(0)}</ProgressTitle>
      </MediaItem>
      <MediaItem>
        <ProgressTitle style={{width:'80px'}}>{sumItem(1)}</ProgressTitle>
      </MediaItem>
      <MediaItem>
        <ProgressTitle style={{width:'150px'}}>{sumItem(2)}</ProgressTitle>
      </MediaItem>
      <MediaItem>
        <ProgressTitle style={{width:'100px'}}>{sumItem(3)}</ProgressTitle>
      </MediaItem>
    </MediaWrap>
  )
}

MediaSumHeaderItemEmissions.propTypes = {
  progressList:PropTypes.array //種まき進捗データリスト
}

export default MediaSumHeaderItemEmissions

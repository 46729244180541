import React, { useEffect } from 'react'
import styled from 'styled-components'
//import PropTypes from 'prop-types'

import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'

import IconPageNextImg from '../../../../image/PageNext.png'
import IconPageBackImg from '../../../../image/PageBack.png'

const MediaWrap = styled.div`
  width:1798px;
  display: flex; /* 横並び */
  flex-wrap: wrap;
  margin: 4px;
  box-shadow: 0 0 3px 2px 'rgba(0,0,0,0.4)';
  background-color: #649CDF;
  color: #fff;
  font-size: 14px;
  font-weight: bold;

  text-align: center;
  align-items: center;
`
const MediaItem = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  height: 40px;
  border-right: 2px dashed #fff;
`

const MediaTitle = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
` 


function getDateText(dateList, index) {
  if (dateList === undefined || dateList === null)
    return <></>;
  else if (dateList.length <= index)
    return <></>;

  return<><span>{LogisticUtility.getStringFromDate(new Date(dateList[index].dateValue), 'MM / DD')}</span></>
}

function getWeekText(dateList, index) {
  if (dateList === undefined || dateList === null)
    return <></>;
  else if (dateList.length <= index)
    return <></>;

  return <>（<span style={{color:LogisticUtility.getDateColor(dateList[index])}}>{dateList[index].week}</span>）</>
}

const MediaHeaderRange = (props) => {
  const {
    dateRangeList
    , dateIndex
    , onClickBack
    , onClickNext
  } = props

  // 初期値
  useEffect(() => {
  })

  return (
    <MediaWrap>
      <MediaItem>
        <MediaTitle style={{width:'120px'}}>スタッフID</MediaTitle>
      </MediaItem>
      <MediaItem>
        <MediaTitle style={{width:'200px'}}>スタッフ名</MediaTitle>
      </MediaItem>
      <MediaItem>
        <MediaTitle style={{width:'140px'}}></MediaTitle>
      </MediaItem>
      <MediaItem>
        <MediaTitle style={{width:'160px'}}>合計（平均）</MediaTitle>
      </MediaItem>
      <MediaItem>
        {dateRangeList.length <=7 &&
          <>
          <MediaTitle style={{width:'160px'}}>{getDateText(dateRangeList, dateIndex + 0)}{getWeekText(dateRangeList, dateIndex + 0)}</MediaTitle>
          </>
        }
        {dateRangeList.length >7 &&
          <>
          <span><img src={IconPageBackImg} onClick={onClickBack} style={{marginLeft: '5px',filter: 'brightness(0) invert(1)'}}/></span>
          <MediaTitle style={{width:'140px'}}>{getDateText(dateRangeList, dateIndex + 0)}{getWeekText(dateRangeList, dateIndex + 0)}</MediaTitle>
          </>
        }
      </MediaItem>
      <MediaItem>
        <MediaTitle style={{width:'160px'}}>{getDateText(dateRangeList, dateIndex + 1)}{getWeekText(dateRangeList, dateIndex + 1)}</MediaTitle>
      </MediaItem>
      <MediaItem>
        <MediaTitle style={{width:'160px'}}>{getDateText(dateRangeList, dateIndex + 2)}{getWeekText(dateRangeList, dateIndex + 2)}</MediaTitle>
      </MediaItem>
      <MediaItem>
        <MediaTitle style={{width:'160px'}}>{getDateText(dateRangeList, dateIndex + 3)}{getWeekText(dateRangeList, dateIndex + 3)}</MediaTitle>
      </MediaItem>
      <MediaItem>
        <MediaTitle style={{width:'160px'}}>{getDateText(dateRangeList, dateIndex + 4)}{getWeekText(dateRangeList, dateIndex + 4)}</MediaTitle>
      </MediaItem>
      <MediaItem>
        <MediaTitle style={{width:'160px'}}>{getDateText(dateRangeList, dateIndex + 5)}{getWeekText(dateRangeList, dateIndex + 5)}</MediaTitle>
      </MediaItem>
      <MediaItem>
        {dateRangeList.length <=7 &&
          <>
          <MediaTitle style={{width:'160px'}}>{getDateText(dateRangeList, dateIndex + 6)}{getWeekText(dateRangeList, dateIndex + 6)}</MediaTitle>
          </>
        }
        {dateRangeList.length > 7 &&
          <>
          <MediaTitle style={{width:'140px'}}>{getDateText(dateRangeList, dateIndex + 6)}{getWeekText(dateRangeList, dateIndex + 6)}</MediaTitle>
          <span><img src={IconPageNextImg} onClick={onClickNext} style={{marginRight: '5px',filter: 'brightness(0) invert(1)'}}/></span>
          </>
        }
      </MediaItem>
    </MediaWrap>
  )
}

MediaHeaderRange.propTypes = {
}

export default MediaHeaderRange

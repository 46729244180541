import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
//import FUNC from '../../Common/FunctionEynum'
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'

import { GetApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import * as LogisticUtility from '../../Common/Utilties/LogisticUtility'
//import ErrorHandling from '../../Common/ErrorHandling'

import {XAxis, YAxis, Tooltip, Legend, Line,Area, Bar,ComposedChart } from "recharts"

const DefaultUrl = '/api/logisticManagement'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 85vh;
  text-align: center;
  vertical-align: middle;
`

const ContentBody = styled.div`
  display: grid;
  grid-gap: 30px 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row: 1 / 2;
  width: 90%;
  margin-left: auto;
  margin-right: auto;

  display: flex; /* 縦並び */
  flex-direction: column;
`

const BoardTitle = styled.div`
  text-align:center;
  font-weight:bold;
  font-size:20px;
  margin-left: 50%;
  transform: translateX(-50%); 
`

const ProgressGraphBoard = (props) => {
  //const { loginUserInfo } = useSelector(state => state);
  const {
    weekly
  } = props

  const dispatch = useDispatch();
  const alert = useAlert();
  const [processList,setProcessList] = useState([]);
  const [beforeProcessList,setBeforeProcessList] = useState([]);
  const [title,setTitle] = useState(null);

  const intervalRef = useRef(null);

  // 進捗設定情報の取得
  const apiGetActivityProgressManagement = async (isLoadSplinner) => {
    //console.log('call getActivityProgressManagement');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getQuantityWeeklySummaryGraph';
   
    const data = {
      workplaceId: '000011',
      targetYMD: LogisticUtility.getStringFromDate(new Date(weekly[0].dateValue), 'YYYY-MM-01'),
    }
    const result = await GetApi(apiUrl, data);
    console.log(result)
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      //alert.error('条件の該当データがありません(進捗設定情報の取得)');
    } else {
      for (const date of weekly) {
        date.acquisitionAt = LogisticUtility.getStringFromDate(new Date(date.dateValue), 'MM/DD'+'（'+date.week+'）')
        date.dateStr = LogisticUtility.getStringFromDate(new Date(date.dateValue), 'MM/DD')
        date.workTime = 0
        date.productivity = 0
        for (const day of result.data) {
          if(day.acquisitionAt == date.dateValue){
            date.workTime = day.workTime
            date.productivity = day.productivity
            break;
          }
        }
      }

      setProcessList(weekly);
      var yearFirst = new Date(new Date(weekly[0].dateValue).getFullYear(), 0, 1);
      var targetDtTime = new Date(weekly[0].dateValue);
      targetDtTime.setHours(1);

      var days = Math.ceil((targetDtTime - yearFirst) / (24*60*60*1000));
      console.log(days)
      var weeks = Math.ceil((days + yearFirst.getDate()) / 7);
      setTitle('工数・生産性推移〔第'+weeks+'週（'+weekly[0].dateStr+'～'+weekly[weekly.length-1].dateStr+'）〕')
      
      let targetDt = new Date(weekly[0].dateValue);
      targetDt.setMonth(targetDt.getMonth()+1, 0);
      if(targetDt.getDay())
      targetDt.setMonth(targetDt.getMonth()+1, 6-targetDt.getDay());
      
      let endDt = new Date(weekly[0].dateValue);
      endDt.setMonth(endDt.getMonth(), 1);
      endDt.setMonth(endDt.getMonth(), 1-endDt.getDay());

      var index = 0
      var date = {}
      var monthList = []
  
      while (targetDt > endDt) {
        index++;
        if(index==1){
          date = {
            acquisitionAt:LogisticUtility.getStringFromDate(endDt, 'MM/DD～'),
            workTime: 0,
            productivity: 0,
            productivityCount: 0
          }
        }
        
        var target = LogisticUtility.getStringFromDate(endDt, 'YYYY-MM-DD');

        
        for (const day of result.data) {
          if(target== day.acquisitionAt){
            date.workTime += day.workTime
            date.productivity += day.productivity
            if(date.productivity )date.productivityCount++;
            break;
          }
        }
        
        if(index==7){
          date.acquisitionAt += LogisticUtility.getStringFromDate(endDt, 'MM/DD')
          if(date.productivityCount){
            date.productivity =  (date.productivity/date.productivityCount).toFixed(2)
          }
          monthList.push(date)
          index=0
        }

        endDt = new Date(endDt.getFullYear(),endDt.getMonth(),endDt.getDate()+1,0,0,0);
      }

      setBeforeProcessList(monthList)
    }
  }

  // 描画時に一度だけ実行
  // useEffect(() => {
  //   apiGetActivityProgressManagement();
  // }, []);
  // 周期初期のクリア
  const onClearInerval = async () => {
    if (intervalRef.current !== null)
      clearInterval(intervalRef.current);
    
    intervalRef.current = null;
  }

  // 描画時に一度だけ実行
  useEffect(() => {
    apiGetActivityProgressManagement(false)

    intervalRef.current = setInterval(() => {
      apiGetActivityProgressManagement(false)
    }, 1000 * 60); // 60秒間隔で変更
    
    return () => {
      onClearInerval();
    };
  }, [weekly])

  return (
    <>
      <ContentWrap>
        <ContentBody>
        <BoardTitle>{title}</BoardTitle>
        <ComposedChart 
          width={1690}
          height={320}
          data={processList}
          margin={{top: 0, right: 0, left: 0, bottom: 5}}>
            <XAxis xAxisId={1} dataKey="acquisitionAt" tickSize={7}/>
            <YAxis yAxisId={1}  tickCount={9}/>
            <YAxis yAxisId={2}  tickCount={9} orientation="right"/>
            <Tooltip />
            <Bar type="bar" xAxisId={1} yAxisId={1} strokeWidth={2} dataKey="workTime" name="実績工数" fill="#EC7A08" />      
            <Line type="plainline" xAxisId={1} yAxisId={2} strokeWidth={2} dataKey="productivity" name="生産性" stroke="#3F9C34" />      
            <Legend/>
        </ComposedChart >
        </ContentBody>
        <ContentBody>
        <BoardTitle>{'工数・生産性推移（週単位）'}</BoardTitle>
        <ComposedChart 
          width={1690}
          height={320}
          data={beforeProcessList}
          margin={{top: 0, right: 0, left: 0, bottom: 5}}>
            <XAxis xAxisId={1} dataKey="acquisitionAt" tickSize={7}/>
            <YAxis yAxisId={1}  tickCount={9}/>
            <YAxis yAxisId={2}  tickCount={9} orientation="right"/>
            <Tooltip />
            <Bar type="bar" xAxisId={1} yAxisId={1} strokeWidth={2} dataKey="workTime" name="実績工数" fill="#EC7A08" />      
            <Line type="plainline" xAxisId={1} yAxisId={2} strokeWidth={2} dataKey="productivity" name="平均生産性" stroke="#3F9C34" />      
            <Legend/>
        </ComposedChart >
        </ContentBody>
      </ContentWrap>
    </>
  )
}

export default ProgressGraphBoard

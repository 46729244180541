import React from 'react'
import PropTypes from 'prop-types'
import {Legend,ResponsiveContainer,RadarChart} from "recharts"


import {BoardBaseFormRow} from '../../../Wrap/BoardBaseWrap'

const COLORS = ['blue', 'red', 'gray', 'green','orange','purple','cyan','navy','maroon','crimson',];

const GraphLegend= ({ keys }) => {        

  const makePayload =()=>{
      return keys.map((key,index)=>{
          return { value: key, type: 'rect', id: 'ID0'+index,color:COLORS[index%COLORS.length] }
      })
  }

  return (
    <BoardBaseFormRow>
      <div>
        <ResponsiveContainer 
          width={1860}
          height={50}
          margin={{
              top: 0, right: 30, left: 0, bottom: 5,
          }}
        >
        <RadarChart>
          <Legend payload={makePayload()}/>
        </RadarChart>
        </ResponsiveContainer >
      </div>
    </BoardBaseFormRow>
  )
}

GraphLegend.propTypes = {
  salariedList: PropTypes.array,
  title: PropTypes.string,
}

export default GraphLegend

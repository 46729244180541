import React, { useState, useEffect, useRef } from 'react'
import { useSelector,useDispatch } from 'react-redux'
//import { useAlert } from 'react-alert'
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'
//import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import FUNC from '../../Common/FunctionEnum'

import * as LogisticUtility from '../../Common/Utilties/LogisticUtility'
import {BoardBaseFormRow, BoardBaseFormRowScroll} from '../Wrap/BoardBaseWrap'

import Tooltip from '@material-ui/core/Tooltip'

import IconChangeImg from '../../../image/page-type-change-icon.png'
import Search from './Search'
import SideSubMenu from '../SideSubMenu'
import OperationBoard from './OperationBoard'
import ProgressBoard from './ProgressBoard'
import ProgressGraphBoard from './ProgressGraphBoard'
import OperationProcessBoard from './OperationProcessBoard'

import { GetApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
const DefaultUrl = '/api/logisticManagement'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  overflow-y: none;
  overflow-x: none;
`

const RealTimeProgrss = (props) => {
  const { loginUserInfo } = useSelector(state => state);
  const [viewFunction,setViewFunction] = useState(false);
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC);
  const subGrants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC_DASHBOARD);
  
  const dispatch = useDispatch();

  const history = useLocation(); 
  const hisState = history.state

  const intervalRef = useRef(null);
  const searchDateRef = useRef()

  const [quantityPlan,setQuantityPlan] = useState(null);
  //const [totalProgressInfo,seTtotalProgressInfo] = useState({progress:0.0});
  //const [plaEndInfo, setPlanEndInfo] = useState({planEndAt:LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD') + " 00:00:00"});

  const defaltValue = {agreementStaffAvgWage:0.0,aveWage:0.0,costProgressValue:0.0}
  const [monthlyData,setMonthlyData] = useState([defaltValue,defaltValue]);
  const [daylyData,setDaylyData] = useState([defaltValue,defaltValue]);

  const [processList,setProcessList] = useState([]);
  const [processMonthList,setProcessMonthList] = useState([]);

  const [wageList,setWageList] = useState([]);
  const [wageMonthList,setWageMonthList] = useState([]);
  
  const [processDataList,setProcessDataList] = useState(null);
  const [beforeProcessList,setBeforeProcessList] = useState(null);
  
  /*2023/01/29 K.Harada ADD 現場IDを表示するように変更 */
  const workplaceId = history.state&&history.state.workplaceId?history.state.workplaceId:'000006'
  const baseId = history.state&&history.state.baseId?history.state.baseId:2

  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }

  // 機能メニューの閉じるイベント
  const onCloseFunction = (event) => {
    setViewFunction(false);
  }
  useEffect(() => {
    if (grants && subGrants)
      document.title = grants.functionMenuName + '【' + subGrants.functionMenuName + '】 | CoreBoard'
    else if (grants)
      document.title = grants.functionMenuName + ' | CoreBoard'
    else
      document.title = 'CoreBoard'
  }, [grants, subGrants])

  // 平均時給情報の取得
  const apiGetAveWage = async (isLoadSplinner) => {
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true))

    const apiUrl = DefaultUrl + '/getAveWage';
    const param = {
      workplaceId: workplaceId ,
      targetYMD: LogisticUtility.getStringFromDate(new Date(searchDateRef.current), 'YYYY-MM-DD'),
    }

    const result = await GetApi(apiUrl, param);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      setQuantityPlan(null);
    } else {
      //console.log(result.data);
      setDaylyData(result.data[0]);
      setMonthlyData(result.data[1]);
    }
  }
  // 進捗設定情報の取得(円グラフ)
  const apiGetProcessPieGraph = async (isLoadSplinner) => {
    //console.log('call getActivityProgressManagement');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getProcessPieGraph';
   
    const data = {
      workplaceId: workplaceId ,
      targetYMD: LogisticUtility.getStringFromDate(new Date(searchDateRef.current), 'YYYY-MM-DD'),
      type: 1
    }
    const result = await GetApi(apiUrl, data);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      //alert.error('条件の該当データがありません(進捗設定情報の取得)');
    } else {
      //console.log(result.data);
      setProcessList(result.data[0]);
      setProcessMonthList(result.data[1]);
    }
  }
  // 平均時給情報の取得（棒グラフ）
  const apiGetAvgWageGraph = async (isLoadSplinner) => {
    //console.log('call apiGetAvgWageGraph');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getAvgWageGraph';
   
    const data = {
      workplaceId: workplaceId ,
      targetYMD: LogisticUtility.getStringFromDate(new Date(searchDateRef.current), 'YYYY-MM-DD'),
      //targetYMD:'2023-02-23'
    }
    const result = await GetApi(apiUrl, data);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      //alert.error('条件の該当データがありません(平均時給情報の取得)');
    } else {
      {
        let sumAveWage = 0
        let sumAgreementStaffAvgWage = 0
        let count = 0
        result.data[0].map((process)=>{
          sumAgreementStaffAvgWage += process.agreementStaffAvgWage
          sumAveWage += process.aveWage

          if(process.agreementStaffAvgWage!==0){
            count++
          }
        })
        if(count===0) count=1
        result.data[0].map((process)=>{
          process.localAgreementStaffAvgWage = sumAgreementStaffAvgWage/count
          process.localAvgWage = sumAveWage/count
        })
        setWageList(result.data[0]);
      }
      {
        let sumAveWage = 0
        let sumAgreementStaffAvgWage = 0
        let count = 0
        result.data[1].map((process)=>{
          sumAgreementStaffAvgWage += process.agreementStaffAvgWage
          sumAveWage += process.aveWage

          if(process.agreementStaffAvgWage!==0){
            count++
          }
        })
        if(count===0) count=1
        result.data[1].map((process)=>{
          process.localAgreementStaffAvgWage = sumAgreementStaffAvgWage/count
          process.localAvgWage = sumAveWage/count

        })
        setWageMonthList(result.data[1]);
      }

      //console.log(result)
    }
  }
 // 工程情報(下部リスト)の取得
 const apiGetProcessInfo = async () => {
    const apiUrl = DefaultUrl + '/getProcessList';
    const param = {
      //targetYMD: LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD'),
      workplaceId:workplaceId ,
      targetYMD: LogisticUtility.getStringFromDate(new Date(searchDateRef.current), 'YYYY-MM-DD'),
    }
    //console.log(param);
    const result = await GetApi(apiUrl, param);

    //dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('週情報取得失敗');
      setProcessDataList([]);
      setBeforeProcessList([]);
    } else {
      console.log(result);
      setProcessDataList(result.data[1]);
      setBeforeProcessList(result.data[0]);
    }
  }

  // 周期初期のクリア
  const onClearInerval = async () => {
    if (intervalRef.current !== null)
      clearInterval(intervalRef.current);
    
    intervalRef.current = null;
  }


  // 描画時に一度だけ実行
  useEffect(() => {
    apiGetAveWage();
    apiGetProcessPieGraph();
    apiGetAvgWageGraph();
    apiGetProcessInfo();
    intervalRef.current = setInterval(() => {
      apiGetAveWage();
    }, 1000 * 60 * 5) ; // 300秒間隔で変更
    intervalRef.current = setInterval(() => {
      apiGetProcessPieGraph();
    }, 1000 * 60 * 5); // 300秒間隔で変更
    intervalRef.current = setInterval(() => {
      apiGetAvgWageGraph();
    }, 1000 * 60 * 5); // 300秒間隔で変更
    intervalRef.current = setInterval(() => {
      apiGetProcessInfo();
    }, 1000 * 60 * 5); // 300秒間隔で変更
    return () => {
      onClearInerval();
    };
  }, [])

  const [wageType,setWageType]=useState(0)
  const onClickChangeWageType = () => {
    setWageType(wageType?0:1);
  }
  const onDateChange=(date)=>{
    searchDateRef.current = date;

    apiGetAveWage();
    apiGetProcessPieGraph();
    apiGetAvgWageGraph();
    apiGetProcessInfo();
  }

  if (!searchDateRef.current) {
    if(hisState && hisState.searchDate){
      searchDateRef.current = LogisticUtility.getStringFromDate(new Date(hisState.searchDate), 'YYYY-MM-DD')
    }else{
      searchDateRef.current = LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD')
    }   
  }
  return (
    <>
      <Search
        onClickFunction={onClickFunction}
        onCloseFunction={onCloseFunction}
        viewFunction={viewFunction}
        searchDateRef={searchDateRef}
        onChange={(date)=>onDateChange(date)}
        workplaceId={workplaceId}
        baseId={baseId}
      />
      <BoardBaseFormRow>
        <SideSubMenu height={'84vh'} planExisit={true}/>
        <BoardBaseFormRowScroll>
          <ContentWrap>
            {/* <OperationBoard />
            <ProgressBoard wageType={wageType} type={1}/> */}
            <div style={{display:'flex',justifyContent:'flex-end',marginRight:'140px', cursor:'pointer'}}>
            <Tooltip title={'平均時給切替'}>
              <div onClick={()=>onClickChangeWageType()}><img src={IconChangeImg} width="28px" height="28px" alt="表示切替"/></div>
            </Tooltip>
            </div>
            <ProgressBoard 
              wageType={wageType}
              type={0} 
              searchDate={searchDateRef.current} 
              monthlyData={monthlyData}
              daylyData={daylyData}
              processList={processList}
              processMonthList={processMonthList}/>
            <ProgressGraphBoard wageType={wageType} searchDate={searchDateRef.current}
            processList={wageList}
            processMonthList={wageMonthList}
            />
            <OperationProcessBoard 
            searchDate={searchDateRef.current} processList={processDataList} beforeProcessList={beforeProcessList}
            targetYMD={LogisticUtility.getStringFromDate(new Date(searchDateRef.current), 'YYYY/MM/DD')} 
            targetYM={LogisticUtility.getStringFromDate(new Date(searchDateRef.current), 'YYYY年MM月度')} 
            />
          </ContentWrap>
        </BoardBaseFormRowScroll>
      </BoardBaseFormRow>
    </>
  )
}

export default RealTimeProgrss

import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import { Prompt } from 'react-router-dom';

import { GetApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import ErrorHandling from '../../Common/ErrorHandling'
import FUNC from '../../Common/FunctionEnum'
import { createStaffInfo } from '../../Common/Utilties/ObjectUtitlity'

import MasterPanelWrap from '../Wrap/MasterPanelWrap'
import MasterFlexWrap from '../Wrap/MasterFlexWrap'
import MasterListView from '../Molcules/MasterListView'
import Search from './Search'
import StaffDetail from './StaffDetail'

import MasterSelect from '../MasterSelect'

export const DefaultUrl = '/api/masterManagement/staff'

const Staff = (props) => {
    const staffIdRef = useRef()
    const staffNameRef = useRef()
    const [dataList, setDataList] = useState([])
    const [detail, setDetail] = useState(null) // 未選択の状態
    const [selectedId, setSelectedId] = useState(null)
    const [searchOption, setSearchOption] = useState({ staffId: '', staffName: '' })
    const { loginUserInfo } = useSelector(state => state)
    const [pagenation, setPagenation] = useState({})
    const dispatch = useDispatch()
    const alert = useAlert()
    const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.STAFF)
    const [viewFunction,setViewFunction] = useState(false);
    const [updateFlag, setUpdateFlag] = useState(false)
    
    const selectItem = async (id) => {
      if (updateFlag) {
        const is_ok = window.confirm('編集中のデータを破棄しますか');
        if(!is_ok)
        {
          return null
        }
        setUpdateFlag(false)
      }

      dispatch(SetLoadSpinner(true))
      setSelectedId(id)
      const result = await GetApi(`${DefaultUrl}/${id}`)
      dispatch(SetLoadSpinner(false))
      ErrorHandling(result, alert) || setDetail(result.data)
    }

    // 新規登録用初期化処理
    const createNew = (e) => {
      setDetail(createStaffInfo()) // 新規作成時の値
      setSelectedId(null)
    }

    // 検索APIの実行
    const onSearch = async (createId, page, showMsg) => {
      if (updateFlag && showMsg) {
        const is_ok = window.confirm('編集中のデータを破棄しますか');
        if(!is_ok)
        {
          return null
        }
        setUpdateFlag(false)
      }

      dispatch(SetLoadSpinner(true))
      setSearchOption(
        {
          staffId: grants?(grants.readGrantDivision === 3 ? loginUserInfo.staffId : staffIdRef.current.value):'',
          staffName: (staffNameRef.current)?staffNameRef.current.value:'',
        }
      )
      const data = {
        staffId: grants?(grants.readGrantDivision === 3 ? loginUserInfo.staffId : staffIdRef.current.value):'',
        staffName: (staffNameRef.current)?staffNameRef.current.value:'',
        page: page
      }
      const result = await GetApi(DefaultUrl, data)
      dispatch(SetLoadSpinner(false))
      if (result.errorDetail) {
        ErrorHandling(result, alert)
        return null
      } else {
        const resultList = result.data.responseList.map(item => {
          return {
            index: item.staffId,
            id: item.staffId,
            name: item.staffName,
            delFlag: item.delFlag,
            createdAt: item.createdAt,
            updatedAt: item.updatedAt,
          }
        })
        setDataList(resultList)
        setPagenation(result.data)
        const isSelected = resultList.find(item => item.index === (createId || selectedId))
        if (!isSelected) {
          setSelectedId(null)
          setDetail(null) // 未選択の状態
        }
      }
    }
    
    const onKeyDown = (event) => {
      if (event.keyCode === 13) {
        onSearch(null, 1)
      }
    }

    const pageSelectFunc = (pageId) => {
      onSearch(null, pageId)
    }

    useEffect(() => {
      if (grants)
        document.title = grants.functionMenuName + ' | Core Time Cloud'
      else
        document.title = 'Core Time Cloud'
    }, [grants])

    //画面表示の時
    useEffect(()=>{
      onSearch(null,1)
    },[])
    //ページ遷移前の更新破棄の確認
    //更新キャンセル処理
    useEffect(() => {
      // イベントの設定
      window.addEventListener('beforeunload', onUnload);
    
      return () => {
        // イベントの設定解除
        window.removeEventListener('beforeunload', onUnload);
      }
    })
    const onUnload = (e) => {
      if(updateFlag){
        e.preventDefault();
        e.returnValue = '';
      }
    }
    // 遷移可能かどうか判定
    const handleBlockedNavigation = () => {
      if (updateFlag) {
        const is_ok = window.confirm('編集中のデータを破棄しますか');
        return is_ok;
      }
      return true;
    };
      // 機能メニューボタンのクリックイベント
      const onClickFunction = (event) => {
        setViewFunction(!viewFunction);
      }
    
      // 機能メニューの閉じるイベント
      const onCloseFunction = (event) => {
        setViewFunction(false);
      }
    if (!grants) return null
    return (
        <MasterPanelWrap id='StaffWrap'>
        <Prompt when={true} message={handleBlockedNavigation} />
        <Search
          title= {grants.functionMenuName}
          showButton
          onClick={() => onSearch(null, 1, true)}
          onKeyDown={(e) => onKeyDown(e)}
          staffIdRef={staffIdRef}
          staffNameRef={staffNameRef}
          grants={grants}
          firstSearch={true}
          onClickFunction={onClickFunction}
          onCloseFunction={onCloseFunction}
          viewFunction={viewFunction}
        >
        </Search>
        <MasterFlexWrap>
            {/* <MasterSelect/> */}
            <MasterListView
              dataList={dataList}
              setSelectedId={setSelectedId}
              selectFunction={(id) => selectItem(id)}
              createFunction={() => createNew()}
              canWrite={grants.writeGrantDivision === 2 || grants.writeGrantDivision === 1}
              pageSelectFunc={pageSelectFunc}
              pagenation={pagenation}
              isIdView
            />
            <StaffDetail
              onSearch={onSearch}
              setSelectedId={setSelectedId}
              setDetail={setDetail}
              staffDetail={detail}
              grants={grants}
              setUpdateFlag={setUpdateFlag}
            >
            </StaffDetail>
        </MasterFlexWrap>
        </MasterPanelWrap>
    )
}

export default Staff

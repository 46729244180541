import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import styled from 'styled-components'
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import jaLocale from '@fullcalendar/core/locales/ja';
import interactionPlugin from '@fullcalendar/interaction';

///import moment from 'moment'
//import { useAlert } from 'react-alert'
//import { useDispatch } from 'react-redux'

//import { PostApi, PutApi } from '../../../Common/ApiAxios'
//import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'
//import Calendar from '../../../Common/Atoms/Calendar'
//import Input from '../../../Common/Atoms/Input'
//import NormalButton from '../../../Common/Atoms/NormalButton'
//import Select from '../../../Common/Atoms/SelectAddValue'
import * as HealthCareUtilty from '../../../Common/Utilties/HealthCareUtilty'

import NoticeModal from '../NoticeModal/NoticeModal'

import CloseIcon from '../../../../image/close_icon.png'

//import MediaAccidentHeader from './MediaAccidentHeader'
//import MediaAccident from './MediaAccident'
//import * as MapWrap from '../../Wrap/PositioningMapWrap'
//import { DefaultUrl } from '../../PositioningBoard'
//import { conforms } from 'lodash'

/* 横並 */
const Flex = styled.div`
  display: flex; /* 横並び */
  flex-flow: row nowrap;
`

/* 縦並 */
const Wrap = styled.div`
  display:flex; /* 縦並び */
  flex-flow: column;
`

const ColumnFlex = styled.div`
  display: flex; /* 横並び */
  flex-flow: row nowrap;
  text-align: left;
  align-items: center;
  marign: 2rem;
  border-bottom: solid 1px #0000ff;
`

const WeekName = styled.div`
  width: 10rem;
  margin: 1rem;
  text-align: center;
  align-items: center;
`

const DateRow = styled.div`
  display:flex; /* 縦並び */
  flex-flow: column;
  width: 10rem;
  height: 80px;
  margin: 1rem;

  text-align: center;
  align-items: center;

  cursor: pointer;
`

const LabelRow = styled.div`
  display: flex; /* 横並び */
  flex-flow: row nowrap;
  width: 100%;
  height: 20px;
  background-color: #ff0000;

  text-align: left;
  align-items: center;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  cursor: pointer;
`

const NoticeCalendarModal = (props) => {
  const {
    //grants,
    targetYMD,
    noticeList,
    modalIsOpen,
    setIsOpen,
    onChangeNotice,
  } = props
  //const dispatch = useDispatch();
  //const alert = useAlert();
  const [dateList0, setDateList0] = useState([]);
  const [dateList1, setDateList1] = useState([]);
  const [dateList2, setDateList2] = useState([]);
  const [dateList3, setDateList3] = useState([]);
  const [dateList4, setDateList4] = useState([]);
  const [dateList5, setDateList5] = useState([]);
  const [editMode, setEditMode] = useState(0);
  const [noticeData, setNoticeData] = useState(null);
  const [openDialog, _setOpenDialog] = useState(false);
  const [items, setItems] = useState([]);
  const [srcList, setSrcList] = useState([]);

  function setOpenDialog(value) {
    if (!value)
    setNoticeData(null);

    _setOpenDialog(value);
  }

  //const contentnputRef = useRef();
  //const typeRef = useRef();

  // モーダル
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      //opacity: 0.8,
      padding: '10px',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    },
    overlay: {
      //background: 'rgba(0, 0, 0, 0.7)',
      zIndex: 10
    }
  }

  // 該当日付のお知らせリストを取得する
  function getNoticeList(srcList, date) {
    return srcList.filter(notice => HealthCareUtilty.getStringFromDate(notice.noticeAt, 'YYYY-MM-DD') === HealthCareUtilty.getStringFromDate(date, 'YYYY-MM-DD'));
  }

  // お知らせ情報の登録イベント
  const onClickCreate = (date) => {
    //console.log('onClickCreate');
    setNoticeData({
      noticeId: '',
      noticeType: 0,
      text: '',
      noticeAt: HealthCareUtilty.getStringFromDate(date, 'YYYY-MM-DD'),
    });
    setEditMode(0);
    setOpenDialog(true);
  }

  const onClickCreate2 = (date) => {
    //console.log('onClickCreate2');
    setNoticeData({
      noticeId: '',
      noticeType: 0,
      text: '',
      noticeAt: date,
    });
    setEditMode(0);
    setOpenDialog(true);
  }

  // お知らせ編集クリックイベント
  const onClickEdit = (event, notice) => {
    //console.log('onClickEdit');
    var id = notice._def.publicId;

    var item = srcList.find((value) => { return Number(value.noticeId) == id;});
    setNoticeData(item);
    setEditMode(1);
    setOpenDialog(true);

    if (event)
      event.stopPropagation();
  }

  //ダイアログ開いた時の処理
  function afterOpenModal () {
    //contentnputRef.current.value = noticeData.text;
    //typeRef.current.value = noticeData.noticeType;
    //console.log('call afterOpenModal');
    
    var index = 0;
    var count = 1;
    var list = [];
    while (count <= 31) {
        var target = new Date(2023, 3-1, count);

        list.push(target);

        if (target.getDay() === 6) {
            switch(index) {
                case 0:
                    while (list.length < 7) {
                        list.unshift(null);
                    }
    
                    //console.log('call setDateList0');
                    setDateList0(list);
                    break;
                case 1:
                    //console.log('call setDateList1');
                    setDateList1(list);
                    break;
                case 2:
                    //console.log('call setDateList2');
                    setDateList2(list);
                    break;
                case 3:
                    //console.log('call setDateList3');
                    setDateList3(list);
                    break;
                case 4:
                    //console.log('call setDateList4');
                    while (list.length < 7) {
                        list.push(null);
                    }
                    setDateList4(list);
                    break;
                case 5:
                    //console.log('call setDateList5');
                    while (list.length < 7) {
                        list.push(null);
                    }
                    setDateList5(list);
                    break;
                default:
                  break;
                }
            index++;
            list = [];
        }

        count++;
    }

    switch(index) {
    case 0:
        setDateList0(list);
        break;
    case 1:
        setDateList1(list);
        break;
    case 2:
        setDateList2(list);
        break;
    case 3:
        setDateList3(list);
        break;
    case 4:
        while (list.length < 7) {
            list.push(null);
        }
        setDateList4(list);
        break;
    case 5:
        while (list.length > 0 && list.length < 7) {
            list.push(null);
        }
        setDateList5(list);
        break;
    default:
      break;
    }

    setItems(noticeList.map(item => {
      //console.log(item);
      return {
        id: item.noticeId,
        title: item.text,
        start: new Date(item.noticeAt),
        end: new Date(item.noticeAt),
      }
    }));

    setSrcList(noticeList);
  }

  function closeModal () {
      setIsOpen(false);
  }

  useEffect(() => {
  }, [noticeList])

  //if (!grants) return null;
  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel='Create Zone Modal'
      ariaHideApp={false}
    >
      <div style={{ width: '80rem', height: '760px'}}>
        <Wrap>
          <Flex>
            <div style={{ height:'20px', marginLeft:'2rem', marginRight:'auto', marginTop:'10px'}}>{HealthCareUtilty.getStringFromDate(new Date(targetYMD), 'YYYY年MM月')}</div>
            <div style={{ height:'20px', marginLeft:'auto', marginRight:'10px', marginTop:'10px', cursor: 'pointer'}}>
              <img alt='閉じる' className='close' src={CloseIcon} title={'ダイアログを閉じる'} width="12px" onClick={closeModal} />
            </div>
          </Flex>
          <Wrap>
            <div>
              {/*
              <ColumnFlex>
                <WeekName>日</WeekName>
                <WeekName>月</WeekName>
                <WeekName>火</WeekName>
                <WeekName>水</WeekName>
                <WeekName>木</WeekName>
                <WeekName>金</WeekName>
                <WeekName>土</WeekName>
              </ColumnFlex>
              <ColumnFlex>
                {dateList0 && dateList0.map(date => (
                  <DateRow onClick={ () => onClickCreate(date) }>
                    {date && date.getDate()}
                    {getNoticeList(noticeList, date).map(item2 => (
                      <LabelRow onClick={ (event) => onClickEdit(event, item2) }>{item2.text}</LabelRow>
                    ))}
                  </DateRow>
                ))}
              </ColumnFlex>
              <ColumnFlex>
                {dateList1 && dateList1.map(date => (
                  <DateRow onClick={ () => onClickCreate(date) }>
                    {date && date.getDate()}
                    {getNoticeList(noticeList, date).map(item2 => (
                      <LabelRow onClick={ (event) => onClickEdit(event, item2) }>{item2.text}</LabelRow>
                    ))}
                  </DateRow>
                ))}
              </ColumnFlex>
              <ColumnFlex>
                {dateList2 && dateList2.map(date => (
                  <DateRow onClick={ () => onClickCreate(date) }>
                    {date && date.getDate()}
                    {getNoticeList(noticeList, date).map(item2 => (
                      <LabelRow onClick={ (event) => onClickEdit(event, item2) }>{item2.text}</LabelRow>
                    ))}
                  </DateRow>
                ))}
              </ColumnFlex>
              <ColumnFlex>
                {dateList3 && dateList3.map(date => (
                  <DateRow onClick={ () => onClickCreate(date) }>
                    {date && date.getDate()}
                    {getNoticeList(noticeList, date).map(item2 => (
                      <LabelRow onClick={ (event) => onClickEdit(event, item2) }>{item2.text}</LabelRow>
                    ))}
                  </DateRow>
                ))}
              </ColumnFlex>
              <ColumnFlex>
                {dateList4 && dateList4.map(date => (
                  <DateRow onClick={ () => onClickCreate(date) }>
                    {date && date.getDate()}
                    {getNoticeList(noticeList, date).map(item2 => (
                      <LabelRow onClick={ (event) => onClickEdit(event, item2) }>{item2.text}</LabelRow>
                    ))}
                  </DateRow>
                ))}
              </ColumnFlex>
              {dateList5.length > 0 &&
                <ColumnFlex>
                {dateList5.map(date => (
                  <DateRow onClick={ () => onClickCreate(date) }>
                    {date && date.getDate()}
                    {getNoticeList(noticeList, date).map(item2 => (
                      <LabelRow onClick={ (event) => onClickEdit(event, item2) }>{item2.text}</LabelRow>
                    ))}
                  </DateRow>
                ))}
                </ColumnFlex>
              }
               */}
              <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                contentHeight={650}
                locales={[jaLocale]}
                locale='ja'
                dateClick={function(info) {
                  //alert('Clicked on: ' + info.dateStr);
                  //alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
                  //alert('Current view: ' + info.view.type);
                  // change the day's background color just for fun
                  //info.dayEl.style.backgroundColor = 'red';

                  onClickCreate2(info.dateStr);
                }}
                eventClick={function(info) {
                  //alert('Event: ' + info.event.title);
                  //alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
                  //alert('View: ' + info.view.type);
              
                  // change the border color just for fun
                  //info.el.style.borderColor = 'red';
                  onClickEdit(null, info.event);
                }}
                events={items}
              />
            </div>
          </Wrap>
        </Wrap>
      </div>
      {noticeData &&
      <NoticeModal
        noticeData={noticeData}
        type={editMode}
        modalIsOpen={openDialog}
        setIsOpen={setOpenDialog}
        onChangeNotice={onChangeNotice}
      />
      }
    </Modal>
  )
}

NoticeCalendarModal.propTypes = {
  //grants: PropTypes.object, // 権限関連
  targetYMD: PropTypes.string,
  noticeList: PropTypes.array,
  modalIsOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  onChangeNotice: PropTypes.func,
}

export default NoticeCalendarModal

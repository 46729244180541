import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import styled from 'styled-components'

import { GetApi } from '../../../Common/ApiAxios'
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'
import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'

import {XAxis, YAxis, Tooltip, Legend, Line,PieChart,Pie,Cell, Bar,ComposedChart,Area } from "recharts"

const DefaultUrl = '/api/logisticManagement'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 85vh;
  text-align: center;
  vertical-align: middle;
`

const ContentBody = styled.div`
  display: grid;
  grid-gap: 30px 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row: 1 / 2;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
`

const BodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  overflow: hidden;
  vertical-align: middle;
  width: 400px;
  height: 100%;
`

const GraphContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
`

const ProcessRow = styled.div`
  display: inline-block;
  color: #535353;
  margin-top: 2px;
  border-bottom: 1px solid #D9D5C9;
  width: 210px;
`

const ProcessName = styled.span`
  display: inline-block;
  font-weight: bold;
  color: #000;
  text-align: right;
  font-size: 1.2rem;
  width: 120px;
`

const ProcessSubItem = styled.span`
  display: inline-block;
  color: #535353;
  text-align: left;
`

const ProcessTitle = styled.div`
  font-weight: bold;
  color: #000;
  text-align: center;
  font-size: 1.2rem;
`

const Process = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
 
  background-color: #fff;
  text-align:center;
  vertical-align:middle;
  margin-top: 5px;
  margin-bottom: 5px;
`
const ProgressBodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  overflow: hidden;
  vertical-align: middle;
  width: 300px;
  height: 90%;
  box-shadow: 2px 2px 6px rgba(0,0,0,.4);
  margin-left: 60px;
`

const cx = 200;
const cy = 90;//140;2024/02/21 MOD

const innerRadius = 70;
const outerRadius = 90;//130;2024/02/21 MOD

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, value, fill,index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos((-midAngle) * RADIAN);
  const y = cy + radius * Math.sin((-midAngle) * RADIAN);
  if(fill == 'transparent' || value == 0){
    return (<></>);  
  }
  return (
    <text x={x} y={y} dx={fill=='firebrick'?24:-24} fill={"black"} fontSize={'16px'} fontWeight={'bold'} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${Number((value).toFixed(0)).toLocaleString()}`}
    </text>
  );
};

// 当月内訳のLegendのカスタム
function renderLegendText(value,pyload) {
  if(pyload.color == 'transparent'){
    return (<></>);  
  }
  return <>{value}</>;
};

const ProgressGraphBoard = (props) => {
  //const { loginUserInfo } = useSelector(state => state);
  const {
    data,percent,progressList,progressNum,searchDate
  } = props
  const dispatch = useDispatch();
  const alert = useAlert();
  const [processList,setProcessList] = useState([]);
  const intervalRef = useRef(null);
  const [prgressGraph,setPrgressGraph] = useState();

  //x軸のデータ  
  const ticks = ['07:00','08:00','09:00','10:00','11:00','12:00',
                 '13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00']

  // 周期初期のクリア
  const onClearInerval = async () => {
    if (intervalRef.current !== null)
      clearInterval(intervalRef.current);
    
    intervalRef.current = null;
  }

  // 描画時に一度だけ実行
  useEffect(() => {
    // apiGetActivityProgressManagement()

    // intervalRef.current = setInterval(() => {
    //   apiGetActivityProgressManagement()
    // }, 1000 * 60); // 60秒間隔で変更
    
    // return () => {
    //   onClearInerval();
    // };
  }, [])

  // 描画時に一度だけ実行
  useEffect(() => {
    if(!data) return;

    setPrgressGraph(data.map((dataRow)=>{
      return dataRow
    }))
  }, [percent])

  /*2023/12/12 K.Harada ADD 残工数のボディを追加 start*/
  useEffect(() => {
    if(!progressNum) return;

    makeRemaining(progressNum,fullScreen)/*2023/12/12 K.Harada ADD 残工数のボディを作成 start*/
  }, [progressNum])

  const [fullScreen,setFullScreen] = useState(window.innerHeight>1000);

  function handleResize(event){
    console.log(window.innerHeight)
    setFullScreen(window.innerHeight>1000)
    makeRemaining(progressNum,window.innerHeight>1000)
  }
  //スクリーン変更イベント
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [progressNum])

  const [remainingWorkTimeList,setRemainingWorkTimeList] = useState([]);
  /*2024/02/21 K.Harada ADD 表示する残工数の数のフラグ（fullScreen）追加 start*/
  const makeRemaining = async (list,fullScreen) => {
    if(!list) return
    var remainingList = []
    var date = new Date();
    var hour = date.getHours();
    
    //if(date > new Date(searchDate)) return
    var nowDate = date.getFullYear() + '-' + ('00'+Number(date.getMonth()+1)).slice(-2)+ '-' + ('00'+date.getDate()).slice(-2)
    if(nowDate != searchDate) {
      setRemainingWorkTimeList(remainingList)
      return
    }

    list.map((process)=>{
      if(process.timeZone.slice(-2) ==='00' && process.timeZone.slice(-5,-3) >= hour){
        remainingList.push(
          <ProcessRow>
            <ProcessSubItem>{process.timeZone}</ProcessSubItem>
            <ProcessName>{process.remainingWorkTime.toFixed(2)}</ProcessName>
            <ProcessSubItem>h</ProcessSubItem>
          </ProcessRow>
        )
      }
    })
    setRemainingWorkTimeList(remainingList.slice(0,fullScreen?12:6))
  }
  /*2023/12/12 K.Harada ADD 残工数のボディを追加 end*/


  return (
    <>

      <ContentWrap>
        <ContentBody>
        {progressList && 
        <GraphContent>
          <ProcessTitle>時間帯別推移</ProcessTitle>
          <ComposedChart 
              width={1350}
              height={210}
              data={progressList}
              margin={{top: 0, right: 0, left: 0, bottom: 5}}>
                <XAxis xAxisId={1} dataKey="timeZone" ticks={ticks}/>
                <YAxis yAxisId={1} domain={[0, 1000]} allowDataOverflow={true} tickCount={9} label={{ value: "物量",fontSize:'24px', angle: -90, dx: -20 }} />
                <Tooltip />
                <Bar type="bar" xAxisId={1} yAxisId={1} strokeWidth={2} dataKey="progressReject" name="リジェクト数" fill="#EC7A08" />      
                <Bar type="bar" xAxisId={1} yAxisId={1} strokeWidth={2} dataKey="progressOverflow" name="オーバーフロー数" fill="#CC0001" />  
                <Line type="plainline" xAxisId={1} yAxisId={1} strokeWidth={2} dataKey="planQuantity" name="予定物量" stroke="#3F9C34" />
                <Line type="plainline" xAxisId={1} yAxisId={1} strokeWidth={2} dataKey="beforeProgressQuantity" name="前同曜日実績物量" stroke="#ee82ee" />      
                <Line type="plainline" xAxisId={1} yAxisId={1} strokeWidth={2} dataKey="progressQuantity" name="実績物量" stroke="#295D8C" />  
                <Legend/>
          </ComposedChart >
        </GraphContent>
        }
        {prgressGraph&&
        <BodyContent>
          <ProcessTitle>シュート投入進捗</ProcessTitle>
          <PieChart width={400} height={210}>
              <Pie
                dataKey="value"
                data={prgressGraph}
                cx={cx}
                cy={cy}
                startAngle={90}
                endAngle={450}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                stroke="none"
                labelLine={false}
                label={renderCustomizedLabel}
                paddingAngle={5}
                >
                {prgressGraph.map((entry, index) => (
                    <Cell key={`cell2-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Legend verticalAlign='bottom' align="center" formatter={renderLegendText}/>
              <text x={cx} y={cy} dx={8} dy={16} fontSize={'36px'} textAnchor="middle" fill={'black'} fontWeight={'bold'}>{(percent && !isNaN(percent))?percent+'%':'0.0%'}</text>
          </PieChart>
        </BodyContent>
        }
        </ContentBody>
        {/*時間帯別人数進捗、残工数追加*/}
        <div style={{marginTop:'5px'}}></div>
        <ContentBody>
        <GraphContent>
          <ProcessTitle>時間帯別就業人数</ProcessTitle>
          <ComposedChart 
            width={1350}
            height={fullScreen?320:160}
            data={progressNum}
            margin={{top: 0, right: 0, left: 0, bottom: 5}}>
              <XAxis xAxisId={1} dataKey="timeZone"  ticks={ticks}/>
              <YAxis yAxisId={1} domain={[0, 80]} tickCount={16} label={{ value: "人数",fontSize:'24px', angle: -90, dx: -20 }}/>
              <Tooltip />
              <Line xAxisId={1} yAxisId={1} strokeWidth={2} type="plainline" name="予定就業人数" dataKey="planStaffCnt" stroke="#3F9C34" />
              <Area xAxisId={1} yAxisId={1} type="step" name="実績就業人数" dataKey="staffCnt" stroke="#CC0001" fill="#CC0001" />
              <Legend/>
          </ComposedChart >
        </GraphContent>
        <ProgressBodyContent>
          <Process>
            <div>
              <ProcessTitle>残工数</ProcessTitle>
              {remainingWorkTimeList}
            </div>
          </Process>
        </ProgressBodyContent>
        </ContentBody>
      </ContentWrap>
    </>
  )
}

export default ProgressGraphBoard

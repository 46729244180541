import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const MediaWrap = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  background-color: #649CDF;
  color: #fff;
  font-size: 1.0vw;
  text-align: center;
  align-items: center;
  width: 1764px;
  margin-left: 4px;
`
const MediaItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 45px;
  border-right: ${props => props.borderNone ? '': '1px solid #000'};
`

const TextWrap = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
` 

const MaterialHandlingItem = (props) => {
  const {
    searchDate,
    data,type,dialog
  } = props

  // 初期値
  useEffect(() => {
  })

  //if (!data) return null;
  //console.log(data);
  return (
    <MediaWrap>
      <MediaItem>
        <TextWrap style={{width:'80px', textAlign:'center'}}>
          <div>{'No'}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'100px', textAlign:'center'}}>
          <div>{'運行ID'}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'250px', textAlign:'center'}}>
          <div>{'運送会社'}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'250px', textAlign:'center'}}>
          <div>{'ドライバー名'}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'80px', textAlign:'center'}}>
          <div>{'車格'}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'80px', textAlign:'center'}}>
          <div>{'温度帯'}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'80px', textAlign:'center'}}>
          <div>{'作業'}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'180px', textAlign:'center'}}>
          <div>{'作業日時'}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'250px', textAlign:'center'}}>
          <div>{'作業場所'}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'80px', textAlign:'center'}}>
          <div>{'カゴ車'}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'80px', textAlign:'center'}}>
          <div>{'カートラ'}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'80px', textAlign:'center'}}>
          <div>{'保冷'}</div>
          <div>{'カーゴ車'}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'80px', textAlign:'center'}}>
          <div>{'ドーリー'}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem borderNone={true}>
        <TextWrap style={{width:'80px', textAlign:'center'}}>
          <div>{'庫内温度'}</div>
        </TextWrap>
      </MediaItem>
    </MediaWrap>
  )
}

MaterialHandlingItem.propTypes = {
}

export default MaterialHandlingItem

import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'


import { GetApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import * as LogisticUtility from '../../Common/Utilties/LogisticUtility'
//import ErrorHandling from '../../Common/ErrorHandling'

import MediaDataItemEmissions from './Atoms/MediaDataItemEmissions'
import MediaHeaderItemEmissions from './Atoms/MediaHeaderItemEmissions'
//import MediaSumHeaderItemEmissions from './Atoms/MediaSumHeaderItemEmissions'
import MediaHeaderCargoEmissions from './Atoms/MediaHeaderCargoEmissions'
import MediaDataCargoEmissions from './Atoms/MediaDataCargoEmissions'

const DefaultUrl = '/api/logisticManagement'

const BodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  vertical-align: middle;
  height: 520px;
`
const ContentWrap = styled.div`
  display: flex; /* 横並び */
  flex-direction: column;
  vertical-align: middle;
  width: 100%;
  height: 520px;
  padding:0.5rem;
`
export const ListUl = styled.ul`
  width: ${props => props.width ? props.width : '100%'};
  height: ${props => props.height ? props.height : '400px'};
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    border:none;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(112, 112, 112);
    border-radius: 10px;
  }
`

export const ListLi = styled.li`
  width: ${props => props.width ? props.width : 'calc(100%-18px)'};
  display: flex; /* 横並び */
  flex-direction: row;
  text-indent: 10px;
  background: ${props => props.selected ? '#048b63' : '#fff'};
  color: ${props => props.selected ? '#fff' : '#333'};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  .listId{
    margin-right:1vw;
  }
  :nth-child(even){
    background: ${props => props.selected ? '#048b63' : '#eee'};
    color: ${props => props.selected ? '#fff' : '#333'};
  }
  :hover {
    background: #ccc;
    color: #333;
  }
`
export const ListShipping = styled.li`
  width: ${props => props.width ? props.width : 'calc(100%-18px)'};
  display: flex; /* 横並び */
  flex-direction: row;
  text-indent: 10px;
  background: ${props => props.selected ? '#D9A1A1' : '#f2b3b3'};
  color: ${props => props.selected ? '#fff' : '#333'}};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  .listId{
    margin-right:1vw;
  }
  :nth-child(even){
    background: ${props => props.selected ? '#f2b3b3' : '#D9A1A1'};
    color: ${props => props.selected ?'#fff' : '#333'}};
  }
  :hover {
    background: #ccc;
    color: #333;
  }
`
export const ListCargo = styled.li`
  width: ${props => props.width ? props.width : 'calc(100%-18px)'};
  display: flex; /* 横並び */
  flex-direction: row;
  text-indent: 10px;
  background: ${props => props.selected ? '#afeeee' : '#97cccc'};
  color: ${props => props.selected ? '#fff' : '#333'}};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  .listId{
    margin-right:1vw;
  }
  
  :nth-child(even){
    background: ${props => props.selected ? '#afeeee' : '#97cccc'};
    color: ${props => props.selected ?'#fff' : '#333'}};
  }
  :hover {
    background: #ccc;
    color: #333;
  }
`
const ItemPerformance = (props) => {
  const {
    searchDate,progressList,onClick,onClickCargo
  } = props
  // 描画時に一度だけ実行
  useEffect(() => {
  }, [progressList]);
  return (
    <ContentWrap>
      <BodyContent>
            <div style={{width:'1810px', marginLeft:'20px', marginRight:'20px', textAlign:'left'}}>
            <MediaHeaderItemEmissions />
            {/* <MediaSumHeaderItemEmissions progressList={progressList}/> */}
            <ListUl width={'100%'} height={'478px'}>
            {progressList.map((item,index) => (
              <>
              <ListLi key={item.shippingCode + '_' + index} style={{cursor: 'default'}}>
                <MediaDataItemEmissions data={item} onClick={()=>onClick(index)} type={0}/>
              </ListLi>
              {/* {item.display &&
                <ListLi key={item.shippingCode + '_' + index + '_header'} style={{cursor: 'default',marginLeft:'54px'}}>
                  <MediaHeaderCargoEmissions />
                </ListLi>   
              } */}
              {/* {item.display && 
                  item.vasCargoItemInfoList.map(info=>(
                      <ListLi key={info.itemCd + '_' + info.cargoId} style={{cursor: 'default'}}>
                        <MediaDataItemEmissions data={item} cargoData={info} onClick={()=>onClick(index)}/>
                      </ListLi>
              ))} */}
              {item.display && 
                  item.vasCargoSumInfoList.map((value,i)=>(
                    <>
                      <ListLi key={value.itemCd + '_' + value.cargoId} style={{cursor: 'default'}}>
                        <MediaDataItemEmissions data={item} cargoData={value} onClick={()=>onClickCargo(index,i)} type={1}/>
                      </ListLi>
                      {value.display && 
                        value.vasCargoItemInfoList.map(info=>(
                          <ListLi key={info.itemCd + '_' + info.cargoId} style={{cursor: 'default'}}>
                            <MediaDataItemEmissions data={item} cargoData={info} onClick={()=>onClick(index)} type={2}/>
                          </ListLi>
                      ))}
                    </>
              ))}

              </>
            ))}
            </ListUl>
            </div>
      </BodyContent>
    </ContentWrap>
  )
}
ItemPerformance.propTypes = {
  progressList: PropTypes.array, //種まき進捗データリスト
  searchDate: PropTypes.string, // 検索日時
}


export default ItemPerformance

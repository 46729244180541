import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'
//import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
//import { useSyncExternalStore } from 'react'

import FUNC from '../../Common/FunctionEnum'
import { GetApi, PostApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import * as HealthCareUtilty from '../../Common/Utilties/HealthCareUtilty'

import BoardBaseWrap, {BoardBaseFormRow, ListUl, ListLi} from '../Wrap/BoardBaseWrap'
import Search from './Search'
import SideSubMenu from '../SideSubMenu'
import CardTotal from './Atoms/CardTotal'
import MediaStaffHeaderHealthCare from './Atoms/MediaStaffHeaderHealthCare'
import MediaStaffDataHealthCare from './Atoms/MediaStaffDataHealthCare'

const DefaultUrl = '/api/truckManagement'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 85vh;
  text-align: center;
  vertical-align: middle;
`

const HeadWrap = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  text-align: center;
  vertical-align: middle;
`

const DateItem = styled.span`
  width: 230px;
  margin-left: 20px;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 38px;
  font-weight: bold;
`

const PanelItem = styled.span`
  width: 300px;
  margin-left: 20px;
`

const HealthCare = (props) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { loginUserInfo } = useSelector(state => state);
  const [viewFunction,setViewFunction] = useState(false);
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.TRUCK);
  const subGrants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.TRUCK_HEALTHCARE);
  const [dateInfo, setDateInfo] = useState(null);
  const [searchDate, setSearchDate] = useState(HealthCareUtilty.getStringFromDate(new Date(), 'YYYY-MM-DD')); // 検索欄の日付
  const [date, setDate] = useState(null); // APIで取得した日付情報
  const [healthCareDailyList, setHealthCareDailyList] = useState([]);
  const [workStatus, setWorkStatus] = useState({ planNum:0, resultNum:0 }); // 稼働状況の集計
  const [workTimeStatus, setWorkTimeStatus] = useState({ avgWorkTime:0 }); // 稼働時間の集計
  const [sleepStatus, setSleepStatus] = useState({ avgSleepTime:0 }); // 睡眠状況の集計
  const [alertStatus, setAlertStatus] = useState({ alertNum:0 }); // アラートの集計
  const [accidentStatus, setAccidentStatus] = useState({ accidentNum:0 }); // 事故の集計
  const intervalRef = useRef(null);
  
  // Fitbit では一般的な数式（220 - 年齢）を用いて最大心拍数を推計しています。
  // 脂肪燃焼ゾーン（最大心拍数の 50 ～ 69%）は、軽め～中程度の運動レベルで、エクササイズの初心者に最適です。 合計消費カロリーは低いものの、脂肪から消費されるカロリーの割合が高めになるために脂肪燃焼ゾーンと呼ばれています。
  // 心拍数欄：caloriesOut消費カロリー Fat Burn:脂肪燃焼ゾーン Cardio:有酸素運動ゾーン

  // 日付情報の取得
  const apiGetDate = async () => {
    console.log('call apiGetDate');
    dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getDate';
    
    const data = {
      targetYMD: searchDate,
    }
    const result = await GetApi(apiUrl, data);

    dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      alert.error('条件の該当データがありません(日付情報の取得)');
    } else {
      //console.log(result.data);
      setDateInfo(result.data);
      setDate(new Date(result.data.date));
    }
  }

  // 日付の勤務情報の取得
  const apiGetWorkDaily = async () => {
    console.log('call getWorkDaily');
    dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getWorkDaily';
    
    const data = {
      targetYMD: dateInfo.date,
    }
    const result = await GetApi(apiUrl, data);

    dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      //alert.error('条件の該当データがありません(日付の勤務情報を取得)');
      setWorkStatus({ planNum:0, resultNum:0 });
    } else {
      //console.log(result.data);
      var planNum = 0;
      var resultNum = 0;

      for (const item of result.data) {
        if (item.planWorkTime > 0)
          planNum++;

        if (item.resultWorkTime > 0)
          resultNum++;
      };

      setWorkStatus({ planNum:planNum, resultNum:resultNum });
    }
  }

  // 日付のヘルス情報の取得
  const apiGetHealthCareDaily = async () => {
    console.log('call apiGetHealthCareDaily');
    dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getHealthCareDaily';
    
    const data = {
      targetYMD: dateInfo.date,
    } 
    const result = await GetApi(apiUrl, data);

    dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      //alert.error('条件の該当データがありません(日付の健康情報を取得)');
      setHealthCareDailyList([]);
    } else {
      //console.log(result.data);
      setHealthCareDailyList(result.data.map(item => {
        return {
          staffId: item.staffId,
          staffName: item.staffName,
          targetYMD: item.targetDt,
          targetDay: HealthCareUtilty.getStringFromDate(new Date(item.targetDt), 'MM-DD'),
          week: item.week,
          publicHoliday: item.publicHoliday,
          steps: item.steps,
          distance: item.distance,
          calorie: item.calorie,
          sleepTime: item.sleepTime,
          sleepScore: item.sleepScore,
          heartRate: item.heartRate,
          bodyTemperature: item.bodyTemperature,
          fatigue: item.fatigue,
          alcoholCheck: item.alcoholCheck,
          totalPlanWorkTime: item.totalPlanWorkTime,
          totalPlanRestTime: item.totalPlanRestTime,
          totalPlanOverTime: item.totalPlanOverTime,
          totalPlanWorkStaffNum: item.totalPlanWorkStaffNum,
          totalPlanWorkDtNum: item.totalPlanWorkDtNum,
          totalResultWorkTime: item.totalResultWorkTime,
          totalResultRestTime: item.totalResultRestTime,
          totalResultOverTime: item.totalResultOverTime,
          totalResultWorkStaffNum: item.totalResultWorkStaffNum,
          totalResultWorkDtNum: item.totalResultWorkDtNum,
          week40overtimeFlag: item.week40overtimeFlag,
          worktimeDailyOvertimeFlag: item.worktimeDailyOvertimeFlag,
          worktimeWeeklyOvertimeFlag: item.worktimeWeeklyOvertimeFlag,
          worktimeMonthlyOvertimeFlag: item.worktimeMonthlyOvertimeFlag,
          sleepScoreAlertFlag: item.sleepScoreAlertFlag,
          sleepTimeAlertFlag: item.sleepTimeAlertFlag,
          fatigueAlertFlag: item.fatigueAlertFlag,
          heartRateAlertFlag: item.heartRateAlertFlag,
        }
      }));

      var workTime  = 0;
      var workDateNum = 0;
      var sleepTime = 0;
      var sleepStaffNum = 0;
      var alertNum = 0;

      for (const item of result.data) {
        if (item.totalResultWorkTime > 0) {
          workTime += item.totalResultWorkTime;
          workDateNum += item.totalResultWorkDtNum;
        }
        
        if (item.sleepTime > 0) {
          sleepTime += item.sleepTime;
          sleepStaffNum++;
        }

        if (item.totalResultOverTime > 40) {
          alertNum++;
        }
      };

      var avgWorkTime = 0;
      if (workTime > 0) {
        avgWorkTime = workTime / workDateNum;
      }
      var avgSleepTime = 0;
      if (sleepStaffNum > 0) {
        avgSleepTime = sleepTime / sleepStaffNum;
      }

      setWorkTimeStatus({ avgWorkTime:avgWorkTime });
      setSleepStatus({ avgSleepTime:avgSleepTime });
      setAlertStatus({ alertNum:alertNum });
    }
  }
  
  // 日付の事故情報の取得
  const apiGetAccidentDaily = async () => {
    //console.log('call apiGetAccidentDaily');
    dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getAccidentDaily';
    
    const data = {
      targetYMD: dateInfo.date,
      workplaceId: '000179',
    } 
    const result = await GetApi(apiUrl, data);

    dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      //alert.error('条件の該当データがありません(日付の事故情報を取得)');
      setAccidentStatus({ accidentNum:0 });
    } else {
      console.log(result.data);
      
      setAccidentStatus({ accidentNum:result.data.length });
    }
  }

  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }

  // 機能メニューの閉じるイベント
  const onCloseFunction = (event) => {
    setViewFunction(false);
  }

  // アラート要求クリックイベント
  const onClickRequetAlert = async () => {
    console.log('call onClickRequetAlert');
    dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/requestTruckAlert';
    
    const result = await PostApi(apiUrl);

    dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      alert.error('アラート確認に失敗しました。');
    } else {
      // 60秒後に画面更新
      dispatch(SetLoadSpinner(true));
      intervalRef.current = setInterval(() => {
        clearInterval(intervalRef.current);
        dispatch(SetLoadSpinner(false));

        intervalRef.current = null;
        apiGetWorkDaily();
        apiGetHealthCareDaily();
        apiGetAccidentDaily();
      }, 1000 * 60 * 1); // 60秒で変更
    }
  }

  useEffect(() => {
    if (grants && subGrants)
      document.title = grants.functionMenuName + '【' + subGrants.functionMenuName + '】 | CoreBoard'
    else if (grants)
    document.title = grants.functionMenuName + ' | CoreBoard'
    else
      document.title = 'CoreBoard'
  }, [grants, subGrants])
    
  useEffect(() => {
    if (searchDate) {
      apiGetDate();
    }
  }, [searchDate]);

  useEffect(() => {
    if (dateInfo) {
      apiGetWorkDaily();
      apiGetHealthCareDaily();
      apiGetAccidentDaily();
    }
  }, [dateInfo]);

  // 描画時に一度だけ実行
  useEffect(() => {
    //apiGetDate();
  }, []);

  return (
    <BoardBaseWrap>
      <Search
        onClickFunction={onClickFunction}
        onCloseFunction={onCloseFunction}
        onClickRequetAlertFunction={onClickRequetAlert}
        viewFunction={viewFunction}
        searchDate={searchDate}
        setSearchDate={setSearchDate}
      />
      <BoardBaseFormRow>
        <SideSubMenu/>
        <ContentWrap>
          {date &&
          <>
            <HeadWrap style={{marginBottom:'15px'}}>
              <DateItem>{HealthCareUtilty.getStringFromDate(date, 'MM / DD')}<span style={{fontSize:'24px'}}>（{dateInfo.week}）</span></DateItem>
              <PanelItem><CardTotal type={0} totalData={workStatus} /></PanelItem>
              <PanelItem><CardTotal type={1} totalData={workTimeStatus}/></PanelItem>
              <PanelItem><CardTotal type={2} totalData={sleepStatus}/></PanelItem>
              <PanelItem><CardTotal type={3} totalData={alertStatus}/></PanelItem>
              <PanelItem><CardTotal type={4} totalData={accidentStatus}/></PanelItem>
            </HeadWrap>
            <div style={{width:'1860px'}}>
              <MediaStaffHeaderHealthCare />
              <ListUl width={'calc(100%)'} height={'600px'}>
              {healthCareDailyList.map(item => (
                  <ListLi key={'shealthCareDaily' + item.staffId + '_' +item.targetYMD} style={{cursor: 'default'}}>
                    <MediaStaffDataHealthCare grants={grants} healthCareData={item} />
                  </ListLi>
                ))}
              </ListUl>
            </div>
          </>
          }
        </ContentWrap>
      </BoardBaseFormRow>
    </BoardBaseWrap>
  )
}

export default HealthCare

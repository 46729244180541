
export function formatMoney(numStr) {
  if(!numStr)
    return '';

  if (typeof numStr != 'string')
    numStr = String(numStr);

  numStr = numStr.replace(/,/g, '');
  numStr = numStr.replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
  //console.log(numStr)
  return numStr;
}


import React from 'react'
import { ErrorMessage } from 'react-hook-form'
import styled from 'styled-components'

import { withStyles } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip'

import Input from './Atoms/Input'
import Select from './Atoms/Select'
import PropTypes from 'prop-types'

// プロパティが階層を持つ場合に存在チェック
function getValue (obj, key, undefinedValue) {
  var k = key.split('.')
  for (var i = 0, v = obj; i < k.length; i++) {
    if (!(k[i] in v)) { return undefinedValue }
    v = v[k[i]]
  }
  return v
}

const Container = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  height: ${({ height }) => height || '100%'};
  > input, select {
    ${({ hasError }) => hasError ? 'background: pink;' : ''};
  }
`
const Label = styled.label`
`
const ErrorTooltip = withStyles({
  tooltip: {
    fontSize: '12px',
    color: 'white',
    backgroundColor: 'red'
  }
})(Tooltip);


export const InputGroup = ({ rule, register, errors, name, displayName, disabled, onBlur, ...props }) => {
  return (
    <Container errors={errors} name={name} hasError={getValue(errors, name)}>
      {displayName && <Label>{displayName}</Label>}

      {!getValue(errors, name) &&
        <Input inputRef={register && register(rule)} name={name} onBlur={onBlur} {...props} disabled={disabled} /> 
      }
      {getValue(errors, name) &&
      <ErrorMessage errors={errors} name={name}>
        {({ message }) => 
          <ErrorTooltip title={ message } placement="bottom-start">
             <Input inputRef={register && register(rule)} name={name} onBlur={onBlur} {...props} disabled={disabled} />
          </ErrorTooltip>
        }
      </ErrorMessage>
      }
    </Container>
  )
}
InputGroup.propTypes = {
  rule: PropTypes.any,
  register: PropTypes.any,
  errors: PropTypes.any,
  name: PropTypes.string,
  displayName: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func
}

export const HiddenInput = ({ register, name, ...props }) => {
  return <input ref={register} name={name} style={{ display: 'none' }} {...props} />
}
HiddenInput.propTypes = {
  register: PropTypes.any,
  name: PropTypes.string
}
export const SelectGroup = ({ rule, register, options, errors, name, displayName, disabled, placeholder, ...props }) => {
  return (
    <Container errors={errors} name={name} hasError={getValue(errors, name)}>
      {displayName && <Label>{displayName}</Label>}
      {!getValue(errors, name) &&
        <Select selectRef={register(rule)} name={name} {...props} disabled={disabled}>
          {placeholder && <option value='' style={{ display: 'none' }}>{placeholder}</option>}
          {Array.isArray(options) && options}
        </Select>
      }
      {getValue(errors, name) &&
        <ErrorMessage errors={errors} name={name}>
          {({ message }) => 
            <ErrorTooltip title={ message } placement="bottom-start">
              <Select selectRef={register(rule)} name={name} {...props} disabled={disabled}>
                {placeholder && <option value='' style={{ display: 'none' }}>{placeholder}</option>}
                {Array.isArray(options) && options}
              </Select>
            </ErrorTooltip>
          }
        </ErrorMessage>
      }
    </Container>
  )
}
SelectGroup.propTypes = {
  rule: PropTypes.any,
  register: PropTypes.any,
  options: PropTypes.any,
  errors: PropTypes.any,
  name: PropTypes.string,
  displayName: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool
}
export default InputGroup

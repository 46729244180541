import React, { useEffect } from 'react'
//import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'


const CardWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 900px;
  height: 100%;
  vertical-align:middle;
  margin-bottom: 5px;
  color: #000;
`

const HeaderContent = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 100%;
  height: 25%;
  text-align:center;
  vertical-align:middle;
  margin-bottom: 12px;
`

const Title = styled.span`
  width: 100%;
  font-size: 1.3vw;
  font-weight: bold;
  margin:auto;
`

const BodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: row;
  height: 75%;
  overflow: hidden;
  text-align: center;
  margin:auto;
`
const BodyContentColumn = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  margin:auto;
  width: 450px;
`

// 通常項目
const NormalItem = styled.span`
  font-size: 1.8vw;
  font-weight: bold;
`
// 小項目
const SmallItem = styled.span`
  font-size: 1.0rem;
  font-weight: bold;
  white-space: pre;
`

  //アイテム数集計  
  // 1:【惣菜】 0:【米飯】
  const sumItem=(progressList,type)=>{
    var sumPlan = 0
    var sumResult = 0
    switch(type){
      case 1:
        progressList.map((item,index)=>{
          var data = item.vasShippingItemInfo
          if(data.typeName=='惣菜'){
            sumPlan += parseInt(data.totalPlanItem)
            sumResult += parseInt(data.totalItem)
          }
        })
        sumPlan = sumPlan.toLocaleString()
        sumResult = sumResult.toLocaleString()
        break;
      case 0:
        progressList.map((item,index)=>{
          var data = item.vasShippingItemInfo
          if(data.typeName=='米飯'){
            sumPlan += parseInt(data.totalPlanItem)
            sumResult += parseInt(data.totalItem)
          }
        })
        sumPlan = sumPlan.toLocaleString()
        sumResult = sumResult.toLocaleString()
        break;
      default:
        break;
    }

    return <>{sumResult} <span style={{fontSize:'90%'}}>/</span> <span style={{fontSize:'70%'}}>{sumPlan}</span></>
  }
  //アイテム数集計  
  // 1:【惣菜】 0:【米飯】
  const sumItemCount=(progressList,type)=>{
    var sumPlan = 0
    var sumResult = 0
    switch(type){
      case 1:
        progressList.map((item,index)=>{
          var data = item.vasShippingItemInfo
          if(data.typeName=='惣菜'){
            sumPlan += parseInt(1)
            if(data.totalItemCount == '1.0000')
              sumResult += parseInt(1)
          }
        })
        sumPlan = sumPlan.toLocaleString()
        sumResult = sumResult.toLocaleString()
        break;
      case 0:
        progressList.map((item,index)=>{
          var data = item.vasShippingItemInfo
          if(data.typeName=='米飯'){
            sumPlan += parseInt(1)
            if(data.totalItemCount == '1.0000')
              sumResult += parseInt(1)
          }
        })
        sumPlan = sumPlan.toLocaleString()
        sumResult = sumResult.toLocaleString()
        break;
      default:
        break;
    }

    return <>{sumResult} <span style={{fontSize:'90%'}}>/</span> <span style={{fontSize:'70%'}}>{sumPlan}</span></>
  }

// ヘッダー部のカード
const CardTotal = (props) => {
  const {
    type,progressList
  } = props
  //const alert = useAlert();
  //const interval1Ref = useRef(null);

  // 描画時に一度だけ実行
  useEffect(() => {
  }, [])

  //if (!grants || !totalData) return null
  return (
    <CardWrap>
      <HeaderContent>
          <Title>{type?'【惣菜】':'【米飯】'}</Title>
      </HeaderContent>
      <BodyContent>
        <BodyContentColumn>
          <SmallItem>アイテム種類</SmallItem>
          <NormalItem>{sumItemCount(progressList,type)}</NormalItem>
        </BodyContentColumn>
        <BodyContentColumn>
          <SmallItem>アイテム数量</SmallItem>
          <NormalItem>{sumItem(progressList,type)}</NormalItem>
        </BodyContentColumn> 
      </BodyContent>
    </CardWrap>
  )
}

CardTotal.propTypes = {
  type: PropTypes.number, // 1:【惣菜】 0:【米飯】
  progressList: PropTypes.array //実績リスト
}
  
export default CardTotal

import React from 'react'
//import styled from 'styled-components'
import { useHistory } from "react-router-dom"
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import Tooltip from '@material-ui/core/Tooltip'

//import FUNC from '../Common/FunctionEnum'
//import { PostApi } from '../Common/ApiAxios'

import IconCollapseImg from '../../../image/Collapse.png'
import IconFunctionImg from '../../../image/three-reader-vertical-icon.png'
import IconTopImg from '../../../image/menu-icon3.png'
import SettingIcon from '../../../image/setting-icon.png'
import IconInquiryImg from '../../../image/inquiry-icon.png'
import IconMapImg from '../../../image/map-icon.png'
import IconCalendarImg from '../../../image/calendar-icon.png'
//import IconCloseImg from '../../image/close_icon.png'
import OperationTypeTab from './Molcules/OperationTypeTab'
import OperationSummaryTypeTab from './Molcules/OperationSummaryTypeTab'
import SearchWrap ,{ FunctionContent, FunctionContentRow, FunctionIcon, FunctionName} from '../Wrap/BoardSerachWrap'

const Search = (props) => {
  const {
    addStyle,
    onClickFunction,
    onCloseFunction,
    viewSubSearch,
    viewFunction,
    summaryType,
    onClickSummaryButton
  } = props
  const history = useHistory();
  const loginUserInfo = useSelector(state => state.loginUserInfo);
  //const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC);

  const makeTitle=(type)=>{
         
    //現在時刻
    var nowDate = ''
    switch(type){
      case 0:
        var now = new Date()
        now.setDate(now.getDate()-1)
        nowDate = now.getFullYear() + '年' + ('00'+Number(now.getMonth()+1)).slice(-2)+ '月' + ('00'+now.getDate()).slice(-2)+'日'
        var week =  ["日", "月", "火", "水", "木", "金", "土"][now.getDay()]
        nowDate = nowDate + '（'+week+'）'
        break
      case 1:
        var now = new Date()
        nowDate = now.getFullYear() + '年' + ('00'+Number(now.getMonth()+1)).slice(-2)+ '月' + ('00'+now.getDate()).slice(-2)+'日'
        var week =  ["日", "月", "火", "水", "木", "金", "土"][now.getDay()]
        nowDate = nowDate + '（'+week+'）'
        break
      case 2:
        var now = new Date()
        nowDate = now.getFullYear() + '年' + ('00'+Number(now.getMonth()+1)).slice(-2)+ '月'
        now.setMonth(now.getMonth()+1)
        now.setDate(0)
        nowDate = nowDate +'01日～'+ ('00'+now.getDate()).slice(-2)+'日'
        break
      default:
        break
    }

    return nowDate
}
  async function summaryClick (type) {
    var state = {type:type}
    history.push({state:state,pathname:'/afstruck/summary'});
  }
  async function summaryPage () {
    history.push('/afstruck/summary');
  }
  async function mapPage () {
    history.push('/afstruck/map');
  }
  async function detailPage () {
    history.push('/afstruck/detail');
  }
  //if (!grants) return null;
  return (
    <SearchWrap addStyle={addStyle} viewSubSearch={viewSubSearch} viewFunction={viewFunction}>
      <ul className='mainSearch'>
        <li>
        </li>
        <li className='subSearch'>
        </li>
      </ul>
      <OperationTypeTab type={summaryType} onClick={onClickSummaryButton}/>
      <div></div>
      <div style={{marginLeft:'auto'}}/>
      <OperationSummaryTypeTab type={summaryType} onClick={(type)=>summaryClick(type)}/>
      <Tooltip title={'機能メニュー'}>
        <img alt='機能メニュー' src={IconFunctionImg} width="24px" height="24px" style={{marginTop: '4px', cursor: 'pointer'}} onClick={onClickFunction} />
      </Tooltip>
      <li className='mainFunction'>
        <FunctionContent onMouseLeave={onCloseFunction}>
          <FunctionContentRow style={{marginTop:'12px'}}>
            <FunctionIcon alt='ホーム' src={IconTopImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>ホーム</span></FunctionName>
          </FunctionContentRow>
          <hr></hr>
          <FunctionContentRow style={{}} onClick={summaryPage}>
            <FunctionIcon alt='サマリ' src={IconInquiryImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>サマリ</span></FunctionName>
          </FunctionContentRow>
          <FunctionContentRow style={{marginTop:'12px'}} onClick={detailPage}>
            <FunctionIcon alt='明細' src={IconInquiryImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>明細</span></FunctionName>
          </FunctionContentRow>
          <FunctionContentRow style={{marginTop:'12px'}}>
            <FunctionIcon alt='マップ' src={IconMapImg} onClick={mapPage}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>マップ</span></FunctionName>
          </FunctionContentRow>
          <FunctionContentRow style={{marginTop:'12px',marginBottom:'12px'}}>
            <FunctionIcon alt='設定' src={SettingIcon}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>設定</span></FunctionName>
          </FunctionContentRow>
        </FunctionContent>
      </li>
    </SearchWrap>
  )
}

Search.propTypes = {
  addStyle: PropTypes.string, // css用追加用
  onClickFunction: PropTypes.func,
  onCloseFunction: PropTypes.func,
  viewSubSearch: PropTypes.bool, // サブ検索項目を表示するか
  viewFunction: PropTypes.bool, // サブ機能を表示するか
}

export default Search

import React, { useState, useEffect,useRef } from 'react'
import { useSelector } from 'react-redux'
//import { useAlert } from 'react-alert'
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
//import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import { GetApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'

//import ErrorHandling from '../../Common/ErrorHandling'

import FUNC from '../../Common/FunctionEnum'

import {BoardBaseFormRow, BoardBaseFormRowScroll} from '../Wrap/BoardBaseWrap'

import Search from './Search'
import PerformanceDaily from './PerformanceDaily'
import PerformanceRange from './PerformanceRange'

import * as LogisticUtility from '../../Common/Utilties/LogisticUtility'

import SideSubMenu from '../SideSubMenu'

const DefaultUrl = '/api/logisticManagement'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  overflow-y: none;
  overflow-x: none;
`

const VASPersonal = (props) => {
  const { loginUserInfo } = useSelector(state => state);
  const [viewFunction,setViewFunction] = useState(false);
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC);
  const subGrants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC_DASHBOARD);
  const dispatch = useDispatch();
  const alert = useAlert();
  const [personalDailyList, setPersonalDailyList] = useState([]);
  const [personalRangeList, setPersonalRangeList] = useState([]);
  const [personalMonthlyList, setPersonalMonthlyList] = useState([]);
  const [dateRangeList, setDateRangeList] = useState([]);
  const [dateMonthlyList, setDateMonthlyList] = useState([]);
  const searchDateRef = useRef();
  const searchFromDateRef = useRef();
  const searchToDateRef = useRef();
  const searchMonthRef = useRef();
  const searchWorkplaceRef = useRef();
  const [dateType, setDateType] = useState(0);
  
  const history = useLocation(); 
  const hisState = history.state

  /*2023/01/29 K.Harada ADD 現場IDを表示するように変更 */
  const workplaceId = history.state&&history.state.workplaceId?history.state.workplaceId:'000006'
  const baseId = history.state&&history.state.baseId?history.state.baseId:2
  
  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }
  // 機能メニューの閉じるイベント
  const onCloseFunction = (event) => {
    setViewFunction(false);
  }

  useEffect(() => {
    if (grants && subGrants)
      document.title = grants.functionMenuName + '【' + subGrants.functionMenuName + '】 | CoreBoard'
    else if (grants)
      document.title = grants.functionMenuName + ' | CoreBoard'
    else
      document.title = 'CoreBoard'
  }, [grants, subGrants]);


  const onDateTypeChange=(dateType) => {
    setDateType(dateType);

    if(dateType == 0) {
      getVasPersonalDaily(true);
      getDateRange(true);
    } else if (dateType == 1) {
      getDateRange(true);
      getVasPersonalRange(true);
    } else if (dateType == 2) {
      getDateMonth(true);
      getVasPersonalMonthly(true);
    }
  }

  const onDateChange=(date)=>{
    searchDateRef.current = date;

    getVasPersonalDaily(true);
  }

  const onRangeChange=(from,to)=>{
    searchFromDateRef.current = from;
    searchToDateRef.current = to;

    getDateRange(true);
    getVasPersonalRange(true);
  }

  const onMonthChange=(month)=>{
    searchMonthRef.current = month;

    getDateMonth(true);
    getVasPersonalMonthly(true);
  }

  const onWorkplaceId=(id)=>{
    searchWorkplaceRef.current = id;

    getVasPersonalDaily(true);
  }
  
  // VAS個人実績日別の取得
  const getVasPersonalDaily = async (isLoadSplinner) => {
    //console.log('call getVasPersonalDaily');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));
  
    const apiUrl = DefaultUrl + '/getVasPersonalDaily';
    
    var now = new Date(searchDateRef.current);
    const data = {
      workplaceId: workplaceId,
      targetYMD: LogisticUtility.getStringFromDate(now, 'YYYY/MM/DD')
    }
    const result = await GetApi(apiUrl, data);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      //alert.error('条件の該当データがありません(個人作業実績の取得)');
      setPersonalDailyList([]);
    } else {
      //console.log(result.data);
      setPersonalDailyList(result.data)       
    }
  }
  
  // VAS個人実績期間別の取得
  const getVasPersonalRange = async (isLoadSplinner) => {
    //console.log('call getVasPersonalRange');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));
  
    const apiUrl = DefaultUrl + '/getVasPersonalRange';
    
    var startDt = new Date(searchFromDateRef.current);
    var endDt = new Date(searchToDateRef.current);
    const data = {
      workplaceId: workplaceId,
      startYMD: LogisticUtility.getStringFromDate(startDt, 'YYYY/MM/DD'),
      endYMD: LogisticUtility.getStringFromDate(endDt, 'YYYY/MM/DD')
    }
    const result = await GetApi(apiUrl, data);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      //alert.error('条件の該当データがありません(個人作業実績の取得)');
    } else {
      //console.log(result.data);
      setPersonalRangeList(result.data)       
    }
  }

  // VAS個人実績月別の取得
  const getVasPersonalMonthly = async (isLoadSplinner) => {
    console.log('call getVasPersonalMonthly');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));
  
    const apiUrl = DefaultUrl + '/getVasPersonalRange';
    
    var startDt = new Date(searchMonthRef.current);
    var endDt = new Date(startDt.setMonth(startDt.getMonth()+1, 0));
    const data = {
      workplaceId: workplaceId,
      startYMD: LogisticUtility.getStringFromDate(startDt, 'YYYY/MM/01'),
      endYMD: LogisticUtility.getStringFromDate(endDt, 'YYYY/MM/DD')
    }
    const result = await GetApi(apiUrl, data);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      //alert.error('条件の該当データがありません(個人作業実績の取得)');
    } else {
      //console.log(result.data);
      setPersonalMonthlyList(result.data)       
    }
  }

  const getDateRange = async (isLoadSplinner) => {
    //console.log('call getDateRange');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));
  
    const apiUrl = '/api/masterManagement/calendar/range';
    
    var startDt = new Date(searchFromDateRef.current);
    var endDt = new Date(searchToDateRef.current);
    const data = {
      startDt: LogisticUtility.getStringFromDate(startDt, 'YYYY/MM/DD'),
      endDt: LogisticUtility.getStringFromDate(endDt, 'YYYY/MM/DD')
    }
    const result = await GetApi(apiUrl, data);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
    } else {
      setDateRangeList(result.data.responseList);
    }
  }

  const getDateMonth = async (isLoadSplinner) => {
    //console.log('call getDateMonth');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));
  
    const apiUrl = '/api/masterManagement/calendar/range';
    
    var startDt = new Date(searchMonthRef.current);
    var endDt = new Date(startDt.setMonth(startDt.getMonth()+1, 0));
    const data = {
      startDt: LogisticUtility.getStringFromDate(startDt, 'YYYY/MM/01'),
      endDt: LogisticUtility.getStringFromDate(endDt, 'YYYY/MM/DD')
    }
    const result = await GetApi(apiUrl, data);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
    } else {
      setDateMonthlyList(result.data.responseList);
    }
  }

  // 描画時に一度だけ実行
  useEffect(() => {
    getVasPersonalDaily(true);
  }, [])
  //const { loginUserInfo } = useSelector(state => state);

  if (!searchDateRef.current) {
    if(hisState && hisState.searchDate){
      searchDateRef.current = LogisticUtility.getStringFromDate(new Date(hisState.searchDate), 'YYYY-MM-DD')
    }else{
      searchDateRef.current = LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD')
    }   
    getVasPersonalDaily(true);
  }
  if (!searchFromDateRef.current) {
    if(hisState && hisState.searchDate){
      searchFromDateRef.current = LogisticUtility.getStringFromDate(new Date(hisState.searchDate), 'YYYY-MM-DD')
    }else{
      searchFromDateRef.current = LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD')
    }   
  }
  if (!searchToDateRef.current) {
    if(hisState && hisState.searchDate){
      searchToDateRef.current = LogisticUtility.getStringFromDate(new Date(hisState.searchDate), 'YYYY-MM-DD')
    }else{
      searchToDateRef.current = LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD');
    }   
  }
  if (!searchMonthRef.current) {
    if(hisState && hisState.searchDate){
      searchMonthRef.current = LogisticUtility.getStringFromDate(new Date(hisState.searchDate), 'YYYY-MM-01')
    }else{
      searchMonthRef.current = LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-01');
    }   
  }
  return (
    <>
      <Search
        onClickFunction={onClickFunction}
        onCloseFunction={onCloseFunction}
        viewFunction={viewFunction}
        searchDateRef={searchDateRef}
        searchFromDateRef={searchFromDateRef}
        searchToDateRef={searchToDateRef}
        searchMonthRef={searchMonthRef}
        onDateChange={(date)=>onDateChange(date)}
        onRangeDateChange={(from,to)=>onRangeChange(from,to)}
        onMonthChange={(month)=>onMonthChange(month)}
        onDateTypeChange={(dateType)=>onDateTypeChange(dateType)}
        onWorkplaceChange={(id)=>onWorkplaceId(id)}
        workplaceId={workplaceId}
        baseId={baseId}
      />
      <BoardBaseFormRow>
        <SideSubMenu height={'84vh'} planExisit={true}/>
        <BoardBaseFormRowScroll>
          <ContentWrap>
            {dateType == 0 &&
              <PerformanceDaily personalList={personalDailyList}/>
            }
            {dateType == 1 &&
              <PerformanceRange dateRangeList={dateRangeList} personalList={personalRangeList}/>
            }
            {dateType == 2 &&
              <PerformanceRange dateRangeList={dateMonthlyList} personalList={personalMonthlyList}/>
            }
          </ContentWrap>
        </BoardBaseFormRowScroll>
      </BoardBaseFormRow>
    </>
  )
}

export default VASPersonal

import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import IconCloseImg from '../../../image/close_icon.png'
import Button from '../../Common/Atoms/NormalButton'
import { useDispatch } from 'react-redux'
import { GetApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import { useLocation } from 'react-router-dom'
const DefaultUrl = '/api/afsTruckDashboardManagement/dashboard'

const MenuNav = styled.nav`
  display: none;
  animation-name: menu;
  animation-duration: .5s;
  animation-timing-function: ease;
  width: 1300px;
  height: 600px;
  background: #cfe2f3;
  box-shadow: 2px 2px 6px rgba(0,0,0,.4);
  position: absolute;
  left: calc(50% - 650px);
  top: 100px;
  z-index: 1300;

  @keyframes menu {
    from {
      opacity: 0;
      transform: translateX(0);
    }
    
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
`
const MenuContentWrap  = styled.div`
display: flex;
`

const SubMenuContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  width:1200px;
  height:500px;
  background: white;
  overflow: hidden;
  :hover {
    overflow-y: auto;
    overflow-x: auto;
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    border:none;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(212, 212, 212, 1);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:active {
    background-color: rgba(112, 112, 112, 1);
  }
  margin-left: calc(50% - 600px);

`

const NavigatorItemContent  = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  text-align: center;
  width: 110px;
  font-size: 14px;
`
const ProgressTitle = styled.div`
  cursor: pointer;
  font-weight: bold;
  color: blue;
  text-decoration:underline;
  text-align: left;
  font-size: 1.8rem;
  width calc(100% - 20px);
  padding:0.5rem;

  :hover{
    color: mediumblue;
  }
`

const Navigator = (props) => {
  const loginUserInfo = useSelector(state => state.loginUserInfo);
  const dispatch = useDispatch()
  const [infomartion,setInfomartion] = useState([]);

  useEffect(() => {
    if(loginUserInfo.staffId && props.showFlg === 'block' )
      apiGetInformation(true)
  // ログインユーザー情報の変更検知時のみ実行する
  // eslint-disable-next-line
  }, [loginUserInfo,props.showFlg]);

  const showMenu = () => {
      //localStorage.clear();
      props.setShowFlg(props.showFlg === 'none' ? 'block' : 'none');
  }
  // 進捗集計情報の取得
  const apiGetInformation = async (isLoadSplinner) => {
    if (isLoadSplinner)
       dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getInformationUser';
    
    const data = {
    }
    console.log(data)
    const result = await GetApi(apiUrl, data);
    if (isLoadSplinner)
       dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
    } else {
      console.log(result);
      setInfomartion(result.data)
    }
  }
  const onOpen = (count,value) => {
    localStorage.setItem("TitleInfo"+count, value.textTitle);
    localStorage.setItem("PeriodInfo"+count, value.textPeriod);
    localStorage.setItem("TextInfo"+count, value.infoText);
    localStorage.setItem("IdInfo"+count, value.infoId);
    localStorage.setItem("companyCodeInfo"+count, value.companyCode);

    if(window.location.hostname=='localhost'){
      window.open(
        `http://localhost:3000/afstruck/information?infonum=${count}`,
        'Info'+count,
        "width=900,height=660,scrollbars=yes"
      )
    }else{
      window.open(
        `https://coreboard-front-dev.pal-style.co.jp/afstruck/information?infonum=${count}`,
        'Info'+count,
        "width=900,height=660,scrollbars=yes"
      )
    }

  }
  return (
  <MenuNav className='info' style={{ display: props.showFlg }} >
     <div style={{display:'flex',margin:'15px',justifyContent: 'flex-end'}}>
      <img alt='閉じる' 
           src={IconCloseImg} 
           width="12px" 
           height="12px" 
           style={{cursor: 'pointer', margin:'0 0 0 auto'}} 
           onClick={showMenu} />
      </div>
      <MenuContentWrap>
        <SubMenuContentWrap>
          {infomartion.map((value,index)=>{
            return <ProgressTitle onClick={()=>onOpen(index,value)}>{value.listTitle}</ProgressTitle>
          })}
        </SubMenuContentWrap>
      </MenuContentWrap>
      <div style={{display:'flex',justifyContent: 'center'}}>
        <Button name='閉じる' padding='0px' onClick={showMenu} />
      </div>
    </MenuNav>
  )
}

Navigator.propTypes = {
  showFlg: PropTypes.string,
  setShowFlg: PropTypes.func,
  topPageFlg: PropTypes.bool,
}

export default Navigator

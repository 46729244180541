import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
//import { useAlert } from 'react-alert'
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'
//import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'

import FUNC from '../../Common/FunctionEnum'

import BoardBaseWrap,{BoardBaseFormRow} from '../Wrap/BoardBaseWrap'

import Search from './Search'
import SideSubMenu from '../SideSubMenu'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 85vh;
  text-align: center;
  vertical-align: middle;
`

const Planning = (props) => {
  const { loginUserInfo } = useSelector(state => state);
  const [viewFunction,setViewFunction] = useState(false);
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC);
  const subGrants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC_PLANNING);

  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }

  // 機能メニューの閉じるイベント
  const onCloseFunction = (event) => {
    setViewFunction(false);
  }


  useEffect(() => {
    if (grants && subGrants)
      document.title = grants.functionMenuName + '【' + subGrants.functionMenuName + '】 | CoreBoard'
    else if (grants)
    document.title = grants.functionMenuName + ' | CoreBoard'
    else
      document.title = 'CoreBoard'
  }, [grants, subGrants])
    
  // 描画時に一度だけ実行
  useEffect(() => {
  }, [])

  return (
      <BoardBaseWrap>
        <Search
          onClickFunction={onClickFunction}
          onCloseFunction={onCloseFunction}
          viewFunction={viewFunction}
        />
        <BoardBaseFormRow>
          <SideSubMenu pageNum={4}/>
          <ContentWrap>
            <div style={{margin:'auto'}}>工事中</div>
          </ContentWrap>
        </BoardBaseFormRow>
        </BoardBaseWrap>
  )
}

export default Planning

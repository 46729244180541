import React, { useEffect, useState, useCallback } from 'react'
//import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import TreeView from '@material-ui/lab/TreeView'
import TreeItem from '@material-ui/lab/TreeItem'

//import { GetApi } from '../../Common/ApiAxios'
//import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
//import ErrorHandling from '../../Common/ErrorHandling'
import * as regex from '../../Common/Regex'
import { InputGroup, HiddenInput } from '../../Common/CommonInputGroup'
import NormalSubButton from '../../Common/Atoms/NormalSubButton'
import { Alert } from '../../Common/CommonStyled'
import { useYubinbango } from '../../Common/Utilties/CommonUtility'
import { formatZipCode } from '../../Common/Utilties/StringUtility'
import { formatPhoneNum } from '../../Common/Utilties/PhoneNumUtility'
import { YubinbangoScript } from '../../Common/Atoms/ScriptTag'

import DepartmentModal from './Molcules/DepartmentModal/DepartmentModal'
import MasterDetailWrap, { MasterDetailFormWrap } from '../Wrap/MasterDetailWrap'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '75vw',
  },
  typography: {
    width: '72vw',
    fontSize: '18px',
    fontWeight: '700',
    color: '#ffffff',
    verticalAlign: 'middle',
    margin: '0',
    padding: '16px',
    background: '#4169E1',
  },
}))

const useTreeStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: '70vw',
  },
}))

export const CustomerMasterForm = ({
  register, errors, setValue, getValues, customerDetail,
  watch, canWrite, loginUserInfo, isDeleted, actionType,
  isView
}) => {
  const classes = useStyles()
  const treeClasses = useTreeStyles()
  const alert = useAlert()
  const [selectDepartment, setSelectDepartment] = useState(null)
  const [open, setOpen] = useState(false)

  // 顧客情報切り替え時
  useEffect(() => {
    if (customerDetail != null) {
      setValue('customerId', customerDetail.customerId)
      setValue('customerName', customerDetail.customerName)
      setValue('customerKana', customerDetail.customerKana)
      setValue('zipCode', customerDetail.zipCode)
      setValue('address1', customerDetail.address1)
      setValue('address2', customerDetail.address2)
      setValue('address3', customerDetail.address3)
      setValue('address4', customerDetail.address4)
      setValue('phoneNum', customerDetail.phoneNum)
      setValue('faxNum', customerDetail.faxNum)
      setValue('webUrl', customerDetail.webUrl)
      setValue('cooperationId', customerDetail.cooperationId)
      setValue('updatedAt', customerDetail.updatedAt)
      setValue('updatedId', customerDetail.updatedId)
    }
  // 顧客情報が変更された場合のみ実行
  // eslint-disable-next-line
  }, [customerDetail])

  // 初期化メソッド
  useEffect(() => {
    async function getOptions () {
    }
    getOptions()
  // 初回描画時のみ実行
  // eslint-disable-next-line
  }, [])

  // 郵便番号による自動補完
  const [zipCode, setZipCode] = useState(null)
  const { region, locality, street } = useYubinbango(zipCode)

  useEffect(() => {
    setValue('address1', region, true)
    setValue('address2', locality, true)
    setValue('address3', street, true)
  // 住所情報の変更検知時のみ実行する
  // eslint-disable-next-line
  }, [region, locality, street])

  const onChangePostalCode = useCallback(e => {
    e.currentTarget.value = formatZipCode(e.currentTarget.value)

    setZipCode(e.currentTarget.value)
  }, [setZipCode])

  const onChangePhoneNum = useCallback(e => {
    e.currentTarget.value = formatPhoneNum(e.currentTarget.value)
  })

  const onOpenDialog = (e, id) => {
    e.stopPropagation() 
    
    var item = customerDetail.departments.find(item => item.cusDepartmentId === id)
    setSelectDepartment(item)

    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const createDepartmentItems = (departments, prefixId, hierarchyNo) => {

    const result = departments.filter(item => item.hierarchyNo === hierarchyNo && (prefixId === "" || item.cusDepartmentId.indexOf(prefixId) === 0));
    return result.map(item => {
      let children = undefined;
      if (hierarchyNo < 4) {
        children = createDepartmentItems(departments, item.cusDepartmentId.substring(0, 3 * hierarchyNo), hierarchyNo + 1);
      }
      return (
        <TreeItem
          key={item.cusDepartmentId}
          nodeId={item.cusDepartmentId}
          label={
              <div style={{padding: '1rem', display: 'flex' }}>
                <div>{item.cusDepartmentName}</div>
                <div  style={{marginLeft: 'auto'}}>
                  <NormalSubButton onClick={ (e) => onOpenDialog(e, item.cusDepartmentId) }
                    display='block'
                    name='詳細'
                    width='90px'
                    margin='0'
                  ></NormalSubButton>
                </div>
            </div>
          }
          children={children}
        />
      )
    })
  }

  if (customerDetail === null) return null // 未選択時の場合は非表示
  return (
    <MasterDetailWrap isView={isView}>
      <YubinbangoScript />
      <div className={classes.root}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.typography}>基本情報</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <MasterDetailFormWrap>
          <ul>
            <li style={{ height: '15px' }}><label /><div /></li>
            {isDeleted && <li><label /><div><Alert>削除データ</Alert></div></li>}
            <li>
              <label>ID</label>
              <div>
                <InputGroup
                  width='10rem'
                  margin='12px 0 12px 0'
                  placeholder='未入力時は自動採番'
                  name='customerId'
                  register={register}
                  rule={{
                    maxLength: regex.maxLength(10),
                    pattern: regex.halfWidthAlphaNumeric
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite || actionType === 'PUT'}
                />
              </div>
            </li>
            <li>
              <label className="required">顧客名</label>
              <div>
                <InputGroup
                  width='20rem'
                  margin='12px 0 12px 0'
                  placeholder='名称'
                  name='customerName'
                  register={register}
                  rule={{
                    required: regex.required,
                    maxLength: regex.maxLength(50)
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                />
                <InputGroup
                  width='20rem'
                  margin='12px 0 12px 16px'
                  placeholder='名称(カナ)'
                  name='staffKana'
                  register={register}
                  rule={{
                    maxLength: regex.maxLength(50),
                    pattern: regex.kana
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
          </ul>
        </MasterDetailFormWrap>
        </AccordionDetails>
      </Accordion>
      </div>

      <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.typography}>連絡情報</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <MasterDetailFormWrap>
          <ul>
            <li>
              <label>住所</label>
              <div>
                <InputGroup
                  width='10rem'
                  margin='12px 0 12px 0'
                  placeholder='郵便番号(例:550-0013)'
                  name='zipCode'
                  register={register}
                  onChange={onChangePostalCode}
                  rule={{
                    maxLength: regex.maxLength(8)
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
            <li>
              <label />
              <div>
                <InputGroup
                  width='10rem'
                  margin='12px 0 12px 0'
                  placeholder='都道府県(例:大阪府)'
                  name='address1'
                  register={register}
                  rule={{
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
            <li>
              <label />
              <div>
                <InputGroup
                  width='40rem'
                  margin='12px 0 12px 0'
                  placeholder='地区町村(例:大阪市西区)'
                  name='address2'
                  register={register}
                  rule={{
                    maxLength: regex.maxLength(20)
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
            <li>
              <label />
              <div>
                <InputGroup
                  width='40rem'
                  margin='12px 0 12px 0'
                  placeholder='町域名(例:新町1丁目4番26号)'
                  name='address3'
                  register={register}
                  rule={{
                    maxLength: regex.maxLength(50)
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
            <li>
              <label />
              <div>
                <InputGroup
                  width='40rem'
                  margin='12px 0 12px 0'
                  placeholder='建物名(例:四ツ橋グランドビル2階)'
                  name='address4'
                  register={register}
                  rule={{
                    maxLength: regex.maxLength(50)
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
            <li>
              <label>電話番号</label>
              <div>
                <InputGroup
                  width='20rem'
                  margin='12px 0 12px 0'
                  placeholder='電話番号'
                  name='phoneNum'
                  register={register}
                  onChange={onChangePhoneNum}
                  rule={{
                    maxLength: regex.maxLength(20),
                    pattern: regex.halfNumericHyphen
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
            <li>
              <label>FAX番号</label>
              <div>
                <InputGroup
                  width='20rem'
                  margin='12px 0 12px 0'
                  placeholder='FAX番号'
                  name='faxNum'
                  register={register}
                  onChange={onChangePhoneNum}
                  rule={{
                    maxLength: regex.maxLength(20),
                    pattern: regex.halfNumericHyphen
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
            <li>
              <label>WEBサイト</label>
              <div>
                <InputGroup
                  width='20rem'
                  margin='12px 0 12px 0'
                  placeholder='URL'
                  name='webURL'
                  register={register}
                  rule={{
                    maxLength: regex.maxLength(100)
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
          </ul>
        </MasterDetailFormWrap>
        </AccordionDetails>
      </Accordion>
      </div>

      <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.typography}>部門情報</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TreeView
              className={treeClasses.root}
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
            >
            {customerDetail.departments && createDepartmentItems(customerDetail.departments, "", 1)}
          </TreeView>
        </AccordionDetails>
      </Accordion>
      </div>

      <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.typography}>他システム連携情報</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MasterDetailFormWrap>
          <ul>
            <li style={{ height: '15px' }}><label /><div /></li>
            <li>
              <label>連携ID</label>
              <div>
                <InputGroup
                  width='10rem'
                  margin='12px 0 12px 16px'
                  placeholder='連携用ID'
                  name='cooperationId'
                  register={register}
                  rule={{
                    maxLength: regex.maxLength(10),
                    pattern: regex.halfWidthAlphaNumeric
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
          </ul>
          </MasterDetailFormWrap>
        </AccordionDetails>
      </Accordion>
      </div>

      <DepartmentModal
        open={open}
        onCloseFunc={handleClose}
        canWrite={canWrite}
        department={selectDepartment}
      >
      </DepartmentModal>
      <HiddenInput name='updatedId' register={register} />
      <HiddenInput name='updatedAt' register={register} />
    </MasterDetailWrap>
  )
}

CustomerMasterForm.propTypes = {
  register: PropTypes.func,
  errors: PropTypes.object,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  customerDetail: PropTypes.object,
  watch: PropTypes.func,
  canWrite: PropTypes.bool,
  isDeleted: PropTypes.bool,
  actionType: PropTypes.string,
  isView: PropTypes.bool,
}

export default CustomerMasterForm

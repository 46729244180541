import React, { useState, useEffect,useRef } from 'react'
//import { useSelector, useDispatch } from 'react-redux'
//import { useAlert } from 'react-alert'
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'
//import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
//import { useSyncExternalStore } from 'react'

//import FUNC from '../Common/FunctionEnum'

//import LogisticCard from './Atoms/LogisticCard'
//import TruckCard from './Atoms/TruckCard'
//import CoreTimeCard from './Atoms/CoreTimeCard'
//import CoreFirstCard from './Atoms/CoreFirstCard'
//import InfoCard from './Atoms/InfoCard'
import MonthCalendar from '../../Common/Atoms/MonthCalendar'
import Calendar from '../../Common/Atoms/Calendar'
import Search from './Search'
import ProgressPerformance from './ProgressPerformance'
import {BoardBaseFormRow, BoardBaseFormRowScroll} from '../Wrap/BoardBaseWrap'
import * as LogisticUtility from '../../Common/Utilties/LogisticUtility'
import { useLocation } from 'react-router-dom'
const InfoContent = styled.div`
  width: calc(100% - 10px);
  height: calc(100% - 200px);
`

const Summary = (props) => {
   /*2023/12/25 K.Harada MOD 初期値を変更*/
  const history = useLocation(); 
  const hisState = history.state
  const summaryTypeDefalut = hisState && hisState.type?1:0

  const [viewFunction,setViewFunction] = useState(false);
  const [summaryType,setSammarytype] = useState(summaryTypeDefalut);
  const searchDateRef = useRef()
  //const dispatch = useDispatch()
  //const alert = useAlert()
  //const { loginUserInfo } = useSelector(state => state);
  //const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.TRUCK);
  //const subGrants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.TRUCK_HEALTHCARE);

  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }

  // 機能メニューの閉じるイベント
  const onCloseFunction = (event) => {
    setViewFunction(false);
  }
  const onClickSummaryButton = (num) => {
    console.log(num)
    setSammarytype(num)
  }
  // 描画時に一度だけ実行
  useEffect(() => {
  }, [])
  // POST・PUT処理
  const onSubmit = (data) => {
  }

  if (!searchDateRef.current) {
    searchDateRef.current = LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD')
    // if(hisState && hisState.searchDate){
    //   searchDateRef.current = LogisticUtility.getStringFromDate(new Date(hisState.searchDate), 'YYYY-MM-DD')
    // }else{
    //   searchDateRef.current = LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD')
    // }   
  }
  return (
    <>
      <BoardBaseFormRow>
      <Search
        onClickFunction={onClickFunction}
        onCloseFunction={onCloseFunction}
        onClickSummaryButton={(num)=>onClickSummaryButton(num)}
        viewFunction={viewFunction}
        summaryType={summaryType}
        searchDateRef={searchDateRef}

      />
      </BoardBaseFormRow>
      <BoardBaseFormRow>
        <BoardBaseFormRowScroll>
          <ProgressPerformance/>
        </BoardBaseFormRowScroll>
      </BoardBaseFormRow>
    </>
  )
}

export default Summary

import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
//import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { GetApi } from '../../../Common/ApiAxios'
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'
import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'

import IconPageNextImg from '../../../../image/PageNext.png'
import IconPageBackImg from '../../../../image/PageBack.png'

const DefaultUrl = '/api/logisticManagement'

const Wrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  float: flex;
  width: 100%;
  text-align: center;
  vertical-align: middle;
`

const Flex = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 100%;
  text-align: center;
  vertical-align: middle;
`

const Content = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  width: calc(100% - 210px);
  height: 100%;
  text-align: center;
  vertical-align: middle;

  margin-left: auto;
  margin-right: auto;

  ::-webkit-scrollbar-corner {
    display: none;
  }
`

const Title = styled.div`
  width: 200px;
  height: 50px;
  margin: 5px;

  font-size: 20px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
`

const DatePanel= styled.div`
  width: 100%;
  height: 50px;
  background-color: #cfe2f3;
  border-radius: 10px;
  border: none;
  margin: 5px;

  font-size: 16px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
`
const DateImgPanel= styled.div`
  width: 100%;
  height: 50px;
  background-color: #cfe2f3;
  border-radius: 10px;
  border: none;
  margin: 5px;

  font-size: 16px;
  font-weight: bold;

  display: flex; /* 縦並び */
  flex-direction: row;
`


const NamePanel= styled.div`
  width: 200px;
  height: 35px;
  background-color: #cfe2f3;
  border-radius: 10px;
  border: none;
  margin: 5px;

  font-size: 16px;
  text-align: center;
  vertical-align: middle;
`

const ValuePanel = styled.div`
  width: 100%;
  height: 35px;
  background-color: #cfe2f3;
  border-radius: 10px;
  border: none;
  margin: 5px;

  font-size: 16px;
`

const DatePanelContent = styled.div`
  margin-top: 10px;
`
const DateNextPanelContent = styled.div`
  margin:10px 50% 0 30%;
`
const DateBackPanelContent = styled.div`
  margin:10px 32.5% 0 37.5%;
`


const PanelContent = styled.div`
  margin-top: 10px;
`

function getQuantity(quantityPlan) {
  if (quantityPlan === undefined || quantityPlan === null || quantityPlan.quantity === undefined || quantityPlan.quantity === null) {
    return '0';
  }

  return LogisticUtility.getCommaText(quantityPlan.quantity);
}
function getPlanQuantity(quantityPlan) {
  if (quantityPlan === undefined || quantityPlan === null || quantityPlan.planQuantity === undefined || quantityPlan.planQuantity === null) {
    return '0';
  }
  return LogisticUtility.getCommaText(quantityPlan.planQuantity);
}

const PlanWeekly = (props) => {
  const {
    onClick,
    dates,
  } = props
  const dispatch = useDispatch();
  //const alert = useAlert();
  const intervalRef = useRef(null);
  const [quantityPlan0,setQuantityPlan0] = useState(null);
  const [quantityPlan1,setQuantityPlan1] = useState(null);
  const [quantityPlan2,setQuantityPlan2] = useState(null);
  const [quantityPlan3,setQuantityPlan3] = useState(null);
  const [quantityPlan4,setQuantityPlan4] = useState(null);
  const [quantityPlan5,setQuantityPlan5] = useState(null);
  const [quantityPlan6,setQuantityPlan6] = useState(null);

  // 物量計画情報の取得
  const apiGetQuantityPlanDaily = async (isLoadSplinner, targetYMD,num) => {
    //console.log('call apiGetQuantityPlanDaily')

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true))

    const apiUrl = DefaultUrl + '/getQuantityWeeklySummaryInfo';
    const param = {
      workplaceId: '000011',
      targetYMD: targetYMD,
    }

    const result = await GetApi(apiUrl, param);
    console.log(result)
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('物量計画情報取得失敗');
      if (num === 0)
        setQuantityPlan0(null);
      else if (num === 1)
        setQuantityPlan1(null);
      else  if (num === 2)
        setQuantityPlan2(null);
      else  if (num === 3)
        setQuantityPlan3(null);
      else if (num === 4)
        setQuantityPlan4(null);
      else if (num === 5)
        setQuantityPlan5(null);
      else if (num === 6)
        setQuantityPlan6(null);
    } else {
      //console.log(result);
      if (num === 0)
        setQuantityPlan0(result.data);
      else if (num === 1)
        setQuantityPlan1(result.data);
        else  if (num === 2)
        setQuantityPlan2(result.data);
      else  if (num === 3)
        setQuantityPlan3(result.data);
      else if (num === 4)
        setQuantityPlan4(result.data);
      else if (num === 5)
        setQuantityPlan5(result.data);
      else if (num === 6)
        setQuantityPlan6(result.data);
    }
  }

  // 周期初期のクリア
  const onClearInerval = async () => {
    if (intervalRef.current !== null)
      clearInterval(intervalRef.current);
    
    intervalRef.current = null;
  }

  // 描画時に一度だけ実行
  useEffect(() => {

  }, [])
  // 描画時に一度だけ実行
  useEffect(() => {
    let index = 0;
    for (const date of dates) {
      apiGetQuantityPlanDaily(false, date.dateValue, index);
      index++;
    }
    intervalRef.current = setInterval(() => {
      let index = 0;
      for (const date of dates) {
        apiGetQuantityPlanDaily(false, date.dateValue, index);
        index++;
      }
    }, 1000 * 60); // 60秒間隔で変更
    
    return () => {
      onClearInerval();
    };
  }, [dates])

  if (!dates) return <></>
  return (
    <Wrap>
      <Flex>
          <Title>物量計画<br/>Weekly</Title>
          <Content>
            {dates.map((item,index) => (
              <>    
              {index == 0 &&
                <DateImgPanel style={{color:LogisticUtility.getDateColor(item)}}>
                  <span><img src={IconPageBackImg} style={{marginTop: '5px',filter: ''}} onClick={()=>onClick(-7)}/></span>
                  <DateNextPanelContent>
                    {LogisticUtility.getStringFromDate(new Date(item.dateValue), 'MM/DD')}<br/>({item.week})
                  </DateNextPanelContent>
                </DateImgPanel>
              }          
              {index != 0 && index!=dates.length-1 &&
                <DatePanel style={{color:LogisticUtility.getDateColor(item)}}>
                  <DatePanelContent>
                    {LogisticUtility.getStringFromDate(new Date(item.dateValue), 'MM/DD')}<br/>({item.week})
                  </DatePanelContent>
                </DatePanel>
              }
              {index==dates.length-1 &&
                <DateImgPanel style={{color:LogisticUtility.getDateColor(item)}}>
                  <DateBackPanelContent>
                    {LogisticUtility.getStringFromDate(new Date(item.dateValue), 'MM/DD')}<br/>({item.week})
                  </DateBackPanelContent>
                  <span><img src={IconPageNextImg} style={{marginTop: '5px',filter: ''}} onClick={()=>onClick(7)}/></span>
                </DateImgPanel>
              }
              </>
            ))}
          </Content>
      </Flex>
      <Flex>
          <NamePanel style={{backgroundColor:'whitesmoke', fontWeight:'bold'}}><PanelContent>予定物量</PanelContent></NamePanel>
          <Content>
              <ValuePanel style={{backgroundColor:'whitesmoke', fontWeight:'bold'}}><PanelContent>{getPlanQuantity(quantityPlan0)}</PanelContent></ValuePanel>
              <ValuePanel style={{backgroundColor:'whitesmoke', fontWeight:'bold'}}><PanelContent>{getPlanQuantity(quantityPlan1)}</PanelContent></ValuePanel>
              <ValuePanel style={{backgroundColor:'whitesmoke', fontWeight:'bold'}}><PanelContent>{getPlanQuantity(quantityPlan2)}</PanelContent></ValuePanel>
              <ValuePanel style={{backgroundColor:'whitesmoke', fontWeight:'bold'}}><PanelContent>{getPlanQuantity(quantityPlan3)}</PanelContent></ValuePanel>
              <ValuePanel style={{backgroundColor:'whitesmoke', fontWeight:'bold'}}><PanelContent>{getPlanQuantity(quantityPlan4)}</PanelContent></ValuePanel>
              <ValuePanel style={{backgroundColor:'whitesmoke', fontWeight:'bold'}}><PanelContent>{getPlanQuantity(quantityPlan5)}</PanelContent></ValuePanel>
              <ValuePanel style={{backgroundColor:'whitesmoke', fontWeight:'bold'}}><PanelContent>{getPlanQuantity(quantityPlan6)}</PanelContent></ValuePanel>
          </Content>
      </Flex>
      <Flex>
          <NamePanel style={{backgroundColor:'lightcoral', fontWeight:'bold'}}><PanelContent>実績物量</PanelContent></NamePanel>
          <Content>
              <ValuePanel style={{backgroundColor:'lightcoral', fontWeight:'bold'}}><PanelContent>{getQuantity(quantityPlan0)}</PanelContent></ValuePanel>
              <ValuePanel style={{backgroundColor:'lightcoral', fontWeight:'bold'}}><PanelContent>{getQuantity(quantityPlan1)}</PanelContent></ValuePanel>
              <ValuePanel style={{backgroundColor:'lightcoral', fontWeight:'bold'}}><PanelContent>{getQuantity(quantityPlan2)}</PanelContent></ValuePanel>
              <ValuePanel style={{backgroundColor:'lightcoral', fontWeight:'bold'}}><PanelContent>{getQuantity(quantityPlan3)}</PanelContent></ValuePanel>
              <ValuePanel style={{backgroundColor:'lightcoral', fontWeight:'bold'}}><PanelContent>{getQuantity(quantityPlan4)}</PanelContent></ValuePanel>
              <ValuePanel style={{backgroundColor:'lightcoral', fontWeight:'bold'}}><PanelContent>{getQuantity(quantityPlan5)}</PanelContent></ValuePanel>
              <ValuePanel style={{backgroundColor:'lightcoral', fontWeight:'bold'}}><PanelContent>{getQuantity(quantityPlan6)}</PanelContent></ValuePanel>
          </Content>
      </Flex>
    </Wrap>
  )
}

PlanWeekly.propTypes = {
  grants: PropTypes.object, // 権限関連
  dates: PropTypes.array, // 日付情報(1週分)
}

export default PlanWeekly

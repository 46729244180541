/* eslint-env browser */
import React, { useState, useEffect } from 'react'
import { Controller} from 'react-hook-form'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ja from 'date-fns/locale/ja'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import CalenderIcon from '../../../image/svg/CalenderIcon.js'
import { HiddenInput } from '../CommonInputGroup'

registerLocale('ja', ja)

// 共通検索用
const WrapDiv = styled.div`
  position: relative;
  margin: ${props => props.margin || '0 16px'};
  .react-datepicker-popper{
    z-index: 2;
  }
  .react-datepicker__current-month {
    display: none;
  }
  .react-datepicker__month-select, .react-datepicker__year-select {
    border: none;
    background: #eee;
    height: 20px;
    line-height: 20px;
  }

  .normalCalendar {
    height: 32px;
    display: ${props => props.display || 'block'};
    position: relative;
    text-indent: 16px;
    width: ${props => props.width || '160px'};
    min-width: ${props => props.width || '160px'};
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.05em;
    background: ${props => props.disabled ? '#eee' : '#fff'};
    border-radius: 5px;
    border: none;
    line-height: 16px;
    box-shadow: inset 0 3px 5px 0 rgba(0,0,0,0.35);
    color: #333;
    z-index: 1;
    &:focus {
      background: #fff;
      outline: none;
      border: 2px solid #0036ff;
      box-sizing: border-box;
      text-indent: 14px;
    }
    ::placeholder {
      color: #ccc;
    }
  }
  svg {
    position: absolute;
    top: 5px;
    right: 4px;
    pointer-events: none;
    z-index:2;
  }
  
  ::after{
    display: ${props => props.isViewIcon ? 'block' : 'none'};
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    background: #0036ff;
    cursor: pointer;
    border-radius: 0 5px 5px 0;
    pointer-events: none;
    z-index:1;
  }
`

const Calendar = ({ name, register, dataValue, changeCalendar, placeholder, width, margin, disabled, display, prefix, isViewIcon }) => {
  const [startDate, setStartDate] = useState(dataValue && new Date(dataValue))
  const onCalendarChange = (changeDate) => {
    setStartDate(changeDate)
    localStorage.setItem('normalCalendar', changeDate)
  }
  const onCalendarClose = () => {
    const localDate = localStorage.getItem('normalCalendar')
    if (!localDate || localDate === 'undefined' || localDate === 'null') return null
    const date = new Date(localDate)
    const dateStr = date.getFullYear() + `${prefix || '-'}` + ('00' + (date.getMonth() + 1)).slice(-2) + `${prefix || '-'}` + ('00' + date.getDate()).slice(-2)
    if (dataValue !== dateStr) {
      changeCalendar(dateStr)
    }
    localStorage.removeItem('normalCalendar')
  }
  useEffect(() => {
    setStartDate(dataValue && new Date(dataValue))
  }, [dataValue])

  return (
    <WrapDiv margin={margin} width={width} display={display} isViewIcon={isViewIcon} disabled={disabled}>
      <DatePicker
        className='normalCalendar'
        dateFormat='yyyy/MM/dd'
        locale='ja'
        selected={startDate}
        onChange={changeDate => onCalendarChange(changeDate)}
        onCalendarOpen={() => localStorage.setItem('normalCalendar', startDate)}
        onCalendarClose={() => onCalendarClose()}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode='select'
        disabled={disabled}
        placeholderText={placeholder}
      />
      {isViewIcon && <CalenderIcon />}
      <HiddenInput name={name} register={register} value={dataValue}/>
    </WrapDiv>
  )
}

Calendar.propTypes = {
  name: PropTypes.string,
  register: PropTypes.func,
  dataValue: PropTypes.string,
  changeCalendar: PropTypes.func,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  margin: PropTypes.string,
  disabled: PropTypes.bool,
  display: PropTypes.string,
  prefix: PropTypes.string,
  isViewIcon: PropTypes.bool
}

export default Calendar

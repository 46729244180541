import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { SetLoadSpinner } from '../../../../Common/Slice/LoadSpinnerSlice'
import { GetApi } from '../../../../Common/ApiAxios'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import styled from 'styled-components'
//import Input from '../../../Common/Atoms/Input'
import * as LogisticUtility from '../../../../Common/Utilties/LogisticUtility'
//import * as regex from '../../../Common/Regex'
import CloseIcon from '../../../../../image/close_icon.png'
//import UpdateIcon from '../../../../image/update-icon.png'

import Select from '../../../../Common/Atoms/Select'
import Calendar from '../../../../Common/Atoms/Calendar'

import MediaDataItemEmissions from './MediaDataStoreEmissions'
import MediaHeaderItemEmissions from './MediaHeaderStoreEmissions'
import MediaHeaderSumEmissions from './MediaSumHeaderStoreEmissions'
import MediaHeaderStoreItemEmissions from './MediaHeaderStoreItemEmissions'
//import { Divider } from '@material-ui/core'

const DefaultUrl = '/api/logisticManagement'

/* 横並 */
const Flex = styled.div`
  display: flex; /* 横並び */
  flex-flow: row nowrap;
`

/* 縦並 */
const Wrap = styled.div`
  display:flex; /* 縦並び */
  flex-flow: column;
`

/* 検索項目 */
const SearchItem = styled.div`
  display: flex; /* 横並び */
  flex-flow: row nowrap;
  text-align: left;
  vertical-align: middle;
`
export const ListUl = styled.ul`
  width: ${props => props.width ? props.width : '100%'};
  height: ${props => props.height ? props.height : '400px'};
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    border:none;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(112, 112, 112);
    border-radius: 10px;
  }
`

export const ListLi = styled.li`
  width: ${props => props.width ? props.width : 'calc(100%-18px)'};
  text-indent: 10px;
  background: ${props => props.selected ? '#048b63' : '#fff'};
  color: ${props => props.selected ? '#fff' : '#333'};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  .listId{
    margin-right:1vw;
  }
  :nth-child(even){
    background: ${props => props.selected ? '#048b63' : '#eee'};
    color: ${props => props.selected ? '#fff' : '#333'};
  }
  :hover {
    background: #ccc;
    color: #333;
  }
`

const ResultDialog = (props) => {
  const {
    //grants,
    modalIsOpen,
    setIsOpen,
    searchDate,
    ownerCd,
    itemCd,itemName,
    pattran,
    type,
    baseId,
    workplaceId,
    batchNo
  } = props
  const dispatch = useDispatch();

  const [orgProgressList,setOrgProgressList] = useState([]);
  const [progressList,setProgressList] = useState([]);
  const listSortRef = useRef();
  //const listFilterTypeRef = useRef();
  //const listFilterRatioRef = useRef();

  //const listFlagRef = useRef();
  //const planSoterRef = useRef();
  
  // モーダル
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      //opacity: 0.8,
      padding: '10px',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    },
    overlay: {
      //background: 'rgba(0, 0, 0, 0.7)',
      zIndex: 10
    }
  }

  // パターンの工程情報の取得
  const apiGetProcessPatternInfo = async () => {
    const apiUrl = DefaultUrl + '/getVasShippingPatternStoreItem';
    dispatch(SetLoadSpinner(true));
    var now = new Date(searchDate);
    var now1 = new Date(searchDate);
    now.setDate(now.getDate() + 1);
    const param = {
      baseId: baseId,//2,
      workplaceId: workplaceId,//'000006',
      ownerCd: ownerCd,
      patternName: pattran,
      targetYMD: LogisticUtility.getStringFromDate(now, 'YYYYMMDD'),
      targetDate: LogisticUtility.getStringFromDate(now1, 'YYYY/MM/DD'),
      blockName: '',
      itemCd: itemCd,
    }
    if(pattran == '米飯　BIG北・信'){
      param.blockName = "'ニ','サン','ヨン'"
    }
    if(pattran == '米飯　BIG中松愛'){
      param.blockName = "'イチ'"
    }
    //console.log(param);
    const result = await GetApi(apiUrl, param);

    dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('工程情報取得失敗');
      setOrgProgressList([]);
      setProgressList([]);
    } else {
      setOrgProgressList(result.data);
      var tmp = result.data;
      // 種別
      //if (listFilterTypeRef.current.value == 1) tmp = tmp.filter((item) => item.vasShippingItemInfo.typeName === '米飯');
      //else if (listFilterTypeRef.current.value == 2) tmp = tmp.filter((item) => item.vasShippingItemInfo.typeName === '惣菜');
      // 割合
      //if (listFilterRatioRef.current.value == 1) tmp = tmp.filter((item) => Number(item.vasShippingItemInfo.totalItem) < Number(item.vasShippingItemInfo.totalPlanItem));
      //else if (listFilterRatioRef.current.value == 2) tmp = tmp.filter((item) => Number(item.vasShippingItemInfo.totalItem) >= Number(item.vasShippingItemInfo.totalPlanItem));
      tmp = listSortRef.current.value == 0 ? lowerData(tmp) : upperData(tmp);//:upperData(result.data)
      setProgressList(JSON.parse(JSON.stringify(tmp)));
    }
  }

  const apiGetProcessOwnerInfo = async () => {

    const apiUrl = DefaultUrl + '/getVasShippingStoreItem';
    dispatch(SetLoadSpinner(true));
    var now = new Date(searchDate);
    var now1 = new Date(searchDate);
    now.setDate(now.getDate() + 1);
    const param = {
      baseId: baseId,//2,
      workplaceId: workplaceId,//'000006',
      ownerCd: ownerCd,
      targetYMD: LogisticUtility.getStringFromDate(now, 'YYYYMMDD'),
      targetDate: LogisticUtility.getStringFromDate(now1, 'YYYY/MM/DD'),
      itemCd: itemCd,
      batchNo: batchNo /*2024/04/15 K.Harada ADD 1便,2便を表示するように変更 */
    }
    //console.log(param);
    const result = await GetApi(apiUrl, param);

    dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('工程情報取得失敗');
      setOrgProgressList([]);
      setProgressList([]);
    } else {
      //console.log(result);
      setOrgProgressList(result.data);
      var tmp = result.data;
      tmp = listSortRef.current.value == 0 ? lowerData(tmp) : upperData(tmp);
      
      setProgressList(JSON.parse(JSON.stringify(tmp)));
    }
  }

  //ダイアログ開いた時の処理
  function afterOpenModal () {
    type === 0 ? apiGetProcessOwnerInfo(true) : apiGetProcessPatternInfo(true);
  }

  function closeModal () {
    setIsOpen(false);
    setOrgProgressList([]);
    setProgressList([]);
  }

  const lowerData =(dataList)=> {
    // 昇順
    dataList.map((data)=>{
      data.vasShippingInfo = data.vasShippingInfo.sort(function(a, b) {
        return Number(a.totalItemCount) - Number(b.totalItemCount);
      });
    })
    return dataList
  }

  const upperData =(dataList)=> {
    // 降順
    dataList.map((data)=>{
      data.vasShippingInfo = data.vasShippingInfo.sort(function(a, b) {
        return Number(b.totalItemCount) - Number(a.totalItemCount);
      });
      data.hidden=true;
    })
    return dataList
  }

  useEffect(() => {
  }, [])

  //ソートの変更イベント
  const onChangeSort = ( event ) => {
    //event.target.value)

    if(event.target.value==0){ 
      setProgressList(JSON.parse(JSON.stringify(lowerData(progressList))));
    }else{
      setProgressList(JSON.parse(JSON.stringify(upperData(progressList))));
    }
  }

  //商品の詳細リストの開閉イベント
  const onClickDisplay = ( index ) => {
    progressList[index].display = !progressList[index].display;
    setProgressList(JSON.parse(JSON.stringify(progressList)));
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel='VAS Item Modal'
      ariaHideApp={false}
    >
      <div style={{ width:'850px', height:'600px', marginLeft:'1rem' }}>
        <Wrap>
          <Flex>
            <div style={{ height:'20px', marginRight:'10px', marginTop:'10px', cursor: 'pointer'}}>
            </div>
            <div style={{ height:'20px', marginLeft:'auto', marginRight:'10px', marginTop:'10px', cursor: 'pointer'}}>
              <img alt='閉じる' className='close' src={CloseIcon} title={'ダイアログを閉じる'} width="12px" onClick={closeModal} />
            </div>
          </Flex>
          <Wrap>
            <SearchItem style={{marginTop:'0.5rem'}}>
              <label style={{marginTop:'auto', marginBottom:'auto'}}>日付</label>
              <Calendar  dataValue={searchDate} disabled={true}/>
              <Select selectRef={listSortRef} onChange={(e)=>onChangeSort(e)} width={'11rem'}>
                <option key={'sort_0'} value={0} selected={true}>{'昇順'}</option>
                <option key={'sort_1'} value={1} >{'降順'}</option>
              </Select>
            </SearchItem>
            <SearchItem style={{marginTop:'3rem'}}>
              <div style={{width:'770px', marginLeft:'40px', textAlign:'left'}}>
                <MediaHeaderItemEmissions />
                <ListUl width={'100%'} height={'383px'}>
                {progressList.map((itemList,index) => (
                  <>
                    <ListLi key={itemList.itemCd + '_' + itemList.itemName} style={{cursor: 'default'}}>
                      <MediaHeaderSumEmissions progressList={itemList} onClickImg={()=>onClickDisplay(index)} itemCd={itemCd}itemName={itemName}/>
                    </ListLi>
                    {itemList.display &&
                      <ListLi key={itemList.itemCd + '_' + itemList.itemName+'_header'} style={{cursor: 'default'}}>
                        <MediaHeaderStoreItemEmissions />
                      </ListLi>   
                    }
                    {itemList.display &&
                    itemList.vasShippingInfo.map(item=>(
                      <ListLi key={item.cd + '_' + item.name} style={{cursor: 'default'}}>
                      <MediaDataItemEmissions data={item} searchDate={searchDate} headerData={itemList}/>
                      </ListLi>
                    ))
                    }
                  </>
                ))}
                </ListUl>
              </div>
            </SearchItem>
          </Wrap>
        </Wrap>
      </div>
    </Modal>
  )
}

ResultDialog.propTypes = {
  modalIsOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
}

export default ResultDialog

import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'

import OwnerGraph from './Atoms/OwnerGraph'
//import PatternGraph from './Atoms/PatternGraph'

const BodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  vertical-align: middle;
  height: 320px;
`
const ContentWrap = styled.div`
  display: flex; /* 横並び */
  flex-direction: column;
  vertical-align: middle;

  width: 100%;
  height: 650px;
  padding:0.5rem;
`

const ShootPerformance = (props) => {
  const {
    searchDate,
    progressList,
    patternList,
    ownerList,
    onChange,
    batchNo,
  } = props
  const intervalRef = useRef(null);
  //データ数
  const dataLength = 12
  const[opacity,setOpacity]=useState(true)
    // 周期初期のクリア
    const onClearInerval = async () => {
      if (intervalRef.current !== null)
        clearInterval(intervalRef.current);
      
      intervalRef.current = null;
    }
  
    // 描画時に一度だけ実行
    useEffect(() => {
      intervalRef.current = setInterval(() => {
        setOpacity((opacity) => !opacity)
      }, 1000 * 2); // 2秒間隔で変更
      return () => {
        onClearInerval();
      };
    }, [])
  return (
    <ContentWrap>
      <BodyContent>
        {ownerList && ownerList.map((entry, index) => 
          {  
            if(entry.ownerCd <= 4 && batchNo == entry.batchNo){
              return (
                <>
                  <OwnerGraph entry={entry} key={'chute_'+index} opacity={opacity} width={'100%'} searchDate={searchDate} progressList={progressList} onChange={onChange}/>
                  {/*パターンリストは使わないのでクローズ */}
                  {/* <>
                  {patternList && patternList.map((entry1, index1) => 
                    {  
                      if(entry1.ownerCd == entry.ownerCd && entry.ownerCd == '1' && entry1.patternCd != 'SOUZAI' && entry.batchNo == '1'){
                        return (
                          <PatternGraph entry={entry1} patternList={patternList} key={'pattran_'+index1} opacity={opacity} width={'90%'} searchDate={searchDate} progressList={progressList} onChange={onChange}/>
                        )   
                      }
                    })
                  }
                  </> */}
                </>
                )    
            }
          })
        }
      </BodyContent>
    </ContentWrap>
  )
}
ShootPerformance.propTypes = {
}

export default ShootPerformance

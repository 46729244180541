import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'
import { PostApi } from '../../../Common/ApiAxios'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import styled from 'styled-components'
import Input from '../../../Common/Atoms/Input'
import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'
//import * as regex from '../../../Common/Regex'
import CloseIcon from '../../../../image/close_icon.png'
import UpdateIcon from '../../../../image/update-icon.png'
import { format48Time } from '../../../Common/Utilties/LaborUtility'
import Calendar from '../../../Common/Atoms/Calendar'

const DefaultUrl = '/api/logisticManagement'

/* 横並 */
const Flex = styled.div`
  display: flex; /* 横並び */
  flex-flow: row nowrap;
`

/* 縦並 */
const Wrap = styled.div`
  display:flex; /* 縦並び */
  flex-flow: column;
`

/* 検索項目 */
const SearchItem = styled.div`
  display: flex; /* 横並び */
  flex-flow: row nowrap;
  text-align: left;
  vertical-align: middle;
`

const PlanDateDialog = (props) => {
  const {
    //grants,
    modalIsOpen,
    setIsOpen,
    workplaceId,
    ownerCd,
    baseId,
    patternCd,
    endTime,
    startTime,
    onChange
  } = props
  const dispatch = useDispatch();
  const [searchDate, setSearchDate] = useState(LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD')); // 検索欄の日付
  const planAllRef = useRef();
  const planSoterRef = useRef();
  
  // モーダル
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      //opacity: 0.8,
      padding: '10px',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    },
    overlay: {
      //background: 'rgba(0, 0, 0, 0.7)',
      zIndex: 10
    }
  }

  //ダイアログ開いた時の処理
  function afterOpenModal () {
    planAllRef.current.value = startTime
    //setSearchDate(LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD'))
  }

  function closeModal () {
    onChange()
      setIsOpen(false);
  }
  
  //日付けを変更した時の処理
  const changeCalendar = (dateStr) => {
    if (!dateStr) {
      if (setSearchDate && setSearchDate(null))
        return null;
    }
    if (searchDate !== dateStr) {
      {setSearchDate && setSearchDate(dateStr)}
    }
  }

  const onClickUpdate = async (isLoadSplinner) => {
    if(isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    var date = new Date(searchDate);
    var week = date.getDay();
    const apiUrl = DefaultUrl + '/updateVasDailyTime';

    const param = {
      workplaceId: workplaceId,
      targetYMD: searchDate,
      baseId:baseId,
      ownerCd: ownerCd,
      patternCd: patternCd,
      week: week,
      startTime: planAllRef.current.value,
      endTime: endTime,
    }
    //console.log(param)
    const result = await PostApi(apiUrl, param);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
    } else {
      closeModal();
    }
  }

  useEffect(() => {
  }, [])

  //数値をカンマ区切りに変更
  const onBlur = ( event ) => {
    let value = event.target.value
    value = format48Time(value)
    event.target.value = value
    
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel='Create Zone Modal'
      ariaHideApp={false}
    >
      <div style={{ width:'300px', height:'350px', marginLeft:'1rem' }}>
        <Wrap>
          <Flex>
            <div style={{ height:'20px', marginRight:'10px', marginTop:'10px', cursor: 'pointer'}}>
              <img alt='更新' className='update' src={UpdateIcon} title={'更新'} width="16px" onClick={onClickUpdate}/>
            </div>
            <div style={{ height:'20px', marginLeft:'auto', marginRight:'10px', marginTop:'10px', cursor: 'pointer'}}>
              <img alt='閉じる' className='close' src={CloseIcon} title={'ダイアログを閉じる'} width="12px" onClick={closeModal} />
            </div>
          </Flex>
          <Wrap>
            <SearchItem style={{marginTop:'0.5rem'}}>
              <label style={{marginTop:'auto', marginBottom:'auto'}}>日付</label>
              <Calendar changeCalendar={changeCalendar} dataValue={searchDate} disabled/>
            </SearchItem>
            <SearchItem style={{marginTop:'3rem'}}>
            <label style={{marginTop:'auto', marginBottom:'auto',width:'7rem'}}>作業開始時間</label>
            <div style={{marginLeft:'10px'}}>
                <Input
                  type='text' 
                  width='10rem'
                  placeholder='作業開始時間'
                  name='planAll'
                  maxLength='5'
                  inputRef={planAllRef} 
                  onBlur={onBlur}
                  defaultValue={'10:30'}
                />
            </div>
            </SearchItem>
          </Wrap>
        </Wrap>
      </div>
    </Modal>
  )
}

PlanDateDialog.propTypes = {
  modalIsOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
}

export default PlanDateDialog

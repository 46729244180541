import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ReTooltip from '@material-ui/core/Tooltip'
import {PolarGrid,PolarAngleAxis,PolarRadiusAxis,Radar,ResponsiveContainer,RadarChart,Tooltip} from "recharts"

import IconGearImg from '../../../../../image/gear-icon.png'
import {BoardBaseFormRow,BoardTitle} from '../../../Wrap/BoardBaseWrap'

const COLORS = ['blue', 'red', 'gray', 'green','orange','purple','cyan','navy','maroon','crimson'];

const PalDashboardGraph= ({ title,data,keys, onClick}) => {        
  const [showMenu,setShowMenu] = useState('none');

  const CustomTooltip = ({ active, payload, label }) => {
    //var i=-1
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{backgroundColor:'white',padding:'10px'}}>
          <p className="desc">{label}</p>
          {payload.map((pay)=>{
            //i++
            return <p className={"label"+pay.name} style={{color:`${pay.color}`}}>{`${pay.name} : ${parseFloat(pay.value).toFixed(2)}${'%'}`}</p>
          })
          }
        </div>
      );
    }
    return null;
  };

  // 初期化メソッド
  useEffect(() => {
  //console.log(title)

  // 初回描画時のみ実行
  // eslint-disable-next-line
  }, [])

  return (
    <BoardBaseFormRow onMouseEnter={()=>setShowMenu('block')} onMouseLeave={()=>setShowMenu('none')} >
      <div>
        <div style={{display: 'flex',textAlign:'center' }}>
          <BoardTitle>{title}</BoardTitle>
          <div></div>
          <ReTooltip title={'設定'}>
            <img alt='設定' src={IconGearImg} width="24px" height="24px" style={{marginTop: '4px',marginLeft: 'auto', cursor: 'pointer',display: showMenu}} onClick={onClick} />
          </ReTooltip>
        </div>
        <ResponsiveContainer 
          width={930}
          height={340}
          margin={{
              top: 0, right: 30, left: 0, bottom: 5,
          }}
        >
        <RadarChart cx="50%" cy="50%" outerRadius="90%" data={data}>
          <PolarGrid />
          <PolarAngleAxis dataKey="rateName" />
          <PolarRadiusAxis angle={90-360/data.length} domain={[0,100]}/>
          {data && keys.map((workplace,index) => {
            return (
              <Radar name={workplace} dataKey={workplace} stroke={COLORS[index%COLORS.length]} fill={COLORS[index%COLORS.length]} fillOpacity={0} strokeWidth={2}/>
            )
          })
          }
          {/* <Legend/> */}
          <Tooltip content={<CustomTooltip/>}/>
        </RadarChart>
        </ResponsiveContainer >
      </div>
    </BoardBaseFormRow>
  )
}

PalDashboardGraph.propTypes = {
  salariedList: PropTypes.array,
  title: PropTypes.string,
}

export default PalDashboardGraph

import React, { useEffect } from 'react'
//import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'

//import { GetApi } from '../../../Common/ApiAxios'
import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'
//import { toHaveAttribute } from '@testing-library/jest-dom'

//const DefaultUrl = '/api/logisticManagement'

const CardWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: ${props => props.width ? props.width : '100%'};
  height: 100%;
  background-color: #1155cc;
  border-radius: 10px;
  border: none;

  color: #fff;

  margin-bottom: 10px;
`

const HeaderContent = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 100%;
  height: 40%;
  text-align:center;
  vertical-align:middle;
`

const Title = styled.span`
  width: 100%;
  font-size: ${props => props.fontSize ? props.fontSize : '2.2vw'};
  font-weight: bold;
  margin:auto;
`

const BodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 60%;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
`

// 通常項目
const NormalItem = styled.span`
  font-size: 1.9vw;
  font-weight: bold;
`

// 小項目
const SmallItem = styled.span`
  font-size: 1.5vw;
  font-weight: bold;
`

// 通常項目
const NormalItem1 = styled.span`
  font-size: 1.3vw;
  font-weight: bold;
`

// 小項目
const SmallItem1 = styled.span`
  font-size: 1.0vw;
  font-weight: bold;
`

function getTitle(type) {
  var title = '';
  switch(type) {
    case 0:
      title = '物量予実';
      break;
    case 1:
      title = '売上予実';
      break;
    case 2:
      title = '工数予実';
      break;
    case 3:
      title = '外注費';
      break;
    case 4:
      title = '週間総工数';
      break;
    case 5:
      title = '平均生産性';
      break;
    case 6:
      title = 'カゴ車台数';
      break;
    case 7:
      title = 'パレット数';
      break;
    case 8:
      title = '事故発生件数';
      break;
    case 9:
      title = '事故弁済額';
      break;
    default:
      title = '';
      break;
  }

  return title;
}

function getValue(totalData, type) {
  var text = '';
  switch(type) {
    case 0:
      if (totalData !== null)
        text = LogisticUtility.getCommaText(Number(totalData).toFixed(0));
      break;
    case 1:
      if (totalData !== null)
        text = LogisticUtility.getCommaText(Number(0).toFixed(2))+'円';
      break;
    case 2:
      if (totalData !== null)
      text = LogisticUtility.getCommaText(Number(totalData).toFixed(2))+'h';
      break;
    case 3:
      if (totalData !== null)
      text = LogisticUtility.getCommaText(Number(totalData).toFixed(0))+'円';
      break;
    case 4:
      if (totalData && totalData !== null)
      text = LogisticUtility.getCommaText(Number(totalData).toFixed(2))+'h';
      break;
    case 5:
      if (totalData && totalData !== null)
      text = LogisticUtility.getCommaText(Number(totalData).toFixed(2))+'';
      break;
    case 6:
      if (totalData !== null)
      text = LogisticUtility.getCommaText(Number(0).toFixed(2))+'台';
      break;
    case 7:
      if (totalData !== null)
      text = LogisticUtility.getCommaText(Number(0).toFixed(2))+'pl';
      break;
    case 8:
      if (totalData !== null)
      text = LogisticUtility.getCommaText(Number(0).toFixed(2))+'件';
      break;
    case 9:
      if (totalData !== null)
      text = LogisticUtility.getCommaText(Number(0).toFixed(2))+'円';
      break;
    case 10:
      if (totalData && totalData !== null)text = LogisticUtility.getCommaText(Number(totalData).toFixed(2));
      else text = LogisticUtility.getCommaText(Number(0).toFixed(2));
      break;
    default:
      text = '';
      break;
  }

  return text;
}

function getRemainingTime(totalData) {
  var text = '';
  
  //console.log('call getRemainingTime');
  //console.log(totalData);
  if (totalData !== null) {
    let date1 = new Date(LogisticUtility.getStringFromDate(new Date(), 'YYYY/MM/DD hh:mm:00'));
    let date2 = new Date(totalData.planEndAt);
    
    let diff = date2.getTime() - date1.getTime();
    let totalMinues = Math.floor(diff / (60*1000));
    //console.log(totalMinues);
    if (totalMinues > 0) {
      let hour = Math.floor(totalMinues / 60);
      let minues = totalMinues - (hour * 60);

      text = ('00' + hour).slice(-2) + ":" + ('00' + minues).slice(-2)
    }
    else {
      text = '00:00';
    }
  }

  return text;
}

// ヘッダー部のカード
const CardTotal = (props) => {
  const {
    grants,
    totalData,
    type,
  } = props
  //const alert = useAlert();
  //const interval1Ref = useRef(null);

  // 描画時に一度だけ実行
  useEffect(() => {
  }, [])

  //if (!grants || !totalData) return null
  return (
    <CardWrap width={type<=3?'100%':'300px'}>
      <HeaderContent>
          <Title fontSize={type<=3?'2.2vw':'1.5vw'}>{getTitle(type)}</Title>
      </HeaderContent>
      <BodyContent>
          {type === 0 &&
          <>
              <NormalItem>{getValue(totalData.quantity, type)}</NormalItem>
              <SmallItem style={{paddingTop:'0.5rem'}}>（予測：{getValue(totalData.planQuantity, type)}）</SmallItem>
          </>
          }
          {type === 1 &&
          <>
              <NormalItem>{getValue(totalData, type)}</NormalItem>
              <SmallItem style={{paddingTop:'0.5rem'}}>（予測：{getValue(totalData, type)}）</SmallItem>
          </>
          }
          {type === 2 &&
          <>
              <NormalItem>{getValue(totalData.workTime, type)}</NormalItem>
              <SmallItem style={{paddingTop:'0.5rem'}}>（予測：{getValue(totalData.planWorkTime, type)}）</SmallItem>
          </>
          }
          {type === 3 &&
          <>
              <NormalItem>{getValue(totalData.outsourcingProgressValue, type)}</NormalItem>
              <SmallItem style={{paddingTop:'0.5rem'}}>(前週比 {getValue(totalData.beforeOutsourcingProgressValue, 10)} ％)</SmallItem>
          </>
          }
          {type === 4 &&
          <>
              <NormalItem1>{getValue(totalData.workTime, type)}</NormalItem1>
              <SmallItem1 style={{paddingTop:'0.5rem'}}>(前週比 {getValue(totalData.workTimeRate, 10)} ％)</SmallItem1>
          </>
          }
          {type === 5 &&
          <>
              <NormalItem1>{getValue(totalData.productivity, type)}</NormalItem1>
              <SmallItem1 style={{paddingTop:'0.5rem'}}>(前週比 {getValue(totalData.productivityRate, 10)} ％)</SmallItem1>
          </>
          }
          {type === 6 &&
          <>
              <NormalItem1>{getValue(totalData, type)}</NormalItem1>
              <SmallItem1 style={{paddingTop:'0.5rem'}}>(前週比 000.00 %)</SmallItem1>
          </>
          }
          {type === 7 &&
          <>
              <NormalItem1>{getValue(totalData, type)}</NormalItem1>
              <SmallItem1 style={{paddingTop:'0.5rem'}}>(前週比 000.00 %)</SmallItem1>
          </>
          }
          {type >= 8 &&
          <>
              <NormalItem>{getValue(totalData, type)}</NormalItem>
              <SmallItem style={{paddingTop:'0.5rem'}}>(前週比 000.00 %)</SmallItem>
          </>
          }
      </BodyContent>
    </CardWrap>
  )
}

CardTotal.propTypes = {
  grants: PropTypes.object, // 権限関連
  totalData: PropTypes.object, // 集計情報
  type: PropTypes.number, // 0:全体進捗率 1:終了予定時刻 2:全体稼働率 3:事故発生件数 4:投資可能額
}
  
export default CardTotal

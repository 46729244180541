import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import PageBack from '../../../../image/PageBack.png'
import PageNext from '../../../../image/PageNext.png'
import IconDownloadImg from '../../../../image/download-icon.png'
const MediaWrap = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  color: #000;
  background-color: #fff;
  font-size:  0.8vw;
  text-align: right;
  align-items: center;
  width: 1764px;
  margin: 4px;
  box-shadow: 0 0 3px 2px ${props => props.selected ? 'rgba(0,128,0,0.8)' : 'rgba(0,0,0,0.4)'};
`
const MediaItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 40px;
`

const TextWrap = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
` 

const MaterialHandlingItem = (props) => {
  const {
    page,
    dataLength,
    onClickNext,
    onClickBack
  } = props

  // 初期値
  useEffect(() => {
  })

  //if (!data) return null;
  //console.log(data);
  return (
    <MediaWrap>
      <Tooltip title={'CSVダウンロード'}>
        <div><img alt='ダウンロード' src={IconDownloadImg} width="32px" height="32px" style={{marginLeft:'10px', cursor: 'pointer'}}/></div>
      </Tooltip>
      <div style={{marginLeft: 'auto'}}/>
      <MediaItem>
        <TextWrap style={{marginLeft:'auto',textAlign:'right'}}>
          <div>{page*16+1}-{page*16+16<dataLength?page*16+16:dataLength}／{dataLength}</div>
        </TextWrap>
      </MediaItem>
      <img alt='戻る' src={PageBack} width="32px" height="32px" style={{marginLeft:'10px',marginTop: '4px', cursor: 'pointer'}} onClick={onClickBack}/>
      <img alt='進む' src={PageNext} width="32px" height="32px" style={{marginLeft:'20px',marginTop: '4px', cursor: 'pointer'}} onClick={onClickNext}/>
    </MediaWrap>
  )
}

MaterialHandlingItem.propTypes = {
}

export default MaterialHandlingItem

import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
//import FUNC from '../../Common/FunctionEynum'
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'

import { GetApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import * as LogisticUtility from '../../Common/Utilties/LogisticUtility'
//import ErrorHandling from '../../Common/ErrorHandling'

import {XAxis, YAxis, Tooltip, Legend, Line,Area, Bar,ComposedChart } from "recharts"

const DefaultUrl = '/api/logisticManagement'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 85vh;
  text-align: center;
  vertical-align: middle;
`

const ContentBody = styled.div`
  display: grid;
  grid-gap: 30px 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row: 1 / 2;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
`

const BodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  overflow: hidden;
  vertical-align: middle;
  width: 300px;
  height: 90%;
  box-shadow: 2px 2px 6px rgba(0,0,0,.4);
`

const Process = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
 
  background-color: #fff;
  text-align:center;
  vertical-align:middle;
  margin-top: 5px;
  margin-bottom: 5px;
`

const ProcessRow = styled.div`
  display: inline-block;
  color: #535353;
  margin-top: 2px;
  border-bottom: 1px solid #D9D5C9;
  width: 210px;
`

const ProcessName = styled.span`
  display: inline-block;
  font-weight: bold;
  color: #000;
  text-align: right;
  font-size: 1.2rem;
  width: 120px;
`

const ProcessSubItem = styled.span`
  display: inline-block;
  color: #535353;
  text-align: left;
`

const ProcessTitle = styled.div`
  font-weight: bold;
  color: #000;
  text-align: center;
  font-size: 1.2rem;
`
const ProgressGraphBoard = (props) => {
  //const { loginUserInfo } = useSelector(state => state);

  const dispatch = useDispatch();
  const alert = useAlert();
  const [processList,setProcessList] = useState([]);
  const [remainingWorkTimeList,setRemainingWorkTimeList] = useState([]);
  const intervalRef = useRef(null);

  // 進捗設定情報の取得
  const apiGetActivityProgressManagement = async (isLoadSplinner) => {
    //console.log('call getActivityProgressManagement');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getActivityProgressManagement';
   
    const data = {
      workplaceId: '000011',
      targetYMD: LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD'),
    }
    const result = await GetApi(apiUrl, data);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      //alert.error('条件の該当データがありません(進捗設定情報の取得)');
    } else {
      //console.log(result.data);
      setProcessList(result.data);
      makeRemaining(result.data);
    }
  }
  
  //x軸のデータ  
  const ticks = ['07:00','08:00','09:00','10:00','11:00','12:00',
                 '13:00','14:00','15:00','16:00','17:00','18:00','19:00']

  // 
  const makeRemaining = async (list) => {
    if(!list) return
    var remainingList = []
    var date = new Date();
    var hour = date.getHours();
    list.map((process)=>{
      if(process.timeZone.slice(-2) ==='00' && process.timeZone.slice(-5,-3) >= hour){
        remainingList.push(
          <ProcessRow>
            <ProcessSubItem>{process.timeZone}</ProcessSubItem>
            <ProcessName>{process.remainingWorkTime.toFixed(2)}</ProcessName>
            <ProcessSubItem>h</ProcessSubItem>
          </ProcessRow>
        )
      }
    })

    setRemainingWorkTimeList(remainingList)
  }

  // 描画時に一度だけ実行
  // useEffect(() => {
  //   apiGetActivityProgressManagement();
  // }, []);
  // 周期初期のクリア
  const onClearInerval = async () => {
    if (intervalRef.current !== null)
      clearInterval(intervalRef.current);
    
    intervalRef.current = null;
  }

  // 描画時に一度だけ実行
  useEffect(() => {
    apiGetActivityProgressManagement()

    intervalRef.current = setInterval(() => {
      apiGetActivityProgressManagement()
    }, 1000 * 60); // 60秒間隔で変更
    
    return () => {
      onClearInerval();
    };
  }, [])

  return (
    <>
      <ContentWrap>
        <ContentBody>
        <ComposedChart 
          width={1690}
          height={370}
          data={processList}
          margin={{top: 50, right: 0, left: 0, bottom: 5}}>
            <XAxis xAxisId={1} dataKey="timeZone" ticks={ticks}/>
            <YAxis yAxisId={1}  domain={[0, 800]} tickCount={9} label={{ value: "物量",fontSize:'24px', angle: -90, dx: -20 }} />
            <Tooltip />
            <Bar type="bar" xAxisId={1} yAxisId={1} strokeWidth={2} dataKey="progressReject" name="リジェクト数" fill="#EC7A08" />      
            <Bar type="bar" xAxisId={1} yAxisId={1} strokeWidth={2} dataKey="progressOverflow" name="オーバーフロー数" fill="#CC0001" />  
            <Line type="plainline" xAxisId={1} yAxisId={1} strokeWidth={2} dataKey="planQuantity" name="予定物量" stroke="#3F9C34" />      
            <Line type="plainline" xAxisId={1} yAxisId={1} strokeWidth={2} dataKey="progressQuantity" name="実績物量" stroke="#295D8C" />  
            <Legend/>
        </ComposedChart >
        </ContentBody>
        <ContentBody>
        <ComposedChart 
          width={1390}
          height={370}
          data={processList}
          margin={{top: 15, right: 0, left: 0, bottom: 30}}>
            <XAxis xAxisId={1} dataKey="timeZone"  ticks={ticks}/>
            <YAxis yAxisId={1} domain={[0, 30]} tickCount={16} label={{ value: "人数",fontSize:'24px', angle: -90, dx: -20 }}/>
            <Tooltip />
            <Area xAxisId={1} yAxisId={1} type="step" name="就業人数" dataKey="staffCnt" stroke="#CC0001" fill="#CC0001" />
            <Legend/>
        </ComposedChart >
        <BodyContent>
          <Process>
            <div>
              <ProcessTitle>残工数</ProcessTitle>
              {remainingWorkTimeList}
            </div>
          </Process>
        </BodyContent>
        </ContentBody>
      </ContentWrap>
    </>
  )
}

export default ProgressGraphBoard

import React, { useEffect,useState,useRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {PieChart,Pie,Cell } from "recharts"

const BodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  overflow: hidden;
  vertical-align: middle;
  width: 300px;
  height: 280px;
  box-shadow: 2px 2px 6px rgba(0,0,0,.4);
  background-color: ${props => props.contentColor?'salmon':'#fff'};
`

const Process = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  
  text-align:center;
  vertical-align:middle;
  padding: 1vw;
`

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  text-align: center;
  vertical-align: middle;
  padding: 0.35rem;
  opacity: ${props => props.contentOpacity?1:0};
`

const ContentBlock = styled.div`
  display: flex; /* 横並び */
  flex-direction: row;
  text-align: center;
  vertical-align: middle;
  padding: 0.5rem;
`
const Content = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  text-align: center;
  vertical-align: middle;
  width: 50%;
`

const ProcessTitle = styled.div`
  font-weight: bold;
  color: #000;
  text-align: center;
  font-size: 1.5rem;
`
const ProgressContentRow = styled.div`
  color: #000;
  text-align: center;
  font-size: 1.4rem;
`

const cx = 125;
const cy = 125;
const iR = 80;
const oR = 100;

const ShootGraph = (props) => {
  const {
    entry,opacity
  } = props
  const [data,setData] = useState([]);

  useEffect(() => {
    //console.log(entry);
    var plan = isNaN(Number(entry.planShootQuantity))?0:Number(entry.planShootQuantity);
    var progress = isNaN(Number(entry.progressShootQuantity))?0:Number(entry.progressShootQuantity);
    var remain= plan-progress;
    remain = remain>0 ? remain : 0;
    setData(
      [
        { name: '実績', value: Number(entry.progressShootQuantity), color: '#40C1C0' },
        { name: '残り', value: remain, color: 'lightgray' },
      ])
  }, [entry])

  const makeContent=(progress,plan)=>{
    if(progress && plan) return <><span style={{fontWeight: 'bold'}}>{isNaN(Number(progress))?0:Number(progress).toLocaleString()}</span> <span style={{fontSize:'50%'}}>/</span> <span style={{fontSize:'50%'}}>{Number(plan).toLocaleString()}</span></>
    return <></>
  }
  const makeContentProductivity=(progress,plan)=>{
    var colorCode = entry.shootProductivityDiffFlag==2?'#009EEC':'black'

    if(progress && plan) return <><span style={{fontWeight: 'bold',color:colorCode}}>{Number(progress).toLocaleString()}</span> <span style={{fontSize:'50%'}}>/</span> <span style={{fontSize:'50%'}}>{Number(plan).toLocaleString()}</span></>
    return <></>
  }
/*
  const setOpacityAndClor=()=>{
    if(!entry.progressEndTimeDiff) return true
    let tokens = entry.progressEndTimeDiff.split(':')
    let sec = 0

    if (tokens[0]) sec += (Number(tokens[0]) - 9)
    if(Number(tokens[0])<12){
      if (tokens[1] && Number(tokens[1])) sec += Number(tokens[1])/60
    }else if(Number(tokens[0])>12){
      sec -= 1
      if (tokens[1] && Number(tokens[1])) sec += Number(tokens[1])/60
    }
    return Number(entry.progressShootQuantityRate) < sec/8 * 100
  }
*/
  return (
    <ContentWrap contentOpacity={Number(entry.shootOverFlowFlag)===1 ? opacity : true}>
        <BodyContent contentColor={Number(entry.shootOverFlowFlag)===1}>
            <Process>
                <div><ProcessTitle>{entry.shootName}</ProcessTitle></div>
                <PieChart width={300} height={143}>
                <Pie
                dataKey="value"
                startAngle={180}
                endAngle={0}
                data={data}
                cx={cx}
                cy={cy}
                innerRadius={iR}
                outerRadius={oR}
                fill="#8884d8"
                stroke="none"
                >
                {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
                </Pie>
                {/* グラフタイトル */}
                <text x={cx} y={cy} dx={8}  fontSize={'24px'} textAnchor="middle" fill={'black'} fontWeight={'bold'}>{entry.progressShootQuantityRate+'%'}</text>
                <text x={cx-oR+15} y={cy} dy={18} textAnchor="middle" fill={'black'}>{'0'}</text>
                <text x={cx+oR-5} y={cy} dy={18} textAnchor="middle" fill={'black'}>{'100'}</text>
                </PieChart>
            </Process>
            {/* 進捗詳細 */}
            <ContentBlock>
                <Content>
                    <div>{'作業物量進捗'}</div>
                    <ProgressContentRow>{makeContent(entry.progressShootQuantity,entry.planShootQuantity)}</ProgressContentRow>
                </Content>
                    <div style={{width:'0px'}} ></div>
                <Content>
                    <div>{'生産性'}</div>
                    <ProgressContentRow>{makeContentProductivity(entry.progressShootProductivity,entry.standardShootProductivity)}</ProgressContentRow>
                </Content>
            </ContentBlock>
        </BodyContent>
    </ContentWrap>
  )
}
ShootGraph.propTypes = {
}

export default ShootGraph

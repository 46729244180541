import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
//import FUNC from '../../Common/FunctionEynum'
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'

import PropTypes from 'prop-types'

import { GetApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import * as LogisticUtility from '../../Common/Utilties/LogisticUtility'
//import ErrorHandling from '../../Common/ErrorHandling'

import AveWageGraph from './Molcules/AveWageGraph'

const DefaultUrl = '/api/logisticManagement'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 85vh;
  text-align: center;
  vertical-align: middle;
`

const ContentBody = styled.div`
  display: grid;
  grid-gap: 30px 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row: 1 / 2;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
`

const ProgressGraphBoard = (props) => {
  const {
    wageType,searchDate,processList,processMonthList
  } = props

  //const { loginUserInfo } = useSelector(state => state);

  const dispatch = useDispatch();
  const alert = useAlert();
  //const [processList,setProcessList] = useState([]);
  //const [processMonthList,setProcessMonthList] = useState([]);
  
  const intervalRef = useRef(null);



  return (
    <ContentWrap>
      <ContentBody>
        {processList.length!==0 &&
          <AveWageGraph processList={processList} type={0} wageType={wageType}/>
        }
      </ContentBody>
      <ContentBody>
        {processMonthList.length!==0 &&
          <AveWageGraph processList={processMonthList} type={1} wageType={wageType}/>
        }
      </ContentBody>
    </ContentWrap>
  )
}

AveWageGraph.propTypes = {
  wageType: PropTypes.number, // 平均時給タイプ　0:契約から算出,1:労務費から算出
}

export default ProgressGraphBoard

import React, { useEffect,useState } from 'react'
//import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import { makeStyles } from '@material-ui/core/styles';
import Modal from 'react-modal'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { GetApi } from '../../../Common/ApiAxios'
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'
import CloseIcon from '../../../../image/close_icon.png'
import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'
import Input from '../../../Common/Atoms/Input'
import MaterialAlertItem from './MaterialAlertItem'
import MaterialAlertHeader from './MaterialAlertHeader'

const DefaultUrl = '/api/logisticManagement'
const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

/* 縦並 */
const Wrap = styled.div`
  display:flex;
  flex-flow: column;
`

const HeaderContent = styled.div`
  display: flex; /* 横並び */
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  text-align:left;
  vertical-align:middle;
  height: 15%;
  margin-bottom: 10px; 
`

const Title = styled.span`
  font-size: ${props => props.fontSize ? props.fontSize : '2.2vw'};
`

const BodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 85%;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
`

export const ListUl = styled.ul`
  width: ${props => props.width ? props.width : '100%'};
  height: ${props => props.height ? props.height : '400px'};
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    border:none;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(112, 112, 112);
    border-radius: 10px;
  }
`

export const ListLi = styled.li`
  width: ${props => props.width ? props.width : 'calc(100%-18px)'};
  display: flex; /* 横並び */
  flex-direction: row;
  background: ${props => props.selected ? '#048b63' : '#fff'};
  color: ${props => props.selected ? '#fff' : '#333'};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  .listId{
    margin-right:1vw;
  }
  :nth-child(even){
    background: ${props => props.selected ? '#048b63' : '#eee'};
    color: ${props => props.selected ? '#fff' : '#333'};
  }
  :hover {
    background: #ccc;
    color: #333;
  }
`

function getTitle(type) {
  var title = '';
  switch(type) {
    case 0:
      title = '店着時刻アラート';
      break;
    case 1:
      title = '長時間作業上位';
      break;
    case 2:
      title = '長時間待機上位';
      break;
    default:
      title = '';
      break;
  }

  return title;
}
const ListDialog = ({ open,onCloseFunc,type,totalData}) => {

  useEffect(() => {
  }, [])

  // モーダル
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      //opacity: 0.8,
      padding: '10px',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    },
    overlay: {
      zIndex: 10
    }
  }

    //ダイアログ開いた時の処理
    function afterOpenModal () {
    }
  
    function closeModal () {
      onCloseFunc();
    }

  return (
    <Modal
    isOpen={open}
    onAfterOpen={afterOpenModal}
    onRequestClose={closeModal}
    style={customStyles}
    contentLabel='Create Zone Modal'
  >
    <div style={{ width:'1150px', height:'800px', marginLeft:'1rem' }}>
      <Wrap>
        <div style={{ height:'20px', marginLeft:'auto', marginRight:'10px', marginTop:'10px', cursor: 'pointer'}}>
          <img alt='閉じる' className='close' src={CloseIcon} title={'ダイアログを閉じる'} width="12px" height="12px" onClick={closeModal} />
        </div>
        <HeaderContent>
          <Title fontSize={'2vw'}>{getTitle(type)}</Title>
      </HeaderContent>
        <BodyContent>
        <MaterialAlertHeader type={type} dialog={true}/>
        <ListUl width={totalData && totalData.length>=15?'1104px':'1094px'} height={'673px'}>
          {totalData.map((item,index) => (
          <ListLi key={index+'_dialog'} style={{cursor: 'default'}}>
            <MaterialAlertItem data={item} index={index+1} type={type} dialog={true}/>
          </ListLi> 
        ))}
        </ListUl>
        </BodyContent>        
      </Wrap>
    </div>
  </Modal>
  )
}

ListDialog.propTypes = {
    open: PropTypes.bool,
    closeFunc: PropTypes.func,
    updatedAttendanceFunc: PropTypes.func,
    defaultUrl: PropTypes.string,
    iconName: PropTypes.string,
    text: PropTypes.string,
    dailyWorkInfoList: PropTypes.array
}

export default ListDialog
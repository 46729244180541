import React, { useEffect, useRef } from 'react'
import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'
//import { GetApi } from '../../../Common/ApiAxios'

const CardWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 120px;
  background-color: ${props => props.bColor === 3 || props.bColor === 4  ? '#f00' : '#1155cc'};
  border-radius: 10px;
  border: none;

  color: #fff;
`

const HeaderContent = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 100%;
  height: 40%;
  text-align:center;
  vertical-align:middle;
`

const Title = styled.span`
  width: 100%;
  font-size: 32px;
  font-weight: bold;
  margin:auto;
`

const Content = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 60%;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
`

// 現在値
const PresentValue = styled.span`
  font-size: 32px;
  font-weight: bold;
  margin: auto;
`

function getTitle(type) {
  var title = '';
  switch(type) {
    case 0:
      title = '稼働状況';
      break;
    case 1:
      title = '平均労働時間';
      break;
    case 2:
      title = '平均睡眠時間';
      break;
    case 3:
      title = 'アラート対象';
      break;
    case 4:
      title = '事故発生件数';
      break;
    default:
      title = '';
      break;
  }

  return title;
}

function getValue(totalData, type) {
  var text = '';
  //if (totalData === null)
  //  return text;
  //console.log(totalData, type);

  switch(type) {
    case 0: // 稼働状況
      if (totalData === null) {
        text = '0 / 0 人';
      } else {
        text = totalData.resultNum + " / " + totalData.planNum + " 人";
      }
      break;
    case 1: // 平均労働時間
      if (totalData === null) {
        text = '0.00 h';
      } else {
        text = totalData.avgWorkTime.toFixed(2) + " h";
      }
      break;
    case 2: // 平均睡眠時間
      if (totalData === null) {
        text = '0.00 h';
      } else {
        text = totalData.avgSleepTime.toFixed(2) + " h";
      }
      break;
    case 3: // アラート対象
      if (totalData === null) {
        text = '0 人';
      } else {
        text = totalData.alertNum + " 人";
      }
      break;
    case 4: // 事故発生件数
      if (totalData === null) {
        text = '0 件';
      } else {
        text = totalData.accidentNum + " 件";
      }
      break;
    default:
      text = '';
      break;
  }

  return text;
}

// ヘッダー部のカード
const CardTotal = (props) => {
  const {
    grants,
    totalData,
    type,
  } = props
  //const alert = useAlert();
  //const interval1Ref = useRef(null);

  // 描画時に一度だけ実行
  useEffect(() => {
  }, [])

  //if (!grants || !totalData) return null
  return (
    <CardWrap bColor={type}>
      <HeaderContent>
        <Title>{getTitle(type)}</Title>
      </HeaderContent>
      <Content>
        <PresentValue>{getValue(totalData, type)}</PresentValue>
      </Content>
    </CardWrap>
  )
}

CardTotal.propTypes = {
    grants: PropTypes.object, // 権限関連
    totalData: PropTypes.object, // 集計情報
    type: PropTypes.number, // 0:ドライバー稼働状況 1:平均労働時間 2:平均睡眠時間 3:アラート対象 4:事故発生件数
  }
  
export default CardTotal

import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'
import IconPageNextImg from '../../../../image/PageNext.png'
import IconPageBackImg from '../../../../image/PageBack.png'
const MediaWrap = styled.div`
  width:calc(1650px - 190px);
  display: flex; /* 横並び */
  flex-wrap: wrap;
  margin: 4px;
  box-shadow: 0 0 3px 2px 'rgba(0,0,0,0.4)';
  background-color: #649CDF;
  color: #fff;
  font-size: 24px;
  font-weight: bold;

  text-align: center;
  align-items: center;

  margin-left:190px;
`
const MediaItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 60px;
  border-right: 2px dashed #fff;
`

const MediaRow = styled.div`
  display: flex; /* 縦並び */
  flex-direction: row;
  height: 30px;
`

const HealthTitle = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
` 
function getDate(date,week) {
  var tmpDate = new Date(date)
  var dayOfWeek  = tmpDate.getDay()

  tmpDate.setDate(tmpDate.getDate() - dayOfWeek + week)
  var text = LogisticUtility.getStringFromDate(tmpDate, 'MM/DD')

  return text;
}

const ProcessHeader = (props) => {
  const {
    targetYMD,onClickBack,onClickNext
  } = props

  // 初期値
  useEffect(() => {
  })

  return (
    <MediaWrap>
      <MediaItem>
          <HealthTitle style={{width:'100pX'}}>単位</HealthTitle>
      </MediaItem>
      <MediaItem>
        <MediaRow>
          <span><img src={IconPageBackImg} onClick={onClickBack} style={{marginTop: '10px',filter: 'brightness(0) invert(1)'}}/></span>
          <HealthTitle style={{width:'155pX',marginRight:'20px'}}>{getDate(targetYMD,1)}</HealthTitle>
        </MediaRow>
        <MediaRow>
          <HealthTitle style={{width:'155pX',marginRight:'20px'}}>（月）</HealthTitle>
        </MediaRow>
      </MediaItem>
      <MediaItem>
        <MediaRow>
        <HealthTitle style={{width:'190px'}}>{getDate(targetYMD,2)}</HealthTitle>
        </MediaRow>
        <MediaRow>
          <HealthTitle style={{width:'190px'}}>（火）</HealthTitle>
        </MediaRow>
      </MediaItem>
      <MediaItem>
        <MediaRow>
        <HealthTitle style={{width:'190px'}}>{getDate(targetYMD,3)}</HealthTitle>
        </MediaRow>
        <MediaRow>
          <HealthTitle style={{width:'190px'}}>（水）</HealthTitle>
        </MediaRow>
      </MediaItem>
      <MediaItem>
        <MediaRow>
        <HealthTitle style={{width:'190px'}}>{getDate(targetYMD,4)}</HealthTitle>
        </MediaRow>
        <MediaRow>
          <HealthTitle style={{width:'190px'}}>（木）</HealthTitle>
        </MediaRow>
      </MediaItem>
      <MediaItem>
        <MediaRow>
        <HealthTitle style={{width:'190px'}}>{getDate(targetYMD,5)}</HealthTitle>
        </MediaRow>
        <MediaRow>
          <HealthTitle style={{width:'190px'}}>（金）</HealthTitle>
        </MediaRow>
      </MediaItem>
      <MediaItem>
        <MediaRow>
        <HealthTitle style={{width:'190px'}}>{getDate(targetYMD,6)}</HealthTitle>
        </MediaRow>
        <MediaRow>
          <HealthTitle style={{width:'190px'}}>（土）</HealthTitle>
        </MediaRow>
      </MediaItem>
      <MediaItem>
        <MediaRow>
          <HealthTitle style={{width:'155pX',marginLeft:'20px'}}>{getDate(targetYMD,7)}</HealthTitle>
          <span><img src={IconPageNextImg} onClick={onClickNext} style={{marginTop: '10px',filter: 'brightness(0) invert(1)'}}/></span>
        </MediaRow>
        <MediaRow>
          <HealthTitle style={{width:'155pX',marginLeft:'20px'}}>（日）</HealthTitle>
        </MediaRow>
      </MediaItem>
    </MediaWrap>
  )
}

ProcessHeader.propTypes = {
  targetYM: PropTypes.string, // 集計月
  targetYMD: PropTypes.string, // 集計日
}

export default ProcessHeader
import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'
import IconPageNextImg from '../../../../image/PageNext.png'
import IconPageBackImg from '../../../../image/PageBack.png'
const MediaWrap = styled.div`
  width:calc(1700px);
  display: flex; /* 横並び */
  flex-wrap: wrap;
  margin: 4px;
  box-shadow: 0 0 3px 2px 'rgba(0,0,0,0.4)';
  background-color: #649CDF;
  color: #fff;
  font-size: 24px;
  font-weight: bold;

  text-align: center;
  align-items: center;

`
const MediaItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 60px;
  border-right: 2px dashed #fff;
`

const MediaRow = styled.div`
  display: flex; /* 縦並び */
  flex-direction: row;
  height: 30px;
`

const ProgressTitle = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
` 
function getDate(date,week) {
  var tmpDate = new Date(date)
  var dayOfWeek  = tmpDate.getDay()

  tmpDate.setDate(tmpDate.getDate() - dayOfWeek + week)
  var text = LogisticUtility.getStringFromDate(tmpDate, 'MM/DD')

  return text;
}

const ProgressHeader = (props) => {
  const {
    targetYMD,onClickBack,onClickNext
  } = props

  // 初期値
  useEffect(() => {
  })

  return (
    <MediaWrap>
      <MediaItem>
          <ProgressTitle style={{width:'230px'}}></ProgressTitle>
      </MediaItem>
      <MediaItem style={{backgroundColor: '#f2b3b3'}}>
          <ProgressTitle style={{width:'250px',}}>合計（平均）</ProgressTitle>
      </MediaItem>
      <MediaItem>
        <MediaRow>
          <span><img src={IconPageBackImg} onClick={onClickBack} style={{marginTop: '10px',filter: 'brightness(0) invert(1)'}}/></span>
          <ProgressTitle style={{width:'135px',marginRight:'20px'}}>{getDate(targetYMD,1)}</ProgressTitle>
        </MediaRow>
        <MediaRow>
          <ProgressTitle style={{width:'135px',marginRight:'20px'}}>（月）</ProgressTitle>
        </MediaRow>
      </MediaItem>
      <MediaItem>
        <MediaRow>
        <ProgressTitle style={{width:'170px'}}>{getDate(targetYMD,2)}</ProgressTitle>
        </MediaRow>
        <MediaRow>
          <ProgressTitle style={{width:'170px'}}>（火）</ProgressTitle>
        </MediaRow>
      </MediaItem>
      <MediaItem>
        <MediaRow>
        <ProgressTitle style={{width:'170px'}}>{getDate(targetYMD,3)}</ProgressTitle>
        </MediaRow>
        <MediaRow>
          <ProgressTitle style={{width:'170px'}}>（水）</ProgressTitle>
        </MediaRow>
      </MediaItem>
      <MediaItem>
        <MediaRow>
        <ProgressTitle style={{width:'170px'}}>{getDate(targetYMD,4)}</ProgressTitle>
        </MediaRow>
        <MediaRow>
          <ProgressTitle style={{width:'170px'}}>（木）</ProgressTitle>
        </MediaRow>
      </MediaItem>
      <MediaItem>
        <MediaRow>
        <ProgressTitle style={{width:'170px'}}>{getDate(targetYMD,5)}</ProgressTitle>
        </MediaRow>
        <MediaRow>
          <ProgressTitle style={{width:'170px'}}>（金）</ProgressTitle>
        </MediaRow>
      </MediaItem>
      <MediaItem>
        <MediaRow>
        <ProgressTitle style={{width:'170px'}}>{getDate(targetYMD,6)}</ProgressTitle>
        </MediaRow>
        <MediaRow>
          <ProgressTitle style={{width:'170px'}}>（土）</ProgressTitle>
        </MediaRow>
      </MediaItem>
      <MediaItem>
        <MediaRow>
          <ProgressTitle style={{width:'135px',marginLeft:'20px'}}>{getDate(targetYMD,7)}</ProgressTitle>
          <span><img src={IconPageNextImg} onClick={onClickNext} style={{marginTop: '10px',filter: 'brightness(0) invert(1)'}}/></span>
        </MediaRow>
        <MediaRow>
          <ProgressTitle style={{width:'135px',marginLeft:'20px'}}>（日）</ProgressTitle>
        </MediaRow>
      </MediaItem>
    </MediaWrap>
  )
}

ProgressHeader.propTypes = {
  targetYMD: PropTypes.string, // 集計日
  onClickBack: PropTypes.func, //バックボタン押下時処理
  onClickNext: PropTypes.func, //ネクストボタン押下時処理
}

export default ProgressHeader

import PropTypes from 'prop-types'
import { useCallback, useState, useEffect } from 'react'

// ID自動生成
export function useIdGenerator () {
  let id = Math.random().toString()
  while (document.getElementById(id) != null) {
    id = Math.random().toString()
  }
  return id
}

// 郵便番号から住所を取得
export function useYubinbango (postalCode) {
    const [addressObject, setAddressObject] = useState(null)
  
    const yubinbangoCore = useCallback((postalCode) => {
      const postalCodeLength = `${postalCode}`.replace(/-/g, '').length
      // コンストラクタ呼び出しのみで良いため、lintルール排除
      // eslint-disable-next-line
      window?.YubinBango?.Core && 8 > postalCodeLength && postalCodeLength > 6 
        ? new window.YubinBango.Core(postalCode, d => { setAddressObject(() => d) })
        : null
    }, [setAddressObject])
  
    useEffect(() => {
      yubinbangoCore(postalCode)
    // postalCode 変更検知時のみ動作させる
    // eslint-disable-next-line
    }, [postalCode])
    return ({ ...addressObject })
}
useYubinbango.propTypes = {
    postalCode: PropTypes.string // 郵便番号文字列(ハイフン有無問わない)
}

// 権限判定 権限区分、対象staffId、 departmentId loginUser情報で判定
export const grantDecision = (division, grantId, loginUserInfo, departmentId, staffId) => {
    const loginDepartmentId = loginUserInfo.departmentId
    const loginId = loginUserInfo.staffId
    const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === grantId)
  
    if (!grants) return false
    if (division === 'write') {
      return grants.writeGrantDivision === 2 || (grants.writeGrantDivision === 1 && (!departmentId || loginDepartmentId === departmentId)) ||
        (grants.writeGrantDivision === 3 && loginId === staffId)
    } else if (division === 'read') {
      return grants.readGrantDivision === 2 || (grants.readGrantDivision === 1 && (!departmentId || loginDepartmentId === departmentId)) ||
        (grants.readGrantDivision === 3 && loginId === staffId)
    } else {
      return false
    }
}

/// 生年月日から年齢を計算する
export const GetAge = (birthDate,today)=>{
  if(!birthDate) return 0;
  birthDate = new Date(birthDate)
  var age = today.getFullYear() - birthDate.getFullYear();
  //現在の日付から年齢を引いた日付が誕生日より前ならば、1引く
  if (today.setFullYear(today.getFullYear() - age) < birthDate)
  {
      age--;
  }
 
  return age;
}


import React, { useEffect } from 'react'
import styled from 'styled-components'
//import PropTypes from 'prop-types'

const MediaWrap = styled.div`
  width:1110px;
  display: flex; /* 横並び */
  flex-wrap: wrap;
  box-shadow: 0 0 3px 2px 'rgba(0,0,0,0.4)';
  background-color: #649CDF;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  margin-left:180px;
  text-align: center;
  align-items: center;
`
const MediaItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 40px;
  border-right: 2px dashed #fff;
`

const HealthTitle = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
` 

const MediaHeaderCarbonEmissions = (props) => {
  const {
  } = props

  // 初期値
  useEffect(() => {
  })

  return (
    <MediaWrap>
      <MediaItem>
        <HealthTitle style={{width:'180px'}}>荷受</HealthTitle>
      </MediaItem>
      <MediaItem>
        <HealthTitle style={{width:'180px'}}>投入口</HealthTitle>
      </MediaItem>
      <MediaItem>
        <HealthTitle style={{width:'180px'}}>ソーター仕分け</HealthTitle>
      </MediaItem>
      <MediaItem>
        <HealthTitle style={{width:'180px'}}>パレット仕分け</HealthTitle>
      </MediaItem>
      <MediaItem>
        <HealthTitle style={{width:'180px'}}>ステージング</HealthTitle>
      </MediaItem>
      <MediaItem>
        <HealthTitle style={{width:'180px'}}>出荷</HealthTitle>
      </MediaItem>
    </MediaWrap>
  )
}

MediaHeaderCarbonEmissions.propTypes = {
}

export default MediaHeaderCarbonEmissions

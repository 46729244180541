import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
//import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { GetApi } from '../../../Common/ApiAxios'
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'
import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'

import CardTotal from '../Atoms/CardTotal'
//import CardTotalVAS from '../Atoms/CardTotalVAS'
import ProcessPieGraph from './ProcessPieGraph'

const DefaultUrl = '/api/logisticManagement'


const Flex = styled.div`
  display: flex; /* 横並び */
  flex-direction: row;
  width: 100%;
  height: 310px;
  text-align: center;
  vertical-align: middle;
  margin-top: 10px;
  margin-bottom: 5px;
`
const ContentRow = styled.div`
  display: flex; /* 横並び */
  flex-direction: column;
`
const Content = styled.div`
  display: grid;

  width: 50%;
  height: 310px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px;
  ::-webkit-scrollbar-corner {
    display: none;
  }
`

// ヘッダー部のカード
const ProgressHeader = (props) => {
  const {
    wageType,
    quantityPlan,
    monthlyData,
    daylyData,
    processList,
    processMonthList
  } = props

  //const [quantityPlan,setQuantityPlan] = useState(null);
  //const [totalProgressInfo,seTtotalProgressInfo] = useState({progress:0.0});
  //const [plaEndInfo, setPlanEndInfo] = useState({planEndAt:LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD') + " 00:00:00"});

  //const defaltValue = {agreementStaffAvgWage:0.0,aveWage:0.0,costProgressValue:0.0}
  //const [monthlyData,setMonthlyData] = useState([defaltValue,defaltValue]);
  //const [daylyData,setDaylyData] = useState([defaltValue,defaltValue]);

  //const [processList,setProcessList] = useState([]);
  //const [processMonthList,setProcessMonthList] = useState([]);

  //if (!grants || !totalDatas) return null
  return (
    <Flex>
      <ProcessPieGraph processList={processList}/>
      <Content>
        <ContentRow>
          <CardTotal totalData={daylyData} type={0} wageType={wageType}/>
          <CardTotal totalData={daylyData} type={1} wageType={wageType}/>
        </ContentRow>
      </Content>
      <ProcessPieGraph processList={processMonthList}/>
      <Content>
        <ContentRow>         
          <CardTotal totalData={monthlyData} type={2} wageType={wageType}/>  
          <CardTotal totalData={monthlyData} type={3} wageType={wageType}/>    
        </ContentRow>
      </Content>
    </Flex>
  )
}

ProgressHeader.propTypes = {
  grants: PropTypes.object, // 権限関連
  totalDatas: PropTypes.object, // 集計情報
  wageType: PropTypes.number, // 平均時給タイプ　0:契約から算出,1:労務費から算出
}
  
export default ProgressHeader


import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'

import { GetApi, exportErrorLog, forceLogout } from './Common/ApiAxios'

//import ShiftIcon from '../image/menu-shift.png'
//import ShiftBulkIcon from '../image/menu-shift-bulk.png'
//import AttendanceIcon from '../image/menu-attendance.png'
//import SalariedIcon from '../image/menu-salaried.png'
//import SalesIcon from '../image/menu-sales.png'
//import DashboardIcon from '../image/menu-dashboard.png'
//import BiometricIcon from '../image/menu-biometric.png'
import TruckIcon from '../image/menu-truck.png'
import LogiIcon from '../image/menu-logi.png'
//import MasterIcon from '../image/menu-master.png'
import CoreTimeIcon from '../image/coretime.png'
import CoreFirstIcon from '../image/corefirst.png'
import CoreBoardIcon from '../image/coreboard.png'

const MenuNav = styled.nav`
  display: none;
  animation-name: menu;
  animation-duration: .5s;
  animation-timing-function: ease;
  width: 350px;
  background: #cfe2f3;
  box-shadow: 2px 2px 6px rgba(0,0,0,.4);
  position: absolute;
  left 15px;
  top: 60px;
  z-index: 1300;

  @keyframes menu {
    from {
      opacity: 0;
      transform: translateX(0);
    }
    
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
`
const MenuContentWrap  = styled.div`
  position: relative;
  display: flex; /* 縦並び */
  flex-direction: column;
  text-align: center;
  width: 330px;
  margin-top: 10px;
  margin-bottom: 10px;
`

const SubMenuContentWrap = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* 幅3等分 */
  grid-template-rows: 60px 60px 60px; /* 60pxの3行 */
  grid-gap: 5px; /* 隙間5px */
`

const NavigatorItemContent  = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  text-align: center;
  width: 110px;
  font-size: 14px;
`

// サービス内の機能へ遷移する項目
const NavigatorItem = (props) => {
  const {
    functionItem,
    onClick,
  } = props
  return (
    <NavigatorItemContent>
        { functionItem.data.functionMenuId !=='2030101001' &&
          <Tooltip title={functionItem.data.functionMenuName}>
            <Link key={functionItem.data.functionMenuId} to={functionItem.linkTo} data-service-menu-id={functionItem.data.functionMenuId} onClick={onClick} >
              <img alt={functionItem.data.functionMenuName} src={functionItem.img} width="32px" height="32px" />
              <div>{functionItem.data.functionMenuName}</div>
            </Link>
          </Tooltip>
        }
        {/* 売上管理Close */}
        { functionItem.data.functionMenuId ==='2030101001' &&
          <div>
            <img alt={functionItem.data.functionMenuName} src={functionItem.img} width="32px" height="32px" />
            <div>{functionItem.data.functionMenuName}</div>
          </div>
        }
    </NavigatorItemContent>
  )
}

NavigatorItem.propTypes = {
  functionItem: PropTypes.object,
  onClick: PropTypes.func
}

// 他サービスへ遷移する項目
const OtherServiceItem = (props) => {
  const {
    functionItem,
    onClick,
  } = props
  return (
    <NavigatorItemContent style={{cursor:functionItem.cursor}}>
      <Tooltip title={functionItem.functionName}>
        <div  onClick={onClick}>
          <img alt={functionItem.functionName} src={functionItem.img} width="32px" height="32px" />
          <div>{functionItem.functionName}</div>
        </div>
      </Tooltip>
    </NavigatorItemContent>
  )
}

OtherServiceItem.propTypes = {
  functionItem: PropTypes.object,
  onClick: PropTypes.func
}

const Navigator = (props) => {
  //const [serviceList, setServiceList] = useState([])
  const loginUserInfo = useSelector(state => state.loginUserInfo);
  const [functionList, setFunctionList] = useState([]);
  //const [masterFunctionList, setMasterFunctionList] = useState([])

  // メニューリスト
  async function getMenuList (cleanedUp) {
    const apiUrl = '/api/menuList'
    const grantNameId = loginUserInfo.grantNameId
    const data = { grantNameId }
    const result = await GetApi(apiUrl, data)
    //console.log(result)
    if (result.errorDetail) {
      exportErrorLog(result)
      const errorStatus = result.errorDetail.response && result.errorDetail.response.status
      if (errorStatus === 401) {
          forceLogout()
      } else {
          alert('情報取得に失敗しました。')
      }
      window.location.href = '/login'
    } else {
      //setServiceList(result.data)
      
      //console.log(result.data)
      var list1 = [];
      result.data.map(service => {
        service.manageMenuList.map(manage => {
          manage.functionMenuList.map(item => {
            //console.log(item.functionMenuId);
            switch (item.functionMenuId) {
              case '2050201001':
                list1.push({ data:item, linkTo: '/logistic', img: LogiIcon, sort:5 });
                break;
              case '2050301001':
                list1.push({ data:item, linkTo: '/truck', img: TruckIcon, sort:6 });
                break;
              //マスタ管理
              case '2042201001':
                //list1.push({ data:item, linkTo: '/Master', img: MasterIcon, sort:7 });
                break;
            }
          })
        })
      })
      //console.log(list1);
      if (list1.length > 0)
        list1.sort(function(a, b){ return a.sort - b.sort; });
      setFunctionList(list1);
    }
  }

  useEffect(() => {
    if (loginUserInfo.grantNameId) {
        getMenuList()
    }
  // ログインユーザー情報の変更検知時のみ実行する
  // eslint-disable-next-line
  }, [loginUserInfo]);

  const showMenu = () => {
      props.setShowFlg(props.showFlg === 'none' ? 'block' : 'none');
  }

  const showCoreTimeMenu = () => {
    props.setShowFlg(props.showFlg === 'none' ? 'block' : 'none');
    window.open('https://coretime-cloud-front-dev.pal-style.co.jp/attendance', '_blank');
  }
  
  const showCoreFirstMenu = () => {
    props.setShowFlg(props.showFlg === 'none' ? 'block' : 'none');
    window.open('https://core-first-demo.pal-style.co.jp/whiteboard/positioning/top', '_blank');
  }
  
  const showCoreBoardMenu = () => {
    props.setShowFlg(props.showFlg === 'none' ? 'block' : 'none');
    //window.open('https://coreboard-front-dev.pal-style.co.jp/top', '_blank');
  }
  
  return (
  <MenuNav className='menu' style={{ display: props.showFlg }} onMouseLeave={showMenu} >
      <MenuContentWrap>
        <SubMenuContentWrap>
          <>
            <OtherServiceItem key={'CoreTime'} functionItem={{functionName:'CoreTime', img:CoreTimeIcon, cursor:'pointer'}} onClick={showCoreTimeMenu}></OtherServiceItem>
            <OtherServiceItem key={'位置測位'} functionItem={{functionName:'位置測位', img:CoreFirstIcon, cursor:'pointer'}} onClick={showCoreFirstMenu}></OtherServiceItem>
            <OtherServiceItem key={'CoreBoard'} functionItem={{functionName:'CoreBoard', img:CoreBoardIcon, cursor:'default'}}></OtherServiceItem>
          </>
        </SubMenuContentWrap>
        {/* <hr/>
        <SubMenuContentWrap>
          {masterFunctionList && masterFunctionList.map(item => (
            <NavigatorItem functionItem={item} onClick={showMenu} />
          ))}
        </SubMenuContentWrap> */}
      </MenuContentWrap>
  </MenuNav>
  )
}

Navigator.propTypes = {
  showFlg: PropTypes.string,
  setShowFlg: PropTypes.func,
  topPageFlg: PropTypes.bool,
}

export default Navigator

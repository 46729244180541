import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import IconCloseImg from '../../../image/close_icon.png'
import Button from '../../Common/Atoms/NormalButton'
import { useDispatch } from 'react-redux'
import { PostApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import { useLocation } from 'react-router-dom'
const DefaultUrl = '/api/afsTruckDashboardManagement/dashboard'

const MenuContentWrap  = styled.div`
display: flex;
`

const SubMenuContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  width:700px;
  height:500px;
  background: white;
  overflow: hidden;
  :hover {
    overflow-y: auto;
    overflow-x: auto;
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    border:none;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(212, 212, 212, 1);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:active {
    background-color: rgba(112, 112, 112, 1);
  }
  margin-left: calc(50% - 350px);
  margin-top: 30px;
`

const ContentWrap = styled.div`
  display: block; /* 横並び */
  flex-direction: column;
  vertical-align: middle;
  width: calc(100vw);
  height: calc(100vh - 67px);//ウィンドウサイズ - ヘッダーサイズ
  text-align: center;
  background: #cfe2f3;
`
const ProgressTitle = styled.div`
  cursor: pointer;
  font-weight: bold;
  text-align: left;
  font-size: 1.8rem;
  width calc(100% - 20px);
  padding:0.5rem;
`
const ProgressContent = styled.div`
  cursor: pointer;
  text-align: left;
  font-size: 1.5rem;
  white-space: pre-wrap;
  width calc(100% - 20px);
  padding:0.5rem;
  white-space: pre-wrap;
`
const InfomationBody = (props) => {
  const loginUserInfo = useSelector(state => state.loginUserInfo);
  const dispatch = useDispatch()
  const onClose = () => {
    window.close()
  }
  //ページ遷移前の更新破棄の確認
  //更新キャンセル処理
  useEffect(() => {
    // イベントの設定
    window.addEventListener('beforeunload', onUnload);
  
    return () => {
      // イベントの設定解除
      window.removeEventListener('beforeunload', onUnload);
    }
  })

  //ページ遷移前に親ウィンドウからの引数をリセット
  const onUnload = (e) => {
    localStorage.removeItem('Title'+window.name);
    localStorage.removeItem('Period'+window.name);
    localStorage.removeItem('Text'+window.name);
    localStorage.removeItem("Id"+window.name);
    localStorage.removeItem("companyCode"+window.name);
  }

  useEffect(() => {
      apiGetInformation(true)
  // ログインユーザー情報の変更検知時のみ実行する
  // eslint-disable-next-line
  }, []);

  // 進捗集計情報の取得
  const apiGetInformation = async (isLoadSplinner) => {
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));
  
    const apiUrl = DefaultUrl + '/updateInformationAlreadyRead';
    console.log(apiUrl)
    const data = {
      infoId:localStorage.getItem("Id"+window.name),
      companyCode: localStorage.getItem("companyCode"+window.name)
    }
    const result = await PostApi(apiUrl, data);
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));
    } else {
      if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));
    }
  }
  return (
    <ContentWrap>
      {console.log(localStorage.getItem('Text'+window.name))}
      <MenuContentWrap>
        <SubMenuContentWrap>
          <ProgressTitle>{localStorage.getItem('Title'+window.name)}</ProgressTitle>
          <ProgressTitle>{localStorage.getItem('Period'+window.name)}</ProgressTitle>
          <ProgressContent><div dangerouslySetInnerHTML={{
            __html: localStorage.getItem('Text'+window.name)
          }}>
            </div></ProgressContent>
        </SubMenuContentWrap>
      </MenuContentWrap>
      <div style={{display:'flex',justifyContent: 'center'}}>
      <Button name='閉じる' padding='0px' onClick={onClose} />
      </div>
    </ContentWrap>
  )
}

InfomationBody.propTypes = {
  showFlg: PropTypes.string,
  setShowFlg: PropTypes.func,
  topPageFlg: PropTypes.bool,
}

export default InfomationBody

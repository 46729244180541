import React, { useEffect } from 'react'
//import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import * as HealthCareUtilty from '../../../Common/Utilties/HealthCareUtilty'
import { DeleteApi } from '../../../Common/ApiAxios'

const CardWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 150px;

  background-color: ${props => props.bColor !== '' ? props.bColor : '#1155cc'};
  border-radius: 10px;
  border: none;

  color: #000;
`

const HeaderContent = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 100%;
  height: 40%;
  text-align:center;
  vertical-align:middle;
`

const Title = styled.span`
  width: 100%;
  margin:auto;

  font-size: 2.0vw;
  font-weight: normal;

  color: #4d4d4d;
`

const BodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 60%;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
`

// 通常項目
const NormalItem = styled.span`
  font-size: 3.0vw;
  font-weight: normal;
`

function getBGColor(type) {
    var color = '';
    
  switch(type) {
    case 0:
      color = '#ffc107';
      break;
    case 1:
      color = '#ffccbc';
      break;
    case 2:
      color = '#b2ebf2';
      break;
    case 3:
      color = '#ffecb3';
      break;
    default:
      color = '';
      break;
  }

  return color;
}

function getTitle(type) {
  var title = '';
  switch(type) {
    case 0:
      title = '走行距離（km）';
      break;
    case 1:
      title = '実車走行距離（km）';
      break;
    case 2:
      title = '空車走行距離（km）';
      break;
    case 3:
      title = '総稼働時間（時間）';
      break;
    default:
      title = '';
      break;
  }

  return title;
}

function getValueText(type, value) {
  var text = '';
  if (value === null)
    return text;

  switch(type) {
    case 0:
      text = HealthCareUtilty.getCommaText(Math.round(value / 10) / 10);
      break;
    case 1:
      text = HealthCareUtilty.getCommaText(Math.round(value / 10) / 10);
      break;
    case 2:
      text = HealthCareUtilty.getCommaText(Math.round(value / 10) / 10);
      break;
    case 3:
      text = HealthCareUtilty.getCommaText(Math.round(value / 3600 * 10) / 10);
      break;
    default:
      text = '';
      break;
  }
  
  if (text.length > 0 && !text.includes('.'))
    text += '.0';
    
  return text;
}

// 上部２段目のカード
const CardTotal2 = (props) => {
  const {
    grants,
    value,
    type,
  } = props
  //const alert = useAlert();
  //const interval1Ref = useRef(null);

  // 描画時に一度だけ実行
  useEffect(() => {
  }, [])

  //if (!grants || !value) return null
  return (
    <CardWrap bColor={getBGColor(type)}>
      <HeaderContent>
          <Title>{getTitle(type)}</Title>
      </HeaderContent>
      <BodyContent>
        <>
        <NormalItem>{getValueText(type, value)}</NormalItem>
        </>
      </BodyContent>
    </CardWrap>
  )
}

CardTotal2.propTypes = {
  grants: PropTypes.object, // 権限関連
  value: PropTypes.object, // 集計値
  type: PropTypes.number, // 0:走行距離 1:実車走行距離 2:空車走行距離 3:総稼働時間
}
  
export default CardTotal2

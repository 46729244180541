import React, { useState, useEffect } from 'react'
//import { useAlert } from 'react-alert'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'

import { GetApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import * as MoneyUtility from '../../Common/Utilties/MoneyUtility'

import SettingIcon from '../../../image/setting-icon.png'

import UpperRightKPIInfoSettingModal from '../Molcules/KPIInfoSettingModal/UpperRightKPIInfoSettingModal'
import DownIcon from '../../../image/down-red-icon.png'
import UpIcon from '../../../image/up-green-icon.png'
const CardWrap = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: calc(100%-20px);
  height: 165px;
  border-radius: 10px;
  border: none;
  margin-left: 10px;
  margin-top: 0;
  margin-right: 10px;
  margin-bottom: 10px;
  text-align: left;
`

const KPIContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 90px;
  gap: 5px;
  text-align: center;
  width: calc(100% - 30px);
`

const ItemWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  border-radius: 10px;
  border: none;
`

const TitelWrap = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;

  font-size: 18px;
  font-weight: bold;
`

const ValueWrap  = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 100%;
  text-align: right;

  font-size: 20px;
`

const Value = styled.span`
  width: 70%;
  margin: 0;
`

const Unit = styled.span`
  font-size: 16px;
  margin-left: 1rem;
  margin-top: 0.25rem;
`

const Icon = styled.img`
  width: 18px;
  height: 18px;
  margin-left: auto;
  margin-right: 0.5rem;
  margin-top: 0.5rem;

  cursor: pointer;

  display:none;
  ${CardWrap}:hover & {
    display:block;
  }
`
const MoMWrap = styled.div`
  font-size: 14px;
  text-align: center;
  margin-bottom: 5px;
`
const IconUpDown = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 6px;
  margin-top: 0.25rem;
`

//予算達成率
function getBudgetAchievementRate(summary) {
  if (!summary)
    return '0.00';
  else if (summary.budgetAchievementRate === null || isNaN(summary.budgetAchievementRate))
    return '0.00';
  
  return summary.budgetAchievementRate.toFixed(2);
}

//予算達成率(前月比)
function getBudgetAchievementMoM(summary,before) {
  var rate = getBudgetAchievementRate(summary)
  var beforeRate = getBudgetAchievementRate(before)
  var momRate = Number(rate)/Number(beforeRate)*100
  if(!beforeRate) momRate = 0;
  return momRate.toFixed(2);
}

//予算達成率(アイコン)
function getBudgetAchievementIcon(summary,before) {
  var rate = getBudgetAchievementRate(summary)
  var beforeRate = getBudgetAchievementRate(before)
  var diff = Number(rate) - Number(beforeRate);
  if (!beforeRate) diff = 0;
  console.log('予算達成率')
  console.log(diff, rate, beforeRate);
  if (diff == 0) {
    return;
  }
  if (diff > 0) {
    return  <IconUpDown src={UpIcon}/>;
  }
  return <IconUpDown src={DownIcon}/>;
}

//売上高
function getSales(summary) {
  if (!summary)
    return '0';
  else if (summary.salesCost === null || isNaN(summary.salesCost))
    return '0';
  else if (Number(summary.salesCost) === 0)
    return '0';

  return MoneyUtility.formatMoney(summary.salesCost);
}

//売上高（前月比）
function getSalesMoM(summary,before) {
  if (!summary || summary.salesCost === null || isNaN(summary.salesCost) || (Number(summary.salesCost) === 0))
    return '0.00';
  if (!before || before.salesCost === null || isNaN(before.salesCost) || (Number(before.salesCost) === 0))
    return '0.00';
  var momRate = Number(summary.salesCost)/Number(before.salesCost)*100
  return momRate.toFixed(2);
}

//売上高（アイコン）
function getSalesIcon(summary,before) {
  if (!summary || summary.salesCost === null || isNaN(summary.salesCost) || (Number(summary.salesCost) === 0))
    return <></>;
  if (!before || before.salesCost === null || isNaN(before.salesCost) || (Number(before.salesCost) === 0))
    return <></>;
  
  var diff = Number(summary.salesCost) - Number(before.salesCost);
  if (diff == 0) {
    return;
  }
  if (diff > 0) {
    return  <IconUpDown src={UpIcon}/>;
  }
  return <IconUpDown src={DownIcon}/>;
}

//営業利益
function getOperatingIncome(summary) {
  if (!summary)
    return '0';
  else if (summary.operatingIncome === null || isNaN(summary.operatingIncome))
    return '0';
  else if (Number(summary.operatingIncome) === 0)
    return '0';

  return MoneyUtility.formatMoney(summary.operatingIncome);
}

//営業利益(前月比)
function getOperatingIncomeMoM(summary,before) {
  if (!summary || summary.operatingIncome === null || isNaN(summary.operatingIncome) || (Number(summary.operatingIncome) === 0))
    return '0.00';
  if (!before || before.operatingIncome === null || isNaN(before.operatingIncome) || (Number(before.operatingIncome) === 0))
    return '0.00';
  var momRate = Number(summary.operatingIncome)/Number(before.operatingIncome)*100
  return momRate.toFixed(2);
}

//営業利益(アイコン)
function getOperatingIncomeIcon(summary,before) {
  if (!summary || summary.operatingIncome === null || isNaN(summary.operatingIncome) || (Number(summary.operatingIncome) === 0))
    return <></>;
  if (!before || before.operatingIncome === null || isNaN(before.operatingIncome) || (Number(before.operatingIncome) === 0))
    return <></>;
  var diff = Number(summary.operatingIncome) - Number(before.operatingIncome)
  if (diff == 0) {
    return;
  }
  if (diff > 1) {
    return  <IconUpDown src={UpIcon}/>;
  }
  return <IconUpDown src={DownIcon}/>;
}

//利益率
function getProfitRate(summary) {
  if (!summary)
    return '0.00';
  else if (summary.profitRate === null || isNaN(summary.profitRate))
    return '0.00';

  return summary.profitRate.toFixed(2);
}
//利益率(前月比)
function getProfitRateMoM(summary,before) {
  var rate = getProfitRate(summary)
  var beforeRate = getProfitRate(before)
  var momRate = rate/beforeRate*100
  if(!beforeRate) momRate = 0;
  return momRate.toFixed(2);
}

//利益率(アイコン)
function getProfitRateIcon(summary,before) {
  var rate = getProfitRate(summary);
  var beforeRate = getProfitRate(before);
  var diff = rate - beforeRate;
  if (diff==0) {
    return;
  }
  if (diff > 0) {
    return  <IconUpDown src={UpIcon}/>;
  }
  return <IconUpDown src={DownIcon}/>;
}

//前月比
function getSalesMonthToMonth(summary) {
  if (!summary)
    return '0.00';
  else if (summary.salesMonthToMonth === null || isNaN(summary.salesMonthToMonth))
    return '0.00';

  return summary.salesMonthToMonth.toFixed(2);
}

//未達現場数
function getUnachievedWorkplaceNum(summary) {
  if (!summary)
    return '0';
  else if (summary.unachievedWorkplaceNum === null || isNaN(summary.unachievedWorkplaceNum))
    return '0';

  return summary.unachievedWorkplaceNum;
}

//未達現場数(現場数)
function getUnachievedWorkplaceNumMoM(summary,before) {
  var num = getUnachievedWorkplaceNum(summary)
  var beforeNum = getUnachievedWorkplaceNum(before)
  var momRate = num/beforeNum*100
  if(!beforeNum) momRate = 0;
  return momRate.toFixed(2);
}

//未達現場数(アイコン)
function getUnachievedWorkplaceNumIcon(summary,before) {
  var num = getUnachievedWorkplaceNum(summary)
  var beforeNum = getUnachievedWorkplaceNum(before)
  var flag = num/beforeNum*100;
  if(!beforeNum) flag = 0;
  console.log('未達現場数')
  console.log(flag, num, beforeNum);
  if(flag==0){
    return <></>
  }
  if(flag>0){
    return  <IconUpDown src={UpIcon}/>
  }
  return <IconUpDown src={DownIcon}/>;
}

function makeItem1(item, summary, before) {
  switch(item.itemId) {
    case 0:
      if (item.showFlag === true) 
        return <ItemWrap>
            <TitelWrap>予算達成率</TitelWrap>
            <MoMWrap>（前月比 {getBudgetAchievementMoM(summary,before)} ％)</MoMWrap>
            <ValueWrap>
              <Value>{getBudgetAchievementRate(summary)}</Value><Unit>%</Unit>
              <>{getBudgetAchievementIcon(summary,before)}</>
            </ValueWrap>
          </ItemWrap>
      break;
    case 1:
      if (item.showFlag === true)
        return <ItemWrap>
            <TitelWrap>予算前月比</TitelWrap>
            <ValueWrap><Value>{getSalesMonthToMonth(summary)}</Value><Unit>%</Unit></ValueWrap>
          </ItemWrap>
      break;
    case 2:
      if (item.showFlag === true)
        return <ItemWrap>
            <TitelWrap>未達現場数</TitelWrap>
            <MoMWrap>（前月比 {getUnachievedWorkplaceNumMoM(summary,before)} ％)</MoMWrap>
            <ValueWrap>
              <Value>{getUnachievedWorkplaceNum(summary)}</Value><Unit></Unit>
              <>{getUnachievedWorkplaceNumIcon(summary,before)}</>
            </ValueWrap>
          </ItemWrap>
      break;
    default:
      break;
  }
  return <></>
}

function makeItem2(item, summary, before) {
    switch(item.itemId) {
      case 3:
        if (item.showFlag === true) 
          return <ItemWrap style={{backgroundColor:'#dcdcdc'}}>
              <TitelWrap>売上高</TitelWrap>
              <MoMWrap>（前月比 {getSalesMoM(summary,before)} ％)</MoMWrap>
              <ValueWrap>
                <Value>{getSales(summary)}</Value><Unit>円</Unit>
                <>{getSalesIcon(summary,before)}</>
              </ValueWrap>
            </ItemWrap>
        break;
      case 4:
        if (item.showFlag === true)
          return <ItemWrap style={{backgroundColor:'#dcdcdc'}}>
              <TitelWrap>営業利益</TitelWrap>
              <MoMWrap>（前月比 {getOperatingIncomeMoM(summary,before)} ％)</MoMWrap>
              <ValueWrap>
                <Value>{getOperatingIncome(summary)}</Value><Unit>円</Unit>
                <>{getOperatingIncomeIcon(summary,before)}</>
              </ValueWrap>
            </ItemWrap>
        break;
      case 5:
        if (item.showFlag === true)
          return <ItemWrap style={{backgroundColor:'#dcdcdc'}}>
              <TitelWrap>利益率</TitelWrap>
              <MoMWrap>（前月比 {getProfitRateMoM(summary,before)} ％)</MoMWrap>
              <ValueWrap>
                <Value>{getProfitRate(summary)}</Value><Unit>%</Unit>
                {getProfitRateIcon(summary,before)}
              </ValueWrap>
            </ItemWrap>
        break;
      default:
        break;
    }
    return <></>
  }

const orgItemInfos = [
  {itemId:0, showFlag:true, sort:0}
 ,{itemId:1, showFlag:true, sort:1}
 ,{itemId:2, showFlag:true, sort:2}
 ,{itemId:3, showFlag:true, sort:3}
 ,{itemId:4, showFlag:true, sort:4}
 ,{itemId:5, showFlag:true, sort:5}
];

// 右上のKPIカード
const UpperRightKPIInfoCard = (props) => {
  const {
//    grants,
    summary,
    before
  } = props
  const dispatch = useDispatch();
  const { loginUserInfo } = useSelector(state => state);
  const [itemInfos,setItemInfos] = useState(null);
  const [openDialog,setOpenDialog] = useState(false);

  const apiGetScreenShowItemInfo = async (isLoadSplinner) => {
    //console.log('call apiGetScreenShowItemInfo');

    if (!loginUserInfo.staffId)
      return;

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = '/api/getScreenShowItemInfo';
    
    const data = {
      staffId: loginUserInfo.staffId,
      url: '/top',
      itemNum: 1
    }
    const result = await GetApi(apiUrl, data);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      //初期値はデータなし
      setItemInfos(orgItemInfos);
    } else {
      setItemInfos(JSON.parse(result.data.showItemJson));
    }
  }

  // 編集クリックイベント
  const onClickEdit = () => {
    //console.log('onClickEdit');
    setOpenDialog(true);
  }

  // 設定の変更イベント
  const onChange = () => {
    apiGetScreenShowItemInfo();
  }

  // ログイン情報が変更された場合
  useEffect(() => {
    apiGetScreenShowItemInfo();
  }, [loginUserInfo])

  // 描画時に一度だけ実行
  useEffect(() => {
    apiGetScreenShowItemInfo();
  }, [])

  if (!itemInfos) return <CardWrap></CardWrap>
  return (
    <CardWrap>
      <KPIContent>
        {itemInfos.map( item =>
          makeItem1(item, summary, before)
        )}
      </KPIContent>
      <Tooltip title={'設定'}>
        <Icon alt={'設定'} src={SettingIcon} onClick={onClickEdit} />
      </Tooltip>
      <KPIContent>
        {itemInfos.map( item =>
          makeItem2(item, summary, before)
        )}
      </KPIContent>
      <UpperRightKPIInfoSettingModal
        itemInfos={itemInfos}
        modalIsOpen={openDialog}
        setIsOpen={setOpenDialog}
        onChange={onChange}
      />
    </CardWrap>
  )
}

UpperRightKPIInfoCard.propTypes = {
//  grants: PropTypes.object, // 権限関連
    summary: PropTypes.object,
}

export default UpperRightKPIInfoCard

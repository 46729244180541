import React, { useState, useEffect } from 'react'
//import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { GetApi } from '../../../Common/ApiAxios'

import CardTotal from '../Atoms/CardTotal'
import CardTotal2 from '../Atoms/CardTotal2'

const DefaultUrl = '/api/logisticManagement'

const Flex = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 100%;
  height: ${props => props.height ? '290px' : '170px'};
  text-align: center;
  vertical-align: middle;
`

const Content = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  width: 90%;
  height: ${props => props.height ? props.height : '200px'};
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  ::-webkit-scrollbar-corner {
    display: none;
  }
`

// ヘッダー部のカード
const OperationHeader = (props) => {
  const {
    grants,
    dates,
    quantity,
    headerType//2023/12/15 旧タイプに戻すフラグ
  } = props
  
  // 描画時に一度だけ実行
  useEffect(() => {
  }, [])

  // if (!quantity || quantity == null) return null
  return (
    <Flex headerType={headerType}>
      <Content height={'150px'}>
        <CardTotal type={0} quantity={quantity}/>
        <CardTotal type={1} quantity={quantity}/>
        <CardTotal type={2} quantity={quantity}/>
      </Content>
      {/*2023/12/25 K.Harada DEL 総運行時間,平均ドライバ―作業時間,平均ドライバ―待機時間を削除*/}
      {headerType &&
        <Content height={'110px'}>
          <CardTotal2 type={0}/>
          <CardTotal2 type={1}/>
          <CardTotal2 type={2}/>
        </Content>
      }
    </Flex>
  )
}

OperationHeader.propTypes = {
  grants: PropTypes.object, // 権限関連
  dates: PropTypes.array, // 日付情報(1週分)
}
  
export default OperationHeader


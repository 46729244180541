/* eslint-env browser */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import Avatar from '@material-ui/core/Avatar'
import Tooltip from '@material-ui/core/Tooltip'
import { useHistory,useLocation } from 'react-router-dom'

import FUNC from './FunctionEnum'
import * as LogisticUtility from './Utilties/LogisticUtility'
import SettingIcon from '../../image/setting-icon.png'

//import { PostApi, GetApi, exportErrorLog, forceLogout } from './ApiAxios'
//import { setLoginUserInfo } from './Slice/LoginSlice'


const WrapDiv = styled.div`
  margin-right: 8px;
  display: flex;
  color: ${props => props.rideMenu ? '#000' : '#fff'};
  position: ${props => props.rideMenu ? 'absolute' : 'unset'};
  position: relative;
  cursor: pointer;
  justify-content: center;
  :hover {
    border: 1px solid #eee;
  }
  span {
    color: white;
    padding: 12px 16px;
    margin: 12px 16px;
    height: 48px;
    font-size: 16px;
    display: block;
    line-height: 20px;
    width: 150px;
    :hover {
      color: #333;
      background: #ddd;
    }
  }
  a {
    color: white;
    text-decoration: none;
  }
`
const SettingMenu = styled.div`
  background: #333;
  border: 1px solid #ccc;
  border-color: rgba(0, 0, 0, .2);
  color: #000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
  display: ${props => props.showFlg};
  outline: none;
  overflow: hidden;
  position: absolute;
  top: 30px;
  right: 0;
  border-radius: 2px;
  z-index: 99999;
  width: 195px;
`

const SettingInfo = (props) => {
  //const { staffName, staffId } = useSelector(state => state.loginUserInfo);
  const loginUserInfo = useSelector(state => state.loginUserInfo);
  const isExternalUser = LogisticUtility.checkExternalUser(loginUserInfo.grantNameId);
  const dispatch = useDispatch()
  const [showFlg, setShowFlg] = useState('none')
  const history = useHistory();
  const location = useLocation(); 
  const hisState = location.state

  const showMenu = () => {
    setShowFlg(showFlg === 'none' ? 'block' : 'none')
  }
  const closeMenu = () => {
    setShowFlg('none')
  }
  const onOpenStaff=()=>{
    closeMenu()
    var tmp = hisState
    if(tmp) tmp.pathname = location.pathname
    else tmp={pathname:location.pathname}

    //console.log(tmp)
    history.push({state:tmp,pathname:'/master/account'});
  }

  // パス情報取得情報取得
  useEffect(() => {
  // 初回描画時のみ実行
  // eslint-disable-next-line
  }, [])
/*
  useEffect(() => {
    if (staffId) {
      GetApi(`/api/master/staff/${staffId}`)
        .then(result => setPhoto(result.data.facePhotoFile))
      console.log('--- staff photo ---')
    }
  }, [staffId, photo])
*/
  const funcShowFlg = (e) => {
    if (!e.target.closest('#settingInfo')) {
      setShowFlg('none')
    }
  }

  window.onclick = funcShowFlg

  return (
    <Tooltip title={'設定'}>
    <WrapDiv rideMenu={props.rideMenu} onClick={showMenu}  onMouseLeave={()=>closeMenu()} id='settingInfo'>
      <Avatar alt={'設定'} src={SettingIcon} style={{ width: '25px', height: '25px', marginRight: '5px' }} />
      {/* <IconImg src={props.rideMenu ? adminIcon2 : adminIcon} alt='adminIcon' /> */}

      <SettingMenu showFlg={showFlg}>
        {(window.location.pathname == '/logistic/sorterPerformance' || window.location.pathname  == '/logistic') &&
          <span onClick={()=>props.showSettingMenu()}>ソータ画面設定</span>
        }
        {(window.location.pathname == '/logistic/vasPerformance' 
        || window.location.pathname  == '/logistic/vasKantouPerformance'
        ) &&
          <span onClick={()=>props.showSettingMenu()}>種まき画面設定</span>
        }
        {!isExternalUser &&
          <span onClick={onOpenStaff}>アカウント管理</span>
        }
      </SettingMenu>
    </WrapDiv>
    </Tooltip>
  )
}

export default SettingInfo

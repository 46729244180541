import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'

const MediaWrap = styled.div`
  width:1800px;
  display: flex; /* 横並び */
  flex-wrap: wrap;
  margin: 4px;
  box-shadow: 0 0 3px 2px ${props => props.selected ? 'rgba(0,128,0,0.8)' : 'rgba(0,0,0,0.4)'};

  color: #000;
  font-size: 16px;

  background-color:  ${props => props.colorFlag ? 'gray' : ''};
  text-align: center;
  align-items: center;
`
const MediaItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 40px;
  border-right: 2px dashed #fff;
`

const TextWrap = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
` 
const ItemRow = styled.div`
  display: flex; /* 縦並び */
  flex-direction: row;
  text-align: center;
  align-items: center;
`
const ItemColumn = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 75px;
`
const MediaDataItemEmissions = (props) => {
  const {
    data
  } = props

  // 初期値
  useEffect(() => {
  })

  //if (!data) return null;
  //console.log(data);
  return (
    <MediaWrap colorFlag={data.totalItemCount == 1.0}>
      <MediaItem>
        <TextWrap style={{width:'150px', textAlign:'left'}}>
          <div style={{marginLeft:'1rem'}}>{data.cargoId}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'150px', textAlign:'left'}}>
          <div style={{marginLeft:'1rem'}}>{data.itemCd}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'300px', textAlign:'left'}}>
          <div style={{marginLeft:'1rem', fontWeight:'bold'}}>{data.itemName}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'100px', textAlign:'right'}}>
          <div style={{marginRight:'1rem'}}>{LogisticUtility.getCommaText(parseInt(data.totalItem))}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'100px', textAlign:'right'}}>
          <div style={{marginRight:'1rem'}}>{'-'}</div>
        </TextWrap>
      </MediaItem>
    </MediaWrap>
  )
}

MediaDataItemEmissions.propTypes = {
  data: PropTypes.object, // 種まき情報
  searchDate: PropTypes.string, // 検索日時
}

export default MediaDataItemEmissions

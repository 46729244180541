import React from 'react'
//import styled from 'styled-components'
import { useHistory } from "react-router-dom"
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import Tooltip from '@material-ui/core/Tooltip'

//import FUNC from '../Common/FunctionEnum'
//import { PostApi } from '../Common/ApiAxios'

import IconFunctionImg from '../../../image/three-reader-vertical-icon.png'
import IconTopImg from '../../../image/menu-icon3.png'
import SettingIcon from '../../../image/setting-icon.png'
import IconInquiryImg from '../../../image/inquiry-icon.png'
import IconMapImg from '../../../image/map-icon.png'
import IconCalendarImg from '../../../image/calendar-icon.png'
//import IconCloseImg from '../../image/close_icon.png'
import OperationTypeTab from './Molcules/OperationTypeTab'
import SearchWrap ,{ FunctionContent, FunctionContentRow, FunctionIcon, FunctionName} from '../Wrap/BoardSerachWrap'
import MonthCalendar from '../../Common/Atoms/MonthCalendar'
import Calendar from '../../Common/Atoms/Calendar'
const Search = (props) => {
  const {
    addStyle,
    onClickFunction,
    onCloseFunction,
    viewSubSearch,
    viewFunction,
    summaryType,
    onClickSummaryButton,
    searchDateRef
  } = props
  const history = useHistory();
  const loginUserInfo = useSelector(state => state.loginUserInfo);
  //const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC);
  const changeCalendar = (date) => {
    searchDateRef.current = date;
    //setShiftDate(date)
    //onClick()
  }
  async function homePage () {
    history.push('/afstruck/home');
  }
  async function summaryPage () {
    history.push('/afstruck/sumary');
  }
  async function mapPage () {
    history.push('/afstruck/map');
  }
  async function detailPage () {
    history.push('/afstruck/detail');
  }
  //if (!grants) return null;
  return (
    <SearchWrap addStyle={addStyle} viewSubSearch={viewSubSearch} viewFunction={viewFunction}>
      <ul className='mainSearch'>
        <li>
        <OperationTypeTab type={summaryType} onClick={onClickSummaryButton}/>
        {!summaryType &&
          <Calendar changeCalendar={changeCalendar} dataValue={searchDateRef.current} isViewIcon placeholder={'対象年月日'}/>
        }
        {summaryType==1 &&
          <MonthCalendar changeCalendar={changeCalendar} dataValue={searchDateRef.current} isViewIcon placeholder={'対象年月'}/>
        } 
        </li>
        <li className='subSearch'>
        </li>
      </ul>
      <div></div>
      <Tooltip title={'機能メニュー'}>
        <img alt='機能メニュー' src={IconFunctionImg} width="24px" height="24px" style={{marginLeft:'auto',marginTop: '4px', cursor: 'pointer'}} onClick={onClickFunction} />
      </Tooltip>
      <li className='mainFunction'>
        <FunctionContent onMouseLeave={onCloseFunction}>
          <FunctionContentRow style={{marginTop:'12px'}} onClick={homePage}>
            <FunctionIcon alt='ホーム' src={IconTopImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>ホーム</span></FunctionName>
          </FunctionContentRow>
          <hr></hr>
          <FunctionContentRow style={{}} >
            <FunctionIcon alt='サマリ' src={IconInquiryImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>サマリ</span></FunctionName>
          </FunctionContentRow>
          <FunctionContentRow style={{marginTop:'12px'}} onClick={detailPage}>
            <FunctionIcon alt='明細' src={IconInquiryImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>明細</span></FunctionName>
          </FunctionContentRow>
          <FunctionContentRow style={{marginTop:'12px'}}>
            <FunctionIcon alt='マップ' src={IconMapImg} onClick={mapPage}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>マップ</span></FunctionName>
          </FunctionContentRow>
          <FunctionContentRow style={{marginTop:'12px',marginBottom:'12px'}}>
            <FunctionIcon alt='設定' src={SettingIcon}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>設定</span></FunctionName>
          </FunctionContentRow>
          </FunctionContent>
      </li>
    </SearchWrap>
  )
}

Search.propTypes = {
  addStyle: PropTypes.string, // css用追加用
  onClickFunction: PropTypes.func,
  onCloseFunction: PropTypes.func,
  viewSubSearch: PropTypes.bool, // サブ検索項目を表示するか
  viewFunction: PropTypes.bool, // サブ機能を表示するか
}

export default Search

import React from 'react'
import { useHistory } from "react-router-dom"
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import Tooltip from '@material-ui/core/Tooltip'

import FUNC from '../../Common/FunctionEnum'
import { PostApi } from '../../Common/ApiAxios'
import IconCollapseImg from '../../../image/Collapse.png'

import SearchWrap ,{ FunctionContent, FunctionContentRow, FunctionIcon, FunctionName} from '../Wrap/BoardSerachWrap'

import IconFunctionImg from '../../../image/three-reader-vertical-icon.png'
import IconTopImg from '../../../image/menu-icon3.png'
import IconCloseImg from '../../../image/close_icon.png'

const Search = (props) => {
  const {
    addStyle,
    onClickFunction,
    onCloseFunction,
    viewSubSearch,
    viewFunction,
  } = props
  const history = useHistory();
  const loginUserInfo = useSelector(state => state.loginUserInfo);
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC);
  const subGrants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC_PLANNING);

  async function topPage () {
    history.push('/top');
  }

  if (!grants && !subGrants) return null;
  return (
    <SearchWrap addStyle={addStyle} viewSubSearch={viewSubSearch} viewFunction={viewFunction}>
      <h3>{grants.functionMenuName}</h3>
      <img alt='階層' src={IconCollapseImg} width="24px" height="24px" style={{marginTop:'4px'}}/>
      <div className='subTitleItem' style={{fontSize:'16px',margin:'8px 0 0 12px'}}>{subGrants.functionMenuName}</div>
      <ul className='mainSearch'>
        <li>
        </li>
        <li className='subSearch'>
        </li>
      </ul>
      <div></div>
      <Tooltip title={'機能メニュー'}>
        <img alt='機能メニュー' src={IconFunctionImg} width="24px" height="24px" style={{marginTop: '4px',marginLeft: 'auto', cursor: 'pointer'}} onClick={onClickFunction} />
      </Tooltip>
      <li className='mainFunction'>
        <FunctionContent onMouseLeave={onCloseFunction}>
          <FunctionContentRow onClick={topPage} style={{marginTop:'12px',marginBottom:'12px'}}>
            <FunctionIcon alt='トップページ' src={IconTopImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>トップページ</span></FunctionName>
          </FunctionContentRow>
        </FunctionContent>
      </li>
    </SearchWrap>
  )
}

Search.propTypes = {
  addStyle: PropTypes.string, // css用追加用
  onClickFunction: PropTypes.func,
  onCloseFunction: PropTypes.func,
  viewSubSearch: PropTypes.bool, // サブ検索項目を表示するか
  viewFunction: PropTypes.bool, // サブ機能を表示するか
}

export default Search

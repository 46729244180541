import React, { useState, useEffect,useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
//import { useAlert } from 'react-alert'
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'
//import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
//import { useSyncExternalStore } from 'react'

//import FUNC from '../Common/FunctionEnum'

//import LogisticCard from './Atoms/LogisticCard'
//import TruckCard from './Atoms/TruckCard'
//import CoreTimeCard from './Atoms/CoreTimeCard'
//import CoreFirstCard from './Atoms/CoreFirstCard'
//import InfoCard from './Atoms/InfoCard'
import { SetChangeType } from '../../Common/Slice/SettingSlice'
import Search from './Search'
import ProgressPerformance from './ProgressPerformance'
import {BoardBaseFormRow, BoardBaseFormRowScroll} from '../Wrap/BoardBaseWrap'
import * as LogisticUtility from '../../Common/Utilties/LogisticUtility'
import { useLocation } from 'react-router-dom'
import { GetApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'

const DefaultUrl = '/api/afsTruckDashboardManagement/dashboard'

const InfoContent = styled.div`
  width: calc(100% - 10px);
  height: calc(100% - 200px);
`

const Detail = (props) => {
  const dispatch = useDispatch()
  /*2023/12/25 K.Harada MOD 初期値を変更*/
  const history = useLocation(); 
  const hisState = history.state
  const summaryTypeDefalut = hisState && hisState.type?hisState.type:0

  const [summaryType,setSammarytype] = useState(summaryTypeDefalut);
  const [viewFunction,setViewFunction] = useState(false);
  const searchFromDateRef = useRef()
  const searchToDateRef = useRef()
  const companyRef = useRef()
  const workplaceRef = useRef()
  const driverRef = useRef()
  // const [companyList, setCompanyList] = useState([
  //   {id:'000284', name:'昭和冷蔵 厚木物流センター', url:'/logistic/syouwaPerformance'}
  // , {id:'000001', name:'AFS南関東センター', url:'/logistic/vasKantouPerformance'}
  // , {id:'000256', name:'AFSランドポート習志野センター', url:'/logistic/narashinoPerformance'}
  // , {id:'000006', name:'AFS尾西センター', url:'/logistic/vasPerformance'}
  // , {id:'000011', name:'福通 神戸カインズセンター', url:'/logistic/sorterPerformance'}
  // ]);
  // const [workplaceList, setWorkplaceList] = useState([
  //   {id:'000284', name:'昭和冷蔵 厚木物流センター', url:'/logistic/syouwaPerformance'}
  // , {id:'000001', name:'AFS南関東センター', url:'/logistic/vasKantouPerformance'}
  // , {id:'000256', name:'AFSランドポート習志野センター', url:'/logistic/narashinoPerformance'}
  // , {id:'000006', name:'AFS尾西センター', url:'/logistic/vasPerformance'}
  // , {id:'000011', name:'福通 神戸カインズセンター', url:'/logistic/sorterPerformance'}
  // ]);
  const [companyList, setCompanyList] = useState([]);
  const [workplaceList, setWorkplaceList] = useState([]);
  const [driverList, setDriverList] = useState([]);
  const [weekly,setWeekly] = useState();
  
  
  const intervalRef = useRef(null);

  /*2023/12/25 K.Harada MOD 前日進捗を初期値に変更*/
  const [quantity,setQuantity] = useState([]);
  const [orgQuantity,setOrgQuantity] = useState([]);
  // 平均時給情報の取得
  const apiGetAFSDetail = async (isLoadSplinner) => {
    //console.log('call apiGetAvgWageGraph');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getDeliveryResult';
   
    const data = {
      startDt: searchFromDateRef.current,
      endDt: searchToDateRef.current,//LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD'),
    }
    const result = await GetApi(apiUrl, data);

    if (isLoadSplinner) 
      dispatch(SetLoadSpinner(false));

    if (result.errorDetaill) {
      //alert.error('');
      setQuantity([])
      setOrgQuantity([])
    } else {
      console.log(result)
      setOrgQuantity(result.data)
      setQuantity(onFilter(result.data))
    }
  }
  //絞り込み
  const onFilter=(data)=>{
    if(!data) return data
    if(companyRef.current.value){
      data = data.filter((elem) => { return elem.companyCode == companyRef.current.value})
    }
    if(workplaceRef.current.value){
      data = data.filter((elem) => { return elem.workPlaceCode == workplaceRef.current.value})
    }
    if(driverRef.current.value){
      data = data.filter((elem) => { return elem.driverId == driverRef.current.value})
    }
    console.log(companyRef.current.value,driverRef.current.value,workplaceRef.current.value,data)
    return data
  }
  // 会社情報の取得
  const apiGetAFSCompany = async (isLoadSplinner) => {
    //console.log('call apiGetAvgWageGraph');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getCompany';
   
    const data = {
    }
    const result = await GetApi(apiUrl, data);

    if (isLoadSplinner) 
      dispatch(SetLoadSpinner(false));

    if (result.errorDetaill) {
      //alert.error('');
      setCompanyList([])
    } else {
      console.log(result)
      setCompanyList(result.data)
    }
  }
  // 作業場所情報の取得
  const apiGetAFSWorkPlace = async (isLoadSplinner) => {
    //console.log('call apiGetAvgWageGraph');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getWorkPlace';
   
    const data = {
    }
    const result = await GetApi(apiUrl, data);

    if (isLoadSplinner) 
      dispatch(SetLoadSpinner(false));

    if (result.errorDetaill) {
      //alert.error('');
      setWorkplaceList([])
    } else {
      console.log(result)
      setWorkplaceList(result.data)
    }
  }
  // ドライバー情報の取得
  const apiGetAFSDriver = async (isLoadSplinner) => {
      //console.log('call apiGetAvgWageGraph');
      if (isLoadSplinner)
        dispatch(SetLoadSpinner(true));
  
      const apiUrl = DefaultUrl + '/getDriver';
     
      const data = {
        companyCode:""
      }
      const result = await GetApi(apiUrl, data);
  
      if (isLoadSplinner) 
        dispatch(SetLoadSpinner(false));
  
      if (result.errorDetaill) {
        //alert.error('');
        setDriverList([])
      } else {
        console.log(result)
        setDriverList(result.data)
      }
  }
  // 周期初期のクリア
  const onClearInerval = async () => {
    if (intervalRef.current !== null)
      clearInterval(intervalRef.current);
    
    intervalRef.current = null;
  }
  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }

  // 機能メニューの閉じるイベント
  const onCloseFunction = (event) => {
    setViewFunction(false);
  }
  const onClickSummaryButton = (num) => {
    setSammarytype(num)
    dispatch(SetChangeType(num))
  }
  // 描画時に一度だけ実行
  useEffect(() => {
     /*2023/12/25 K.Harada MOD 初期値を変更*/
    dispatch(SetChangeType(summaryTypeDefalut))
    apiGetAFSCompany();
    apiGetAFSWorkPlace();
    apiGetAFSDriver();
    /*2024/01/16 K.Harada ADD マテハン情報をDBから受け取るように変更*/
    apiGetAFSDetail(true);
    /*2024/01/16 K.Harada ADD マテハン情報をDBから受け取るように変更*/
  }, [])
  // POST・PUT処理
  const onSubmit = (data) => {
  }
  const onChange=(fromDate, toDate)=>{
    searchFromDateRef.current = fromDate
    searchToDateRef.current = toDate
    apiGetAFSDetail(true)
  }
  const onChangeFilter=()=>{
    setQuantity(onFilter(JSON.parse(JSON.stringify(orgQuantity))))
  }
  if (!searchFromDateRef.current) {
    searchFromDateRef.current = LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD')
    searchToDateRef.current = LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD')
    // if(hisState && hisState.searchDate){
    //   searchDateRef.current = LogisticUtility.getStringFromDate(new Date(hisState.searchDate), 'YYYY-MM-DD')
    // }else{
    //   searchDateRef.current = LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD')
    // }   
  }
  return (
    <>
      <BoardBaseFormRow>
      <Search
        onClickFunction={onClickFunction}
        onCloseFunction={onCloseFunction}
        viewFunction={viewFunction}
        onClickSummaryButton={(num)=>onClickSummaryButton(num)}
        summaryType={summaryType}
        searchFromDateRef={searchFromDateRef}
        searchToDateRef={searchToDateRef}
        companyRef={companyRef}
        companyList={companyList}
        workplaceRef={workplaceRef}
        workplaceList={workplaceList}
        driverRef={driverRef}
        driverList={driverList}
        onChange={(fromDate, toDate)=>onChange(fromDate, toDate)}
        onChangeFilter={()=>onChangeFilter()}
      />
      </BoardBaseFormRow>
      <BoardBaseFormRow>
        <BoardBaseFormRowScroll>
          <ProgressPerformance quantity={quantity}/>
        </BoardBaseFormRowScroll>
      </BoardBaseFormRow>
    </>
  )
}

export default Detail

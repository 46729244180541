import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import styled from 'styled-components'
//import { useAlert } from 'react-alert'
//import { useDispatch } from 'react-redux'

import CloseIcon from '../../../../image/close_icon.png'

/* 縦並 */
const Wrap = styled.div`
  display:flex;
  flex-flow: column;
`
/* 項目 */
const Item = styled.div`
  display: flex; /* 横並び */
  flex-flow: row nowrap;
  text-align: left;
  vertical-align: middle;
`

const ExplainHeartRateModal  = (props) => {
  const {
    modalIsOpen,
    setIsOpen,
  } = props
  //const dispatch = useDispatch();
  //const alert = useAlert();

  // モーダル
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      //opacity: 0.8,
      padding: '10px',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    },
    overlay: {
      //background: 'rgba(0, 0, 0, 0.7)',
      zIndex: 15
    }
  }

  //ダイアログ開いた時の処理
  function afterOpenModal () {
    //();
  }

  function closeModal () {
    setIsOpen(false);
  }

  useEffect(() => {
  }, [modalIsOpen])

  // 描画時に一度だけ実行
  useEffect(() => {

  }, [])

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel='Explanation SleepSCore Modal'
      ariaHideApp={false}
    >
      <div style={{ width:'500px', height:'200px', marginLeft:'1rem' }}>
        <Wrap>
          <div style={{ height:'20px', marginLeft:'auto', marginRight:'10px', marginTop:'10px', cursor: 'pointer'}}>
            <img alt='閉じる' className='close' src={CloseIcon} title={'ダイアログを閉じる'} width="12px" height="12px" onClick={closeModal} />
          </div>
        </Wrap>
        <Wrap style={{marginTop:'0.25rem'}}>
          <Item style={{marginLeft:'10px'}}>心拍数について</Item>
          <Item style={{marginLeft:'20px', marginTop:'1rem'}}>50〜100未満であれば正常</Item>
          <Item style={{marginLeft:'20px', marginTop:'0.5rem'}}>運動時で120～130ぐらい</Item>
          <Item style={{marginLeft:'20px', marginTop:'0.5rem'}}>50未満の場合不整脈、徐脈の可能性</Item>
          <Item style={{marginLeft:'20px', marginTop:'0.5rem'}}>40で危険な状態</Item>
          <Item style={{marginLeft:'20px', marginTop:'0.5rem'}}>140以上は危険な状態</Item>
        </Wrap>
      </div>
    </Modal>
  )
}

ExplainHeartRateModal.propTypes = {
  modalIsOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
}

export default ExplainHeartRateModal

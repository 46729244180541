import React,{useState,useRef,useEffect} from 'react'
//import styled from 'styled-components'
import { useHistory } from "react-router-dom"
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import Tooltip from '@material-ui/core/Tooltip'

import FUNC from '../../../Common/FunctionEnum'
//import { PostApi } from '../../../Common/ApiAxios'

import Select from '../../../Common/Atoms/Select'
import SearchCalendar from '../../../Common/Atoms/Calendar'

import SearchWrap ,{ FunctionContent, FunctionContentRow, FunctionIcon, FunctionName} from '../../Wrap/BoardSerachWrap'

import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'

import IconCollapseImg from '../../../../image/Collapse.png'
import IconFunctionImg from '../../../../image/three-reader-vertical-icon.png'
import IconTopImg from '../../../../image/menu-icon3.png'
//import IconCloseImg from '../../../../image/close_icon.png'
import IconReturnImg from '../../../../image/return-icon.png'

const Search = (props) => {
  const {
    addStyle,
    onClickFunction,
    onCloseFunction,
    viewSubSearch,
    viewFunction,
    searchDateRef,
    onChange,
    onChangeType,
    ownerCd,
    pattran,
    listFlagRef,
    workplaceId,
    ownerName
  } = props
  const history = useHistory();
  const loginUserInfo = useSelector(state => state.loginUserInfo);
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC);
  const subGrants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC_DASHBOARD);
  const isExternalUser = LogisticUtility.checkExternalUser(loginUserInfo.grantNameId);
  const changeCalendar = (date)=>{
    searchDateRef.current = date
    onChange(date)
  }
  const workplaceRef = useRef();
  const [workplaceList, setWorkplaceList] = useState([
    {id:'000001', name:'AFS南関東センター', url:'/logistic/vasKantouPerformance'}
    , {id:'000006', name:'AFS尾西センター', url:'/logistic/vasPerformance'}
  ]);

  const [ownerList, setOwnerList] = useState([
    {id:'1', name:'尾西'},{id:'2', name:'MV静岡'},{id:'3', name:'MV東海'}
  ]);

  const [pattranList, setPattranList] = useState([
    {id:undefined, name:'-'},{id:'米総リテール', name:'米総リテール'},{id:'米飯　BIG北・信', name:'米飯　BIG北・信'},{id:'米飯　BIG中松愛', name:'米惣 中松愛'}
  ]);

  const onChangeWorkplace = (e) => {
    //console.log(workplaceRef.current.value);

    let id = workplaceRef.current.value;
    let workplace = workplaceList.find(element => element.id === id);
    if (workplace) {
      switch(id){
        case '000001':
          history.push('/logistic/vasKantouPerformance');
          break;
        case '000011':
          history.push('/logistic/sorterPerformance');
          break;
        case '000006':
          history.push('/logistic/vasPerformance');
          break;
        case '000284':
          history.push('/logistic/syouwaPerformance');
          break;
        case '000256':
          history.push('/logistic/narashinoPerformance');
          break;
        default:
          break
      }
    }
  }

  async function topPage () {
    history.push('/top');
  }
  async function vasPerformance () {
    var state = {searchDate:searchDateRef.current}
    var pathname = '/logistic/vasPerformance'
    workplaceList.map((value)=>{
      if(value.id == workplaceId) pathname = value.url
    })
    history.push({state:state,pathname:pathname});
  }
  useEffect(() => {
    if (ownerName) {
      setOwnerList(
        [{id:ownerCd, name:ownerName}]
      );
    }
  }, [ownerName]);
  if (!grants && !subGrants) return null;
  return (
    <SearchWrap addStyle={addStyle} viewSubSearch={viewSubSearch} viewFunction={viewFunction}>
      <h3>{grants.functionMenuName}</h3>
      <img alt='階層' src={IconCollapseImg} width="24px" height="24px" style={{marginTop:'4px'}}/>
      <div className='subTitleItem' style={{fontSize:'16px',margin:'8px 0 0 12px'}}>{'種まきデータ実績照会'}</div>
      <div style={{margin:'0 0px 0 0'}}>
      <Select selectRef={workplaceRef} onChange={onChangeWorkplace} width={'11rem'} disabled>
            {workplaceList.map(item => (
              <option key={'work_'+item.id} value={item.id} selected={item.id==workplaceId}>{item.name}</option>
            ))}
      </Select>
      </div>
      <div style={{fontSize:'16px',margin:'8px 0 0 0px'}}>{'対象日付'}</div>
      <div style={{margin:'0 0 0 0'}}>
        <SearchCalendar changeCalendar={changeCalendar} dataValue={searchDateRef.current} isViewIcon disabled/>
      </div>
      <div style={{fontSize:'16px',margin:'8px 0 0 0px'}}>{'荷主'}</div>
      <div style={{margin:'0 0 0 0'}}>
      <Select  width={'7rem'} disabled>
        {ownerList.map(item => (
          <option key={'ownerCd_'+item.id} value={item.id} selected={item.id==ownerCd}>{item.name}</option>
        ))}
      </Select>
      </div>
      <div style={{fontSize:'16px',margin:'8px 0 0 0px'}}>{'パターン'}</div>
      <Select  width={'11rem'} disabled>
        <option key={'ownerCd_'+0} value={0} selected={true}>{pattran}</option>
      </Select>
      <div style={{margin:'0 0 0 0'}}>
      </div>
      <div style={{fontSize:'16px',margin:'8px 0 0 0px'}}>{'ソート'}</div>
      <div style={{margin:'0 0 0 0'}}>
      <Select selectRef={listFlagRef} onChange={(e)=>onChangeType(e)} width={'5rem'}>
        <option key={'sort_0'} value={0} selected={true}>{'昇順'}</option>
        <option key={'sort_1'} value={1} >{'降順'}</option>
      </Select>
      </div>
      <ul className='mainSearch'>
        <li>
        </li>
        <li className='subSearch'>
        </li>
      </ul>
      <div></div>
      <Tooltip title={'機能メニュー'}>
        <img alt='機能メニュー' src={IconFunctionImg} width="24px" height="24px" style={{marginTop: '4px',marginLeft: 'auto', cursor: 'pointer'}} onClick={onClickFunction} />
      </Tooltip>
      <li className='mainFunction'>
        <FunctionContent onMouseLeave={onCloseFunction}>
          {!isExternalUser &&
          <>
          <FunctionContentRow onClick={topPage} style={{marginTop:'12px'}}>
            <FunctionIcon alt='トップページ' src={IconTopImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>トップページ</span></FunctionName>
          </FunctionContentRow>
          <hr></hr>
          </>
          }
          <FunctionContentRow onClick={vasPerformance} style={{marginTop:'12px',marginBottom:'12px'}}>
            <FunctionIcon alt='戻る' src={IconReturnImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>戻る</span></FunctionName>
          </FunctionContentRow>
        </FunctionContent>
      </li>
    </SearchWrap>
  )
}

Search.propTypes = {
  addStyle: PropTypes.string, // css用追加用
  onClickFunction: PropTypes.func,
  onCloseFunction: PropTypes.func,
  viewSubSearch: PropTypes.bool, // サブ検索項目を表示するか
  viewFunction: PropTypes.bool, // サブ機能を表示するか
}

export default Search

import React, { useEffect } from 'react'
//import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'

//import { GetApi } from '../../../Common/ApiAxios'
import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'
import MaterialHandlingHeader  from './MaterialHandlingHeader'
import MaterialHandlingItem  from './MaterialHandlingItem'

//const DefaultUrl = '/api/logisticManagement'

const CardWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: ${props => props.width ? props.width : '100%'};
  height: 100%;

  border: none;

  color: #000;

  margin-bottom: 10px;
  
`

const HeaderContent = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 100%;
  text-align:left;
  height: calc(20% + 10px);
`

const Title = styled.span`
  width: 100%;
  font-size: ${props => props.fontSize ? props.fontSize : '2.2vw'};
  margin:auto;
`

const BodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 80%;
  overflow: hidden;
`

export const ListUl = styled.ul`
  width: ${props => props.width ? props.width : '100%'};
  height: ${props => props.height ? props.height : '400px'};
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    border:none;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(112, 112, 112);
    border-radius: 10px;
  }
`

export const ListLi = styled.li`
  width: ${props => props.width ? props.width : 'calc(100%-18px)'};
  display: flex; /* 横並び */
  flex-direction: row;
  text-indent: 10px;
  background: ${props => props.selected ? '#048b63' : '#fff'};
  color: ${props => props.selected ? '#fff' : '#333'};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  .listId{
    margin-right:1vw;
  }
  :nth-child(even){
    background: ${props => props.selected ? '#048b63' : '#eee'};
    color: ${props => props.selected ? '#fff' : '#333'};
  }
  :hover {
    background: #ccc;
    color: #333;
  }
`
/*2023/12/25 K.Harada ADD type追加 0:マテハン出入状況,1:マテハン在庫数*/
function getTitle(type) {
  var title = '';
  switch(type) {
    case 0:
      title = 'マテハン出入状況';
      break;
    case 1:
      title = 'マテハン在庫数';
      break;
    default:
      title = '';
      break;
  }

  return title;
}

// ヘッダー部のカード
const CardTotal = (props) => {
  const {
    grants,
    totalData,
    type,
  } = props
  //const alert = useAlert();
  //const interval1Ref = useRef(null);

  // 描画時に一度だけ実行
  useEffect(() => {
  }, [])

  //if (!grants || !totalData) return null
  return (
    <CardWrap width={'100%'}>
      <HeaderContent>
        <Title fontSize={'2vw'}>{getTitle(type)}</Title>
      </HeaderContent>
      <BodyContent>
        <MaterialHandlingHeader/>
        <ListUl width={'100%'} height={'100%'}>
          <MaterialHandlingItem data={0} type={type}/>
          <MaterialHandlingItem data={1} type={type}/>
        </ListUl>
      </BodyContent>
    </CardWrap>
  )
}

CardTotal.propTypes = {
  grants: PropTypes.object, // 権限関連
  totalData: PropTypes.object, // 集計情報
  type: PropTypes.number, // 0:全体進捗率 1:終了予定時刻 2:全体稼働率 3:事故発生件数 4:投資可能額
}
  
export default CardTotal

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import styled from 'styled-components'
import { useAlert } from 'react-alert'
import { useDispatch } from 'react-redux'
import { XAxis ,YAxis, Tooltip, Legend, LineChart, Line, CartesianGrid, ComposedChart, Bar, Cell,LabelList } from 'recharts'

import { GetApi } from '../../../Common/ApiAxios'
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'
import TabSelector from '../../../Common/Atoms/TabSelector'
import Select from '../../../Common/Atoms/Select'
import * as HealthCareUtilty from '../../../Common/Utilties/HealthCareUtilty'

import MediaStaffHeaderHealthCareDaily from '../Atoms/MediaStaffHeaderHealthCareDaily'
import MediaStaffDataHealthCareDaily from '../Atoms/MediaStaffDataHealthCareDaily'
import * as BaseWrap from '../../Wrap/BoardBaseWrap'

import CloseIcon from '../../../../image/close_icon.png'

const DefaultUrl = '/api/truckManagement'

/* 横並 */
const Flex = styled.div`
  display: flex; /* 横並び */
  flex-flow: row nowrap;
`

/* 縦並 */
const Wrap = styled.div`
  display:flex;
  flex-flow: column;
`

const StaffId = styled.span`
  width: 170px;
  font-size: 10px;
  margin-left: 2.5rem;
  margin-top: 0.25rem;
`

const StaffName = styled.span`
  font-size: 16px;
  margin-left: 2rem;
  margin-top: auto;
  margin-bottom: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
`
const colors = ['red','skyblue','deepskyblue','navy','red','orange','gold']
const ModeList = 0 // リストモード
const ModeDailyGraph = 1 // 日グラフモード
const ModeMonthlyGraph = 2 // 月グラフモード

const StaffHealthCareModal  = (props) => {
  const {
    grants,
    staffData,
    modalIsOpen,
    setIsOpen
  } = props
  const dispatch = useDispatch();
  const alert = useAlert();
  const [healthCareDailyList, setHealthCareDailyList] = useState([]);
  const [healthCareSummary, setHealthCareSummary] = useState([]);
  const [selectTab, _setSelectTab] = useState(ModeList);
  const setSelectTab = (value) => {
    //console.log('call setSelectTab');
    _setSelectTab(value);

    if (value === ModeList) {
    }
    else if (value === ModeDailyGraph) {
      createDailyraphData(selectDateValue);
    }
    else if (value === ModeMonthlyGraph) {
    }
  }
  const tabList = [
    { value: ModeList, name: 'リスト' }
    , { value: ModeDailyGraph, name: '日グラフ' }
    , { value: ModeMonthlyGraph, name: '月グラフ' }
  ]
  const [sleepList, setSleepList] = useState([]);
  const [sleepSummary, setSleepSummary] = useState({});
  const [sleepSummaryDataList, setSleepSummaryDataList] = useState([]);
  const [heartRateList, setHeartRateList] = useState([]);
  const [heartRateSummary, setHeartRateSummary] = useState({});
  const [heartRateZoneList, setHeartRateZoneList] = useState([]);

  const [heartRateZones, setHeartRateZones] = useState([]);
  const [stepList, setStepList] = useState([]);
  const [stepSummary, setStepSummary] = useState({});
  const [distanceList, setDistanceList] = useState([]);
  
  const [dateRows,setDateRows] = useState(<></>);
  const [selectDateValue,setSelectDateValue] = useState(-1);

  // モーダル
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      //opacity: 0.8,
      padding: '10px',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    },
    overlay: {
      //background: 'rgba(0, 0, 0, 0.7)',
      zIndex: 10
    }
  }

  const createDateUIItem = () => {
    //consostaffData.targetYMD;
    var targetYMD = new Date(staffData.targetYMD);
    var getsumatsu = new Date(targetYMD.getFullYear(), targetYMD.getMonth() + 1, 0);
    var max = getsumatsu.getDate();
   
    var list = [];
    for(var i = 0; i < max; i++) {
      var date = HealthCareUtilty.getStringFromDate(new Date(targetYMD.getFullYear(), targetYMD.getMonth(), i+1), 'MM月DD日');
      list.push(<option key={'date-'+ i} value={i} >{date}</option>); 
    }
    setDateRows(list);
  }

  // 日付コンボボックス選択変更イベント
  const onDateChange = (event) => {
    event.persist();
    setSelectDateValue(event.target.value);
  }

  const makeSleepTime=(value)=>{
    if(value){
      var hour = Math.floor(value/60) 
      var min = Math.floor(value%60) 
      if(value<60){
        return <span>{min}<span style={{fontSize:'50%'}}>分</span></span>
      }
      return <span>{hour}<span style={{fontSize:'50%'}}>時間</span>{min != 0 && <>{min}<span style={{fontSize:'50%'}}>分</span></>}</span>
    }else{
      return '-'
    }
  }

  const makeSleepTimeLabel=(value)=>{
    if(value){
      var hour = Math.floor(value/60) 
      var min = Math.floor(value%60) 
      if(value<60){
        return min+'分'
      }
      if(min==0){
        return hour+'時間'
      }
      return hour+'時間'+min+'分'
    }else{
      return '0分'
    }
  }
  const createDailyraphData = (index) => {
    //console.log('call createDailyraphData');
    if (healthCareDailyList !== null && index !== -1 && healthCareDailyList.length > index) {
      var item = healthCareDailyList[index];
      var stepSummaryData = {steps:item.steps,distance:item.distance.toFixed(2),calorie:item.calorie}

      var sleep = item.sleepJson.length!=0?JSON.parse(item.sleepJson):undefined;
      var step = JSON.parse(item.stepsJson);
      var distance = JSON.parse(item.distanceJson);
      var heartRate = JSON.parse(item.heartRateJson);
      
      var sleepData = [];
      var sleepSummaryData = []
      var sleepSummary = {}
     
      if (sleep && sleep.sleep.length > 0) {
        /*
        sleepData = sleep.sleep[0].levels.data;
        sleepData.map(item => {
          item.dateTime = item.dateTime.split('T')[1].split('.')[0];
          switch(item.level) {
            case 'wake':
              //item.level = '4.目覚めた状態';
              item.ykey = 4;
              break;
            case 'rem':
              //item.level = '3.レム睡眠';
              item.ykey = 3;
              break;
            case 'light':
              //item.level = '2.浅い睡眠';
              item.ykey = 2;
              break;
            case 'deep':
              //item.level = '1.深い睡眠';
              item.ykey = 1;
              break;
            default:
              //item.level = '';
              item.ykey = 0;
              break;
          }
        });
        */

        var tmpData = sleep.sleep[0].levels.data;
        for (var i = 0; i < tmpData.length; i++) {
          var item  = tmpData[i];

          var date = new Date(item.dateTime);
          switch(item.level) {
            case 'wake':
              //item.level = '4.目覚めた状態';
              item.ykey = 4;
              break;
            case 'rem':
              //item.level = '3.レム睡眠';
              item.ykey = 3;
              break;
            case 'light':
              //item.level = '2.浅い睡眠';
              item.ykey = 2;
              break;
            case 'deep':
              //item.level = '1.深い睡眠';
              item.ykey = 1;
              break;
            default:
              //item.level = '';
              item.ykey = 0;
              break;
          }

          var copyItem = Object.assign({}, JSON.parse(JSON.stringify(item)));
          
          item.dateTime = HealthCareUtilty.getStringFromDate(date, 'hh:mm:ss');
          date.setSeconds(date.getSeconds() + item.seconds);
          copyItem.dateTime = HealthCareUtilty.getStringFromDate(date, 'hh:mm:ss');

          sleepData.push(item);
          sleepData.push(copyItem);
        }

        var sleepSummaryTmpData = sleep.sleep[0].levels.summary;
        if(sleepSummaryTmpData && sleepSummaryTmpData.wake){
          var item = sleepSummaryTmpData.wake
          item.name = '目覚めた状態'
          item.raito = (item.minutes/sleep.sleep[0].timeInBed*100).toFixed(0)
          item.label = item.name+'\n'+makeSleepTimeLabel(item.minutes)
          sleepSummaryData.push(item)
        }
        if(sleepSummaryTmpData && sleepSummaryTmpData.rem){
          var item = sleepSummaryTmpData.rem
          item.name = 'レム睡眠'
          item.raito = (item.minutes/sleep.sleep[0].timeInBed*100).toFixed(0)
          item.label = item.name+'\n'+makeSleepTimeLabel(item.minutes)
          sleepSummaryData.push(item)
        }
        if(sleepSummaryTmpData && sleepSummaryTmpData.light){
          var item = sleepSummaryTmpData.light
          item.name = '浅い睡眠'
          item.raito = (item.minutes/sleep.sleep[0].timeInBed*100).toFixed(0)
          item.label = item.name+'\n'+makeSleepTimeLabel(item.minutes)
          sleepSummaryData.push(item)
        }
        if(sleepSummaryTmpData && sleepSummaryTmpData.deep){
          var item = sleepSummaryTmpData.deep
          item.name = '深い睡眠'
          item.raito = (item.minutes/sleep.sleep[0].timeInBed*100).toFixed(0)
          item.label = item.name+'\n'+makeSleepTimeLabel(item.minutes)
          sleepSummaryData.push(item)
        }

        sleepSummary.startTime = sleep.sleep[0].startTime.split('T')[1].split('.')[0].slice(0,5);
        sleepSummary.endTime = sleep.sleep[0].endTime.split('T')[1].split('.')[0].slice(0,5);
        sleepSummary.minutesAsleep = sleep.sleep[0].minutesAsleep

        var tmp = Math.max.apply(null,sleepSummaryData.map(function(s){return s.minutes;}))
        sleepSummary.maxValue = (tmp - tmp%50)*2 + 150
      }

      var stepData = [];
      if (step) {
        stepData = step['activities-steps-intraday'].dataset;
      }
      
      var distanceData = [];
      if (distance) {
        distanceData = distance['activities-distance-intraday'].dataset;
      }

      var heartRateSummaryData = {}
      var heartRateData = [];
      if (heartRate) {
        heartRateData = heartRate['activities-heart-intraday'].dataset;

        heartRateSummaryData.max = Math.max.apply(null,heartRateData.map(function(o){return o.value;}));
        heartRateSummaryData.min = Math.min.apply(null,heartRateData.map(function(o){return o.value;}));
        heartRateSummaryData.restingHeartRate = heartRate['activities-heart'][0].value.restingHeartRate
      }

      var heartRateZonesData = [];
      if (heartRate) {
        heartRateZonesData = heartRate['activities-heart'][0].value.heartRateZones;
        heartRateSummaryData.sumMin = 0
        heartRateSummaryData.maxGraph = 50
        heartRateSummaryData.calorie = 0
        heartRateZonesData.map(item => {
          switch(item.name) {
            case 'Out of Range':
              item.name = 'その他';
              break;
            case 'Fat Burn':
              item.name = '脂肪燃焼ゾーン';
              item.label = makeSleepTimeLabel(item.minutes)+'\n'+item.name
              break;
            case 'Cardio':
              item.name = '有酸素運動ゾーン';
              item.label = makeSleepTimeLabel(item.minutes)+'\n'+item.name
              break;
            case 'Peak':
              item.name = 'ピーク';
              item.label = makeSleepTimeLabel(item.minutes)+'\n'+item.name
              break;
            default:
              item.name = '';
              break;
          } 

          var maxGraph = 0
          if(item.name != 'その他') {
            heartRateSummaryData.sumMin  += item.minutes
            heartRateSummaryData.calorie  += Number(item.caloriesOut)
            maxGraph = (item.minutes-(item.minutes%50))*2+100
          }
          if(maxGraph>heartRateSummaryData.maxGraph)
          heartRateSummaryData.maxGraph  = maxGraph
          
        });
      }

      setSleepList(sleepData);
      setSleepSummaryDataList(sleepSummaryData)
      setSleepSummary(sleepSummary)
      setStepList(stepData);
      setDistanceList(distanceData);
      setHeartRateList(heartRateData);
      setHeartRateZoneList(heartRateZonesData.filter((item) => item.name !== 'その他').reverse());
      setHeartRateZones(heartRateZonesData);
      setHeartRateSummary(heartRateSummaryData)
      setStepSummary(stepSummaryData)
    } else {
      setSleepList([]);
      setSleepSummaryDataList([])
      setSleepSummary({})
      setStepList([]);
      setDistanceList([]);
      setHeartRateList([]);
      setHeartRateZoneList([])
      setHeartRateZones([]);
      setHeartRateSummary({})
      setStepSummary({})
    }
  }
  
  // スタッフの月単位の健康情報の取得
  const getHealthCareStaffMonthly = async () => {
    //console.log('call getHealthCareStaffMonthly');
    dispatch(SetLoadSpinner(true));

    if (!staffData) {
      setSelectDateValue(-1);
      createDailyraphData(-1);
      return;
    }

    setSelectDateValue(new Date(staffData.targetYMD).getDate()-1);
    const apiUrl = DefaultUrl + '/getHealthCareStaffMonthly';
    
    const data = {
      targetYM: staffData.targetYMD,
      staffId: staffData.staffId,
    }
    const result = await GetApi(apiUrl, data);

    dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      //alert.error('条件の該当データがありません(スタッフの月単位の健康情報の取得)');
      setHealthCareDailyList([]);
      createDailyraphData(-1);
      setHealthCareSummary({sleepSummaryList:[]})
    } else {
      var healthCareSummaryData={
        steps:0,distance:0,calorie:0,
        minutesAsleep:0,sleepCount:0,sleepSummaryList:[],sleepDataCount:0,
        heartRateZonesMinutes:0, heartRateZonesCalorie: 0,heartRateZonesList:[],heartRateZonesCount:0,
        restingHeartRate: 0, restingHeartRateCount: 0
      }

      var sleepSummaryData =  [{name:'目覚めた状態',minutes:0},{name:'レム睡眠',minutes:0},{name:'浅い睡眠',minutes:0},{name:'深い睡眠',minutes:0}]
      var heartRateZonesData = [{name:'ピーク',minutes:0},{name:'有酸素運動ゾーン',minutes:0},{name:'脂肪燃焼ゾーン',minutes:0}];
      setHealthCareDailyList(result.data.map(item => {
        //歩数集計データ用
        healthCareSummaryData.steps += item.steps
        healthCareSummaryData.distance += item.distance
        healthCareSummaryData.calorie += item.calorie

        if (!(item.sleepJson == null || item.sleepJson == "")) {
          //睡眠集計データ用
          var sleep = JSON.parse(item.sleepJson);
          if (sleep && sleep.sleep.length > 0) {
            var sleepSummaryTmpData = sleep.sleep[0].levels.summary;
            if(sleepSummaryTmpData && sleepSummaryTmpData.wake){
              var entry = sleepSummaryTmpData.wake
              sleepSummaryData[0].minutes += entry.minutes
            }
            if(sleepSummaryTmpData && sleepSummaryTmpData.rem){
              var entry = sleepSummaryTmpData.rem
              sleepSummaryData[1].minutes += entry.minutes
            }
            if(sleepSummaryTmpData && sleepSummaryTmpData.light){
              var entry = sleepSummaryTmpData.light
              sleepSummaryData[2].minutes += entry.minutes
            }
            if(sleepSummaryTmpData && sleepSummaryTmpData.deep){
              var entry = sleepSummaryTmpData.deep
              sleepSummaryData[3].minutes += entry.minutes
            }
            if(!sleepSummaryTmpData.asleep){
              healthCareSummaryData.sleepDataCount += 1
            }
            healthCareSummaryData.minutesAsleep += sleep.sleep[0].minutesAsleep
            healthCareSummaryData.sleepCount += 1
          }
        }
        if (!(item.heartRateJson == null || item.heartRateJson == "")) {
          //心拍数集計データ用
          var heartRate = JSON.parse(item.heartRateJson);
          if (heartRate && heartRate['activities-heart-intraday'].dataset.length > 0) {
            var tmpHeartRateZonesData = heartRate['activities-heart'][0].value.heartRateZones;
            if(tmpHeartRateZonesData) healthCareSummaryData.heartRateZonesCount+=1
            tmpHeartRateZonesData.map(entry => {
              switch(entry.name) {
                case 'Out of Range':
                  entry.name = 'その他';
                  break;
                case 'Fat Burn':
                  entry.name = '脂肪燃焼ゾーン';
                  heartRateZonesData[2].minutes += entry.minutes
                  break;
                case 'Cardio':
                  entry.name = '有酸素運動ゾーン';
                  heartRateZonesData[1].minutes += entry.minutes
                  break;
                case 'Peak':
                  entry.name = 'ピーク';
                  heartRateZonesData[0].minutes += entry.minutes
                  break;
                default:
                  entry.name = '';
                  break;
              } 

              if(entry.name != 'その他') {
                healthCareSummaryData.heartRateZonesMinutes  += entry.minutes
                healthCareSummaryData.heartRateZonesCalorie  += Number(entry.caloriesOut)
              }            
            });
            if(!isNaN(Number(heartRate['activities-heart'][0].value.restingHeartRate))){
              healthCareSummaryData.restingHeartRateCount += 1
              healthCareSummaryData.restingHeartRate += heartRate['activities-heart'][0].value.restingHeartRate
            }
          }
        }
  
        return {
          staffId: item.staffId,
          staffName: item.staffName,
          targetYMD: item.targetDt,
          week: item.week,
          publicHoliday: item.publicHoliday,
          steps: item.steps,
          distance: item.distance,
          calorie: item.calorie,
          sleepTime: (Number(item.sleepTime)/60),
          sleepTimeMinutes: item.sleepTime,
          sleepScore: item.sleepScore,
          heartRate: item.heartRate,
          bodyTemperature: item.bodyTemperature,
          fatigue: item.fatigue,
          alcoholCheck: item.alcoholCheck,
          stepsJson: item.stepsJson,
          distanceJson: item.distanceJson,
          calorieJson: item.calorieJson,
          sleepJson: item.sleepJson,
          heartRateJson: item.heartRateJson,
          totalPlanWorkTime: item.totalPlanWorkTime,
          totalPlanRestTime: item.totalPlanRestTime,
          totalPlanOverTime: item.totalPlanOverTime,
          totalPlanWorkStaffNum: item.totalPlanWorkStaffNum,
          totalPlanWorkDtNum: item.totalPlanWorkDtNum,
          totalResultWorkTime: item.totalResultWorkTime,
          totalResultRestTime: item.totalResultRestTime,
          totalResultOverTime: item.totalResultOverTime,
          totalResultWorkStaffNum: item.totalResultWorkStaffNum,
          totalResultWorkDtNum: item.totalResultWorkDtNum,
          week40overtimeFlag: item.week40overtimeFlag,
          worktimeDailyOvertimeFlag: item.worktimeDailyOvertimeFlag,
          worktimeWeeklyOvertimeFlag: item.worktimeWeeklyOvertimeFlag,
          worktimeMonthlyOvertimeFlag: item.worktimeMonthlyOvertimeFlag,
          sleepScoreAlertFlag: item.sleepScoreAlertFlag,
          sleepTimeAlertFlag: item.sleepTimeAlertFlag,
          fatigueAlertFlag: item.fatigueAlertFlag,
        }
      }));
      createDailyraphData(new Date(staffData.targetYMD).getDate()-1);
      //集計データ作成
      //睡眠
      if(healthCareSummaryData.minutesAsleep != 0)
        healthCareSummaryData.minutesAsleep = (healthCareSummaryData.minutesAsleep/healthCareSummaryData.sleepCount).toFixed(0)
      sleepSummaryData.map((item,index)=>{
        if(healthCareSummaryData.sleepDataCount>0) item.minutes = (item.minutes/healthCareSummaryData.sleepDataCount).toFixed(0)
        item.label = item.name+'\n'+makeSleepTimeLabel(item.minutes)
      })
      var tmp = Math.max.apply(null,sleepSummaryData.map(function(s){return s.minutes;}))
      healthCareSummaryData.sleepMaxValue = (tmp - tmp%50)*2 + 150
      healthCareSummaryData.sleepSummaryList = sleepSummaryData
      //歩数
      healthCareSummaryData.distance = healthCareSummaryData.distance.toFixed(2)
      setHealthCareSummary(healthCareSummaryData)
      //心拍数
      if(healthCareSummaryData.heartRateZonesCount > 0){
        healthCareSummaryData.heartRateZonesMinutes = (healthCareSummaryData.heartRateZonesMinutes/healthCareSummaryData.heartRateZonesCount).toFixed(0)
        healthCareSummaryData.heartRateZonesCalorie = (healthCareSummaryData.heartRateZonesCalorie/healthCareSummaryData.heartRateZonesCount).toFixed(0)
        healthCareSummaryData.restingHeartRate = (healthCareSummaryData.restingHeartRate/healthCareSummaryData.restingHeartRateCount).toFixed(0)
      }
      
      heartRateZonesData.map((item,index)=>{
        if(healthCareSummaryData.heartRateZonesCount>0) item.minutes = (item.minutes/healthCareSummaryData.heartRateZonesCount).toFixed(0)
        item.label = makeSleepTimeLabel(item.minutes)+'\n'+item.name
      })
      var tmp = Math.max.apply(null,heartRateZonesData.map(function(s){return s.minutes;}))
      healthCareSummaryData.heartRateZonesMaxValue = (tmp - tmp%50)*2 + 150
      healthCareSummaryData.heartRateZonesList = heartRateZonesData
    }
  }

  //ダイアログ開いた時の処理
  function afterOpenModal () {
    //();
  }

  function closeModal () {
    _setSelectTab(ModeList);
    setIsOpen(false);
  }
/*
  const CustomTooltip = ({ active, payload, label,unit }) => {
    var i=-1
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{backgroundColor:'white',padding:'10px'}}>
          <p className="desc">{label}</p>
          {payload.map((pay)=>{
            i++
            return <p className={"label"+pay.name} style={{color:`${pay.color}`}}>{`${pay.name} : ${parseFloat(pay.value).toFixed(2)}${unit[i]}`}</p>
          })
          }
        </div>
      );
    }
    return null;
  };
  const CustomTooltipReverse = ({ active, payload, label,unit }) => {
    var i=-1
    var tooltipValue = payload.map((pay)=>{
      i++
      return <p className={"label"+pay.name} style={{color:`${pay.color}`}}>{`${pay.name} : ${parseFloat(pay.value).toFixed(2)}${unit[i]}`}</p>
    })
    const revaresedTooltipValue = [...tooltipValue].reverse()
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{backgroundColor:'white',padding:'10px'}}>
          <p className="desc">{label}</p>
          {revaresedTooltipValue.map((tooltip)=>{
            return tooltip
          })
          }
        </div>
      );
    }
    return null;
  };
  const CustomTooltipMoveCal = ({ active, payload, label,unit }) => {

    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{backgroundColor:'white',padding:'10px'}}>
          <p className="desc">{label}</p>
          <p className={"label"+payload[0].name} style={{color:`${payload[0].color}`}}>{`${payload[0].name} : ${parseFloat(payload[0].value).toFixed(2)}${unit[0]}`}</p>
          <p className={"label"+payload[1].name} style={{color:`${payload[1].color}`}}>
            {`${payload[1].name}（${payload[2].name}） : ${parseFloat(payload[1].value).toFixed(2)}${unit[1]}（${parseFloat(payload[2].value).toFixed(2)}${unit[2]}）`}
          </p>
        </div>
      );
    }
    return null;
  };
*/

  useEffect(() => {
    createDailyraphData(selectDateValue);
  }, [selectDateValue]);

  useEffect(() => {
    if (modalIsOpen) {
      createDateUIItem();
      getHealthCareStaffMonthly();
    }
  }, [modalIsOpen])

  // 描画時に一度だけ実行
  useEffect(() => {
  }, [])

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel='Create Zone Modal'
      ariaHideApp={false}
    >
      <div style={{ width: '1700px', height: '78vh', marginLeft:'1rem' }}>
        <Wrap>
          <div style={{ height:'20px', marginLeft:'auto', marginRight:'10px', marginTop:'10px', cursor: 'pointer'}}>
            <img alt='閉じる' className='close' src={CloseIcon} title={'ダイアログを閉じる'} width="12px" height="12px" onClick={closeModal} />
          </div>
          <Flex  style={{marginBottom:'10px'}}>
            <TabSelector
              selectValue={selectTab}
              setSelectValue={setSelectTab}
              tabsName='activeStaffTab'
              dataList={tabList}
              margin='0'
            />
            <Wrap>
              <StaffName>{staffData.staffName}</StaffName>
              <StaffId>{staffData.staffId}</StaffId>
            </Wrap>
            {selectTab === ModeDailyGraph &&
            <Select
              name={'dailyDate'}
              width='10rem'
              onChange={onDateChange}
              selectValue={selectDateValue}
            >
              {dateRows}
            </Select>
            }
          </Flex>
          {selectTab === ModeList &&
            <>
            <div style={{width:'1680px'}}>
              <MediaStaffHeaderHealthCareDaily />
              <BaseWrap.ListUl height={'600px'}>
              {healthCareDailyList.map(item => (
                  <BaseWrap.ListLi key={'shealthCareDaily' + item.staffId + '_' +item.targetYMD} style={{cursor: 'default'}}>
                  <MediaStaffDataHealthCareDaily grants={grants} healthCareData={item} />
                  </BaseWrap.ListLi>
              ))}
              </BaseWrap.ListUl>
            </div>
            </>
          }
          {/* 日グラフ */}
          {selectTab === ModeDailyGraph &&
            <>
            <div style={{display:'block'}}>
              <div style={{margin:'auto'}}>
                <div style={{textAlign:'center',fontSize:"18px",fontWeight:"bold"}}>睡眠ステージ</div>
                <div style={{display:'flex',flexDirection: 'row'}}>
                <LineChart
                  width={1200}
                  height={300}
                  data={sleepList}
                  margin={{
                    top: 5,
                    right: 5,
                    left: 5,
                    bottom: 5,
                  }}
                >
                  <defs>
                    <linearGradient
                      id="gradationColor"
                      y1="0%"
                      x1="0%"
                      y2="100%"
                      x2="0%"
                    >
                    <stop offset="0%" stopColor="red" />
                    <stop offset="25%" stopColor="skyblue" />
                    <stop offset="50%" stopColor="deepskyblue" />
                    <stop offset="100%" stopColor="navy"/>
                    </linearGradient>
                  </defs>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="dateTime" />
                  <YAxis dataKey="ykey" tick={{fontSize: 8}}
                    tickFormatter={value => value == 4 ? '目覚めた状態' : value == 3 ? 'レム睡眠' : value == 2 ? '浅い睡眠' : value == 1 ? '深い睡眠' : '' }
                  />
                  <Line name="睡眠レベル" type="monotone" dataKey="ykey" stroke="url(#gradationColor)"  dot={false}/>
                  <Legend payload={[{value: '目覚めた状態', type: 'line', id: 'ID01',color:'red'},{value: 'レム睡眠', type: 'line', id: 'ID02',color:'skyblue'},
                                    {value: '浅い睡眠', type: 'line', id: 'ID03',color:'deepskyblue'},{value: '深い睡眠', type: 'line', id: 'ID04',color:'navy'}]}/>
                  <Tooltip  formatter={(value) => value == 4 ? '目覚めた状態' : value == 3 ? 'レム睡眠' : value == 2 ? '浅い睡眠' : value == 1 ? '深い睡眠' : ''}/>
                </LineChart>
                <ComposedChart
                  layout='vertical'
                  width={300}
                  height={285}
                  data={sleepSummaryDataList}
                  margin={{
                    top: 5,
                    right: 5,
                    left: 10,
                    bottom: 5,
                  }}
                >
                  <XAxis type="number" axisLine={false} tick={false} domain={[0,sleepSummary.maxValue?sleepSummary.maxValue:500]}/>
                  <YAxis dataKey="name" type="category" axisLine={false} tick={false} width={0}/>
                  <Bar name="睡眠時間" dataKey="minutes">
                    {sleepSummaryDataList.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                    ))}
                    <LabelList dataKey="label" position="right" formatter={(value) => value}/>
                  </Bar>
                  <Tooltip formatter={(value) => makeSleepTime(value)}/>
                </ComposedChart>
                <div style={{display:'flex',flexDirection: 'column',width:'400px'}}>
                <div style={{textAlign:'left',fontSize:"18px"}}>睡眠時間</div>
                <div style={{textAlign:'left',fontSize:"36px"}}>{makeSleepTime(sleepSummary.minutesAsleep)}</div>
                <div style={{textAlign:'left',fontSize:"18px",marginTop:'100px'}}>睡眠のスケジュール</div>
                <div style={{textAlign:'left',fontSize:"36px"}}>{sleepSummary.startTime} <span style={{fontSize:'50%'}}>{sleepSummary.startTime?'就寝':'-'}</span></div>
                <div style={{textAlign:'left',fontSize:"36px"}}>{sleepSummary.endTime} <span style={{fontSize:'50%'}}>{sleepSummary.startTime?'起床':'-'}</span></div>
                </div>
                </div>
              </div>
              <div style={{margin:'auto'}}>
                <div style={{textAlign:'center',fontSize:"18px",fontWeight:"bold"}}>歩数</div>
                <div style={{display:'flex',flexDirection: 'row'}}>
                <LineChart
                  width={1200}
                  height={300}
                  data={stepList}
                  margin={{
                    top: 5,
                    right: 5,
                    left: 5,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="time" />
                  <YAxis dataKey="value" />
                  <Line name="歩数" type="monotone" dataKey="value" stroke="blue" dot={false} />
                  <Legend />
                  <Tooltip />
                </LineChart>
                <div style={{display:'flex',flexDirection: 'column',width:'200px',marginLeft:'45px'}}>
                <div style={{textAlign:'left',fontSize:"18px"}}>歩数</div>
                <div style={{textAlign:'left',fontSize:"36px"}}>{stepSummary.steps?Number(stepSummary.steps).toLocaleString():''}<span style={{fontSize:'50%'}}>{stepSummary.steps?'':'-'}</span></div>
                <div style={{textAlign:'left',fontSize:"18px",marginTop:'100px'}}>移動距離</div>
                <div style={{textAlign:'left',fontSize:"36px"}}>{stepSummary.distance?Number(stepSummary.distance).toLocaleString():''}<span style={{fontSize:'50%'}}>{stepSummary.distance?'km':'-'}</span></div>
                </div>
                <div style={{display:'flex',flexDirection: 'column',width:'200px',marginLeft:'50px'}}>
                <div style={{textAlign:'left',fontSize:"18px"}}>消費カロリー</div>
                <div style={{textAlign:'left',fontSize:"36px"}}>{stepSummary.calorie?Number(stepSummary.calorie).toLocaleString():''}<span style={{fontSize:'50%'}}>{stepSummary.calorie?'':'-'}</span></div>
                <div style={{textAlign:'left',fontSize:"18px",marginTop:'100px'}}>ゾーンタイム</div>
                <div style={{textAlign:'left',fontSize:"36px"}}>{makeSleepTime(heartRateSummary.sumMin)}</div>
                </div>
              </div>
              </div>
              {/* <div style={{margin:'auto'}}>
                <div style={{textAlign:'center',fontSize:"18px",fontWeight:"bold"}}>移動距離</div>
                <LineChart
                  width={1200}
                  height={300}
                  data={distanceList}
                  margin={{
                    top: 5,
                    right: 5,
                    left: 5,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="time" />
                  <YAxis dataKey="value" />
                  <Line name="移動距離" type="monotone" dataKey="value" stroke="blue" dot={false} />
                  <Legend />
                  <Tooltip />
                </LineChart>
              </div> */}
              <div style={{margin:'auto'}}>
                <div style={{textAlign:'center',fontSize:"18px",fontWeight:"bold"}}>心拍数</div>
                <div style={{display:'flex',flexDirection: 'row'}}>
                <LineChart
                  width={1200}
                  height={300}
                  data={heartRateList}
                  margin={{
                    top: 5,
                    right: 5,
                    left: 5,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="time" />
                  <YAxis dataKey="value" domain={[0, 220]}/>
                  <Line name="心拍数" type="monotone" dataKey="value" stroke="blue" dot={false} />
                  <Legend />
                  <Tooltip />
                </LineChart>
                <ComposedChart
                  layout='vertical'
                  width={300}
                  height={300}
                  data={heartRateZoneList}
                  margin={{
                    top: 5,
                    right: 5,
                    left: 10,
                    bottom: 5,
                  }}
                >
                  <XAxis type="number" axisLine={false} tick={false} domain={[0,heartRateSummary.maxGraph ?heartRateSummary.maxGraph :20]}/>
                  <YAxis dataKey="name" type="category" axisLine={false} tick={false} width={0}/>
                  <Bar name="時間" dataKey="minutes" minPointSize={1}>
                    {heartRateZoneList.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={colors[index % 20+4]} />
                    ))}
                    <LabelList dataKey="label" position="right" formatter={(value) => value}/>
                  </Bar>
                  <Tooltip formatter={(value) => makeSleepTime(value)}/>
                </ComposedChart>
                <div style={{display:'flex',flexDirection: 'column',width:'400px'}}>
                <div style={{textAlign:'left',fontSize:"18px"}}>安静時の心拍数</div>
                <div style={{textAlign:'left',fontSize:"36px"}}>{heartRateSummary.restingHeartRate}<span style={{fontSize:'50%'}}>{heartRateSummary.restingHeartRate?'bpm':'-'}</span></div>
                <div style={{textAlign:'left',fontSize:"18px",marginTop:'100px'}}>エクササイズゾーン</div>
                <div style={{textAlign:'left',fontSize:"36px"}}>{makeSleepTime(heartRateSummary.sumMin)}</div>
                <div style={{textAlign:'left',fontSize:"36px"}}>{heartRateSummary.calorie?Number(heartRateSummary.calorie.toFixed(0)).toLocaleString():'-'}<span style={{fontSize:'50%'}}>{heartRateSummary.calorie?'カロリー':''}</span></div>
                </div>
                </div>
              </div>
              <div style={{margin:'auto'}}>
                <div style={{textAlign:'center',fontSize:"18px",fontWeight:"bold"}}>脂肪燃焼</div>
                <LineChart
                  width={1200}
                  height={300}
                  data={heartRateZones}
                  margin={{
                    top: 5,
                    right: 5,
                    left: 5,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis dataKey="minutes" />
                  <Line name="消費カロリー" type="monotone" dataKey="minutes" stroke="blue" dot={false} />
                  <Legend />
                  <Tooltip />
                </LineChart>
              </div>
            </div>
            </>
          }
          {/* 月グラフ */}
          {selectTab === ModeMonthlyGraph &&
            <>
            <div style={{display:'block'}}>
              <div style={{margin:'auto'}}>
                <div style={{textAlign:'center',fontSize:"18px",fontWeight:"bold"}}>睡眠時間</div>
                <div style={{display:'flex',flexDirection: 'row'}}>
                <LineChart
                  width={1200}
                  height={300}
                  data={healthCareDailyList}
                  margin={{
                    top: 5,
                    right: 5,
                    left: 5,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="targetYMD" />
                  <YAxis dataKey="sleepTime" />
                  <Line name="睡眠時間" type="monotone" dataKey="sleepTime" stroke="blue" />
                  <Legend />
                  <Tooltip formatter={(value, name, props) => makeSleepTime(Number(props.payload.sleepTimeMinutes))}/>
                </LineChart>
                <ComposedChart
                  layout='vertical'
                  width={300}
                  height={285}
                  data={healthCareSummary.sleepSummaryList}
                  margin={{
                    top: 5,
                    right: 5,
                    left: 10,
                    bottom: 5,
                  }}
                >
                  <XAxis type="number" axisLine={false} tick={false} domain={[0,healthCareSummary.sleepMaxValue?healthCareSummary.sleepMaxValue:500]}/>
                  <YAxis dataKey="name" type="category" axisLine={false} tick={false} width={0}/>
                  <Bar name="睡眠時間" dataKey="minutes">
                    {healthCareSummary.sleepSummaryList.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                    ))}
                    <LabelList dataKey="label" position="right" formatter={(value) => value}/>
                  </Bar>
                  <Tooltip formatter={(value) => makeSleepTime(value)}/>
                </ComposedChart>
                <div style={{display:'flex',flexDirection: 'column',width:'400px'}}>
                <div style={{textAlign:'left',fontSize:"18px"}}>睡眠時間</div>
                <div style={{textAlign:'left',fontSize:"36px"}}>{makeSleepTime(healthCareSummary.minutesAsleep)}</div>
                </div>
                </div>
              </div>
              <div style={{margin:'auto'}}>
                <div style={{textAlign:'center',fontSize:"18px",fontWeight:"bold"}}>歩数</div>
                <div style={{display:'flex',flexDirection: 'row'}}>
                <LineChart
                  width={1200}
                  height={300}
                  data={healthCareDailyList}
                  margin={{
                    top: 5,
                    right: 5,
                    left: 5,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="targetYMD" />
                  <YAxis dataKey="steps" />
                  <Line name="歩数" type="monotone" dataKey="steps" stroke="blue" dot={false} />
                  <Legend />
                  <Tooltip />
                </LineChart>
                <div style={{display:'flex',flexDirection: 'column',width:'200px',marginLeft:'45px'}}>
                <div style={{textAlign:'left',fontSize:"18px"}}>歩数</div>
                <div style={{textAlign:'left',fontSize:"36px"}}>{healthCareSummary.steps?Number(healthCareSummary.steps).toLocaleString():''}<span style={{fontSize:'50%'}}>{healthCareSummary.steps?'':'-'}</span></div>
                <div style={{textAlign:'left',fontSize:"18px",marginTop:'100px'}}>移動距離</div>
                <div style={{textAlign:'left',fontSize:"36px"}}>{Number(healthCareSummary.distance).toLocaleString()}<span style={{fontSize:'50%'}}>{healthCareSummary.distance?'km':'-'}</span></div>
                </div>
                <div style={{display:'flex',flexDirection: 'column',width:'200px',marginLeft:'50px'}}>
                <div style={{textAlign:'left',fontSize:"18px"}}>消費カロリー</div>
                <div style={{textAlign:'left',fontSize:"36px"}}>{healthCareSummary.calorie?Number(healthCareSummary.calorie).toLocaleString():''}<span style={{fontSize:'50%'}}>{healthCareSummary.calorie?'':'-'}</span></div>
                {/* <div style={{textAlign: 'left',fontSize:"18px",marginTop:'100px'}}>ゾーンタイム</div>
                <div style={{textAlign: 'left',fontSize:"36px"}}>{makeSleepTime(heartRateSummary.sumMin)}</div> */}
                </div>
                </div>
              </div>
              {/* <div style={{margin:'auto'}}>
                <div style={{textAlign:'center',fontSize:"18px",fontWeight:"bold"}}>移動距離</div>
                <LineChart
                  width={1200}
                  height={300}
                  data={healthCareDailyList}
                  margin={{
                    top: 5,
                    right: 5,
                    left: 5,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="targetYMD" />
                  <YAxis dataKey="distance" />
                  <Line name="移動距離" type="monotone" dataKey="distance" stroke="blue" dot={false} />
                  <Legend />
                  <Tooltip />
                </LineChart>
              </div> */}
              <div style={{margin:'auto'}}>
                <div style={{textAlign:'center',fontSize:"18px",fontWeight:"bold"}}>心拍数</div>
                <div style={{display:'flex',flexDirection: 'row'}}>
                <LineChart
                  width={1200}
                  height={300}
                  data={healthCareDailyList}
                  margin={{
                    top: 5,
                    right: 5,
                    left: 5,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="targetYMD" />
                  <YAxis dataKey="heartRate" domain={[0, 220]}/>
                  <Line name="心拍数" type="monotone" dataKey="heartRate" stroke="blue" dot={false} />
                  <Legend />
                  <Tooltip />
                </LineChart>
                <ComposedChart
                  layout='vertical'
                  width={300}
                  height={300}
                  data={healthCareSummary.heartRateZonesList}
                  margin={{
                    top: 5,
                    right: 5,
                    left: 10,
                    bottom: 5,
                  }}
                >
                  <XAxis type="number" axisLine={false} tick={false} domain={[0,healthCareSummary.heartRateZonesMaxValue?healthCareSummary.heartRateZonesMaxValue:20]}/>
                  <YAxis dataKey="name" type="category" axisLine={false} tick={false} width={0}/>
                  <Bar name="時間" dataKey="minutes" minPointSize={1}>
                    {healthCareSummary.heartRateZonesList.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={colors[index % 20+4]} />
                    ))}
                    <LabelList dataKey="label" position="right" formatter={(value) => value}/>
                  </Bar>
                  <Tooltip formatter={(value) => makeSleepTime(value)}/>
                </ComposedChart>
                <div style={{display:'flex',flexDirection: 'column',width:'400px'}}>
                <div style={{textAlign:'left',fontSize:"18px"}}>安静時の心拍数</div>
                <div style={{textAlign:'left',fontSize:"36px"}}>{healthCareSummary.restingHeartRate}<span style={{fontSize:'50%'}}>{healthCareSummary.restingHeartRate?'bpm':'-'}</span></div>
                <div style={{textAlign:'left',fontSize:"18px",marginTop:'100px'}}>エクササイズゾーン</div>
                <div style={{textAlign:'left',fontSize:"36px"}}>{makeSleepTime(healthCareSummary.heartRateZonesMinutes)}</div>
                <div style={{textAlign:'left',fontSize:"36px"}}>{healthCareSummary.heartRateZonesCalorie?Number(healthCareSummary.heartRateZonesCalorie).toLocaleString():'-'}<span style={{fontSize:'50%'}}>{healthCareSummary.heartRateZonesCalorie?'カロリー':''}</span></div>
                </div>
                </div>
              </div>
              <div style={{margin:'auto'}}>
                <div style={{textAlign: 'center',fontSize:"18px",fontWeight:"bold"}}>脂肪燃焼</div>
                <LineChart
                  width={1200}
                  height={300}
                  data={healthCareDailyList}
                  margin={{
                    top: 5,
                    right: 5,
                    left: 5,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="targetYMD" />
                  <YAxis dataKey="calorie" />
                  <Line name="消費カロリー" type="monotone" dataKey="calorie" stroke="blue" dot={false} />
                  <Legend />
                  <Tooltip />
                </LineChart>
              </div>
            </div>
            </>
          }
        </Wrap>
      </div>
    </Modal>
  )
}

StaffHealthCareModal.propTypes = {
  grants: PropTypes.object, // 権限関連
  staffData: PropTypes.object, // 健康情報
  modalIsOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
}

export default StaffHealthCareModal

import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
//import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { GetApi } from '../../../Common/ApiAxios'
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'
import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'

import CardTotal from '../Atoms/CardTotal'
import ProcessPieGraph from './ProcessPieGraph'

const DefaultUrl = '/api/logisticManagement'

const Flex = styled.div`
  display: flex; /* 横並び */
  flex-direction: row;
  width: 100%;
  height: 310px;
  text-align: center;
  vertical-align: middle;
  margin-top: 10px;
  margin-bottom: 5px;
`
const ContentRow = styled.div`
  display: flex; /* 横並び */
  flex-direction: column;
`
const Content = styled.div`
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  width: 50%;
  height: 310px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px;
  ::-webkit-scrollbar-corner {
    display: none;
  }
`

// ヘッダー部のカード
const ProgressHeader = (props) => {
  const {
    grants,
    totalDatas,
    wageType
  } = props
  const dispatch = useDispatch();
  //const alert = useAlert();
  const intervalRef1 = useRef(null);
  const intervalRef2 = useRef(null);
  const [quantityPlan,setQuantityPlan] = useState(null);
  //const [totalProgressInfo,seTtotalProgressInfo] = useState({progress:0.0});
  //const [plaEndInfo, setPlanEndInfo] = useState({planEndAt:LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD') + " 00:00:00"});

  const defaltValue = {agreementStaffAvgWage:0.0,aveWage:0.0,costProgressValue:0.0}
  const [monthlyData,setMonthlyData] = useState([defaltValue,defaltValue]);
  const [daylyData,setDaylyData] = useState([defaltValue,defaltValue]);

  const [processList,setProcessList] = useState([]);
  const [processMonthList,setProcessMonthList] = useState([]);
  // 平均時給情報の取得
  const apiGetAveWage = async (isLoadSplinner) => {
    //console.log('call apiGetAveWage');

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true))

    const apiUrl = DefaultUrl + '/getAveWage';
    const param = {
      workplaceId: '000011',
      targetYMD: LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD'),
      //targetYMD:'2023-02-22'
    }

    const result = await GetApi(apiUrl, param);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      setQuantityPlan(null);
    } else {
      //console.log(result.data);
      setDaylyData(result.data[0]);
      setMonthlyData(result.data[1]);
    }
  }

  // 進捗設定情報の取得
  const apiGetProcessPieGraph = async (isLoadSplinner) => {
    //console.log('call apiGetProcessPieGraph');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getProcessPieGraph';
   
    const data = {
      workplaceId: '000011',
      targetYMD: LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD'),
      //targetYMD:'2023-02-24'
      type:0 //追加
    }
    const result = await GetApi(apiUrl, data);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      //alert.error('条件の該当データがありません(進捗設定情報の取得)');
    } else {
      //console.log(result.data);
      setProcessList(result.data[0]);
      setProcessMonthList(result.data[1]);
    }
  }

  // 周期初期のクリア
  const onClearInerval = async () => {
    if (intervalRef1.current !== null)
      clearInterval(intervalRef1.current);
    if (intervalRef2.current !== null)
      clearInterval(intervalRef2.current);
    
    intervalRef1.current = null;
    intervalRef2.current = null;
  }

  // 描画時に一度だけ実行
  useEffect(() => {
    apiGetAveWage();
    apiGetProcessPieGraph()
    intervalRef1.current = setInterval(() => {
      apiGetAveWage();
    }, 1000 * 30); // 30秒間隔で変更
    intervalRef2.current = setInterval(() => {
      apiGetProcessPieGraph()
    }, 1000 * 60); // 60秒間隔で変更
    return () => {
      onClearInerval();
    };
  }, [])


  //if (!grants || !totalDatas) return null
  return (
    <Flex>
      <ProcessPieGraph processList={processList}/>
      <Content>
        <ContentRow>
          <CardTotal totalData={daylyData} type={0} wageType={wageType}/>
          <CardTotal totalData={daylyData} type={1} wageType={wageType}/>
        </ContentRow>
      </Content>
      <ProcessPieGraph processList={processMonthList}/>
      <Content>
        <ContentRow>         
          <CardTotal totalData={monthlyData} type={2} wageType={wageType}/>  
          <CardTotal totalData={monthlyData} type={3} wageType={wageType}/>    
        </ContentRow>
      </Content>
    </Flex>
  )
}

ProgressHeader.propTypes = {
  grants: PropTypes.object, // 権限関連
  totalDatas: PropTypes.object, // 集計情報
  wageType: PropTypes.number, // 平均時給タイプ　0:契約から算出,1:労務費から算出
}
  
export default ProgressHeader


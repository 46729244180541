import React, { useState, useEffect } from 'react'
//import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { GetApi } from '../../../Common/ApiAxios'

import AlertList from '../Atoms/AlertList'

const Flex = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 100%;
  height: 320x;
  text-align: center;
  vertical-align: middle;
`

const Content = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
  width: 90%;
  height: ${props => props.height ? props.height : '200px'};
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
  ::-webkit-scrollbar-corner {
    display: none;
  }
`

// ヘッダー部のカード
const OperationHeader = (props) => {
  const {
    grants,
    dates,
    quantity
  } = props
  

  // 描画時に一度だけ実行
  useEffect(() => {
  }, [])

  if (!quantity || quantity == null) return null
  return (
    <Flex>
      <Content height={'310px'}>
        <AlertList type={0} totalData={[
          {index:1,companyName:'A運送',driverName:'鹿児島 裕次郎',storeName:'京都支店',time:'12:30'}
          ,{index:1,companyName:'B運送',driverName:'東京 太郎',storeName:'阪南支店',time:'13:30'}
          ,{index:1,companyName:'C運送',driverName:'横浜 一郎',storeName:'貝塚支店',time:'14:30'}
          ,{index:1,companyName:'A運送',driverName:'大阪 二郎',storeName:'京都支店',time:'16:00'}
          ,{index:1,companyName:'D運送',driverName:'福岡 三郎',storeName:'京都支店',time:'19:30'}
        ]}
        />
        <AlertList type={1} totalData={[
          {index:1,companyName:'A運送',driverName:'東京 太郎',work:'荷積み',storeName:'京都支店',time:'111分'}
          ,{index:1,companyName:'B運送',driverName:'横浜 一郎',work:'荷積み',storeName:'阪南支店',time:'105分'}
          ,{index:1,companyName:'C運送',driverName:'東京 太郎',work:'荷降し',storeName:'貝塚支店',time:'100分'}
          ,{index:1,companyName:'A運送',driverName:'横浜 一郎',work:'荷積み',storeName:'京都支店',time:'94分'}
          ,{index:1,companyName:'D運送',driverName:'大阪 二郎',work:'荷積み',storeName:'京都支店',time:'80分'}
          ,{index:1,companyName:'E運送',driverName:'福岡 三郎',work:'荷積み',storeName:'京都支店',time:'78分'}
        ]}/>
        <AlertList type={2} totalData={[
          {index:1,companyName:'A運送',driverName:'鹿児島 裕次郎',storeName:'京都支店',time:'115分'}
          ,{index:1,companyName:'B運送',driverName:'東京 太郎',storeName:'阪南支店',time:'111分'}
          ,{index:1,companyName:'C運送',driverName:'横浜 一郎',storeName:'貝塚支店',time:'91分'}
          ,{index:1,companyName:'A運送',driverName:'大阪 二郎',storeName:'京都支店',time:'88分'}
          ,{index:1,companyName:'D運送',driverName:'福岡 三郎',storeName:'京都支店',time:'81分'}
        ]}/>
      </Content>
    </Flex>
  )
}

OperationHeader.propTypes = {
  grants: PropTypes.object, // 権限関連
  dates: PropTypes.array, // 日付情報(1週分)
}
  
export default OperationHeader


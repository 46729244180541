import React, { useEffect, useState, useCallback } from 'react'
import { useAlert } from 'react-alert'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { GetApi } from '../../Common/ApiAxios'
import ErrorHandling from '../../Common/ErrorHandling'
//import Calendar from '../../Common/Atoms/Calendar'
//import Select from '../../Common/Atoms/Select'
import * as regex from '../../Common/Regex'
import { InputGroup, SelectGroup, HiddenInput } from '../../Common/CommonInputGroup'
import { Alert } from '../../Common/CommonStyled'
import { useYubinbango,GetAge } from '../../Common/Utilties/CommonUtility'
import { formatZipCode } from '../../Common/Utilties/StringUtility'
import { formatPhoneNum, formatMobileNum } from '../../Common/Utilties/PhoneNumUtility'
import { YubinbangoScript } from '../../Common/Atoms/ScriptTag'
//import Button from '../../Common/Atoms/NormalButton'
import MasterDetailWrap, { MasterDetailFormWrap } from '../Wrap/MasterDetailWrap'
import {formatRound48Time,diff48Time,secToTime} from '../../Common/Utilties/LaborUtility'
import EmploymentDialog from './EmploymentDialog'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '75vw',
  },
  typography: {
    width: '72vw',
    fontSize: '18px',
    fontWeight: '700',
    color: '#ffffff',
    verticalAlign: 'middle',
    margin: '0',
    padding: '16px',
    background: '#4169E1',
  },
}))

export const StaffMasterForm = ({
  register, errors, setValue, getValues, staffDetail,
  watch, canWrite, grants, loginUserInfo, isPasswordChange, setIsPasswordChange, isDeleted, actionType,
  isView,setUpdateFlag
}) => {
  const classes = useStyles();
  const [staffTypeOptions, setStaffTypeOptions] = useState(<></>)
  const [sexOptions, setSexOptions] = useState(<></>)
  const [grantOptions, setGrantOptions] = useState(<></>)
  const loginOptions = [
    <option value={false} key={1}>無</option>,
    <option value key={2}>有</option>
  ]
  const alert = useAlert()

  function changeSetIsPasswordChange (e) {
    setIsPasswordChange(e)
  }

  // スタッフ情報切り替え時
  useEffect(() => {
    if (staffDetail != null) {
      console.log(staffDetail)
      console.log(staffDetail.hope.shiftWed)
      setBirthDt(staffDetail.birthDt)
      setEnterDt(staffDetail.enterDt)
      setRetireDt(staffDetail.retireDt)

      setValue('staffId', staffDetail.staffId)
      setValue('staffName', staffDetail.staffName)
      setValue('staffKana', staffDetail.staffKana)
      setValue('staffDivision', staffDetail.staffDivision)
      setValue('departmentId', staffDetail.departmentId)
      setValue('loginFlag', true)
      //setValue('enterDt', staffDetail.enterDt)
      //setValue('retireDt', staffDetail.retireDt)
      setValue('sex', staffDetail.sex)
      //setValue('birthDt', staffDetail.birthDt)
      setValue('zipCode', staffDetail.zipCode)
      setValue('address1', staffDetail.address1)
      setValue('address2', staffDetail.address2)
      setValue('address3', staffDetail.address3)
      setValue('address4', staffDetail.address4)
      setValue('phoneNum', staffDetail.phoneNum)
      setValue('mobileNum', staffDetail.mobileNum)
      setValue('pcMailAddress', staffDetail.pcMailAddress)
      setValue('mobileMailAddress', staffDetail.mobileMailAddress)
      setValue('cooperationId', staffDetail.cooperationId)
      setValue('updatedAt', staffDetail.updatedAt)
      setValue('updatedId', staffDetail.updatedId)
      setValue('grant.grantNameId', staffDetail.grant.grantNameId?staffDetail.grant.grantNameId:5)

      setValue('hope.hopeStartTime',diff48Time('1900-01-01', staffDetail.hope.hopeStartTime))
      setValue('hope.hopeEndTime', diff48Time('1900-01-01', staffDetail.hope.hopeEndTime))
      setValue('hope.overtimePropriety', (staffDetail.hope.overtimePropriety?'true':'false'))

      setValue('hope.shiftMon', staffDetail.hope.shiftMon?'true':'false')
      setValue('hope.shiftTue', staffDetail.hope.shiftTue?'true':'false')
      setValue('hope.shiftWed', staffDetail.hope.shiftWed?'true':'false')
      setValue('hope.shiftThu', staffDetail.hope.shiftThu?'true':'false')
      setValue('hope.shiftFri', staffDetail.hope.shiftFri?'true':'false')
      setValue('hope.shiftSat', staffDetail.hope.shiftSat?'true':'false')
      setValue('hope.shiftSun', staffDetail.hope.shiftSun?'true':'false')
      setValue('hope.shiftHol', staffDetail.hope.shiftHol?'true':'false')


      setValue('setting.workingStartTime',diff48Time('1900-01-01', staffDetail.setting.workingStartTime))
      setValue('setting.workingEndTime', diff48Time('1900-01-01', staffDetail.setting.workingEndTime))
      setValue('setting.nightStartTime',diff48Time('1900-01-01', staffDetail.setting.nightStartTime))
      setValue('setting.nightEndTime', diff48Time('1900-01-01', staffDetail.setting.nightEndTime))

      setValue('setting.workingTime',formatRound48Time(secToTime(staffDetail.setting.workingTime),1))
      setValue('setting.deemedTime', formatRound48Time(secToTime(staffDetail.setting.deemedTime),1))
      setValue('setting.beforeWorkingFlag', staffDetail.setting.beforeWorkingFlag?'true':'false')
      setValue('setting.afterWorkingFlag', staffDetail.setting.afterWorkingFlag?'true':'false')
      if(staffDetail.employment && staffDetail.employment.length!=0){
        var employ = staffDetail.employment[staffDetail.employment.length-1]
        console.log(staffDetail.employment)
        setValue('lengthServiceYears', employ.lengthServiceYears +'年')
        setValue('scheduleWeekTotalDt',formatRound48Time(secToTime(employ.scheduleWeekTotalDt),1))
        setValue('scheduleWeekWorkCt',employ.scheduleWeekWorkCt+'日')
        //_setEmployment(staffDetail.employment)
      }
      else{
        setValue('lengthServiceYears', '')
        setValue('scheduleWeekTotalDt','')
        setValue('scheduleWeekWorkCt','')
      }
      setUpdateFlag(false)
      //setValue('grant.updatedId', staffDetail.grant.updatedId)
      //setValue('grant.updatedAt', staffDetail.grant.updatedAt)
    }
  // スタッフ情報が変更された場合のみ実行
  // eslint-disable-next-line
  }, [staffDetail, alert])

  // 初期化メソッド
  useEffect(() => {
    async function getOptions () {
      getStaffType()
      getSex()
      getGrant()
    }
    getOptions()
  // 初回描画時のみ実行
  // eslint-disable-next-line
  }, [])

  // スタッフ区分取得
  const getStaffType = useCallback(async () => {
    const result = await GetApi('/api/masterManagement/classificationValues/staffType')
    if (!ErrorHandling(result, alert) && Array.isArray(result.data.values)) {
      const options = result.data.values.map(value =>
        <option value={value.valueId} key={value.valueId}>{value.valueName}</option>
      )
      setStaffTypeOptions(options)
    }
  }, [setStaffTypeOptions, alert])

  // 性別取得
  const getSex = useCallback(async () => {
    const result = await GetApi('/api/masterManagement/classificationValues/sex')
    if (!ErrorHandling(result, alert) && Array.isArray(result.data.values)) {
      const options = result.data.values.map(value =>
        <option value={value.valueId} key={value.valueId}>{value.valueName}</option>
      )
      setSexOptions(options)
    }
  }, [setSexOptions, alert])

  // 権限取得
  const getGrant = useCallback(async () =>{
    const result = await GetApi('/api/masterManagement/grant/grantType')
    console.log(result)
    if (!ErrorHandling(result, alert) && Array.isArray(result.data.values)) {
      const options = result.data.values.map(value =>
        <option value={value.grantNameId} key={value.grantNameId}>{value.grantName}</option>
      )
      setGrantOptions(options)
    }
  }, [setGrantOptions, alert])

  // 誕生日
  const [birthDt, _setBirthDt] = useState()
  const [age,setAge] = useState('0歳')
  const setBirthDt = (date) => {
    _setBirthDt(date)
    setValue('birthDt', date)

    setAge(GetAge(date,new Date())+'歳')
    
    setUpdateFlag(true)
  }

  // 入社日
  const [enterDt, _setEnterDt] = useState()
  const setEnterDt = (date) => {
    _setEnterDt(date)
    setValue('enterDt', date)

    setUpdateFlag(true)
  }

  // 退社日
  const [retireDt, _setRetireDt] = useState()
  const setRetireDt = (date) => {
    _setRetireDt(date)
    setValue('retireDt', date)

    setUpdateFlag(true)
  }

  // 郵便番号による自動補完
  const [zipCode, setZipCode] = useState(null)
  const { region, locality, street } = useYubinbango(zipCode)

  useEffect(() => {
    setValue('address1', region, true)
    setValue('address2', locality, true)
    setValue('address3', street, true)
  // 住所情報の変更検知時のみ実行する
  // eslint-disable-next-line
  }, [region, locality, street])

  const onChangePostalCode = useCallback(e => {
    e.currentTarget.value = formatZipCode(e.currentTarget.value)

    setZipCode(e.currentTarget.value)

    setUpdateFlag(true)
  }, [setZipCode])

  const onChangePhoneNum = useCallback(e => {
    e.currentTarget.value = formatPhoneNum(e.currentTarget.value)

    setUpdateFlag(true)
  })

  const onChangeMobileNum = useCallback(e => {
    e.currentTarget.value = formatMobileNum(e.currentTarget.value)

    setUpdateFlag(true)
  })

  const onBlurTime = ( event ) => {
    let value = event.target.value
    value = formatRound48Time(value,1)
    event.target.value = value

    setUpdateFlag(true)
  }


  const onOverTime = ( event ) => {
    event.persist()
    var overTime = getValues('hope.overtimePropriety')
    setValue('hope.overtimePropriety', (overTime=='true'?false:true))
    event.target.innerText = (overTime=='true'?'不可':'可')

    setUpdateFlag(true)
  }

  const onShiftTime = ( e , week) => {
    e.persist()
    var overTime = getValues('hope.shift'+week)
    setValue('hope.shift'+week,(overTime=='true'?false:true))
    e.target.innerText = (overTime=='true'?'不可':'可')
    console.log(overTime)

    setUpdateFlag(true)
  }

  const onWorkingTime = ( e , flag) => {
    e.persist()
    var overTime = getValues('setting.'+flag+'WorkingFlag')
    setValue('setting.'+flag+'WorkingFlag',(overTime=='true'?false:true))
    e.target.innerText = (overTime=='true'?'不可':'可')
    console.log(overTime)

    setUpdateFlag(true)
  }

  const comDate=(start,end)=>
  {
    if(!start && !end) return false
    if(start && end){
      return start > end
    }
    return true
  }

  const onChange =()=>{
    setUpdateFlag(true)
  }
  const [canOpenDialog, setCanOpenDialog] = useState(false);
  //ダイアログ閉じるボタン処理
  const handleClose = () => {
    setCanOpenDialog(false);
  }
  const handleOpen = () => {
    setCanOpenDialog(true);
  }
  if (staffDetail === null) return null // 未選択時の場合は非表示
  return (
    <>
    <MasterDetailWrap isView={isView}>
      <YubinbangoScript />
      <div className={classes.root}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.typography}>基本情報</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <MasterDetailFormWrap>
          <ul>
            <li style={{ height: '15px' }}><label /><div /></li>
            {isDeleted && <li><label /><div><Alert>削除データ</Alert></div></li>}
            <li>
              <label>ID</label>
              <div>
                <InputGroup
                  width='10rem'
                  margin='12px 0 12px 0'
                  placeholder='未入力時は自動採番'
                  name='staffId'
                  register={register}
                  rule={{
                    maxLength: regex.maxLength(10),
                    pattern: regex.halfWidthAlphaNumeric
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite || actionType === 'PUT'}
                  onChange={()=>onChange()}
                />
              </div>
            </li>
            <li>
              <label className="required">氏名</label>
              <div>
                <InputGroup
                  width='20rem'
                  margin='12px 0 12px 0'
                  placeholder='アカウント名称'
                  name='staffName'
                  register={register}
                  rule={{
                    required: regex.required,
                    maxLength: regex.maxLength(50)
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                  onBlur={()=>onChange()}
                />
                <InputGroup
                  width='20rem'
                  margin='12px 0 12px 16px'
                  placeholder='アカウント名称(カナ)'
                  name='staffKana'
                  register={register}
                  rule={{
                    maxLength: regex.maxLength(50),
                    pattern: regex.kana
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                  onBlur={()=>onChange()}
                />
              </div>
            </li>
            {/* <li>
              <label>性別</label>
              <div>
                <SelectGroup
                  name='sex'
                  width='10rem'
                  margin='12px 0 12px 0'
                  options={sexOptions}
                  register={register}
                  rule={{
                    required: regex.required
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                  onChange={()=>onChange()}
                />
              </div>
            </li> */}
            {/* <li>
              <label>生年月日</label>
              <div>
                <Calendar
                  margin='0'
                  name='birthDt'
                  register={register}
                  placeholder='生年月日'
                  changeCalendar={setBirthDt}
                  dataValue={birthDt}
                  disabled={isDeleted || !canWrite}
                  prefix='/'
                  isViewIcon
                />
                <label style={{margin:'0 12px 0 12px'}}>年齢</label>
                <InputGroup
                  width='10rem'
                  margin='12px 0 12px 0'
                  placeholder='年齢'
                  name='age'
                  value={age}
                  errors={errors}
                  disabled={true}
                />
              </div>
            </li> */}
          </ul>
        </MasterDetailFormWrap>
        </AccordionDetails>
      </Accordion>
      </div>
{/* 
      <div className={classes.root}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.typography}>所属情報</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <MasterDetailFormWrap>
          <ul>
            <li style={{ height: '15px' }}><label /><div /></li>
            <li>
              <label>区分</label>
              <div>
                <SelectGroup
                  name='staffDivision'
                  width='10rem'
                  margin='0'
                  options={staffTypeOptions}
                  register={register}
                  rule={{
                    required: regex.required
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                  onChange={()=>onChange()}
                />
              </div>
            </li>
            <li>
              <label>入社日/退社日</label>
              <div>
                <Calendar
                  margin='0 16px 0 0'
                  name='enterDt'
                  register={register}
                  placeholder='入社日'
                  changeCalendar={setEnterDt}
                  dataValue={enterDt}
                  disabled={isDeleted || !canWrite}
                  prefix='/'
                  isViewIcon
                />
                <Calendar
                  margin='0'
                  name='retireDt'
                  register={register}
                  placeholder='退社日'
                  changeCalendar={setRetireDt}
                  dataValue={retireDt}
                  disabled={isDeleted || !canWrite}
                  prefix='/'
                  isViewIcon
                />
              </div>
            </li>
          </ul>
        </MasterDetailFormWrap>
        </AccordionDetails>
      </Accordion>
      </div>

      <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.typography}>連絡情報</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <MasterDetailFormWrap>
          <ul>
            <li>
              <label>住所</label>
              <div>
                <InputGroup
                  width='10rem'
                  margin='12px 0 12px 0'
                  placeholder='郵便番号(例:550-0013)'
                  name='zipCode'
                  register={register}
                  onChange={onChangePostalCode}
                  rule={{
                    maxLength: regex.maxLength(8)
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                  onBlur={()=>onChange()}
                />
              </div>
            </li>
            <li>
              <label />
              <div>
                <InputGroup
                  width='10rem'
                  margin='12px 0 12px 0'
                  placeholder='都道府県(例:大阪府)'
                  name='address1'
                  register={register}
                  rule={{
                    maxLength: regex.maxLength(5)
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                  onBlur={()=>onChange()}
                />
              </div>
            </li>
            <li>
              <label />
              <div>
                <InputGroup
                  width='40rem'
                  margin='12px 0 12px 0'
                  placeholder='地区町村(例:大阪市西区)'
                  name='address2'
                  register={register}
                  rule={{
                    maxLength: regex.maxLength(20)
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                  onBlur={()=>onChange()}
                />
              </div>
            </li>
            <li>
              <label />
              <div>
                <InputGroup
                  width='40rem'
                  margin='12px 0 12px 0'
                  placeholder='町域名(例:新町1丁目4番26号)'
                  name='address3'
                  register={register}
                  rule={{
                    maxLength: regex.maxLength(50)
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                  onBlur={()=>onChange()}
                />
              </div>
            </li>
            <li>
              <label />
              <div>
                <InputGroup
                  width='40rem'
                  margin='12px 0 12px 0'
                  placeholder='建物名(例:四ツ橋グランドビル2階)'
                  name='address4'
                  register={register}
                  rule={{
                    maxLength: regex.maxLength(50)
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                  onBlur={()=>onChange()}
                />
              </div>
            </li>
            <li>
              <label>電話番号</label>
              <div>
                <InputGroup
                  width='20rem'
                  margin='12px 0 12px 0'
                  placeholder='ご自宅'
                  name='phoneNum'
                  register={register}
                  onChange={onChangePhoneNum}
                  rule={{
                    maxLength: regex.maxLength(20),
                    pattern: regex.halfNumericHyphen
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                  onBlur={()=>onChange()}
                />
                <InputGroup
                  width='20rem'
                  margin='12px 0 12px 16px'
                  placeholder='携帯'
                  name='mobileNum'
                  register={register}
                  onChange={onChangeMobileNum}
                  rule={{
                    maxLength: regex.maxLength(20),
                    pattern: regex.halfNumericHyphen
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                  onBlur={()=>onChange()}
                />
              </div>
            </li>
            <li>
              <label>メールアドレス</label>
              <div>
                <InputGroup
                  width='20rem'
                  margin='12px 0 12px 0'
                  placeholder='パソコン'
                  name='pcMailAddress'
                  register={register}
                  rule={{
                    maxLength: regex.maxLength(50),
                    pattern: regex.mail
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                  onBlur={()=>onChange()}
                />
                <InputGroup
                  width='20rem'
                  margin='12px 0 12px 16px'
                  placeholder='携帯'
                  name='mobileMailAddress'
                  register={register}
                  rule={{
                    maxLength: regex.maxLength(50),
                    pattern: regex.mail
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                  onBlur={()=>onChange()}
                />
              </div>
            </li>
          </ul>
        </MasterDetailFormWrap>
        </AccordionDetails>
      </Accordion>
      </div> */}

      <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.typography}>システム利用情報</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <MasterDetailFormWrap>
          <ul>
            <li>
              <label>権限</label>
              <div>
                <SelectGroup
                  name='grant.grantNameId'
                  width='10rem'
                  margin='12px 0 12px 0'
                  options={grantOptions}
                  register={register}
                  rule={{
                    required: regex.required
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                  onChange={()=>onChange()}
                />
              </div>
            </li>
            {/* <li>
              <label>ログイン有無</label>
              <div>
                <SelectGroup
                  width='10rem'
                  margin='12px 0 12px 0'
                  name='loginFlag'
                  register={register}
                  options={loginOptions}
                  rule={{ required: regex.required }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                  onChange={()=>onChange()}
                />
              </div>
            </li> */}
            <li>
              <label>パスワード</label>
              <div>
                <InputGroup
                  width='20rem'
                  margin='12px 0 12px 0'
                  name='password'
                  type='password'
                  register={register}
                  rule={{
                    maxLength: regex.maxLength(50),
                    validate: {
                      customRequired: value => (watch('loginFlag') === 'true' && isPasswordChange && !value) ? 'ログインユーザーの場合は必須です' : null
                    }
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite || !isPasswordChange}
                />
                <InputGroup
                  width='20rem'
                  margin='12px 0 12px 16px'
                  placeholder='確認用'
                  name='confirmPassword'
                  type='password'
                  register={register}
                  rule={{
                    maxLength: regex.maxLength(50),
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite || !isPasswordChange}
                />
              </div>
            </li>
            <li style={{ height: 24 }}>
              <label />
              <div>
                <label>
                  <input
                    type='checkbox'
                    ref={register}
                    id='isPasswordChange'
                    name='isPasswordChange'
                    checked={isPasswordChange}
                    onChange={e => changeSetIsPasswordChange(e.target.checked)}
                    disabled={isDeleted || !canWrite}
                  />
                  パスワードを更新する
                </label>
              </div>
            </li>
            <li style={{ height: '15px' }}><label /><div /></li>
          </ul>
        </MasterDetailFormWrap>
        </AccordionDetails>
      </Accordion>
      </div>
      
      {/* 追加 */}
      {/* <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.typography}>スタッフ希望時間</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <MasterDetailFormWrap>
        <ul>
            <li>
              <label>希望時間</label>
              <div>
                <InputGroup
                  width='5rem'
                  margin='12px 16px 12px 0'
                  placeholder='開始時間'
                  name='hope.hopeStartTime'
                  register={register}
                  rule={{
                    maxLength: regex.maxLength(5),
                    validate: {
                      customRequired: value => (comDate(value,getValues('hope.hopeEndTime')))? '開始時刻に誤りがあります' : null
                    }
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
                <label>～</label>
                <InputGroup
                  width='5rem'
                  margin='12px 0 12px 16px'
                  placeholder='終了時間'
                  name='hope.hopeEndTime'
                  register={register}
                  rule={{
                    maxLength: regex.maxLength(5),
                    validate: {
                      customRequired: value => (comDate(getValues('hope.hopeStartTime'),value)) ? '終了時刻に誤りがあります' : null
                    }
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
            <li>
              <label>時間外勤務</label>
              <div>
              <Button
                height='36px'
                width='90px'
                padding='0'
                name={staffDetail.hope.overtimePropriety?'可':'不可'}
                type='button'
                margin='0 0 0 0'
                disabled={isDeleted || !canWrite}
                onClick={onOverTime}
              />
              </div>
            </li>
            <li>
              <label>希望曜日</label>
              <div>
              <label>月</label>
              <Button
                height='36px'
                width='90px'
                padding='0'
                name={staffDetail.hope.shiftMon?'可':'不可'}
                type='button'
                margin='0 12px 0 12px'
                disabled={isDeleted || !canWrite}
                onClick={(e)=>onShiftTime(e,'Mon')}
              />
              <label>火</label>
              <Button
                height='36px'
                width='90px'
                padding='0'
                name={staffDetail.hope.shiftTue?'可':'不可'}
                type='button'
                margin='0 12px 0 12px'
                disabled={isDeleted || !canWrite}
                onClick={(e)=>onShiftTime(e,'Tue')}
              />
              <label>水</label>
              <Button
                height='36px'
                width='90px'
                padding='0'
                name={staffDetail.hope.shiftWed?'可':'不可'}
                type='button'
                margin='0 12px 0 12px'
                disabled={isDeleted || !canWrite}
                onClick={(e)=>onShiftTime(e,'Wed')}
              />
              <label>木</label>
              <Button
                height='36px'
                width='90px'
                padding='0'
                name={staffDetail.hope.shiftThu?'可':'不可'}
                type='button'
                margin='0 12px 0 12px'
                disabled={isDeleted || !canWrite}
                onClick={(e)=>onShiftTime(e,'Thu')}
              />
              <label>金</label>
              <Button
                height='36px'
                width='90px'
                padding='0'
                name={staffDetail.hope.shiftFri?'可':'不可'}
                type='button'
                margin='0 12px 0 12px'
                disabled={isDeleted || !canWrite}
                onClick={(e)=>onShiftTime(e,'Fri')}
              />
              <label>土</label>
              <Button
                height='36px'
                width='90px'
                padding='0'
                name={staffDetail.hope.shiftSat?'可':'不可'}
                type='button'
                margin='0 12px 0 12px'
                disabled={isDeleted || !canWrite}
                onClick={(e)=>onShiftTime(e,'Sat')}
              />
              <label>日</label>
              <Button
                height='36px'
                width='90px'
                padding='0'
                name={staffDetail.hope.shiftSun?'可':'不可'}
                type='button'
                margin='0 12px 0 12px'
                disabled={isDeleted || !canWrite}
                onClick={(e)=>onShiftTime(e,'Sun')}
              />
              <label>祝</label>
              <Button
                height='36px'
                width='90px'
                padding='0'
                name={staffDetail.hope.shiftHol?'可':'不可'}
                type='button'
                margin='0 12px 0 12px'
                disabled={isDeleted || !canWrite}
                onClick={(e)=>onShiftTime(e,'Hol')}
              />                                              
              </div>
            </li>
          </ul>
        </MasterDetailFormWrap>
        </AccordionDetails>
      </Accordion>
      </div>

      <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.typography}>他システム連携情報</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <MasterDetailFormWrap>
          <ul>
            <li>
              <label>連携ID</label>
              <div>
                <InputGroup
                  width='10rem'
                  margin='12px 0 12px 16px'
                  placeholder='連携用ID'
                  name='cooperationId'
                  register={register}
                  rule={{
                    maxLength: regex.maxLength(10),
                    pattern: regex.halfWidthAlphaNumeric
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                  onBlur={()=>onChange()}
                />
              </div>
            </li>
          </ul>
        </MasterDetailFormWrap>
        </AccordionDetails>
      </Accordion>
      </div>
  
      <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.typography}>雇用契約</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <MasterDetailFormWrap>
        <ul>
            <li>
              <label>所定労働総時間</label>
              <div>
              <InputGroup
                  width='5rem'
                  margin='12px 0 12px 0'
                  name='setting.workingTime'
                  register={register}
                  rule={{
                    maxLength: regex.maxLength(50),
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                  onBlur={onBlurTime}
                />
              </div>
            </li>
            <li>
              <label>所定労働時間</label>
              <div>
                <InputGroup
                  width='5rem'
                  margin='12px 16px 12px 0'
                  placeholder='開始時間'
                  name='setting.workingStartTime'
                  register={register}
                  rule={{
                    maxLength: regex.maxLength(5),
                    validate: {
                      customRequired: value => (comDate(value,getValues('setting.workingEndTime')))? '開始時刻に誤りがあります' : null
                    }
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
                <label>～</label>
                <InputGroup
                  width='5rem'
                  margin='12px 0 12px 16px'
                  placeholder='終了時間'
                  name='setting.workingEndTime'
                  register={register}
                  rule={{
                    maxLength: regex.maxLength(5),
                    validate: {
                      customRequired: value => (comDate(getValues('setting.workingStartTime'),value)) ? '終了時刻に誤りがあります' : null
                    }
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>            
            <li>
              <label>深夜時間</label>
              <div>
                <InputGroup
                  width='5rem'
                  margin='12px 16px 12px 0'
                  placeholder='開始時間'
                  name='setting.nightStartTime'
                  register={register}
                  rule={{
                    maxLength: regex.maxLength(5),
                    validate: {
                      customRequired: value => (comDate(value,getValues('setting.nightEndTime')))? '開始時刻に誤りがあります' : null
                    }
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
                <label>～</label>
                <InputGroup
                  width='5rem'
                  margin='12px 0 12px 16px'
                  placeholder='終了時間'
                  name='setting.nightEndTime'
                  register={register}
                  rule={{
                    maxLength: regex.maxLength(5),
                    validate: {
                      customRequired: value => (comDate(getValues('setting.nightStartTime'),value)) ? '終了時刻に誤りがあります' : null
                    }
                  }}
                  errors={errors}
                  onBlur={onBlurTime}
                  disabled={isDeleted || !canWrite}
                />
              </div>
            </li>
            <li>
              <label>みなし時間</label>
              <div>
                <InputGroup
                  width='5rem'
                  margin='12px 0 12px 0'
                  name='setting.deemedTime'
                  register={register}
                  rule={{
                    maxLength: regex.maxLength(50),
                  }}
                  errors={errors}
                  disabled={isDeleted || !canWrite}
                  onBlur={onBlurTime}
                />
              </div>
            </li>
            <li>
              <label>勤務フラグ</label>
              <div>
              <label>終業時刻前</label>
              <Button
                height='36px'
                width='90px'
                padding='0'
                name={staffDetail.setting.beforeWorkingFlag?'可':'不可'}
                type='button'
                margin='0 12px 0 12px'
                disabled={isDeleted || !canWrite}
                onClick={(e)=>onWorkingTime(e,'before')}
              />
              <label>終業時刻後</label>
              <Button
                height='36px'
                width='90px'
                padding='0'
                name={staffDetail.setting.afterWorkingFlag?'可':'不可'}
                type='button'
                margin='0 12px 0 12px'
                disabled={isDeleted || !canWrite}
                onClick={(e)=>onWorkingTime(e,'after')}
              />                                         
              </div>
            </li>
            <li>
              <label>履歴</label>
              <div>
              <Button
                height='36px'
                width='90px'
                padding='0'
                name={'履歴'}
                type='button'
                margin='0 0 0 0'
                onClick={()=>handleOpen()}
              />                                             
              </div>
            </li>
          </ul>
        </MasterDetailFormWrap>
        </AccordionDetails>
      </Accordion>
      </div> */}

      <HiddenInput name='hope.overtimePropriety' register={register} value={staffDetail.hope.shiftMon?'true':'false'}/>
      <HiddenInput name='hope.shiftMon' register={register} value={staffDetail.hope.shiftMon?'true':'false'}/>
      <HiddenInput name='hope.shiftTue' register={register} value={staffDetail.hope.shiftTue?'true':'false'}/>
      <HiddenInput name='hope.shiftWed' register={register} value={staffDetail.hope.shiftWed?'true':'false'}/>
      <HiddenInput name='hope.shiftThu' register={register} value={staffDetail.hope.shiftThu?'true':'false'}/>
      <HiddenInput name='hope.shiftFri' register={register} value={staffDetail.hope.shiftFri?'true':'false'}/>
      <HiddenInput name='hope.shiftSat' register={register} value={staffDetail.hope.shiftSat?'true':'false'}/>
      <HiddenInput name='hope.shiftSun' register={register} value={staffDetail.hope.shiftSun?'true':'false'}/>
      <HiddenInput name='hope.shiftHol' register={register} value={staffDetail.hope.shiftHol?'true':'false'}/>

      <HiddenInput name='setting.beforeWorkingFlag' register={register} value={staffDetail.setting.beforeWorkingFlag?'true':'false'}/>
      <HiddenInput name='setting.afterWorkingFlag?' register={register} value={staffDetail.setting.afterWorkingFlag?'true':'false'}/>

      <HiddenInput name='updatedId' register={register} value={staffDetail.updatedId}/>
      <HiddenInput name='updatedAt' register={register} value={staffDetail.updatedAt}/>

    </MasterDetailWrap>
      <EmploymentDialog
        open={canOpenDialog}
        onCloseFunc={handleClose}
        employment={staffDetail}
      >
      </EmploymentDialog>
    </>
  )
}

StaffMasterForm.propTypes = {
  register: PropTypes.func,
  errors: PropTypes.object,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  staffDetail: PropTypes.object,
  watch: PropTypes.func,
  canWrite: PropTypes.bool,
  isPasswordChange: PropTypes.bool,
  setIsPasswordChange: PropTypes.func,
  isDeleted: PropTypes.bool,
  actionType: PropTypes.string,
  isView: PropTypes.bool
}

export default StaffMasterForm

import React, { useState, useEffect } from 'react'
//import { useSelector, useDispatch } from 'react-redux'
//import { useAlert } from 'react-alert'
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'
//import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
//import { useSyncExternalStore } from 'react'

//import FUNC from '../Common/FunctionEnum'

//import LogisticCard from './Atoms/LogisticCard'
//import TruckCard from './Atoms/TruckCard'
//import CoreTimeCard from './Atoms/CoreTimeCard'
//import CoreFirstCard from './Atoms/CoreFirstCard'
//import InfoCard from './Atoms/InfoCard'

import Search from './Search'
import SideSubMenu from './SideSubMenu'
import RecentMenu from './Atoms/RecentMenu'
import InfoCard from './Atoms/InfoCard'

import {BoardBaseFormRow, BoardBaseFormRowScroll} from './Wrap/BoardBaseWrap'

const InfoContent = styled.div`
  width: calc(100% - 10px);
  height: calc(100% - 200px);
  margin: 10px;
`

const ManagementPortal = (props) => {
  const [viewFunction,setViewFunction] = useState(false);
  //const dispatch = useDispatch()
  //const alert = useAlert()
  //const { loginUserInfo } = useSelector(state => state);
  //const [viewFunction,setViewFunction] = useState(false);
  //const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.TRUCK);
  //const subGrants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.TRUCK_HEALTHCARE);

  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }

  // 機能メニューの閉じるイベント
  const onCloseFunction = (event) => {
    setViewFunction(false);
  }

  // 描画時に一度だけ実行
  useEffect(() => {
  }, [])

  return (
    <>
      <Search
        onClickFunction={onClickFunction}
        onCloseFunction={onCloseFunction}
        viewFunction={viewFunction}
      />
      <BoardBaseFormRow>
        <SideSubMenu/>
        <BoardBaseFormRowScroll style={{height:'100%'}}>
          <InfoContent>
            <RecentMenu />
            <InfoCard/>
          </InfoContent>
        </BoardBaseFormRowScroll>
      </BoardBaseFormRow>
    </>
  )
}

export default ManagementPortal

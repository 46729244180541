import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
//import { useForm } from 'react-hook-form'
//import styled from 'styled-components'

import FUNC from '../../Common/FunctionEnum'
import { GetApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import ErrorHandling from '../../Common/ErrorHandling'
//import Select from '../../Common/Atoms/SelectAddValue'

import BoardBaseWrap,{BoardBaseFormRow} from '../Wrap/BoardBaseWrap'

import Search from './Search'
import SideSubMenu from '../SideSubMenu'
import GraphBody from './Molcules/PalDashboardGraph/PalDashboardGraphBody'

import DashBoardRateModal from './Molcules/PalDashboardDialog/PalDashboardRateDialog'

export const CargoWorkDefaultUrl = '/api/dashboardManagement/cargoWork'
export const LaborDefaultUrl = '/api/dashboardManagement/labor'

const OlapAnalysis = (props) => {
  const [searchOption, setSearchOption] = useState({targetDt:'',postType:'',postId:'',departmentId:''})
  const { loginUserInfo } = useSelector(state => state)
  const targetDateRef = useRef()
  //const { register, handleSubmit, getValues, setValue, watch, reset, errors, control } = useForm({ validateCriteriaMode: 'all' })
  
  const [viewSubSearch,setViewSubSearch] = useState(false)
  const [viewFunction,setViewFunction] = useState(false)
    
  const [pageType, setPageType] = useState(0)//0:リスト 1:スクロール
  const [postId,setPostId] = useState()
  const [postType,setPostType] = useState(0)//0:会社 1:顧客
  const [postList,setPostList] = useState(new Array())
  const [departmentId,setDepartmentId] = useState()

  const dispatch = useDispatch()
  const alert = useAlert()

  const staffWorkplceId = '000002' 
  //元の検索データ
  const [cargoWorkDataList, setCargoWorkDataList] = useState([])
  const [laborAllDataList, setLaborAllDataList] = useState([])
  //ダッシュボードに表示するデータ
  const [dashboardDataList, setDashboardDataList] = useState([])
  //ダッシュボードに表示する項目データ
  const [cargoWorkRateData, setCargoWorkRateData] = useState([])
  const [laborRateData, setLaborRateData] = useState([])
  //項目のデータリスト
  const [cargoWorkRateList, setCargoWorkRateList] = useState([])
  const [laborRateList, setLaborRateList] = useState([])
  //現場のリスト
  const [workplaceList,setWorkplaceList] = useState([staffWorkplceId])
  const [workplaceAllList,setWorkplaceAllList] = useState([])
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC);
  const subGrants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC_DASHBOARD);

  // 検索APIの実行
  const onSearch = async (createId, page,dataList) => {

    setSearchOption(
      {
        targetDt: targetDateRef.current,
        postType:postType,
        postId:(postId !== undefined && postId !== -1) ? postId:'',
        departmentId:(departmentId !== null && departmentId !== undefined)? departmentId:'',
      }
    )
   
    const data = {targetDt: targetDateRef.current,}
    dispatch(SetLoadSpinner(true))

    var tmpList = []
    var count = 1
    var errorFlag = false;
    var tmpCargoWorkList = []
    var tmpLaborList = []
    const sendData =  {targetDt: data.targetDt}
    const worlplaceCargoWorkData = await GetApi(`${CargoWorkDefaultUrl}/workplace/all`,sendData)
    const worlplaceLaborData = await GetApi(`${LaborDefaultUrl}/workplace/all`,sendData)
    if (worlplaceCargoWorkData.errorDetail || worlplaceLaborData.errorDetail) {
        ErrorHandling(worlplaceCargoWorkData, alert)
        errorFlag=true
        //データ作成
        var [dataList,keys] = await makeCargoWorkDashboardData([])
        var laborDataList = await makeLaborDashboardData([])
        tmpList.push({title:'',postId:0,cargoWorkDataList:dataList,laborDataList:laborDataList,keys:keys})
        setDashboardDataList(tmpList.sort((el1, el2) => {
          if (el1['postId'] < el2['postId']) {
            return 1;
          }
          if (el1['postId'] > el2['postId']) {
            return -1;
          }
            return 0;
        }))
      
        setCargoWorkDataList(tmpCargoWorkList)
        setLaborAllDataList(tmpLaborList)

        dispatch(SetLoadSpinner(false))  
    }else{
        tmpCargoWorkList.push({postId:0,data:worlplaceCargoWorkData.data})
        tmpLaborList.push({postId:0,data:worlplaceLaborData.data})

        //データ作成
        var [dataList,keys] = await makeCargoWorkDashboardData(worlplaceCargoWorkData.data)
        var laborDataList = await makeLaborDashboardData(worlplaceLaborData.data)
        tmpList.push({title:'',postId:0,cargoWorkDataList:dataList,laborDataList:laborDataList,keys:keys})
        if(count === 1 && !errorFlag){
          setDashboardDataList(tmpList.sort((el1, el2) => {
              if (el1['postId'] < el2['postId']) {
                return 1;
              }
              if (el1['postId'] > el2['postId']) {
                return -1;
              }
                return 0;
          }))
          
          setCargoWorkDataList(tmpCargoWorkList)
          setLaborAllDataList(tmpLaborList)

          dispatch(SetLoadSpinner(false))
        }
    }   
    // サブ検索項目を非表示に変更
    setViewSubSearch(false);
    setViewFunction(false)
  }

  //ダッシュボード用にデータを加工(荷積み)
  const makeCargoWorkDashboardData = async(workplaceData)=>{
    var dataList = (cargoWorkRateList)

    if(dataList.length===0){
      //console.log(dataList)
      dataList=await setCargoWorkSchema()
    }
    //console.log(cargoWorkRateList,workplaceData)
    var keys = []
    workplaceData.map((data)=>{
        var workplaceName = data.workplaceName;
        dataList.forEach(ratedata => {
          if(workplaceList.includes(data.workplaceId)) ratedata[workplaceName] = data[ratedata.id]
        });
        if(workplaceList.includes(data.workplaceId)) keys.push(workplaceName)
    })
    return [dataList,keys]
  }
  //ダッシュボード用にデータを加工(労務)
  const makeLaborDashboardData = async(workplaceData)=>{
        var dataList = (laborRateList)
        if(dataList.length === 0){
          dataList=await setLaborSchema()
        }
        //console.log(dataList,workplaceData)
        workplaceData.map((data)=>{
            var workplaceName = data.workplaceName;
            dataList.forEach(ratedata => {
              if(workplaceList.includes(data.workplaceId)) ratedata[workplaceName] = data[ratedata.id]
              //console.log(data.workplaceId)
            });
        })
    return dataList
  }

  // 検索項目でキーボードの押下イベント
  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      onSearch(null, pageType===0?1:0)
    }
  }

  // 検索のオプションボタンのクリックイベント
  const onClickOptionBtn = (event) => {
    setViewSubSearch(!viewSubSearch);
  }

  const setWorkplace=async()=>{
    const result = await GetApi(`${CargoWorkDefaultUrl}/workplaceId`);
    if (result.errorDetail) {
      ErrorHandling(result, alert);
      return null;
    }
    else{
        //console.log(result)
        //setWorkplaceList(result.data)
        result.data.map((workplace)=>{
          //デフォルト値決定
          workplace.checked = workplace.workplaceId === staffWorkplceId
        })
        setWorkplaceAllList(result.data);
    }    
  }

  useEffect(() => {
    setPost()
    setWorkplace()
    onSearch(null,1)
  }, []);

  //項目リスト変更処理
  useEffect(() => {
    if(cargoWorkDataList.length !== 0){
      var tmpList = []
      cargoWorkDataList.map(async(dataList)=>{  
        var [tmp,keys] = await makeCargoWorkDashboardData(dataList.data)
        dashboardDataList.map((dashboardData)=>{
          if(dashboardData.postId === dataList.postId){
            //console.log(tmp)
            tmpList.push({title:dashboardData.title,postId:dashboardData.postId,cargoWorkDataList:tmp,laborDataList:dashboardData.laborDataList,keys:dashboardData.keys})
          }
          if(dashboardDataList.length === tmpList.length){
            setDashboardDataList(tmpList.sort((el1, el2) => {
              if (el1['postId'] < el2['postId']) {
                return 1;
              }
              if (el1['postId'] > el2['postId']) {
                return -1;
              }
                return 0;
            }))
          
          }
        })
      })
    }
  }, [cargoWorkRateList]);

  useEffect(() => {
    if(laborAllDataList.length !== 0){
      var tmpList = []
      laborAllDataList.map(async(dataList)=>{  
        var tmp = await makeLaborDashboardData(dataList.data)
        dashboardDataList.map((dashboardData)=>{
          if(dashboardData.postId === dataList.postId){
            // console.log(dashboardData)
            tmpList.push({title:dashboardData.title,postId:dashboardData.postId,cargoWorkDataList:dashboardData.cargoWorkDataList,laborDataList:tmp,keys:dashboardData.keys})
          }
          if(dashboardDataList.length === tmpList.length){
            setDashboardDataList(tmpList.sort((el1, el2) => {
              if (el1['postId'] < el2['postId']) {
                return 1;
              }
              if (el1['postId'] > el2['postId']) {
                return -1;
              }
                return 0;
            }))
          
          }
        })
      })
    }
  }, [laborRateList]);

  useEffect(() => {
    if(laborAllDataList.length !== 0){
      //console.log(workplaceList)
      var tmpList = []
      laborAllDataList.map(async(dataList)=>{  
        var tmpLabor = await makeLaborDashboardData(dataList.data)
        var [tmpCargo,keys] = await makeCargoWorkDashboardData(dataList.data)
        dashboardDataList.map((dashboardData)=>{
          if(dashboardData.postId === dataList.postId){
            //console.log(dashboardData)
            tmpList.push({title:dashboardData.title,postId:dashboardData.postId,cargoWorkDataList:tmpCargo,laborDataList:tmpLabor,keys:keys})
          }
          if(dashboardDataList.length === tmpList.length){
            setDashboardDataList(tmpList.sort((el1, el2) => {
              if (el1['postId'] < el2['postId']) {
                return 1;
              }
              if (el1['postId'] > el2['postId']) {
                return -1;
              }
                return 0;
            }))
          
          }
        })
      })
    }

  }, [workplaceList]);

  const setCargoWorkSchema=async()=>{
    const resultDashBoardData = await GetApi(`${CargoWorkDefaultUrl}/schema`)
    if (resultDashBoardData.errorDetail) {
      ErrorHandling(resultDashBoardData, alert)
      return null
    }
    else{
        //console.log(resultDashBoardData)
        setCargoWorkRateData(resultDashBoardData.data)
        var tmpList = []
        resultDashBoardData.data.map((column)=>{
            tmpList.push({rateName:column.comment,id:makeRateId(column.columnName)})
            //console.log(column)
        })
        setCargoWorkRateList(tmpList)
        return tmpList
    }
  }

  const setLaborSchema=async()=>{
    const result = await GetApi(`${LaborDefaultUrl}/schema`)
    if (result.errorDetail) {
      ErrorHandling(result, alert)
      return null
    }
    else{
        //console.log(result)
        setLaborRateData(result.data)
        var tmpList = []
        result.data.map((column)=>{
            tmpList.push({rateName:column.comment,id:makeRateId(column.columnName) })
            //console.log(column)
        })
        setLaborRateList(tmpList)
        return tmpList
    }
  }  

  const makeRateId=(columnName)=>{
    var columns = columnName.split('_')
    var rateId = ''
    columns.map((name,index)=>{
      name = name.replace('overtime','overTime')
      if(index === 0) {
        rateId+=name
        return
      } 
      rateId+=name[0].toUpperCase()+name.slice(1)
    })
    return rateId
  }

  const serachCompany = async()=>{
    const result = await GetApi('/api/masterManagement/company', {companyId:'',companyName:'',page: 1})
    if (result.errorDetail) {
      ErrorHandling(result, alert)
      return null
    }
    const resultList = result.data.responseList.map(item => {
      return {
        index: item.companyId,
        name: item.companyName+'('+item.companyId+')',
      }
    })
    setPostList(resultList)
    return true
  }

  const serachCustomer = async()=>{
    const result = await GetApi('/api/masterManagement/customer', {customerId:'',customerName:'',page: 1})
    if (result.errorDetail) {
      ErrorHandling(result, alert)
      return null
    } 
    const resultList = result.data.responseList.map(item => {
      return {
        index: item.customerId,
        name: item.customerName+'('+item.customerId+')',
      }
    })
    setPostList(resultList)
    return true
  }

  const setPost=()=>{
    if(postType === 0){
      serachCompany()
    }else{
      serachCustomer()
    }
  }

  //会社顧客変更処理
  useEffect(() => {
    setPost()
    // 必要なデータが更新された場合のみ実行
    // eslint-disable-next-line
  }, [postType])

  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }

  // 機能メニューの閉じるイベント
  const onCloseFunction = (event) => {
    setViewFunction(false);
  }

  if(!postList){
    setPost()
  }

  if (!targetDateRef.current) {
    var today = new Date();
    targetDateRef.current = today.getFullYear() + '-' + ('00' + (today.getMonth() + 1)).slice(-2) + '-01'
  }

  useEffect(() => {
    if (grants && subGrants)
      document.title = grants.functionMenuName + '【' + subGrants.functionMenuName + '】 | CoreBoard'
    else if (grants)
      document.title = grants.functionMenuName + ' | CoreBoard'
    else
      document.title = 'CoreBoard'
  }, [grants, subGrants])
    
  // 描画時に一度だけ実行
  useEffect(() => {
  }, [])

  //ダイアログ処理
  const [canOpenDialog, setCanOpenDialog] = useState(false);
  const [canOpenLaborDialog, setCanOpenLaborDialog] = useState(false);
  //ダイアログ閉じるボタン処理
  const handleLaborClose = () => {
    setCanOpenLaborDialog(false);
  }

  //選択ボタン処理
  const onClickLabor = () => {
    setCanOpenLaborDialog(true)
  }
  //ダイアログ閉じるボタン処理
  const handleClose = () => {
    setCanOpenDialog(false);
  }
  //選択ボタン処理
  const onClickDialog = () => {
    setCanOpenDialog(true)
  }

  if (!grants) return null
  return (
    <BoardBaseWrap>
      <BoardBaseFormRow>
        <Search
            showButton
            onClick={() => onSearch(null, pageType === 0 ? 1 : 0)}
            onClickOption={(e) => onClickOptionBtn(e)}
            onKeyDown={(e) => onKeyDown(e)}
            shiftDateRef={targetDateRef}
            viewSubSearch={viewSubSearch}
            grants={grants}
            postList={postList}
            postId={postId}
            setPostId={setPostId}
            departmentId={departmentId}
            setDepartmentId={setDepartmentId}
            postType={postType}
            setPostType={setPostType}
            viewFunction={viewFunction}
            onClickFunction={()=>onClickFunction()}
            onCloseFunction={()=>onCloseFunction()}
            cargoWorkRateData={cargoWorkRateData}
            laborRateData={laborRateData}
            setCargoWorkRateList={setCargoWorkRateList}
            setLaborRateList={setLaborRateList}
            cargoWorkRateList={cargoWorkRateList}
            laborRateList={laborRateList}
            setWorkplaceList={setWorkplaceList}
            workplaceAllList={workplaceAllList}
            workplaceList={workplaceList}
            >
        </Search>
      </BoardBaseFormRow>
      <BoardBaseFormRow>
        <SideSubMenu pageNum={2}/>
        {dashboardDataList.length !== 0 &&
        <GraphBody dateDataList={dashboardDataList} onClickCargo={()=>onClickDialog()} onClickLabor={()=>onClickLabor()}/>
        }
      </BoardBaseFormRow>
      <DashBoardRateModal
              open={canOpenDialog}
              onCloseFunc={handleClose}
              ColumnList={cargoWorkRateData}
              setRateList={setCargoWorkRateList}
              rateList={cargoWorkRateList}
            >
        </DashBoardRateModal>
        <DashBoardRateModal
              open={canOpenLaborDialog}
              onCloseFunc={handleLaborClose}
              ColumnList={laborRateData}
              setRateList={setLaborRateList}
              rateList={laborRateList}
            >
        </DashBoardRateModal>
    </BoardBaseWrap>
  )
}

export default OlapAnalysis


import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  changeFlag: false,
  afsSummaryType: 1,
  workplaceId: undefined,
  timeChangeFlag: false,
  changeTime: ''
}

const CommonSettingSlice = createSlice({
  name: 'commonSetting',
  initialState,
  reducers: {

    // 対象セット
    SetChangeFlag: (state, action) => {
      console.log(action.payload)
      state.changeFlag = action.payload
    },
    // 対象セット
    SetChangeType: (state, action) => {
      console.log(action.payload)
      state.afsSummaryType = action.payload
    },
    // 現在の表示画面の現場ID
    SetWorkplaceId: (state, action) => {
      console.log(action.payload)
      state.workplaceId = action.payload
    },
    // 対象セット
    SetTimeChangeType: (state, action) => {
      console.log(action.payload)
      state.timeChangeFlag = action.payload
    },
    // 対象セット
    SetChangeTime: (state, action) => {
      console.log(action.payload)
      state.changeTime = action.payload
    },
  }
})


export const {
  SetWorkplaceId,
  SetChangeFlag,
  SetChangeType,
  SetTimeChangeType,
  SetChangeTime
} = CommonSettingSlice.actions

export default CommonSettingSlice.reducer

import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import PropTypes from 'prop-types'

import { DeleteApi, MultipartApi } from '../../Common/ApiAxios'
import Button from '../../Common/Atoms/NormalButton'
import FUNC from '../../Common/FunctionEnum'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import ErrorHandling from '../../Common/ErrorHandling'
import { grantDecision } from '../../Common/Utilties/CommonUtility'

import MasterBaseFormWrap from '../Wrap/MasterBaseFormWrap'
import MasterEditButtonsWrap from '../Wrap/MasterEditButtonsWrap'

import { DefaultUrl } from './Staff'
import StaffMasterForm from './StaffMasterForm'

const StaffDetail = ({ onSearch, setDetail, setSelectedId, staffDetail, grants, setUpdateFlag}) => {
  const { register, handleSubmit, setValue, watch, errors, reset, getValues, control } = useForm({ validateCriteriaMode: 'all' })
  const [actionType, setActionType] = useState('POST')
  const [isView, setIsView] = useState(false)
  const [isDeleted, setIsDeleted] = useState(false)
  const [isPasswordChange, setIsPasswordChange] = useState(false)
  const [canWriteStaffBasic, setCanWriteStaffBasic] = useState(false)
  const loginUserInfo = useSelector(state => state.loginUserInfo)


  const alert = useAlert()
  const dispatch = useDispatch()

  // 情報をFormに反映
  useEffect(() => {
    if (staffDetail === null) {
      // 未選択
      setIsView(false)
      return
    }

    if (staffDetail.staffId === undefined) {
      // 新規作成
      
      setActionType('POST')
      reset()
      setIsView(true)
      setIsPasswordChange(false)
      setIsDeleted(false)
      setCanWriteStaffBasic(grantDecision('write', FUNC.STAFF, loginUserInfo, null, null))
      //更新フラグ追加
      setUpdateFlag(true)
    } else {
      // 編集
      setValue('birthDt', staffDetail.birthDt)
      setValue('enterDt', staffDetail.enterDt)
      setValue('retireDt', staffDetail.retireDt)
      setActionType('PUT')
      setIsView(true)
      setIsDeleted(staffDetail.delFlag)
      setCanWriteStaffBasic(grantDecision('write', FUNC.STAFF, loginUserInfo, null, staffDetail.staffId))
    }
    
  // スタッフ情報の変更検知時のみ実行
  // eslint-disable-next-line
  }, [staffDetail])

  // POST・PUT処理
  const onSubmit = (data) => {
    async function sendPost (requestBody, data) {
      var result
      dispatch(SetLoadSpinner(true))
      //console.log(data)
      switch (actionType) {
        case 'POST':
          result = await MultipartApi(`${DefaultUrl}/board`, actionType, requestBody, data)
          break
        case 'PUT':
          result = await MultipartApi(`${DefaultUrl}/board/${staffDetail.staffId}`, actionType, requestBody, data)
          break
        default:
          result = false
          break
      }
      dispatch(SetLoadSpinner(false))
      if (!ErrorHandling(result, alert)) {
        isDeleted ? alert.success('有効化しました') : alert.success('保存しました')
        setDetail(null)
        setSelectedId(null)
        onSearch(null, 1, false)
        setActionType('POST')

        setUpdateFlag(false)
      }
    }
    console.log(staffDetail)
    console.log(data)
    const submitData = new window.FormData()
    if (!data.isPasswordChange) {
      data.password = ''
      data.confirmPassword = ''
    }

    if (actionType === 'POST') {
      data.birthDt = '2000-01-01'; // ダミーデータ
      data.enterDt = new Date().getFullYear() + '-04-01'; // ダミーデータ
      data.staffDivision =  3; // ダミーデータ
    }

    //更新データの削除フラグ無効
    data.delFlag = false

    submitData.append('formData', new window.Blob([JSON.stringify(data)], { type: 'application/json' }))
    data.staffName && sendPost(submitData, data)
  }

  // DELETEはForm状態に影響されないため個別で定義
  const onClickHandleDelete = () => {
    async function sendDelete (staffId, data) {
      dispatch(SetLoadSpinner(true))
      const result = await DeleteApi(`${DefaultUrl}/${staffId}/${data.lastUpdatedAt}`, data, true)
      dispatch(SetLoadSpinner(false))
      ErrorHandling(result, alert)
      if (result.data) {
        alert.info('無効化しました')
        setDetail(null)
        setSelectedId(null)
        onSearch(null, 1)
        setActionType('POST')

        setUpdateFlag(false)
      }
    }
    if (staffDetail.staffId !== undefined) {
      // const lastUpdatedAt = moment(staffDetail.updatedAt, 'YYYY/MM/DD HH:mm:ss')
      const data = { lastUpdatedAt: staffDetail.updatedAt }
      sendDelete(staffDetail.staffId, data)
    }
  }

  return (
    <>
      <MasterBaseFormWrap onSubmit={handleSubmit(onSubmit)} className='h-adr' style={{display: isView ? 'block' : 'none'}}>
        <StaffMasterForm
          register={register}
          errors={errors}
          isDeleted={isDeleted}
          getValues={getValues}
          control={control}
          setValue={setValue}
          staffDetail={staffDetail}
          watch={watch}
          canWrite={canWriteStaffBasic}
          grants={grants}
          loginUserInfo={loginUserInfo}
          isPasswordChange={isPasswordChange}
          setIsPasswordChange={setIsPasswordChange}
          actionType={actionType}
          isView={true}
          setUpdateFlag={setUpdateFlag}
        />
        <MasterEditButtonsWrap>
          <Button
            display={canWriteStaffBasic ? 'block' : 'none'}
            name={actionType === 'PUT' ? (isDeleted ? '有効化' : '更新') : '登録'}
            height='40px'
            width='90px'
            padding='0'
            margin='0 0 12px 0'
            type='submit'
          />
          {actionType === 'PUT' && !isDeleted &&
            <Button
              //display='none' // TODO 一旦非表示
              height='40px'
              width='90px'
              padding='0'
              name='削除'
              type='button'
              margin='0 0 12px 10px'
              onClick={(e) => onClickHandleDelete(e)}
          />}
          
          {actionType === 'POST' && !isDeleted &&
            <div style={{height:'40px', width:'90px', padding:'0', marign:'0 0 12px 10px'}}></div>
          }
        </MasterEditButtonsWrap>
      </MasterBaseFormWrap>
    </>
  )
}

StaffDetail.propTypes = {
  staffDetail: PropTypes.object,
  onSearch: PropTypes.func,
  grants: PropTypes.object
}

export default StaffDetail

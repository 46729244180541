import React, { useState, useEffect,useRef } from 'react'
import { useSelector } from 'react-redux'
//import { useAlert } from 'react-alert'
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'
//import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'

import FUNC from '../../Common/FunctionEnum'

import {BoardBaseFormRow, BoardBaseFormRowScroll} from '../Wrap/BoardBaseWrap'

//import Tooltip from '@material-ui/core/Tooltip'

//import IconChangeImg from '../../../image/page-type-change-icon.png'
import Search from './Search'
//import SideSubMenu from '../SideSubMenu'
import ProgressPerformance from './ProgressPerformance'
import ShootPerformance from './OwnerPerformance'
//import ShootPerformance from './ShootPerformance'
//import StorePerformance from './StorePerformance'
import { GetApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import { SetWorkplaceId } from '../../Common/Slice/SettingSlice'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import SideSubMenu from '../SideSubMenu'

import * as LogisticUtility from '../../Common/Utilties/LogisticUtility'

const DefaultUrl = '/api/logisticManagement'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  overflow-y: none;
  overflow-x: none;
`

const VASPerformance = (props) => {
  const { loginUserInfo } = useSelector(state => state);
  const [viewFunction,setViewFunction] = useState(false);
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC);
  const subGrants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC_DASHBOARD);
  
  const { SettingInfo } = useSelector(state => state)
  const searchDateRef = useRef()

  const history = useLocation(); 
  const hisState = history.state

  const [ownerList,setOwnerList] = useState([]);
  const [patternList,setPatternList] = useState([]);
  const [progressList,setProgressList] = useState([]);
  //バッチNoの指定
  const [batchNo, setBatchNo] = useState(1);

  const dispatch = useDispatch();
  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }

  // 機能メニューの閉じるイベント
  const onCloseFunction = (event) => {
    setViewFunction(false);
  }

  useEffect(() => {
    if (grants && subGrants)
      document.title = grants.functionMenuName + '【' + subGrants.functionMenuName + '】 | CoreBoard'
    else if (grants)
      document.title = grants.functionMenuName + ' | CoreBoard'
    else
      document.title = 'CoreBoard'
  }, [grants, subGrants])
    
  const intervalRef = useRef(null);
  const [windowType,setWindowType]=useState(0)
    // 周期初期のクリア
    const onClearInerval = async () => {
      if (intervalRef.current !== null)
        clearInterval(intervalRef.current);
      
      intervalRef.current = null;
    }
  
    // 描画時に一度だけ実行
    useEffect(() => {
      dispatch(SetWorkplaceId('000001'));
      apiGetShippingReal(true);
      intervalRef.current = setInterval(() => {
        setWindowType((windowType)=>{
          switch(windowType) {
            case 0: // 全体進捗
              return 1;
            case 1: // 荷主進捗
              return 0;
          }
        })
  
        //console.log(windowType)
      }, 1000 * 30); // 30秒間隔で変更
      intervalRef.current = setInterval(() => {
        apiGetShippingReal(true);
       }, 1000 * 60 * 5); // 5分間隔で変更
      return () => {
        onClearInerval();
      };
    }, [])
    // 描画時に一度だけ実行
    useEffect(() => {
      intervalRef.current = setInterval(() => {
        var time = LogisticUtility.getStringFromDate(new Date(), 'hh:mm')
        if(SettingInfo.timeChangeFlag && time==SettingInfo.changeTime){
          searchDateRef.current = LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD')
          apiGetShippingReal(true);
        }
       }, 1000 * 30); // 30秒間隔で変更

      return () => {
        onClearInerval();
      };
    }, [SettingInfo.timeChangeFlag,SettingInfo.changeTime])
    // const onChange=(date)=>{
    //   searchDateRef.current = date
    // }
    // 進捗設定情報の取得
    const apiGetShippingReal = async (isLoadSplinner) => {
      //console.log('call getActivityProgressManagement');
      if (isLoadSplinner)
        dispatch(SetLoadSpinner(true));
      //全体進捗データ取得
      var apiUrl = DefaultUrl + '/getVasShipping';
      var now = new Date(searchDateRef.current);
      var now1 = new Date(searchDateRef.current);
      now.setDate(now.getDate() + 1);
      var data = {
        workplaceId: '000001',
        //targetYMD: '20230516'//LogisticUtility.getStringFromDate(new Date(), 'YYYYMMDD'),
        targetYMD: LogisticUtility.getStringFromDate(now, 'YYYYMMDD'),
        targetDate: LogisticUtility.getStringFromDate(now1, 'YYYY/MM/DD')
      }
      const result = await GetApi(apiUrl, data);
      
      //荷主別進捗データ取得用
      apiUrl = DefaultUrl + '/getVasShippingOwner';
      data = {
        baseId: 1,
        workplaceId: '000001',
        targetYMD: LogisticUtility.getStringFromDate(now, 'YYYYMMDD'),
        targetDate: LogisticUtility.getStringFromDate(now1, 'YYYY/MM/DD'),
      }
      const ownerResult = await GetApi(apiUrl, data);
  
      //パターン別進捗データ取得用
      //apiUrl = DefaultUrl + '/getVasShippingPattern';
      //const patternResult = await GetApi(apiUrl, data);
  
      if (isLoadSplinner)
        dispatch(SetLoadSpinner(false));
  
      if (result.errorDetail) {
        //alert.error('条件の該当データがありません(進捗情報の取得)');
        setProgressList([]);
        setOwnerList([]);
        setPatternList([]);  
      } else {
        setProgressList(result.data);
        setOwnerList(ownerResult.data);
        //setPatternList(patternResult.data);  
      }
    }
    const onChange=(date)=>{
      //console.log('a')
      searchDateRef.current = date
      
      apiGetShippingReal(true);
      //apiGetOwnerReal();
      //apiGetPatternReal();
  
      history.state = {searchDate:searchDateRef.current}
    }
  
    const onChangeStartTime=()=>{
      apiGetShippingReal(true);
      //apiGetOwnerReal();
      //apiGetPatternReal();
    }

    if (!searchDateRef.current) {
      if(hisState && hisState.searchDate){
        searchDateRef.current = LogisticUtility.getStringFromDate(new Date(hisState.searchDate), 'YYYY-MM-DD')
      }else{
        searchDateRef.current = LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD')
      }   
  }
  return (
    <>
      <Search
        onClickFunction={onClickFunction}
        onCloseFunction={onCloseFunction}
        viewFunction={viewFunction}
        searchDateRef={searchDateRef}
        onChange={(date)=>onChange(date)}
      />
      <BoardBaseFormRow>
        <SideSubMenu height={'84vh'} planExisit={true}/>
        <BoardBaseFormRowScroll>
        <ContentWrap>
            {(!SettingInfo.changeFlag || windowType==0) &&
            <ProgressPerformance 
              searchDate={searchDateRef.current}
              progressList={progressList}
              setProgressList={setProgressList}
              onChange={()=>onChangeStartTime()}
              batchNo={batchNo}
              setBatchNo={setBatchNo}
            />
            }
            {(!SettingInfo.changeFlag || windowType!=0) &&
            <ShootPerformance 
              searchDate={searchDateRef.current} 
              ownerList={ownerList} 
              patternList={patternList}  
              progressList={progressList} 
              onChange={()=>onChangeStartTime()}
              batchNo={batchNo}
            />
            }
            {/* 使わないのでクローズ */}
            {/* <ShootPerformance searchDate={searchDateRef.current}/> */}
            {/* <StorePerformance searchDate={searchDateRef.current}/> */}
          </ContentWrap>
        </BoardBaseFormRowScroll>
      </BoardBaseFormRow>
    </>
  )
}

export default VASPerformance

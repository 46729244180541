import React, { useState, useEffect,useRef } from 'react'
import { useSelector } from 'react-redux'
//import { useAlert } from 'react-alert'
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'
//import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import { GetApi } from '../../../Common/ApiAxios'
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'
import { useLocation } from 'react-router-dom'
import { SetWorkplaceId } from '../../../Common/Slice/SettingSlice'
//import ErrorHandling from '../../Common/ErrorHandling'

import FUNC from '../../../Common/FunctionEnum'

import {BoardBaseFormRow, BoardBaseFormRowScroll} from '../../Wrap/BoardBaseWrap'

//import Tooltip from '@material-ui/core/Tooltip'

//import IconChangeImg from '../../../image/page-type-change-icon.png'
import Search from './Search'
//import SideSubMenu from '../SideSubMenu'
// import ProgressPerformance from './ProgressPerformance'
// import ShootPerformance from './ShootPerformance'
import ItemPerformance from './ItemPerformance'

import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'

import SideSubMenu from '../../SideSubMenu'

import StoreDialog from './Atoms/ResultDialog'
import ProgressBoard from './ProgressBoard'

const DefaultUrl = '/api/logisticManagement'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  overflow-y: none;
  overflow-x: none;
`

const VASPerformance = (props) => {
  const { loginUserInfo } = useSelector(state => state);
  const [viewFunction,setViewFunction] = useState(false);
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC);
  const subGrants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC_DASHBOARD);
  const dispatch = useDispatch();
  const alert = useAlert();

  const history = useLocation(); 
  const hisState = history.state
  
  const ownerCd = history.state ? history.state.ownerCd : 1
  const type = history.state ? hisState.type : 0
  const pattran = history.state?history.state.pattrnName:'RETAIL'

  /*2023/12/19 K.Harada ADD 1便,2便を表示するように変更 */
  const batchNo = history.state&&history.state.batchNo?history.state.batchNo:1

  /*2023/01/25 K.Harada ADD 現場IDを表示するように変更 */
  const baseId = history.state&&history.state.baseId?history.state.baseId:2
  const workplaceId = history.state&&history.state.workplaceId?history.state.workplaceId:'000006'
  const ownerName = history.state&&history.state.ownerName?history.state.ownerName:''
  
  const [orgProgressList,setOrgProgressList] = useState([]);
  const [progressList,setProgressList] = useState([]);
  const searchDateRef = useRef(null)
  const { SettingInfo } = useSelector(state => state)
  
  const listSortRef = useRef();
  const listFilterTypeRef = useRef();
  const listFilterRatioRef = useRef();

  //ダイアログ
  const [open,setOpen] = useState(false)
  const [itemCd,setItemCd] = useState(null)
  const [itemName,setItemName] = useState(null)
  const onOpen=(cd,name)=>{
    setItemCd(cd)
    setItemName(name)
    setOpen(true)
  }
  const onClose=()=>{
    setOpen(false)
  }

  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }

  // 機能メニューの閉じるイベント
  const onCloseFunction = (event) => {
    setViewFunction(false);
  }

  useEffect(() => {
    if (grants && subGrants)
      document.title = grants.functionMenuName + '【' + subGrants.functionMenuName + '】 | CoreBoard'
    else if (grants)
      document.title = grants.functionMenuName + ' | CoreBoard'
    else
      document.title = 'CoreBoard'
  }, [grants, subGrants])

      // 荷主の工程情報の取得
  const apiGetProcessOwnerInfo = async () => {

    const apiUrl = DefaultUrl + '/getVasShippingItemGraph';
    dispatch(SetLoadSpinner(true));
    var now = new Date(searchDateRef.current);
    var now1 = new Date(searchDateRef.current);
    now.setDate(now.getDate() + 1);
    const param = {
      baseId: baseId,//2,
      workplaceId: workplaceId,//'000006',
      ownerCd: ownerCd,
      targetYMD: LogisticUtility.getStringFromDate(now, 'YYYYMMDD'),
      targetDate: LogisticUtility.getStringFromDate(now1, 'YYYY/MM/DD'),
      batchNo: batchNo /*2023/12/19 K.Harada ADD 1便,2便を表示するように変更 */
    }
    //console.log(param);
    const result = await GetApi(apiUrl, param);

    dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('工程情報取得失敗');
      setOrgProgressList([]);
      setProgressList([]);
    } else {
      //console.log(result);
      setOrgProgressList(result.data);
      var tmp = result.data;
      // 種別
      if (listFilterTypeRef.current.value == 1) tmp = tmp.filter((item) => item.vasShippingItemInfo.typeName === '米飯');
      else if (listFilterTypeRef.current.value == 1) tmp = tmp.filter((item) => item.vasShippingItemInfo.typeName === '惣菜');
      // 割合
      if (listFilterRatioRef.current.value == 1) tmp = tmp.filter((item) => Number(item.vasShippingItemInfo.totalItem) < Number(item.vasShippingItemInfo.totalPlanItem));
      else if (listFilterRatioRef.current.value == 2) tmp = tmp.filter((item) => Number(item.vasShippingItemInfo.totalItem) >= Number(item.vasShippingItemInfo.totalPlanItem));
      tmp = listSortRef.current.value == 0 ? lowerData(tmp) : upperData(tmp);//:upperData(result.data)
     
      setProgressList(JSON.parse(JSON.stringify(tmp)));
    }
  }
      // パターンの工程情報の取得
  const apiGetProcessPatternInfo = async () => {

    const apiUrl = DefaultUrl + '/getVasShippingPatternItemGraph';
    dispatch(SetLoadSpinner(true));
    var now = new Date(searchDateRef.current);
    var now1 = new Date(searchDateRef.current);
    now.setDate(now.getDate() + 1);
    const param = {
      baseId: baseId,//2,
      workplaceId: workplaceId,//'000006',
      ownerCd: ownerCd,
      patternName: pattran,
      targetYMD: LogisticUtility.getStringFromDate(now, 'YYYYMMDD'),
      targetDate: LogisticUtility.getStringFromDate(now1, 'YYYY/MM/DD'),
      blockName: ''
    }
    if(pattran == '米飯　BIG北・信'){
      param.blockName = "'ニ','サン','ヨン'"
    }
    if(pattran == '米飯　BIG中松愛'){
      param.blockName = "'イチ'"
    }
    //console.log(param);
    const result = await GetApi(apiUrl, param);

    dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('工程情報取得失敗');
      setOrgProgressList([]);
      setProgressList([]);
    } else {
      setOrgProgressList(result.data);
      var tmp = result.data;
      // 種別
      if (listFilterTypeRef.current.value == 1) tmp = tmp.filter((item) => item.vasShippingItemInfo.typeName === '米飯');
      else if (listFilterTypeRef.current.value == 2) tmp = tmp.filter((item) => item.vasShippingItemInfo.typeName === '惣菜');
      // 割合
      if (listFilterRatioRef.current.value == 1) tmp = tmp.filter((item) => Number(item.vasShippingItemInfo.totalItem) < Number(item.vasShippingItemInfo.totalPlanItem));
      else if (listFilterRatioRef.current.value == 2) tmp = tmp.filter((item) => Number(item.vasShippingItemInfo.totalItem) >= Number(item.vasShippingItemInfo.totalPlanItem));
      tmp = listSortRef.current.value == 0 ? lowerData(tmp) : upperData(tmp);//:upperData(result.data)

      setProgressList(JSON.parse(JSON.stringify(tmp)));
    }
  }

  const lowerData =(data)=> {
    // 昇順
    data = data.sort(function(a, b) {
      return Number(a.vasShippingItemInfo.totalItemCount) - Number(b.vasShippingItemInfo.totalItemCount);
    });

    return data;
  }

  const upperData =(data)=> {
    // 降順
    data = data.sort(function(a, b) {
      return Number(b.vasShippingItemInfo.totalItemCount) - Number(a.vasShippingItemInfo.totalItemCount);
    });

    return data;
  }

  // 検索結果のソート変更イベント
  const onChangeSort = ( event ) => {
    var tmp = progressList;
    
    tmp = listSortRef.current.value == 0 ? lowerData(tmp) : upperData(tmp);
    setProgressList(JSON.parse(JSON.stringify(tmp)));
  }

  // 検索結果の種別のフィルター変更イベント
  const onChangeType = ( event ) => {
    var tmp = orgProgressList;

    // 種別
    if (listFilterTypeRef.current.value == 1) tmp = tmp.filter((item) => item.vasShippingItemInfo.typeName === '米飯');
    else if (listFilterTypeRef.current.value == 2) tmp = tmp.filter((item) => item.vasShippingItemInfo.typeName === '惣菜');
    // 割合
    if (listFilterRatioRef.current.value == 1) tmp = tmp.filter((item) => Number(item.vasShippingItemInfo.totalItem) < Number(item.vasShippingItemInfo.totalPlanItem));
    else if (listFilterRatioRef.current.value == 2) tmp = tmp.filter((item) => Number(item.vasShippingItemInfo.totalItem) >= Number(item.vasShippingItemInfo.totalPlanItem));
    tmp = listSortRef.current.value == 0 ? lowerData(tmp) : upperData(tmp);

    setProgressList(JSON.parse(JSON.stringify(tmp)));
  }

  // 検索結果の割合のフィルタ変更イベント
  const onChangeRaito = ( event ) => {
    var tmp = orgProgressList;
    
    // 種別
    if (listFilterTypeRef.current.value == 1) tmp = tmp.filter((item) => item.vasShippingItemInfo.typeName === '米飯');
    else if (listFilterTypeRef.current.value == 2) tmp = tmp.filter((item) => item.vasShippingItemInfo.typeName === '惣菜');
    // 割合
    if (listFilterRatioRef.current.value == 1) tmp = tmp.filter((item) => Number(item.vasShippingItemInfo.totalItem) < Number(item.vasShippingItemInfo.totalPlanItem));
    else if (listFilterRatioRef.current.value == 2) tmp = tmp.filter((item) => Number(item.vasShippingItemInfo.totalItem) >= Number(item.vasShippingItemInfo.totalPlanItem));
    tmp = listSortRef.current.value === 0 ? lowerData(tmp) : upperData(tmp);

    setProgressList(JSON.parse(JSON.stringify(tmp)));
  }

  const intervalRef = useRef(null);
  const [windowType,setWindowType]=useState(0);
  
  // 周期初期のクリア
  const onClearInerval = async () => {
      if (intervalRef.current !== null)
        clearInterval(intervalRef.current);
      
      intervalRef.current = null;
  }
  
  // 描画時に一度だけ実行
  useEffect(() => {
      dispatch(SetWorkplaceId(workplaceId));
      type === 0 ? apiGetProcessOwnerInfo(true) : apiGetProcessPatternInfo(true);

      intervalRef.current = setInterval(() => {
        type === 0 ? apiGetProcessOwnerInfo(true) : apiGetProcessPatternInfo(true);
      }, 1000 * 60 * 15); // 15分間隔で変更
      return () => {
        onClearInerval();
      };
  }, []);

  if (!searchDateRef.current) {
      if(hisState && hisState.searchDate){
        searchDateRef.current = LogisticUtility.getStringFromDate(new Date(hisState.searchDate), 'YYYY-MM-DD')
      }else{
        searchDateRef.current = LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD')
      }   
  }
  return (
    <>
      <Search
        onClickFunction={onClickFunction}
        onCloseFunction={onCloseFunction}
        viewFunction={viewFunction}
        searchDateRef={searchDateRef}
        onChangeSort={(e)=>onChangeSort(e)}
        onChangeType={(e)=>onChangeType(e)}
        onChangeRaito={(e)=>onChangeRaito(e)}
        ownerCd={ownerCd}
        pattran={pattran}
        listSortRef={listSortRef}
        listFilterTypeRef={listFilterTypeRef}
        listFilterRatioRef={listFilterRatioRef}
        workplaceId={workplaceId}
        ownerName={ownerName}
      />
      <BoardBaseFormRow>
        <SideSubMenu height={'84vh'} planExisit={true}/>
        <BoardBaseFormRowScroll>
          <ContentWrap>
            <ProgressBoard progressList={progressList}/>
            <ItemPerformance searchDate={searchDateRef.current} progressList={progressList} onClick={(cd,name)=>onOpen(cd,name)}/>
          </ContentWrap>
        </BoardBaseFormRowScroll>
      </BoardBaseFormRow>
      <StoreDialog
          modalIsOpen={open}
          setIsOpen={setOpen}
          searchDate={searchDateRef.current}
          ownerCd={ownerCd}
          pattran={pattran}
          itemCd={itemCd}
          itemName={itemName}
          type={type}
          baseId={baseId}
          workplaceId={workplaceId}
          batchNo={batchNo} 
      />
    </>
  )
}

export default VASPerformance

import React, { useState } from 'react'
import { useHistory } from "react-router-dom"
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'

//import DateCalendar from '../../Common/Atoms/Calendar'
import FUNC from '../../Common/FunctionEnum'
//import { PostApi } from '../../Common/ApiAxios'

import IconSearchImg from '../../../image/search-icon.png'
import IconCollapseImg from '../../../image/Collapse.png'

import IconFunctionImg from '../../../image/three-reader-vertical-icon.png'
import IconTopImg from '../../../image/menu-icon3.png'
import IconSettingImg from '../../../image/setting-icon.png'
import IconCheckboxImg from '../../../image/checkbox-icon.png'

//import { DataUsageTwoTone } from '@material-ui/icons'

import SearchWrap ,{ FunctionContent, FunctionContentRow, FunctionIcon, FunctionName} from '../Wrap/BoardSerachWrap'

import SearchOptionModal from './Molcules/SearchOptionModal'
import AlertSettingModal from './Molcules/AlertSettingModal'

const Search = (props) => {
  const {
    addStyle,
    onClickFunction,
    onCloseFunction,
    onClickRequetAlertFunction,
    viewSubSearch,
    viewFunction,
    searchDate,
    setSearchDate,
  } = props
  const history = useHistory();
  const loginUserInfo = useSelector(state => state.loginUserInfo);
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.TRUCK);
  const subGrants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.TRUCK_HEALTHCARE);
  //ダイアログ表示用
  const [openDialog1, setOpenDialog1] = useState(false);
  const [openDialog2, setOpenDialog2] = useState(false);

  const onClickSearchBtn=()=>{
    setOpenDialog1(true);
  }

  async function topPage() {
    history.push('/top');
  }

  // アラート設定クリックイベント
  const onClickAlertSetting=()=>{
    console.log('onClickAlertSetting');
    setOpenDialog2(true);
  }

  // アラート要求クリックイベント
  const onClickRequestAlert=()=>{
    console.log('onClickRequestAlert');
    onClickRequetAlertFunction();
  }

  const changeCalendar = (dateStr) => {
    //console.log(dateStr);
    if (!dateStr) {
      //dispatch(SetWorkDate(null))
      {setSearchDate && setSearchDate(null)}
      return null
    }
    if (searchDate !== dateStr) {
      //dispatch(setSearchDate(dateStr))
      {setSearchDate && setSearchDate(dateStr)}
    }

    // ダイアログを閉じる
    setOpenDialog1(false);
  }

  if (!grants && !subGrants) return null;
  return (
    <SearchWrap addStyle={addStyle} viewSubSearch={viewSubSearch} viewFunction={viewFunction}>
      <h3>{grants.functionMenuName}</h3>
      <img alt='階層' src={IconCollapseImg} width="24px" height="24px" style={{marginTop:'4px'}}/>
      <div className='subTitleItem' style={{fontSize:'16px',margin:'8px 0 0 12px'}}>{subGrants.functionMenuName}</div>
      <ul className='mainSearch'>
        <li>
          <Tooltip title={'検索オプション'}>
            <img alt='検索オプション' src={IconSearchImg} width="24px" height="24px" style={{ marginTop: '4px',cursor: 'pointer' }} onClick={onClickSearchBtn}  />
          </Tooltip>
        </li>
        <li className='subSearch'>
        </li>
      </ul>
      <div></div>
      <Tooltip title={'機能メニュー'}>
        <img alt='機能メニュー' src={IconFunctionImg} width="24px" height="24px" style={{marginTop: '4px',marginLeft: 'auto', cursor: 'pointer'}} onClick={onClickFunction} />
      </Tooltip>
      
      <li className='mainFunction'>
        <FunctionContent onMouseLeave={onCloseFunction}>
          <FunctionContentRow onClick={topPage} style={{marginTop:'12px'}}>
            <FunctionIcon alt='トップページ' src={IconTopImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>トップページ</span></FunctionName>
          </FunctionContentRow>
          <hr></hr>
          <FunctionContentRow onClick={onClickRequestAlert} style={{marginBottom:'12px'}}>
            <FunctionIcon alt='アラート確認' src={IconCheckboxImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>アラート確認</span></FunctionName>
          </FunctionContentRow>
          <FunctionContentRow onClick={onClickAlertSetting} style={{marginBottom:'12px'}}>
            <FunctionIcon alt='アラート設定' src={IconSettingImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>アラート設定</span></FunctionName>
          </FunctionContentRow>
        </FunctionContent>
      </li>
      
      <SearchOptionModal
        grants={grants}
        modalIsOpen={openDialog1}
        setIsOpen={setOpenDialog1}
        changeCalendar={changeCalendar}
        searchDate={searchDate}
      />
      <AlertSettingModal
        grants={grants}
        modalIsOpen={openDialog2}
        setIsOpen={setOpenDialog2}
      />
    </SearchWrap>
  )
}

Search.propTypes = {
  addStyle: PropTypes.string, // css用追加用
  onClickFunction: PropTypes.func,
  onCloseFunction: PropTypes.func,
  viewSubSearch: PropTypes.bool, // サブ検索項目を表示するか
  viewFunction: PropTypes.bool, // サブ機能を表示するか
  searchDate: PropTypes.string,
  setSearchDate: PropTypes.func,
}

export default Search

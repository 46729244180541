import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
//import ToolTips from '@material-ui/core/Tooltip'

import * as HealthCareUtilty from '../../../Common/Utilties/HealthCareUtilty'

import AlertModal from '../Molcules/AlertModal'

import StressLevel0 from '../../../../image/stress-level0.png'
import StressLevel1 from '../../../../image/stress-level1.png'
import StressLevel2 from '../../../../image/stress-level2.png'
import StressLevel3 from '../../../../image/stress-level3.png'

const MediaWrap = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 1660px; /* 100% - margin-left - margin-right */
  margin-left: 4px;
  margin-right: 4px;
  margin-top: 2px;
  margin-bottom: 2px;
  box-shadow: 0 0 3px 2px ${props => props.selected ? 'rgba(0,128,0,0.8)' : 'rgba(0,0,0,0.4)'};

  color: #000;
  font-size: 16px;
  font-weight: bold;

  text-align: center;
  align-items: center;
`
const MediaItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 40px;
  border-right: 2px dashed #fff;
`

const HealthValue = styled.div`
  width: 100px;
  margin: auto;

  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;

  text-align: right;
` 

const HealthWrap = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
`


// 運動強度のレベルに応じたストレスのアイコンを取得
function getStressIcon(value) {
  if (value >= 90)
    return StressLevel3;
  else if (value >= 80)
    return StressLevel2;
  else if (value >= 70)
    return StressLevel1;
    
  return StressLevel0;
 }

 function getAlertColor(flag) {
  if (flag === null)
    return '';

  if (flag) {
    return '#ff0000';
  }

  return '';
}

const MediaStaffDataHealthCareDaily = (props) => {
  const {
    healthCareData,
  } = props
  const [workAlertFlag, setWorkAlertFlag] = useState(false);

  //ダイアログ表示用
  const [openDialog1, setOpenDialog1] = useState(false);
  const onClickAlertBtn=()=>{
    setOpenDialog1(true);
  }
  
  useEffect(() => {
    setWorkAlertFlag(healthCareData.week40overtimeFlag
       + healthCareData.worktimeDailyOvertimeFlag
        + healthCareData.worktimeWeeklyOvertimeFlag
         + healthCareData.worktimeMonthlyOvertimeFlag);
  }, [healthCareData]);

  // 初期値
  useEffect(() => {
    setWorkAlertFlag(healthCareData.week40overtimeFlag
       + healthCareData.worktimeDailyOvertimeFlag
        + healthCareData.worktimeWeeklyOvertimeFlag
         + healthCareData.worktimeMonthlyOvertimeFlag);
  });

  //console.log(healthCareData)
  return (
    <MediaWrap>
    <MediaItem style={{marginTop:'0.5rem', marginBottom:'0.5rem'}}>
        <HealthWrap style={{width:'110px', height:'100%', fontSize:'16px'}}>
          <HealthValue style={{width:'110px', textAlign:'center'}}>
            {HealthCareUtilty.getStringFromDate(new Date(healthCareData.targetYMD), 'MM / DD')}
            <span style={{fontSize:'12px'}}>（<span style={{color:HealthCareUtilty.getDateColor(healthCareData)}}>{healthCareData.week}</span>）</span>
            {/*healthCareData.week40overtimeFlag > 0 &&
              <ToolTips title={'週40時間オーバー'}><Badge style={{marginLeft:'0.25rem'}}>40</Badge></ToolTips>
            */}
          </HealthValue>
        </HealthWrap>
      </MediaItem>
      <MediaItem style={{marginTop:'0.5rem', marginBottom:'0.5rem'}}>
        <HealthWrap style={{height:'100%'}}>
          <HealthValue><span style={{marginRight:'15px', color:HealthCareUtilty.getAlertColor(workAlertFlag)}}>{HealthCareUtilty.getCommaText(Number(healthCareData.totalResultWorkTime).toFixed(2))}</span></HealthValue>
          <HealthValue><span style={{marginRight:'15px'}}>{HealthCareUtilty.getCommaText(Number(healthCareData.totalResultRestTime).toFixed(2))}</span></HealthValue>
          <HealthValue><span style={{marginRight:'15px'}}>{HealthCareUtilty.getCommaText(Number(healthCareData.totalResultOverTime).toFixed(2))}</span></HealthValue>
          <HealthValue>
            <span style={{marginRight:'15px'}}>{HealthCareUtilty.getCommaText(Number(healthCareData.totalResultWorkDtNum))}</span>
          </HealthValue>
        </HealthWrap>
      </MediaItem>
      <MediaItem style={{marginTop:'0.5rem', marginBottom:'0.5rem'}}>
        <HealthWrap style={{height:'100%'}}>
          <HealthValue><span style={{marginRight:'15px'}}>{HealthCareUtilty.getCommaText(healthCareData.steps)}</span></HealthValue>
          <HealthValue><span style={{marginRight:'15px'}}>{HealthCareUtilty.getCommaText(Number(healthCareData.distance).toFixed(2))}</span></HealthValue>
          <HealthValue><span style={{marginRight:'15px'}}>{HealthCareUtilty.getCommaText(healthCareData.calorie)}</span></HealthValue>
          <HealthValue><span style={{marginRight:'15px', color:HealthCareUtilty.getAlertColor(healthCareData.sleepTimeAlertFlag)}}>{HealthCareUtilty.getCommaText(Number(healthCareData.sleepTime).toFixed(2))}</span></HealthValue>
          <HealthValue style={{width:'100px'}}><span style={{marginRight:'15px', color:HealthCareUtilty.getAlertColor(healthCareData.sleepScoreAlertFlag)}}>{HealthCareUtilty.getCommaText(healthCareData.sleepScore)}</span></HealthValue>
          <HealthValue><span style={{marginRight:'15px', color:HealthCareUtilty.getAlertColor(healthCareData.heartRateAlertFlag)}}>{HealthCareUtilty.getCommaText(healthCareData.heartRate)}</span></HealthValue>
          <HealthValue><span style={{marginRight:'15px'}}>--.-</span></HealthValue>
          <HealthValue style={{textAlign:'center'}}>
            {healthCareData.fatigue > 0 &&
              <span><img alt='ストレス' src={getStressIcon(healthCareData.fatigue)} /></span>
            }
          </HealthValue>
        </HealthWrap>
      </MediaItem>
      <MediaItem style={{marginTop:'0.5rem', marginBottom:'0.5rem'}}>
        <HealthWrap style={{height:'100%'}}>
          <HealthValue style={{ width:'120px', textAlign:'center'}}>
            <span>―</span>
          </HealthValue>
        </HealthWrap>
      </MediaItem>
      <MediaItem style={{marginTop:'0.5rem', marginBottom:'0.5rem'}}>
        <HealthWrap style={{height:'100%'}}>
          <HealthValue style={{ width:'180px', textAlign:'center'}}>
          {(healthCareData.sleepTimeAlertFlag > 0
            || healthCareData.sleepScoreAlertFlag > 0
            || healthCareData.fatigueAlertFlag > 0
            || healthCareData.heartRateAlertFlag > 0
            || healthCareData.week40overtimeFlag > 0
            || healthCareData.worktimeDailyOvertimeFlag > 0
            || healthCareData.worktimeWeeklyOvertimeFlag > 0
            || healthCareData.worktimeMonthlyOvertimeFlag > 0
            ) &&
            <span style={{color:'red', cursor:'pointer'}} onClick={onClickAlertBtn}>発生</span>
          }
          </HealthValue>
        </HealthWrap>
      </MediaItem>
      {(healthCareData.sleepTimeAlertFlag > 0
        || healthCareData.sleepScoreAlertFlag > 0
        || healthCareData.fatigueAlertFlag > 0
        || healthCareData.heartRateAlertFlag > 0
        || healthCareData.week40overtimeFlag > 0
        || healthCareData.worktimeDailyOvertimeFlag > 0
        || healthCareData.worktimeWeeklyOvertimeFlag > 0
        || healthCareData.worktimeMonthlyOvertimeFlag > 0
        ) &&
        <AlertModal
          healthCareData={healthCareData}
          modalIsOpen={openDialog1}
          setIsOpen={setOpenDialog1}
        />
      }
    </MediaWrap>
  )
}

MediaStaffDataHealthCareDaily.propTypes = {
    healthCareData: PropTypes.object,
}

export default MediaStaffDataHealthCareDaily

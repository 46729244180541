import React, { useEffect,useState } from 'react'
//import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import { makeStyles } from '@material-ui/core/styles';
import Modal from 'react-modal'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { GetApi } from '../../../Common/ApiAxios'
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'
import CloseIcon from '../../../../image/close_icon.png'
import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'
import Input from '../../../Common/Atoms/Input'
const DefaultUrl = '/api/logisticManagement'
const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

/* 縦並 */
const Wrap = styled.div`
  display:flex;
  flex-flow: column;
`

const EditDialog = ({ open,onCloseFunc}) => {
  //const { register, handleSubmit, getValues, setValue, watch, reset, errors, control } = useForm({ validateCriteriaMode: 'all' })
  const classes = useStyles();

  const alert = useAlert()
  const dispatch = useDispatch()
  const [beforePlanList,setBeforePlanList] = useState([]);

  const [planList,setPlanList] = useState([]);
  const [shootList,setShootList] = useState([]);
  const [storeList,setStoreList] = useState([]);
  const [managementList,setManagementList] = useState([]);
  const [processList,setProcessList] = useState([]);
  // 進捗設定情報の取得
  const apiGetSoterReal = async (isLoadSplinner) => {
      //console.log('call getActivityProgressManagement');
      if (isLoadSplinner)
        dispatch(SetLoadSpinner(true));
  
      const apiUrl = DefaultUrl + '/getSoterPlan';
     
      var now = new Date();
      const data = {
        workplaceId: '000011',
        targetYMD: LogisticUtility.getStringFromDate(now, 'YYYY-MM-DD'),
      }
      const result = await GetApi(apiUrl, data);
  
      if (isLoadSplinner)
        dispatch(SetLoadSpinner(false));
  
      if (result.errorDetail) {
        //alert.error('条件の該当データがありません(進捗設定情報の取得)');
      } else {
        //console.log(result.data);
        
        if(result.data.length>4){
          setPlanList(result.data[0][0]);
          if(result.data[0].length>1){
            setBeforePlanList(result.data[0][1]);
          }
          setShootList(result.data[1]);
          setStoreList(result.data[2]);
          setManagementList(result.data[3])
          setProcessList(result.data[4])
        }        
      }
  }
  
  useEffect(() => {
  }, [])

  // モーダル
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      //opacity: 0.8,
      padding: '10px',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    },
    overlay: {
      zIndex: 10
    }
  }

    //ダイアログ開いた時の処理
    function afterOpenModal () {
      apiGetSoterReal(true)
    }
  
    function closeModal () {
      onCloseFunc();
    }
    const toLocaleStringNumber=(number)=>{
      if(!number) return 0
      var tmp = Number(number).toFixed(0)
      if(tmp == NaN)return 0
      return LogisticUtility.getCommaText(tmp)
    }
  return (
    <Modal
    isOpen={open}
    onAfterOpen={afterOpenModal}
    onRequestClose={closeModal}
    style={customStyles}
    contentLabel='Create Zone Modal'
  >
    <div style={{ width:'1000px', height:'800px', marginLeft:'1rem' }}>
      <Wrap>
        <div style={{ height:'20px', marginLeft:'auto', marginRight:'10px', marginTop:'10px', cursor: 'pointer'}}>
          <img alt='閉じる' className='close' src={CloseIcon} title={'ダイアログを閉じる'} width="12px" height="12px" onClick={closeModal} />
        </div>        
        {processList && processList.map((entry, index) => 
          {  
              return (
                <div style={{  display: 'flex',flexDirection: 'row'}} key={index+'_'+entry.shootName}>
                <div style={{height:'5px'}}/>
                <div style={{  display: 'flex',flexDirection: 'row'}}>
                <div style={{width:'150px',lineHeight:'32px'}}>{entry.shootName}</div>
                <div style={{width:'100px',lineHeight:'32px'}}>{'作業物量'}</div>
                <Input
                  type='text' 
                  width='5rem'
                  placeholder='作業物量'
                  name={'quantity'+'_'+entry.shootName}
                  // inputRef={planAllRef} 
                  // onBlur={onBlur}
                  defaultValue={toLocaleStringNumber(entry.planShootQuantity)}
                  />
                </div>
                <div style={{width:'100px',lineHeight:'32px'}}>{'作業開始時間'}</div>
                    <Input
                      type='text' 
                      width='5rem'
                      placeholder='作業開始時間'
                      name={'start_time_'+entry.shootName}
                      // inputRef={planAllRef} 
                      // onBlur={onBlur}
                      defaultValue={(entry.planWorkTime)}
                    />
                <div style={{width:'100px',lineHeight:'32px'}}>{'作業終了時間'}</div>
                    <Input
                      type='text' 
                      width='5rem'
                      placeholder='作業終了時間'
                      name={'end_time_'+entry.shootName}
                      // inputRef={planAllRef} 
                      // onBlur={onBlur}
                      defaultValue={(entry.progressEndTime)}
                />
                </div> 
                
              )
          })
        }        
        <div style={{height:'10px'}}/>
        {shootList && shootList.map((entry, index) => 
          {  
              return (
                <div style={{  display: 'flex',flexDirection: 'row'}} key={entry.shootCode+'_'+entry.shootName}>
                <div style={{height:'5px'}}/>
                <div style={{  display: 'flex',flexDirection: 'row'}}>
                <div style={{width:'100px',lineHeight:'32px'}}>{entry.shootName}</div>
                <Input
                  type='text' 
                  width='5rem'
                  placeholder='作業物量'
                  name={entry.shootCode+'_'+entry.shootName}
                  // inputRef={planAllRef} 
                  // onBlur={onBlur}
                  defaultValue={toLocaleStringNumber(entry.planShootQuantity)}
                  />
                </div>
                {storeList && storeList.map((entry1, index) => 
                {
                  if(entry1.shootCode == entry.shootCode){
                  return(
                    <>
                    <div style={{height:'5px'}}/>
                    <div style={{  display: 'flex',flexDirection: 'row'}} key={entry1.storeName+'_'+entry1.storeCd}>
                    <div style={{width:'100px',lineHeight:'32px'}}>{entry1.storeName}</div>
                    <Input
                      type='text' 
                      width='5rem'
                      placeholder='作業物量'
                      name={entry1.storeName+'_'+entry1.storeCd}
                      // inputRef={planAllRef} 
                      // onBlur={onBlur}
                      defaultValue={toLocaleStringNumber(entry1.planStoreQuantity)}
                    />
                    </div>    
                    </>            
                  )
                  }
                })}
                </div>
              )
          })
        }        
      </Wrap>
    </div>
  </Modal>
  )
}

EditDialog.propTypes = {
    open: PropTypes.bool,
    closeFunc: PropTypes.func,
    updatedAttendanceFunc: PropTypes.func,
    defaultUrl: PropTypes.string,
    iconName: PropTypes.string,
    text: PropTypes.string,
    dailyWorkInfoList: PropTypes.array
}

export default EditDialog
const GRANT = {
    SYSADMIN: 1, // システム監理者 
    ADMIN: 2, // 監理者
    GENERAL: 3, // 一般
    CAINZ: 6, // カインズ
    AFS: 7, // イオンフォードサプライズ
    GUEST: 8,//AFS運送事業部向け
    AFSTRUCK: 12, // AFS運送会社
  }
  
  export default GRANT
  
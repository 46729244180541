import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import { useSelector } from 'react-redux'
//import FUNC from '../../Common/FunctionEynum'
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'

//import { GetApi } from '../../Common/ApiAxios'
//import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import * as LogisticUtility from '../../Common/Utilties/LogisticUtility'
//import ErrorHandling from '../../Common/ErrorHandling'

import ProgressGraphBoard from './Atoms/ProgressGraphBoard'
import EditIcon from '../../../image/edit-icon.png'
import PlanDateDialog from './Atoms/PlanDateDialog'

const DefaultUrl = '/api/logisticManagement'

const BodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: row;

  vertical-align: middle;
  padding:0.5rem;
`
const TitleContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: row;
  text-align: center;
  vertical-align: middle;
  width: 100%;
`
const ContentColumn = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;

  vertical-align: middle;
  padding:0.5rem;
`

const ProgressContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;

  vertical-align: middle;
  width: ${props => props.width ? props.width : '500px'};
  height: ${props => props.height ? props.height : '135px'};//2024/02/27 170pxから変更
  background-color: whitesmoke;
`

const ContentWrap = styled.div`
  display: flex; /* 横並び */
  flex-direction: column;
  vertical-align: middle;

  width: 100%;
  height: 600px;
`

const Title = styled.div`
  display: flex; /* 横並び */
  flex-direction: row;
  font-weight: bold;
  color: #000;
  text-align: left;
  font-size: 1.8rem; //2024/02/21 MOD 2rem;
`

const ProgressTitle = styled.div`
  font-weight: bold;
  color: #000;
  text-align: center;
  font-size: 1.8rem;
  width 100%;
  font-size: 1.5rem; //2024/02/21 MOD 1.8rem;
  padding:0.5rem;
  //margin-top:0.7rem;//2024/02/21 DEL

`
const ProgressImageTitle = styled.div`
  font-weight: bold;
  color: #000;
  text-align: center;
  font-size: 1.8rem;
  width 100%;
  padding:0.5rem;
  margin-top:0.7rem;
  margin-left:24px;
`

const ProgressContentRow = styled.div`
  font-weight: bold;
  color: #000;
  text-align: center;
  font-size: 2rem;
`
const ProgressProgressContentRow = styled.div`
  font-weight: bold;
  color: #000;
  text-align: center;
  font-size: 1.5rem;
`
const ProgressSubContentRow1 = styled.div`
  font-weight: bold;
  color: #000;
  text-align: center;
  font-size: 1.0rem;//2024/02/27 MOD 1.5rem;
`
const ProgressSubContentRow = styled.div`
  font-weight: bold;
  color: #000;
  text-align: center;
  font-size: 1rem;
`

const ProgressSubBody= styled.div`
  display: flex; /* 縦並び */
  flex-direction: row;

  text-align: center;
  vertical-align: middle;
  padding:0.3rem;
  width: 500px;
  height: 100px;
`
const ProgressSubBody1= styled.div`
  display: flex; /* 縦並び */
  flex-direction: row;

  text-align: center;
  vertical-align: middle;
  padding:0rem;
  width: 500px;
  height: 100px;
`
const ProgressSubContent = styled.div`
  display: flex; /* 横並び */
  flex-direction: column;
  vertical-align: middle;
  width: 250px;
  padding:0.5rem;
`

const ProgressStaffRow = styled.div`
  font-weight: bold;
  color: #000;
  text-align: center;
  font-size: 2rem;
  padding:0.2rem;//2024/02/27 MOD 0.4rem;
`

const ProgressPerformance = (props) => {
  const {
    searchDate,progressList,setProgressList,onChange,batchNo, setBatchNo
  } = props
  const { loginUserInfo } = useSelector(state => state);
  const isExternalUser = LogisticUtility.checkExternalUser(loginUserInfo.grantNameId);

  const [opacity,setOpacity]=useState(true)
  
  const [openDialog,setOpenDialog] = useState(false);
  // 描画時に一度だけ実行
  useEffect(() => {
  }, [progressList])

  // 描画時に一度だけ実行
  useEffect(() => {
  }, [])

  /**
   * 勤務時間(HH:mm)を秒数で返す
   * @param {string} time 勤務時間(HH:mm)
   * @return {int} 秒数
   */
  function timeToSec(time) {
    if(!time)
      return 0
    let tokens = time.split(':')
    let sec = 0

    if (tokens[0]) sec += Number(tokens[0]) * 3600
    if (tokens[1]) sec += Number(tokens[1]) * 60

    return sec
  }
    
    const makeStaffNum=(num)=>{
      if(!num) return '000'
      if(num<0) return num='000'
      return ('000'+num).slice(-3);
    }
    const makeTitle=()=>{
         
        //進捗工数
        var totalTime = progressList.operationHour
        //稼働スタッフ
        var staffNum = progressList.planVasStaffNum
        //予定生産性
        var productivity = progressList.totalPlanItem/progressList.planVasWorkTime
        //残課題
        var reminng = progressList.totalPlanItem - progressList.totalItem
        //残り必要工数
        var diffEnd = reminng/(productivity*staffNum)*60
        diffEnd = Math.round(diffEnd)
 
        //現在時刻
        var now = new Date()
        var nowDate = now.getFullYear() + '-' + ('00'+Number(now.getMonth()+1)).slice(-2)+ '-' + ('00'+now.getDate()).slice(-2)
        if(isNaN(diffEnd)) return <>【作業終了予定時刻】 <span style={{fontSize:'2.1rem'}}>{'--:--'}</span></>
        if(nowDate != searchDate) {
          if(progressList.endWorkTime){
            var endWorkTime = progressList.endWorkTime.slice(0,5)
            return <>【作業終了時刻】 <span style={{fontSize:'2.1rem'}}>{endWorkTime}</span></>
          }else{
            return <>【作業終了時刻】 <span style={{fontSize:'2.1rem'}}>{'--:--'}</span></>
          } 
        }
        //種まき終了予定時間
        var endPlanSecond= progressList.totalPlanItem/productivity/staffNum

        //終了予定時間
        var endMin = Number(now.getMinutes()+diffEnd%60)
        var endHour = Number(now.getHours()+Math.floor(diffEnd/60) + Math.floor(endMin/60))
        endMin = endMin%60
        var endTime = ('00'+endHour).slice(-2) + ':' + ('00'+endMin).slice(-2)

        var planStartMin = Number(progressList.startTime.split(':')[1])
        var planStartHour = Number(progressList.startTime.split(':')[0])

        //終了予定時間差分
        var endDiffMin = endMin-Math.floor(endPlanSecond*60%60 + planStartMin)
        var endDiffHour = endHour-Math.floor(endPlanSecond + planStartHour)

        var endDiffMinCount = Number(endDiffHour)*60 + Number(endDiffMin)
        
        endDiffMin = endDiffMinCount%60
        endDiffHour = Math.floor(endDiffMinCount/60)

        if(endDiffHour<0){
          endDiffMin=endDiffMin*(-1)
          endDiffHour=endDiffHour*(-1)
          progressList.endTimeDiffFlag=2
          
        }

        var endDiffTime = [('00'+(endDiffHour)).slice(-2) , ('00'+(endDiffMin)).slice(-2)]
        
        var diffTime = ''
        if(endDiffTime[0]!='00'){
          diffTime += endDiffTime[0]+'時間'
        }
        if(endDiffTime[1]!='00'){
          diffTime += endDiffTime[1]+'分'
        }

        if(progressList.endTimeDiffFlag==2){
          return <>【作業終了予定時刻】 <span style={{fontSize:'2.1rem'}}>{endTime}</span>【お知らせ】 <span>予定より{diffTime}早く終了する見込みです。</span></>
        }
        else if(progressList.endTimeDiffFlag==1){
          return <>【作業終了予定時刻】 <span style={{fontSize:'2.1rem'}}>{endTime}</span>【お知らせ】 <span>予定通りの終了時刻です。</span></>
        }
        else{
          return <>【作業終了予定時刻】 <span style={{fontSize:'2.1rem'}}>{endTime}</span>【お知らせ】 <span style={{color:'#EE2439',opacity:opacity?1:0}}>予定より{diffTime}遅れています。</span></>
        }
    }


    const makeContent=(progress,plan)=>{
      if(progress && plan) return <>{Number(progress).toLocaleString()} <span style={{fontSize:'90%'}}>/</span> <span style={{fontSize:'70%'}}>{Number(plan).toLocaleString()}</span></>
      else if(plan) return <>{'0'} <span style={{fontSize:'90%'}}>/</span> <span style={{fontSize:'70%'}}>{Number(plan).toLocaleString()}</span></>
      return <>{'0'} <span style={{fontSize:'90%'}}>/</span> <span style={{fontSize:'70%'}}>{'0'}</span></>
    }

    const makeContentProductivity=(progress,plan,progressTime,planTime)=>{

      if(progress && plan && progressTime && planTime && planTime!=0 && progressTime!=0) return <>{Number(Number(progress/progressTime).toFixed(2)).toLocaleString()} <span style={{fontSize:'90%'}}>/</span> <span style={{fontSize:'70%'}}>{Number(Number(plan/planTime).toFixed(2)).toLocaleString()}</span></>
      else if(plan && planTime && planTime!=0 ) return <>{'0'} <span style={{fontSize:'90%'}}>/</span> <span style={{fontSize:'70%'}}>{Number(Number(plan/planTime).toFixed(2)).toLocaleString()}</span></>
      return <>{'0'} <span style={{fontSize:'90%'}}>/</span> <span style={{fontSize:'70%'}}>{'0'}</span></>
    }
    const makeContentProductivityPercent=(progress,plan,progressTime,planTime)=>{
      if(progress==null || plan==null || progress==undefined || plan==undefined || progressTime==undefined || planTime==undefined
        || plan == 0   || progressTime == 0   || planTime == 0) return <span>0.00 %</span>
      var progress1 = Number(progress/progressTime)
      var plan1 = Number(plan/planTime)
      return <span style={{color:makeContentProductivityPercentFlag(progressList.totalItem,progressList.totalPlanItem,progressList.operationHour,progressList.planVasWorkTime)<0?
        '#EE2439':makeContentProductivityPercentFlag(progressList.totalItem,progressList.totalPlanItem,progressList.operationHour,progressList.planVasWorkTime)==0?
        '#009EEC':'#535353'}}>{(progress1/plan1*100).toFixed(2)+'%'}</span>
    }
    const makeContentProductivityPercentFlag=(progress,plan,progressTime,planTime)=>{
      if(progress==null || plan==null || progress==undefined || plan==undefined || progressTime==undefined || planTime==undefined
        || plan == 0   || progressTime == 0   || planTime == 0) return -100
      var progress1 = Number(progress/progressTime)
      var plan1 = Number(plan/planTime)
      return Number(progress1/plan1*100-100)
    }
    const makeGraphData=(plan,progress)=>{

      if(!plan || !progress) return;
    
      var data = [
        { name: '残物量', value: Number(plan)-Number(progress), color: 'firebrick' },
        { name: '実績物量', value: Number(progress), color: 'steelblue' },
      ];
      
      return JSON.parse(JSON.stringify(data));
    }
    const makeHourInput=()=>{
      var now = new Date()
      var nowDate = now.getFullYear() + '-' + ('00'+Number(now.getMonth()+1)).slice(-2)+ '-' + ('00'+now.getDate()).slice(-2)
      if(nowDate != searchDate) return <>-</>;
      if(!progressList.timeZone) return<>0</>;
      var inputResult = Number(progressList.timeZone)
      return inputResult.toLocaleString();
    }
    const makeDelayRate=()=>{
      var now = new Date()
      var nowDate = now.getFullYear() + '-' + ('00'+Number(now.getMonth()+1)).slice(-2)+ '-' + ('00'+now.getDate()).slice(-2)

      if(!progressList.timeZone || nowDate != searchDate) return<>0.00%</>;
    
      var inputResult = progressList.timeZone
      var planHour = progressList.totalPlanItem/24.0
      var delayRate = inputResult/planHour*100
      var colorCode = '#009EEC'
      if(delayRate<100){
        colorCode='#EE2439'
      }
      return <span style={{color:colorCode}}>{delayRate.toFixed(2)}%</span>;
    }

    const makeHourAverageInput=()=>{
      if(!progressList.totalItem) return '0.00';
      //var now = new Date()
      //var hours = now.getHours()+now.getMinutes()/60.0
      var hours = progressList.operationHour;
      if(!hours || hours==0)  return '0.00';
      
      if(hours < 1.0) return Number(Number(progressList.totalItem).toFixed(2)).toLocaleString();

      return Number(Number(progressList.totalItem/hours).toFixed(2)).toLocaleString();
    }
    const makeWeekRate=()=>{
      
      var average = makeHourAverageInput()
      if(!progressList.aveBeforeWeek || progressList.aveBeforeWeek ==0) return<>0.00%</>;
 
      var inputResult = Number(average.replace(',',''))
      var planHour = progressList.aveBeforeWeek

      //console.log(average.replace(',',''),planHour)
      var rate = Math.abs(inputResult/planHour*100)
      var colorCode = '#009EEC'
      if(rate<100){
        colorCode='#EE2439'
      }
      return <span style={{color:colorCode}}>{rate.toFixed(2)}%</span>;
    }
    const makePercent=(progress,plan)=>{
      if(progress==null || plan==null || progress==undefined || plan==undefined || plan == 0) return <span>0.00 %</span>

      return <span>{(progress/plan*100).toFixed(2)+'%'}</span>
    }

    const makeItem=(progress,plan)=>{
      if(progress==null || plan==null || progress==undefined || plan==undefined || plan == 0) return <span>0</span>

      return <span>{plan - progress}</span>
    }

    const onClickImage=()=>{
      setOpenDialog(true)
    }

  return (
    <>
    <ContentWrap>
      <Title>
        {makeTitle()}
        {!isExternalUser &&
          <div style={{ height:'16px', marginLeft:'auto', marginRight:'10px', marginTop:'0px', cursor: 'pointer'}}>
          <img alt='開く' className='open' src={EditIcon} title={'ダイアログを開く'} width="16px"  onClick={onClickImage}/>
          </div>
        }
      </Title>
      <BodyContent>
        <ContentColumn>
          <ProgressContent>
            <TitleContent>
              <ProgressTitle>種まき作業実績</ProgressTitle>
            </TitleContent>
            <ProgressProgressContentRow>{makeContent(progressList.totalItem,progressList.totalPlanItem)}</ProgressProgressContentRow>
            <ProgressSubContentRow1>{makePercent(progressList.totalItem,progressList.totalPlanItem)}</ProgressSubContentRow1>
            <ProgressSubBody1>
              <ProgressSubContent>
                <ProgressSubContentRow>１便</ProgressSubContentRow>
                <ProgressProgressContentRow>{makeContent(progressList.totalItem1,progressList.totalPlanItem1)}</ProgressProgressContentRow>
              </ProgressSubContent>
              <ProgressSubContent>
                <ProgressSubContentRow>2便</ProgressSubContentRow>
                <ProgressProgressContentRow>{makeContent(progressList.totalItem2,progressList.totalPlanItem2)}</ProgressProgressContentRow>
              </ProgressSubContent>
            </ProgressSubBody1>
          </ProgressContent>
          <div style={{height:'0.5rem'}}/>{/* 2024/02/27 K.Harada MOD サイズ変更 1rem => 0.5rem  */}
          <ProgressContent>
            <ProgressTitle>アイテム別種まき進捗率</ProgressTitle>
            <ProgressContentRow>{makePercent(progressList.totalItemCount,progressList.totalPlanItemCount)}</ProgressContentRow>
            <ProgressSubBody>
              <ProgressSubContent>
                <ProgressSubContentRow>完了アイテム数</ProgressSubContentRow>
                <ProgressContentRow>{progressList.totalItemCount?progressList.totalItemCount:0} 個</ProgressContentRow>
              </ProgressSubContent>
              <ProgressSubContent>
                <ProgressSubContentRow>未完了アイテム数</ProgressSubContentRow>
                <ProgressContentRow>{makeItem(progressList.totalItemCount,progressList.totalPlanItemCount)} 個</ProgressContentRow>
              </ProgressSubContent>
            </ProgressSubBody>
          </ProgressContent>
        </ContentColumn>
        <ContentColumn>
          <ProgressContent width={'400px'}>
            <ProgressTitle>生産性</ProgressTitle>
            <div style={{height:'0.3rem'}}/>
            <ProgressContentRow>{makeContentProductivity(progressList.totalItem,progressList.totalPlanItem,progressList.operationHour,progressList.planVasWorkTime)}</ProgressContentRow>
            <div style={{height:'0.8rem'}}/>
            <div style={{fontSize:'2rem',fontWeight:'bold'}}>
              ({makeContentProductivityPercent(progressList.totalItem,progressList.totalPlanItem,progressList.operationHour,progressList.planVasWorkTime)})
            </div>
          </ProgressContent>
          <div style={{height:'0.5rem'}}/>{/* 2024/02/27 K.Harada MOD サイズ変更 1rem => 0.5rem  */}
          <ProgressContent width={'400px'}>
            <ProgressTitle>1.0h 平均種まき数</ProgressTitle>
            <div style={{height:'0.3rem'}}/>
            <ProgressContentRow>{makeHourAverageInput()}</ProgressContentRow>
            <div style={{height:'0.8rem'}}/>
            <ProgressContentRow>（前同曜日比：{makeWeekRate()}）</ProgressContentRow>           
          </ProgressContent>
        </ContentColumn>
        <ContentColumn>
          <ProgressContent width={'400px'}>
            <ProgressTitle>工数進捗（h）</ProgressTitle>
            <div style={{height:'0.3rem'}}/>
            <ProgressContentRow>{makeContent(progressList.progressWorkTime,progressList.planWorkTime)}</ProgressContentRow>
            <div style={{height:'0.8rem'}}/>
            <ProgressContentRow>（{makePercent(progressList.progressWorkTime,progressList.planWorkTime)}）</ProgressContentRow>
          </ProgressContent>
          <div style={{height:'0.5rem'}}/>{/* 2024/02/27 K.Harada MOD サイズ変更 1rem => 0.5rem  */}
          <ProgressContent width={'400px'}>
            <ProgressTitle>直近1.0h種まき数</ProgressTitle>
            <div style={{height:'0.3rem'}}/>
            <ProgressContentRow>{makeHourInput()}</ProgressContentRow>
            <div style={{height:'0.8rem'}}/>
            <ProgressContentRow>（遅延率：{makeDelayRate()}）</ProgressContentRow>
          </ProgressContent>
        </ContentColumn>
        <ContentColumn>
          <ProgressContent height={'calc(0.5rem + 270px)'}>{/* 2024/02/27 K.Harada MOD サイズ変更 1rem+340px => 0.5rem+270px  */}
            <ProgressTitle>出勤状況</ProgressTitle>
            <ProgressStaffRow>未就業・・・{makeStaffNum(progressList.unemployedCnt)}人</ProgressStaffRow>
            <ProgressStaffRow>就業中・・・{makeStaffNum(progressList.attendanceWorkCnt)}人</ProgressStaffRow>
            <ProgressStaffRow>休憩中・・・{makeStaffNum(progressList.restCnt)}人</ProgressStaffRow>
            <ProgressStaffRow>退勤済・・・{makeStaffNum(progressList.leavingWorkCnt)}人</ProgressStaffRow>
            <div style={{height:'1rem'}}/>
            <ProgressStaffRow>有給・・・・{makeStaffNum(progressList.paidCnt)}人</ProgressStaffRow>
            <ProgressStaffRow>欠勤・・・・{makeStaffNum(progressList.absenteeismCnt)}人</ProgressStaffRow>
          </ProgressContent>
        </ContentColumn>
      </BodyContent>
    </ContentWrap>
    {progressList.length != 0 &&
      <ProgressGraphBoard 
        data={makeGraphData(progressList.totalPlanItem,progressList.totalItem)}
        percent={progressList.totalPlanItem?((progressList.totalItem/progressList.totalPlanItem*100).toFixed(1)):0.0} 
        setProgressList={setProgressList}
        serachData={searchDate}
        planItem={progressList.totalPlanItem1}
        planItem2={progressList.totalPlanItem2}
        batchNo={batchNo}
        setBatchNo={setBatchNo}
        startTime={progressList.startTime}
        endTime={progressList.endTime}
      />
    }
    <PlanDateDialog
        modalIsOpen={openDialog}
        setIsOpen={setOpenDialog}
        workplaceId={'000001'}
        searchDate={searchDate}
        ownerCd={'0'}
        baseId={'1'}
        patternCd={''}
        endTime={progressList.endTime}
        startTime={progressList.startTime}
        onChange={onChange}
      />
    </>
  )
}
ProgressPerformance.propTypes = {
  
}

export default ProgressPerformance

import React, { useEffect } from 'react'
//import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'
//import { GetApi } from '../../../Common/ApiAxios'

const Flex = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 100%;
  height: 50px;
  text-align: center;
  vertical-align: middle;
  margin-top: 5px;
  margin-bottom: 10px;
`

const Content = styled.div`
  width: 90%;
  heigt: 100px;
  background-color: #f4cccc;
  border-radius: 10px;
  border: none;
  margin-left: auto;
  margin-right: auto;

  ::-webkit-scrollbar-corner {
    display: none;
  }
`

const AlertPanel = (props) => {
    const {
      grants,
    } = props
    //const alert = useAlert();
    //const interval1Ref = useRef(null);

    // 描画時に一度だけ実行
    useEffect(() => {
    }, [])
  
    //if (!grants) return null
    return (
      <Flex>
        <Content>
        </Content>
      </Flex>
    )
  }

  AlertPanel.propTypes = {
    grants: PropTypes.object, // 権限関連
  }
  
export default AlertPanel


import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'

import Button from '../../../../Common/Atoms/NormalButton'

//import { DefaultUrl } from '../../Company'
import DepartmentMasterForm from  './DepartmentMasterForm'

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  root: {
    width: '75vw',
  },
  typography: {
    width: '70vw',
    fontSize: '18px',
    fontWeight: '700',
    color: '#ffffff',
    verticalAlign: 'middle',
    margin: '0',
    padding: '16px',
    background: '#4169E1',
  },
}));


const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
  width: calc(75vw + 10px);
  height: calc(80vh - 6rem);

  :hover {
    overflow-y: auto;
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  ::-webkit-scrollbar-track {
    border:none;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(212, 212, 212, 1);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:active {
    background-color: rgba(112, 112, 112, 1);
  }
`

const TitleWrap = styled.div`
  display: flex;
  padding: 1vw;
`

const FormWrap = styled.form`
  display: flex;
`

const ButtonsWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1vw;
`

const DepartmentModal = ({ open, onCloseFunc, canWrite, department }) => {
  const { register, handleSubmit, getValues, setValue, watch, reset, errors, control } = useForm({ validateCriteriaMode: 'all' })
  const [actionType, setActionType] = useState('POST')
  const [isDeleted, setIsDeleted] = useState(false)
  const classes = useStyles()
  const alert = useAlert()
  const dispatch = useDispatch()

  // 情報をFormに反映
  useEffect(() => {
  // 部門情報の変更検知時のみ実行
  // eslint-disable-next-line
  }, [department])

  // POST・PUT処理
  const onSubmit = (data) => {

    //const submitData = new window.FormData()
    //submitData.append('formData', new window.Blob([JSON.stringify(updateData)], { type: 'application/json' }))
    //sendPost(submitData)
  }

  return (
    <Modal
      open={open}
      onClose={onCloseFunc}
      aria-labelledby='部門情報'
      aria-describedby='部門情報ダイアログ'
      style={{display:'flex',alignItems:'center',justifyContent:'center'}}
    >
    <FormWrap onSubmit={handleSubmit(onSubmit)} className='h-dsm'>
    <div className={classes.paper}>
    　<TitleWrap>部門情報</TitleWrap>
      <ContentWrap>
      <DepartmentMasterForm
        register={register}
        errors={errors}
        isDeleted={isDeleted}
        getValues={getValues}
        control={control}
        setValue={setValue}
        reset={reset}
        department={department}
        watch={watch}
        canWrite={canWrite}
        actionType={actionType}
        setActionType={setActionType}
        setIsDeleted={setIsDeleted}
      >
      </DepartmentMasterForm>
      </ContentWrap>
      <ButtonsWrap>
        <Button name='閉じる' padding='0px' onClick={onCloseFunc} />
      </ButtonsWrap>
    </div>
    </FormWrap>
  </Modal>
  )
}

DepartmentModal.propTypes = {
    open: PropTypes.bool,
    closeFunc: PropTypes.func,
    canWrite: PropTypes.bool,
    department: PropTypes.object,
}

export default DepartmentModal

import React, { useState, useEffect,useRef } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { GetApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import { useLocation } from 'react-router-dom'

import FUNC from '../../Common/FunctionEnum'

import {BoardBaseFormRow, BoardBaseFormRowScroll} from '../Wrap/BoardBaseWrap'
import {onExportCsvFile} from '../../Common/Utilties/ExportUtility'
import Search from './Search'
import ItemPerformance from './ItemPerformance'

import * as LogisticUtility from '../../Common/Utilties/LogisticUtility'

import SideSubMenu from '../SideSubMenu'
import ProgressBoard from './ProgressBoard'

const DefaultUrl = '/api/logisticManagement'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  overflow-y: none;
  overflow-x: none;
`

const VASPerformance = (props) => {
  const { loginUserInfo } = useSelector(state => state);
  const [viewFunction,setViewFunction] = useState(false);
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC);
  const subGrants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC_DASHBOARD);
  const dispatch = useDispatch();

  const history = useLocation(); 
  const hisState = history.state
  
  const ownerCd = history.state ? history.state.ownerCd : 1
  const type = history.state ? hisState.type : 0
  const pattran = history.state&&history.state.pattrnName?history.state.pattrnName:''
  const patternCd = history.state&&history.state.patternCd?history.state.patternCd:''
  const batchNo = history.state&&history.state.batchNo?history.state.batchNo:1
  const baseId = history.state&&history.state.baseId?history.state.baseId:1
  const workplaceId = history.state&&history.state.workplaceId?history.state.workplaceId:'000001'
  const ownerName = history.state&&history.state.ownerName?history.state.ownerName:''
  
  const [orgProgressList,setOrgProgressList] = useState([]);
  const [progressList,setProgressList] = useState([]);
  const searchDateRef = useRef(null)
  const { SettingInfo } = useSelector(state => state)
  
  const [pieGraph,setPieGraph] = useState([]);
  const [barGraph,setBarGraph] = useState([]);

  const cargoIdRef = useRef();
  const itemNameRef = useRef();

  //グラフのタイプ
  const graphTypeRef = useRef();
  
  //ダイアログ
  const [open,setOpen] = useState(false)
  const [itemCd,setItemCd] = useState(null)
  const [itemName,setItemName] = useState(null)
  const onOpen=(cd,name)=>{
    setItemCd(cd)
    setItemName(name)
    setOpen(true)
  }
  const onClose=()=>{
    setOpen(false)
  }

  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }

  // 機能メニューの閉じるイベント
  const onCloseFunction = (event) => {
    setViewFunction(false);
  }

  useEffect(() => {
    if (grants && subGrants)
      document.title = grants.functionMenuName + '【' + subGrants.functionMenuName + '】 | CoreBoard'
    else if (grants)
      document.title = grants.functionMenuName + ' | CoreBoard'
    else
      document.title = 'CoreBoard'
  }, [grants, subGrants])

  // 荷主のカゴ車情報の取得
  const apiGetProcessInfo = async (isLoadSplinner) => {

    const apiUrl = DefaultUrl + '/getVasCargo';
    if (isLoadSplinner) 
      dispatch(SetLoadSpinner(true));
    var now = new Date(searchDateRef.current);
    var now1 = new Date(searchDateRef.current);
    now1.setDate(now.getDate()+1);
    const param = {
      baseId: baseId,
      workplaceId: workplaceId,
      ownerCd: ownerCd,
      targetYMD: LogisticUtility.getStringFromDate(now, 'YYYY/MM/DD'),
      targetNextYMD: LogisticUtility.getStringFromDate(now1, 'YYYY/MM/DD'),
      batchNo: batchNo ,
      patternCd: patternCd,
      blockName: '',
      blockCd: ''
    }
    if(pattran == '米惣 北陸・信州'){
      param.blockName = "'ニ','サン','ヨン'"
      param.blockCd = "'2','3','4'"
    }
    if(pattran == '米惣 中松愛'){
      param.blockName = "'イチ'"
      param.blockCd = "'1'"
    }
    console.log(param)
    const result = await GetApi(apiUrl, param);

    if (isLoadSplinner) 
      dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('工程情報取得失敗');
      setOrgProgressList([]);
      setProgressList([]);
    } else {
      //console.log(result);
      setOrgProgressList(result.data);
      var tmp = result.data;
      setProgressList(JSON.parse(JSON.stringify(tmp)));
      console.log(tmp)
    }
  }

  // 荷主のカゴ車グラフ情報の取得(日単位)
  const apiGetGraphDailyInfo = async (isLoadSplinner) => {
    if (isLoadSplinner) 
      dispatch(SetLoadSpinner(true));
    const apiUrl = DefaultUrl + '/getVasShippingCargoGraph';
    var now = new Date(searchDateRef.current);
    const param = {
      baseId: baseId,
      workplaceId: '',
      targetYMD: LogisticUtility.getStringFromDate(now, 'YYYY-MM-DD'),
      batchNo: batchNo ,
    }
    console.log(param)
    const result = await GetApi(apiUrl, param);
    if (isLoadSplinner) 
      dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('工程情報取得失敗');
      setPieGraph([])
      setBarGraph([])
    } else {
      console.log(result);
      setPieGraph(result.data.pieGraph)
      setBarGraph(result.data.barGraph)
    }
  }
  // 荷主のカゴ車グラフ情報の取得(週単位)
  const apiGetGraphWeeklyInfo = async (isLoadSplinner) => {
    if (isLoadSplinner) 
      dispatch(SetLoadSpinner(true));
    const apiUrl = DefaultUrl + '/getVasShippingCargoGraphWeekly';
    var now = new Date(searchDateRef.current);
    const param = {
      baseId: baseId,
      workplaceId: '',
      targetYMD: LogisticUtility.getStringFromDate(now, 'YYYY-MM-DD'),
      batchNo: batchNo ,
    }
    console.log(param)
    const result = await GetApi(apiUrl, param);
    if (isLoadSplinner) 
      dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('工程情報取得失敗');
      setPieGraph([])
      setBarGraph([])
    } else {
      console.log(result);
      setPieGraph(result.data.pieGraph)
      setBarGraph(result.data.barGraph)
    }
  }
  // 荷主のカゴ車グラフ情報の取得(月単位)
  const apiGetGraphMonthlyInfo = async (isLoadSplinner) => {
    if (isLoadSplinner) 
      dispatch(SetLoadSpinner(true));
    const apiUrl = DefaultUrl + '/getVasShippingCargoGraphMonthly';
    var now = new Date(searchDateRef.current);
    const param = {
      baseId: baseId,
      workplaceId: '',
      targetYMD: LogisticUtility.getStringFromDate(now, 'YYYY-MM-DD'),
      batchNo: batchNo ,
    }
    console.log(param)
    const result = await GetApi(apiUrl, param);
    if (isLoadSplinner) 
      dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('工程情報取得失敗');
      setPieGraph([])
      setBarGraph([])
    } else {
      console.log(result);
      setPieGraph(result.data.pieGraph)
      setBarGraph(result.data.barGraph)
    }
  }

  const intervalRef = useRef(null);

  // 周期初期のクリア
  const onClearInerval = async () => {
      if (intervalRef.current !== null)
        clearInterval(intervalRef.current);
      
      intervalRef.current = null;
  }

  // 荷主のカゴ車グラフ情報の取得(タイプ振り分け)
  const apiGetGraphInfo = async (isLoadSplinner) => {
    switch(graphTypeRef.current?graphTypeRef.current.value:0){
      case '1':
        console.log(1)
        apiGetGraphDailyInfo(isLoadSplinner);
        break;
      case '2':
        console.log(2)
        apiGetGraphWeeklyInfo(isLoadSplinner);
        break;
      case '3':
        console.log(3)
        apiGetGraphMonthlyInfo(isLoadSplinner);
        break;
      default:
        console.log(0)
        apiGetGraphDailyInfo(isLoadSplinner);
        break;
    }
  }

  // 描画時に一度だけ実行
  useEffect(() => {
      apiGetProcessInfo(true);
      apiGetGraphInfo(false);
      intervalRef.current = setInterval(() => {
        apiGetProcessInfo(true);
        apiGetGraphInfo(true);
      }, 1000 * 60 * 10); // 10分間隔で変更
      return () => {
        onClearInerval();
      };
  }, []);
  //グラフタイプ変更イベント
  const onChangeType = (event) => {
    apiGetGraphInfo(true);
  }

  //商品の詳細リストの開閉イベント
  const onClickDisplay = ( index ) => {
    console.log(index)
    progressList[index].display = !progressList[index].display;
    setProgressList(JSON.parse(JSON.stringify(progressList)));
  }
  //カゴ車の詳細リストの開閉イベント
  const onClickDisplayCargo = (shipIndex,cargoIndex) => {
    progressList[shipIndex].vasCargoSumInfoList[cargoIndex].display
        = !progressList[shipIndex].vasCargoSumInfoList[cargoIndex].display;
    setProgressList(JSON.parse(JSON.stringify(progressList)));
  }

  //カゴ車ID/アイテム名称絞り込み
  const onBlurCargoId = (e) => {
    var tmpList=JSON.parse(JSON.stringify(orgProgressList))
    if(itemNameRef.current && itemNameRef.current.value && cargoIdRef.current && cargoIdRef.current.value){
      var searchId = cargoIdRef.current.value
      var searchName = itemNameRef.current.value
      tmpList = 
        tmpList.filter(function(value) {
          value.vasCargoSumInfoList =
          value.vasCargoSumInfoList.filter((item)=>{
            item.vasCargoItemInfoList =
            item.vasCargoItemInfoList.filter((info)=>{
              return info.cargoId.includes(searchId) || info.itemName.includes(searchName)
            })
            return item.vasCargoItemInfoList.length;
          })
          return value.vasCargoSumInfoList.length;
      })
    }
    else if(cargoIdRef.current && cargoIdRef.current.value){
      var searchId = cargoIdRef.current.value
      tmpList = 
        tmpList.filter(function(value) {
          value.vasCargoSumInfoList =
          value.vasCargoSumInfoList.filter((item)=>{
            item.vasCargoItemInfoList =
            item.vasCargoItemInfoList.filter((info)=>{
              return info.cargoId.includes(searchId)
            })
            return item.vasCargoItemInfoList.length;
          })
          return value.vasCargoSumInfoList.length;
      })
    }
    else if(itemNameRef.current && itemNameRef.current.value){
      var searchName = itemNameRef.current.value
      tmpList = 
        tmpList.filter(function(value) {
          value.vasCargoSumInfoList =
          value.vasCargoSumInfoList.filter((item)=>{
            item.vasCargoItemInfoList =
            item.vasCargoItemInfoList.filter((info)=>{
              return info.itemName.includes(searchName)
            })
            return item.vasCargoItemInfoList.length;
          })
          return value.vasCargoSumInfoList.length;
      })
    }
    setProgressList(JSON.parse(JSON.stringify(tmpList)));
  }
  const onClear=()=>{
    cargoIdRef.current.value = ''
    itemNameRef.current.value = ''
    onBlurCargoId()
  }
  const [viewSubSearch,setViewSubSearch] = useState(false)
  // 検索のオプションボタンのクリックイベント
  const onClickOptionBtn = (event) => {
    setViewSubSearch(!viewSubSearch);
  }
  
  const onExport=(workplaceName)=>{
    let exportResult = makeCSV()
    let fileName = LogisticUtility.getStringFromDate(new Date(searchDateRef.current), 'YYYYMMDD')+"_"+workplaceName+"_"+ownerName+"_"
    if(pattran){
      fileName += pattran+"_";
    }
    fileName += batchNo+"便_カゴ車実績データ";
    onExportCsvFile(exportResult,fileName);
  }
  function makeCSV() 
  {
    let Header = ''
    let header =[
      '店舗コード',
      '店舗名',
      '総アイテム数',
      '総数量',
      'カゴ車台数',
      'サンテナ数'
      ]
    header.map((head)=>{
      //console.log(head)
      Header+=head
      Header+=','
    })
    let result = Header + '\r\n'
    var tmpList=JSON.parse(JSON.stringify(orgProgressList))
    tmpList.map(function(value) {
      var lineResult =
        value.shippingCode + ',' +
        value.shippingName + ',' +
        value.totalItemCount + ',' +
        value.totalItem + ',' +
        value.cargoCount + ',' +
        value.suntenorCount + ',' 
  
      lineResult = lineResult.replaceAll('null','')
      result += lineResult
      
      result += '\r\n'
    })

    return result
  }

  if (!searchDateRef.current) {
      if(hisState && hisState.searchDate){
        searchDateRef.current = LogisticUtility.getStringFromDate(new Date(hisState.searchDate), 'YYYY-MM-DD')
      }else{
        searchDateRef.current = LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD')
      }   
  }
  return (
    <>
      <Search
        onClickFunction={onClickFunction}
        onCloseFunction={onCloseFunction}
        viewFunction={viewFunction}
        viewSubSearch={viewSubSearch}
        searchDateRef={searchDateRef}
        ownerCd={ownerCd}
        pattran={pattran}
        itemNameRef={itemNameRef}
        workplaceId={workplaceId}
        ownerName={ownerName}
        onBlurCargoId={(e)=>onBlurCargoId(e)}
        onClear={(e)=>onClear(e)}
        cargoIdRef={cargoIdRef}
        onClickOption={()=>onClickOptionBtn()}
        batchNo={batchNo}
        pageType={type}
        onExport={(workplaceName)=>onExport(workplaceName)}
        graphTypeRef={graphTypeRef}
        onChangeType={(e)=>onChangeType(e)}
      />
      <BoardBaseFormRow>
        <SideSubMenu height={'84vh'} planExisit={true}/>
        <BoardBaseFormRowScroll>
          <ContentWrap>
            <ProgressBoard 
              progressList={pieGraph}
              barGraph={barGraph}
            />
            <ItemPerformance 
              searchDate={searchDateRef.current} 
              progressList={progressList}
              onClick={(index)=>onClickDisplay(index)}
              onClickCargo={(index1,index2)=>onClickDisplayCargo(index1,index2)}
            />
          </ContentWrap>
        </BoardBaseFormRowScroll>
      </BoardBaseFormRow>
    </>
  )
}

export default VASPerformance

import React, { useEffect,useState } from 'react'
//import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'

//import { GetApi } from '../../../Common/ApiAxios'
import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'
//import { toHaveAttribute } from '@testing-library/jest-dom'

//const DefaultUrl = '/api/logisticManagement'
import MaterialAlertItem from './PocTableItem'
import MaterialAlertHeader from './PocTableHeader'
import MaterialAlertFooter from './PocTableFooter'
const DefaultUrl = '/api/logisticManagement'

const PopupImg = styled.img`
  cursor: pointer;
  width: 28px;
  height: 28px;

  margin: auto 15px;
`
const CardWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: ${props => props.width ? props.width : '100%'};
  height: 100%;

  border: none;

  color: #000;

  margin-bottom: 10px;
  
`

const HeaderContent = styled.div`
  display: flex; /* 横並び */
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  text-align:left;
  vertical-align:middle;
`

const Title = styled.span`
  font-size: ${props => props.fontSize ? props.fontSize : '2.2vw'};
`

const BodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  margin-top: 10px;
`

export const ListUl = styled.ul`
  width: ${props => props.width ? props.width : '100%'};
  height: ${props => props.height ? props.height : '400px'};
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    border:none;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(112, 112, 112);
    border-radius: 10px;
  }
`

export const ListLi = styled.li`
  width: ${props => props.width ? props.width : 'calc(100%)'};
  display: flex; /* 横並び */
  flex-direction: row;
  background: ${props => props.selected ? '#048b63' : '#fff'};
  color: ${props => props.selected ? '#fff' : '#333'};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  .listId{
    margin-right:1vw;
  }
  :nth-child(even){
    background: ${props => props.selected ? '#048b63' : '#eee'};
    color: ${props => props.selected ? '#fff' : '#333'};
  }
  :hover {
    background: #ccc;
    color: #333;
  }
`


// ヘッダー部のカード
const CardTotal = (props) => {
  const {
    grants,
    totalData,
    type,
  } = props
  //const alert = useAlert();
  //const interval1Ref = useRef(null);
  const [tmpList,setTmpList] = useState([]);
  const [page,setPage] = useState(0);
  const onMakePage=(pageNum)=>{
    setTmpList(totalData.slice(pageNum*16,pageNum*16+16))
  }
  // 描画時に一度だけ実行
  useEffect(() => {
    onMakePage(0)
  }, [])

  const onClickNext=()=>{
    if(page*16+16<totalData.length){
      setPage(page+1)
      onMakePage(page+1)
    }
  } 
  const onClickBack=()=>{
    if(page!=0){
      setPage(page-1)
      onMakePage(page-1)
    }
  }

  if (!totalData) return null
  return (
    <CardWrap width={'100%'}>
      <BodyContent>
      <MaterialAlertHeader/>
      <ListUl width={'calc(100% - 10px)'} height={'660px'}>
        {tmpList.map((item,index) => (
          <ListLi key={totalData} style={{cursor: 'default'}}>
            <MaterialAlertItem data={item} type={type}/>
          </ListLi> 
        ))}
      </ListUl>
      {tmpList.length!=0 && 
        <MaterialAlertFooter page={page} dataLength={totalData.length}
         onClickNext={onClickNext} onClickBack={onClickBack}
         />
      }
      </BodyContent>
    </CardWrap>
  )
}

CardTotal.propTypes = {
  grants: PropTypes.object, // 権限関連
  totalData: PropTypes.object, // 集計情報
  type: PropTypes.number, // 0:全体進捗率 1:終了予定時刻 2:全体稼働率 3:事故発生件数 4:投資可能額
}
  
export default CardTotal

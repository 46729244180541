import React,{useEffect} from 'react'
import { Switch, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
//import PropTypes from 'prop-types'
//import Tooltip from '@material-ui/core/Tooltip'

import FUNC from '../Common/FunctionEnum'
import GRANT from '../Common/GrantEnum'

import Dashboard from './Dashboard/Dashboard'
import OlapAnalysis from './OlapAnalysis/OlapAnalysis'
import DataMining from './DataMining/DataMining'
import Planning from './Planning/Planning'
import PlanningDate from './PlanningDate/PlanningDate'
import CarbonEmissions from './CarbonEmissions/CarbonEmissions'
import RealTimeProgrss from './RealTimeProgrss/RealTimeProgrss'
import SorterPerformance from './SorterPerformance/SorterPerformance'
import VASPerformance from './VASPerformance/VASPerformance'
import SyouwaPerformance from './SyouwaPerformance/SyouwaPerformance'
import NarashinoPerformance from './NarashinoPerformance/NarashinoPerformance'
import VASKantouPerformance from './VASKantouPerformance/VASPerformance'
import VASSummary from './VASSummary/VASSummary'
import VASPersonal from './VASPersonal/VASPersonal'
import VASItem from './VASPerformance/VasItem/VASPerformance'
import VASStore from './VASPerformance/VasStore/VASPerformance'
import VASCargo from './VASCargo/VASPerformance'
import VASRealTimeProgress from './VASRealTimeProgress/VASRealTimeProgress'
import CainzWeekly from './CainzWeekly/CainzWeekly'

const Container = styled.div`
  .AppLogistic {
    flex-direction: column;
    background-repeat: no-repeat;
    background-color: #FFF;
    height: 100%;
    width: 100%;
    overflow-y: none;
    overflow-y: none;
  }
`

const Logistic = () => {
  const { loginUserInfo } = useSelector(state => state);
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC);
  const isCainz = GRANT.CAINZ === loginUserInfo.grantNameId;
  const isAFS = GRANT.AFS === loginUserInfo.grantNameId;

  useEffect(() => {
    //console.log(loginUserInfo.menuList);
    if (grants)
      document.title = grants.functionMenuName + ' | Core Board';
    else
      document.title = 'Core Board';
  }, [grants])

  return (
    <Container>
      <div className='AppLogistic' id='AppLogistic'>
        <div className='mainLogistic'>
          <Switch>
            <Route path='/logistic/dashboard' component={Dashboard} />
            <Route path='/logistic/olapAnalysis' component={OlapAnalysis} />
            <Route path='/logistic/dataMining' component={DataMining} />
            <Route path='/logistic/planning' component={Planning} />
            <Route path='/logistic/carbonEmissions' component={CarbonEmissions} />
            <Route path='/logistic/realTimeProgrss' component={RealTimeProgrss} />
            <Route path='/logistic/sorterPerformance' component={SorterPerformance} />
            {!isAFS &&
              <Route path='/logistic/vasPerformance' component={VASPerformance} />
            }
            <Route path='/logistic/syouwaPerformance' component={SyouwaPerformance} />
            <Route path='/logistic/vasKantouPerformance' component={VASKantouPerformance} />
            <Route path='/logistic/narashinoPerformance' component={NarashinoPerformance} />
            <Route path='/logistic/vasSummary' component={VASSummary} />
            <Route path='/logistic/vasPersonal' component={VASPersonal} />
            <Route path='/logistic/vasItem' component={VASItem} />
            <Route path='/logistic/vasStore' component={VASStore} />
            <Route path='/logistic/vasCargo' component={VASCargo} />
            <Route path='/logistic/planningDate' component={PlanningDate} />
            <Route path='/logistic/vasRealTimeProgress' component={VASRealTimeProgress} />
            <Route path='/logistic/weekly' component={CainzWeekly} />
            {/* <Route path='/logistic/syouwaPerformance' component={SorterPerformance} /> */}
            {!isAFS &&
              <Route exact component={SorterPerformance} />
            }
            {isAFS && 
              <Route exact component={VASPerformance} />
            }
          </Switch>
        </div>
      </div>
    </Container>
  )
}

export default Logistic

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
//import { useAlert } from 'react-alert'
//import FUNC from '../../Common/FunctionEnum'
//import { useForm } from 'react-hook-form'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import styled from 'styled-components'
//import ToolTips from '@material-ui/core/Tooltip'

import { GetApi } from '../../Common/ApiAxios'
import * as LogisticUtility from '../../Common/Utilties/LogisticUtility'
//import {onExportCsvFile} from '../../Common/Utilties/ExportUtility'

//import IconDownloadImg from '../../../image/download-icon.png'

import ProgressListHeader from './Molcules/ProgressListHeader'
import ProgressHeaderTitle from './Molcules/ProgressHeaderTitle'
import MediaDataSummary from './Atoms/MediaDataSummary'

import MediaDataSummaryScore from './Atoms/MediaDataSummaryScore'
import MediaDataSummaryAveHour from './Atoms/MediaDataSummaryAveHour'

const DefaultUrl = '/api/logisticManagement'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  float: flex;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;

  margin-top:25px;
  margin-bottom:25px;
`

const ContentBody = styled.div`
  width: 90%;
  heigt: 100px;
  margin-left: auto;
  margin-right: auto;
`
export const ListUl = styled.ul`
  width: ${props => props.width ? props.width : '100%'};
  height: ${props => props.height ? props.height : '400px'};
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    border:none;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(112, 112, 112);
    border-radius: 10px;
  }
`

export const ListLi = styled.li`
  width: ${props => props.width ? props.width : 'calc(100%-18px)'};
  text-indent: 10px;
  background: ${props => props.selected ? '#048b63' : '#fff'};
  color: ${props => props.selected ? '#fff' : '#333'};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  .listId{
    margin-right:1vw;
  }
  :nth-child(even){
    background: ${props => props.selected ? '#048b63' : '#eee'};
    color: ${props => props.selected ? '#fff' : '#333'};
  }
  :hover {
    background: #ccc;
    color: #333;
  }
`
//const Header = '集計月,現場ID,現場名,工程ID,工程名,人数,作業時間（秒）,作業時間（時）'
const OperationBoard = (props) => {
  const {
    targetYMD,setTargetYMD,workplaceId,baseId
  } = props
  //const { loginUserInfo } = useSelector(state => state);

  const dispatch = useDispatch();
  //const alert = useAlert();
  const [progressList,setProgressList] = useState([]);
  const [summary,setSummary] = useState([]);

  const [boforeProgressList,setBeforeProgressList] = useState(['米惣リテール','米惣 北陸・信州','米惣 中松愛','MV静岡','MV東海（総量出荷）']);

  //const [targetYMD,setTargetYMD] = useState(LogisticUtility.getStringFromDate(new Date(), 'YYYY/MM/DD'));
  //const [targetYM,setTargetYM] = useState(LogisticUtility.getStringFromDate(new Date(), 'YYYY年MM月度'));
  
  // 工程情報の取得
  const apiGetProgressInfo = async (add) => {
    console.log(targetYMD)
    dispatch(SetLoadSpinner(true));
    var targetDt = new Date(targetYMD)
    targetDt.setDate(targetDt.getDate()+add)
    targetDt = LogisticUtility.getStringFromDate(targetDt, 'YYYY/MM/DD')
    const apiUrl = DefaultUrl + '/getVasSummary';
    const param = {
      baseId: baseId,
      workplaceId: workplaceId,
      targetDate: targetDt
    }
    //console.log(param);
    const result = await GetApi(apiUrl, param);

    dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('週情報取得失敗');
      setProgressList([]);
      setBeforeProgressList([]);
    } else {
      //console.log(result);
      var resultTmp = new Array();
      result.data.map((item)=>{
        if(item.ownerCd != 0){
          resultTmp.push(item);
        }else{
          setProgressList(item);
        }
      })
      setSummary(resultTmp);
      setTargetYMD(targetDt);
    }
  }

  useEffect(() => {
    apiGetProgressInfo(0);
  }, [])

  const onClick =(add)=>{
    apiGetProgressInfo(add);
  }

  if (progressList.length== 0 || boforeProgressList==null) return <></>;
  return (
    <ContentWrap>
      <ContentBody>
        <ProgressListHeader targetYMD={targetYMD} onClickNext={()=>onClick(7)} onClickBack={()=>onClick(-7)} />
        <ListUl width={'1710px'} height={'413px'}>
          <ListLi key={'item_'+0} style={{cursor: 'default'}} width={'1700px'} >
            <MediaDataSummary title={'VAS利用者'} index={0} unit={'人'} data={progressList.vasShippingInfo}  targetYMD={targetYMD} dataName={'attendanceWorkCnt'}/>
          </ListLi>
          <ListLi key={'item_'+1} style={{cursor: 'default'}} width={'1700px'} >
            {/* <MediaDataProgress1 title={'VAS操作時間'} index={0} data={progressList.vasShippingInfo} targetYMD={targetYMD}/> */}
            <MediaDataSummary title={'VAS操作時間'} index={0} unit={'ｈ'} data={progressList.vasShippingInfo}  targetYMD={targetYMD} dataName={'operationHour'}/>
          </ListLi>
          <ListLi key={'item_'+2} style={{cursor: 'default'}} width={'1700px'} >
            <MediaDataSummary title={'一人当り平均操作時間'} index={0} unit={'ｈ'} data={progressList.vasShippingInfo} targetYMD={targetYMD} dataName={'aveOperationHour'}/>
          </ListLi>
          {summary.map((item,index) => (
          <ListLi key={item.ownerCd +'_'+item.patternCd} style={{cursor: 'default'}} width={'1700px'} >
            {/* <ProgressHeaderTitle title={'荷主：'+item.ownerCd+'('+item.ownerName+')  パターン：'+item.patternName}/> */}
            <ProgressHeaderTitle title={'荷主：'+item.ownerName+'　パターン：'+item.patternName.replace('米飯','米惣')}/>
            <MediaDataSummaryScore title={'達成率'} index={index} color='orange' unit={'％'} data={item.vasShippingInfo} targetYMD={targetYMD} dataName={'totalItem'} dataName2={'totalPlanItem'}/>
            <MediaDataSummary title={'予定物量'} index={index} color='orange' unit={'個'} data={item.vasShippingInfo} targetYMD={targetYMD} dataName={'totalPlanItem'}/>
            <MediaDataSummary title={'実績物量'} index={index} color='orange' unit={'個'} data={item.vasShippingInfo} targetYMD={targetYMD} dataName={'totalItem'}/>
            <MediaDataSummaryAveHour title={'1時間当り平均実績物量'} index={index} color='orange' unit={'個'} data={item.vasShippingInfo} targetYMD={targetYMD} subData={progressList.vasShippingInfo}/>
          </ListLi>
        ))}
        </ListUl>
      </ContentBody>
    </ContentWrap>
  )
}

export default OperationBoard

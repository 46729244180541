import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import { GetApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import FUNC from '../../Common/FunctionEnum'

import {BoardBaseFormRow, BoardBaseFormRowScroll} from '../Wrap/BoardBaseWrap'

import Search from './Search'
import SideSubMenu from '../SideSubMenu'

import IndustryCarbonEmissionBoard2 from './Molcules/IndustryCarbonEmissionBoard2' 
import IndustryCarbonEmissionBoard3 from './Molcules/IndustryCarbonEmissionBoard3' 

import StorePerformance from './Molcules/StorePerformance' 
import ProgressPerformance from './Molcules/ProgressPerformance' 

import * as LogisticUtility from '../../Common/Utilties/LogisticUtility'

const DefaultUrl = '/api/logisticManagement'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  overflow-y: none;
  overflow-x: none;
`

const NarashinoPerformance = (props) => {
  const { loginUserInfo } = useSelector(state => state);
  const [viewFunction,setViewFunction] = useState(false);
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC);
  const subGrants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC_DASHBOARD);
  const searchDateRef = useRef()// 検索欄の日付
      
  const dispatch = useDispatch();
  const alert = useAlert();
  const [shippingList,setShippingList] = useState();
  const [productivityList,setProductivityList] = useState();
  const [summray,setSummray] = useState();
  
  const intervalRef = useRef(null);
  const ShippingIntervalRef = useRef(null);
  const [windowType,setWindowType]=useState(0);

  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }

  // 機能メニューの閉じるイベント
  const onCloseFunction = (event) => {
    setViewFunction(false);
  }

  useEffect(() => {
    if (grants && subGrants)
      document.title = grants.functionMenuName + '【' + subGrants.functionMenuName + '】 | CoreBoard'
    else if (grants)
      document.title = grants.functionMenuName + ' | CoreBoard'
    else
      document.title = 'CoreBoard'
  }, [grants, subGrants])
  
  // 周期初期のクリア
  const onClearInerval = async () => {
    if (intervalRef.current !== null)
      clearInterval(intervalRef.current);
    if (ShippingIntervalRef.current !== null)
      clearInterval(ShippingIntervalRef.current);
    intervalRef.current = null;
    ShippingIntervalRef.current = null;
  }

  const onChange=(date)=>{
    searchDateRef.current = date
    apiGetSummrayShippingReal();
    apiGetAreaShippingReal();
    apiGetProductivityReal();
  }


  // 進捗集計情報の取得
  const apiGetSummrayShippingReal = async (isLoadSplinner) => {
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getNarashinoSummrayShipping';
    
    const data = {
      targetYMD: LogisticUtility.getStringFromDate(new Date(searchDateRef.current), 'YYYY-MM-DD'),
    }
    const result = await GetApi(apiUrl, data);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      //alert.error('条件の該当データがありません(進捗設定情報の取得)');
    } else {
      setSummray(result.data[0]);
    }
  }
  // 進捗情報の取得
  const apiGetAreaShippingReal = async (isLoadSplinner) => {
    //console.log('call getActivityProgressManagement');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getNarashinoAreaShipping';
    
    const data = {
      targetYMD: LogisticUtility.getStringFromDate(new Date(searchDateRef.current), 'YYYY-MM-DD'),
    }
    const result = await GetApi(apiUrl, data);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      //alert.error('条件の該当データがありません(進捗設定情報の取得)');
    } else {
      //console.log(result.data);
      var dataList = result.data
      setShippingList(dataList)
    }
  }
  // 生産性情報の取得
  const apiGetProductivityReal = async (isLoadSplinner) => {
    //console.log('call getActivityProgressManagement');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getNarashinoProductivityShipping';
    
    const data = {
      workplaceId: '000011',
      targetYMD: LogisticUtility.getStringFromDate(new Date(searchDateRef.current), 'YYYY-MM-DD'),
    }
    const result = await GetApi(apiUrl, data);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      //alert.error('条件の該当データがありません(進捗設定情報の取得)');
    } else {
      //console.log(result.data);
      if(result.data.length == 7)setProductivityList(result.data);
      else setProductivityList(undefined)
    }
  }
  // 描画時に一度だけ実行
  useEffect(() => {
    apiGetSummrayShippingReal();
    apiGetAreaShippingReal();
    apiGetProductivityReal();
    ShippingIntervalRef.current = setInterval(() => {
      apiGetSummrayShippingReal();
      apiGetAreaShippingReal();
      apiGetProductivityReal();
    }, 1000 * 60 * 5); // 5分間隔で変更

    intervalRef.current = setInterval(() => {
      setWindowType((windowType)=>{
          switch(windowType) {
            case 0: // 全体進捗
              return 1;
            case 1: // シュート進捗
              return 2;
            case 2: // 店舗進捗
              return 0;
          }
        }
      )
      //console.log(windowType)
    }, 1000 * 30); // 30秒間隔で変更
  
    return () => {
      onClearInerval();
    };
  }, [])

  if (!searchDateRef.current) {
      searchDateRef.current = LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD')
  }
  
  return (
    <>
      <Search
        onClickFunction={onClickFunction}
        onCloseFunction={onCloseFunction}
        viewFunction={viewFunction}
        searchDateRef={searchDateRef}
        onChange={(date)=>onChange(date)}
      />
      <BoardBaseFormRow>
        <SideSubMenu height={'84vh'} planExisit={true}/>
        <BoardBaseFormRowScroll>
          <ContentWrap>
            <IndustryCarbonEmissionBoard2 grants={grants} searchWorkplaceId={'000256'} searchDate={searchDateRef.current}/> 
            <IndustryCarbonEmissionBoard3 grants={grants} searchWorkplaceId={'000256'} searchDate={searchDateRef.current}/>
            <ProgressPerformance dataList={productivityList}/>
            <StorePerformance summray={summray} shippingList={shippingList}/>
          </ContentWrap>
        </BoardBaseFormRowScroll>
      </BoardBaseFormRow>
    </>
  )
}

export default NarashinoPerformance

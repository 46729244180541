import React, { useState, useEffect, useRef } from 'react'
//import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'
//import { GetApi } from '../../../Common/ApiAxios'

//import ProgressLampImg from '../../../../image/progress-lamp.png'
import ProgressLampYellowImg from '../../../../image/progress-lamp-yellow.png'
import ProgressLampRedImg from '../../../../image/progress-lamp-red.png'
import DirectImg from '../../../../image/PageNext.png'
import DirectAdvanceImg from '../../../../image/direct-advance.png'

const Wrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: none;

  color: #000;
`

const HeaderContent = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 100%;
  height: 40%;
  text-align:center;
  vertical-align:middle;
`

const BodyContent = styled.div`
display: flex; /* 横並び */
  height: 40%;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
`

const FooterContent = styled.div`
display: flex; /* 横並び */
  height: 20%;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
`

function getAlertLamp(setting) {
  if (setting === undefined || setting === null)
    return null;
  switch(setting.alertType) {
    case 1: // 注意
      return ProgressLampYellowImg;
    case 2: // 警告
      return ProgressLampRedImg;
    default:
      return null;
  }

  return null;
}


const ProgressBox = (props) => {
  const {
    grants,
    settingInfo,
  } = props
  //const alert = useAlert();
  const intervalRef = useRef(null);
  const initalState = 0;
  const [count, setCount] = useState(initalState);
  const counterRef = useRef(initalState);

  // 進捗の更新
  const updatePrgCount = (count) => {
    if (count === 6)
      count = 0;
    
    setCount(count);
    counterRef.current = count;
  }

  // 周期初期のクリア
  const onClearInerval = async () => {
    if (intervalRef.current !== null)
      clearInterval(intervalRef.current);
    
    intervalRef.current = null;
  }

  // 描画時に一度だけ実行
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      updatePrgCount(counterRef.current + 1);
    }, 1000 * 2); // 2秒間隔で変更
    
    return () => {
      onClearInerval();
    };
  }, [])

  //if (!settingInfo) return null
  //console.log(settingInfo);
  return (
    <Wrap>
      <HeaderContent>
        {settingInfo.alertType !== 0 &&
          <img src={getAlertLamp(settingInfo)} style={{ margin:'auto', height:'64px' }} />
        }
      </HeaderContent>
      <BodyContent>
        <div style={{ margin:'auto' }}>
          <img alt='進捗' src={count > 0 ? DirectAdvanceImg : DirectImg} style={{ margin:'auto', height:'64px' }} />
          <img alt='進捗' src={count > 1 ? DirectAdvanceImg : DirectImg} style={{ margin:'auto', height:'64px' }} />
          <img alt='進捗' src={count > 2 ? DirectAdvanceImg : DirectImg} style={{ margin:'auto', height:'64px' }} />
          <img alt='進捗' src={count > 3 ? DirectAdvanceImg : DirectImg} style={{ margin:'auto', height:'64px' }} />
          <img alt='進捗' src={count > 4 ? DirectAdvanceImg : DirectImg} style={{ margin:'auto', height:'64px' }} />
        </div>
      </BodyContent>
      <FooterContent>

      </FooterContent>
    </Wrap>
  )
}

ProgressBox.propTypes = {
  grants: PropTypes.object, // 権限関連
  settingInfo: PropTypes.object,
}

export default ProgressBox

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import styled from 'styled-components'
//import { useAlert } from 'react-alert'
//import { useDispatch } from 'react-redux'

import DateCalendar from '../../../Common/Atoms/Calendar'
import CloseIcon from '../../../../image/close_icon.png'

/* 縦並 */
const Wrap = styled.div`
  display:flex;
  flex-flow: column;
`

/* 検索項目 */
const SearchItem = styled.div`
  display: flex; /* 横並び */
  flex-flow: row nowrap;
  text-align: left;
  vertical-align: middle;
`

const SearchOptionModal  = (props) => {
  const {
    grants,
    modalIsOpen,
    setIsOpen,
    changeCalendar,
    searchDate,
  } = props
  //const dispatch = useDispatch();
  //const alert = useAlert();

  // モーダル
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      //opacity: 0.8,
      padding: '10px',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    },
    overlay: {
      //background: 'rgba(0, 0, 0, 0.7)',
      zIndex: 10
    }
  }

  //ダイアログ開いた時の処理
  function afterOpenModal () {
    //();
  }

  function closeModal () {
    setIsOpen(false);
  }

  useEffect(() => {
  }, [modalIsOpen])

  // 描画時に一度だけ実行
  useEffect(() => {

  }, [])

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel='Create Zone Modal'
      ariaHideApp={false}
    >
      <div style={{ width:'300px', height:'400px', marginLeft:'1rem' }}>
        <Wrap>
          <div style={{ height:'20px', marginLeft:'auto', marginRight:'10px', marginTop:'10px', cursor: 'pointer'}}>
            <img alt='閉じる' className='close' src={CloseIcon} title={'ダイアログを閉じる'} width="12px" height="12px" onClick={closeModal} />
          </div>
          <SearchItem style={{marginTop:'1rem'}}>
            <label style={{marginTop:'auto', marginBottom:'auto'}}>日付</label>
            <DateCalendar changeCalendar={changeCalendar} dataValue={searchDate} />
        </SearchItem>
        </Wrap>
      </div>
    </Modal>
  )
}

SearchOptionModal.propTypes = {
  grants: PropTypes.object, // 権限関連
  modalIsOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  changeCalendar: PropTypes.func,
  searchDate: PropTypes.string,
}

export default SearchOptionModal

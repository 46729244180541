// ヘルスケアで利用する共通のライブラリ

// 日時の文字列を返す。
export function getStringFromDate(date, format) {
  if (date === null || date === undefined)
    return '';

  //console.log(date);

  let year_str = date.getFullYear();
  //月だけ+1すること
  let month_str = 1 + date.getMonth();
  let day_str = date.getDate();
  let hour_str = date.getHours();
  let minute_str = date.getMinutes();
  let second_str = date.getSeconds();

  month_str = ('0' + month_str).slice(-2);
  day_str = ('0' + day_str).slice(-2);
  hour_str = ('0' + hour_str).slice(-2);
  minute_str = ('0' + minute_str).slice(-2);
  second_str = ('0' + second_str).slice(-2);

  let format_str = 'YYYY/MM/DD hh:mm:ss';
  if (format != null)
    format_str = format;

  format_str = format_str.replace(/YYYY/g, year_str);
  format_str = format_str.replace(/MM/g, month_str);
  format_str = format_str.replace(/DD/g, day_str);
  format_str = format_str.replace(/hh/g, hour_str);
  format_str = format_str.replace(/mm/g, minute_str);
  format_str = format_str.replace(/ss/g, second_str);

  return format_str;
};
  
// 日付の文字色
export function getDateColor(date) {
  let nomalColor = '';
  if (date === null) 
    return nomalColor;

  if (date.publicHoliday === 1)
    return 'red';
  if ('日' === date.week)
    return 'red';
  if ('土' === date.week)
    return 'blue';

  return nomalColor;
}
  
// カンマ数字のテキストを取得
export function getCommaText(value) {
  if (value === undefined || value === null)
    return '';

  let s = String(value).split('.');
  let ret = String(s[0]).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
  if (s.length > 1) {
    ret += '.' + s[1];
  }
  return ret;
}

// ヘルスケアのアラート時のカラー
export function getAlertColor(flag) {
 if (flag === null)
   return '';

 if (flag) {
   return '#ff0000';
 }

 return '';
}

import React, { useState, useEffect } from 'react'
//import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { GetApi } from '../../../Common/ApiAxios'

import CardTotal from '../Atoms/CardTotal'

const DefaultUrl = '/api/logisticManagement'

const Flex = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 100%;
  height: 180px;
  text-align: center;
  vertical-align: middle;
  margin-top: 10px;
  margin-bottom: 10px;
`

const Content = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 90%;
  height: 140px;
  margin-left: auto;
  margin-right: auto;

  ::-webkit-scrollbar-corner {
    display: none;
  }
`

// ヘッダー部のカード
const OperationHeader = (props) => {
  const {
    grants,
    dates,
  } = props
  const [quantity,setQuantity] = useState(null);
  //const alert = useAlert();
  //const interval1Ref = useRef(null);

  // 物量計画の週情報の取得
  const apiGetQuantityWeekly = async (targetYMD) => {
    //console.log('call apiGetQuantityWeekly')
    //dispatch(SetLoadSpinner(true))
    if (targetYMD === null) {
      setQuantity(null);
      return;
    }

    const apiUrl = DefaultUrl + '/getQuantityWeeklySummaryCard';
    const param = {
      workplaceId: '000011',
      targetYMD: targetYMD,
    }

    const result = await GetApi(apiUrl, param);

    //dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('物量計画の週情報取得失敗');
      setQuantity(null);
    } else {
      //console.log(result);
      console.log(result.data)
      if(result.data.length==2){
        result.data[0].beforeOutsourcingProgressValue = 0
        if(result.data[0].outsourcingProgressValue && result.data[1].outsourcingProgressValue ){
          result.data[0].beforeOutsourcingProgressValue = result.data[0].outsourcingProgressValue/result.data[1].outsourcingProgressValue * 100
        }
        setQuantity(result.data[0]);
      }
     
    }
  }
  
  // 描画時に一度だけ実行
  useEffect(() => {
    if (dates === null || dates === undefined || dates.count === 0) {
      apiGetQuantityWeekly(null);
    } else {
      apiGetQuantityWeekly(dates[0].dateValue);
    }
  }, [dates])

  if (!quantity || quantity == null) return null
  return (
    <Flex>
      <Content>
        <CardTotal totalData={quantity} type={0}/>
        <CardTotal totalData={quantity} type={1}/>
        <CardTotal totalData={quantity} type={2}/>
        <CardTotal totalData={quantity} type={3}/>
      </Content>
    </Flex>
  )
}

OperationHeader.propTypes = {
  grants: PropTypes.object, // 権限関連
  dates: PropTypes.array, // 日付情報(1週分)
}
  
export default OperationHeader


import React, { useEffect } from 'react'
//import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'

//import { GetApi } from '../../../Common/ApiAxios'
import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'
import { FaBookReader } from 'react-icons/fa'
//import { toHaveAttribute } from '@testing-library/jest-dom'

//const DefaultUrl = '/api/logisticManagement'

const CardWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 300px;
  height: 100%;
  background-color: ${props => props.bColor === 3 ? '#f00' : props.bColor === 4 ? '#ff9900' :'#1155cc'};
  border-radius: 10px;
  border: none;

  margin-bottom: 5px;
  color: #fff;
`

const HeaderContent = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 100%;
  height: 40%;
  text-align:center;
  vertical-align:middle;
`

const Title = styled.span`
  width: 100%;
  font-size: 2.0vw;
  font-weight: bold;
  margin:auto;
`

const BodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 60%;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
`

// 通常項目
const NormalItem = styled.span`
  font-size: 1.7vw;
  font-weight: bold;
`

// 小項目
const SmallItem = styled.span`
  font-size: 1.25vw;
  font-weight: bold;
`

function getTitle(type) {
  var title = '';
  switch(type) {
    case 0:
      title = '当日労務コスト';
      break;
    case 1:
      title = '当日平均時給';
      break;
    case 2:
      title = '当月労務コスト';
      break;
    case 3:
      title = '当月平均時給';
      break;
    default:
      title = '';
      break;
  }

  return title;
}

function getValue(totalData, type, wageType) {
  var text = '';
  if(totalData.length >2)return
  totalData=totalData[1]
  switch(type) {
    case 0:
    case 2:
      if (totalData !== null)
        text = LogisticUtility.getCommaText((totalData.costProgressValue/1000.0).toFixed(0)) + " (千円)";
      break;
    case 1:
    case 3:
      if (totalData !== null){
        if(!wageType){
          text = LogisticUtility.getCommaText((totalData.agreementStaffAvgWage).toFixed(0)) + " (円/h)";
        }else{
          text = LogisticUtility.getCommaText((totalData.aveWage).toFixed(0)) + " (円/h)";
        }   
      }     
      break;
    default:
      break;
  }

  return text;
}

function getBasis(totalData, type, wageType) {
  var text = '';
  if(totalData.length >2)return
  switch(type) {
    case 0:
      text = '(前日比: ';
      if (totalData[0].costProgressValue !== 0)
        text += LogisticUtility.getCommaText((totalData[1].costProgressValue/totalData[0].costProgressValue*100).toFixed(2)) + "%)";
      else
        text += '0.00%)'
      break;
    case 2:
      text = '(前月比: ';
      if (totalData[0].costProgressValue !== 0)
        text += LogisticUtility.getCommaText((totalData[1].costProgressValue/totalData[0].costProgressValue*100).toFixed(2)) + "%)";
      else
        text += '0.00%)'
      break;
    case 1:
      text = '(前日比: ';
      if(!wageType && totalData[0].agreementStaffAvgWage !== 0){
        text += LogisticUtility.getCommaText((totalData[1].agreementStaffAvgWage/totalData[0].agreementStaffAvgWage*100).toFixed(2)) + "%)";
      }else if(wageType && totalData[0].aveWage !== 0){
        text += LogisticUtility.getCommaText((totalData[1].aveWage/totalData[0].aveWage*100).toFixed(2)) + "%)";
      }   
      else
        text += '0.00%)'
      break;
    case 3:
      text = '(前月比: ';
      if(!wageType && totalData[0].agreementStaffAvgWage !== 0){
        text += LogisticUtility.getCommaText((totalData[1].agreementStaffAvgWage/totalData[0].agreementStaffAvgWage*100).toFixed(2)) + "%)";
      }else if(wageType && totalData[0].aveWage !== 0){
        text += LogisticUtility.getCommaText((totalData[1].aveWage/totalData[0].aveWage*100).toFixed(2)) + "%)";
      }   
      else
        text += '0.00%)'
      break;
    default:
      text = '';
      break;
  }

  return text;
}

// ヘッダー部のカード
const CardTotal = (props) => {
  const {
    grants,
    totalData,
    type,
    wageType
  } = props
  //const alert = useAlert();
  //const interval1Ref = useRef(null);

  // 描画時に一度だけ実行
  useEffect(() => {
  }, [])

  //if (!grants || !totalData) return null
  return (
    <CardWrap bColor={1}>
      <HeaderContent>
          <Title>{getTitle(type)}</Title>
      </HeaderContent>
      <BodyContent>
          {(type === 0 ||type === 2) &&
          <>
              <NormalItem>{getValue(totalData, type, wageType)}</NormalItem>
              <SmallItem style={{paddingTop:'0.5rem'}}>{getBasis(totalData,type,wageType)}</SmallItem>
          </>
          }
          {(type === 1 ||type === 3)&&
          <>
              <NormalItem>{getValue(totalData, type,wageType)}</NormalItem>
              <SmallItem style={{paddingTop:'0.5rem'}}>{getBasis(totalData,type,wageType)}</SmallItem>
          </>
          }
      </BodyContent>
    </CardWrap>
  )
}

CardTotal.propTypes = {
  grants: PropTypes.object, // 権限関連
  totalData: PropTypes.object, // 集計情報
  type: PropTypes.number, // 0:当日労務コスト 1:当日平均時給 2:当月労務コスト 3:当月平均時給
  wageType: PropTypes.number, // 平均時給タイプ　0:契約から算出,1:労務費から算出
}
  
export default CardTotal

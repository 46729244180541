import {useState,useEffect} from 'react'

export default function Marker(options){
  const [marker, setMarker] = useState();

  useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
      marker.addListener("click", () => {
        //console.log(options);
        //console.log('call click');
        options.onClick(options.id);
      });
    }
  }, [marker, options]);

  return null;
};

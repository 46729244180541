import React, { useEffect } from 'react'
//import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'

//import { GetApi } from '../../../Common/ApiAxios'
import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'
//import { toHaveAttribute } from '@testing-library/jest-dom'

//const DefaultUrl = '/api/logisticManagement'

const CardWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${props => props.bColor === 3 ? '#f00' : props.bColor === 4 ? '#ff9900' :'#1155cc'};
  border-radius: 10px;
  border: none;

  color: #fff;
`

const HeaderContent = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 100%;
  height: 40%;
  text-align:center;
  vertical-align:middle;
`

const Title = styled.span`
  width: 100%;
  font-size: 2.0vw;
  font-weight: bold;
  margin:auto;
`

const BodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 60%;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
`

// 通常項目
const NormalItem = styled.span`
  font-size: 1.7vw;
  font-weight: bold;
`

// 小項目
const SmallItem = styled.span`
  font-size: 1.25vw;
  font-weight: bold;
`

function getTitle(type) {
  var title = '';
  switch(type) {
    case 0:
      title = '全体進捗率';
      break;
    case 1:
      title = '終了予定時刻';
      break;
    case 2:
      title = '全体稼働率';
      break;
    case 3:
      title = '事故発生件数';
      break;
    case 4:
      title = '投資可能額';
      break;
    default:
      title = '';
      break;
  }

  return title;
}

function getValue(totalData, type) {
  var text = '';
  switch(type) {
    case 0:
      if (totalData !== null)
        text = LogisticUtility.getCommaText(totalData.progress.toFixed(1)) + " %";
      break;
    case 1:
      if (totalData !== null)
        text = LogisticUtility.getStringFromDate(new Date(totalData.planEndAt), 'hh:mm');
      break;
    case 2:
      text = '000.00' + " %";
      break;
    case 3:
      text = '000' + " 件";
      break;
    default:
      text = '';
      break;
  }

  return text;
}

function getRemainingTime(totalData) {
  var text = '';
  
  //console.log('call getRemainingTime');
  //console.log(totalData);
  if (totalData !== null) {
    let date1 = new Date(LogisticUtility.getStringFromDate(new Date(), 'YYYY/MM/DD hh:mm:00'));
    let date2 = new Date(totalData.planEndAt);
    
    let diff = date2.getTime() - date1.getTime();
    let totalMinues = Math.floor(diff / (60*1000));
    //console.log(totalMinues);
    if (totalMinues > 0) {
      let hour = Math.floor(totalMinues / 60);
      let minues = totalMinues - (hour * 60);

      text = ('00' + hour).slice(-2) + ":" + ('00' + minues).slice(-2)
    }
    else {
      text = '00:00';
    }
  }

  return text;
}

// ヘッダー部のカード
const CardTotal = (props) => {
  const {
    grants,
    totalData,
    type,
  } = props
  //const alert = useAlert();
  //const interval1Ref = useRef(null);

  // 描画時に一度だけ実行
  useEffect(() => {
  }, [])

  //if (!grants || !totalData) return null
  return (
    <CardWrap bColor={type}>
      <HeaderContent>
          <Title>{getTitle(type)}</Title>
      </HeaderContent>
      <BodyContent>
          {type === 0 &&
          <>
              <NormalItem>{getValue(totalData, type)}</NormalItem>
              <SmallItem style={{paddingTop:'0.5rem'}}>(実績物量／予定物量)</SmallItem>
          </>
          }
          {type === 1 &&
          <>
              <NormalItem>{getValue(totalData, type)}</NormalItem>
              <SmallItem style={{paddingTop:'0.5rem'}}>残り時間 {getRemainingTime(totalData)}</SmallItem>
          </>
          }
          {type === 2 &&
          <>
              <NormalItem>{getValue(totalData, type)}</NormalItem>
              <SmallItem style={{paddingTop:'0.5rem'}}>(生産性 000.00 %)</SmallItem>
          </>
          }
          {type === 3 &&
          <>
              <NormalItem>{getValue(totalData, type)}</NormalItem>
              <SmallItem style={{paddingTop:'0.5rem'}}>(前日比 000.00 %)</SmallItem>
          </>
          }
          {type === 4 &&
          <>
              <SmallItem>3年:0 (千円) / 年</SmallItem>
              <SmallItem>5年:0(千円) / 年</SmallItem>
              <SmallItem>7年:0(千円) / 年</SmallItem>
          </>
          }
      </BodyContent>
    </CardWrap>
  )
}

CardTotal.propTypes = {
  grants: PropTypes.object, // 権限関連
  totalData: PropTypes.object, // 集計情報
  type: PropTypes.number, // 0:全体進捗率 1:終了予定時刻 2:全体稼働率 3:事故発生件数 4:投資可能額
}
  
export default CardTotal

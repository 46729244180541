import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'

const MediaWrap = styled.div`
  width: 520px;
  display: flex; /* 横並び */
  flex-wrap: wrap;
  margin: 4px;
  box-shadow: 0 0 3px 2px ${props => props.selected ? 'rgba(0,128,0,0.8)' : 'rgba(0,0,0,0.4)'};

  color: #000;
  font-size: 16px;

  text-align: center;
  align-items: center;
`
const MediaItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 40px;
  border-right: 2px dashed #fff;
`

const Flex  = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  margin: auto;
`

const TextWrap = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
` 

// 項目名
function getTitle(data) {
  let text = '';

  text = data.activityName;

  return text;
}

function getUnit(data) {
  let text = '';

  switch(data.activityId) {
    case '00001':
      text = 'MWh';
      break;
    case '00002':
      text = 'KL';
      break;
    case '00003':
      text = 'KL';
      break;
    case '00004':
      text = 'KL';
      break;
    case '00005':
      text = 'KL';
      break;
    case '00005':
      text = 'KL';
      break;
    case '00006':
      text = 'KL';
      break;
    case '00007':
      text = '千m3';
      break;
    default:
      text = '';
      break;
  }

  return text;
}

const MediaDataActivityEmissions = (props) => {
  const {
    grants,
    data,
  } = props

  // 初期値
  useEffect(() => {
  })

  //if (!data) return null;
  //console.log(data);
  return (
    <MediaWrap>
      <MediaItem>
        <TextWrap style={{width:'200px', textAlign:'left'}}>
          <div style={{marginLeft:'1rem', fontWeight:'bold'}}>{getTitle(data)}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
          <Flex style={{width:'150px', textAlign:'right'}}>
            <div style={{width:'90px', marginRight:'0.25rem'}}>
              {LogisticUtility.getCommaText(data.activityNum.toFixed(2))}
            </div>
            <div style={{width:'2rem', textAling:'right', marginRight:'1rem', fontSize:'10px'}}>
              {getUnit(data)}
            </div>
          </Flex>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'150px', textAlign:'right'}}>
          <div style={{marginRight:'1rem'}}>{LogisticUtility.getCommaText(data.emissions.toFixed(4))}</div>
        </TextWrap>
      </MediaItem>
    </MediaWrap>
  )
}

MediaDataActivityEmissions.propTypes = {
  grants: PropTypes.object, // 権限関連
  data: PropTypes.object, // CO2排出情報
}

export default MediaDataActivityEmissions

import React from 'react'
import styled from 'styled-components'

const TableWrap = styled.table`
  border-collapse:separate;
  border-spacing: 1px;

  thead, tbody {
    display: ${props => props.visible ? 'block' : 'none'};
  }

  thead {
    width: calc(56.5rem + 14px)
  }

  tbody {
    overflow-x: hidden;
    overflow-y: hidden;
  }

  th, td {
    white-space: nowrap;
    border-radius: 5px;
    border: 1px solid #DDD;
    border-collapse: collapse;
  }

  th {
    height: 2.5rem;
    background-color: #649cdf;
    text-align: center;
    vertical-align: middle;
  }

  td {
    height: 2.5rem;
    text-align: right;
    vertical-align: middle;
    font-size: 1.2rem;
    padding-right: 1rem;
  }

  tbody tr {
    :nth-child(even) {
      background: ${props => props.selected ? '#048b63' : '#eee'};
    }
    :hover {
      ${props => !props.selected ? 'background: #ccc; color: #333;' : ''}
    }
  }
`

const ProgressGraphTable = (props) => {
  const {
    dataList
  } = props

  return (
    <>
      <TableWrap visible={true}>
        <thead>
            <tr>
                <th style={{width:'2.5rem'}}></th>
                <th style={{width:'calc(18rem + 1px)'}}colSpan={2}>今週</th>
                <th style={{width:'calc(18rem + 1px)'}}colSpan={2}>先週</th>
                <th style={{width:'calc(18rem + 1px)'}}colSpan={2}>先々週</th>
            </tr>
            <tr>
                <th style={{width:'2.5rem'}}>曜日</th>
                <th style={{width:'9rem',fontSize:'14px'}}>出荷支持<br/>(行)</th>
                <th style={{width:'9rem',fontSize:'14px'}}>作業時間<br/>(分)</th>
                <th style={{width:'9rem',fontSize:'14px'}}>出荷支持<br/>(行)</th>
                <th style={{width:'9rem',fontSize:'14px'}}>作業時間<br/>(分)</th>
                <th style={{width:'9rem',fontSize:'14px'}}>出荷支持<br/>(行)</th>
                <th style={{width:'9rem',fontSize:'14px'}}>作業時間<br/>(分)</th>
            </tr>
        </thead>
        <tbody>
        {dataList && dataList.map((entry, index) => 
          {  
              return (
                <tr>
                  <th style={{width:'2.5rem'}}>{entry.week}</th>
                  <td style={{width:'9rem'}}>{entry.thisShippingOrderCount.toLocaleString()}</td>
                  <td style={{width:'9rem'}}>{entry.thisStationWorktime.toLocaleString()}</td>
                  <td style={{width:'9rem'}}>{entry.beforeShippingOrderCount.toLocaleString()}</td>
                  <td style={{width:'9rem'}}>{entry.beforeStationWorktime.toLocaleString()}</td>
                  <td style={{width:'9rem'}}>{entry.before2ShippingOrderCount.toLocaleString()}</td>
                  <td style={{width:'9rem'}}>{entry.before2StationWorktime.toLocaleString()}</td>
                </tr>
              )   
          })
        }
        </tbody>
      </TableWrap>
    </>
  )
}

export default ProgressGraphTable

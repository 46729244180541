import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
//import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { GetApi } from '../../../Common/ApiAxios'
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'
import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'

//import CardTotal from '../Atoms/CardTotal'
import CardTotalVAS from '../Atoms/CardTotalVAS'
import AveProductivityGraph from './AveProductivityGraph'

const DefaultUrl = '/api/logisticManagement'

const Flex = styled.div`
  display: flex; /* 横並び */
  flex-direction: row;
  width: 100%;
  height: 180px;
  text-align: center;
  vertical-align: middle;
  margin-top: 10px;
  margin-bottom: 5px;
`
const ContentRow = styled.div`
  display: flex; /* 横並び */
  flex-direction: column;
`
const Content = styled.div`
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  width: 50%;
  height: 180px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px;
  ::-webkit-scrollbar-corner {
    display: none;
  }
`
// ヘッダー部のカード
const ProgressHeader = (props) => {
  const {
    grants,
    totalDatas,
    wageType,
    searchDate
  } = props
  const dispatch = useDispatch();
  //const alert = useAlert();
  const intervalRef = useRef(null);
  //const [quantityPlan,setQuantityPlan] = useState(null);
  //const [totalProgressInfo,seTtotalProgressInfo] = useState({progress:0.0});
  //const [plaEndInfo, setPlanEndInfo] = useState({planEndAt:LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD') + " 00:00:00"});

  //const defaltValue = {agreementStaffAvgWage:0.0,aveWage:0.0,costProgressValue:0.0}
  const [beforeData,setBeforeData] = useState([]);
  const [daylyData,setDaylyData] = useState([]);

  const [processList,setProcessList] = useState([]);
  //const [processMonthList,setProcessMonthList] = useState([]);

  // 周期初期のクリア
  const onClearInerval = async () => {
    if (intervalRef.current !== null)
      clearInterval(intervalRef.current);
    
    intervalRef.current = null;
  }

  // 描画時に一度だけ実行
  useEffect(() => {
    apiGetGraph();
    apiGetProductivityToday();
    intervalRef.current = setInterval(() => {
      apiGetGraph();
      apiGetProductivityToday();
  }, 1000 * 60 *5); // 60秒間隔で変更
    return () => {
      onClearInerval();
    };
  }, [])

  // 進捗設定情報の取得
  const apiGetGraph = async (isLoadSplinner) => {
    //console.log('call apiGetGraph');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getVasProductivity';
   
    const data = {
      targetDate: LogisticUtility.getStringFromDate(new Date(searchDate), 'YYYY/MM/01')
    }
    const result = await GetApi(apiUrl, data);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
    } else {

      var targetDt = new Date();
      targetDt = new Date(targetDt.getFullYear(),targetDt.getMonth(),1,0,0,0);

      var endDt = new Date(targetDt.getFullYear(),targetDt.getMonth()+1,1,0,0,0);

      var list = new Array();

      while (targetDt < endDt) {
        var targetYMD = LogisticUtility.getStringFromDate(targetDt, 'YYYY/MM/DD');
        var list1 = result.data.filter((elem) => { return elem.timeZone === targetYMD});

        var total1 = 0;
        var total2 = 0;
        list1.map(item => {
          total1 += Number(item.totalItem);
          total2 += Number(item.progressWorkTime)
        });

        var productivity = 0;
        if(total2 != 0 && total1!= NaN && total2 != NaN){
          productivity = total1/total2
        }
        list.push({  dataValue: targetYMD,productivity:productivity });

        targetDt = new Date(targetDt.getFullYear(),targetDt.getMonth(),targetDt.getDate()+1,0,0,0);
      }
      setProcessList(list);
    }
  }

  // 平均時給情報の取得
  const apiGetProductivityToday = async (isLoadSplinner) => {
    //console.log('call apiGetProductivityToday');

    const apiUrl = DefaultUrl + '/getVasProductivityToday';
    const param = {
      targetDate: LogisticUtility.getStringFromDate(new Date(), 'YYYY/MM/DD')
    }

    const result = await GetApi(apiUrl, param);

    if (result.errorDetail) {
    } else {
      //console.log(result);
      var targetDt = new Date();
      var beforeDt = new Date(targetDt.getFullYear(),targetDt.getMonth(),targetDt.getDate()-7,0,0,0);

      var targetYMD = LogisticUtility.getStringFromDate(targetDt, 'YYYY/MM/DD');
      var beforeYMD = LogisticUtility.getStringFromDate(beforeDt, 'YYYY/MM/DD');
      var list1 = result.data.filter((elem) => { return elem.timeZone === targetYMD});
      var list2 = result.data.filter((elem) => { return elem.timeZone === beforeYMD});
      if(list1.length != 0){
        setDaylyData(list1);
      }else{
        setDaylyData([]);
      }
      if(list2.length != 0){;
        setBeforeData(list2)
      }else{
        setBeforeData([]);
      }
      //
      //
    }
  }

  //if (!grants || !totalDatas) return null
  return (
    <Flex>
      <Content>
        <ContentRow>         
          <CardTotalVAS daylyData={daylyData} beforeData={beforeData}/>      
        </ContentRow>
      </Content>
      {processList && processList.length!=0 &&
        <AveProductivityGraph processList={processList}/>
      }
    </Flex>
  )
}

ProgressHeader.propTypes = {
  grants: PropTypes.object, // 権限関連
  totalDatas: PropTypes.object, // 集計情報
  wageType: PropTypes.number, // 平均時給タイプ　0:契約から算出,1:労務費から算出
}
  
export default ProgressHeader

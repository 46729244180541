import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
//import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'

import IconCollapseImg from '../../image/Collapse.png'

const SideMenuContent = styled.div`
  display:${props => props.display || 'flex'};
  margin-top:${props => props.top || '0px'};
  flex-wrap: wrap;
  height: 85vh;
  img {
  }
  ul.mainSearch {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    position: relative;
    > li {
      display: flex;
      margin: 0 0 0 1rem;
      label {
        line-height: 32px;
        text-align: right;
        font-size: 12px;
        margin: 0 0.5rem 0 0;
    }
  }
    li.subSearch {
      display: ${props => props.visible ? 'block' : 'none' };
      position: absolute;
      font-size: 16px;
      margin: 0;
      top: 0px;
      z-index: 3;
      left: 0;
    }
  }
`

const MenuContent = styled.div`
    flex-direction: column;
    background-repeat: no-repeat;
    height: 85vh;
    width: 10vw;
    background-color: #cfe2f3;
    overflow-y: none;
`

const MenuContentRow = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1vw;
    background-repeat: no-repeat;
    width: 10vw;
    overflow-y: none;
`

const MenuContentImg = styled.div`
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    overflow-y: none;
`

const SideSubMenu = ({top}) => {
  const [viewMenu, setViewMenu] = useState(false);

  const onClick =()=>{    
    setViewMenu(!viewMenu);
  }

  const onClose =()=>{
    setViewMenu(false);
  }

  return (
    <SideMenuContent visible={viewMenu} top={top} onMouseLeave={()=>onClose()}>
      <ul className='mainSearch'>
        <img alt='開閉' src={IconCollapseImg} width="24px" height="24px" style={{cursor: 'pointer' }} onClick={()=>onClick()}/>
      <li className='subSearch'>
        <MenuContent>
            <MenuContentImg>
              <img alt='開閉' src={IconCollapseImg} width="24px" height="24px" style={{transform: 'rotate(180deg)' ,cursor: 'pointer' }} onClick={()=>onClick()}/>
            </MenuContentImg>
            <MenuContentRow>
              <Link to="/truck/currentMonthProgress"><Tooltip title={'当月進捗'}><div>{'当月進捗'}</div></Tooltip></Link>
            </MenuContentRow>
            <MenuContentRow>
              <Link to="/truck/previousMonthResults"><Tooltip title={'前月実績'}><div>{'前月実績'}</div></Tooltip></Link>
            </MenuContentRow>
            <MenuContentRow>
              <Link to="/truck/summary"><Tooltip title={'サマリ（テスト）'}><div>{'サマリ'}</div></Tooltip></Link>
            </MenuContentRow>
            <MenuContentRow>
              <Link to="/truck/healthCare"><Tooltip title={'ヘルスケア'}><div>{'ヘルスケア'}</div></Tooltip></Link>
            </MenuContentRow>
            <MenuContentRow>
              <Link to="/truck/help"><Tooltip title={'ヘルプ'}><div>{'ヘルプ'}</div></Tooltip></Link>
            </MenuContentRow>
        </MenuContent>
      </li>
    </ul>
    </SideMenuContent>
  )
}

SideSubMenu.propTypes = {
}

export default SideSubMenu

import React, { useEffect } from 'react'
//import { useSelector } from 'react-redux'
//import { useAlert } from 'react-alert'
//import FUNC from '../../Common/FunctionEnum'
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import PropTypes from 'prop-types'
//import { GetApi } from '../../Common/ApiAxios'
//import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
//import ErrorHandling from '../../Common/ErrorHandling'

import ProgressHeader from './Molcules/ProgressHeader'
import ProgressHeader1 from './Molcules/ProgressHeader1'
//const DefaultUrl = '/api/logisticManagement'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 85vh;
  text-align: center;
  vertical-align: middle;

  margin-bottom: 30px;
`

const ContentBody = styled.div`
  display: grid;

  width: 90%;
  margin-left: auto;
  margin-right: auto;
`

const ProgressBoard = (props) => {
  const {
    wageType,type,searchDate,
    monthlyData,
    daylyData,
    processList,
    processMonthList
  } = props

  //const { loginUserInfo } = useSelector(state => state);

  // 描画時に一度だけ実行
  useEffect(() => {
  }, [])

  return (
    <>
      <ContentWrap>
        <ContentBody>
          {type == 0 &&
            <>
              <ProgressHeader wageType={wageType} searchDate={searchDate}
                            monthlyData={monthlyData}
                            daylyData={daylyData}
                            processList={processList}
                            processMonthList={processMonthList}
              />  
            </>
          }
          {type == 1 &&
            <>
              <ProgressHeader1 wageType={wageType} searchDate={searchDate}/>  
            </>
          }
        </ContentBody>
      </ContentWrap>
    </>
  )
}
ProgressBoard.propTypes = {
  wageType: PropTypes.number, // 平均時給タイプ　0:契約から算出,1:労務費から算出
}

export default ProgressBoard

import React from 'react'
//import styled from 'styled-components'
import { useHistory } from "react-router-dom"
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import Tooltip from '@material-ui/core/Tooltip'

//import FUNC from '../Common/FunctionEnum'
//import { PostApi } from '../Common/ApiAxios'
import OperationTypeTab from './Molcules/OperationTypeTab'
import IconFunctionImg from '../../../image/three-reader-vertical-icon.png'
import IconTopImg from '../../../image/menu-icon3.png'
import SettingIcon from '../../../image/setting-icon.png'
import IconInquiryImg from '../../../image/inquiry-icon.png'
import IconMapImg from '../../../image/map-icon.png'
import SearchWrap ,{ FunctionContent, FunctionContentRow, FunctionIcon, FunctionName} from '../Wrap/BoardSerachWrap'
import RangeCalendar from '../../Common/Atoms/RangeCalendar'
import Select from '../../Common/Atoms/Select'
const Search = (props) => {
  const {
    addStyle,
    onClickFunction,
    onCloseFunction,
    viewSubSearch,
    viewFunction,
    searchFromDateRef,
    searchToDateRef,
    companyRef,
    companyList,
    workplaceList,
    workplaceRef,
    onClickSummaryButton,
    summaryType
  } = props
  const history = useHistory();
  const loginUserInfo = useSelector(state => state.loginUserInfo);
  //const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC);
  // 検索期間変更イベント
  const changeRangeCalendar = (fromDate, toDate) => {
    searchFromDateRef.current = fromDate;
    searchToDateRef.current = toDate;
    //onRangeDateChange(fromDate, toDate);
  }

  async function homePage () {
    history.push('/afstruck/home');
  }
  async function summaryPage () {
    history.push('/afstruck/summary');
  }
  async function mapPage () {
    history.push('/afstruck/map');
  }
  async function detailPage () {
    history.push('/afstruck/detail');
  }
  //if (!grants) return null;
  return (
    <SearchWrap addStyle={addStyle} viewSubSearch={viewSubSearch} viewFunction={viewFunction}>
      <ul className='mainSearch'>
        <li>
        <OperationTypeTab type={summaryType} onClick={onClickSummaryButton}/>
        </li>
        <li className='subSearch'>
        </li>
      </ul>
        <div style={{fontSize:'16px',margin:'8px 0 0 12px'}}>{'運送会社'}</div>
        <div style={{margin:'0 0px 0 0'}}>
        <Select selectRef={companyRef} width={'13rem'}>
              {companyList.map(item => (
                <option key={'company_'+item.id} value={item.id}>{item.name}</option>
              ))}
        </Select>
        </div>
        <div style={{fontSize:'16px',margin:'8px 0 0 0px'}}>{'対象期間'}</div>
        <div style={{margin:'0 0 0 0', display:'flex',lineHeight:'30px'}}>
          <RangeCalendar onChange={changeRangeCalendar} 
            value={{ fromDate:new Date(searchFromDateRef.current), toDate:new Date(searchToDateRef.current) }}
            isViewIcon placeholderStart={'開始日'} placeholderEnd={'終了日'}/>
        </div>
        <div style={{fontSize:'16px',margin:'8px 0 0 0px'}}>{'作業場所'}</div>
        <div style={{margin:'0 0px 0 0'}}>
        <Select selectRef={workplaceRef} width={'13rem'}>
              {workplaceList.map(item => (
                <option key={'work_'+item.id} value={item.id}>{item.name}</option>
              ))}
        </Select>
        </div>
      <div></div>
      <Tooltip title={'機能メニュー'}>
        <img alt='機能メニュー' src={IconFunctionImg} width="24px" height="24px" style={{marginLeft:'auto',marginTop: '4px', cursor: 'pointer'}} onClick={onClickFunction} />
      </Tooltip>
      <li className='mainFunction'>
        <FunctionContent onMouseLeave={onCloseFunction}>
          <FunctionContentRow style={{marginTop:'12px'}} onClick={homePage}>
            <FunctionIcon alt='ホーム' src={IconTopImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>ホーム</span></FunctionName>
          </FunctionContentRow>
          <hr></hr>
          <FunctionContentRow style={{}} onClick={summaryPage}>
            <FunctionIcon alt='サマリ' src={IconInquiryImg} />
            <FunctionName><span style={{marginLeft:'0.5rem'}}>サマリ</span></FunctionName>
          </FunctionContentRow>
          <FunctionContentRow style={{marginTop:'12px'}}>
            <FunctionIcon alt='明細' src={IconInquiryImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>明細</span></FunctionName>
          </FunctionContentRow>
          <FunctionContentRow style={{marginTop:'12px'}}>
            <FunctionIcon alt='マップ' src={IconMapImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>マップ</span></FunctionName>
          </FunctionContentRow>
          <FunctionContentRow style={{marginTop:'12px',marginBottom:'12px'}}>
            <FunctionIcon alt='設定' src={SettingIcon}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>設定</span></FunctionName>
          </FunctionContentRow>
          </FunctionContent>
      </li>
    </SearchWrap>
  )
}

Search.propTypes = {
  addStyle: PropTypes.string, // css用追加用
  onClickFunction: PropTypes.func,
  onCloseFunction: PropTypes.func,
  viewSubSearch: PropTypes.bool, // サブ検索項目を表示するか
  viewFunction: PropTypes.bool, // サブ機能を表示するか
}

export default Search

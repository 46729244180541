import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import styled from 'styled-components'
//import { useAlert } from 'react-alert'
//import { useDispatch } from 'react-redux'

import Select from '../../../Common/Atoms/Select'

//import DateCalendar from '../../../Common/Atoms/Calendar'
import MonthCalendar from '../../../Common/Atoms/MonthCalendar'
import CloseIcon from '../../../../image/close_icon.png'

/* 縦並 */
const Wrap = styled.div`
  display:flex;
  flex-flow: column;
`

/* 検索項目 */
const SearchItem = styled.div`
  display: flex; /* 横並び */
  flex-flow: row nowrap;
  text-align: left;
  vertical-align: middle;
`

const SearchOptionModal  = (props) => {
  const {
    grants,
    modalIsOpen,
    setIsOpen,
    changeCalendar,
    searchWorkplaceId,
    setSearchWorkplaceId,
    setSearchWorkplaceName,
    searchDate,
  } = props
  //const dispatch = useDispatch();
  //const alert = useAlert();
  const [workplaceList, setWorkplaceList] = useState([
    {id:'000001', name:'【ロジ関東】南関東_AFS_流通'}
    , {id:'000002', name:'【ロジ関東】南関東清掃'}
    , {id:'000003', name:'【ロジ関東】UNIQLO仙台'}
    , {id:'000004', name:'【ロジ関東】メニコン北ロジ'}
    , {id:'000126', name:'【ロジ関東】東急ストア'}
    , {id:'000256', name:'【ロジ関東】ランドポート習志野'}
    , {id:'000284', name:'【ロジ関東】昭和冷蔵'}
    , {id:'000006', name:'【ロジ中部】尾西_AFS_流通'}
    , {id:'000007', name:'【ロジ中部】尾西_AFS_清掃'}
    , {id:'000008', name:'【ロジ中部】笠寺_Menicon'}
    , {id:'000010', name:'【ロジ中部】ヤマナカ'}
    , {id:'000011', name:'【ロジ関西】神戸_Cainz'}
    , {id:'000012', name:'【ロジ関西】神戸_Otome'}
    , {id:'000013', name:'【ロジ関西】兵庫姫路_afs_清掃'}
    , {id:'000016', name:'【ロジ関西】カインズ福岡'}
    , {id:'000127', name:'【ロジ関西】尾家産業'}
    , {id:'000173', name:'【ロジ関西】フェリシモアネックス'}
    , {id:'000251', name:'【ロジ関西】アイエイチロジ'}
    //, {id:'000282', name:'【ロジ関西】万代_彩都'}
    //, {id:'000283', name:'万代_渋川'}
    , {id:'000179', name:'運送事業'}
    , {id:'000000', name:'全社'}
  ]);
  const workplaceRef = useRef(searchWorkplaceId);

  // モーダル
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      //opacity: 0.8,
      padding: '10px',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    },
    overlay: {
      //background: 'rgba(0, 0, 0, 0.7)',
      zIndex: 10
    }
  }

  //ダイアログ開いた時の処理
  function afterOpenModal () {
    //();
    workplaceRef.current.value = searchWorkplaceId;
  }

  function closeModal () {
    setIsOpen(false);
  }

  // 現場コンボボックスの変更イベント
  const onChangeWorkplace = (e) => {
    //console.log(workplaceRef.current.value);

    let id = workplaceRef.current.value;
    let workplace = workplaceList.find(element => element.id === id);
    if (workplace) {
      setSearchWorkplaceName(workplace.name);
      setSearchWorkplaceId(workplaceRef.current.value);
    }

    // ダイアログを閉じる
    //setIsOpen(false);
  }

  useEffect(() => {
  }, [modalIsOpen])

  // 描画時に一度だけ実行
  useEffect(() => {
    //workplaceRef.current.value = searchWorkplaceId

    let workplace = workplaceList.find(element => element.id === searchWorkplaceId);
    if (workplace) {
      setSearchWorkplaceName(workplace.name);
    }
  }, [])

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel='Create Zone Modal'
      ariaHideApp={false}
    >
      <div style={{ width:'500px', height:'420px', marginLeft:'1rem' }}>
        <Wrap>
          <div style={{ height:'20px', marginLeft:'auto', marginRight:'10px', marginTop:'10px', cursor: 'pointer'}}>
            <img alt='閉じる' className='close' src={CloseIcon} title={'ダイアログを閉じる'} width="12px" height="12px" onClick={closeModal} />
          </div>
          <SearchItem style={{marginTop:'1rem'}}>
            <label style={{marginTop:'auto', marginBottom:'auto'}}>現場</label>
            <Select selectRef={workplaceRef} onChange={onChangeWorkplace} width={'19rem'}>
            {workplaceList.map(item => (
              <option key={'work_'+item.id} value={item.id}>{item.name}</option>
            ))}
            </Select>
          </SearchItem>
          <SearchItem style={{marginTop:'1rem'}}>
            <label style={{marginTop:'auto', marginBottom:'auto'}}>日付</label>
            <MonthCalendar changeCalendar={changeCalendar} dataValue={searchDate} />
          </SearchItem>
        </Wrap>
      </div>
    </Modal>
  )
}

SearchOptionModal.propTypes = {
  grants: PropTypes.object, // 権限関連
  modalIsOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  changeCalendar: PropTypes.func,
  searchWorkplaceId: PropTypes.object,
  setSearchWorkplaceId: PropTypes.func,
  setSearchWorkplaceName: PropTypes.func,
  searchDate: PropTypes.string, // 検索欄で選択した日付
}

export default SearchOptionModal

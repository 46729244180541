import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
//import { AiFillPlusCircle, AiFillMinusCircle } from 'react-icons/ai'
//import { useAlert } from 'react-alert'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'

//import { PostApi } from '../../Common/ApiAxios'
import FUNC from '../../Common/FunctionEnum'
import MonthCalendar from '../../Common/Atoms/MonthCalendar'
import Button from '../../Common/Atoms/NormalButton'

import IconSearchImg from '../../../image/search-icon.png'
import IconCollapseImg from '../../../image/Collapse.png'
import IconFunctionImg from '../../../image/three-reader-vertical-icon.png'
import IconTopImg from '../../../image/menu-icon3.png'
//import IconCloseImg from '../../../image/close_icon.png'

import SearchWrap ,{ SubSearchContent, SubSearchContentRow, FunctionContent,FunctionContentRow, FunctionIcon, FunctionName} from '../Wrap/BoardSerachWrap'
//import DashBoardRateModal from './Molcules/PalDashboardDialog/PalDashboardRateDialog'
import DashBoardWorkplaceModal from './Molcules/PalDashboardDialog/PalDashboardWorkplaceDialog'

const Search = (props) => {
  const dispatch = useDispatch()
  const [shiftDate, setShiftDate] = useState()
  //const [renderFlg, setRenderFlg] = useState(false)
  const {
    onClick, onClickOption, onKeyDown, shiftDateRef, firstSearch, viewSubSearch, grants,
    postList,setPostId,postId,setDepartmentId,departmentId,
    postType,setPostType,
    addStyle, relationStatus,
    viewFunction,onClickFunction,onCloseFunction,cargoWorkRateData,laborRateData,
    setCargoWorkRateList,setLaborRateList,
    cargoWorkRateList,laborRateList,
    setWorkplaceList,workplaceAllList,workplaceList
  } = props
  const [rows,setRows] = useState(<></>);
  //const alert = useAlert();
  const history = useHistory();
  const loginUserInfo = useSelector(state => state.loginUserInfo);
  const subGrants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC_DASHBOARD);

  const [labelName, setLabelName] = useState('会社');
  const [postText, setPostText] = useState('全て');
  const [departmentName, setDepartmentName] = useState('');
  //const [canOpenDialog, setCanOpenDialog] = useState(false);
  //const [canOpenLaborDialog, setCanOpenLaborDialog] = useState(false);
  const [canOpenWorkplaceDialog, setCanOpenWorkplaceDialog] = useState(false);
  const [selectValue,setSelectValue] = useState(null);
  /*
  //ダイアログ閉じるボタン処理
  const handleClose = () => {
    setCanOpenDialog(false);
  }

  //選択ボタン処理
  const onClickDepartmentSelect =  () => {
    setCanOpenDialog(true)
  }
  */
  // 初期値
  useEffect(() => {
  // 必要なデータが更新された場合のみ実行
  // eslint-disable-next-line
  }, [loginUserInfo, dispatch])

  // レンダー終了判定

  //会社リスト更新時処理
  useEffect(() => {
    //console.log(postList)
    postList.unshift({index:-1,name:'全て'});
    var postRows = 
      postList.map(post=>{
        return <><option value={post.index} >{post.name}</option></> 
      });
    setRows(postRows);

  // 必要なデータが更新された場合のみ実行
  // eslint-disable-next-line
  }, [postList])

  //デフォルト設定
  const setDefault=()=>{
    setSelectValue(-1)
    setPostId(-1)
    setPostText('全て')
    setDepartmentName('')
    setDepartmentId(null)
  }
  //会社顧客変更処理
  useEffect(() => {
    if(postType === 0){
      setLabelName('会社')
    }else{
      setLabelName('顧客')
    }
    setDefault()
// 必要なデータが更新された場合のみ実行
// eslint-disable-next-line
}, [postType])

  const changeCalendar = (date) => {
    shiftDateRef.current = date;
    setShiftDate(date)
    onClick()
  }

  /*
  // 会社・顧客のコンボボックス選択変更イベント
  const onChange = (event) => {
    event.persist()
    setPostId(event.target.value)
    setPostText(event.target.options[event.target.selectedIndex].text)
    setSelectValue(event.target.value)
  }

  // 会社、顧客ラベルのクリックイベント
  const onClickLabel = ()=>{
    //console.log(postType)
    if(postType==0){
      setPostType(1)
    }else{
      setPostType(0)
    }
  }

  //リセットボタン処理
  const onClickResetBtn=()=>{
    setPostType(0)
    setDefault()
  }

  //ダイアログ閉じるボタン処理
  const handleLaborClose = () => {
    setCanOpenLaborDialog(false);
  }

  //選択ボタン処理
  const onClickLabor=  () => {
    setCanOpenLaborDialog(true)
  }
  */
  //ダイアログ閉じるボタン処理
  const handleWorkplaceClose = () => {
    setCanOpenWorkplaceDialog(false);
  }

  //選択ボタン処理
  const onClickWorkplace=  () => {
    setCanOpenWorkplaceDialog(true)
  }
  useEffect(() => {
  // 必要なデータが更新された場合のみ実行
  // eslint-disable-next-line
  }, [loginUserInfo, relationStatus])

  async function topPage () {
    history.push('/top');
  }

  return (
    <SearchWrap addStyle={addStyle} viewSubSearch={viewSubSearch} viewFunction={viewFunction}>
      <h3>{grants.functionMenuName}</h3>
      <img alt='階層' src={IconCollapseImg} width="24px" height="24px" style={{marginTop:'4px'}}/>
      <div className='subTitleItem' style={{fontSize:'16px',margin:'8px 0 0 12px'}}>{subGrants.functionMenuName}</div>
      <ul className='mainSearch'>
        <li>
          <MonthCalendar changeCalendar={changeCalendar} dataValue={shiftDateRef.current} isViewIcon placeholder={'対象年月'}/>
          <Tooltip title={'検索オプション'}>
            <img alt='検索オプション' src={IconSearchImg} width="24px" height="24px" style={{ marginTop: '4px',cursor: 'pointer' }} onClick={onClickOption} />
          </Tooltip>
        </li>
        <li className='subSearch'>
          <SubSearchContent>
            <SubSearchContentRow>
              <label style={{width:'12rem'}}>表示現場</label>
              <Button type='button' width='4rem' margin='0 0 0 4px' name='選択' padding='0px' onClick={onClickWorkplace} />
            </SubSearchContentRow>
            <SubSearchContentRow style={{textAlign:'right', marginLeft:'auto', marginRight:'1.5rem' }}>
              <Button type='button' margin='0 0 0 16px' name='検索' padding='0px' onClick={onClick} />
            </SubSearchContentRow>
          </SubSearchContent>
        </li>
      </ul>
      <div></div>
      <Tooltip title={'機能メニュー'}>
        <img alt='機能メニュー' src={IconFunctionImg} width="24px" height="24px" style={{marginTop: '4px',marginLeft: 'auto', cursor: 'pointer'}} onClick={onClickFunction} />
      </Tooltip>
      <li className='mainFunction'>
        <FunctionContent onMouseLeave={onCloseFunction}>
          <FunctionContentRow onClick={topPage} style={{marginTop:'12px',marginBottom:'12px'}}>
            <FunctionIcon alt='トップページ' src={IconTopImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>トップページ</span></FunctionName>
          </FunctionContentRow>
        </FunctionContent>
      </li>
        <DashBoardWorkplaceModal
          open={canOpenWorkplaceDialog}
          onCloseFunc={handleWorkplaceClose}
          ColumnList={workplaceAllList}
          setRateList={setWorkplaceList}
          rateList={workplaceList}
        >
        </DashBoardWorkplaceModal>
    </SearchWrap>
  )
}

Search.propTypes = {
  title: PropTypes.string, // タイトル
  onClick: PropTypes.func, // 検索処理
  onClickOption: PropTypes.func, // サブ検索項目の表示処理
  onKeyDown: PropTypes.func, // キーダウン処理
  shiftDateRef: PropTypes.object, // 検索項目関連
  firstSearch: PropTypes.bool, // 最初に検索処理をするか
  viewSubSearch: PropTypes.bool, // サブ検索項目を表示するか
  grants: PropTypes.object, // 権限関連
  addStyle: PropTypes.string, // css用追加用
  postList: PropTypes.string, // 会社・顧客リスト
}

export default Search

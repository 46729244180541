import React,{useState} from 'react'
import { useHistory } from "react-router-dom"
import styled from 'styled-components'

import IconCollapseImg from '../../image/Collapse.png'
import IconTopImg from '../../image/menu-icon3.png'
import IconCloseImg from '../../image/close_icon.png'

const SideMenuContent = styled.div`
  display:${props => props.display || 'flex'};
  margin-top:${props => props.top || '0px'};
  flex-wrap: wrap;
  height: 85vh;
  img {
  }
  ul.mainSearch {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    position: relative;
    > li {
      display: flex;
      margin: 0 0 0 1rem;
      label {
        line-height: 32px;
        text-align: right;
        font-size: 12px;
        margin: 0 0.5rem 0 0;
    }
  }
    li.subSearch {
      display: ${props => props.visible ? 'block' : 'none' };
      position: absolute;
      font-size: 20px;
      margin: 0;
      top: 0px;
      z-index: 3;
      left: 0;
    }
  }
`

const MenuContent = styled.div`
    flex-direction: column;
    background-repeat: no-repeat;
    height: 85vh;
    width: 15vw;
    background-color: #cfe2f3;
    overflow-y: none;
`

const MenuContentImg = styled.div`
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    overflow-y: none;
`

const MenuContentRow = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  text-align: left;
  margin-top: 4px;
  margin-bottom: 4px;
  
  cursor: pointer;
`

const MenuIcon = styled.img`
  width: 18px;
  height: 18px;

  margin-left: 12px;
`

const MenuName = styled.div`
  margin-left: 1rem;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
`

const SideSubMenu = ({top}) => {
  const history = useHistory();
  const [viewMenu, setViewMenu] = useState(false);

  const onClick =()=>{    
    setViewMenu(!viewMenu)
  }

  async function topPage () {
    history.push('/top');
  }

  async function licensePage () {
    //history.push('/top');
  }

  return (
    <SideMenuContent visible={viewMenu} top={top}>
      <ul className='mainSearch'>
        <img alt='開閉' src={IconCollapseImg} width="24px" height="24px" style={{cursor: 'pointer' }} onClick={()=>onClick()}/>
      <li className='subSearch'>
        <MenuContent>
          <MenuContentImg>
            <img alt='開閉' src={IconCollapseImg} width="24px" height="24px" style={{transform: 'rotate(180deg)' ,cursor: 'pointer' }} onClick={()=>onClick()}/>
          </MenuContentImg>
          <MenuContentRow onClick={topPage} style={{marginTop:'12px', marginBottom:'12px'}}>
            <MenuIcon alt='TOP' src={IconTopImg}/>
            <MenuName><span style={{marginLeft:'0.5rem'}}>TOP</span></MenuName>
          </MenuContentRow>
          <MenuContentRow onClick={licensePage} style={{marginBottom:'12px'}}>
            <MenuIcon alt='運用環境情報' src={IconCloseImg}/>
            <MenuName><span style={{marginLeft:'0.5rem'}}>運用環境情報</span></MenuName>
          </MenuContentRow>
          <MenuContentRow onClick={licensePage} style={{marginBottom:'12px'}}>
            <MenuIcon alt='利用者' src={IconCloseImg}/>
            <MenuName><span style={{marginLeft:'0.5rem'}}>利用者</span></MenuName>
          </MenuContentRow>
          <MenuContentRow onClick={licensePage} style={{marginBottom:'12px'}}>
            <MenuIcon alt='セキュリティ' src={IconCloseImg}/>
            <MenuName><span style={{marginLeft:'0.5rem'}}>セキュリティ</span></MenuName>
          </MenuContentRow>
          <MenuContentRow onClick={licensePage} style={{marginBottom:'12px'}}>
            <MenuIcon alt='ログイン' src={IconCloseImg}/>
            <MenuName><span style={{marginLeft:'0.5rem'}}>ログイン</span></MenuName>
          </MenuContentRow>
          <MenuContentRow onClick={licensePage} style={{marginBottom:'12px'}}>
            <MenuIcon alt='運用開始' src={IconCloseImg}/>
            <MenuName><span style={{marginLeft:'0.5rem'}}>運用開始</span></MenuName>
          </MenuContentRow>
          <MenuContentRow onClick={licensePage} style={{marginBottom:'12px'}}>
            <MenuIcon alt='外部サービス連携' src={IconCloseImg}/>
            <MenuName><span style={{marginLeft:'0.5rem'}}>外部サービス連携</span></MenuName>
          </MenuContentRow>
          <MenuContentRow onClick={licensePage} style={{marginBottom:'12px'}}>
            <MenuIcon alt='ライセンス' src={IconCloseImg}/>
            <MenuName><span style={{marginLeft:'0.5rem'}}>ライセンス</span></MenuName>
          </MenuContentRow>
        </MenuContent>
      </li>
    </ul>
    </SideMenuContent>
  )
}

SideSubMenu.propTypes = {
}

export default SideSubMenu

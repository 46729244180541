import React, { useEffect } from 'react'
//import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'

//import { GetApi } from '../../../Common/ApiAxios'
import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'
//import { toHaveAttribute } from '@testing-library/jest-dom'

//const DefaultUrl = '/api/logisticManagement'

const CardWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: ${props => props.width ? props.width : '100%'};
  height: 100%;
  background-color: #fff;

  border: none;

  color: #000;

  margin-bottom: 10px;
  box-shadow: 2px 2px 6px rgba(0,0,0,.4);
`

const HeaderContent = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 100%;
  height: 40%;
  text-align:center;
  vertical-align:middle;
`

const Title = styled.span`
  width: 100%;
  font-size: ${props => props.fontSize ? props.fontSize : '2.2vw'};
  margin:auto;
`

const BodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 60%;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
`

// 通常項目
const NormalItem = styled.span`
  font-size: 3vw;
`

function getTitle(type) {
  var title = '';
  switch(type) {
    case 0:
      title = '総運行時間';
      break;
    case 1:
      title = '平均ドライバ―作業時間';
      break;
    case 2:
      title = '平均ドライバ―待機時間';
      break;
    default:
      title = '';
      break;
  }

  return title;
}

function getValue(totalData, type) {
  var text = '';
  switch(type) {
    case 0:
      if (totalData !== null)
        text = LogisticUtility.getCommaText(Number(totalData).toFixed(2));
        text = <>{text} <span style={{fontSize:'70%',fontWeight:'normal'}}>{'時間'}</span></>
      break;
    case 1:
      if (totalData !== null)
        text = LogisticUtility.getCommaText(Number(totalData).toFixed(0));
        text = <>{text} <span style={{fontSize:'70%',fontWeight:'normal'}}>{'分'}</span></>
      break;
    case 2:
      if (totalData !== null)
        text = LogisticUtility.getCommaText(Number(totalData).toFixed(0));
        text = <>{text} <span style={{fontSize:'70%',fontWeight:'normal'}}>{'分'}</span></>
      break;
    default:
      text = '';
      break;
  }

  return text;
}


// ヘッダー部のカード
const CardTotal = (props) => {
  const {
    grants,
    totalData,
    type,
  } = props
  //const alert = useAlert();
  //const interval1Ref = useRef(null);

  // 描画時に一度だけ実行
  useEffect(() => {
  }, [])

  //if (!grants || !totalData) return null
  return (
    <CardWrap width={'100%'}>
      <HeaderContent>
          <Title fontSize={'1.5vw'}>{getTitle(type)}</Title>
      </HeaderContent>
      <BodyContent>
          {type === 0 &&
          <>
              <NormalItem>{getValue(228, type)}</NormalItem>
          </>
          }
          {type === 1 &&
          <>
              <NormalItem>{getValue(58.5, type)}</NormalItem>
          </>
          }
          {type === 2 &&
          <>
              <NormalItem>{getValue(34.53, type)}</NormalItem>
          </>
          }
      </BodyContent>
    </CardWrap>
  )
}

CardTotal.propTypes = {
  grants: PropTypes.object, // 権限関連
  totalData: PropTypes.object, // 集計情報
  type: PropTypes.number, // 0:全体進捗率 1:終了予定時刻 2:全体稼働率 3:事故発生件数 4:投資可能額
}
  
export default CardTotal

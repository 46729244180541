import React, { useEffect } from 'react'
//import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useHistory } from "react-router-dom"
//import { GetApi } from '../../../Common/ApiAxios'
import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'
//import { toHaveAttribute } from '@testing-library/jest-dom'
import Tooltip from '@material-ui/core/Tooltip'
//const DefaultUrl = '/api/logisticManagement'
import popupIcon from '../../../../image/popup-icon.png'
const PopupImg = styled.img`
  cursor: pointer;
  width: 28px;
  height: 28px;

  margin 10px 10px 0 0;
`
const CardWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: ${props => props.width ? props.width : '100%'};
  height: 100%;
  background-color: #fff;

  border: none;

  color: #000;

  margin-bottom: 10px;
  box-shadow: 2px 2px 6px rgba(0,0,0,.4);
`

const HeaderContent = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  height: 40%;
  text-align:center;
  vertical-align:middle;
`

const Title = styled.span`
  width: 80%;
  font-size: ${props => props.fontSize ? props.fontSize : '2.2vw'};
  margin:auto;
  margin-left:55px;
`

const BodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 60%;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
`

// 通常項目
const NormalItem = styled.span`
  font-size: 4vw;
`


function getTitle(type) {
  var title = '';
  switch(type) {
    case 0:
      title = '運行便数';
      break;
    case 1:
      title = '平均積載率';
      break;
    case 2:
      title = 'Co2排出量';
      break;
    default:
      title = '';
      break;
  }

  return title;
}

function getValue(totalData, type) {
  var text = '0';
  switch(type) {
    case 0:
      if (totalData && totalData !== null)
        text = LogisticUtility.getCommaText(Number(totalData).toFixed(0));
      break;
    case 1:
      if (totalData && totalData !== null)
        text = LogisticUtility.getCommaText(Number(totalData).toFixed(2));
        text = <>{text} <span style={{fontSize:'70%',fontWeight:'normal'}}>{'%'}</span></>
      break;
    case 2:
      if (totalData && totalData !== null)
        text = LogisticUtility.getCommaText(Number(totalData).toFixed(2));
      text = <>{text} <span style={{fontSize:'70%',fontWeight:'normal'}}>{'t-Co2'}</span></>
      break;
    default:
      text = '';
      break;
  }

  return text;
}


// ヘッダー部のカード
const CardTotal = (props) => {
  const {
    grants,
    totalData,
    type,
    quantity
  } = props
  //const alert = useAlert();
  //const interval1Ref = useRef(null);
  const history = useHistory();
  function onClick() {
    var state = {type:0}
    switch(type) {
      case 0:
        var state = {type:1}
        break;
      case 1:
        var state = {type:2}
        break;
    }
    history.push({state:state,pathname:'/afstruck/detail'});
  }

  
  // 描画時に一度だけ実行
  useEffect(() => {
  }, [])

  //if (!grants || !totalData) return null
  return (
    <CardWrap width={'100%'}>
      <HeaderContent>
          <Title fontSize={'2vw'}>{getTitle(type)}</Title>
          {type !== 2 &&
          <Tooltip title='明細表示'>
            <PopupImg src={popupIcon} onClick={()=>onClick()}/>
          </Tooltip>
          }
      </HeaderContent>
      <BodyContent>
          {type === 0 &&
          <>
              <NormalItem>{getValue(quantity.truckCount, type)}</NormalItem>
          </>
          }
          {type === 1 &&
          <>
              <NormalItem>{getValue(58.5, type)}</NormalItem>
          </>
          }
          {type === 2 &&
          <>
              <NormalItem>{getValue(quantity.emissions, type)}</NormalItem>
          </>
          }
      </BodyContent>
    </CardWrap>
  )
}

CardTotal.propTypes = {
  grants: PropTypes.object, // 権限関連
  totalData: PropTypes.object, // 集計情報
  type: PropTypes.number, // 0:全体進捗率 1:終了予定時刻 2:全体稼働率 3:事故発生件数 4:投資可能額
}
  
export default CardTotal

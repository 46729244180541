import React, { useState, useEffect, useRef } from 'react'
//import { useDispatch } from 'react-redux'
//import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import * as LogisticUtility from '../../../../Common/Utilties/LogisticUtility'

import {XAxis, YAxis, Tooltip, Bar, ComposedChart } from "recharts"

//const DefaultUrl = '/api/logisticManagement'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 880px;
  height: 85px;
  text-align: center;
  vertical-align: middle;
`

const ContentBody = styled.div`
`

const GraphContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
`

const ProgressGraphBoard = (props) => {
  //const { loginUserInfo } = useSelector(state => state);
  const {
    data,planItem
  } = props

  const [processList,setProcessList] = useState([]);
 // const [prgressGraph,setPrgressGraph] = useState();
  const [maxQuantity, setMaxQuantity] = useState(0);

  // 進捗設定情報の取得
  const apiGetVasShippingGraph= async () => {
      //console.log(result.data);
      var planTotal = 0
      if(planItem){
        planTotal = Math.round(planItem/96)
      }
      //console.log(planTotal)
      //年単位の内訳（比率）算出
      {
        var targetDt = new Date(1900,1,1,0,0,0);
        var endDt = new Date(1900,1,2,0,0,0);
        var list = new Array();
        var maxValue = planTotal;

        while (targetDt < endDt) {
          var targetTimeZone = LogisticUtility.getStringFromDate(targetDt, 'hh:mm');
          var list1 = data.filter((elem) => { return elem.timeZone === targetTimeZone});

          var total1 = 0;
          var total2 = 0;
          var total3 = 0;
          list1.map(item => {
            total1 += Number(item.totalItem);
            total2 += Number(item.totalItemCount);
            total3 += Number(item.attendanceWorkCnt);
          });
          if (maxValue < total1)
            maxValue = total1;

          list.push(
            { timeZone:targetTimeZone,planQuantity:planTotal ,progressQuantity:total1.toFixed(2)
              ,totalItemCount:total2,attendanceWorkCnt:total3 }
            );

          targetDt = new Date(1900,1,1,targetDt.getHours(),targetDt.getMinutes()+15,0);
        }
        setProcessList(list);
        setMaxQuantity(Math.ceil(maxValue / 100) * 100 + 100);
      }
  }
  
  //x軸のデータ  
  const ticks = ['00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00','09:00','10:00','11:00','12:00',
                 '13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00']

  // 描画時に一度だけ実行
  useEffect(() => {
    if(!data) return;

    apiGetVasShippingGraph();
  }, [planItem])

  return (
    <>
      <ContentWrap>
        <ContentBody>
        <GraphContent>
          <ComposedChart 
            width={900}
            height={100}
            data={processList}
            >
              <XAxis xAxisId={1} dataKey="timeZone" ticks={ticks}/>
              <YAxis yAxisId={1} domain={[0, maxQuantity]}  tickCount={9} />
              <Tooltip />  
              <Bar type="plainline" xAxisId={1} yAxisId={1} strokeWidth={2} dataKey="progressQuantity" name="実績物量" stroke="#295D8C" />   
          </ComposedChart >
        </GraphContent>
        </ContentBody>
      </ContentWrap>
    </>
  )
}

ProgressGraphBoard.propTypes = {
  data: PropTypes.object, // 種まき情報
  planItem: PropTypes.string, // 予定物量
}

export default ProgressGraphBoard

import React, { useEffect } from 'react'
//import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'

//import { GetApi } from '../../../Common/ApiAxios'
import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'
import { FaBookReader } from 'react-icons/fa'
//import { toHaveAttribute } from '@testing-library/jest-dom'

//const DefaultUrl = '/api/logisticManagement'

const CardWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 550px;
  height: 100%;
  background-color: #649CDF;
  border-radius: 10px;
  border: none;

  margin-bottom: 5px;
  color: #000;
`

const HeaderContent = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 100%;
  height: 40%;
  text-align:center;
  vertical-align:middle;
`

const Title = styled.span`
  width: 100%;
  font-size: 2.0vw;
  font-weight: bold;
  margin:auto;
`

const BodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 60%;
  overflow: hidden;
  text-align: left;
  margin-left: 1rem;
  vertical-align: middle;
`

// 通常項目
const NormalItem = styled.span`
  font-size: 1.7vw;
  font-weight: bold;
`

// 小項目
const SmallItem = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
  white-space: pre;
`


function getValue(data, beforeData,type) {

  if(data.length == 0 || beforeData.length==0){
    return type?<>{'米飯：0個／時間（前週比：000.00%）'}</>:<>{'惣菜：0個／時間（前週比：000.00%）'}</>
  }

  var text = type?'米飯':'惣菜';
  text += '：';

  var productivityText = ''
  var percentText = ''

  switch(type) {
    case 0:
      var total1 = 0;
      var total2 = 0;
      data.map(item => {
        total1 += Number(item.totalItem2);
        total2 += Number(item.progressWorkTime)
      });

      var productivity = 0;
      if(total2 != 0 && total1!= NaN && total2 != NaN){
        productivity = total1/total2
      }
      productivityText += LogisticUtility.getCommaText((productivity).toFixed(0))
  
  
      total1 = 0;
      total2 = 0;
      beforeData.map(item => {
        total1 += Number(item.totalItem2);
        total2 += Number(item.progressWorkTime)
      });

      var productivityPercent = 0;
      if(total2 != 0 && total1!= NaN && total2 != NaN && productivity!=0){
        var productivityBefore = total1/total2
        productivityPercent = productivity/productivityBefore*100
      }
      percentText=LogisticUtility.getCommaText((productivityPercent).toFixed(2))

      break;
    case 1:
      var total1 = 0;
      var total2 = 0;
      data.map(item => {
        total1 += Number(item.totalItem1);
        total2 += Number(item.progressWorkTime)
      });

      var productivity = 0;
      if(total2 != 0 && total1!= NaN && total2 != NaN){
        productivity = total1/total2
      }
      productivityText += LogisticUtility.getCommaText((productivity).toFixed(0))
      
      total1 = 0;
      total2 = 0;
      beforeData.map(item => {
        total1 += Number(item.totalItem1);
        total2 += Number(item.progressWorkTime)
      });

      var productivityPercent = 0;
      if(total2 != 0 && total1!= NaN && total2 != NaN && productivity!=0){
        var productivityBefore = total1/total2
        productivityPercent = productivity/productivityBefore*100
      }
      percentText=LogisticUtility.getCommaText((productivityPercent).toFixed(2))
      break;
    default:
      break;
  }

  return <>{text}<span style={{width:'10.5rem',textAlign:'right'}}>{productivityText}</span>{"個／時間（前週比："}<span style={{width:'9rem',textAlign:'right'}}>{percentText}</span>{'%）'}</>;
}

// ヘッダー部のカード
const CardTotal = (props) => {
  const {
    beforeData,daylyData
  } = props
  //const alert = useAlert();
  //const interval1Ref = useRef(null);

  // 描画時に一度だけ実行
  useEffect(() => {
  }, [])

  //if (!grants || !totalData) return null
  return (
    <CardWrap>
      <HeaderContent>
          <Title>{'VAS運用状況'}</Title>
      </HeaderContent>
      <BodyContent>
          <>
              <SmallItem>{getValue(daylyData,beforeData,0)}</SmallItem>
              <div style={{height:'1rem'}}/>
              <SmallItem style={{paddingTop:'0.5rem'}}>{getValue(daylyData,beforeData,1)}</SmallItem>
          </>
      </BodyContent>
    </CardWrap>
  )
}

CardTotal.propTypes = {
  grants: PropTypes.object, // 権限関連
  totalData: PropTypes.object, // 集計情報
  type: PropTypes.number, // 0:当日労務コスト 1:当日平均時給 2:当月労務コスト 3:当月平均時給
  wageType: PropTypes.number, // 平均時給タイプ　0:契約から算出,1:労務費から算出
}
  
export default CardTotal

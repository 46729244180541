import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const MediaWrap = styled.div`
  width:1047px;
  display: flex; /* 横並び */
  flex-wrap: wrap;
  margin: 4px;
  box-shadow: 0 0 3px 2px 'rgba(0,0,0,0.4)';
  background-color: #649CDF;
  color: #fff;
  font-size: 14px;
  font-weight: bold;

  text-align: center;
  align-items: center;
`
const MediaItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 60px;
  border-right: 2px dashed #fff;
`
const MediaRow = styled.div`
  display: flex; /* 縦並び */
  flex-direction: row;
  height: 30px;
`

const HealthTitle = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
` 

const ProcessHeader = (props) => {
  const {
    targetYMD,targetYM
  } = props

  // 初期値
  useEffect(() => {
  })

  return (
    <MediaWrap>
      <MediaItem>
        <HealthTitle style={{width:'185px'}}>工程名</HealthTitle>
      </MediaItem>
      <MediaItem>
        <HealthTitle style={{width:'81px'}}>予測物量</HealthTitle>
      </MediaItem>
      <MediaItem>
        <HealthTitle style={{width:'81px'}}>進捗物量</HealthTitle>
      </MediaItem>
      <MediaItem>
        <HealthTitle style={{width:'95px'}}>進捗工数</HealthTitle>
      </MediaItem>
      <MediaItem>
        <HealthTitle style={{width:'95px'}}>前同曜日<br/>実績工数</HealthTitle>
      </MediaItem>
      <MediaItem>
        <HealthTitle style={{width:'95px'}}>生産性</HealthTitle>
      </MediaItem>
      <MediaItem>
        <HealthTitle style={{width:'95px'}}>残必要工数</HealthTitle>
      </MediaItem>
      <MediaItem>
        <HealthTitle style={{width:'95px'}}>平均<br/>生産性/当月</HealthTitle>
      </MediaItem>
      <MediaItem>
        <HealthTitle style={{width:'95px'}}>累計工数/当月</HealthTitle>
      </MediaItem>
      <MediaItem>
        <HealthTitle style={{width:'95px'}}>割合/当月</HealthTitle>
      </MediaItem>
    </MediaWrap>
  )
}

ProcessHeader.propTypes = {
  targetYM: PropTypes.string, // 集計月
  targetYMD: PropTypes.string, // 集計日
}

export default ProcessHeader
import React, { useEffect } from 'react'
//import { useSelector } from 'react-redux'
//import { useAlert } from 'react-alert'
//import FUNC from '../../Common/FunctionEnum'
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import PropTypes from 'prop-types'
//import { GetApi } from '../../Common/ApiAxios'
//import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
//import ErrorHandling from '../../Common/ErrorHandling'

import ProgressHeader from './Molcules/ProgressHeader'
//const DefaultUrl = '/api/logisticManagement'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  vertical-align: middle;
`

const ContentBody = styled.div`
  width: 95%; 
  height: 190px;
  text-align: center;
  vertical-align: middle;
`

const ProgressBoard = (props) => {
  const {
    progressList,barGraph
  } = props

  //const { loginUserInfo } = useSelector(state => state);

  // 描画時に一度だけ実行
  useEffect(() => {
  }, [])

  return (
      <ContentWrap>
        <ContentBody>
            <ProgressHeader 
              progressList={progressList}
              barGraph={barGraph}
            />  
        </ContentBody>
      </ContentWrap>
  )
}
ProgressBoard.propTypes = {
  progressList: PropTypes.array //実績リスト
}

export default ProgressBoard

import React from 'react'
import { Redirect } from 'react-router-dom'

const Main = () => (
  // <div className='contents' />
  //<Redirect to='/whiteboard/whiteboard' />
  //<div className='contents'>Main</div>
  // <Redirect to='/shift' />
  <Redirect to='/top' />
)

export default Main

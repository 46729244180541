import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
//import { useAlert } from 'react-alert'
//import { useHistory } from "react-router-dom"
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'

import { GetApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import * as HealthCareUtilty from '../../Common/Utilties/HealthCareUtilty'
import MediaNotice from './MediaNotice'

import {ListUl, ListLi} from '../Wrap/BoardBaseWrap'

import InfoIcon from '../../../image/infomation-icon.png'
import CalendarIcon from '../../../image/calendar-icon.png'
import IconCollapseImg from '../../../image/Collapse.png'

import NoticeCalendarModal from '../Molcules/NoticeCalendarModal/NoticeCalendarModal'
import NoticeModal from '../Molcules/NoticeModal/NoticeModal'

const InfoheaderContent = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 100%;
`

const CardWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 880px;
  background-color: #ffffff;
  border-radius: 10px;
  border: none;

  color: #000;
`

const CardHeaderContent = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 100%;
  height: 48px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
`

const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
`

const Title = styled.span`
  font-size: 24px;
  font-weight: bold;
`

const DateText = styled.span`
  font-size: 20px;
  font-weight: bold;
`

const Content = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 690px;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
`

const FooterContent = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  height: 48px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.5rem;
`

const SideMenuContent = styled.div`
  display:${props => props.display || 'flex'};
  margin-top:${props => props.top || '0px'};
  flex-wrap: wrap;
  height: ${props => props.height || '50px'};
  ul.mainSearch {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    position: relative;
    > li {
      display: flex;
      margin: 0 0 0 1rem;
      label {
        line-height: 32px;
        text-align: right;
        font-size: 12px;
        margin: 0 0.5rem 0 0;
    }
  }
    li.subSearch {
      display: ${props => props.visible ? 'block' : 'none' };
      position: absolute;
      font-size: 16px;
      margin: 0;
      top: 0px;
      z-index: 3;
      left: 0;
    }
  }
`

const MenuContent = styled.div`
    flex-direction: column;
    background-repeat: no-repeat;
    height: 85vh;
    width: 900px;
    background-color: #ffffff;
  
    color: #000;
    overflow-y: none;
`

const MenuContentRow = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1vw;
    background-repeat: no-repeat;
    overflow-y: none;
`

const MenuContentHead = styled.div`
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    overflow-y: none;
`

//　お知らせカード
const InfoCard = ({top,height},props) => {
  const {
    grants,
  } = props
  const dispatch = useDispatch();
  //const alert = useAlert();
  const interval1Ref = useRef(null);
  const interval2Ref = useRef(null);
  const [viewMenu, setViewMenu] = useState(false);
  const [dateInfo, setDateInfo] = useState(null);
  const [week, setWeek] = useState('');
  const [dateText, setDateText] = useState('');
  const [timeText, setTimeText] = useState('');
  const [noticeData, setNoticeData] = useState(null);
  const [noticeList, setNoticeList] = useState([]);
  const [openDialog1,setOpenDialog1] = useState(false);
  const [openDialog2,setOpenDialog2] = useState(false);
  
  // 日付情報の取得
  const apiGetDateInfo = async (isLoadSplinner) => {
    //console.log('cal apiGetDateInfo');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = '/api/masterManagement/calendar' + '/range';
    const data = {
      startDt: HealthCareUtilty.getStringFromDate(new Date(), 'YYYY-MM-DD'),
      endDt: HealthCareUtilty.getStringFromDate(new Date(), 'YYYY-MM-DD'),
    }
    const result = await GetApi(apiUrl, data);

    if (isLoadSplinner)
        dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      //alert.error('日付情報の取得に失敗しました');
    } else {
      if (result.data === null) {
        return;
      }
      //console.log(result.data);
      setDateInfo(result.data.responseList[0]);

      setWeek(result.data.responseList[0].week);

      setDateText(HealthCareUtilty.getStringFromDate(new Date(), 'MM/DD'));
      setTimeText(HealthCareUtilty.getStringFromDate(new Date(), 'hh:mm'));
    }
  }

  // お知らせリストの取得
  const apiGetNoticeList = async (isLoadSplinner) => {
    //console.log('cal apiGetNoticeList');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = '/api/noticeManagement' + '/getNotices';
    const data = {
    }
    const result = await GetApi(apiUrl, data);

    if (isLoadSplinner)
        dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      //alert.error('お知らせリストの取得に失敗しました');
    } else {
      //console.log(result.data);
      if (result.data === null || result.data === "") {
        return;
      }
      var list = result.data;
      list.sort((a, b) => new Date(b.noticeAt) - new Date(a.noticeAt));
      setNoticeList(list.map(item => {
        return {
          noticeId: item.noticeId,
          noticeType: item.noticeType,
          title: item.title,
          text: item.noticeContent,
          noticeAt: new Date(item.noticeAt),
        }
      }))
    }
  }

  // お知らせ情報の変更イベント
  const onChangeNotice = async () => {
    apiGetNoticeList(false);
  }

  // お知らせ情報の登録イベント
  const onClickCreateNotice = () => {
    setNoticeData({
      noticeId: '',
      noticeType: 0,
      text: '',
      noticeAt: HealthCareUtilty.getStringFromDate(new Date(), 'YYYY-MM-DD'),
    });

    setOpenDialog1(true);
  }

  const onClick =()=>{    
    setViewMenu(!viewMenu)
  }

  // 周期初期のクリア
  const onClearInerval = async () => {
    //console.log('call onClearInerval');
    if (interval1Ref.current !== null) {
      clearInterval(interval1Ref.current);
    }
    
    if (interval2Ref.current !== null) {
      clearInterval(interval2Ref.current);
    }

    interval1Ref.current = null;
    interval2Ref.current = null;
  }

  // 描画時に一度だけ実行
  useEffect(() => {
    apiGetDateInfo(false);
    apiGetNoticeList(false);
    
    // 日時更新
    interval1Ref.current = setInterval(() => {
      apiGetDateInfo(false);
    }, 5000); // 5秒間隔で日時情報を取得

    // お知らせ更新
    interval2Ref.current = setInterval(() => {
      apiGetNoticeList(false);
    }, 60000); // 60秒間隔でお知らせ情報を取得

    return () => {
      onClearInerval();
    };
  }, [])

  //if (!grants) return null
  return (
    <SideMenuContent visible={viewMenu} top={top} height={height}>
      <ul className='mainSearch'>
        <InfoheaderContent>
          <Icon alt='開閉' src={IconCollapseImg} width="24px" height="24px" style={{cursor: 'pointer' }} onClick={()=>onClick()}/>
          <Icon alt="お知らせ" src={InfoIcon} />
          <Title style={{marginLeft:'0.5rem', marginTop:'0.50rem'}}>お知らせ</Title>
        </InfoheaderContent>
        <li className='subSearch'>
          <MenuContent>
            <MenuContentHead>
              <InfoheaderContent>
                <Icon alt='開閉' src={IconCollapseImg} width="24px" height="24px" style={{transform: 'rotate(180deg)' ,cursor: 'pointer' }} onClick={()=>onClick()}/>
                <Icon alt="お知らせ" src={InfoIcon} />
                <Title style={{marginLeft:'0.5rem', marginTop:'0.50rem'}}>お知らせ</Title>
              </InfoheaderContent>
            </MenuContentHead>
            <MenuContentRow>
              <CardWrap>
              <CardHeaderContent>
              {dateInfo &&
              <>
                <div style={{textAlign:'right', marginLeft:'auto', marginRight:'1.0rem', marginTop:'0.5rem'}}>
                  <DateText>{dateText+' ('}<span style={{color:HealthCareUtilty.getDateColor(dateInfo)}}>{week}</span>{') '+timeText}</DateText>
                </div>
                <div style={{textAlign:'right', marginRight:'1.5rem' }}>
                  <Tooltip title='月単位のお知らせ表示'>
                    <Icon style={{width:'20px', height:'20px', cursor:'pointer'}} alt="カレンダー" src={CalendarIcon} onClick={onClickCreateNotice} />
                  </Tooltip>
                </div>
              </>
              }
              </CardHeaderContent>
              <Content>
                <ListUl width={'95%'} height={'610px'} style={{marginLeft:'1rem', marginTop:'1rem'}}>
                  {noticeList && noticeList.map(item => (
                    <ListLi key={'info_'+item.noticeId}>
                      <MediaNotice grants={grants} noticeData={item} selected={false} onChangeNotice={onChangeNotice} />
                    </ListLi>
                  ))}
                </ListUl>
              </Content>
              <FooterContent>
              </FooterContent>
              {noticeData &&
              <NoticeCalendarModal
                targetYMD={HealthCareUtilty.getStringFromDate(new Date(), 'YYYY-MM-DD')}
                noticeList={noticeList}
                modalIsOpen={openDialog1}
                setIsOpen={setOpenDialog1}
                onChangeNotice={onChangeNotice}
              />
              }
              {noticeData &&
              <NoticeModal
                noticeData={noticeData}
                type={0}
                modalIsOpen={openDialog2}
                setIsOpen={setOpenDialog2}
                onChangeNotice={onChangeNotice}
              />
              }
              </CardWrap>
            </MenuContentRow>
          </MenuContent>
        </li>
      </ul>
    </SideMenuContent>
  )
}

InfoCard.propTypes = {
  grants: PropTypes.object, // 権限関連
}

export default InfoCard

import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
//import { useAlert } from 'react-alert'
//import { useHistory } from "react-router-dom"
import styled from 'styled-components'
import PropTypes from 'prop-types'
//import Tooltip from '@material-ui/core/Tooltip'

import IconCloseImg from '../../../image/close_icon.png'

//import {ListUl, ListLi} from '../Wrap/BoardBaseWrap'

const RecentMenuWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 150px;
  background-color: #bad3ff;
  border-radius: 10px;
  border: none;

  color: #fff;
  margin-bottom: 10px;
`

const HeaderContent = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 100%;
  height: 30px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
`

const Title = styled.span`
  font-size: 24px;
  font-weight: bold;
`

const Content = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  height: 80px;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;

  margin-top: 10px;
`

const FooterContent = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  height: 10px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.5rem;
`

const MenuContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  text-align: center;
  margin-left: 0.5rem;

  border-radius: 10px;
  border: 2px solid gray;
  
  cursor: pointer;
`
const MenuIcon = styled.img`
  margin: 5px;
  width: 18px;
  height: 18px;
`

const MenuName = styled.div`
  margin: 5px;
  text-align: center;
`

//　最近使用したメニュー
const RecentMenu = (props) => {
  const {
    grants,
  } = props
  //const dispatch = useDispatch();
  //const alert = useAlert();

  // 描画時に一度だけ実行
  useEffect(() => {
  }, [])

  //if (!grants) return null
  return (
    <RecentMenuWrap>
      <HeaderContent>
        <Title style={{marginLeft:'0.5rem', marginTop:'0.5rem'}}>最近使用したメニュー</Title>
      </HeaderContent>
      <Content>
        <MenuContent>  
          <div><MenuIcon alt='運用環境情報' src={IconCloseImg}/></div>
          <MenuName><span style={{marginLeft:'0.5rem', marginRight:'0.5rem'}}>運用環境情報</span></MenuName>
        </MenuContent>
        <MenuContent>  
          <div><MenuIcon alt='ライセンス' src={IconCloseImg}/></div>
          <MenuName><span style={{marginLeft:'0.5rem', marginRight:'0.5rem'}}>ライセンス</span></MenuName>
        </MenuContent>
      </Content>
      <FooterContent>
      </FooterContent>
    </RecentMenuWrap>
  )
}

RecentMenu.propTypes = {
  grants: PropTypes.object, // 権限関連
}

export default RecentMenu

import React, { useState, useEffect } from 'react'
//import { useSelector, useDispatch } from 'react-redux'
//import { useAlert } from 'react-alert'
//import FUNC from '../../Common/FunctionEnum'
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import ToolTips from '@material-ui/core/Tooltip'

import { GetApi } from '../../Common/ApiAxios'
import * as LogisticUtility from '../../Common/Utilties/LogisticUtility'
import {onExportCsvFile} from '../../Common/Utilties/ExportUtility'

import IconDownloadImg from '../../../image/download-icon.png'

import ProcessHeader from './Molcules/ProcessListHeader'
import MediaDataProcess from './Atoms/MediaDataProcessList'

const DefaultUrl = '/api/logisticManagement'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  float: flex;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
`

const ContentBody = styled.div`
  width: 90%;
  heigt: 100px;
  margin-left: auto;
  margin-right: auto;
`
export const ListUl = styled.ul`
  width: ${props => props.width ? props.width : '100%'};
  height: ${props => props.height ? props.height : '400px'};
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    border:none;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(112, 112, 112);
    border-radius: 10px;
  }
`

export const ListLi = styled.li`
  width: ${props => props.width ? props.width : 'calc(100%-18px)'};
  text-indent: 10px;
  background: ${props => props.selected ? '#048b63' : '#fff'};
  color: ${props => props.selected ? '#fff' : '#333'};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  .listId{
    margin-right:1vw;
  }
  :nth-child(even){
    background: ${props => props.selected ? '#048b63' : '#eee'};
    color: ${props => props.selected ? '#fff' : '#333'};
  }
  :hover {
    background: #ccc;
    color: #333;
  }
`
const Header = '集計月,現場ID,現場名,工程ID,工程名,人数,作業時間（秒）,作業時間（時）'
const OperationBoard = (props) => {
  const {
    searchDate,processList,beforeProcessList,targetYMD,targetYM
  } = props
  //const { loginUserInfo } = useSelector(state => state);

  //const dispatch = useDispatch();
  //const alert = useAlert();
  //const [processList,setProcessList] = useState(null);
  //const [beforeProcessList,setBeforeProcessList] = useState(null);

  //const [targetYMD,setTargetYMD] = useState(LogisticUtility.getStringFromDate(new Date(searchDate), 'YYYY/MM/DD'));
  //const [targetYM,setTargetYM] = useState(LogisticUtility.getStringFromDate(new Date(searchDate), 'YYYY年MM月度'));
 
  useEffect(() => {
    //apiGetProcessInfo();
  }, [])

  const onExport = async ()=>{
    const apiUrl = DefaultUrl + '/getProcessExport';
    const param = {
      workplaceId:'000006',
      //targetYMD: '2023-02-24'
      targetYMD: LogisticUtility.getStringFromDate(new Date(searchDate), 'YYYY-MM-DD'),
    }
    //console.log(param);
    const result = await GetApi(apiUrl, param);

    //dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
    } else {
      let exportResult = Header + '\r\n';


      for (const workInfo of result.data) {
        var lineResult =
            workInfo.dateValue + ','
            + workInfo.workplaceId + ','
            + workInfo.workplaceName + ','
            + workInfo.processId + ','
            + workInfo.processName + ','
            + workInfo.processStaff + ','
            + workInfo.processTime + ','
            + workInfo.monthProcessTime + ',';
        lineResult = lineResult.replaceAll('null','');
        exportResult += lineResult;
        
        exportResult += '\r\n';
      }

      let fileName = LogisticUtility.getStringFromDate(new Date(), 'YYYYMMDD')+"_工程実績データ(工程別月単位)"
      onExportCsvFile(exportResult,fileName)
    }
  }
  if (processList==null || beforeProcessList==null) return <></>;
  return (
    <ContentWrap>
      <ContentBody>
        <div div style={{display:'flex',justifyContent:'flex-end', cursor:'pointer'}}>
          <ToolTips title={'ダウンロード'}><div  onClick={()=>onExport()}><img alt='ダウンロード' src={IconDownloadImg} width="25px" height="25px"/></div></ToolTips>
        </div>
        <ProcessHeader targetYMD={targetYMD} targetYM={targetYM} />
        <ListUl width={'1750px'} height={'288px'}>
        {processList.map((item,index) => (
          <ListLi key={item.processId} style={{cursor: 'default'}} width={'1740px'} >
            <MediaDataProcess data={item} before={beforeProcessList[index]} index={index}/>
          </ListLi>
        ))}
        </ListUl>
      </ContentBody>
    </ContentWrap>
  )
}

export default OperationBoard

import React from 'react'
//import { useForm } from 'react-hook-form'
//import { useDispatch } from 'react-redux'
//import { useAlert } from 'react-alert'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Button from '../../../../Common/Atoms/NormalButton'

import Table from './PalDashBoardWorkplaceTable'
const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
`
/*
const TitleWrap = styled.div`
  display: flex;
  padding: 1vw;
`
*/
const FormWrap = styled.form`
  display: flex;
`
const TableWrap = styled.div`
  display: flex;
`
const ButtonsWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1vw;
`

export const IconImg = styled.img`
  align-items: left;
  width: 20px;
  height: 20px;
`
export const NotIconImg = styled.div`
  align-items: left;
  width: 20px;
  height: 20px;
`

const PalDashboardWorkplaceDialog = ({ open,onCloseFunc,graphName,ColumnList,setRateList}) => {
  //const { register, handleSubmit, getValues, setValue, watch, reset, errors, control } = useForm({ validateCriteriaMode: 'all' })
  const classes = useStyles();

  const onClose=()=>{
    var tmpList = new Array()
    ColumnList.map((column)=>{
      if(column.checked){
        tmpList.push(column.workplaceId)
      }
    })
    setRateList(tmpList)
    onCloseFunc()
  }
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='現場'
      aria-describedby='現場選択ダイアログ'
      style={{display:'flex',alignItems:'center',justifyContent:'center'}}
    >
    <FormWrap>
    <div className={classes.paper}>
      <ContentWrap>
      <TableWrap>
      <Table ColumnList={ColumnList}/>
      </TableWrap>
      </ContentWrap>
      <ButtonsWrap>
        <Button name='閉じる' type='button' padding='0px' onClick={onClose} />
      </ButtonsWrap>
    </div>
    </FormWrap>
  </Modal>
  )
}

PalDashboardWorkplaceDialog.propTypes = {
    open: PropTypes.bool,
    closeFunc: PropTypes.func,
    updatedAttendanceFunc: PropTypes.func,
    defaultUrl: PropTypes.string,
    iconName: PropTypes.string,
    text: PropTypes.string,
    dailyWorkInfoList: PropTypes.array
}

export default PalDashboardWorkplaceDialog
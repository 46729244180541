import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'
const MediaWrap = styled.div`
  width:calc(1650px);
  display: flex; /* 横並び */
  flex-wrap: wrap;
  margin: 4px;
  box-shadow: 0 0 3px 2px 'rgba(0,0,0,0.4)';
  background-color: #649CDF;
  color: #fff;
  font-size: 18px;
  font-weight: bold;

  text-align: left;
  align-items: center;
`
const MediaItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 40px;
  border-right: 2px dashed #fff;
`
const MediaRow = styled.div`
  display: flex; /* 縦並び */
  flex-direction: row;
  height: 30px;
`

const HealthTitle = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
` 
function getDate(date,week) {
  var tmpDate = new Date(date)
  var dayOfWeek  = tmpDate.getDay()

  tmpDate.setDate(tmpDate.getDate() - dayOfWeek + week)
  var text = LogisticUtility.getStringFromDate(tmpDate, 'MM/DD')

  return text;
}

const ProcessHeader = (props) => {
  const {
     title
  } = props

  // 初期値
  useEffect(() => {
  })

  return (
    <MediaWrap>
      <MediaItem>
          <HealthTitle style={{width:'1650pX'}}>{title}</HealthTitle>
      </MediaItem>
    </MediaWrap>
  )
}

ProcessHeader.propTypes = {
  targetYM: PropTypes.string, // 集計月
  targetYMD: PropTypes.string, // 集計日
}

export default ProcessHeader
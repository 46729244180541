import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
//import FUNC from '../../Common/FunctionEnum'
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'

import { GetApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
//import ErrorHandling from '../../Common/ErrorHandling'

import AlertPanel from './Atoms/AlertPanel'
import ProgressHeader from './Molcules/ProgressHeader'
import ProcessBox from './Molcules/ProcessBox'
import ProgressBox from './Molcules/ProgressBox'

const DefaultUrl = '/api/logisticManagement'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 85vh;
  text-align: center;
  vertical-align: middle;
`

const ContentBody = styled.div`
  display: grid;
  grid-gap: 30px 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row: 1 / 2;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
`

const ProgressBoard = (props) => {
  //const { loginUserInfo } = useSelector(state => state);

  const dispatch = useDispatch();
  const alert = useAlert();
  const [processList,setProcessList] = useState([]);

  // 進捗設定情報の取得
  const apiGetProgressBoardSetting = async (isLoadSplinner) => {
    //console.log('call apiGetProgressBoardSetting');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getProgressBoardSetting';
  
    const data = {
      workplaceId: '000011',
    }
    const result = await GetApi(apiUrl, data);
    
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      //alert.error('条件の該当データがありません(進捗設定情報の取得)');
    } else {
      setProcessList(result.data);
    }
  }

  // 描画時に一度だけ実行
  useEffect(() => {
    apiGetProgressBoardSetting(true);
  }, [])

  return (
    <>
      <ContentWrap>
        <ProgressHeader/>
        <AlertPanel />
        <ContentBody>
          {processList.map(process => (
            <>
            {process.items.map(item => (
            <>
              {1 === item.itemType && <ProcessBox settingInfo={item}></ProcessBox>}
              {2 === item.itemType && <ProgressBox settingInfo={item}></ProgressBox>}
            </>
            ))
            }
            </>
          ))}
        </ContentBody>
      </ContentWrap>
    </>
  )
}

export default ProgressBoard

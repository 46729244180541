const FUNC = {
    HOME: '2010101001', // ホーム
    SHIFT: '2020101001', // シフト管理
    ATTENDANCE: '2020201001', // 勤怠管理
    STAFF: '2040101001', // スタッフマスタ基本情報
    STAFF_GRANT: '2040101003', // スタッフマスタ権限
    COMPANY: '2040201001', // 会社マスタ
    CUSTOMER: '2040301001', // 顧客マスタ
    ORDER: '2040401001', // 受注管理
    ALLOCATION: '2040501001', // 引当管理
    GRANT: '2040601001', // 権限管理
    CALENDAR: '2040901001', // カレンダー管理
    SALARIED:'2020301001', //有給休暇管理
    STAFF_BIOETRIC:'2040101002',//認証管理
    DASHBOARD:'2050101001',//ダッシュボード
    LOGISTIC:'2050201001',//ロジスティクス事業部
    LOGISTIC_DASHBOARD:'2050202001',//ロジスティクス事業部【ダッシュボード】
    LOGISTIC_OLAPANALYSIS:'2050203001',//ロジスティクス事業部【OLAP分析】
    LOGISTIC_DATAMINING:'2050204001',//ロジスティクス事業部【データマイング】
    LOGISTIC_PLANNING:'2050205001',//ロジスティクス事業部【プランニング】
    LOGISTIC_CARBONEMISSIONS:'2050206001',//ロジスティクス事業部【サプライチェーンCo2排出量】
    TRUCK:'2050301001',//運送事業部
    TRUCK_CURRENTMONTHPROGRESS:'2050302001',//運送事業部【当月進捗】
    TRUCK_PREVIOUSMONTHRESULTS:'2050303001',//運送事業部【前月実績】
    TRUCK_SUMMARY:'2050304001',//運送事業部【サマリ】
    TRUCK_HEALTHCARE:'2050305001',//運送事業部【ヘルスケア】
    TRUCK_HELP:'2050306001',//運送事業部【ヘルプ】
    BULK_EDIT:'2020102001',//一括編集
    DIFF_ATTENDANCE:'2020203001',//勤怠差異
    SHIFT_GANTCHART:'2020103001',//シフトガントチャート
  }
  
  export default FUNC
  
import React,{useRef,useEffect,useState} from 'react';

export default function Map({children}){
  const ref = useRef(null);
  const [map, setMap] = useState();

 useEffect(() => {
  if (ref.current && !map) {
    setMap(new window.google.maps.Map(ref.current, {
       center:{
          lat: 35.18348497476951, // 名古屋市
          lng: 136.91324566005633,
      },
      zoom:7,
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false,
    }));
  }
  if (map) {
    /*取得スタイルの貼り付け*/

    var styleOptions = [
    {
      "stylers": [
        {hue: '#003366'},
        {saturation : -50},
      ]
    }
    ];
    var styledMapOptions = { name: '株式会社PAL' }/*地図左上のタイトル*/
    var sampleType = new window.google.maps.StyledMapType(styleOptions, styledMapOptions);
    map.mapTypes.set('style', sampleType);
    map.setMapTypeId('style');
    
  }
}, [ref, map]);

return(  <>
    <div ref={ref} style={{height:550, width:1100}} />
    {React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { map });
      }
    })}
  </>
  )
}

import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import styled from 'styled-components'

import { GetApi } from '../../../Common/ApiAxios'
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'
import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'

import {XAxis, YAxis, Tooltip, Legend, Line,PieChart,Pie,Cell, Bar,ComposedChart } from "recharts"

const DefaultUrl = '/api/logisticManagement'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 85vh;
  text-align: center;
  vertical-align: middle;
`

const ContentBody = styled.div`
  display: grid;
  grid-row: 1 / 2;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
`

const BodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  overflow: hidden;
  vertical-align: middle;
  width: 400px;
  height: 100%;
`

const GraphContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
`

const ProcessRow = styled.div`
  display: inline-block;
  color: #535353;
  margin-top: 2px;
  border-bottom: 1px solid #D9D5C9;
  width: 210px;
`

const ProcessName = styled.span`
  display: inline-block;
  font-weight: bold;
  color: #000;
  text-align: right;
  font-size: 1.2rem;
  width: 120px;
`

const ProcessSubItem = styled.span`
  display: inline-block;
  color: #535353;
  text-align: left;
`

const ProcessTitle = styled.div`
  font-weight: bold;
  color: #000;
  text-align: center;
  font-size: 1.2rem;
`


const ProgressGraphBoard = (props) => {
  //const { loginUserInfo } = useSelector(state => state);
  const {
    dataList
  } = props
  const dispatch = useDispatch();
  const alert = useAlert();
  const [processList,setProcessList] = useState([]);
  const intervalRef = useRef(null);
  const [prgressGraph,setPrgressGraph] = useState();

  // 進捗設定情報の取得
  const apiGetActivityProgressManagement = async (isLoadSplinner) => {
    //console.log('call getActivityProgressManagement');
    // if (isLoadSplinner)
    //   dispatch(SetLoadSpinner(true));

    // const apiUrl = DefaultUrl + '/getActivityProgressManagement';
   
    // const data = {
    //   workplaceId: '000011',
    //   targetYMD: LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD'),
    // }
    // const result = await GetApi(apiUrl, data);

    // if (isLoadSplinner)
    //   dispatch(SetLoadSpinner(false));

    // if (result.errorDetail) {
    //    alert.error('条件の該当データがありません(進捗設定情報の取得)');
    // } else {
    //   console.log(result.data);
    //   setProcessList(result.data);
    // }
  }
  
  //x軸のデータ  

  // 周期初期のクリア
  const onClearInerval = async () => {
    if (intervalRef.current !== null)
      clearInterval(intervalRef.current);
    
    intervalRef.current = null;
  }

  // 描画時に一度だけ実行
  useEffect(() => {
    console.log(window.parent.screen.width)
    apiGetActivityProgressManagement()

    intervalRef.current = setInterval(() => {
      apiGetActivityProgressManagement()
    }, 1000 * 60); // 60秒間隔で変更
    
    return () => {
      onClearInerval();
    };
  }, [])


  return (
    <ContentWrap>
      {dataList && 
        <GraphContent>
          <ComposedChart 
              width={950}
              height={405}
              data={dataList}
              >
                <XAxis xAxisId={1} dataKey="week" padding={{ left: 30, right: 30 }}/>
                <YAxis yAxisId={1}  domain={[0, dataMax => (dataMax+(50 - dataMax%50))]} tickCount={6} width={40}/>
                <Tooltip formatter={(value)=>Math.round(value)}/>  
                <Line type="plainline" xAxisId={1} yAxisId={1} strokeWidth={2} dataKey="thisShippingMh" name="今週" stroke="#649CDF" />    
                <Line type="plainline" xAxisId={1} yAxisId={1} strokeWidth={2} dataKey="beforeShippingMh" name="先週" stroke="orangered" />      
                <Line type="plainline" xAxisId={1} yAxisId={1} strokeWidth={2} dataKey="before2ShippingMh" name="先々週" stroke="orange" />  
                <Legend layout="vertical" verticalAlign="top" align="right"/>
          </ComposedChart >
        </GraphContent>
      }
    </ContentWrap>
  )
}

export default ProgressGraphBoard

import PropTypes from 'prop-types'
import { useCallback, useState, useEffect } from 'react'

//csvファイルエクスポート処理
export function onExportCsvFile (exportData,name) {

    {
      //BOM情報付与(utf-8 文字コードでcsvが開く情報付与)
      let bom  = new Uint8Array([0xEF, 0xBB, 0xBF]);
      const blob = new Blob([bom,exportData], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download =  name+".csv"; 
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    }
}
onExportCsvFile.propTypes = {
    exportData: PropTypes.string, // 出力データ
    name: PropTypes.string, //ファイル名
}

import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import styled from 'styled-components'
//import { useAlert } from 'react-alert'
import { useDispatch } from 'react-redux'

//import Select from '../../../Common/Atoms/Select'

import Input from '../../../Common/Atoms/Input'
import NormalButton from '../../../Common/Atoms/NormalButton'
import { GetApi, PostApi } from '../../../Common/ApiAxios'
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'
//import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'

import ExplainWorkTimeModal from './ExplainWorkTimeModal'
import ExplainSleepScoreModal from './ExplainSleepScoreModal'
import ExplainSleepTimeModal from './ExplainSleepTimeModal'
import ExplainExerciseIntensityModal from './ExplainExerciseIntensityModal'
import ExplainHeartRateModal from './ExplainHeartRateModal'

//import DateCalendar from '../../../Common/Atoms/Calendar'
import CloseIcon from '../../../../image/close_icon.png'
import QuestionIcon from '../../../../image/question-icon.png'

const DefaultUrl = '/api/truckManagement'

/* 縦並 */
const Wrap = styled.div`
  display:flex;
  flex-flow: column;
`

/* 検索項目 */
const SettingItem = styled.div`
  display: flex; /* 横並び */
  flex-flow: row nowrap;
  text-align: left;
  vertical-align: middle;
`

const ItemTitle = styled.label`
  width: 10rem;
  margin-top: auto;
  margin-right: 0.5rem;
  margin-bottom: auto;
`

const ItemUnit = styled.span`
  width: 2rem;
  margin-left: 1rem;
  margin-top: 1rem;
`

const ItemCheck = styled.label`
  width: 5rem;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
` 

const ItemQuestion = styled.div`
  margin-left: 0.25rem;
  margin-top: 0.5rem;
` 

const AlertSettingModal  = (props) => {
  const {
    grants,
    modalIsOpen,
    setIsOpen,
  } = props
  const dispatch = useDispatch();
  //const alert = useAlert();
  const workTimeRef = useRef();
  const workTimeDailyRef = useRef();
  const workTimeWeeklyRef = useRef();
  const workTimeMonthlyRef= useRef();
  const sleepScoreRef = useRef();
  const sleepTimeRef = useRef();
  const exerciseIntensityRef = useRef();
  const heartRateUnderRef = useRef();
  const heartRateUpperRef = useRef();
  
  const [workTime,setWorkTime] = useState(false);
  const [workTimeDaily,setWorkTimeDaily] = useState(false);
  const [workTimeWeekly,setWorkTimeWeekly] = useState(false);
  const [workTimeMonthly,setWorkTimeMonthly] = useState(false);
  const [sleepScore,setSleepScore] = useState(false);
  const [sleepTime,setSleepTime] = useState(false);
  const [exerciseIntensity,setExerciseIntensity] = useState(false);
  const [heartRateUnder,setHeartRateUnder] = useState(false);
  const [heartRateUpper,setHeartRateUpper] = useState(false);

  //ダイアログ表示用
  const [openDialog1, setOpenDialog1] = useState(false);
  const [openDialog2, setOpenDialog2] = useState(false);
  const [openDialog3, setOpenDialog3] = useState(false);
  const [openDialog4, setOpenDialog4] = useState(false);
  const [openDialog5, setOpenDialog5] = useState(false);
  const [openDialog6, setOpenDialog6] = useState(false);
  const [openDialog7, setOpenDialog7] = useState(false);
  const [openDialog8, setOpenDialog8] = useState(false);
  const [openDialog9, setOpenDialog9] = useState(false);

  // アラート設定情報の取得
  const apiGetAlertSetting = async(isLoadSplinner) => {
    //console.log('call apiGetAlertSetting');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getAlertSetting';

    const param = {
    }
    //console.log(param);
    const result = await GetApi(apiUrl, param);
  
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      //alert.error('アラート設定情報の取得失敗');
    } else {
      //console.log(result);
      workTimeRef.current.value = result.data[0].threshold.toFixed(2);
      setWorkTime(result.data[0].useFlag);
      workTimeDailyRef.current.value = result.data[4].threshold.toFixed(2);
      setWorkTimeDaily(result.data[4].useFlag);
      workTimeWeeklyRef.current.value = result.data[5].threshold.toFixed(2);
      setWorkTimeWeekly(result.data[5].useFlag);
      workTimeMonthlyRef.current.value = result.data[6].threshold.toFixed(2);
      setWorkTimeMonthly(result.data[6].useFlag);

      sleepScoreRef.current.value = result.data[1].threshold;
      setSleepScore(result.data[1].useFlag);
      sleepTimeRef.current.value = result.data[2].threshold.toFixed(2);
      setSleepTime(result.data[2].useFlag);
      exerciseIntensityRef.current.value = result.data[3].threshold;
      setExerciseIntensity(result.data[3].useFlag);
      heartRateUnderRef.current.value = result.data[7].threshold;
      setHeartRateUnder(result.data[7].useFlag);
      heartRateUpperRef.current.value = result.data[8].threshold;
      setHeartRateUpper(result.data[8].useFlag);
    }
  }

  // 作業時間（法：週）のアラート設定の更新
  const apiPostWorkTimeAlertSetting = async (isLoadSplinner) => {
    //console.log('call apiPostWorkTimeAlertSetting');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/updateAlertSetting';

    const param = {
      id: 1,
      threshold: workTimeRef.current.value,
      useFlag: workTime
    }
    //console.log(param);
    const result = await PostApi(apiUrl, param);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('作業時間（法：週）のアラート設定の更新失敗');
    } else {
    }
  }

  // 作業時間（日）のアラート設定の更新
  const apiPostWorkTimeDailyAlertSetting = async (isLoadSplinner) => {
    //console.log('call apiPostWorkTimeDailyAlertSetting');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/updateAlertSetting';

    const param = {
      id: 5,
      threshold: workTimeDailyRef.current.value,
      useFlag: workTimeDaily
    }
    //console.log(param);
    const result = await PostApi(apiUrl, param);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('作業時間（日）のアラート設定の更新失敗');
    } else {
    }
  }

  // 作業時間（週）のアラート設定の更新
  const apiPostWorkTimeWeeklyAlertSetting = async (isLoadSplinner) => {
    //console.log('call apiPostWorkTimeWeeklyAlertSetting');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/updateAlertSetting';

    const param = {
      id: 6,
      threshold: workTimeWeeklyRef.current.value,
      useFlag: workTimeWeekly
    }
    //console.log(param);
    const result = await PostApi(apiUrl, param);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('作業時間（週）のアラート設定の更新失敗');
    } else {
    }
  }

  // 作業時間（月）のアラート設定の更新
  const apiPostWorkTimeMonthlyAlertSetting = async (isLoadSplinner) => {
    //console.log('call apiPostWorkTimeMonthlyAlertSetting');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/updateAlertSetting';

    const param = {
      id: 7,
      threshold: workTimeMonthlyRef.current.value,
      useFlag: workTimeMonthly
    }
    //console.log(param);
    const result = await PostApi(apiUrl, param);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('作業時間（月）のアラート設定の更新失敗');
    } else {
    }
  }
  
  // 睡眠スコアのアラート設定の更新
  const apiPostSleepScoreAlertSetting = async (isLoadSplinner) => {
    //console.log('call apiPostSleepScoreAlertSetting');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/updateAlertSetting';

    const param = {
      id: 2,
      threshold: sleepScoreRef.current.value,
      useFlag: sleepScore
    }
    //console.log(param);
    const result = await PostApi(apiUrl, param);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('睡眠スコアのアラート設定の更新失敗');
    } else {
    }
  }
  
  // 睡眠時間のアラート設定の更新
  const apiPostSleepTimeAlertSetting = async (isLoadSplinner) => {
    //console.log('call apiPostSleepTimeAlertSetting');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/updateAlertSetting';

    const param = {
      id: 3,
      threshold: sleepTimeRef.current.value,
      useFlag: sleepTime
    }
    //console.log(param);
    const result = await PostApi(apiUrl, param);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('睡眠時間のアラート設定の更新失敗');
    } else {
    }
  }
  
  // 疲労度のアラート設定の更新
  const apiPostExerciseIntensityAlertSetting = async (isLoadSplinner) => {
    //console.log('call apiPostExerciseIntensityAlertSetting');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/updateAlertSetting';

    const param = {
      id: 4,
      threshold: exerciseIntensityRef.current.value,
      useFlag: exerciseIntensity
    }
    //console.log(param);
    const result = await PostApi(apiUrl, param);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('疲労度のアラート設定の更新失敗');
    } else {
    }
  }

  // 心拍数（下）のアラート設定の更新
  const apiPostHeartRateUnderAlertSetting = async (isLoadSplinner) => {
    //console.log('call apiPostHeartRateUnderAlertSetting');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/updateAlertSetting';

    const param = {
      id: 8,
      threshold: heartRateUnderRef.current.value,
      useFlag: heartRateUnder
    }
    //console.log(param);
    const result = await PostApi(apiUrl, param);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('疲労度のアラート設定の更新失敗');
    } else {
    }
  }
  
  // 心拍数（上）のアラート設定の更新
  const apiPostHeartRateUpperAlertSetting = async (isLoadSplinner) => {
    //console.log('call apiPostHeartRateUpperAlertSetting');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/updateAlertSetting';

    const param = {
      id: 9,
      threshold: heartRateUpperRef.current.value,
      useFlag: heartRateUpperRef
    }
    //console.log(param);
    const result = await PostApi(apiUrl, param);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('疲労度のアラート設定の更新失敗');
    } else {
    }
  }

  // モーダル
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      //opacity: 0.8,
      padding: '10px',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    },
    overlay: {
      //background: 'rgba(0, 0, 0, 0.7)',
      zIndex: 10
    }
  }

  //ダイアログ開いた時の処理
  function afterOpenModal () {
    apiGetAlertSetting();
  }

  function closeModal () {
    setIsOpen(false);
  }

  function changeWorkTime (value) {
    setWorkTime(value);
  }

  function changeWorkTimeDaily (value) {
    setWorkTimeDaily(value);
  }

  function changeWorkTimeWeekly (value) {
    setWorkTimeWeekly(value);
  }

  function changeWorkTimeMonthly (value) {
    setWorkTimeMonthly(value);
  }

  function changeSleepScore (value) {
    setSleepScore(value);
  }

  function changeSleepTime (value) {
    setSleepTime(value);
  }

  function changeExerciseIntensity(value) {
    setExerciseIntensity(value);
  }

  function changeHeartRateUnder(value) {
    setHeartRateUnder(value);
  }

  function changeHeartRateUpper(value) {
    setHeartRateUpper(value);
  }

  // さ行時間説明クリックイベント
  const onClickWorkTime=()=>{
    //console.log('onClickWorkTime');
    setOpenDialog1(true);
  }

  // 睡眠スコア説明クリックイベント
  const onClickSleepScore=()=>{
    //console.log('onClickSleepScore');
    setOpenDialog5(true);
  }

  // 睡眠時間説明クリックイベント
  const onClickSleepTime=()=>{
    //console.log('onClickSleepTime');
    setOpenDialog6(true);
  }

  // 疲労度説明クリックイベント
  const onClickExerciseIntensity=()=>{
    //console.log('onClickExerciseIntensity');
    setOpenDialog7(true);
  }

  // 新派宇数説明クリックイベント
  const onClickHeartRate=()=>{
    //console.log('onClickHeartRate');
    setOpenDialog8(true);
  }
  
  // 反映ボタンクリックイベント
  const onClickRefer = async () => {

    await apiPostWorkTimeAlertSetting(true);
    await apiPostWorkTimeDailyAlertSetting(true);
    await apiPostWorkTimeWeeklyAlertSetting(true);
    await apiPostWorkTimeMonthlyAlertSetting(true);
    await apiPostSleepScoreAlertSetting(true);
    await apiPostSleepTimeAlertSetting(true);
    await apiPostExerciseIntensityAlertSetting(true);
    await apiPostHeartRateUnderAlertSetting(true);
    await apiPostHeartRateUpperAlertSetting(true);
    
    closeModal();
  }

  useEffect(() => {
  }, [modalIsOpen]);

  // 描画時に一度だけ実行
  useEffect(() => {
  }, []);

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel='Setting Alert Modal'
      ariaHideApp={false}
    >
      <div style={{ padding:'1rem', marginLeft:'1rem', marginRight:'0.25rem' }}>
        <Wrap>
          <div style={{ height:'20px', marginLeft:'auto', marginRight:'0px', marginTop:'0px', cursor: 'pointer'}}>
            <img alt='閉じる' className='close' src={CloseIcon} title={'ダイアログを閉じる'} width="12px" height="12px" onClick={closeModal} />
          </div>
          <SettingItem style={{marginTop:'1rem'}}>
            <ItemTitle>作業時間（法：週）</ItemTitle>
            <Input type='text' name='workTime' width='8rem' style={{textAlign:'right', paddingRight: '1rem'}} inputRef={workTimeRef} />
            <ItemUnit>h</ItemUnit>
            <ItemCheck>
              <input
                type='checkbox'
                id='workTime'
                checked={workTime}
                onChange={e => changeWorkTime(e.target.checked)}
              />
              利用
            </ItemCheck>
            <ItemQuestion>
              <img alt='詳細' className='close' src={QuestionIcon} title={'説明表示'} width="12px" height="12px" onClick={onClickWorkTime} />
            </ItemQuestion>
          </SettingItem>
          <SettingItem style={{marginTop:'1rem'}}>
            <ItemTitle>作業時間（日）</ItemTitle>
            <Input type='text' name='workTime' width='8rem' style={{textAlign:'right', paddingRight: '1rem'}} inputRef={workTimeDailyRef} />
            <ItemUnit>h</ItemUnit>
            <ItemCheck>
              <input
                type='checkbox'
                id='workTimeDaily'
                checked={workTimeDaily}
                onChange={e => changeWorkTimeDaily(e.target.checked)}
              />
              利用
            </ItemCheck>
            <ItemQuestion>
              <img alt='詳細' className='close' src={QuestionIcon} title={'説明表示'} width="12px" height="12px" onClick={onClickWorkTime} />
            </ItemQuestion>
          </SettingItem>
          <SettingItem style={{marginTop:'1rem'}}>
            <ItemTitle>作業時間（週）</ItemTitle>
            <Input type='text' name='workTime' width='8rem' style={{textAlign:'right', paddingRight: '1rem'}} inputRef={workTimeWeeklyRef} />
            <ItemUnit>h</ItemUnit>
            <ItemCheck>
              <input
                type='checkbox'
                id='workTimeWeekly'
                checked={workTimeWeekly}
                onChange={e => changeWorkTimeWeekly(e.target.checked)}
              />
              利用
            </ItemCheck>
            <ItemQuestion>
              <img alt='詳細' className='close' src={QuestionIcon} title={'説明表示'} width="12px" height="12px" onClick={onClickWorkTime} />
            </ItemQuestion>
          </SettingItem>
          <SettingItem style={{marginTop:'1rem'}}>
            <ItemTitle>作業時間（月）</ItemTitle>
            <Input type='text' name='workTime' width='8rem' style={{textAlign:'right', paddingRight: '1rem'}} inputRef={workTimeMonthlyRef} />
            <ItemUnit>h</ItemUnit>
            <ItemCheck>
              <input
                type='checkbox'
                id='workTimeMonthly'
                checked={workTimeMonthly}
                onChange={e => changeWorkTimeMonthly(e.target.checked)}
              />
              利用
            </ItemCheck>
            <ItemQuestion>
              <img alt='詳細' className='close' src={QuestionIcon} title={'説明表示'} width="12px" height="12px" onClick={onClickWorkTime} />
            </ItemQuestion>
          </SettingItem>
          <SettingItem style={{marginTop:'1rem'}}>
            <ItemTitle>睡眠スコア</ItemTitle>
            <Input type='text' name='sleepScore' width='8rem' style={{textAlign:'right', paddingRight: '1rem'}} inputRef={sleepScoreRef} />
            <ItemUnit>点</ItemUnit>
            <ItemCheck>
              <input
                type='checkbox'
                id='sleepScore'
                checked={sleepScore}
                onChange={e => changeSleepScore(e.target.checked)}
              />
              利用
            </ItemCheck>
            <ItemQuestion>
              <img alt='詳細' className='close' src={QuestionIcon} title={'説明表示'} width="12px" height="12px" onClick={onClickSleepScore} />
            </ItemQuestion>
          </SettingItem>
          <SettingItem style={{marginTop:'1rem'}}>
            <ItemTitle>睡眠時間</ItemTitle>
            <Input type='text' name='sleepScore' width='8rem' style={{textAlign:'right', paddingRight: '1rem'}} inputRef={sleepTimeRef} />
            <ItemUnit>h</ItemUnit>
            <ItemCheck>
              <input
                type='checkbox'
                id='sleepTime'
                checked={sleepTime}
                onChange={e => changeSleepTime(e.target.checked)}
              />
              利用
            </ItemCheck>
            <ItemQuestion>
              <img alt='詳細' className='close' src={QuestionIcon} title={'説明表示'} width="12px" height="12px" onClick={onClickSleepTime} />
            </ItemQuestion>
          </SettingItem>
          <SettingItem style={{marginTop:'1rem'}}>
            <ItemTitle>疲労度</ItemTitle>
            <Input type='text' name='sleepScore' width='8rem' style={{textAlign:'right', paddingRight: '1rem'}} inputRef={exerciseIntensityRef} />
            <ItemUnit>点</ItemUnit>
            <ItemCheck>
              <input
                type='checkbox'
                id='exerciseIntensity'
                checked={exerciseIntensity}
                onChange={e => changeExerciseIntensity(e.target.checked)}
              />
              利用
            </ItemCheck>
            <ItemQuestion>
              <img alt='詳細' className='close' src={QuestionIcon} title={'説明表示'} width="12px" height="12px" onClick={onClickExerciseIntensity} />
            </ItemQuestion>
          </SettingItem>
          <SettingItem style={{marginTop:'1rem'}}>
            <ItemTitle>心拍数（下）</ItemTitle>
            <Input type='text' name='sleepScore' width='8rem' style={{textAlign:'right', paddingRight: '1rem'}} inputRef={heartRateUnderRef} />
            <ItemUnit>bpm</ItemUnit>
            <ItemCheck>
              <input
                type='checkbox'
                id='exerciseIntensity'
                checked={heartRateUnder}
                onChange={e => changeHeartRateUnder(e.target.checked)}
              />
              利用
            </ItemCheck>
            <ItemQuestion>
              <img alt='詳細' className='close' src={QuestionIcon} title={'説明表示'} width="12px" height="12px" onClick={onClickHeartRate} />
            </ItemQuestion>
          </SettingItem>
          
          <SettingItem style={{marginTop:'1rem'}}>
            <ItemTitle>心拍数（上）</ItemTitle>
            <Input type='text' name='sleepScore' width='8rem' style={{textAlign:'right', paddingRight: '1rem'}} inputRef={heartRateUpperRef} />
            <ItemUnit>bpm</ItemUnit>
            <ItemCheck>
              <input
                type='checkbox'
                id='exerciseIntensity'
                checked={heartRateUpper}
                onChange={e => changeHeartRateUpper(e.target.checked)}
              />
              利用
            </ItemCheck>
            <ItemQuestion>
              <img alt='詳細' className='close' src={QuestionIcon} title={'説明表示'} width="12px" height="12px" onClick={onClickHeartRate} />
            </ItemQuestion>
          </SettingItem>
          <div style={{width:'100%', marginTop:'1rem', textAlign:'right'}}>
            <div style={{width:'150px', marginLeft:'auto', marginRight:'0px'}}>
              <NormalButton type='button' onClick={onClickRefer} width='100px' name='反映' />
            </div>
          </div>
        </Wrap>
      </div>
      <ExplainWorkTimeModal
        modalIsOpen={openDialog1}
        setIsOpen={setOpenDialog1}
      />
      <ExplainSleepScoreModal
        modalIsOpen={openDialog5}
        setIsOpen={setOpenDialog5}
      />
      <ExplainSleepTimeModal
        modalIsOpen={openDialog6}
        setIsOpen={setOpenDialog6}
      />
      <ExplainExerciseIntensityModal
        modalIsOpen={openDialog7}
        setIsOpen={setOpenDialog7}
      />
      <ExplainHeartRateModal
        modalIsOpen={openDialog8}
        setIsOpen={setOpenDialog8}
      />
    </Modal>
  )
}

AlertSettingModal.propTypes = {
  grants: PropTypes.object, // 権限関連
  modalIsOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
}

export default AlertSettingModal

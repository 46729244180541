import React,{useState,useRef,useEffect} from 'react'
//import styled from 'styled-components'
import { useHistory } from "react-router-dom"
import { batch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import Tooltip from '@material-ui/core/Tooltip'

import FUNC from '../../Common/FunctionEnum'
//import { PostApi } from '../../../Common/ApiAxios'

import Select from '../../Common/Atoms/Select'
import SearchCalendar from '../../Common/Atoms/Calendar'

import * as LogisticUtility from '../../Common/Utilties/LogisticUtility'

import SearchWrap ,{ SubSearchContent, SubSearchContentRow,FunctionContent, FunctionContentRow, FunctionIcon, FunctionName} from '../Wrap/BoardSerachWrap'

import IconCollapseImg from '../../../image/Collapse.png'
import IconFunctionImg from '../../../image/three-reader-vertical-icon.png'
import IconTopImg from '../../../image/menu-icon3.png'
import IconReturnImg from '../../../image/return-icon.png'
import IconInquiryImg from '../../../image/inquiry-icon.png'
import IconSearchImg from '../../../image/search-icon.png'
import IconCloseImg from '../../../image/close_icon.png'
import IconDownloadImg from '../../../image/download-icon.png'
import Input from '../../Common/Atoms/Input'
import Button from '../../Common/Atoms/NormalButton'

const Search = (props) => {
  const {
    addStyle,
    onClickFunction,
    onCloseFunction,
    onClickOption,
    viewSubSearch,
    viewFunction,
    searchDateRef,
    onChange,
    ownerCd,
    pattran,
    workplaceId,
    ownerName,
    onBlurCargoId,
    cargoIdRef,
    itemNameRef,
    onClear,
    pageType,
    batchNo,
    onExport,
    graphTypeRef,
    onChangeType
  } = props
  const history = useHistory();
  const loginUserInfo = useSelector(state => state.loginUserInfo);
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC);
  const subGrants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC_DASHBOARD);
  const isExternalUser = LogisticUtility.checkExternalUser(loginUserInfo.grantNameId);
  const changeCalendar = (date)=>{
    searchDateRef.current = date
    onChange(date)
  }
  const workplaceRef = useRef();
  const [workplaceList, setWorkplaceList] = useState([
    {id:'000001', name:'AFS南関東センター', url:'/logistic/vasKantouPerformance'}
    , {id:'000006', name:'AFS尾西センター', url:'/logistic/vasPerformance'}
  ]);

  const [ownerList, setOwnerList] = useState([
    {id:'1', name:'尾西'},{id:'2', name:'MV静岡'},{id:'3', name:'MV東海'}
  ]);

  const [pattranList, setPattranList] = useState([
    {id:undefined, name:'-'},{id:'米総リテール', name:'米総リテール'},{id:'米飯　BIG北・信', name:'米飯　BIG北・信'},{id:'米飯　BIG中松愛', name:'米惣 中松愛'}
  ]);

  const [graphTypeList, setGraphTypeList] = useState([
    {id:'1', name:'日'},{id:'2', name:'週'},{id:'3', name:'月'}
  ]);

  const onChangeWorkplace = (e) => {
    //console.log(workplaceRef.current.value);

    let id = workplaceRef.current.value;
    let workplace = workplaceList.find(element => element.id === id);
    if (workplace) {
      switch(id){
        case '000001':
          history.push('/logistic/vasKantouPerformance');
          break;
        case '000011':
          history.push('/logistic/sorterPerformance');
          break;
        case '000006':
          history.push('/logistic/vasPerformance');
          break;
        case '000284':
          history.push('/logistic/syouwaPerformance');
          break;
        case '000256':
          history.push('/logistic/narashinoPerformance');
          break;
        default:
          break
      }
    }
  }

  async function topPage () {
    history.push('/top');
  }
  async function vasPerformance () {
    var state = {searchDate:searchDateRef.current}
    var pathname = '/logistic/vasPerformance'
    workplaceList.map((value)=>{
      if(value.id == workplaceId) pathname = value.url
    })
    history.push({state:state,pathname:pathname});
  }
  async function vasSummary () {
    var state = {searchDate:searchDateRef.current,workplaceId:'000001',baseId:1}
    history.push({state:state,pathname:'/logistic/vasSummary'});
    //history.push('/logistic/vasSummary');
  }

  async function vasPersonal () {
    var state = {searchDate:searchDateRef.current,workplaceId:'000001',baseId:1}
    history.push({state:state,pathname:'/logistic/vasPersonal'});
    //history.push('/logistic/vasPersonal');
  }
  async function vasRealTime () {
    var state = {searchDate:searchDateRef.current,workplaceId:'000001',baseId:1}
    history.push({state:state,pathname:'/logistic/vasRealTimeProgress'});
    //history.push('/logistic/vasRealTimeProgress');
  }

  /*
  * CSV出力用
  */
  async function toExport () {
    var workplaceName = ''
    if(workplaceRef.current && workplaceRef.current.selectedOptions 
      && workplaceRef.current.selectedOptions.length)
       workplaceName = workplaceRef.current.selectedOptions[0].innerText
    onExport(workplaceName)
  }

  useEffect(() => {
    if (ownerName) {
      setOwnerList(
        [{id:ownerCd, name:ownerName}]
      );
    }
  }, [ownerName]);

  if (!grants && !subGrants) return null;
  return (
    <SearchWrap addStyle={addStyle} viewSubSearch={viewSubSearch} viewFunction={viewFunction}>
      <h3>{grants.functionMenuName}</h3>
      <img alt='階層' src={IconCollapseImg} width="24px" height="24px" style={{marginTop:'4px'}}/>
      <ul className='mainSearch'>
        <li>
          <div className='subTitleItem' style={{fontSize:'16px',margin:'8px 0 0 12px'}}>{'カゴ車実績照会'}</div>
          <div style={{margin:'0 0px 0 0'}}>
            <Select selectRef={workplaceRef} onChange={onChangeWorkplace} width={'11rem'} disabled>
              {workplaceList.map(item => (
                <option key={'work_'+item.id} value={item.id} selected={item.id==workplaceId}>{item.name}</option>
              ))}
            </Select>
          </div>
          <div style={{fontSize:'16px',margin:'8px 0 0 0px'}}>{'店着日付'}</div>
          <div style={{margin:'0 0 0 0'}}>
            <SearchCalendar changeCalendar={changeCalendar} dataValue={searchDateRef.current} isViewIcon disabled={!pageType}/>
          </div>
          <div style={{fontSize:'16px',margin:'8px 0 0 0px'}}>{'荷主'}</div>
          <div style={{margin:'0 0 0 0'}}>
            <Select width={'7rem'} disabled>
              {ownerList.map(item => (
                <option key={'ownerCd_'+item.id} value={item.id} selected={item.id==ownerCd}>{item.name}</option>
              ))}
            </Select>
          </div>
          <div style={{fontSize:'16px',margin:'8px 0 0 0px'}}>{'パターン'}</div>
          <Select width={'11rem'} disabled>
            <option key={'pattranCd_'+0} value={0} selected={true}>{pattran}</option>
          </Select>
          <div style={{fontSize:'16px',margin:'8px 0 0 0px'}}>{'運行便数'}</div>
          <Select width={'5rem'} disabled>
            <option key={'batchNo_'+0} value={0} selected={true}>{batchNo+'便'}</option>
          </Select>
          <div style={{fontSize:'16px',margin:'8px 0 0 0px'}}>{'グラフ表示'}</div>
          <div style={{margin:'0 0 0 0'}}>
            <Select width={'5rem'} selectRef={graphTypeRef} onChange={onChangeType}>
              {graphTypeList.map(item => (
                <option key={'type_'+item.id} value={item.id}>{item.name}</option>
              ))}
            </Select>
          </div>
          <Tooltip title={'検索オプション'}>
            <img alt='検索オプション' src={IconSearchImg} width="24px" height="24px" style={{ marginTop: '4px',cursor: 'pointer' }} onClick={onClickOption} />
          </Tooltip>
        </li>
        <li className='subSearch'>
        <SubSearchContent>
            <SubSearchContentRow style={{textAlign:'right', marginLeft:'auto'}}>
                <img alt='閉じる' 
                  src={IconCloseImg} 
                  width="12px" 
                  height="12px" 
                  style={{cursor: 'pointer' }} 
                  onClick={onClickOption} />
            </SubSearchContentRow>
            <SubSearchContentRow>
              <label style={{width:'5rem'}}>カゴ車ID</label>
              <Input inputRef={cargoIdRef} width={'22rem'}/>
            </SubSearchContentRow>
            <SubSearchContentRow>
              <label style={{width:'5rem'}}>アイテム名称</label>
              <Input inputRef={itemNameRef} width={'22rem'}/>
            </SubSearchContentRow>
            <SubSearchContentRow style={{textAlign:'right', marginLeft:'auto', marginRight:'1.5rem' }}>
              <Button type='button' margin='0 0 0 16px' name='クリア' padding='0px' onClick={onClear} />
              <Button type='button' margin='0 0 0 16px' name='検索' padding='0px' onClick={onBlurCargoId} />
            </SubSearchContentRow>
          </SubSearchContent>
        </li>
      </ul>
      <div></div>
      <Tooltip title={'機能メニュー'}>
        <img alt='機能メニュー' src={IconFunctionImg} width="24px" height="24px" style={{marginTop: '4px',marginLeft: 'auto', cursor: 'pointer'}} onClick={onClickFunction} />
      </Tooltip>
      <li className='mainFunction'>
        <FunctionContent onMouseLeave={onCloseFunction}>
          {!isExternalUser &&
          <>
          <FunctionContentRow onClick={topPage} style={{marginTop:'12px'}}>
            <FunctionIcon alt='トップページ' src={IconTopImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>トップページ</span></FunctionName>
          </FunctionContentRow>
          <hr></hr>
          </>
          }
          <FunctionContentRow onClick={toExport} style={{marginTop:isExternalUser?'12px':'0px'}}>
            <FunctionIcon alt='ダウンロード' src={IconDownloadImg}/>
            <FunctionName><span style={{marginLeft:'0.5rem'}}>CSVダウンロード</span></FunctionName>
          </FunctionContentRow>
          <hr></hr>
          {!!!pageType &&
            <FunctionContentRow onClick={vasPerformance} style={{marginBottom:'12px'}}>
              <FunctionIcon alt='戻る' src={IconReturnImg}/>
              <FunctionName><span style={{marginLeft:'0.5rem'}}>戻る</span></FunctionName>
            </FunctionContentRow>
          }
          {!!pageType &&
          <>
            <FunctionContentRow onClick={vasSummary} style={{marginTop:'12px'}}>
              <FunctionIcon alt='VAS運用状況' src={IconInquiryImg}/>
              <FunctionName><span style={{marginLeft:'0.5rem'}}>VAS運用状況</span></FunctionName>
            </FunctionContentRow>
            <FunctionContentRow onClick={vasRealTime} style={{marginTop:'12px'}}>
              <FunctionIcon alt='作業工程実績照会' src={IconInquiryImg}/>
              <FunctionName><span style={{marginLeft:'0.5rem'}}>作業工程実績照会</span></FunctionName>
            </FunctionContentRow>
            <FunctionContentRow onClick={vasPerformance} style={{marginTop:'12px'}}>
              <FunctionIcon alt='種まきデータ実績照会' src={IconInquiryImg}/>
              <FunctionName><span style={{marginLeft:'0.5rem'}}>種まきデータ実績照会</span></FunctionName>
            </FunctionContentRow>
            <FunctionContentRow onClick={vasPersonal} style={{marginTop:'12px',marginBottom:'12px'}}>
              <FunctionIcon alt='個人作業実績照会' src={IconInquiryImg}/>
              <FunctionName><span style={{marginLeft:'0.5rem'}}>個人作業実績照会</span></FunctionName>
            </FunctionContentRow>
            <FunctionContentRow style={{marginTop:'12px',marginBottom:'12px'}}>
              <FunctionIcon alt='カゴ車実績照会' src={IconInquiryImg}/>
              <FunctionName><span style={{marginLeft:'0.5rem'}}>個人作業実績照会</span></FunctionName>
            </FunctionContentRow>
          </>
          }

        </FunctionContent>
      </li>
    </SearchWrap>
  )
}

Search.propTypes = {
  addStyle: PropTypes.string, // css用追加用
  onClickFunction: PropTypes.func,
  onCloseFunction: PropTypes.func,
  viewSubSearch: PropTypes.bool, // サブ検索項目を表示するか
  viewFunction: PropTypes.bool, // サブ機能を表示するか
}

export default Search

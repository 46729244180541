import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const MediaWrap = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  background-color: #649CDF;
  color: #fff;
  font-size: ${props => props.dialog ? '1.3vw;': '0.7vw;'};
  text-align: center;
  align-items: center;
  width: ${props => props.dialog ? '1084px': 'calc(100% - 17px)'};
  margin-left: 4px;
`
const MediaItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 45px;
  border-right: ${props => props.borderNone ? '': '1px solid #000'};
`

const TextWrap = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
` 

function getTitle(type) {
  var title = '';
  switch(type) {
    case 0:
      title = '予定時刻';
      break;
    case 1:
      title = '作業時間';
      break;
    case 2:
      title = '待機時間';
      break;
    default:
      title = '';
      break;
  }

  return title;
}

const MaterialHandlingItem = (props) => {
  const {
    searchDate,
    data,type,dialog
  } = props

  // 初期値
  useEffect(() => {
  })

  //if (!data) return null;
  //console.log(data);
  return (
    <MediaWrap dialog={dialog}>
      <MediaItem>
        <TextWrap style={{width:dialog?'84px':'42px', textAlign:'center'}}>
          <div>{'No'}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:dialog?'200px':'100px', textAlign:'center'}}>
          <div>{'会社名'}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:dialog?'200px':'100px', textAlign:'center'}}>
          <div>{'ドライバー氏名'}</div>
        </TextWrap>
      </MediaItem>
      {type!=1 &&
        <>
          <MediaItem>
            <TextWrap style={{width:dialog?'400px':'200px', textAlign:'center'}}>
              <div>{'店舗名'}</div>
            </TextWrap>
          </MediaItem>
        </>
      }
      {type==1 &&
        <>
          <MediaItem>
            <TextWrap style={{width:dialog?'200px':'98px', textAlign:'center'}}>
              <div>{'店舗名'}</div>
            </TextWrap>
          </MediaItem>
          <MediaItem>
            <TextWrap style={{width:dialog?'200px':'98px', textAlign:'center'}}>
              <div>{'作業種別'}</div>
            </TextWrap>
          </MediaItem>
        </>
      }
      <MediaItem borderNone={true}>
        <TextWrap style={{width:dialog?'180px':'80px', textAlign:'center'}}>
          <div>{getTitle(type)}</div>
        </TextWrap>
      </MediaItem>
    </MediaWrap>
  )
}

MaterialHandlingItem.propTypes = {
}

export default MaterialHandlingItem

import React, { useState, useEffect, useRef } from 'react'
//import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { GetApi } from '../../../Common/ApiAxios'

const DefaultUrl = '/api/logisticManagement'

const BoxWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;

  color: #000;
`

const HeaderContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  text-align:center;
  vertical-align:middle;

  color: #fff;
`

const BodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
`

const FooterContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  overflow: hidden;
  text-align: left;
  vertical-align: middle;
`

const HeaderProgress = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 100%;
  height: 3vh;
  background-color: #25b837;
  border-radius: 10px;
  border: none; 

  text-align:center;
  vertical-align:middle;
`

const HeaderCost = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 100%;
  height: 3vh;
  background-color: #ff9900;
  border-radius: 10px;
  border: none; 
  
  text-align:center;
  vertical-align:middle;
  margin-top: 5px;
`

const Process = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 12vh;
  background-color: #eeeeee;
  border-radius: 10px;
  border: none; 

  text-align:center;
  vertical-align:middle;
  margin-top: 5px;
  margin-bottom: 5px;

  color: #000;
`

const ProcessName = styled.div`
  font-weight: bold;
`

const ProcessSubItem = styled.div`
  margin-top: 5px;
  color: #00f;
`
const FooterItem = styled.div`
display: flex; /* 横並び */
flex-wrap: wrap;
width: 100%;
`

const FooterName = styled.div`
  width: 4rem;
  font-weight: bold;
`

function getProgressRate(data) {
  if (data === undefined || data === null)
    return '0.0';

  return data.progressRate;
}

function getLaborCost(data) {
  if (data === undefined || data === null)
    return '0';

  return data.laborCost;
}

function getName(data) {
  if (data === undefined || data === null)
    return '';

  return data.itemName;
}

function getPlanStaffNum(data) {
  if (data === undefined || data === null)
    return '0';

  return data.planStaffNum;
}
function getResultStaffNum(data) {
  if (data === undefined || data === null)
    return '0';

  return data.resultStaffNum;
}

function getRequiredStaffNum(data) {
  if (data === undefined || data === null)
    return '0';

  return data.requiredStaffNum;
}

function getActiveStaffRate(data) {
  if (data === undefined || data === null)
    return '0.0';

  if (data.planStaffNum === 0)
    return '---.-';

  return data.resultStaffNum / data.planStaffNum;
}

function getLaborProductivity(data) {
  if (data === undefined || data === null)
    return '0.0';

  return data.laborProductivity;
}

function getPlanEndDt(data) {
  if (data === undefined || data === null)
    return '--:--';

  return '--:--';
}

// 工程
const ProcessBox = (props) => {
  const {
    grants,
    settingInfo,
    requestClearInterval,
  } = props
  //const alert = useAlert();
  const intervalRef = useRef(null);
  const [process,setProcess] = useState(null);

  // 周期初期のクリア
  const onClearInerval = async () => {
    if (intervalRef.current !== null)
      clearInterval(intervalRef.current);
    
      intervalRef.current = null;
  }

  // 工程進捗情報の取得
  const apiGetProcessProgress = async () => {
    //console.log('call apiGetProcessProgress')
    //dispatch(SetLoadSpinner(true))

    if (requestClearInterval && intervalRef.current !== null) {
      clearInterval(intervalRef.current);
      return;
    }
    if (settingInfo === undefined || settingInfo === null)
      return;

    const apiUrl = DefaultUrl + '/getProgressBoardProcessInfo';
    const param = {
      workplaceId: settingInfo.workplaceId,
      progressId: settingInfo.progressId,
      itemId: settingInfo.itemId,
    }

    const result = await GetApi(apiUrl, param);

    //dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('工程進捗情報取得失敗');
      setProcess(null);
    } else {
      //console.log(result);
      setProcess(result.data);
    }
  }

  // 描画時に一度だけ実行
  useEffect(() => {
    
    intervalRef.current = setInterval(() => {
      apiGetProcessProgress()
    }, 1000 * 60); //60秒間隔で工程進捗を取得
  
    return () => {
      onClearInerval();
    };
  }, [])

  //if (!grants || !process) return null
  return (
    <BoxWrap>
      <HeaderContent>
        <HeaderProgress><div style={{margin:'auto'}}>{getProgressRate(process)} %</div></HeaderProgress>
        <HeaderCost><div style={{margin:'auto'}}>{getLaborCost(process)} 千円</div></HeaderCost>
      </HeaderContent>
      <BodyContent>
        <Process>
          <div style={{margin:'auto'}}>
            <ProcessName>{getName(settingInfo)}</ProcessName>
            <ProcessSubItem>{getResultStaffNum(process)}／{getPlanStaffNum(process)}</ProcessSubItem>
            <ProcessSubItem>(人数)</ProcessSubItem>
          </div>
        </Process>
      </BodyContent>
      <FooterContent>
        <FooterItem><FooterName>必要工数</FooterName>：{getRequiredStaffNum(process)}名</FooterItem>
        <FooterItem><FooterName>稼働率</FooterName>：{getActiveStaffRate(process)}%</FooterItem>
        <FooterItem><FooterName>生産性</FooterName>：{getLaborProductivity(process)}%</FooterItem>
        <FooterItem><FooterName>終了予定</FooterName>：{getPlanEndDt(process)}</FooterItem>
      </FooterContent>
    </BoxWrap>
  )
}

ProcessBox.propTypes = {
  grants: PropTypes.object, // 権限関連
  settingInfo: PropTypes.object, // 設定情報
  requestClearInterval: PropTypes.object, 
}
  
export default ProcessBox

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
//import { useAlert } from 'react-alert'
//import { useForm } from 'react-hook-form'
import { useHistory } from "react-router-dom"
import styled from 'styled-components'
//import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
//import { useSyncExternalStore } from 'react'
import { Wrapper } from "@googlemaps/react-wrapper";
import {XAxis, YAxis, Tooltip, Legend, Line, Bar,ComposedChart } from "recharts"

import { GetApi } from '../Common/ApiAxios'
import Select from '../Common/Atoms/Select'
import { SetLoadSpinner } from '../Common/Slice/LoadSpinnerSlice'
//import FUNC from '../Common/FunctionEnum'
import * as HealthCareUtilty from '../Common/Utilties/HealthCareUtilty'

import PinBlueIcon from '../../image/map-pin-blue-icon.png'
import PinGrayIcon from '../../image/map-pin-gray-icon.png'
import PinRedIcon from '../../image/map-pin-red-icon.png'

//import LogisticCard from './Atoms/LogisticCard'
//import TruckCard from './Atoms/TruckCard'

//import NormalButton from '../Common/Atoms/NormalButton'
//import CoreTimeCard from './Atoms/CoreTimeCard'
//import CoreFirstCard from './Atoms/CoreFirstCard'
import InfoCard from './Atoms/InfoCard'
import UpperRightKPIInfoCard from './Atoms/UpperRightKPIInfoCard'
import KPIInfoCard from './Atoms/KPIInfoCard'
import Map from "./Atoms/Map";
import MyMarker from "./Atoms/Marker";

import BoardBaseWrap, {BoardBaseFormRow} from './Wrap/BoardBaseWrap'
//import { unstable_renderSubtreeIntoContainer } from 'react-dom';

const DefaultUrl = '/api/top'

const Wrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
`

const Content = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 100%;
`

const GraphContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
`

const ProcessTitle = styled.div`
  font-weight: bold;
  color: #000;
  text-align: center;
  font-size: 1.2rem;
`

// divisionId:事業部 1:ロジスティック 2:運送
// areaId:エリア 1:関東 2:中部 3:関西
const workplacesOrg = [
  {
    id:'000001',
    name:'AFS南関東センター\r\n千葉県船橋市高瀬町24-12',
    divisionId:1,
    postId:1,
    areaId:1,
    position:{lat:35.671704, lng:139.9926181},
  },
  {
    id:'000003',
    name:'UNIQLO仙台\r\n宮城県仙台市泉区明通2-9-5',
    divisionId:1,
    postId:1,
    areaId:1,
    position:{lat:38.3563603, lng:140.8449055},
  },
  {
    id:'000004',
    name:'メニコン北ロジ\r\n宮城県柴田郡大河原町金ケ瀬中川原83',
    divisionId:1,
    postId:1,
    areaId:1,
    position:{lat:38.0404592, lng:140.7048669},
  },
  {
    id:'000126',
    name:'東急ストア\r\n神奈川県川崎市川崎区東扇島23-4',
    divisionId:1,
    postId:1,
    areaId:1,
    position:{lat:35.4998254, lng:139.7676545},
  },
  {
    id:'000256',
    name:'AFSランドポート習志野センター\r\n千葉県習志野市茜浜3-7-2',
    divisionId:1,
    postId:1,
    areaId:1,
    position:{lat:35.65741905437664, lng:139.99108892533454},
  },
  {
    id:'000284',
    name:'昭和冷蔵 厚木物流センター\r\n神奈川県厚木市上依知字上野原3007-9',
    divisionId:1,
    postId:1,
    areaId:1,
    position:{lat:35.5178521, lng:139.3518361},
  },
  {
    id:'000006',
    name:'AFS尾西センター\r\n愛知県一宮市明地字南茱之木25-1',
    divisionId:1,
    postId:2,
    areaId:2,
    position:{lat:35.2869532, lng:136.7367265},
  },
  {
    id:'000008',
    name:'メニコン笠寺\r\n愛知県名古屋市南区立脇町3-3-1',
    divisionId:1,
    postId:2,
    areaId:2,
    position:{lat:35.0945899, lng:136.9256705},
  },
  {
    id:'000010',
    name:'ヤマナカ\r\n愛知県名古屋市港区潮凪町1-3',
    divisionId:1,
    postId:2,
    areaId:2,
    position:{lat:35.0797409, lng:136.8592792},
  },
  /*
  {
    id:'000173',
    name:'フェリシモアネックス\r\n兵庫県神戸市須磨区弥栄台2-5-3',
    divisionId:1,
    postId:3,
    areaId:3,
    position:{lat:34.6835848, lng:135.0712708},
  },
  */
  {
    id:'000011',
    name:'福通 神戸カインズセンター\r\n兵庫県神戸市須磨区弥栄台4-1-1',
    divisionId:1,
    postId:3,
    areaId:3,
    position:{lat:34.6910852, lng:135.0663249},
  },
  {
    id:'000013',
    name:'AFS姫路センター\r\n兵庫県姫路市白浜町甲841-51',
    divisionId:1,
    postId:3,
    areaId:3,
    position:{lat:34.778214, lng:134.7037571},
  },
  {
    id:'000016',
    name:'福通 福岡カインズ\r\n福岡県糟屋郡粕屋町江辻18-1',
    divisionId:1,
    postId:3,
    areaId:3,
    position:{lat:33.6269229, lng:130.4816803},
  },
  {
    id:'000127',
    name:'尾家産業\r\n大阪府貝塚市二色中町5-1',
    divisionId:1,
    postId:3,
    areaId:3,
    position:{lat:34.4546367, lng:135.347756},
  },
  {
    id:'000302',
    name:'メディエントランス\r\大阪府箕面市森町西2-4-1',
    divisionId:1,
    postId:3,
    areaId:3,
    position:{lat:34.8844165, lng:135.4436274},
  },
  {
    id:'000179',
    name:'運送事業部\r\n千葉県習志野市谷津5-23-12',
    divisionId:2,
    postId:23,
    areaId:1,
    position:{lat:35.6892959, lng:140.0085782},
  },
];
var workplacesLet = workplacesOrg;

function getPinColor(id) {
  /*
  if (postId == 1) {
    return "1e90ff";
  } else if (postId == 2) {
    return "87cefa";
  } else if (postId == 3) {
    return "afeeee";
  } else if (postId == 4) {
    return "90ee90"
  } else if (postId == 5) {
    return "00fa9a"
  } else if (postId == 6) {
    return "7fff00"
  } else if (postId == 7) {
    return "daa520"
  } else if (postId == 8) {
    return "ffd700"
  } else if (postId == 9) {
    return "f0e68c"
  } else if (postId == 10) {
    return "ff7f50"
  } else if (postId == 11) {
    return "ffc0cb"
  } else if (postId == 12) {
    return "ee82ee"
  }
  */
  switch(id){
    case '000179':
      return "1e90ff";
    case '000001':
    case '000006':
    case '000011':
    case '000256':
      return "ff3366";
    default:
      return "cccccc";
  }  
  // if (postId == 23) {
  //   //return "ee82ee";
  //   return "1e90ff";
  // } 

  // return "ff3366";
}

function getPin(id) {
  switch(id){
    case '000179':
      return PinBlueIcon;
    case '000001':
    case '000006':
    case '000011':
    case '000256':
      return PinRedIcon;
    default:
      return PinGrayIcon;
  }
}

function getPinImg(id) {
  if (!window.google)
    return null;
  /*
  return new window.google.maps.MarkerImage("http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|" + getPinColor(id),
    new window.google.maps.Size(21, 34),
    new window.google.maps.Point(0, 0),
    new window.google.maps.Point(10, 34));
  */
  return getPin(id);
}

function getPinShadow() {
  if (!window.google)
    return null;

  return new window.google.maps.MarkerImage("http://chart.apis.google.com/chart?chst=d_map_pin_shadow",
    new window.google.maps.Size(40, 37),
    new window.google.maps.Point(0, 0),
    new window.google.maps.Point(12, 35));
}
  
function getSumSalesCost(list, max) {
  var cost = 0;
  for(var i = 0; i <= max; i++)
    cost += list[i].salesCost;

  return cost;
}

// 右上表示項目の設定
const orgItemInfos = [
  {itemId:0, showFlag:true, sort:0}
 ,{itemId:1, showFlag:true, sort:1}
 ,{itemId:2, showFlag:true, sort:2}
];

const Top = (props) => {
  const { loginUserInfo } = useSelector(state => state);
  const dispatch = useDispatch();
  //const alert = useAlert()
  const history = useHistory();
  const [workplaceName,setWorkplaceName] = useState('');
  const [summaryInfo,setSummaryInfo] = useState(null);
  const [monthlySummaryInfo,setMonthlySummaryInfo] = useState([]);
  const [maxSaleCost, setMaxSaleCost] = useState(0);
  const [workplaces, setWorkplaces] = useState(workplacesOrg);
  const [filterDivision, setFilterDivision] = useState(0);
  const [filterArea, setFilterArea] = useState(0);
  const [itemInfos,setItemInfos] = useState(null);
  const [openDialog,setOpenDialog] = useState(false);
  
  //前月の情報
  const [beforeSummaryInfo,setBeforeSummaryInfo] = useState(null);

  const apiGetDailySummary = async (isLoadSplinner) => {
    //console.log('call apiGetDailySummary');

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getWorkplaceDailySummary';
    
    const data = {
      targetYMD: HealthCareUtilty.getStringFromDate(new Date(), 'YYYY-MM-DD'),
    }
    const result = await GetApi(apiUrl, data);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      //alert.error('条件の該当データがありません(日付の現場情報を取得)');
    } else {
      //console.log(result.data);

      var budgetSalesCost = 0;
      var salesCost = 0;
      var operatingIncome = 0;
      var profitRate = 0;
      var budgetAchievementRate = 0;
      var salesMonthToMonth = 0;
      var unachievedWorkplaceNum = 0;
      var staffNum = 0;
      var laborCost = 0;
      var recruitmentCost = 0;
      var totalWorkTime = 0;
      var totalLengthOfService = 0;
      //var avgWage = 0;
      //var avgLengthOfService = 0;
      var accidentNum = 0;
      var accidentPaymentAmount = 0;

      var count = 0;
      result.data.forEach(item => {
        if (workplacesLet.find((e) => e.id === item.workplaceId)) {

          count++;

          budgetSalesCost += item.budgetSalesCost;
          salesCost += item.salesCost;
          operatingIncome += item.operatingIncome;
          profitRate += item.profitRate;
          budgetAchievementRate += item.budgetAchievementRate;
          salesMonthToMonth += item.salesMonthToMonth;
          unachievedWorkplaceNum += item.unachievedWorkplaceNum;
          staffNum += item.staffNum;
          laborCost += item.laborCost;
          recruitmentCost += item.recruitmentCost;
          totalWorkTime += item.totalWorkTime;
          totalLengthOfService += item.totalLengthOfService;
          accidentNum += item.accidentNum;

          accidentPaymentAmount += item.accidentPaymentAmount;
        }
      });

      setSummaryInfo({
        budgetSalesCost: budgetSalesCost,
        salesCost: salesCost,
        operatingIncome: operatingIncome,
        profitRate: count === 0 ? 0 : profitRate / count,
        budgetAchievementRate: count === 0 ? 0 : budgetAchievementRate / count,
        salesMonthToMonth: count === 0 ? 0 : salesMonthToMonth / count,
        unachievedWorkplaceNum: unachievedWorkplaceNum,
        staffNum: staffNum,
        laborCost: laborCost,
        recruitmentCost: recruitmentCost,
        avgWage: totalWorkTime === 0 ? 0 : laborCost / totalWorkTime,
        avgLengthOfService: staffNum === 0 ? 0 : totalLengthOfService / staffNum,
        accidentNum: accidentNum,
        accidentPaymentAmount: accidentPaymentAmount,
      });
    }
  }
  const apiGetDailyBeforeSummary = async (isLoadSplinner) => {
    //console.log('call apiGetDailySummary');

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getWorkplaceDailySummary';
    let date = new Date();
    date.setMonth(date.getMonth(), 0);//先月末の作成
    const data = {
      targetYMD: HealthCareUtilty.getStringFromDate(date, 'YYYY-MM-DD'),
    }
    const result = await GetApi(apiUrl, data);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      //alert.error('条件の該当データがありません(日付の現場情報を取得)');
    } else {
      //console.log(result.data);

      var budgetSalesCost = 0;
      var salesCost = 0;
      var operatingIncome = 0;
      var profitRate = 0;
      var budgetAchievementRate = 0;
      var salesMonthToMonth = 0;
      var unachievedWorkplaceNum = 0;
      var staffNum = 0;
      var laborCost = 0;
      var recruitmentCost = 0;
      var totalWorkTime = 0;
      var totalLengthOfService = 0;
      //var avgWage = 0;
      //var avgLengthOfService = 0;
      var accidentNum = 0;
      var accidentPaymentAmount = 0;

      var count = 0;
      result.data.forEach(item => {
        if (workplacesLet.find((e) => e.id === item.workplaceId)) {

          count++;

          budgetSalesCost += item.budgetSalesCost;
          salesCost += item.salesCost;
          operatingIncome += item.operatingIncome;
          profitRate += item.profitRate;
          budgetAchievementRate += item.budgetAchievementRate;
          salesMonthToMonth += item.salesMonthToMonth;
          unachievedWorkplaceNum += item.unachievedWorkplaceNum;
          staffNum += item.staffNum;
          laborCost += item.laborCost;
          recruitmentCost += item.recruitmentCost;
          totalWorkTime += item.totalWorkTime;
          totalLengthOfService += item.totalLengthOfService;
          accidentNum += item.accidentNum;

          accidentPaymentAmount += item.accidentPaymentAmount;
        }
      });

      setBeforeSummaryInfo({
        budgetSalesCost: budgetSalesCost,
        salesCost: salesCost,
        operatingIncome: operatingIncome,
        profitRate: count === 0 ? 0 : profitRate / count,
        budgetAchievementRate: count === 0 ? 0 : budgetAchievementRate / count,
        salesMonthToMonth: count === 0 ? 0 : salesMonthToMonth / count,
        unachievedWorkplaceNum: unachievedWorkplaceNum,
        staffNum: staffNum,
        laborCost: laborCost,
        recruitmentCost: recruitmentCost,
        avgWage: totalWorkTime === 0 ? 0 : laborCost / totalWorkTime,
        avgLengthOfService: staffNum === 0 ? 0 : totalLengthOfService / staffNum,
        accidentNum: accidentNum,
        accidentPaymentAmount: accidentPaymentAmount,
      });
    }
  }

  const apiGetYearSummary = async (isLoadSplinner) => {
    //console.log('call apiGetYearSummary');

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getWorkplaceYearSummary';
    
    const data = {
      targetY: HealthCareUtilty.getStringFromDate(new Date(), 'YYYY'),
    }
    const result = await GetApi(apiUrl, data);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      //alert.error('条件の該当データがありません(月別の現場情報を取得)');
    } else {

      var sumBudgetSalesCost = 0.0;
      var maxCost = 0.0;
      result.data.forEach(item => {
        if (workplacesLet.find((e) => e.id === item.workplaceId)) {
          sumBudgetSalesCost += item.budgetSalesCost;
          if (maxCost < item.budgetSalesCost)
            maxCost = item.budgetSalesCost;
        }
      });

      var list =[];
      var year = HealthCareUtilty.getStringFromDate(new Date(), 'YYYY');
      for(var i = 1; i <= 12; i++) {
        var targetDt = year + "-" + i.toString().padStart( 2, '0') + "-01";

        var count = 0;
        var budgetSalesCost = 0;
        var salesCost = 0;
        var operatingIncome = 0;
        var profitRate = 0;
        var budgetAchievementRate = 0;
        var salesMonthToMonth = 0;
        var unachievedWorkplaceNum = 0;
        var staffNum = 0;
        var laborCost = 0;
        var recruitmentCost = 0;
        var totalWorkTime = 0;
        var totalLengthOfService = 0;
        //var avgWage = 0;
        //var avgLengthOfService = 0;
        var accidentNum = 0;
        result.data.forEach(item => {
          if (item.targetDt == targetDt && workplacesLet.find((e) => e.id === item.workplaceId)) {
            count++;

            budgetSalesCost += item.budgetSalesCost;
            salesCost += item.salesCost;
            operatingIncome += item.operatingIncome;
            profitRate += item.profitRate;
            budgetAchievementRate += item.budgetAchievementRate;
            salesMonthToMonth += item.salesMonthToMonth;
            unachievedWorkplaceNum += item.unachievedWorkplaceNum;
            staffNum += item.staffNum;
            laborCost += item.laborCost;
            recruitmentCost += item.recruitmentCost;
            totalWorkTime += item.totalWorkTime;
            totalLengthOfService += item.totalLengthOfService;
            accidentNum += item.accidentNum;
          }
        });

        list.push({
          targetDt: targetDt,
          budgetSalesCost: budgetSalesCost,
          salesCost: salesCost,
          operatingIncome: operatingIncome,
          salesMonthToMonth: count === 0 ? 0 : salesMonthToMonth / count,
          unachievedWorkplaceNum: unachievedWorkplaceNum,
          staffNum: staffNum,
          laborCost: laborCost,
          recruitmentCost: recruitmentCost,
          accidentNum: accidentNum,
        });
      }
      
      setMonthlySummaryInfo(list.map((item, index) => {
        return {
          targetDt: HealthCareUtilty.getStringFromDate(new Date(item.targetDt), 'MM月'),
          budgetSalesCost: (item.budgetSalesCost / (100 * 10000)).toFixed(3),
          salesCost: (item.salesCost / (100 * 10000)).toFixed(3),
          sumProfitRate: (getSumSalesCost(list, index) * 100 / sumBudgetSalesCost).toFixed(2),
          profitRate: (item.salesCost  * 100 / item.budgetSalesCost).toFixed(2),
        }
      }));

      setMaxSaleCost(maxCost / (100 * 10000))
    }
  }

  // マーカークリックイベント
  const onClickMarker = async (id) => {
    let list = workplacesLet.filter((item) => item.id === id);
    if (list.length === 0)
      return;

    switch(id) {
      case '000001':
        history.push('/logistic/vasKantouPerformance');
        break;
      case '000006':
        history.push('/logistic/vasPerformance');
        break;
      case '000011':
        history.push('/logistic/sorterPerformance');
        break;
      case '000256':
        history.push('/logistic/narashinoPerformance');
        break;
      case '000179':
        history.push('/truck');
        break;
      default:
        break;
    }
  }

  // フィルター事業部変更イベント
  const changeFilterDivision = (event) => {
    //console.log(event.target.value);

    setFilterDivision(Number(event.target.value));
  }

  // フィルターエリア変更イベント
  const changeFilterArea = (event) => {
    //console.log(event.target.value);

    setFilterArea(Number(event.target.value));
  }

  // フィルターの変更の場合
  useEffect(() => {
    var list = workplacesOrg;
    
    if (filterDivision !== 0)
      list = list.filter((item) => item.divisionId === filterDivision);
    
    if (filterArea !== 0)
      list = list.filter((item) => item.areaId === filterArea);
    
    workplacesLet = list;
    setWorkplaces(list);
    
    apiGetDailySummary();
    apiGetYearSummary();
    apiGetDailyBeforeSummary();
  }, [filterDivision, filterArea]);

  // 描画時に一度だけ実行
  useEffect(() => {
    apiGetDailySummary();
    apiGetYearSummary();
    apiGetDailyBeforeSummary();
  }, [])

  return (
    <BoardBaseWrap>
    <BoardBaseFormRow style={{height:'100%'}}>
      <Wrap>
      <InfoCard style={{marginLeft:'1rem'}}/>
      <Wrap style={{width:'100%'}}>
        <Content>
          <Wrap style={{width:'1100px', textAlign:'left', marginLeft:'1rem'}}>
            <Content style={{marginBottom:'0.5rem'}}>
              <div style={{fontSize:'16px',margin:'8px 0 0 0px'}}>事業部</div>
              <div style={{margin:'0 0 0 0'}}>
                <Select width={'12rem'} onChange={(e)=>changeFilterDivision(e)}>
                  <option key={'division_0'} value={0} selected={true}>{'ー'}</option>
                  <option key={'division_1'} value={1} >{'ロジスティック事業部'}</option>
                  <option key={'division_2'} value={2} >{'運送事業部'}</option>
                </Select>
              </div>
              <div style={{fontSize:'16px',margin:'8px 0 0 0px'}}>エリア</div>
              <div style={{margin:'0 0 0 0'}}>
                <Select width={'6rem'} onChange={(e)=>changeFilterArea(e)}>
                  <option key={'area_0'} value={0} selected={true}>{'ー'}</option>
                  <option key={'area_1'} value={1} >{'関東'}</option>
                  <option key={'area_2'} value={2} >{'中部'}</option>
                  <option key={'area_3'} value={3} >{'関西'}</option>
                </Select>
            </div>
            </Content>
            <Wrapper apiKey={"AIzaSyB9CVtmE0I6E8ZF9AH5go_FlVgkfM8YaP0"}>
            <Map>
              {workplaces.map(
                w => <MyMarker position={w.position} title={w.name} id={w.id} key={w.id}
                icon={getPinImg(w.id)}
                shadow={getPinShadow()}
                onClick={onClickMarker}
                />
                )
              }
            </Map>
          </Wrapper>
          </Wrap>
          <Wrap style={{marginLeft:'1rem', width:'730px'}}>
            <div style={{marginTop:'36px'}}>
              <UpperRightKPIInfoCard summary={summaryInfo} before={beforeSummaryInfo}/>
            </div>
            <div style={{marginTop:'20px', width:'700px'}}>
              {loginUserInfo.staffId && 
              <GraphContent>
                <ProcessTitle>月別予算実績グラフ</ProcessTitle>
                <ComposedChart 
                    width={720}
                    height={345}
                    data={monthlySummaryInfo}
                    margin={{top: 20, right: 0, left: 0, bottom: 5}}>
                      <XAxis xAxisId={1} dataKey="targetDt"/>
                      <YAxis yAxisId={1} domain={[0, maxSaleCost]} tickCount={9} label={{ value: "",fontSize:'10px', angle: -90, dx: -20 }} />
                      <YAxis yAxisId={2} domain={[0, 120]} tickCount={7} label={{ value: "",fontSize:'10px', angle: -90, dx: -20 }} orientation="right" />
                      <Tooltip />
                      <Bar type="bar" xAxisId={1} yAxisId={1} strokeWidth={2} dataKey="budgetSalesCost" name="予算(百万)" fill="#0000ff" />      
                      <Bar type="bar" xAxisId={1} yAxisId={1} strokeWidth={2} dataKey="salesCost" name="実績(百万)" fill="#ff0000" />  
                      <Line type="plainline" xAxisId={1} yAxisId={2} strokeWidth={2} dataKey="profitRate" name="達成率" stroke="#3F9C34" />    
                      <Legend/>
                </ComposedChart >
              </GraphContent>
              }
            </div>
            <div style={{marginTop:'20px'}}>{workplaceName}</div>
          </Wrap>
        </Content>
        <Content>
          <KPIInfoCard summary={summaryInfo} before={beforeSummaryInfo}/>
        </Content>
      </Wrap>
      </Wrap>
    </BoardBaseFormRow>
    </BoardBaseWrap>
  )
}

export default Top

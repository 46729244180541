import React, { useEffect,useState } from 'react'
import styled from 'styled-components'
//import PropTypes from 'prop-types'

import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'

import IconPageNextImg from '../../../../image/PageNext.png'
import IconPageBackImg from '../../../../image/PageBack.png'

const MediaWrap = styled.div`
  width:1798px;
  display: flex; /* 横並び */
  flex-wrap: wrap;
  margin: 4px;
  box-shadow: 0 0 3px 2px 'rgba(0,0,0,0.4)';
  background-color: #f2b3b3;
  color: #fff;
  font-size: 14px;
  font-weight: bold;

  text-align: center;
  align-items: center;
`
const MediaItem = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  flex-direction: column;
  height: 75px;
  border-right: 2px dashed #fff;
`

const MediaTitle = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
` 

const TextWrap = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
` 
/**
 * 秒数を時間(HHmm)の文字列で返す
 * @param {int} seconds 秒
 * @return {string} 時間(HHmm)の文字列
 */
function secToTime(seconds) {
  if(!seconds) return '-';

  let hour = seconds / 3600 | 0
  let minutes = seconds % 3600 / 60 | 0
  hour = hour < 100 ?('00' + hour.toString()).slice(-2):hour.toString()
  return hour + ':' + ('00' + minutes.toString()).slice(-2)
}

function getDateText(dateList, index) {
  if (dateList === undefined || dateList === null)
    return <></>;
  else if (dateList.length <= index)
    return <></>;

  return<><span>{LogisticUtility.getStringFromDate(new Date(dateList[index].dateValue), 'MM / DD')}</span></>
}

function getWeekText(dateList, index) {
  if (dateList === undefined || dateList === null)
    return <></>;
  else if (dateList.length <= index)
    return <></>;

  return <>（<span style={{color:LogisticUtility.getDateColor(dateList[index])}}>{dateList[index].week}</span>）</>
}
function getData(dataList, dateList, index) {
  if (dataList === undefined || dataList === null || dateList === undefined || dateList === null)
    return [];
  else if (dateList.length <= index)
    return [];

  var pList = []
  var date = dateList[index].dateValue;
  dataList.map((data,index)=>{
    var p = data.personals.find((p) => { return p.targetDt == date});
    if(p) pList.push(p)
  })
  
  return pList;
}
const MediaHeaderRange = (props) => {
  const {
    dateRangeList
    , dateIndex
    , personalList
  } = props

  // 初期値
  const [info0, setInfo0] = useState([]);
  const [info1, setInfo1] = useState([]);
  const [info2, setInfo2] = useState([]);
  const [info3, setInfo3] = useState([]);
  const [info4, setInfo4] = useState([]);
  const [info5, setInfo5] = useState([]);
  const [info6, setInfo6] = useState([]);

  useEffect(() => {
    console.log(getData(personalList, dateRangeList, dateIndex + 0))
    setInfo0(getData(personalList, dateRangeList, dateIndex + 0));
    setInfo1(getData(personalList, dateRangeList, dateIndex + 1));
    setInfo2(getData(personalList, dateRangeList, dateIndex + 2));
    setInfo3(getData(personalList, dateRangeList, dateIndex + 3));
    setInfo4(getData(personalList, dateRangeList, dateIndex + 4));
    setInfo5(getData(personalList, dateRangeList, dateIndex + 5));
    setInfo6(getData(personalList, dateRangeList, dateIndex + 6));
  }, [dateRangeList, dateIndex]);
  //集計  
  const makeSummary=(type,info)=>{
    var sum = 0

    switch(type){
      case 0:
        personalList.map((data,index)=>{
          sum += parseInt(data.totalWorkTimeSec)
        })
        sum = secToTime(sum)
        break;
      case 1:
        personalList.map((data,index)=>{
          sum += parseInt(data.toTalResultNum)
        })
        sum = sum.toLocaleString()
        break;
      case 2:
        info.map((data,index)=>{
          sum += parseInt(data.workTimeSec)
        })
        sum = secToTime(sum)
        break;
      case 3:
        info.map((data,index)=>{
          sum += parseInt(data.resultNum)
        })
        if(isNaN(sum) || info.length == 0) sum = '-'
        else sum = sum.toFixed(0).toLocaleString()
        break;
      default:
        break;
    }

    return sum
  }
  return (
    <MediaWrap>
      <MediaItem>
        <MediaTitle style={{width:'120px'}}>集計</MediaTitle>
      </MediaItem>
      <MediaItem>
        <MediaTitle style={{width:'200px'}}>{personalList.length}</MediaTitle>
      </MediaItem>
      <MediaItem>
        <MediaTitle style={{width:'140px'}}>作業時間</MediaTitle>
        <MediaTitle style={{width:'140px'}}>種まき数</MediaTitle>
      </MediaItem>
      <MediaItem>
        <MediaTitle style={{width:'160px'}}>{makeSummary(0)}</MediaTitle>
        <MediaTitle style={{width:'160px'}}>{makeSummary(1)}</MediaTitle>
      </MediaItem>
      <MediaItem>
        <MediaTitle style={{width:'160px'}}>{makeSummary(2,info0)}</MediaTitle>
        <MediaTitle style={{width:'160px'}}>{makeSummary(3,info0)}</MediaTitle>
      </MediaItem>
      <MediaItem>
        <MediaTitle style={{width:'160px'}}>{makeSummary(2,info1)}</MediaTitle>
        <MediaTitle style={{width:'160px'}}>{makeSummary(3,info1)}</MediaTitle>
      </MediaItem>
      <MediaItem>
        <MediaTitle style={{width:'160px'}}>{makeSummary(2,info2)}</MediaTitle>
        <MediaTitle style={{width:'160px'}}>{makeSummary(3,info2)}</MediaTitle>
      </MediaItem>
      <MediaItem>
        <MediaTitle style={{width:'160px'}}>{makeSummary(2,info3)}</MediaTitle>
        <MediaTitle style={{width:'160px'}}>{makeSummary(3,info3)}</MediaTitle>
      </MediaItem>
      <MediaItem>
        <MediaTitle style={{width:'160px'}}>{makeSummary(2,info4)}</MediaTitle>
        <MediaTitle style={{width:'160px'}}>{makeSummary(3,info4)}</MediaTitle>
      </MediaItem>
      <MediaItem>
        <MediaTitle style={{width:'160px'}}>{makeSummary(2,info5)}</MediaTitle>
        <MediaTitle style={{width:'160px'}}>{makeSummary(3,info5)}</MediaTitle>
      </MediaItem>
      <MediaItem>
        <MediaTitle style={{width:'160px'}}>{makeSummary(2,info6)}</MediaTitle>
        <MediaTitle style={{width:'160px'}}>{makeSummary(3,info6)}</MediaTitle>
      </MediaItem>
    </MediaWrap>
  )
}

MediaHeaderRange.propTypes = {
}

export default MediaHeaderRange

import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import useReactRouter from 'use-react-router'

import Tooltip from '@material-ui/core/Tooltip'

import GRANT from './Common/GrantEnum'

import SettingMenu from './SettingMenu'

import SettingInfoDialog from './Common/SettingInfo'
import StaffInfo from './Common/StaffInfo'
import * as LogisticUtility from './Common/Utilties/LogisticUtility'
import Navigator from './Navigator'

import menuButton from '../image/menu-btn.png'
import IconCollapseImg from '../image/Collapse.png'

import TruckNavigator from './TrackNavigator'
import InfomationIcon from '../image/infomation-icon.png'
//import LogoIcon from '../image/logo.png'
//import SettingIcon from '../image/menu-icon4.png'
import InfomationNavigator from './AFSTruckManagement/Infomation/InfomationNavigator'
import { useHistory } from 'react-router-dom'

const FlexDiv = styled.div`
  display: flex; 
  align-items: center;
  height: 32px;
`

const MenuImg = styled.img`
  cursor: pointer;
  margin-left: 15px;
  width: 32px;
`

const LogoImg = styled.img`
  margin-left: 15px;
  margin-right: auto;
  height: 48px;
`

const SettingImg = styled.img`
  margin-left: auto;
  margin-right: 15px;
  height: 28px;
`

const HeaderWrap = styled.header`
  background: #cfe2f3;
  position: sticky;
  top: 0;
  z-index: 4;
`

//お知らせ機能用
const InfoDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  font-size: 24px; 
  font-weight: bold;
  border: 2px solid #ffffff;
  height:40px;
  width:150px;
  text-align:center;
  vertical-align: middle;
  background-color: yellow;
  :hover {
    background: #ccc;
    color: #333;
  }
`

const Header = (props) => {
  const { SettingInfo } = useSelector(state => state);
  const loginUserInfo = useSelector(state => state.loginUserInfo);
  const isExternalUser = LogisticUtility.checkExternalUser(loginUserInfo.grantNameId);
  const isAFS = GRANT.AFS === loginUserInfo.grantNameId;
  const isTruckUser = LogisticUtility.checkTruckUser(loginUserInfo.grantNameId);
  const { location } = useReactRouter();
  const [showMenuFlg, setShowMenuFlg] = useState('none');
  const showMenu = () => {
    setShowMenuFlg(showMenuFlg === 'none' ? 'block' : 'none')
  }

  //初回ログイン時のみ
  const [showInfoFlg, setShowInfoFlg] = useState(document.referrer.includes('login')?'none':'block');
  //運送ダッシュボード（お知らせ機能用）
  const showInfo = () => {
    console.log()
    setShowInfoFlg('block')
  }
  //運送ダッシュボード（お知らせ機能用）
  const showMenuTruck = () => {
    if(showInfoFlg!='block')
      setShowMenuFlg(showMenuFlg === 'none' ? 'block' : 'none');

  }
  // サイドメニュー判定
  //const sideMenuPosition = useSelector(state => state.sideMenuPosition)
  //const existSideMenu = document.getElementById('SideMenu')
  //const marginRight = existSideMenu ? sideMenuPosition.position : '0px'
  const marginRight = '0px';
  const [showSetteingMenuFlg, setShowSettingMenuFlg] = useState(false);
  const showSettingMenu = () => {
    setShowSettingMenuFlg(true)
  }

  const afsSubTitle=(type)=>{
    var text = ''
    switch(type){
      case 0:
        text = '配送実績'
        break
      case 1:
        text = '日別運行'
        break
      case 2:
        text = '積載率'
        break
      default:
        text = ''
        break
    }
    switch(window.location.pathname.slice(10)){
      case 'detail':
        return '明細　'+text+'レポート'
      case 'summary':
        return 'サマリ'
      case 'truckDetail':
        return '配送実績レポート'
      case 'storeDetail':
        return '店舗作業時間チェックレポート'
      case 'information':
        return 'お知らせ'
      default:
        return 'ホーム'
    }
  }
  //AFS用現在時刻作成
  const makeAfsDate=(type)=>{
         
    //現在時刻
    var nowDate = ''
    switch(type){
      case 0:
        var now = new Date()
        now.setDate(now.getDate()-1)
        nowDate = now.getFullYear() + '年' + ('00'+Number(now.getMonth()+1)).slice(-2)+ '月' + ('00'+now.getDate()).slice(-2)+'日'
        var week =  ["日", "月", "火", "水", "木", "金", "土"][now.getDay()]
        nowDate = nowDate + '（'+week+'）'
        break
      case 1:
        var now = new Date()
        nowDate = now.getFullYear() + '年' + ('00'+Number(now.getMonth()+1)).slice(-2)+ '月' + ('00'+now.getDate()).slice(-2)+'日'
        var week =  ["日", "月", "火", "水", "木", "金", "土"][now.getDay()]
        nowDate = nowDate + '（'+week+'）'
        break
      case 2:
        var now = new Date()
        nowDate = now.getFullYear() + '年' + ('00'+Number(now.getMonth()+1)).slice(-2)+ '月'
        now.setMonth(now.getMonth()+1)
        now.setDate(0)
        nowDate = nowDate +'01日～'+ ('00'+now.getDate()).slice(-2)+'日'
        break
      case 99:
        var now = new Date()
        nowDate = now.getFullYear() + '/' + ('00'+Number(now.getMonth()+1)).slice(-2)+ '/'+('00'+now.getDate()).slice(-2) +' '
                  + ('00'+Number(now.getHours())).slice(-2)+':'+('00'+Number(now.getMinutes())).slice(-2)
                  +':'+('00'+Number(now.getSeconds())).slice(-2)
        break
      default:
        break
    }

    return nowDate
  }
  return (
    <HeaderWrap className='header'>
      <div className='header-container'>
        {!isExternalUser && !isTruckUser&& 
        <Tooltip title='メインメニュー'>
          <MenuImg src={menuButton} onClick={showMenu} />
        </Tooltip>
        }
       {isTruckUser && (window.location.pathname!='/afstruck/information') &&
        <Tooltip title='メインメニュー'>
          <MenuImg src={menuButton} onClick={showMenuTruck} />
        </Tooltip>
        }
        {/*<LogoImg src={LogoIcon} />*/}
        <div style={{marginLeft:'2rem', fontSize:'34px', fontWeight:'bold'}}>Core Board</div>
        {window.location.pathname.slice(1,9)!='afstruck' &&
          <Navigator showFlg={showMenuFlg} setShowFlg={setShowMenuFlg} topPageFlg={window.location.href.endsWith('top')} />
        }
        {window.location.pathname.slice(1,9)=='afstruck' &&
          <TruckNavigator showFlg={showMenuFlg} setShowFlg={setShowMenuFlg} topPageFlg={window.location.href.endsWith('top')} />
        }
        {window.location.pathname.slice(1,9)=='afstruck' &&
        <>
          <div style={{marginLeft:'2rem',fontSize:'24px', fontWeight:'bold'}}>運送ダッシュボード</div>
          <img alt='階層' src={IconCollapseImg} width="24px" height="24px" style={{marginTop:'4px'}}/>
          <div className='subTitleItem' style={{fontSize:'20px',margin:'0px 0 0 12px'}}>
            {afsSubTitle(SettingInfo.afsSummaryType)}
          </div>
        </>
        }
        <div></div>
        {window.location.pathname.slice(1,9)=='afstruck' &&
          <>
            <div style={{marginLeft: 'auto'}}>
            </div>
          </>
        }
        {/*お知らせ機能用処理追加 */}
        {window.location.pathname.slice(1,9)=='afstruck' && (window.location.pathname!='/afstruck/information') &&
        <>
          <Tooltip title={'お知らせ'}>
            <InfoDiv onClick={()=>{showInfo()}}>
              <img 
                alt={'お知らせ'} 
                src={InfomationIcon} 
                width="28px" 
                height="28px" 
              />
              <div style={{marginLeft: '5px'}}>{'お知らせ'}</div>
            </InfoDiv>
          </Tooltip>
          <InfomationNavigator
            showFlg={showInfoFlg} 
            setShowFlg={setShowInfoFlg} 
          />
        </>
        }
        {window.location.pathname.slice(1,9)=='afstruck' && afsSubTitle()=='ホーム' &&
          <>
            <div style={{marginRight: '10px',fontSize:'24px', fontWeight:'bold'}}>
              {makeAfsDate(SettingInfo.afsSummaryType)}
            </div>
          </>
        }
        {window.location.pathname=='/afstruck/detail' &&
          <>
            <div style={{marginRight: '10px',fontSize:'24px', fontWeight:'bold'}}>
              {makeAfsDate(99)}
            </div>
          </>
        }
        {(window.location.pathname=='/afstruck/truckDetail' ||
         window.location.pathname=='/afstruck/storeDetail') &&
          <>
            <div style={{marginRight: '10px',fontSize:'24px', fontWeight:'bold'}}>
              {makeAfsDate(1)}
            </div>
          </>
        }
        {/* {!isAFS && window.location.pathname.slice(1,9)!='afstruck' &&
          <FlexDiv style={{marginLeft: 'auto',marginRight: marginRight }}>
              <SettingInfoDialog showSettingMenu={showSettingMenu}/>
          </FlexDiv>
          // <Tooltip title='設定'>
          //   <SettingImg src={SettingIcon} onClick={showSettingMenu}/>
          // </Tooltip>
        } */}
        {/*2024/02/14 ADD AFSにも画面切替設定が変更できるように変更 */}
        {window.location.pathname.slice(1,9)!='afstruck' &&
          (!isAFS || (window.location.pathname == '/logistic/vasPerformance' 
          || window.location.pathname  == '/logistic/vasKantouPerformance'
          )) &&
          <FlexDiv style={{marginLeft: 'auto',marginRight: marginRight }}>
              <SettingInfoDialog showSettingMenu={showSettingMenu}/>
          </FlexDiv>
          // <Tooltip title='設定'>
          //   <SettingImg src={SettingIcon} onClick={showSettingMenu}/>
          // </Tooltip>
        }
        <FlexDiv style={{ marginRight: marginRight }}>
          <StaffInfo />
        </FlexDiv>
      </div>
      <SettingMenu open={showSetteingMenuFlg} setOpen={setShowSettingMenuFlg}/>
    </HeaderWrap>
  )
}

export default Header

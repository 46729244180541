import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const MediaWrap = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  background-color: #fff;
  color: #000;
  font-size: 1.5vw;
  text-align: center;
  align-items: center;
`
const MediaItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 40px;
  border-right: ${props => props.borderNone ? '': '1px solid #000'};
  border-bottom: ${props => props.type ? '': '1px solid #000'};
`

const TextWrap = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
` 

function getTitle(type,data) {
  var title = '';
  switch(type) {
    case 0:
      title = data?'戻り':'センター出し'
      break;
    case 1:
      title = data?'センター':'外部';
      break;
    case 2:
      title = '予測';
      break;
    default:
      title = '';
      break;
  }

  return title;
}

const MaterialHandlingItem = (props) => {
  const {
    searchDate,
    data,
    type,
    onClick
  } = props

  // 初期値
  useEffect(() => {
  })

  //if (!data) return null;
  //console.log(data);
  return (
    <MediaWrap>
      <MediaItem type={data}>
        <TextWrap style={{width:'275px', textAlign:'center'}}>
          <div>{getTitle(type,data)}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem type={data}>
        <TextWrap style={{width:'280px', textAlign:'center'}}>
          <div>{data?198:128}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem type={data}>
        <TextWrap style={{width:'280px', textAlign:'center'}}>
          <div>{data?140:132}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem type={data}>
        <TextWrap style={{width:'280px', textAlign:'center'}}>
          <div>{data?146:137}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem type={data}>
        <TextWrap style={{width:'280px', textAlign:'center'}}>
          <div>{data?46:37}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem type={data} borderNone={true}>
        <TextWrap style={{width:'280px', textAlign:'center'}}>
          <div>{data?146:137}</div>
        </TextWrap>
      </MediaItem>
    </MediaWrap>
  )
}

MaterialHandlingItem.propTypes = {
}

export default MaterialHandlingItem

import React,{useState} from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
//import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'

import * as LogisticUtility from '../Common/Utilties/LogisticUtility'
import GRANT from '../Common/GrantEnum'

import IconCollapseImg from '../../image/Collapse.png'

const SideMenuContent = styled.div`
  display:${props => props.display || 'flex'};
  margin-top:${props => props.top || '0px'};
  flex-wrap: wrap;
  height: ${props => props.height || '85vh'};
  img {
  }
  ul.mainSearch {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    position: relative;
    > li {
      display: flex;
      margin: 0 0 0 1rem;
      label {
        line-height: 32px;
        text-align: right;
        font-size: 12px;
        margin: 0 0.5rem 0 0;
    }
  }
    li.subSearch {
      display: ${props => props.visible ? 'block' : 'none' };
      position: absolute;
      font-size: 16px;
      margin: 0;
      top: 0px;
      z-index: 3;
      left: 0;
    }
  }
`

const MenuContent = styled.div`
    flex-direction: column;
    background-repeat: no-repeat;
    height: 84.8vh;
    width: 10vw;
    background-color: #cfe2f3;
    overflow-y: none;
`

const MenuContentRow = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1vw;
    background-repeat: no-repeat;
    width: 10vw;
    overflow-y: none;
`

const MenuContentImg = styled.div`
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    overflow-y: none;
`

const SideSubMenu = ({top,height,planExisit,pageNum,searchDate}) => {
  const [viewMenu, setViewMenu] = useState(false);
  const loginUserInfo = useSelector(state => state.loginUserInfo);
  const isExternalUser = LogisticUtility.checkExternalUser(loginUserInfo.grantNameId);
  const isAFS = GRANT.AFS === loginUserInfo.grantNameId;

  const onClick =()=>{    
    setViewMenu(!viewMenu);
  }
  const onClose =()=>{    
    setViewMenu(false);
  }

  return (
    <SideMenuContent visible={viewMenu} top={top} height={height} onMouseLeave={()=>onClose()}>
      <ul className='mainSearch'>
        {isAFS && 
        <div style={{width:"24px", height:"24px"}}></div>
        }
        {!isAFS &&
        <img alt='開閉' src={IconCollapseImg} width="24px" height="24px" style={{cursor: 'pointer' }} onClick={()=>onClick()}/>
        }
      <li className='subSearch'>
        <MenuContent>
            <MenuContentImg>
              <img alt='開閉' src={IconCollapseImg} width="24px" height="24px" style={{transform: 'rotate(180deg)' ,cursor: 'pointer' }} onClick={()=>onClick()}/>
            </MenuContentImg>
            <MenuContentRow>
              {planExisit &&
                <div>{'当日の予定'}</div>
              }
              {!planExisit &&
                <Link to={{pathname: "/logistic/planningDate", state: {searchDate: searchDate} }}><Tooltip title={'当日の予定'}><div>{'当日の予定'}</div></Tooltip></Link>
              }
            </MenuContentRow>
            <MenuContentRow>
              {!pageNum &&
                <div>{'ダッシュボード'}</div>
              }
              {pageNum &&
                <Link to="/logistic/sorterPerformance"><Tooltip title={'ダッシュボード'}><div>{'ダッシュボード'}</div></Tooltip></Link>
              }
            </MenuContentRow>
            {!isExternalUser && false &&
            <>
            <MenuContentRow>
              {pageNum==2 &&
                <div>{'OLAP分析'}</div>
              }
              {pageNum!=2 &&
                <Link to="/logistic/olapAnalysis"><Tooltip title={'OLAP分析'}><div>{'OLAP分析'}</div></Tooltip></Link>
              }
            </MenuContentRow>
            <MenuContentRow>
              {pageNum==3 &&
                <div>{'データマイング'}</div>
              }
              {pageNum!=3 &&
                <Link to="/logistic/dataMining"><Tooltip title={'データマイング'}><div>{'データマイング'}</div></Tooltip></Link>
              }
            </MenuContentRow>
            <MenuContentRow>
              {pageNum==4 &&
                <div>{'プランニング'}</div>
              }
              {pageNum!=4 &&
                <Link to="/logistic/planning"><Tooltip title={'プランニング'}><div>{'プランニング'}</div></Tooltip></Link>
              }
            </MenuContentRow>
            <MenuContentRow>
              {pageNum==5 &&
                <div>{'サプライチェーンCo2排出量'}</div>
              }
              {pageNum!=5 &&
                <Link to="/logistic/carbonEmissions"><Tooltip title={'サプライチェーンCo2排出量'}><div>{'サプライチェーンCo2排出量'}</div></Tooltip></Link>
              }
            </MenuContentRow>
            </>
            }
        </MenuContent>
      </li>
    </ul>
    </SideMenuContent>
  )
}

SideSubMenu.propTypes = {
}

export default SideSubMenu

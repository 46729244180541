import React, { useState, useEffect } from 'react'
//import { useForm } from 'react-hook-form'
//import { useDispatch } from 'react-redux'
//import { useAlert } from 'react-alert'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import styled from 'styled-components'
import PropTypes from 'prop-types'

//import Input from '../../../Common/Atoms/Input'
//import Select from '../../../Common/Atoms/SelectAddValue'
import Button from '../../../Common/Atoms/NormalButton'

//import { GetApi } from '../../../Common/ApiAxios'
//import ErrorHandling from '../../../Common/ErrorHandling'

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 12px;
`

const TitleWrap = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: bold; 
  
  width: 20rem;
`

const FormWrap = styled.form`
  display: flex;
`

const RowWrap = styled.div`
  display: flex;
  flex-direction: row;
  height: 20px;

  margin-bottom: 0.5rem;
`

const ButtonsWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`

const AveWageVisibleDialog = ({ open,onCloseFunc,type,visibleList,setVisibleList}) => {
  //const { register, handleSubmit, getValues, setValue, watch, reset, errors, control } = useForm({ validateCriteriaMode: 'all' })
  const classes = useStyles();

  const [tmpVisibleList,setTmpVisibleList] =useState(visibleList)
  useEffect(() => {
    setTmpVisibleList(visibleList)
  }, [open])

  const onClose=()=>{
    setVisibleList(tmpVisibleList)
    onCloseFunc()
  }
  
  const onChange=(name)=>{
    const newVal = JSON.parse(JSON.stringify(tmpVisibleList)) 
    newVal[name] = !newVal[name]
    setTmpVisibleList(newVal);
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='平均時給情報'
      aria-describedby='平均時給表示情報ダイアログ'
      style={{display:'flex',alignItems:'center',justifyContent:'center'}}
    >
    <FormWrap>
    <div className={classes.paper}>
      <TitleWrap>グラフ表示項目選択</TitleWrap>
      <ContentWrap>
        <RowWrap><input type="checkbox" id='check1' onChange={()=>onChange("localMinWage")} checked={tmpVisibleList["localMinWage"]}/><label htmlFor='check1'>地域最低時給</label></RowWrap>
        <RowWrap><input type="checkbox" id='check2' onChange={()=>onChange("localAvgWage")} checked={tmpVisibleList["localAvgWage"]}/><label htmlFor='check2'>{type?'当年平均時給':'当月平均時給'}</label></RowWrap>
        <RowWrap><input type="checkbox" id='check3' onChange={()=>onChange("marketMinWage")} checked={tmpVisibleList["marketMinWage"]}/><label htmlFor='check3'>相場最低時給</label></RowWrap>
        <RowWrap><input type="checkbox" id='check4' onChange={()=>onChange("marketMaxWage")} checked={tmpVisibleList["marketMaxWage"]}/><label htmlFor='check4'>相場最高時給</label></RowWrap>
        <RowWrap><input type="checkbox" id='check5' onChange={()=>onChange("marketLowerAvgWage")} checked={tmpVisibleList["marketLowerAvgWage"]}/><label htmlFor='check5'>相場下限平均時給</label></RowWrap>
        <RowWrap><input type="checkbox" id='check6' onChange={()=>onChange("marketUpperAvgWage")} checked={tmpVisibleList["marketUpperAvgWage"]}/><label htmlFor='check6'>相場上限平均時給</label></RowWrap>
      </ContentWrap>
      <ButtonsWrap>
        <Button name='閉じる' padding='0px' onClick={onClose} />
      </ButtonsWrap>
    </div>
    </FormWrap>
  </Modal>
  )
}

AveWageVisibleDialog.propTypes = {
  open: PropTypes.bool,
  onCloseFunc: PropTypes.func,
  type: PropTypes.number, // 0：日単位(上グラフ),1:月単位(下グラフ)
  visibleList: PropTypes.array, // グラフに表示する項目
  setVisibleList: PropTypes.func, // グラフに表示する項目設定
}
export default AveWageVisibleDialog
import { combineReducers } from '@reduxjs/toolkit'
import loginUserInfoReducer from '../Common/Slice/LoginSlice'
import LoadSpinnerReducer from '../Common/Slice/LoadSpinnerSlice'
import SettingReducer from '../Common/Slice/SettingSlice'

const rootReducer = combineReducers({
    loginUserInfo: loginUserInfoReducer,
    loadSpinner: LoadSpinnerReducer,
    SettingInfo: SettingReducer
  })
  
  export default rootReducer
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
//import FUNC from '../../Common/FunctionEynum'
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'

import PropTypes from 'prop-types'

import { GetApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import * as LogisticUtility from '../../Common/Utilties/LogisticUtility'
//import ErrorHandling from '../../Common/ErrorHandling'

import AveWageGraph from './Molcules/AveWageGraph'

const DefaultUrl = '/api/logisticManagement'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 85vh;
  text-align: center;
  vertical-align: middle;
`

const ContentBody = styled.div`
  display: grid;
  grid-gap: 30px 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row: 1 / 2;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
`

const ProgressGraphBoard = (props) => {
  const {
    wageType
  } = props

  //const { loginUserInfo } = useSelector(state => state);

  const dispatch = useDispatch();
  const alert = useAlert();
  const [processList,setProcessList] = useState([]);
  const [processMonthList,setProcessMonthList] = useState([]);
  
  const intervalRef = useRef(null);

  // 平均時給情報の取得
  const apiGetAvgWageGraph = async (isLoadSplinner) => {
    //console.log('call apiGetAvgWageGraph');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getAvgWageGraph';
   
    const data = {
      workplaceId: '000011',
      targetYMD: LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD'),
      //targetYMD:'2023-02-23'
    }
    const result = await GetApi(apiUrl, data);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result.errorDetail) {
      //alert.error('条件の該当データがありません(平均時給情報の取得)');
    } else {
      {
        let sumAveWage = 0;
        let sumAgreementStaffAvgWage = 0;
        let count = 0;
        result.data[0].map((process)=>{
          sumAgreementStaffAvgWage += process.agreementStaffAvgWage
          sumAveWage += process.aveWage

          if(process.agreementStaffAvgWage!==0){
            count++
          }
        })
        if(count===0) count=1;

        result.data[0].map((process)=>{
          process.localAgreementStaffAvgWage = sumAgreementStaffAvgWage/count
          process.localAvgWage = sumAveWage/count
        })
        setProcessList(result.data[0]);
      }
      {
        let sumAveWage = 0;
        let sumAgreementStaffAvgWage = 0;
        let count = 0;
        result.data[1].map((process)=>{
          sumAgreementStaffAvgWage += process.agreementStaffAvgWage
          sumAveWage += process.aveWage

          if(process.agreementStaffAvgWage!==0){
            count++
          }
        })
        if(count===0) count=1
        result.data[1].map((process)=>{
          process.localAgreementStaffAvgWage = sumAgreementStaffAvgWage/count
          process.localAvgWage = sumAveWage/count

        })
        setProcessMonthList(result.data[1]);
      }

      //console.log(result)
    }
  }

  // 周期初期のクリア
  const onClearInerval = async () => {
    if (intervalRef.current !== null)
      clearInterval(intervalRef.current);
    
    intervalRef.current = null;
  }

  // 描画時に一度だけ実行
  useEffect(() => {
    apiGetAvgWageGraph()

    intervalRef.current = setInterval(() => {
      apiGetAvgWageGraph()
    }, 1000 * 60); // 60秒間隔で変更
    
    return () => {
      onClearInerval();
    };
  }, [])

  return (
    <ContentWrap>
      <ContentBody>
        {processList.length!==0 &&
          <AveWageGraph processList={processList} type={0} wageType={wageType}/>
        }
      </ContentBody>
      <ContentBody>
        {processMonthList.length!==0 &&
          <AveWageGraph processList={processMonthList} type={1} wageType={wageType}/>
        }
      </ContentBody>
    </ContentWrap>
  )
}

AveWageGraph.propTypes = {
  wageType: PropTypes.number, // 平均時給タイプ　0:契約から算出,1:労務費から算出
}

export default ProgressGraphBoard

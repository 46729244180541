import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
//import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { GetApi } from '../../../Common/ApiAxios'
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'
import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'

const DefaultUrl = '/api/logisticManagement'

const Wrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  float: flex;
  width: 100%;
  text-align: center;
  vertical-align: middle;
`

const Flex = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 100%;
  text-align: center;
  vertical-align: middle;
`

const Content = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  width: calc(100% - 210px);
  height: 100%;
  text-align: center;
  vertical-align: middle;

  margin-left: auto;
  margin-right: auto;

  ::-webkit-scrollbar-corner {
    display: none;
  }
`

const Title = styled.div`
  width: 200px;
  height: 50px;
  margin: 5px;

  font-size: 20px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
`

const DatePanel= styled.div`
  width: 100%;
  height: 50px;
  background-color: #cfe2f3;
  border-radius: 10px;
  border: none;
  margin: 5px;

  font-size: 16px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
`

const NamePanel= styled.div`
  width: 200px;
  height: 35px;
  background-color: #cfe2f3;
  border-radius: 10px;
  border: none;
  margin: 5px;

  font-size: 16px;
  text-align: center;
  vertical-align: middle;
`

const ValuePanel = styled.div`
  width: 100%;
  height: 35px;
  background-color: #cfe2f3;
  border-radius: 10px;
  border: none;
  margin: 5px;

  font-size: 16px;
  text-align: center;
  vertical-align: middle;
`

const DatePanelContent = styled.div`
  margin-top: 10px;
`

const PanelContent = styled.div`
  margin-top: 10px;
`

function getQuantity(quantityPlan) {
  if (quantityPlan === undefined || quantityPlan === null) {
    return '0';
  }

  return LogisticUtility.getCommaText(quantityPlan.quantity);
}

function getStartTime(quantityPlan) {
  if (quantityPlan === undefined || quantityPlan === null || quantityPlan.startAt === null) {
    return '--:--';
  }

  return LogisticUtility.getStringFromDate(new Date(quantityPlan.startAt), 'hh:mm');
}

function getPlanEndTime(quantityPlan) {
  if (quantityPlan === undefined || quantityPlan === null || quantityPlan.planEndAt === null) {
    return '--:--';
  }

  return LogisticUtility.getStringFromDate(new Date(quantityPlan.planEndAt), 'hh:mm');
}

function getResultEndTime(quantityPlan) {
  if (quantityPlan === undefined || quantityPlan === null || quantityPlan.resultEndAt === null) {
    return '--:--';
  }

  //console.log(quantityPlan);
  return LogisticUtility.getStringFromDate(new Date(quantityPlan.resultEndAt), 'hh:mm');
}
const PlanWeekly = (props) => {
  const {
    grants,
    dates,
  } = props
  const dispatch = useDispatch();
  //const alert = useAlert();
  const intervalRef = useRef(null);
  const [quantityPlan0,setQuantityPlan0] = useState(null);
  const [quantityPlan1,setQuantityPlan1] = useState(null);
  const [quantityPlan2,setQuantityPlan2] = useState(null);
  const [quantityPlan3,setQuantityPlan3] = useState(null);
  const [quantityPlan4,setQuantityPlan4] = useState(null);
  const [quantityPlan5,setQuantityPlan5] = useState(null);
  const [quantityPlan6,setQuantityPlan6] = useState(null);

  // 物量計画情報の取得
  const apiGetQuantityPlanDaily = async (isLoadSplinner, targetYMD,num) => {
    //console.log('call apiGetQuantityPlanDaily')

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true))

    const apiUrl = DefaultUrl + '/getQuantityPlanDaily';
    const param = {
      workplaceId: '000011',
      targetYMD: targetYMD,
    }

    const result = await GetApi(apiUrl, param);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('物量計画情報取得失敗');
      if (num === 0)
        setQuantityPlan0(null);
      else if (num === 1)
        setQuantityPlan1(null);
      else  if (num === 2)
        setQuantityPlan2(null);
      else  if (num === 3)
        setQuantityPlan3(null);
      else if (num === 4)
        setQuantityPlan4(null);
      else if (num === 5)
        setQuantityPlan5(null);
      else if (num === 6)
        setQuantityPlan6(null);
    } else {
      //console.log(result);
      if (num === 0)
        setQuantityPlan0(result.data);
      else if (num === 1)
        setQuantityPlan1(result.data);
        else  if (num === 2)
        setQuantityPlan2(result.data);
      else  if (num === 3)
        setQuantityPlan3(result.data);
      else if (num === 4)
        setQuantityPlan4(result.data);
      else if (num === 5)
        setQuantityPlan5(result.data);
      else if (num === 6)
        setQuantityPlan6(result.data);
    }
  }

  // 周期初期のクリア
  const onClearInerval = async () => {
    if (intervalRef.current !== null)
      clearInterval(intervalRef.current);
    
    intervalRef.current = null;
  }

  // 描画時に一度だけ実行
  useEffect(() => {
    let index = 0;
    for (const date of dates) {
      apiGetQuantityPlanDaily(false, date.dateValue, index);
      index++;
    }

    intervalRef.current = setInterval(() => {
      let index = 0;
      for (const date of dates) {
        apiGetQuantityPlanDaily(false, date.dateValue, index);
        index++;
      }
    }, 1000 * 60); // 60秒間隔で変更
    
    return () => {
      onClearInerval();
    };
  }, [])

  if (!dates) return <></>
  return (
    <Wrap>
      <Flex>
          <Title>物量計画<br/>Weekly</Title>
          <Content>
            {dates.map(item => (
              <DatePanel style={{color:LogisticUtility.getDateColor(item)}}><DatePanelContent>{LogisticUtility.getStringFromDate(new Date(item.dateValue), 'MM/DD')}<br/>({item.week})</DatePanelContent></DatePanel>
            ))}
          </Content>
      </Flex>
      <Flex>
          <NamePanel style={{color:'#f00', backgroundColor:'#fce5cd', fontWeight:'bold'}}><PanelContent>作業計画</PanelContent></NamePanel>
          <Content>
              <ValuePanel style={{color:'#f00', backgroundColor:'#fce5cd', fontWeight:'bold'}}><PanelContent>{getQuantity(quantityPlan0, 0)}</PanelContent></ValuePanel>
              <ValuePanel style={{color:'#f00', backgroundColor:'#fce5cd', fontWeight:'bold'}}><PanelContent>{getQuantity(quantityPlan1, 1)}</PanelContent></ValuePanel>
              <ValuePanel style={{color:'#f00', backgroundColor:'#fce5cd', fontWeight:'bold'}}><PanelContent>{getQuantity(quantityPlan2, 2)}</PanelContent></ValuePanel>
              <ValuePanel style={{color:'#f00', backgroundColor:'#fce5cd', fontWeight:'bold'}}><PanelContent>{getQuantity(quantityPlan3, 3)}</PanelContent></ValuePanel>
              <ValuePanel style={{color:'#f00', backgroundColor:'#fce5cd', fontWeight:'bold'}}><PanelContent>{getQuantity(quantityPlan4, 4)}</PanelContent></ValuePanel>
              <ValuePanel style={{color:'#f00', backgroundColor:'#fce5cd', fontWeight:'bold'}}><PanelContent>{getQuantity(quantityPlan5, 5)}</PanelContent></ValuePanel>
              <ValuePanel style={{color:'#f00', backgroundColor:'#fce5cd', fontWeight:'bold'}}><PanelContent>{getQuantity(quantityPlan6, 6)}</PanelContent></ValuePanel>
          </Content>
      </Flex>
      <Flex>
          <NamePanel><PanelContent>開始時間</PanelContent></NamePanel>
          <Content>
              <ValuePanel><PanelContent>{getStartTime(quantityPlan0)}</PanelContent></ValuePanel>
              <ValuePanel><PanelContent>{getStartTime(quantityPlan1)}</PanelContent></ValuePanel>
              <ValuePanel><PanelContent>{getStartTime(quantityPlan2)}</PanelContent></ValuePanel>
              <ValuePanel><PanelContent>{getStartTime(quantityPlan3)}</PanelContent></ValuePanel>
              <ValuePanel><PanelContent>{getStartTime(quantityPlan4)}</PanelContent></ValuePanel>
              <ValuePanel><PanelContent>{getStartTime(quantityPlan5)}</PanelContent></ValuePanel>
              <ValuePanel><PanelContent>{getStartTime(quantityPlan6)}</PanelContent></ValuePanel>
          </Content>
      </Flex>
      <Flex>
          <NamePanel><PanelContent>終了予定</PanelContent></NamePanel>
          <Content>
              <ValuePanel><PanelContent>{getPlanEndTime(quantityPlan0)}</PanelContent></ValuePanel>
              <ValuePanel><PanelContent>{getPlanEndTime(quantityPlan1)}</PanelContent></ValuePanel>
              <ValuePanel><PanelContent>{getPlanEndTime(quantityPlan2)}</PanelContent></ValuePanel>
              <ValuePanel><PanelContent>{getPlanEndTime(quantityPlan3)}</PanelContent></ValuePanel>
              <ValuePanel><PanelContent>{getPlanEndTime(quantityPlan4)}</PanelContent></ValuePanel>
              <ValuePanel><PanelContent>{getPlanEndTime(quantityPlan5)}</PanelContent></ValuePanel>
              <ValuePanel><PanelContent>{getPlanEndTime(quantityPlan6)}</PanelContent></ValuePanel>
          </Content>
      </Flex>
      <Flex>
          <NamePanel style={{backgroundColor:'#b6d7a8', fontWeight:'bold'}}><PanelContent>終了実績</PanelContent></NamePanel>
          <Content>
              <ValuePanel style={{backgroundColor:'#b6d7a8', fontWeight:'bold'}}><PanelContent>{getResultEndTime(quantityPlan0)}</PanelContent></ValuePanel>
              <ValuePanel style={{backgroundColor:'#b6d7a8', fontWeight:'bold'}}><PanelContent>{getResultEndTime(quantityPlan1)}</PanelContent></ValuePanel>
              <ValuePanel style={{backgroundColor:'#b6d7a8', fontWeight:'bold'}}><PanelContent>{getResultEndTime(quantityPlan2)}</PanelContent></ValuePanel>
              <ValuePanel style={{backgroundColor:'#b6d7a8', fontWeight:'bold'}}><PanelContent>{getResultEndTime(quantityPlan3)}</PanelContent></ValuePanel>
              <ValuePanel style={{backgroundColor:'#b6d7a8', fontWeight:'bold'}}><PanelContent>{getResultEndTime(quantityPlan4)}</PanelContent></ValuePanel>
              <ValuePanel style={{backgroundColor:'#b6d7a8', fontWeight:'bold'}}><PanelContent>{getResultEndTime(quantityPlan5)}</PanelContent></ValuePanel>
              <ValuePanel style={{backgroundColor:'#b6d7a8', fontWeight:'bold'}}><PanelContent>{getResultEndTime(quantityPlan6)}</PanelContent></ValuePanel>
          </Content>
      </Flex>
    </Wrap>
  )
}

PlanWeekly.propTypes = {
  grants: PropTypes.object, // 権限関連
  dates: PropTypes.array, // 日付情報(1週分)
}

export default PlanWeekly

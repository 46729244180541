import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'

import ProgressGraphBoard from '../Atoms/ProgressGraphBoard'
import ProgressGraphTable from '../Atoms/ProgressGraphTable'

const ContentWrap = styled.div`
  display: flex; /* 横並び */
  flex-direction: column;
  vertical-align: middle;
  width: 100%;
  height: 520px;
`

const Title = styled.div`
  font-weight: bold;
  color: #000;
  text-align: left;
  font-size: 1.8rem;
`

const TitleWrap = styled.div`
  display: flex; /* 横並び */
  flex-direction: row;
  vertical-align: left;
  width: 100%;
  padding:1.0rem;
`

const TableWrap = styled.div`
  display: flex; /* 横並び */
  flex-direction: row;
  vertical-align: middle;
  text-align: center;
  width: 100%;
`

const ProgressPerformance = (props) => {
  const {
    dataList
  } = props

  return (
    <ContentWrap>
      <TitleWrap>
        <Title>出荷ステーション生産性（MH）</Title>
      </TitleWrap>
      <TableWrap>
        {dataList &&
        <>
          <ProgressGraphBoard dataList={dataList}/>
          <ProgressGraphTable dataList={dataList}/>
        </>
        }
      </TableWrap>
    </ContentWrap>
  )
}
ProgressPerformance.propTypes = {
}

export default ProgressPerformance

import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
//import { useAlert } from 'react-alert'
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'
//import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'

import FUNC from '../../Common/FunctionEnum'
//import Button from '../../Common/Atoms/NormalButton'

import BoardBaseWrap, {BoardBaseFormRow, ListUl} from '../Wrap/BoardBaseWrap'

import Search from './Search'
import SideSubMenu from '../SideSubMenu'
import DescriptionHeader from './Atoms/DescriptionHeader'
import DescriptionData from './Atoms/DescriptionData'
import HealthCareAlertDescription from './Atoms/HealthCareAlertDescription'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 85vh;
  text-align: center;
  vertical-align: middle;
`
const ContentFlex = styled.div`
  display: flex; /* 横並び */
  flex-flow: row nowrap;
  text-align: left;
  vertical-align: middle;
`

const Title = styled.div`
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const Help = (props) => {
  const { loginUserInfo } = useSelector(state => state);
  const [viewFunction,setViewFunction] = useState(false);
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.TRUCK);
  const subGrants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.TRUCK_HELP);

  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }
  
  // 機能メニューの閉じるイベント
  const onCloseFunction = () => {
    setViewFunction(false);
  }

  // 別サイトに移動クリックイベント
  const onClikcJumpURL = () => {
    window.open("https://datastudio.google.com/u/0/reporting/b40e4b1b-0ede-42f9-a1f5-e4958a3d0a42/page/p_fpsqysldwc?s=ryzR4ttsqfo", "_blank");
  }

  useEffect(() => {
    if (grants && subGrants)
      document.title = grants.functionMenuName + '【' + subGrants.functionMenuName + '】 | CoreBoard'
    else if (grants)
    document.title = grants.functionMenuName + ' | CoreBoard'
    else
      document.title = 'CoreBoard'
  }, [grants, subGrants])
    
  // 描画時に一度だけ実行
  useEffect(() => {
  }, [])

  return (
      <BoardBaseWrap>
        <Search
          onClickFunction={onClickFunction}
          onCloseFunction={onCloseFunction}
          viewFunction={viewFunction}
        />
        <BoardBaseFormRow>
          <SideSubMenu/>
          <ContentWrap>
            <div style={{width:'1860px', marginLeft:'10px'}}>
              <Title>運行状況</Title>
              <DescriptionHeader />
              <ListUl width={'calc(100%)'} height={'345px'}>
                <DescriptionData no={1} />
                <DescriptionData no={2} />
                <DescriptionData no={3} />
                <DescriptionData no={4} />
                <DescriptionData no={5} />
              </ListUl>

              <Title>ヘルスケア</Title>
              <ContentFlex>
                <div style={{width:'310px'}}><HealthCareAlertDescription no={1} /></div>
                <div style={{width:'20px'}}></div>
                <div style={{width:'310px'}}><HealthCareAlertDescription no={2} /></div>
                <div style={{width:'20px'}}></div>
                <div style={{width:'310px'}}><HealthCareAlertDescription no={3} /></div>
                <div style={{width:'20px'}}></div>
                <div style={{width:'450px'}}><HealthCareAlertDescription no={4} /></div>
                <div style={{width:'20px'}}></div>
                <div style={{width:'400px'}}><HealthCareAlertDescription no={5} /></div>
              </ContentFlex>
            </div>
            {/*<Button type='button' width='4rem' margin='auto' name='表示' padding='0px' onClick={onClikcJumpURL} />*/}
          </ContentWrap>
        </BoardBaseFormRow>
        </BoardBaseWrap>
  )
}

export default Help

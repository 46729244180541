import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import styled from 'styled-components'
import Login, { Auth } from './Login/Login'
import { setLoginUserInfo } from './Common/Slice/LoginSlice'
import LoadSpinner from './Common/LoadSpinner'
import { getLoginUserInfo } from './Common/StaffInfo'

import Header from './Header'
import Main from './Main'
import Footer from './Footer';
import NotFound from './NotFound'

import Top from './Top/Top'
import Logistic from './LogisticManagement/Logistic'
import Truck from './TruckManagement/Truck'
import Master from './MasterManagement/Master'
import ManagementPortal from './ManagementPortal/ManagementPortal'
import AFSTruck from './AFSTruckManagement/AFSTruck'

import '../css/App.scss';

const Container = styled.div`
  .App {
    flex-direction: column;
    background-repeat: no-repeat;
    background-color: #FFF;
    height: 100vh;
    width: 100vw;
    overflow-y: auto;
  }
`

const App = () => {
  const { loginUserInfo } = useSelector(state => state)
  const userInfo = { loginUserInfo: loginUserInfo, setLoginUserInfo: setLoginUserInfo }
  const dispatch = useDispatch()

  // ログインスタッフ情報・会社情報取得
  useEffect(() => {

    async function getLoginUserInfo_ () {
      getLoginUserInfo(dispatch)
    }
    if (loginUserInfo.staffId === undefined) {
      getLoginUserInfo_()
    }
  }, [loginUserInfo, dispatch])

  return (
    <Container>
      <Router>
        <LoadSpinner />
        <Switch>
        <Route path='/login' render={() => (<Login userInfo={userInfo} />)} exact />
        <Auth>
        <div className='App' id='App'>
            <div className='main'>
              <Header />
              <Switch>
                <Route path='/' component={Main} exact />
                <Route path='/top' component={Top} exact />
                <Route path='/logistic' component={Logistic} />
                <Route path='/truck' component={Truck} />
                <Route path='/master' component={Master} />
                <Route path='/managementPortal' component={ManagementPortal} />
                <Route path='/afstruck' component={AFSTruck} />
                {/* 404 not found */}
                <Route component={NotFound} />
              </Switch>
              <Footer />
            </div>
        </div>
        </Auth>
        </Switch>
      </Router>
    </Container>
  );
}

export default App;

import React from 'react'
//import { useSelector, useDispatch } from 'react-redux'
//import { useAlert } from 'react-alert'
//import FUNC from '../../Common/FunctionEynum'
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'

import PropTypes from 'prop-types'
//import ErrorHandling from '../../Common/ErrorHandling'

import {PieChart,Pie,Cell,Tooltip,Legend } from "recharts"

//const DefaultUrl = '/api/logisticManagement'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 85vh;
  text-align: center;
  vertical-align: middle;
`

const ContentBody = styled.div`
  display: grid;
  grid-gap: 0px 5px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row: 1 / 2;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
`
//var COLORS = ["red","orange","aqua","green","blue","purple","skyblue","black","gold","gray","pink","brown"]
const COLORS = [
  '#6e6eff',
  '#6fb7ff',
  '#5bcaff',
  '#4646ff',
  '#51a8ff',
  '#28b9ff',
  '#1e8eff',
  '#00acff',
  '#0074e8',
  '#0099e3',
  '#005ebb',
  '#007ab5',
  '#00509f',
  '#005f8c',
  '#003a75',
  '#004364',
];
const BoardTitle = styled.div`
  text-align:center;
  font-weight:bold;
  font-size:20px;
  margin-left: 41%;
  margin-bottom: 50px;
  transform: translateX(-59%); 
`

const ProcessPieGraph = (props) => {
  const {
    processList,title
  } = props

  return (
    <>
      <ContentWrap>
      <BoardTitle>{title}</BoardTitle>
        <ContentBody>
          <PieChart 
          width={480}
          height={310}>
          <Pie
              data={processList}
              cx="50%"
              cy="50%"
              startAngle={450}
              endAngle={90}
              labelLine={false}
              // label={renderCustomizedLabel}
              outerRadius={150}
              fill="#8884d8"
              dataKey="processRaito"
              nameKey="processName"
          >
            {processList.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[(entry.orderNo) % COLORS.length]} />
              ))}
          </Pie>
          <Tooltip/>
          <Legend layout="vertical" verticalAlign="top" align="right" />
        </PieChart >
        </ContentBody>
      </ContentWrap>
    </>
  )
}

ProcessPieGraph.propTypes = {
  processList: PropTypes.array, // 工程情報
}

export default ProcessPieGraph

import React, { useState, useEffect,useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
//import { useAlert } from 'react-alert'
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'
//import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
//import { useSyncExternalStore } from 'react'

//import FUNC from '../Common/FunctionEnum'

//import LogisticCard from './Atoms/LogisticCard'
//import TruckCard from './Atoms/TruckCard'
//import CoreTimeCard from './Atoms/CoreTimeCard'
//import CoreFirstCard from './Atoms/CoreFirstCard'
//import InfoCard from './Atoms/InfoCard'
import { SetChangeType } from '../../Common/Slice/SettingSlice'
import Search from './Search'
import ProgressPerformance from './ProgressPerformance'
import {BoardBaseFormRow, BoardBaseFormRowScroll} from '../Wrap/BoardBaseWrap'
import * as LogisticUtility from '../../Common/Utilties/LogisticUtility'
const InfoContent = styled.div`
  width: calc(100% - 10px);
  height: calc(100% - 200px);
`

const Detail = (props) => {
  const dispatch = useDispatch()
  const [summaryType,setSammarytype] = useState(0);
  const [viewFunction,setViewFunction] = useState(false);
  const searchFromDateRef = useRef()
  const searchToDateRef = useRef()
  const companyRef = useRef()
  const workplaceRef = useRef()

  const [companyList, setCompanyList] = useState([
    {id:'000284', name:'昭和冷蔵 厚木物流センター', url:'/logistic/syouwaPerformance'}
  , {id:'000001', name:'AFS南関東センター', url:'/logistic/vasKantouPerformance'}
  , {id:'000256', name:'AFSランドポート習志野センター', url:'/logistic/narashinoPerformance'}
  , {id:'000006', name:'AFS尾西センター', url:'/logistic/vasPerformance'}
  , {id:'000011', name:'福通 神戸カインズセンター', url:'/logistic/sorterPerformance'}
  ]);
  const [workplaceList, setWorkplaceList] = useState([
    {id:'000284', name:'昭和冷蔵 厚木物流センター', url:'/logistic/syouwaPerformance'}
  , {id:'000001', name:'AFS南関東センター', url:'/logistic/vasKantouPerformance'}
  , {id:'000256', name:'AFSランドポート習志野センター', url:'/logistic/narashinoPerformance'}
  , {id:'000006', name:'AFS尾西センター', url:'/logistic/vasPerformance'}
  , {id:'000011', name:'福通 神戸カインズセンター', url:'/logistic/sorterPerformance'}
  ]);

  const [weekly,setWeekly] = useState();
  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }

  // 機能メニューの閉じるイベント
  const onCloseFunction = (event) => {
    setViewFunction(false);
  }
  const onClickSummaryButton = (num) => {
    setSammarytype(num)
    dispatch(SetChangeType(num))
  }
  // 描画時に一度だけ実行
  useEffect(() => {
    dispatch(SetChangeType(0))
  }, [])
  // POST・PUT処理
  const onSubmit = (data) => {
  }


  if (!searchFromDateRef.current) {
    searchFromDateRef.current = LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD')
    searchToDateRef.current = LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD')
    // if(hisState && hisState.searchDate){
    //   searchDateRef.current = LogisticUtility.getStringFromDate(new Date(hisState.searchDate), 'YYYY-MM-DD')
    // }else{
    //   searchDateRef.current = LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD')
    // }   
  }
  return (
    <>
      <BoardBaseFormRow>
      <Search
        onClickFunction={onClickFunction}
        onCloseFunction={onCloseFunction}
        viewFunction={viewFunction}
        onClickSummaryButton={(num)=>onClickSummaryButton(num)}
        summaryType={summaryType}
        searchFromDateRef={searchFromDateRef}
        searchToDateRef={searchToDateRef}
        companyRef={companyRef}
        companyList={companyList}
        workplaceRef={workplaceRef}
        workplaceList={workplaceList}
      />
      </BoardBaseFormRow>
      <BoardBaseFormRow>
        <BoardBaseFormRowScroll>
          <ProgressPerformance/>
        </BoardBaseFormRowScroll>
      </BoardBaseFormRow>
    </>
  )
}

export default Detail

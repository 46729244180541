import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'

const MediaWrap = styled.div`
  width:680px;
  display: flex; /* 横並び */
  flex-wrap: wrap;
  margin: 4px;
  box-shadow: 0 0 3px 2px ${props => props.selected ? 'rgba(0,128,0,0.8)' : 'rgba(0,0,0,0.4)'};

  color: #000;
  font-size: 12px;
`
const MediaItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 40px;
  border-right: 2px dashed #fff;
`
const MediaRow = styled.div`
  display: flex; /* 縦並び */
  flex-direction: row;
  height: 40px;

  align-items: center;
`
const TextWrap = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
` 

// 項目名
function getTitle(data) {
  let text = '';

  if (data.processId === '') {
    return text
  }
  
  {
    text = <>{data.processName}<br/>({data.processId})</>
  }

  return text;
}

function getItem(staffNum,processHour) {
  if(staffNum==null || processHour==null) return;

  let processHourText = LogisticUtility.getCommaText(processHour.toFixed(2));
  let staffNumText = LogisticUtility.getCommaText(staffNum.toFixed(2));

  return <>{processHourText} h<br/>({staffNumText} 人)</>
}
function getAverage(processHour,processSumHour) {
  if(processSumHour==null || processHour==null) return;
  
  let ratio = 0.00;
  
  if(processSumHour !== 0 && processHour !== 0){
    ratio = processHour * 100 / processSumHour;
  }  
  let value = ratio.toFixed(2); 

  return <>{value} %</>
}
function getAverageMonth(processSumHour) {
  if(processSumHour==null) return;
  
  let ratio = 0.00;
  if(processSumHour !== 0){
    var nowDate = new Date().getDate()
    ratio = processSumHour/nowDate;
  }  
  let value = ratio.toFixed(2); 

  return <>{value} h</>
}
function getMonthRaito(processSumHour,monthProcessTime) {

  let ratio = 0.00;
  if(processSumHour !== 0){
    ratio = monthProcessTime/processSumHour * 100;
  }  
  let value = ratio.toFixed(2); 

  return <>{value} ％</>
}
const MediaDataCarbonEmissions = (props) => {
  const {
    data,before,sumProcessTime
  } = props

  // 初期値
  useEffect(() => {
  })

  //if (!data) return null;
  //console.log(data);
  return (
    <MediaWrap>
      <MediaItem>
        <TextWrap style={{width:'185px'}}>
          <div>{getTitle(data)}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'95px'}}>
          <div>{getItem(data.processStaff,data.processTime)}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'95px'}}>
          <div>{getItem(before.processStaff,before.processTime)}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'95px'}}>
          <div>{getAverageMonth(data.monthProcessTime)}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'95px'}}>
          <div>{getItem(data.monthProcessStaff,data.monthProcessTime)}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'95px'}}>
          <div>{getMonthRaito(sumProcessTime,data.monthProcessTime)}</div>
        </TextWrap>
      </MediaItem>
    </MediaWrap>
  )
}

MediaDataCarbonEmissions.propTypes = {
  grants: PropTypes.object, // 権限関連
  data: PropTypes.object, // CO2排出情報
  totalEmissions: PropTypes.number, // CO2排出量の合計値
}

export default MediaDataCarbonEmissions

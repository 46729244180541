import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
//import { useAlert } from 'react-alert'
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'
//import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'

import FUNC from '../../Common/FunctionEnum'

import {BoardBaseFormRow, BoardBaseFormRowScroll} from '../Wrap/BoardBaseWrap'

import Tooltip from '@material-ui/core/Tooltip'

import IconChangeImg from '../../../image/page-type-change-icon.png'
import Search from './Search'
import SideSubMenu from '../SideSubMenu'
import OperationBoard from './OperationBoard'
import ProgressBoard from './ProgressBoard'
import ProgressGraphBoard from './ProgressGraphBoard'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  overflow-y: none;
  overflow-x: none;
`

const RealTimeProgrss = (props) => {
  const { loginUserInfo } = useSelector(state => state);
  const [viewFunction,setViewFunction] = useState(false);
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC);
  const subGrants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC_DASHBOARD);

  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }

  // 機能メニューの閉じるイベント
  const onCloseFunction = (event) => {
    setViewFunction(false);
  }

  useEffect(() => {
    if (grants && subGrants)
      document.title = grants.functionMenuName + '【' + subGrants.functionMenuName + '】 | CoreBoard'
    else if (grants)
      document.title = grants.functionMenuName + ' | CoreBoard'
    else
      document.title = 'CoreBoard'
  }, [grants, subGrants])
    
  // 描画時に一度だけ実行
  useEffect(() => {
  }, [])

  const [wageType,setWageType]=useState(0)
  const onClickChangeWageType = () => {
    setWageType(wageType?0:1);
  }

  return (
    <>
      <Search
        onClickFunction={onClickFunction}
        onCloseFunction={onCloseFunction}
        viewFunction={viewFunction}
      />
      <BoardBaseFormRow>
        <SideSubMenu height={'84vh'}/>
        <BoardBaseFormRowScroll>
          <ContentWrap>
            <div style={{display:'flex',justifyContent:'flex-end',marginRight:'120px', cursor:'pointer'}}>
            <Tooltip title={'平均時給切替'}>
              <div onClick={()=>onClickChangeWageType()}><img src={IconChangeImg} width="28px" height="28px" alt="表示切替"/></div>
            </Tooltip>
            </div>
            <ProgressBoard wageType={wageType}/>
            <ProgressGraphBoard wageType={wageType}/>
            <OperationBoard />
          </ContentWrap>
        </BoardBaseFormRowScroll>
      </BoardBaseFormRow>
    </>
  )
}

export default RealTimeProgrss

import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'

const MediaWrap = styled.div`
  width:840px;
  display: flex; /* 横並び */
  flex-wrap: wrap;
  margin: 4px;
  box-shadow: 0 0 3px 2px ${props => props.selected ? 'rgba(0,128,0,0.8)' : 'rgba(0,0,0,0.4)'};

  color: #000;
  font-size: 16px;


  text-align: center;
  align-items: center;
`
const MediaItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 40px;
  border-right: 2px dashed #fff;
`

const TextWrap = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
` 

// 項目名
function getTitle(data) {
  let text = '';

  if (data.categoryName === '') {
    text = 'スコープ' + Number(data.scopeNum);
  }
  else {
    text = 'カテゴリ' + Number(data.categoryId) + "　" + data.categoryName;
  }

  return text;
}

// CO2排出の割合
function getRatio(emissions, totalEmissions) {
  //console.log(emissions);
  //console.log(totalEmissions);
  if (totalEmissions === 0)
    return '－';
  
  let ratio = emissions * 100 / totalEmissions;
  let value = ratio.toFixed(2); 

  return LogisticUtility.getCommaText(value);
}

const MediaDataCarbonEmissions = (props) => {
  const {
    grants,
    data,
    totalEmissions,
  } = props

  // 初期値
  useEffect(() => {
  })

  //if (!data) return null;
  //console.log(data);
  return (
    <MediaWrap>
      <MediaItem>
        <TextWrap style={{width:'500px', textAlign:'left'}}>
          <div style={{marginLeft:'1rem', fontWeight:'bold'}}>{getTitle(data)}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'150px', textAlign:'right'}}>
          <div style={{marginRight:'1rem'}}>{LogisticUtility.getCommaText(data.emissions.toFixed(4))}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'150px', textAlign:'right'}}>
          <div style={{marginRight:'1rem'}}>{getRatio(data.emissions, totalEmissions)}</div>
        </TextWrap>
      </MediaItem>
    </MediaWrap>
  )
}

MediaDataCarbonEmissions.propTypes = {
  grants: PropTypes.object, // 権限関連
  data: PropTypes.object, // CO2排出情報
  totalEmissions: PropTypes.number, // CO2排出量の合計値
}

export default MediaDataCarbonEmissions

import React, { useState, useEffect,useRef } from 'react'
import { useSelector } from 'react-redux'
import { Prompt } from 'react-router'
//import { useAlert } from 'react-alert'
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'
//import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'

import FUNC from '../../Common/FunctionEnum'

import {BoardBaseFormRow, BoardBaseFormRowScroll} from '../Wrap/BoardBaseWrap'

import Tooltip from '@material-ui/core/Tooltip'
import { useDispatch } from 'react-redux'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
// import IconChangeImg from '../../../image/page-type-change-icon.png'
import Search from './Search'
import SideSubMenu from '../SideSubMenu'
// import OperationBoard from './OperationBoard'
import PlanPerformance from './PlanPerformance'
// import ProgressGraphBoard from './ProgressGraphBoard'
import * as LogisticUtility from '../../Common/Utilties/LogisticUtility'
import { Location, useLocation } from 'react-router-dom'

import EditDialog from './Atoms/EditDialog'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  overflow-y: none;
  overflow-x: none;
`

const PlanningDate = (props) => {
  const { loginUserInfo } = useSelector(state => state);
  const [viewFunction,setViewFunction] = useState(false)
  const [viewEdit,setViewEdit] = useState(false);
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC);
  const subGrants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC_DASHBOARD);
  const searchDateRef = useRef()
  
  const history = useLocation(); 
  const hisState = history.state

  const [updateFlag,setUpdateFlag] = useState(false);

  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }

  // 機能メニューの閉じるイベント
  const onCloseFunction = (event) => {
    setViewFunction(false);
  }

  // 編集メニューボタンのクリックイベント
  const onClickEdit = (event) => {
    setViewEdit(true);
  }
  
  // 編集メニューの閉じるイベント
  const onCloseEdit = (event) => {
    setViewEdit(false);
  }
  useEffect(() => {
    if (grants && subGrants)
      document.title = grants.functionMenuName + '【' + subGrants.functionMenuName + '】 | CoreBoard'
    else if (grants)
      document.title = grants.functionMenuName + ' | CoreBoard'
    else
      document.title = 'CoreBoard'
  }, [grants, subGrants])
    
  // 描画時に一度だけ実行
  useEffect(() => {
  },[]);

  //ページ遷移前の更新破棄の確認
  //更新キャンセル処理
  useEffect(() => {
    // イベントの設定
    window.addEventListener('beforeunload', onUnload);
    window.addEventListener('popstate', onUnload);
  
    return () => {
      // イベントの設定解除
      window.removeEventListener('beforeunload', onUnload);
      window.addEventListener('popstate', onUnload);
    }
  })

  const onUnload = (e) => {
    if(updateFlag){
      e.preventDefault();
      e.returnValue = '';
    }
  }
  //2023/12/27 K.Harada ADD 日付初期値挿入用
  const dispatch = useDispatch();
  const onChange=(date)=>{
    searchDateRef.current = date
    dispatch(SetLoadSpinner(true));
  }
  if (!searchDateRef.current) {
    console.log(history)
    if(hisState && hisState.searchDate){
      searchDateRef.current = LogisticUtility.getStringFromDate(new Date(hisState.searchDate), 'YYYY-MM-DD')
    }else{
      searchDateRef.current = LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD')
    }   
  }
  return (
    <>
      <Prompt
        when={updateFlag}
        message='編集中のデータを破棄しますか?'
      />
      <Search
        onClickFunction={onClickFunction}
        onCloseFunction={onCloseFunction}
        onClickEdit={onClickEdit}
        viewFunction={viewFunction}
        searchDateRef={searchDateRef}
        onChange={(date)=>onChange(date)}
      />
      <BoardBaseFormRow>
        <SideSubMenu height={'84vh'} planExisit={true} pageNum={1}/>
        <BoardBaseFormRowScroll>
          <ContentWrap>
            <PlanPerformance 
              editFlag={viewEdit} 
              onCloseEdit={onCloseEdit} 
              updateFlag={updateFlag} 
              setUpdateFlag={setUpdateFlag}
              searchDate={searchDateRef.current}//2023/12/28 K.Harada ADD 日付を選択できるように変更
            />
          </ContentWrap>
        </BoardBaseFormRowScroll>
      </BoardBaseFormRow>
      {/* <EditDialog
      open={viewEdit}
      onCloseFunc={onCloseEdit}
    /> */}
    </>
  )
}

export default PlanningDate

import React, { useState, useEffect,useRef } from 'react'
import { useSelector } from 'react-redux'
//import { useAlert } from 'react-alert'
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'
//import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import { GetApi } from '../../../Common/ApiAxios'
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'
import { useLocation } from 'react-router-dom'
//import ErrorHandling from '../../Common/ErrorHandling'

import FUNC from '../../../Common/FunctionEnum'

import {BoardBaseFormRow, BoardBaseFormRowScroll} from '../../Wrap/BoardBaseWrap'

//import Tooltip from '@material-ui/core/Tooltip'

//import IconChangeImg from '../../../image/page-type-change-icon.png'
import Search from './Search'
//import SideSubMenu from '../SideSubMenu'
// import ProgressPerformance from './ProgressPerformance'
// import ShootPerformance from './ShootPerformance'
import ItemPerformance from './StorePerformance'

import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'

import SideSubMenu from '../../SideSubMenu'

const DefaultUrl = '/api/logisticManagement'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  overflow-y: none;
  overflow-x: none;
`

const VASPerformance = (props) => {
  const { loginUserInfo } = useSelector(state => state);
  const [viewFunction,setViewFunction] = useState(false);
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC);
  const subGrants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.LOGISTIC_DASHBOARD);
  const dispatch = useDispatch();
  const alert = useAlert();

  const history = useLocation(); 
  const hisState = history.state
  
  const ownerCd = history.state?history.state.ownerCd:1
  const type = history.state?hisState.type:0
  const pattran = history.state?history.state.pattrnName:'RETAIL'

  /*2023/12/19 K.Harada ADD 1便,2便を表示するように変更 */
  const batchNo = history.state&&history.state.batchNo?history.state.batchNo:1

  /*2023/01/25 K.Harada ADD 現場IDを表示するように変更 */
  const baseId = history.state&&history.state.baseId?history.state.baseId:2
  const workplaceId = history.state&&history.state.workplaceId?history.state.workplaceId:'000006'
  const ownerName = history.state&&history.state.ownerName?history.state.ownerName:''

  const [progressList,setProgressList] = useState([]);
  const searchDateRef = useRef(null)
  const { SettingInfo } = useSelector(state => state)
  
  const listFlagRef = useRef();

  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }

  // 機能メニューの閉じるイベント
  const onCloseFunction = (event) => {
    setViewFunction(false);
  }

  useEffect(() => {
    if (grants && subGrants)
      document.title = grants.functionMenuName + '【' + subGrants.functionMenuName + '】 | CoreBoard'
    else if (grants)
      document.title = grants.functionMenuName + ' | CoreBoard'
    else
      document.title = 'CoreBoard'
  }, [grants, subGrants])

      // 工程情報の取得
  const apiGetProcessInfo = async () => {

    const apiUrl = DefaultUrl + '/getVasShippingOwnerStoreGraph';
    dispatch(SetLoadSpinner(true));
    var now = new Date(searchDateRef.current);
    var now1 = new Date(searchDateRef.current);
    now.setDate(now.getDate() + 1);
    const param = {
      baseId: baseId,//2,
      workplaceId: workplaceId,//'000006',
      ownerCd: ownerCd,
      targetYMD: LogisticUtility.getStringFromDate(now, 'YYYYMMDD'),
      targetDate: LogisticUtility.getStringFromDate(now1, 'YYYY/MM/DD'),
      batchNo: batchNo /*2023/12/19 K.Harada ADD 1便,2便を表示するように変更 */
    }
    //console.log(param);
    const result = await GetApi(apiUrl, param);

    dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('週情報取得失敗');
      setProgressList([]);
    } else {
      //console.log(result);
      var tmp = listFlagRef.current.value==0?lowerData(result.data):upperData(result.data)//:upperData(result.data)
      setProgressList(tmp);
    }
  }
      // 工程情報の取得
  const apiGetProcessPatternInfo = async () => {

    const apiUrl = DefaultUrl + '/getVasShippingPatternStoreGraph';
    dispatch(SetLoadSpinner(true));
    var now = new Date(searchDateRef.current);
    var now1 = new Date(searchDateRef.current);
    now.setDate(now.getDate() + 1);
    const param = {
      baseId: baseId,//2,
      workplaceId: workplaceId,//'000006',
      ownerCd: ownerCd,
      patternName: pattran,
      targetYMD: LogisticUtility.getStringFromDate(now, 'YYYYMMDD'),
      targetDate: LogisticUtility.getStringFromDate(now1, 'YYYY/MM/DD'),
      blockName: ''
    }
    if(pattran == '米飯　BIG北・信'){
      param.blockName = "'ニ','サン','ヨン'"
    }
    if(pattran == '米飯　BIG中松愛'){
      param.blockName = "'イチ'"
    }
    //console.log(param);
    const result = await GetApi(apiUrl, param);

    dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('週情報取得失敗');
      setProgressList([]);
    } else {
      //console.log(result);
      var tmp = listFlagRef.current.value==0?lowerData(result.data):upperData(result.data)//:upperData(result.data)
      setProgressList(tmp);
    }
  }
  const lowerData =(data)=> {
    // 昇順
    data = data.sort(function(a, b) {
      return Number(a.vasShippingItemInfo.totalItemCount) - Number(b.vasShippingItemInfo.totalItemCount);
    });

    return data
  }
  const upperData =(data)=> {
    // 降順
    data = data.sort(function(a, b) {
      return Number(b.vasShippingItemInfo.totalItemCount) - Number(a.vasShippingItemInfo.totalItemCount);
    });

    return data
  }
  //数値をカンマ区切りに変更
  const onChangeType = ( event ) => {
    console.log(event.target.value)

    if(event.target.value==0){ 
      setProgressList(JSON.parse(JSON.stringify(lowerData(progressList))) )
    }else{
      setProgressList(JSON.parse(JSON.stringify(upperData(progressList))) )
    }
  }
  const intervalRef = useRef(null);
  const [windowType,setWindowType]=useState(0)
  
  // 周期初期のクリア
  const onClearInerval = async () => {
      if (intervalRef.current !== null)
        clearInterval(intervalRef.current);
      
      intervalRef.current = null;
  }
  
  // 描画時に一度だけ実行
  useEffect(() => {
      type==0?apiGetProcessInfo(true):apiGetProcessPatternInfo(true)

      intervalRef.current = setInterval(() => {
        type==0?apiGetProcessInfo(true):apiGetProcessPatternInfo(true)
      }, 1000 * 60 * 5); // 5分間隔で変更
      return () => {
        onClearInerval();
      };
  }, [])


  if (!searchDateRef.current) {
      if(hisState && hisState.searchDate){
        searchDateRef.current = LogisticUtility.getStringFromDate(new Date(hisState.searchDate), 'YYYY-MM-DD')
      }else{
        searchDateRef.current = LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD')
      }   
  }
  return (
    <>
      <Search
        onClickFunction={onClickFunction}
        onCloseFunction={onCloseFunction}
        viewFunction={viewFunction}
        searchDateRef={searchDateRef}
        onChangeType={(e)=>onChangeType(e)}
        ownerCd={ownerCd}
        pattran={pattran}
        listFlagRef={listFlagRef}
        workplaceId={workplaceId}
        ownerName={ownerName}
      />
      <BoardBaseFormRow>
        <SideSubMenu height={'84vh'} planExisit={true}/>
        <BoardBaseFormRowScroll>
          <ContentWrap>
            <ItemPerformance searchDate={searchDateRef.current} progressList={progressList}/>
          </ContentWrap>
        </BoardBaseFormRowScroll>
      </BoardBaseFormRow>
    </>
  )
}

export default VASPerformance

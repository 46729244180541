import React, { useEffect } from 'react'
import styled from 'styled-components'
//import PropTypes from 'prop-types'

const MediaWrap = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 1660px; /* 100% - margin-left - margin-right */
  margin: 4px;
  box-shadow: 0 0 3px 2px 'rgba(0,0,0,0.4)';
  background-color: #649CDF;
  color: #fff;
  font-size: 16px;
  font-weight: bold;

  text-align: center;
  align-items: center;
`
const MediaItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 60px;
  border-right: 2px dashed #fff;
`

const DateItem = styled.span`
  width: 110px;
  font-size: 24px;
  margin: auto;
`

const HealthTitle = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
` 

const HealthWrap = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
`

const MediaStaffHeaderHealthCareDaily = (props) => {
  const {
  } = props

  // 初期値
  useEffect(() => {
  })

  return (
    <MediaWrap>
      <MediaItem>
        <DateItem>日付</DateItem>
      </MediaItem>
      <MediaItem>
        <div style={{marginTop:'0.5rem', marginBottom:'0.25rem'}}>
          <HealthTitle style={{marginBottom:'0.5rem'}}>実績</HealthTitle>
          <HealthWrap>
            <HealthTitle style={{width:'100px'}}>作業時間</HealthTitle>
            <HealthTitle style={{width:'100px'}}>休憩時間</HealthTitle>
            <HealthTitle style={{width:'100px'}}>残業時間</HealthTitle>
            <HealthTitle style={{width:'100px'}}>作業日数</HealthTitle>
          </HealthWrap>
        </div>
      </MediaItem>
      <MediaItem>
        <div style={{marginTop:'0.5rem', marginBottom:'0.25rem'}}>
          <HealthTitle style={{marginBottom:'0.5rem'}}>アクティビティ</HealthTitle>
          <HealthWrap>
            <HealthTitle style={{width:'100px'}}>歩数</HealthTitle>
            <HealthTitle style={{width:'100px'}}>距離(km)</HealthTitle>
            <HealthTitle style={{width:'100px'}}>カロリー(cal)</HealthTitle>
            <HealthTitle style={{width:'100px'}}>睡眠(h)</HealthTitle>
            <HealthTitle style={{width:'100px'}}>睡眠スコア</HealthTitle>
            <HealthTitle style={{width:'100px'}}>心拍数</HealthTitle>
            <HealthTitle style={{width:'100px'}}>体温(℃)</HealthTitle>
            <HealthTitle style={{width:'100px'}}>疲労度</HealthTitle>
          </HealthWrap>
        </div>
      </MediaItem>
      <MediaItem>
        <div style={{margin:'auto'}}>
          <HealthTitle style={{width:'120px', marginBottom:'0.25rem'}}>アルコール</HealthTitle>
          <HealthWrap>
            <HealthTitle>チェック</HealthTitle>
          </HealthWrap>
        </div>
      </MediaItem>
      <MediaItem>
        <div style={{margin:'auto'}}>
          <HealthTitle style={{width:'180px'}}>アラート</HealthTitle>
        </div>
      </MediaItem>
    </MediaWrap>
  )
}

MediaStaffHeaderHealthCareDaily.propTypes = {
}

export default MediaStaffHeaderHealthCareDaily

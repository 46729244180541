import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
//import { useAlert } from 'react-alert'
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'
//import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
//import { useSyncExternalStore } from 'react'

//import FUNC from '../Common/FunctionEnum'
import { GetApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import * as LogisticUtility from '../../Common/Utilties/LogisticUtility'
//import LogisticCard from './Atoms/LogisticCard'
//import TruckCard from './Atoms/TruckCard'
//import CoreTimeCard from './Atoms/CoreTimeCard'
//import CoreFirstCard from './Atoms/CoreFirstCard'
//import InfoCard from './Atoms/InfoCard'
import { SetChangeType } from '../../Common/Slice/SettingSlice'
import Search from './Search'
import ProgressPerformance from './ProgressPerformance'
import {BoardBaseFormRow, BoardBaseFormRowScroll} from '../Wrap/BoardBaseWrap'

const DefaultUrl = '/api/afsTruckDashboardManagement/dashboard'

const InfoContent = styled.div`
  width: calc(100% - 10px);
  height: calc(100% - 200px);
`

const Home = (props) => {
  const [viewFunction,setViewFunction] = useState(false);
  /*2023/12/25 K.Harada MOD 前日進捗を初期値に変更*/
  const [summaryType,setSammarytype] = useState(0);
  const dispatch = useDispatch()
  //const alert = useAlert()
  //const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.TRUCK);
  //const subGrants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.TRUCK_HEALTHCARE);
  //const { loginUserInfo } = useSelector(state => state);

  const intervalRef = useRef(null);

  /*2023/12/25 K.Harada MOD 前日進捗を初期値に変更*/
  const [matehan,setMatehan] = useState();
  const [result,setResult] = useState({});

  // 平均時給情報の取得
  const apiGetAFSMatehan = async (isLoadSplinner) => {
    //console.log('call apiGetAvgWageGraph');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getAFSMatehan';
   
    const data = {
      startDt: '2023/09/01',
      endDt: '2023/09/01',//LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD'),
      shippingFlag: 0
    }
    const result1 = await GetApi(apiUrl, data);
    data.shippingFlag = 1
    const result2 = await GetApi(apiUrl, data);

    const apiUrl2 = DefaultUrl + '/getResult';
    const result3 = await GetApi(apiUrl2, data);
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));

    if (result1.errorDetail || result2.errorDetail) {
      //alert.error('');
      setMatehan([])
      setResult({})
    } else {
      console.log(result1,result2,result3)
      setMatehan([result1.data,result2.data])
      setResult(result3.data)
    }
  }

  // 周期初期のクリア
  const onClearInerval = async () => {
    if (intervalRef.current !== null)
      clearInterval(intervalRef.current);
    
    intervalRef.current = null;
  }

  const [weekly,setWeekly] = useState();
  // 機能メニューボタンのクリックイベント
  const onClickFunction = (event) => {
    setViewFunction(!viewFunction);
  }

  // 機能メニューの閉じるイベント
  const onCloseFunction = (event) => {
    setViewFunction(false);
  }
  const onClickSummaryButton = (num) => {
    setSammarytype(num)
    dispatch(SetChangeType(num))
  }
  // 描画時に一度だけ実行
  useEffect(() => {
    /*2023/12/25 K.Harada MOD 前日進捗を初期値に変更*/
    dispatch(SetChangeType(0))

    /*2024/01/16 K.Harada ADD マテハン情報をDBから受け取るように変更*/
    apiGetAFSMatehan(true)
    intervalRef.current = setInterval(() => {
      apiGetAFSMatehan()
    }, 1000 * 60 * 5); // 300秒間隔で変更
    
    return () => {
      onClearInerval();
    };
    /*2024/01/16 K.Harada ADD マテハン情報をDBから受け取るように変更*/
  }, [])
  // POST・PUT処理
  const onSubmit = (data) => {
  }
  return (
    <>
      <BoardBaseFormRow>
      <Search
        onClickFunction={onClickFunction}
        onCloseFunction={onCloseFunction}
        onClickSummaryButton={(num)=>onClickSummaryButton(num)}
        viewFunction={viewFunction}
        summaryType={summaryType}
      />
      </BoardBaseFormRow>
      <BoardBaseFormRow>
        <BoardBaseFormRowScroll>
          <ProgressPerformance
            matehan={matehan}
            result={result}
          />
        </BoardBaseFormRowScroll>
      </BoardBaseFormRow>
    </>
  )
}

export default Home

import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const CardWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  height: 200px;
  background-color: #fafafa;
  border-radius: 10px;
  border: none;

  color: #000;
`

const HeaderContent = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 100%;
  text-align: center;
  margin-left: 1rem;
  margin-right: auto;
  margin-top: 1rem;
`

const Title = styled.span`
  font-size: 20px;
  font-weight: bold;
`

const Content = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
`

const Wrap = styled.div`
  display:flex; /* 縦並 */
  flex-flow: column;
`

/* 項目 */
const Item = styled.div`
  display: flex; /* 横並び */
  flex-flow: row nowrap;
  text-align: left;
  vertical-align: middle;
`


// ヘルスケアアラート項目説明
const HealthCareAlertDescription = (props) => {
  const {
    no,
  } = props


  // 描画時に一度だけ実行
  useEffect(() => {
  }, []);

  if (!no) return null;
  return (
    <CardWrap >
      <HeaderContent>
        {no === 1 && 
          <Title>睡眠スコア</Title>
        }
        {no === 2 && 
          <Title>睡眠時間</Title>
        }
        {no === 3 && 
          <Title>疲労度</Title>
        }
        {no === 4 && 
          <Title>作業時間</Title>
        }
        {no === 5 && 
          <Title>心拍数</Title>
        }
      </HeaderContent>
      <Content>
        {no == 1 && 
        <Wrap style={{marginLeft:'0.25rem', marginTop:'0.25rem'}}>
          <Item style={{marginLeft:'20px', marginTop:'1rem'}}><span style={{width:'5rem'}}>90～100点</span><span style={{width:'1.5rem'}}>：</span>非常によい</Item>
          <Item style={{marginLeft:'20px', marginTop:'0.5rem'}}><span style={{width:'5rem'}}>80～89点</span><span style={{width:'1.5rem'}}>：</span>よい</Item>
          <Item style={{marginLeft:'20px', marginTop:'0.5rem'}}><span style={{width:'5rem'}}>60～79点</span><span style={{width:'1.5rem'}}>：</span>やや低い</Item>
          <Item style={{marginLeft:'20px', marginTop:'0.5rem'}}><span style={{width:'5rem'}}>60点未満</span><span style={{width:'1.5rem'}}>：</span>低い</Item>
          <Item style={{marginLeft:'20px', marginTop:'0.5rem'}}>平均は72～82点</Item>
        </Wrap>
        }
        {no === 2 &&
        <Wrap style={{marginLeft:'0.25rem', marginTop:'0.25rem'}}>
          <Item style={{marginLeft:'20px', marginTop:'1rem'}}>理想の睡眠時間は6～7時間</Item>
          <Item style={{marginLeft:'20px', marginTop:'0.5rem'}}>日本人の労働者の平均は7時間</Item>
        </Wrap>
        }
        {no === 3 &&
        <Wrap style={{marginLeft:'0.25rem', marginTop:'0.25rem'}}>
          <Item style={{marginLeft:'20px', marginTop:'1rem'}}><span style={{width:'5rem'}}>81点以上</span><span style={{width:'1.5rem'}}>：</span>かなりきつい</Item>
          <Item style={{marginLeft:'20px', marginTop:'0.5rem'}}><span style={{width:'5rem'}}>71～80点</span><span style={{width:'1.5rem'}}>：</span>きつい</Item>
          <Item style={{marginLeft:'20px', marginTop:'0.5rem'}}><span style={{width:'5rem'}}>61～70点</span><span style={{width:'1.5rem'}}>：</span>ややきつい</Item>
          <Item style={{marginLeft:'20px', marginTop:'0.5rem'}}><span style={{width:'5rem'}}>60点以下</span><span style={{width:'1.5rem'}}>：</span>楽である</Item>
        </Wrap>
        }
        {no === 4 &&
        <Wrap style={{marginLeft:'0.25rem', marginTop:'0.25rem'}}>
          <Item style={{marginLeft:'20px', marginTop:'1rem'}}>作業時間は残業時間を含みます</Item>
          <Item style={{marginLeft:'20px', marginTop:'0.5rem'}}>（法：週）は労働基準法第32条で定められた労働時間</Item>
          <Item style={{marginLeft:'20px', marginTop:'0.5rem'}}>週40時間です。</Item>
          <Item style={{marginLeft:'20px', marginTop:'0.5rem'}}>1日最大8時間（※法定休日も含めた合計値）</Item>
          <Item style={{marginLeft:'20px', marginTop:'0.5rem'}}>本来は法定休日は含みません</Item>
        </Wrap>
        }
        {no === 5 &&
        <Wrap style={{marginLeft:'0.25rem', marginTop:'0.25rem'}}>
          <Item style={{marginLeft:'20px', marginTop:'1rem'}}>50〜100未満であれば正常</Item>
          <Item style={{marginLeft:'20px', marginTop:'0.5rem'}}>運動時で120～130ぐらい</Item>
          <Item style={{marginLeft:'20px', marginTop:'0.5rem'}}>50未満の場合不整脈、徐脈の可能性</Item>
          <Item style={{marginLeft:'20px', marginTop:'0.5rem'}}>40で危険な状態</Item>
          <Item style={{marginLeft:'20px', marginTop:'0.5rem'}}>140以上は危険な状態</Item>
        </Wrap>
        }
      </Content>
    </CardWrap>
  )
}

HealthCareAlertDescription.propTypes = {
  no: PropTypes.object, // 番号
}

export default HealthCareAlertDescription

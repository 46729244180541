import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'

import IconImg from '../../../../image/expand-icon.png'

const MediaWrap = styled.div`
  width:1800px;
  display: flex; /* 横並び */
  flex-wrap: wrap;
  margin: 4px;
  box-shadow: 0 0 3px 2px ${props => props.selected ? 'rgba(0,128,0,0.8)' : 'rgba(0,0,0,0.4)'};

  color: #000;
  font-size: 16px;

  background-color:  ${props => props.colorFlag ? 'gray' : ''};
  text-align: center;
  align-items: center;
`
const MediaItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 40px;
  border-right: 2px dashed #fff;
`

const TextWrap = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
` 

  //アイテム数集計  
  const sumItem=(progressList)=>{
    var sum = 0
    progressList &&
    progressList.vasCargoItemInfoList &&
    progressList.vasCargoItemInfoList.map((item,index)=>{
      sum += parseInt(item.totalItem)
    })
    if(isNaN(sum))
     sum=0
    return LogisticUtility.getCommaText(sum)
  }

const MediaDataItemEmissions = (props) => {
  const {
    searchDate,
    data,onClick,cargoData,
    type
  } = props

  // 初期値
  useEffect(() => {
  })

  //if (!data) return null;
  //console.log(data);
  return (
    <MediaWrap colorFlag={data.totalItemCount == '1.0000'}>
      <MediaItem>
        <TextWrap style={{width:'150px', textAlign:'left'}}>
          <div style={{marginLeft:'1rem'}}>{data.shippingCode}</div>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'200px', textAlign:'left'}}>
          <div style={{marginLeft:'1rem', fontWeight:'bold'}}>{data.shippingName}</div>
        </TextWrap>
      </MediaItem>
      {type == 0 &&
      <>
        <MediaItem>
          <TextWrap style={{width:'100px', textAlign:'right'}}>
            <div style={{marginRight:'1rem'}}>{LogisticUtility.getCommaText(parseInt(data.totalItemCount))}</div>
          </TextWrap>
        </MediaItem>
        <MediaItem>
          <TextWrap style={{width:'100px', textAlign:'right'}}>
            <div style={{marginRight:'1rem'}}>{LogisticUtility.getCommaText(parseInt(data.totalItem))}</div>
          </TextWrap>
        </MediaItem>
        <MediaItem>
          <TextWrap style={{width:'100px', textAlign:'right'}}>
            <div style={{marginRight:'1rem'}}>{LogisticUtility.getCommaText(parseInt(data.cargoCount))}</div>
          </TextWrap>
        </MediaItem>
        <MediaItem>
          <TextWrap style={{width:'100px', textAlign:'right'}}>
            <div style={{marginRight:'1rem'}}>{'-'}</div>
          </TextWrap>
        </MediaItem>
        <MediaItem>
          <TextWrap style={{width:'150px', textAlign:'left'}}>
            <div style={{marginLeft:'1rem'}}>{'-'}</div>
          </TextWrap>
        </MediaItem>
        <MediaItem>
          <TextWrap style={{width:'150px', textAlign:'left'}}>
            <div style={{marginLeft:'1rem'}}>{'-'}</div>
          </TextWrap>
        </MediaItem>
        <MediaItem>
          <TextWrap style={{width:'300px', textAlign:'left'}}>
            <div style={{marginLeft:'1rem'}}>{'-'}</div>
          </TextWrap>
        </MediaItem>
        <MediaItem>
          <TextWrap style={{width:'100px', textAlign:'right'}}>
            <div style={{marginRight:'1rem'}}>{'-'}</div>
          </TextWrap>
        </MediaItem>
        <MediaItem>
        {!!parseInt(data.totalItem) && 
          <TextWrap style={{width:'50px', textAlign:'right',cursor: 'pointer'}} onClick={onClick}>
            <img src={IconImg} style={{marginRight:'8px',transform: data.display?'rotate(180deg)':''}}/>
          </TextWrap>
        }
        </MediaItem>
      </>
      }
      {type == 1 && cargoData &&
      <>
        <MediaItem>
          <TextWrap style={{width:'100px', textAlign:'right'}}>
            <div style={{marginRight:'1rem'}}>{LogisticUtility.getCommaText(cargoData.vasCargoItemInfoList.length)}</div>
          </TextWrap>
        </MediaItem>
        <MediaItem>
          <TextWrap style={{width:'100px', textAlign:'right'}}>
            <div style={{marginRight:'1rem'}}>{sumItem(cargoData)}</div>
          </TextWrap>
        </MediaItem>
        <MediaItem>
          <TextWrap style={{width:'100px', textAlign:'right'}}>
            <div style={{marginRight:'1rem'}}>{'-'}</div>
          </TextWrap>
        </MediaItem>
        <MediaItem>
          <TextWrap style={{width:'100px', textAlign:'right'}}>
            <div style={{marginRight:'1rem'}}>{'-'}</div>
          </TextWrap>
        </MediaItem>
        <MediaItem>
          <TextWrap style={{width:'150px', textAlign:'left'}}>
            <div style={{marginLeft:'1rem'}}>{cargoData.cargoId}</div>
          </TextWrap>
        </MediaItem>
        <MediaItem>
          <TextWrap style={{width:'150px', textAlign:'left'}}>
            <div style={{marginLeft:'1rem'}}>{'-'}</div>
          </TextWrap>
        </MediaItem>
        <MediaItem>
          <TextWrap style={{width:'300px', textAlign:'left'}}>
            <div style={{marginLeft:'1rem', fontWeight:'bold'}}>{'-'}</div>
          </TextWrap>
        </MediaItem>
        <MediaItem>
          <TextWrap style={{width:'100px', textAlign:'right'}}>
            <div style={{marginRight:'1rem'}}>{'-'}</div>
          </TextWrap>
        </MediaItem>
        <MediaItem>
        {!!parseInt(data.totalItem) && 
          <TextWrap style={{width:'50px', textAlign:'right',cursor: 'pointer'}} onClick={onClick}>
            <img src={IconImg} style={{marginRight:'8px',transform: cargoData.display?'rotate(180deg)':''}}/>
          </TextWrap>
        }
        </MediaItem>
      </>
      }
      {type == 2 && cargoData &&
      <>
        <MediaItem>
          <TextWrap style={{width:'100px', textAlign:'right'}}>
            <div style={{marginRight:'1rem'}}>{'-'}</div>
          </TextWrap>
        </MediaItem>
        <MediaItem>
          <TextWrap style={{width:'100px', textAlign:'right'}}>
            <div style={{marginRight:'1rem'}}>{'-'}</div>
          </TextWrap>
        </MediaItem>
        <MediaItem>
          <TextWrap style={{width:'100px', textAlign:'right'}}>
            <div style={{marginRight:'1rem'}}>{'-'}</div>
          </TextWrap>
        </MediaItem>
        <MediaItem>
          <TextWrap style={{width:'100px', textAlign:'right'}}>
            <div style={{marginRight:'1rem'}}>{'-'}</div>
          </TextWrap>
        </MediaItem>
        <MediaItem>
          <TextWrap style={{width:'150px', textAlign:'left'}}>
            <div style={{marginLeft:'1rem'}}>{cargoData.cargoId}</div>
          </TextWrap>
        </MediaItem>
        <MediaItem>
          <TextWrap style={{width:'150px', textAlign:'left'}}>
            <div style={{marginLeft:'1rem'}}>{cargoData.itemCd}</div>
          </TextWrap>
        </MediaItem>
        <MediaItem>
          <TextWrap style={{width:'300px', textAlign:'left'}}>
            <div style={{marginLeft:'1rem', fontWeight:'bold'}}>{cargoData.itemName}</div>
          </TextWrap>
        </MediaItem>
        <MediaItem>
          <TextWrap style={{width:'100px', textAlign:'right'}}>
            <div style={{marginRight:'1rem'}}>{LogisticUtility.getCommaText(parseInt(cargoData.totalItem))}</div>
          </TextWrap>
        </MediaItem>
        <MediaItem>
          <TextWrap style={{width:'50px', textAlign:'right',cursor: 'pointer'}} onClick={onClick}>
          </TextWrap>
        </MediaItem>
      </>
      }
      {/* <MediaItem>
        {!!parseInt(data.totalItem) && 
          <TextWrap style={{width:'50px', textAlign:'right',cursor: 'pointer'}} onClick={onClick}>
            <img src={IconImg} style={{marginRight:'8px',transform: data.display?'rotate(180deg)':''}}/>
          </TextWrap>
        }
      </MediaItem> */}
    </MediaWrap>
  )
}

MediaDataItemEmissions.propTypes = {
  data: PropTypes.object, // 種まき情報
  searchDate: PropTypes.string, // 検索日時
}

export default MediaDataItemEmissions

import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
//import { useAlert } from 'react-alert'
import styled from 'styled-components'
//import PropTypes from 'prop-types'

import { GetApi } from '../../../Common/ApiAxios'
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'
import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'

//import CardTotal from '../Atoms/CardTotal'
import CardTotalVAS from '../Atoms/CardTotalVAS'
import AveProductivityGraph from './AveProductivityGraph'

const DefaultUrl = '/api/logisticManagement'


const Flex = styled.div`
  display: flex; /* 横並び */
  flex-direction: row;
  width: 100%;
  height: 180px;
  text-align: center;
  vertical-align: middle;
  margin-top: 10px;
  margin-bottom: 5px;
`
const ContentRow = styled.div`
  display: flex; /* 横並び */
  flex-direction: column;
`
const Content = styled.div`
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  width: 50%;
  height: 180px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px;
  ::-webkit-scrollbar-corner {
    display: none;
  }
`
// ヘッダー部のカード
const ProgressHeader = (props) => {
  const {
    searchDate,daylyData,beforeData,progressList
  } = props

  //if (!grants || !totalDatas) return null
  return (
    <Flex>
      <Content>
        <ContentRow>         
          <CardTotalVAS daylyData={daylyData} beforeData={beforeData}/>      
        </ContentRow>
      </Content>
      {progressList && progressList.length!=0 &&
        <AveProductivityGraph progressList={progressList}/>
      }
    </Flex>
  )
}

ProgressHeader.propTypes = {
}
  
export default ProgressHeader


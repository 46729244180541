import React, { useState, useEffect } from 'react'
//import { useSelector, useDispatch } from 'react-redux'
//import { useAlert } from 'react-alert'
//import FUNC from '../../Common/FunctionEnum'
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'

import { GetApi } from '../../Common/ApiAxios'
import * as LogisticUtility from '../../Common/Utilties/LogisticUtility'

import OperationHeader from './Molcules/OperationHeader'
import PlanWeekly from './Molcules/PlanWeekly'
import OperationWeekly from './Molcules/OperationWeekly'

const DefaultUrl = '/api/logisticManagement'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  float: flex;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
`

const ContentBody = styled.div`
  width: 90%;
  heigt: 100px;
  margin-left: auto;
  margin-right: auto;
`

const OperationBoard = (props) => {
  //const { loginUserInfo } = useSelector(state => state);

  //const dispatch = useDispatch();
  //const alert = useAlert();
  const [weekly,setWeekly] = useState(null);
  // 稼働状況設定情報の取得
  const apiGetOperationStatusSetting = async () => {
    //console.log('call apiGetOperationStatusSetting')
    //dispatch(SetLoadSpinner(true))

    const apiUrl = DefaultUrl + '/getOperationStatusSetting';
    const param = {
      workplaceId: '000011',
    }

    const result = await GetApi(apiUrl, param);

    //dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('稼働状況設定取得失敗');
      setWeekly([]);
    } else {
      //console.log(result);
      setWeekly(result.data);
    }
  }

  // 週情報の取得
  const apiGetWeekDateInfo = async () => {
    //console.log('call getWeekDateInfo')
    //dispatch(SetLoadSpinner(true))

    const apiUrl = DefaultUrl + '/getWeekDateInfo';
    const param = {
      targetYMD: LogisticUtility.getStringFromDate(new Date(), 'YYYY-MM-DD'),
    }
    console.log(param);
    const result = await GetApi(apiUrl, param);

    //dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('週情報取得失敗');
      setWeekly([]);
    } else {
      console.log(result);
      setWeekly(result.data);
    }
  }

  useEffect(() => {
    apiGetWeekDateInfo();
  }, [])

  if (!weekly) return <></>;
  return (
    <>
      <ContentWrap>
        <OperationHeader dates={weekly} />
        <ContentBody>
          <PlanWeekly dates={weekly} />
          <OperationWeekly dates={weekly} />
        </ContentBody>
      </ContentWrap>
    </>
  )
}

export default OperationBoard

import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { InputGroup, SelectGroup, HiddenInput } from '../../../Common/CommonInputGroup'
import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'
import { format48Time, diff48Time } from '../../../Common/Utilties/LaborUtility'
const MediaWrap = styled.div`
  width:1290px;
  display: flex; /* 横並び */
  flex-wrap: wrap;
  margin: 4px;
  box-shadow: 0 0 3px 2px ${props => props.selected ? 'rgba(0,128,0,0.8)' : 'rgba(0,0,0,0.4)'};
  color: #000;
  font-size: 24px;
`
const MediaItem = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: ${props => props.height ? props.height : '40px'};
  border-right: 2px dashed #fff;
`
const MediaRow = styled.div`
  display: flex; /* 縦並び */
  flex-direction: row;
  height: 40px;
vertical-align: middle;
  align-items: center;
`
const TextWrap = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
` 
const HealthTitle = styled.div`
  margin: auto;
  text-overflow: ellipsis; /* 見切れ対策 */
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
` 


// 日付確認
function getDate(date,week) {
  var tmpDate = new Date(date)
  var dayOfWeek  = tmpDate.getDay()

  tmpDate.setDate(tmpDate.getDate() - dayOfWeek + week)
  var text = LogisticUtility.getStringFromDate(tmpDate, 'YYYY/MM/DD')

  return text;
}
// 日付確認
function getData(dataList,date,week,dataName) {
  if(dataList==null || !dataList) return;
  var dateValue = getDate(date,week)

  var dataRow = null
  dataList.map((item)=>{
    if(item.timeZone == dateValue){
      dataRow = item
    }
  })
  if(dataRow == null || dataRow[dataName] == null) return 0

  return Number(Number(dataRow[dataName]).toFixed(2)).toLocaleString();
}



const MediaDataCarbonEmissions = (props) => {
  const {
    title,data,before,index,color,unit,dataName,targetYMD,editFlag,register,errors,setUpdateFlag
  } = props
  const onBlur = ( event ) => {
    if(title=='作業人数')
    {
      let value = event.target.value
      value = Number(value)
      if(isNaN(value)){
        value=0
      }
      event.target.value = value.toLocaleString()
    }else{
      let value = event.target.value
      value = format48Time(value)
      event.target.value = value
    }

    setUpdateFlag(true)
  }
  // 初期値
  useEffect(() => {
  })

  return (
    <MediaWrap style={{color:index==0?'orangered':'black'}}>
      <MediaItem style={{backgroundColor: '#649CDF'}}>
        <HealthTitle style={{width:'175px',color:'white',fontWeight:'bold'}}>
          <div>{title}</div>
        </HealthTitle>
      </MediaItem>
      {!editFlag &&
      <>
      <MediaItem>
        <TextWrap style={{width:'180px'}}>
          <MediaRow>
            <div style={{width:'180px'}}>{data.niuke}</div>
          </MediaRow>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'180px'}}>
          <MediaRow>
          <div style={{width:'180px'}}>{data.input}</div>
          </MediaRow>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'180px'}}>
          <MediaRow>
            <div style={{width:'180px'}}>{data.soter}</div>
          </MediaRow>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'180px'}}>
          <MediaRow>
          <div style={{width:'180px'}}>{data.palet}</div>
          </MediaRow>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'180px'}}>
          <MediaRow>
            <div style={{width:'180px'}}>{data.staging}</div>
          </MediaRow>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'180px'}}>
          <MediaRow>
          <div style={{width:'180px'}}>{data.syukka}</div>
          </MediaRow>
        </TextWrap>
      </MediaItem>
      </>
      }
      {editFlag &&
      <>
      <MediaItem>
        <TextWrap style={{width:'180px'}}>
          <MediaRow>
            <InputGroup
              width='4.5rem'
              margin='0 0 0 52px'
              placeholder={title}
              name={'1_'+title}
              register={register}
              rule={{}}
              errors={errors}
              onBlur={onBlur}
              disabled={false}
              defaultValue={data.niuke}
              textAlign={index==0?'right':'left'}
            />
          </MediaRow>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'180px'}}>
          <MediaRow>
            <InputGroup
              width='4.5rem'
              margin='0 0 0 52px'
              placeholder={title}
              name={'2_'+title}
              register={register}
              rule={{}}
              errors={errors}
              onBlur={onBlur}
              disabled={false}
              defaultValue={data.input}
              textAlign={index==0?'right':'left'}
            />
          </MediaRow>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'180px'}}>
          <MediaRow>
            <InputGroup
              width='4.5rem'
              margin='0 0 0 52px'
              placeholder={title}
              name={'3_'+title}
              register={register}
              rule={{}}
              errors={errors}
              onBlur={onBlur}
              disabled={false}
              defaultValue={data.soter}
              textAlign={index==0?'right':'left'}
            />
          </MediaRow>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'180px'}}>
          <MediaRow>
          <InputGroup
              width='4.5rem'
              margin='0 0 0 52px'
              placeholder={title}
              name={'4_'+title}
              register={register}
              rule={{}}
              errors={errors}
              onBlur={onBlur}
              disabled={false}
              defaultValue={data.palet}
              textAlign={index==0?'right':'left'}
            />
          </MediaRow>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'180px'}}>
          <MediaRow>
          <InputGroup
              width='4.5rem'
              margin='0 0 0 52px'
              placeholder={title}
              name={'5_'+title}
              register={register}
              rule={{}}
              errors={errors}
              onBlur={onBlur}
              disabled={false}
              defaultValue={data.staging}
              textAlign={index==0?'right':'left'}
            />
          </MediaRow>
        </TextWrap>
      </MediaItem>
      <MediaItem>
        <TextWrap style={{width:'180px'}}>
          <MediaRow>
          <InputGroup
              width='4.5rem'
              margin='0 0 0 52px'
              placeholder={title}
              name={'6_'+title}
              register={register}
              rule={{}}
              errors={errors}
              onBlur={onBlur}
              disabled={false}
              defaultValue={data.syukka}
              textAlign={index==0?'right':'left'}
            />
          </MediaRow>
        </TextWrap>
      </MediaItem>
      </>
      }
    </MediaWrap>
  )
}

MediaDataCarbonEmissions.propTypes = {
  grants: PropTypes.object, // 権限関連
  data: PropTypes.object, // CO2排出情報
  totalEmissions: PropTypes.number, // CO2排出量の合計値
}

export default MediaDataCarbonEmissions

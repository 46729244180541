import React, { useEffect,useState } from 'react'
//import { useAlert } from 'react-alert'
import styled from 'styled-components'
import PropTypes from 'prop-types'

//import { GetApi } from '../../../Common/ApiAxios'
import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'
//import { toHaveAttribute } from '@testing-library/jest-dom'

//const DefaultUrl = '/api/logisticManagement'
import popupIcon from '../../../../image/popup-icon.png'
import MaterialAlertItem from './MaterialAlertItem'
import MaterialAlertHeader from './MaterialAlertHeader'
import ListDialog from './ListDialog'
const DefaultUrl = '/api/logisticManagement'

const PopupImg = styled.img`
  cursor: pointer;
  width: 28px;
  height: 28px;

  margin: auto 15px;
`
const CardWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: ${props => props.width ? props.width : '100%'};
  height: 100%;

  border: none;

  color: #000;

  margin-bottom: 10px;
  
`

const HeaderContent = styled.div`
  display: flex; /* 横並び */
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  text-align:left;
  vertical-align:middle;
`

const Title = styled.span`
  font-size: ${props => props.fontSize ? props.fontSize : '2.2vw'};
`

const BodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  height: 85%;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
`

export const ListUl = styled.ul`
  width: ${props => props.width ? props.width : '100%'};
  height: ${props => props.height ? props.height : '400px'};
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    border:none;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(112, 112, 112);
    border-radius: 10px;
  }
`

export const ListLi = styled.li`
  width: ${props => props.width ? props.width : 'calc(100%)'};
  display: flex; /* 横並び */
  flex-direction: row;
  background: ${props => props.selected ? '#048b63' : '#fff'};
  color: ${props => props.selected ? '#fff' : '#333'};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  .listId{
    margin-right:1vw;
  }
  :nth-child(even){
    background: ${props => props.selected ? '#048b63' : '#eee'};
    color: ${props => props.selected ? '#fff' : '#333'};
  }
  :hover {
    background: #ccc;
    color: #333;
  }
`

function getTitle(type) {
  var title = '';
  switch(type) {
    case 0:
      title = '店着時刻アラート';
      break;
    case 1:
      title = '長時間作業上位';
      break;
    case 2:
      title = '長時間待機上位';
      break;
    default:
      title = '';
      break;
  }

  return title;
}

function getValue(totalData, type) {
  var text = '';
  switch(type) {
    case 0:
      if (totalData !== null)
        text = LogisticUtility.getCommaText(Number(totalData).toFixed(1));
        text = <>{text} <span style={{fontSize:'70%',fontWeight:'normal'}}>{'時間'}</span></>
      break;
    case 1:
      if (totalData !== null)
        text = LogisticUtility.getCommaText(Number(totalData).toFixed(0));
        text = <>{text} <span style={{fontSize:'70%',fontWeight:'normal'}}>{'分'}</span></>
      break;
    case 2:
      if (totalData !== null)
        text = LogisticUtility.getCommaText(Number(totalData).toFixed(0));
        text = <>{text} <span style={{fontSize:'70%',fontWeight:'normal'}}>{'分'}</span></>
      break;
    default:
      text = '';
      break;
  }

  return text;
}


// ヘッダー部のカード
const CardTotal = (props) => {
  const {
    grants,
    totalData,
    type,
  } = props
  //const alert = useAlert();
  //const interval1Ref = useRef(null);
  const [open,setOpen] = useState(false);
  const onClickOpen=()=>{
    setOpen(true)
  }
  const onCloseFunc=()=>{
    setOpen(false)
  }
  // 描画時に一度だけ実行
  useEffect(() => {
  }, [])

  if (!totalData) return null
  return (
    <CardWrap width={'100%'}>
      <HeaderContent>
          <Title fontSize={'2vw'}>{getTitle(type)}</Title>
          <PopupImg src={popupIcon} onClick={onClickOpen}/>
      </HeaderContent>
      <BodyContent>
      <MaterialAlertHeader data={1} type={type}/>
      <ListUl width={totalData && totalData.length>5?'100%':'calc(100% - 10px)'} height={'243px'}>
        {totalData.map((item,index) => (
          <ListLi key={index+type} style={{cursor: 'default'}}>
            <MaterialAlertItem data={item} index={index+1} type={type}/>
          </ListLi> 
        ))}
      </ListUl>
      </BodyContent>
      <ListDialog onCloseFunc={onCloseFunc}  open={open} type={type} totalData={totalData}/>
    </CardWrap>
  )
}

CardTotal.propTypes = {
  grants: PropTypes.object, // 権限関連
  totalData: PropTypes.object, // 集計情報
  type: PropTypes.number, // 0:全体進捗率 1:終了予定時刻 2:全体稼働率 3:事故発生件数 4:投資可能額
}
  
export default CardTotal

import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
//import FUNC from '../../Common/FunctionEynum'
import { useForm } from 'react-hook-form'
//import * as regex from '../../Common/Regex'
import styled from 'styled-components'

import { GetApi,MultipartApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import * as LogisticUtility from '../../Common/Utilties/LogisticUtility'
//import ErrorHandling from '../../Common/ErrorHandling'
import { InputGroup, SelectGroup, HiddenInput } from '../../Common/CommonInputGroup'
import MediaHeaderEmissions from './Atoms/MediaHeaderEmissions'
import MediaDataEmissions from './Atoms/MediaDataEmissions'
const DefaultUrl = '/api/logisticManagement'

const BodyContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: row;

  vertical-align: middle;
  padding:0.5rem;
`
const TitleContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: row;
  text-align: center;
  vertical-align: middle;
  width: 100%;
`
const ContentColumn = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;

  vertical-align: middle;
  padding:0.5rem;
`

const ProgressContent = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;

  vertical-align: middle;
  width: ${props => props.width ? props.width : '897.5px'};
  height: ${props => props.height ? props.height : '170px'};
  background-color: ${props => props.color ? props.color : 'whitesmoke'};
  border-radius: ${props => props.radius ? '10px': '0px'};;
`

const ProgressCameraImg = styled.img`
  display: flex; /* 縦並び */
  flex-direction: column;

  vertical-align: middle;
  width: ${props => props.width ? props.width : '500px'};
  height: ${props => props.height ? props.height : '170px'};
  background-color: ${props => props.color ? props.color : 'whitesmoke'};
`

const ContentWrap = styled.div`
  display: flex; /* 横並び */
  flex-direction: column;
  vertical-align: middle;

  width: 100%;
  height: 600px;
`

const Title = styled.div`
  font-weight: bold;
  color: #000;
  text-align: left;
  font-size: 2rem;
  display: flex;
  flexDirection: row;
`

const ProgressTitle = styled.div`
  font-weight: bold;
  color: #000;
  text-align: center;
  font-size: 1.0rem;
  padding: 0.5rem;
  width 100%;
`
const ProgressImageTitle = styled.div`
  font-weight: bold;
  color: #000;
  text-align: center;
  font-size: 1.8rem;
  width 100%;
  padding:0.5rem;
  margin-top:0.7rem;
  margin-left:24px;
`

const ProgressContentRow = styled.div`
  font-weight: bold;
  color: #000;
  text-align: left;
  font-size: 2.0rem;
  padding: 0.5rem;
`

const StaffContentRow = styled.div`
  font-weight: bold;
  color: #000;
  text-align: left;
  font-size: 1.5rem;
  padding: 0.2rem;
  margin-left: 0.3rem;
`

const ProgressSubContentRow = styled.div`
  font-weight: bold;
  color: #000;
  text-align: center;
  padding:0.2rem;
  font-size: 1.5rem;
`
const ProgressSubContentInput = styled.input`
  font-weight: bold;
  color: #000;
  text-align: center;
  padding:0.2rem;
  font-size: 1.5rem;
`
export const ListUl = styled.ul`
  width: ${props => props.width ? props.width : '100%'};
  height: ${props => props.height ? props.height : '400px'};
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    border:none;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(112, 112, 112);
    border-radius: 10px;
  }
`

export const ListLi = styled.li`
  width: ${props => props.width ? props.width : 'calc(100%-18px)'};
  text-indent: 10px;
  background: ${props => props.selected ? '#048b63' : '#fff'};
  color: ${props => props.selected ? '#fff' : '#333'};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  .listId{
    margin-right:1vw;
  }
  :nth-child(even){
    background: ${props => props.selected ? '#048b63' : '#eee'};
    color: ${props => props.selected ? '#fff' : '#333'};
  }
  :hover {
    background: #ccc;
    color: #333;
  }
`

const ProgressPerformance = (props) => {
  const {
    editFlag,onCloseEdit,updateFlag,setUpdateFlag,searchDate
  } = props
    //const { loginUserInfo } = useSelector(state => state);
    const { register, handleSubmit, setValue, watch, errors, reset, getValues, control } = useForm({ validateCriteriaMode: 'all' })
    const dispatch = useDispatch();
    const alert = useAlert();
    const [beforePlanList,setBeforePlanList] = useState([]);

    const [planList,setPlanList] = useState([]);
    const [shootList,setShootList] = useState([]);
    const [storeList,setStoreList] = useState([]);
    const [managementList,setManagementList] = useState([]);

    const [processList,setProcessList] = useState([]);

    const [orgProcessList,setOrgProcessList] = useState([]);


    const [plaEndInfo, setPlanEndInfo] = useState("00:00");
    const intervalRef1 = useRef(null);
    const intervalRef2 = useRef(null);
    const intervalRef3 = useRef(null);
    const intervalRef4 = useRef(null);
    const [opacity,setOpacity]=useState(true)

    const onClose=()=>{
      if (updateFlag) {
        const is_ok = window.confirm('編集中のデータを破棄しますか?');
        if(!is_ok)
        {
          return null
        }
        setUpdateFlag(false)
      }

      onCloseEdit()
    }

    // 進捗設定情報の取得
    const apiGetSoterReal = async (isLoadSplinner) => {
      //console.log('call getActivityProgressManagement');
      if (isLoadSplinner)
        dispatch(SetLoadSpinner(true));
  
      const apiUrl = DefaultUrl + '/getSoterPlan';
     
      var now = new Date(searchDate);
      const data = {
        workplaceId: '000011',
        targetYMD: LogisticUtility.getStringFromDate(now, 'YYYY-MM-DD'),
      }
      const result = await GetApi(apiUrl, data);
  
      if (isLoadSplinner)
        dispatch(SetLoadSpinner(false));
  
      if (result.errorDetail) {
        //alert.error('条件の該当データがありません(進捗設定情報の取得)');
      } else {
        //console.log(result.data);
        
        if(result.data.length>4){
          setPlanList(result.data[0][0]);
          if(result.data[0].length>1){
            setBeforePlanList(result.data[0][1]);
          }
          setShootList(result.data[1]);
          setStoreList(result.data[2]);
          setManagementList(result.data[3])
          setOrgProcessList(result.data[4])

          var process = []
          process.push({title:'作業人数',niuke:'0',input:'0',soter:'0',palet:'0',staging:'0',syukka:'0'})
          process.push({title:'開始時間',niuke:'00:00',input:'00:00',soter:'00:00',palet:'00:00',staging:'00:00',syukka:'00:00'})
          process.push({title:'終了予定',niuke:'00:00',input:'00:00',soter:'00:00',palet:'00:00',staging:'00:00',syukka:'00:00'})

          result.data[4].map((tmp)=>{
            switch(tmp.shootName){
              case '荷受':
                process[0].niuke = tmp.attendanceWorkCnt
                process[1].niuke = tmp.planWorkTime
                process[2].niuke = tmp.progressEndTime
                break;
              case '投入口':
                process[0].input = tmp.attendanceWorkCnt
                process[1].input = tmp.planWorkTime
                process[2].input = tmp.progressEndTime
                break;
              case 'ステージング':
                process[0].staging = tmp.attendanceWorkCnt
                process[1].staging = tmp.planWorkTime
                process[2].staging = tmp.progressEndTime
                break;
              case 'ソーター仕分け':
                process[0].soter = tmp.attendanceWorkCnt
                process[1].soter = tmp.planWorkTime
                process[2].soter = tmp.progressEndTime
                break;
              case 'パレット仕分け':
                process[0].palet = tmp.attendanceWorkCnt
                process[1].palet = tmp.planWorkTime
                process[2].palet = tmp.progressEndTime
                break;
              case '出荷':
                process[0].syukka = tmp.attendanceWorkCnt
                process[1].syukka = tmp.planWorkTime
                process[2].syukka = tmp.progressEndTime
                break;
              default:
                break;
            }
          })
          
          setProcessList(process)
        }        
      }
    }    

    // 進捗情報の取得
    const apiGetActivityProgressManagement = async (isLoadSplinner) => {
      //console.log('call getActivityProgressManagement');
      if (isLoadSplinner)
        dispatch(SetLoadSpinner(true));
  
      const apiUrl = DefaultUrl + '/getActivityProgressManagement';
     
      const data = {
        workplaceId: '000011',
        targetYMD: LogisticUtility.getStringFromDate(new Date(searchDate), 'YYYY-MM-DD'),
      }
      const result = await GetApi(apiUrl, data);
  
      if (isLoadSplinner)
        dispatch(SetLoadSpinner(false));
  
      if (result.errorDetail) {
        //alert.error('条件の該当データがありません(進捗情報の取得)');
      } else {
        var planQuantity = 0.0;
        var progressQuantity = 0.0;
        var timeZone = '00:00';
        result.data.map((item)=>{
          planQuantity += item.planQuantity;
          progressQuantity += item.progressQuantity;
          if (item.remainingWorkTime > 0) {
            timeZone = item.timeZone;
          }
        });
  
        var totalProgress = 0.0;
        if (planQuantity > 0)
          totalProgress = progressQuantity * 100 / planQuantity;
  
        var endAt = LogisticUtility.getStringFromDate(new Date(), timeZone);

        setPlanEndInfo(endAt);


      }
    }

  // POST・PUT処理
  const onSubmit = () => {
    async function sendPost (requestBody, data) {
      dispatch(SetLoadSpinner(true))
      const apiUrl = DefaultUrl + '/updateSoterPlan';
      var result = await MultipartApi(apiUrl,'POST', requestBody, data)

      if (!result.errorDetail) {
        alert.success('保存しました')
        apiGetSoterReal(true);
        apiGetActivityProgressManagement();
        setUpdateFlag(false)
      }else{
        dispatch(SetLoadSpinner(false))
      }
    }

    const submitData = new window.FormData()
  
    var data = makeSendData()
    submitData.append('formData', new window.Blob([JSON.stringify(data)], { type: 'application/json' }))
    if(window.confirm('更新しますか?')){
      sendPost(submitData, data)
    }

    //updatedAttendanceFunc()
    //onCloseFunc()
  }
  const makeSendData = () =>
  {
    var workPlanList=[]
    orgProcessList.map((entry, index) => {
      workPlanList.push({
        workId: entry.shootCode,
        staffNum: getValues(entry.shootCode+'_'+'作業人数').replaceAll(',',''),
        startTime: getValues(entry.shootCode+'_'+'開始時間'),
        endTime: getValues(entry.shootCode+'_'+'終了予定'),
        quantity: 0,
      })
    })

    var planDataStore = []
    storeList.map((entry, index) => {
      planDataStore.push({
        week: new Date(searchDate).getDay()+1,
        shootNo: entry.shootCode,
        code: entry.storeCode,
        name: entry.storeName,
        quantity: getValues(entry.storeName).replaceAll(',',''),
      })
    })

    var planDataShoot = []
    shootList.map((entry1, index1) => {
      var quantity = 0
      storeList.map((entry, index) => {
        if(entry1.shootCode == entry.shootCode){
          quantity += Number(getValues(entry.storeName).replaceAll(',',''))
        }
      })
      planDataShoot.push({
        week: new Date(searchDate).getDay()+1,
        shootNo: entry1.shootCode,
        code: entry1.shootCode,
        name: entry1.shootName,
        quantity: quantity,
      })
    })

    var quantity = 0
    storeList.map((entry, index) => {
      quantity += Number(getValues(entry.storeName).replaceAll(',',''))
    })

    return {
      workPlanList: workPlanList,
      storeList:planDataStore,
      chuteList:planDataShoot,
      planSoterQuantity:quantity,
      planAllQuantity: quantity+Number(planList.planNotsoterQuantity),
      planNotSoterQuantity: Number(planList.planNotsoterQuantity),
      workplaceId: '000011',
      targetYMD: LogisticUtility.getStringFromDate(new Date(searchDate), 'YYYY-MM-DD'),
      }
  }

    // 周期初期のクリア
    const onClearInerval = async () => {
      if (intervalRef1.current !== null)
        clearInterval(intervalRef1.current);
      
        intervalRef1.current = null;

      if (intervalRef2.current !== null)
        clearInterval(intervalRef2.current);
        
        intervalRef2.current = null;

      if (intervalRef3.current !== null)
        clearInterval(intervalRef3.current);
        
        intervalRef3.current = null;

      if (intervalRef4.current !== null)
        clearInterval(intervalRef4.current);
        
        intervalRef4.current = null;
    }
  
    // 描画時に一度だけ実行
    useEffect(() => {
      apiGetSoterReal(true);
      //apiGetSoterBefore();
      apiGetActivityProgressManagement();
      intervalRef1.current = setInterval(() => {
        //apiGetSoterReal();
      }, 1000 * 60 * 5); // 5分間隔で変更
      
      // intervalRef2.current = setInterval(() => {
      //   setOpacity((opacity) => !opacity)
      // }, 1000 * 2); // 2秒間隔で変更


      return () => {
        onClearInerval();
      };
    }, [searchDate])

    const makeStaffNum=(plan)=>{
      if(!plan.absenteeismCnt) return 0

      var num = 0
      num += Number(plan.absenteeismCnt)
      num += Number(plan.attendanceWorkCnt)
      num += Number(plan.leavingWorkCnt)
      num += Number(plan.restCnt)
      num += Number(plan.unemployedCnt)

      return num
    }
    // 曜日を数値から文字列に変換するための配列
    const week = ["日", "月", "火", "水", "木", "金", "土"];  
    const makeTitle=()=>{
      var now = new Date(searchDate)
      var targetYMD = LogisticUtility.getStringFromDate(now, 'YYYY年MM月DD日')
      var w = now.getDay()
      if(!targetYMD) 
        return <><span style={{fontSize:'2.1rem'}}>{'yyyy年MM月dd日（曜日）の予定'}</span><span style={{marginLeft:'3rem',fontSize:'2.1rem'}}>{'【作業責任者】'}</span></>

        var manager = ''
        managementList.map((entry, index) => 
        {
          if(entry.shootOverFlowFlag=='1'){
            manager = entry.shootName+'　（'+entry.storeName+'）'
          }
        })
        return <>
                <span style={{fontSize:'2.1rem'}}>{targetYMD+'（'+week[w]+'）の予定'}</span>
                <span style={{marginLeft:'3rem',fontSize:'2.1rem'}}>{'【作業責任者】'}{manager}</span>
               </>

     }

    const makePlanTime=(plaEndInfo)=>{
        var endTime = plaEndInfo
        if(!endTime) return <>【終了予定時刻】 <span style={{fontSize:'2.1rem'}}>{'18:00'}</span></>
        return <>【終了予定時刻】 <span style={{fontSize:'2.1rem'}}>{plaEndInfo}</span></>
    }

    const makeManagement=(progress,plan)=>{
        var managementName = ' '
        if(managementList.length<1) return managementName;
        managementName += ('00'+(managementList.length-1)).slice(-2)+'名：'
        managementList.map((entry, index) => 
        {
          if(entry.shootOverFlowFlag=='0'){
            managementName += entry.shootName + '、'  
          }
        })
        return managementName.slice(0,managementName.length-1)
    }

    const toLocaleStringNumber=(number)=>{
      if(!number) return 0
      var tmp = Number(number).toFixed(0)
      if(tmp == NaN)return 0
      return LogisticUtility.getCommaText(tmp)
    }
    const makePersent=(after,before)=>{
      if(!after || !before || before=='0.0') return 0
    
      var num =after/before*100
      return num.toFixed(2)
    }

    const onBlur = ( event ) => {
        let value = event.target.value
        value = Number(value)
        if(isNaN(value)){
          value=0
        }
        event.target.value = value.toLocaleString()

        setUpdateFlag(true)
    }
  return (
    <>
    <ContentWrap>
      <BodyContent>
        <ContentColumn>
          <Title>
            {makeTitle()}
            {editFlag && 
            <>
              <div style={{marginLeft: 'auto', cursor: 'pointer',color:'blue',fontSize:'1.5rem', textDecoration:'underline'}} onClick={()=>onSubmit()}>更新</div>
              <div style={{marginLeft: '10px', cursor: 'pointer',color:'blue',fontSize:'1.5rem', textDecoration:'underline'}} onClick={onClose}>戻る</div>
            </>
            }
          </Title>
          <div style={{height:'0.5rem'}}/>
          <ProgressContent height={'75px'} width={'1850px'}>
            <ProgressContentRow style={{marginTop:'12px'}}>
            【出勤社員】 {makeManagement()}
            </ProgressContentRow>
          </ProgressContent>
          <div style={{height:'0.5rem'}}/>
          <ProgressContent height={'75px'} width={'1850px'}>
            <ProgressContentRow style={{marginTop:'12px'}}>
              <>
              <span style={{marginRight:'6rem'}}>{makePlanTime(plaEndInfo)}</span>
              <span>
               【作業予定物量】{toLocaleStringNumber(planList.planAllQuantity)}
                （ソーター：{toLocaleStringNumber(planList.planSoterQuantity)}・非ソーター：{toLocaleStringNumber(planList.planNotsoterQuantity)}）
              </span>
              </>
            </ProgressContentRow>
          </ProgressContent>
          <div style={{height:'0.5rem'}}/>
          <div style={{  display: 'flex',flexDirection: 'row'}}>
          <div style={{  display: 'flex',flexDirection: 'column'}}>
          <MediaHeaderEmissions/>
          <ListUl width={'1290px'} height={'158px'}>
          {processList.map((item,index) => (
          <ListLi key={item.title} style={{cursor: 'default'}} width={'1290px'} >
            <MediaDataEmissions title={item.title} index={index} data={item} editFlag={editFlag} register={register} errors={errors} setUpdateFlag={setUpdateFlag}/>
          </ListLi>
          ))}
        </ListUl>
          </div>
          <div style={{width:'5px'}}/>
          <ProgressContent height={'188px'} width={'555px'} color='none'>
          <div style={{  display: 'flex',flexDirection: 'column'}}>
          <ProgressContentRow style={{marginTop:'12px'}}>
              <>              
              <p>
              【稼働人数】{makeStaffNum(planList)}人<br/>
                <span style={{fontSize:'1.5rem'}}>（前日比：{makePersent(makeStaffNum(planList),makeStaffNum(beforePlanList))}％）</span>
              </p>
              </>
            </ProgressContentRow>
            <ProgressContentRow style={{marginTop:'12px'}}>
              <>
              <p>
               【稼働工数】{toLocaleStringNumber(planList.planWorkTime)}時間<br/>
               <span style={{fontSize:'1.5rem'}}>（前日比：{makePersent(planList.planWorkTime,beforePlanList.planWorkTime)}％）</span>
              </p>
              </>
            </ProgressContentRow>
           </div>
          </ProgressContent>
          </div>  
          <div style={{height:'0.5rem'}}/>
          <div style={{  display: 'flex',flexDirection: 'row'}}>
          {shootList && shootList.map((entry, index) => 
          {  
              return (
                <div style={{width:'155px'}}  key={entry.shootCode+'_'+entry.shootName}>
                <div style={{  display: 'flex',flexDirection: 'column'}}>
                <ProgressContent height={'80px'} width={'145px'} color={'lightcoral'} radius={true}>
                  <ProgressTitle>{entry.shootName}</ProgressTitle>
                  <ProgressSubContentRow>{toLocaleStringNumber(entry.planShootQuantity)}</ProgressSubContentRow>
                </ProgressContent>
                {storeList && storeList.map((entry1, index) => 
                {
                  if(entry1.shootCode == entry.shootCode){
                  return(
                    <>
                    <div style={{height:'5px'}}/>
                    <ProgressContent height={'80px'} width={'145px'} key={entry1.storeName+'_'+entry1.storeCode} radius={true}>
                      <ProgressTitle>{entry1.storeName}</ProgressTitle>
                      {!editFlag &&
                        <ProgressSubContentRow>{toLocaleStringNumber(entry1.planStoreQuantity)}</ProgressSubContentRow>
                      }
                      {editFlag &&
                        <InputGroup
                          width='7rem'
                          margin='auto'
                          placeholder='作業物量'
                          name={entry1.storeName}
                          register={register}
                          rule={{
                          }}
                          errors={errors}
                          disabled={false}
                          defaultValue={Number(entry1.planStoreQuantity).toLocaleString()}
                          onBlur={onBlur}
                          textAlign={'right'}
                        />
                      }
                    </ProgressContent>    
                    </>            
                  )
                  }
                })}
                </div>
                </div>
              )
          })
        }
        </div>
        </ContentColumn>
      </BodyContent>
    </ContentWrap>
    </>
  )
}
ProgressPerformance.propTypes = {
}

export default ProgressPerformance

import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import { useSelector } from 'react-redux'
//import FUNC from '../../Common/FunctionEynum'
//import { useForm } from 'react-hook-form'
import styled from 'styled-components'

//import { GetApi } from '../../Common/ApiAxios'
//import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import * as LogisticUtility from '../../Common/Utilties/LogisticUtility'
//import ErrorHandling from '../../Common/ErrorHandling'
import OperationList from './Molcules/OperationList'

const ContentWrap = styled.div`
  display: flex; /* 横並び */
  flex-direction: column;
  vertical-align: middle;

  width: 100%;
  height: 100%;
`

const ProgressPerformance = (props) => {
  const {
  } = props

  // 描画時に一度だけ実行
  useEffect(() => {
  }, [])


  return (
    <>
    <ContentWrap>
     <OperationList quantity={1000}/>
    </ContentWrap>
    </>
  )
}
ProgressPerformance.propTypes = {
  
}

export default ProgressPerformance

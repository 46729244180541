import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
//import { useAlert } from 'react-alert'
import { useDispatch } from 'react-redux'
//import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { GetApi } from '../../../Common/ApiAxios'
import { SetLoadSpinner } from '../../../Common/Slice/LoadSpinnerSlice'
import * as LogisticUtility from '../../../Common/Utilties/LogisticUtility'
//import BoardBaseWrap, {BoardBaseFormRow, ListUl, ListLi} from '../../Wrap/BoardBaseWrap'

//import Scop1Img from '../../../../image/scop1-icon.png'
//import Scop2Img from '../../../../image/scop2-icon.png'
//import Scop3UpperImg from '../../../../image/scop3-1-icon.png'
//import Scop3UnderImg from '../../../../image/scop3-2-icon.png'
import EditIcon from '../../../../image/edit-icon.png'

//import MediaHeaderCarbonEmissions from '../Atoms/MediaHeaderCarbonEmissions'
//import MediaDataCarbonEmissions from '../Atoms/MediaDataCarbonEmissions'
import InputYearlyGoalModal from './InputYearlyGoalModal'
import MonthlyModal from './MonthlyModal'
import YearlyModal from './YearlyModal'

const DefaultUrl = '/api/logisticManagement'

const ContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  text-align: left;
  vertical-align: middle;
`

const ContentFlex = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  text-align: center;
`

const ColumnContentWrap = styled.div`
  display: flex; /* 縦並び */
  flex-direction: column;
  width: 30vw;
  margin-left: 1rem;
  margin-top: 0.25rem;
  margin-right: 1rem;
  margin-bottom: 0.25rem;
  text-align: center;
`

const IndustryWrap = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: left;
  vertical-align: middle;
`

const GraphTitleWrap = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 600px;
  font-size: 1.5vw;
  font-weight: bold;
  margin-top: auto;
  margin-bottom: auto;
`
const ActionImg = styled.img`
  width: 16px;
  height: 16px;
  display:none;
  ${GraphTitleWrap}:hover & {
    display:block;
  }

  cursor: pointer;
`

const TitleWrap = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  background-color: #649CDF;
  color: #fff;
`

const TitleText = styled.div`
  margin: auto;
  font-size: 1.2vw;
  font-weight: bold;
`

const ScopeWrap = styled.div`
  display: flex; /* 横並び */
  flex-wrap: wrap;
  width: 100%;
  text-align: center;
`

const ScopeNameWrap = styled.div`
  height: 48px;
  font-size: 1.2vw;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
`

const ScopeValueWrap = styled.div`
  display: flex; /* 横並び */
  width: 100%;
  height: 30px;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
`

const ScopeRatio = styled.span`
  font-size: 0.8vw;
`

const ScopeIcon = styled.img`
  display: none;
  width: 28px;
  height: 28px;
  margin-left: 0.5rem;
  margin-top: 5px;
`

// 当月内訳のLegendのカスタム
function renderLegendText(value) {
  return <span style={{ color:"#000" }}>{value}</span>;
};

const IndustryCarbonEmissionBoard2 = (props) => {
  const {
    grants,
    industryData,
    searchWorkplaceId,
    searchDate,
  } = props
  const dispatch = useDispatch();
  const [totalEmissions, setTotalEmissions] = useState(0);
  const [realYeartotalEmissions, setRealYeartotalEmissions] = useState(0);
  const [yearTotalEmissions, setYearTotalEmissions] = useState([{name: 'CO2排出量累計',value:0,}]); // CO2排出累計
  const [yearlyGoal, setYearlyGoal] = useState(0.0);
  const [openDialog1, setOpenDialog1] = useState(false);
  const [openDialog2, setOpenDialog2] = useState(false);
  const [openDialog3, setOpenDialog3] = useState(false);

  // 年間のCO2排出量目標値の取得
  const apiGetYearGoalEmissions = async (isLoadSplinner) => {
    //console.log('call apiGetYearGoalEmissions');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true))

    const apiUrl = DefaultUrl + '/getYearGoalEmissions';

    var now = new Date(searchDate);
    var year = now.getFullYear();
    var month = now.getMonth();
    if (month < 3) {
      year -= 1;
    }
    const param = {
      workplaceId: searchWorkplaceId,
      targetYMD: LogisticUtility.getStringFromDate(new Date(year, 3, 1), 'YYYY-MM-DD'),
    }
    //console.log(param);
    const result = await GetApi(apiUrl, param);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('年間のCO2排出量目標値取得失敗');
      setYearlyGoal(0.0);
    } else {
      //console.log(result);
      if (result.data === '') {
        setYearlyGoal(0.0);
      } else {
        setYearlyGoal(result.data.emissions);
      }
    }
  }

  // 年間のCO2排出量
  const apiGetTotalCategoryEmissionsYear = async (isLoadSplinner) => {
    //console.log('call apiGetTotalCategoryEmissionsMonthly');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getTotalCategoryEmissionsYear';

    var now = new Date(searchDate);
    var year = now.getFullYear();
    var month = now.getMonth();
    if (month < 3) {
      year -= 1;
    }
    const param = {
      workplaceId: searchWorkplaceId,
      targetYMD: LogisticUtility.getStringFromDate(new Date(year, 3, 1), 'YYYY-MM-DD'),
      industryId: '00005',
    }
    //console.log(param);
    const result = await GetApi(apiUrl, param);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('日単位のCO2排出量のカテゴリの集計取得失敗');
      setTotalEmissions(0);
    } else {
      //console.log(result.data);
      var total = 0;
      result.data.forEach(function(elem, index) {
        total += elem.emissions;
      });
      
      var data = [
        {
          name: 'CO2排出量累計',
          value: total.toFixed(4),
        },
      ];
      setYearTotalEmissions(data);
      setRealYeartotalEmissions(total);
    }
  }

  // 月単位のCO2排出量のカテゴリの集計の取得
  const apiGetTotalCategoryEmissionsMonthly = async (isLoadSplinner) => {
    //console.log('call apiGetTotalCategoryEmissionsMonthly');
    if (isLoadSplinner)
      dispatch(SetLoadSpinner(true));

    const apiUrl = DefaultUrl + '/getTotalCategoryEmissionsMonthly';
    const param = {
      workplaceId: searchWorkplaceId,
      targetYMD: LogisticUtility.getStringFromDate(new Date(searchDate), 'YYYY-MM-DD'),
      industryId: '00005',
    }
    //console.log(param);
    const result = await GetApi(apiUrl, param);

    if (isLoadSplinner)
      dispatch(SetLoadSpinner(false));
    if (result.errorDetail) {
      //alert.error('日単位のCO2排出量のカテゴリの集計取得失敗');
      setTotalEmissions(0);
    } else {
      //console.log(result.data);
      {
        var total = 0;
        result.data.forEach(function(elem, index) {
          total += elem.emissions;
        });

        setTotalEmissions(total);
      }
    }
  }
  // 年間目標クリックイベント
  const onClickEdit = () => {
    console.log('onClickEdit');
    setOpenDialog1(true);
  }
  // 年間目標クリックイベント
  const onClickMonthly = () => {
    console.log('onClickMonthly');
    setOpenDialog2(true);
  }
  // 年間目標クリックイベント
  const onClickYearly = () => {
      console.log('onClickMonthly');
      setOpenDialog3(true);
  }
  useEffect(() => {
    //console.log(searchDate)
    apiGetYearGoalEmissions();
    apiGetTotalCategoryEmissionsYear();
    apiGetTotalCategoryEmissionsMonthly();
  }, [searchDate])
  return (
    <ContentWrap>
      <IndustryWrap>
        <GraphTitleWrap>
          年間 CO2排出量目標値 {LogisticUtility.getCommaText(yearlyGoal.toFixed(4))}（ton）
          <ActionImg style={{marginTop:'0px'}} src={EditIcon} onClick={onClickEdit} />
        </GraphTitleWrap>
        <GraphTitleWrap onClick={onClickYearly}>
          年間 CO2排出量累計値 {LogisticUtility.getCommaText(realYeartotalEmissions.toFixed(4))}（ton）
        </GraphTitleWrap>
        <GraphTitleWrap onClick={onClickMonthly}>
          当月 CO2排出量 {LogisticUtility.getCommaText(totalEmissions.toFixed(4))}（ton）
        </GraphTitleWrap>
      </IndustryWrap>
      <InputYearlyGoalModal
        grants={grants}
        modalIsOpen={openDialog1}
        setIsOpen={setOpenDialog1}
        workplaceId={searchWorkplaceId}
        targetYMD={searchDate}
        yearlyGoal={yearlyGoal}
        setYearlyGoal={setYearlyGoal}
      />
      <MonthlyModal
        grants={grants}
        modalIsOpen={openDialog2}
        setIsOpen={setOpenDialog2}
        workplaceId={searchWorkplaceId}
        searchDate={searchDate}
        yearlyGoal={yearlyGoal}
        setYearlyGoal={setYearlyGoal}
      />
      <YearlyModal
        grants={grants}
        modalIsOpen={openDialog3}
        setIsOpen={setOpenDialog3}
        workplaceId={searchWorkplaceId}
        searchDate={searchDate}
        yearlyGoal={yearlyGoal}
        setYearlyGoal={setYearlyGoal}
      />
    </ContentWrap>
  )
}

IndustryCarbonEmissionBoard2.propTypes = {
  grants: PropTypes.object, // 権限関連
  industryData: PropTypes.object, // 業種情報
  searchWorkplaceId: PropTypes.string, // 検索欄で選択した現場ID
  searchDate: PropTypes.string, // 検索欄で選択した日付
}

export default IndustryCarbonEmissionBoard2
